import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  MlConfigModelActions as Actions,
  MlConfigModelCreateRequestData,
  MlConfigModelCreateRequestFormValues,
  MlConfigModelCreateSuccessData,
  MlConfigModelDetail,
  MlConfigModelListItem,
  MlConfigModelUpdateRequestData,
  MlConfigModelUpdateRequestFormValues,
  MlConfigModelUpdateSuccessData,
} from './types';

export const listMlConfigModel = createAsyncAction(
  Actions.LIST_ML_CONFIG_MODEL_REQUEST,
  Actions.LIST_ML_CONFIG_MODEL_SUCCESS,
  Actions.LIST_ML_CONFIG_MODEL_FAILURE
)<ListRequestPayload, ListState<MlConfigModelListItem>, AxiosError>();

export const createMlConfigModel = createAsyncAction(
  Actions.CREATE_ML_CONFIG_MODEL_REQUEST,
  Actions.CREATE_ML_CONFIG_MODEL_SUCCESS,
  Actions.CREATE_ML_CONFIG_MODEL_FAILURE
)<
  FormRequestPayload<MlConfigModelCreateRequestFormValues, MlConfigModelCreateRequestData>,
  MlConfigModelCreateSuccessData,
  AxiosError
>();

export const readMlConfigModel = createAsyncAction(
  Actions.READ_ML_CONFIG_MODEL_REQUEST,
  Actions.READ_ML_CONFIG_MODEL_SUCCESS,
  Actions.READ_ML_CONFIG_MODEL_FAILURE
)<ReadRequestPayload, MlConfigModelDetail, AxiosError>();

export const updateMlConfigModel = createAsyncAction(
  Actions.UPDATE_ML_CONFIG_MODEL_REQUEST,
  Actions.UPDATE_ML_CONFIG_MODEL_SUCCESS,
  Actions.UPDATE_ML_CONFIG_MODEL_FAILURE
)<
  FormRequestPayload<MlConfigModelUpdateRequestFormValues, MlConfigModelUpdateRequestData>,
  MlConfigModelUpdateSuccessData,
  AxiosError
>();
