import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  BannerSettingMapEntryActions as Actions,
  BannerSettingMapEntryCreateRequestData,
  BannerSettingMapEntryCreateSuccessData,
  BannerSettingMapEntryUpdateRequestData,
  BannerSettingMapEntryUpdateSuccessData,
} from './types';

export const createBannerSettingMapEntry = createAsyncAction(
  Actions.CREATE_MAP_ENTRY_REQUEST,
  Actions.CREATE_MAP_ENTRY_SUCCESS,
  Actions.CREATE_MAP_ENTRY_FAILURE
)<FormRequestPayload<BannerSettingMapEntryCreateRequestData>, BannerSettingMapEntryCreateSuccessData, AxiosError>();

export const updateBannerSettingMapEntry = createAsyncAction(
  Actions.UPDATE_MAP_ENTRY_REQUEST,
  Actions.UPDATE_MAP_ENTRY_SUCCESS,
  Actions.UPDATE_MAP_ENTRY_FAILURE
)<FormRequestPayload<BannerSettingMapEntryUpdateRequestData>, BannerSettingMapEntryUpdateSuccessData, AxiosError>();

export const deleteBannerSettingMapEntry = createAsyncAction(
  Actions.DELETE_MAP_ENTRY_REQUEST,
  Actions.DELETE_MAP_ENTRY_SUCCESS,
  Actions.DELETE_MAP_ENTRY_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
