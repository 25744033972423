import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  TeamMessageLinkSettingActions as Actions,
  TeamMessageLinkSettingCreateRequestData,
  TeamMessageLinkSettingCreateResponseData,
  TeamMessageLinkSettingFormValues,
  TeamMessageLinkSettingUpdateRequestData,
  TeamMessageLinkSettingUpdateResponseData,
} from './types';

export const createTeamMessageLinkSetting = createAsyncAction(
  Actions.CREATE_TEAM_MESSAGE_LINK_SETTING_REQUEST,
  Actions.CREATE_TEAM_MESSAGE_LINK_SETTING_SUCCESS,
  Actions.CREATE_TEAM_MESSAGE_LINK_SETTING_FAILURE
)<
  FormRequestPayload<TeamMessageLinkSettingFormValues, TeamMessageLinkSettingCreateRequestData>,
  TeamMessageLinkSettingCreateResponseData,
  AxiosError
>();

export const updateTeamMessageLinkSetting = createAsyncAction(
  Actions.UPDATE_TEAM_MESSAGE_LINK_SETTING_REQUEST,
  Actions.UPDATE_TEAM_MESSAGE_LINK_SETTING_SUCCESS,
  Actions.UPDATE_TEAM_MESSAGE_LINK_SETTING_FAILURE
)<
  FormRequestPayload<TeamMessageLinkSettingFormValues, TeamMessageLinkSettingUpdateRequestData>,
  TeamMessageLinkSettingUpdateResponseData,
  AxiosError
>();
