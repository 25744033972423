import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as decoColorPresetActions from './actions';
import { DecoColorPresetState, initialState } from './types';

const decoColorPreset = createReducer<DecoColorPresetState, RootAction>(initialState)
  .handleAction(decoColorPresetActions.listDecoColorPreset.request, (state) => ({ ...state }))
  .handleAction(decoColorPresetActions.listDecoColorPreset.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

export default decoColorPreset;
