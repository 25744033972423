import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createTeamMessage, listTeamMessage, readTeamMessage, updateTeamMessage } from './actions';
import { createTeamMessageApi, listTeamMessageApi, readTeamMessageApi, updateTeamMessageApi } from './api';

const listTeamMessageEpic = createListEpic(listTeamMessage, listTeamMessageApi);

const readTeamMessageEpic = createReadEpic(readTeamMessage, readTeamMessageApi);

const createTeamMessageEpic = createPostEpic(createTeamMessage, createTeamMessageApi);

const createTeamMessageToastEpic = createToastEpic(createTeamMessage.success, 'Successfully created');

const updateTeamMessageEpic = createUpdateEpic(updateTeamMessage, updateTeamMessageApi);

const updateTeamMessageToastEpic = createToastEpic(updateTeamMessage.success, 'Successfully saved');

export default combineEpics(
  listTeamMessageEpic,
  readTeamMessageEpic,
  createTeamMessageEpic,
  createTeamMessageToastEpic,
  updateTeamMessageEpic,
  updateTeamMessageToastEpic
);
