import { createReducer } from 'typesafe-actions';

import * as abTestActions from 'store/abTest/actions';
import * as abTestCriteriaActions from 'store/abTestCriteria/actions';
import { initialState, AbTestState } from 'store/abTest/types';
import { RootAction } from 'store/root/types';
import { AbTestCriteria } from 'store/abTestCriteria/types';

const abTest = createReducer<AbTestState, RootAction>(initialState)
  .handleAction(abTestActions.listAbTest.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(abTestActions.listAbTest.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(abTestActions.readAbTest.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(abTestCriteriaActions.createAbTestCriteria.success, (state, { payload }) => {
    const criteria = state?.detail?.criteria ?? [];
    const newCriteria = [...criteria, payload];

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            criteria: newCriteria,
          }
        : undefined,
    };
  })
  .handleAction(abTestCriteriaActions.updateAbTestCriteria.success, (state, { payload }) => {
    const criteria = state?.detail?.criteria ?? [];
    const updatedScheduleIndex = criteria.findIndex((criteria: AbTestCriteria) => criteria.id === payload.id);

    if (updatedScheduleIndex !== -1) {
      criteria[updatedScheduleIndex] = payload;
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            criteria: criteria,
          }
        : undefined,
    };
  })
  .handleAction(abTestCriteriaActions.deleteAbTestCriteria.success, (state, { payload }) => {
    const criteria = state?.detail?.criteria ?? [];
    const newCriteria = criteria.filter((criteria: AbTestCriteria) => criteria.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            criteria: newCriteria,
          }
        : undefined,
    };
  });

export default abTest;
