import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as manualSegmentActions from './actions';
import { initialState, ManualSegmentState } from './types';

const manualSegment = createReducer<ManualSegmentState, RootAction>(initialState)
  .handleAction(manualSegmentActions.listManualSegment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(manualSegmentActions.listManualSegment.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);

    newState = newState.set('list', {
      ...payload,
      data: payload.data.map((item) => {
        const {
          id,
          segment: { segmentCode, segmentName, description },
          user: { id: userId, userName },
        } = item;

        return {
          id,
          segmentCode,
          segmentName,
          description,
          userId,
          userName,
        };
      }),
    });

    return newState.toJS() as ManualSegmentState;
  })
  .handleAction(manualSegmentActions.readManualSegment.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);

    const {
      id,
      segment: { segmentCode },
      user: { id: userId, userName },
    } = payload;

    newState = newState.set('detail', {
      id,
      segmentCode,
      userId,
      userName,
    });

    return newState.toJS() as ManualSegmentState;
  })
  .handleAction(manualSegmentActions.updateManualSegment.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);

    const {
      id,
      segment: { segmentCode },
      user: { id: userId, userName },
    } = payload;

    newState = newState.set('detail', {
      id,
      segmentCode,
      userId,
      userName,
    });

    return newState.toJS() as ManualSegmentState;
  });
export default manualSegment;
