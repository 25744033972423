import { initialListState, ListState } from '../types';

export enum PushContentActions {
  LIST_PUSH_CONTENT_REQUEST = 'LIST_PUSH_CONTENT#REQUEST',
  LIST_PUSH_CONTENT_SUCCESS = 'LIST_PUSH_CONTENT#SUCCESS',
  LIST_PUSH_CONTENT_FAILURE = 'LIST_PUSH_CONTENT#FAILURE',

  CREATE_PUSH_CONTENT_REQUEST = 'CREATE_PUSH_CONTENT#REQUEST',
  CREATE_PUSH_CONTENT_SUCCESS = 'CREATE_PUSH_CONTENT#SUCCESS',
  CREATE_PUSH_CONTENT_FAILURE = 'CREATE_PUSH_CONTENT#FAILURE',

  READ_PUSH_CONTENT_REQUEST = 'READ_PUSH_CONTENT#REQUEST',
  READ_PUSH_CONTENT_SUCCESS = 'READ_PUSH_CONTENT#SUCCESS',
  READ_PUSH_CONTENT_FAILURE = 'READ_PUSH_CONTENT#FAILURE',

  UPDATE_PUSH_CONTENT_REQUEST = 'UPDATE_PUSH_CONTENT#REQUEST',
  UPDATE_PUSH_CONTENT_SUCCESS = 'UPDATE_PUSH_CONTENT#SUCCESS',
  UPDATE_PUSH_CONTENT_FAILURE = 'UPDATE_PUSH_CONTENT#FAILURE',

  REFRESH_PUSH_CONTENT_REQUEST = 'REFRESH_PUSH_CONTENT#REQUEST',
  REFRESH_PUSH_CONTENT_SUCCESS = 'REFRESH_PUSH_CONTENT#SUCCESS',
  REFRESH_PUSH_CONTENT_FAILURE = 'REFRESH_PUSH_CONTENT#FAILURE',

  // Variation

  CREATE_PUSH_CONTENT_VARIATION_REQUEST = 'CREATE_PUSH_CONTENT_VARIATION#REQUEST',
  CREATE_PUSH_CONTENT_VARIATION_SUCCESS = 'CREATE_PUSH_CONTENT_VARIATION#SUCCESS',
  CREATE_PUSH_CONTENT_VARIATION_FAILURE = 'CREATE_PUSH_CONTENT_VARIATION#FAILURE',

  UPDATE_PUSH_CONTENT_VARIATION_REQUEST = 'UPDATE_PUSH_CONTENT_VARIATION#REQUEST',
  UPDATE_PUSH_CONTENT_VARIATION_SUCCESS = 'UPDATE_PUSH_CONTENT_VARIATION#SUCCESS',
  UPDATE_PUSH_CONTENT_VARIATION_FAILURE = 'UPDATE_PUSH_CONTENT_VARIATION#FAILURE',

  DELETE_PUSH_CONTENT_VARIATION_REQUEST = 'DELETE_PUSH_CONTENT_VARIATION#REQUEST',
  DELETE_PUSH_CONTENT_VARIATION_SUCCESS = 'DELETE_PUSH_CONTENT_VARIATION#SUCCESS',
  DELETE_PUSH_CONTENT_VARIATION_FAILURE = 'DELETE_PUSH_CONTENT_VARIATION#FAILURE',

  // Push type

  READ_PUSH_TYPE_REQUEST = 'READ_PUSH_TYPE_REQUEST',
  READ_PUSH_TYPE_SUCCESS = 'READ_PUSH_TYPE_SUCCESS',
  READ_PUSH_TYPE_FAILURE = 'READ_PUSH_TYPE_FAILURE',
}

export interface PushNotificationMode {
  apnsForegroundNotificationMode: ApnsForegroundPushNotificationModeValue;
  fcmForegroundNotificationMode: FcmForegroundPushNotificationModeValue;
  fcmBackgroundNotificationMode: FcmBackgroundPushNotificationModeValue;
}

export interface PushContent {
  contentId: string;
  type: string;
  messageType: string;
  showNotification: boolean;
  showNotificationCenter: boolean;
  title: string;
  message: string;
  buttonText: string;
  deeplink: string;
  imageUrl: string;
  pushNotificationMode: PushNotificationMode;
}

export interface PushContentDetail extends PushContent {
  variations: PushContentVariation[];
}

export interface PushContentFormValues extends PushContent {
  titleAsString: boolean;
  messageAsString: boolean;
  buttonTextAsString: boolean;
  imageUrlAsString: boolean;
  apnsForegroundNotificationMode: ApnsForegroundPushNotificationModeValue;
  fcmForegroundNotificationMode: FcmForegroundPushNotificationModeValue;
  fcmBackgroundNotificationMode: FcmBackgroundPushNotificationModeValue;
}

export type PushContentCreateRequestData = PushContent;
export type PushContentCreateResponseData = PushContent;

export interface PushContentState {
  list: ListState<PushContent>;
  detail?: PushContentDetail;
  pushTypes: Array<string>;
}

export const initialState: PushContentState = {
  list: initialListState,
  detail: undefined,
  pushTypes: [],
};

export enum FcmBackgroundPushNotificationModeValue {
  IGNORE = 'IGNORE',
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION',
}

export enum FcmForegroundPushNotificationModeValue {
  IGNORE = 'IGNORE',
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION',
  IN_APP = 'IN_APP',
}

export enum ApnsForegroundPushNotificationModeValue {
  IGNORE = 'IGNORE',
  IN_APP = 'IN_APP',
}

export interface PushContentVariation {
  pushContentId: string;
  id: string;
  title: string;
  message: string;
  buttonText: string;
  variationImageUrl: string;
}

export interface PushContentVariationFormValues extends PushContentVariation {
  titleAsString: boolean;
  messageAsString: boolean;
  buttonTextAsString: boolean;
  variationImageUrlAsString: boolean;
}

export type PushContentVariationCreateRequestData = PushContentVariation;
export type PushContentVariationCreateResponseData = PushContentVariation;
export type PushContentVariationUpdateRequestData = PushContentVariation;
export type PushContentVariationUpdateResponseData = PushContentVariation;
