import { createListApi, createReadApi } from 'store/utils';

import { BigqueryNewUserSegment, BigquerySegment, BigquerySegmentDetail } from './types';

export const listBigquerySegmentApi = createListApi<BigquerySegment>('/segment/bigquery/declaration', {
  params: {
    type: 'VERSIONING_MANUAL',
  },
});

export const listBigquerySegmentSearchApi = createListApi<BigqueryNewUserSegment>('/segment/bigquery', {
  params: {
    type: 'VERSIONING_MANUAL',
  },
});

export const readBigquerySegmentApi = createReadApi<BigquerySegmentDetail>('segment/bigquery/:id');
