export enum WebDecoSettingScheduleActions {
  CREATE_WEB_DECO_SETTING_SCHEDULE_REQUEST = 'CREATE_WEB_DECO_SETTING_SCHEDULE#REQUEST',
  CREATE_WEB_DECO_SETTING_SCHEDULE_SUCCESS = 'CREATE_WEB_DECO_SETTING_SCHEDULE#SUCCESS',
  CREATE_WEB_DECO_SETTING_SCHEDULE_FAILURE = 'CREATE_WEB_DECO_SETTING_SCHEDULE#FAILURE',

  UPDATE_WEB_DECO_SETTING_SCHEDULE_REQUEST = 'UPDATE_WEB_DECO_SETTING_SCHEDULE#REQUEST',
  UPDATE_WEB_DECO_SETTING_SCHEDULE_SUCCESS = 'UPDATE_WEB_DECO_SETTING_SCHEDULE#SUCCESS',
  UPDATE_WEB_DECO_SETTING_SCHEDULE_FAILURE = 'UPDATE_WEB_DECO_SETTING_SCHEDULE#FAILURE',

  DELETE_WEB_DECO_SETTING_SCHEDULE_REQUEST = 'DELETE_WEB_DECO_SETTING_SCHEDULE#REQUEST',
  DELETE_WEB_DECO_SETTING_SCHEDULE_SUCCESS = 'DELETE_WEB_DECO_SETTING_SCHEDULE#SUCCESS',
  DELETE_WEB_DECO_SETTING_SCHEDULE_FAILURE = 'DELETE_WEB_DECO_SETTING_SCHEDULE#FAILURE',
}

export interface WebDecoSettingSchedule {
  id: number;
  settingId?: number;
  segmentCode: string;
  startDate?: string;
  endDate?: string;
  priority: number;
  enabled: boolean;
}

export type WebDecoSettingScheduleFormValues = Omit<WebDecoSettingSchedule, 'id' | 'enabled'>;

export type WebDecoSettingScheduleCreateRequestData = WebDecoSettingScheduleFormValues;

export type WebDecoSettingScheduleCreateResponseData = WebDecoSettingSchedule;

export type WebDecoSettingScheduleUpdateRequestData = WebDecoSettingScheduleFormValues;

export type WebDecoSettingScheduleUpdateResponseData = WebDecoSettingSchedule;
