import { initialListState, ListState } from 'store/types';
import { PurposeSchedule } from 'store/purposeSchedule/types';
import { PurposeOption } from 'store/purposeOption/types';

export enum PurposeActions {
  LIST_PURPOSE_REQUEST = 'LIST_PURPOSE#REQUEST',
  LIST_PURPOSE_SUCCESS = 'LIST_PURPOSE#SUCCESS',
  LIST_PURPOSE_FAILURE = 'LIST_PURPOSE#FAILURE',

  READ_PURPOSE_REQUEST = 'READ_PURPOSE#REQUEST',
  READ_PURPOSE_SUCCESS = 'READ_PURPOSE#SUCCESS',
  READ_PURPOSE_FAILURE = 'READ_PURPOSE#FAILURE',

  CREATE_PURPOSE_REQUEST = 'CREATE_PURPOSE#REQUEST',
  CREATE_PURPOSE_SUCCESS = 'CREATE_PURPOSE#SUCCESS',
  CREATE_PURPOSE_FAILURE = 'CREATE_PURPOSE#FAILURE',

  UPDATE_PURPOSE_REQUEST = 'UPDATE_PURPOSE#REQUEST',
  UPDATE_PURPOSE_SUCCESS = 'UPDATE_PURPOSE#SUCCESS',
  UPDATE_PURPOSE_FAILURE = 'UPDATE_PURPOSE#FAILURE',
}

export const initialState: PurposeState = {
  list: initialListState,
  detail: undefined,
};

export interface PurposeState {
  list: ListState<PurposeListItem>;
  detail?: PurposeItemDetail;
}

export interface PurposeItemDetail {
  id: number;
  set: PurposeSet;
  options: PurposeOption[];
  schedules: PurposeSchedule[];
}

export interface Purpose {
  id: number;
  name: string;
  displayNameKey: string;
  description?: string;
  optionDisplayCount: number;
}

export interface PurposeListItem extends Purpose {
  enabled: boolean;
}

export interface PurposeValues extends Purpose {
  displayNameKeyAsString: boolean;
}

export type PurposeFormValues = PurposeValues;
export type PurposeSet = Purpose;

// export type PurposeCreateRequestData = Omit<PurposeFormValues, 'id' | 'enabled'>;
export interface PurposeCreateRequestData extends Omit<Purpose, 'id'> {}
export type PurposeCreateSuccessData = Purpose;
export type PurposeCreateResponseData = Purpose;

export type PurposeUpdateRequestData = Purpose;
export type PurposeUpdateSuccessData = Purpose;
export type PurposeUpdateResponseData = Purpose;
