import {
  createDeleteApi,
  createDeleteBodyApi,
  createListApi,
  createPostApi,
  createReadApi,
  createUpdateApi,
} from 'store/utils';
import {
  ShopSectionScheduleFormValues,
  ShopSectionScheduleCreateRequestData,
  ShopSectionScheduleCreateResponseData,
  ShopSectionScheduleUpdateRequestData,
  ShopSectionScheduleUpdateResponseData,
  ShopSectionModuleFormValue,
  ShopSectionModuleResponseData,
  ShopSectionModuleBulkCreateRequestData,
} from './types';

export const listShopSectionApi = createListApi('/shop_sections');
export const createShopSectionApi = createPostApi('/shop_sections');
export const readShopSectionApi = createReadApi('/shop_sections/:id');
export const deleteShopSectionApi = createDeleteApi('/shop_sections/:id');
export const updateShopSectionApi = createUpdateApi('/shop_sections/:id');

// - Module -
export const connectShopSectionModuleApi = createPostApi<ShopSectionModuleResponseData>('/shop_modules/links');
export const editShopSectionModuleApi = createUpdateApi<ShopSectionModuleFormValue, ShopSectionModuleResponseData>(
  '/shop_modules/links/:linkId'
);
export const disconnectShopSectionModuleApi = createDeleteApi('/shop_modules/links/:linkId');

// -MODULE BULK -
export const bulkCreateShopSectionModuleApi =
  createPostApi<ShopSectionModuleBulkCreateRequestData>('/shop_modules/links/bulk');

export const bulkDeleteShopSectionModuleApi = createDeleteBodyApi('/shop_modules/links/bulk');

// - SCHEDULE -
export const createShopSectionPageScheduleApi = createPostApi<
  ShopSectionScheduleFormValues,
  ShopSectionScheduleCreateRequestData,
  ShopSectionScheduleCreateResponseData
>('/shop_sections/schedules');

export const updateShopSectionPageScheduleApi = createUpdateApi<
  ShopSectionScheduleFormValues,
  ShopSectionScheduleUpdateRequestData,
  ShopSectionScheduleUpdateResponseData
>('/shop_sections/schedules/:id');

export const deleteShopSectionPageScheduleApi = createDeleteApi('/shop_sections/schedules/:id');
