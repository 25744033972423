import { createListApi, createPostApi, createReadApi, createUpdateApi, createDeleteApi } from 'store/utils';
import {
  ShopModuleScheduleFormValues,
  ShopModuleScheduleCreateRequestData,
  ShopModuleScheduleCreateResponseData,
  ShopModuleScheduleUpdateRequestData,
  ShopModuleScheduleUpdateResponseData,
} from './types';

export const createShopModuleApi = createPostApi('/shop_modules');
export const listShopModuleApi = createListApi('/shop_modules');
export const readShopModuleApi = createReadApi('/shop_modules/:id');
export const deleteShopModuleApi = createDeleteApi('/shop_modules/:id');
export const updateShopModuleApi = createUpdateApi('/shop_modules/:id');

// - SCHEDULE -
export const createShopModulePageScheduleApi = createPostApi<
  ShopModuleScheduleFormValues,
  ShopModuleScheduleCreateRequestData,
  ShopModuleScheduleCreateResponseData
>('/shop_modules/schedules');

export const updateShopModulePageScheduleApi = createUpdateApi<
  ShopModuleScheduleFormValues,
  ShopModuleScheduleUpdateRequestData,
  ShopModuleScheduleUpdateResponseData
>('/shop_modules/schedules/:id');

export const deleteShopModulePageScheduleApi = createDeleteApi('/shop_modules/schedules/:id');
