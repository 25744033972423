import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  FlatListRequestPayload,
  FlatListState,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  WebDecoEffectActions as Actions,
  WebDecoEffectCreateRequestData,
  WebDecoEffectCreateResponseData,
  WebDecoEffectDetail,
  WebDecoEffectFormValues,
  WebDecoEffectListItem,
  WebDecoEffectUpdateRequestData,
  WebDecoEffectUpdateResponseData,
} from './types';
import { WebDecoEffectGroup } from 'store/webDecoEffect/types';

export const listWebDecoEffect = createAsyncAction(
  Actions.LIST_WEB_DECO_EFFECT_REQUEST,
  Actions.LIST_WEB_DECO_EFFECT_SUCCESS,
  Actions.LIST_WEB_DECO_EFFECT_FAILURE
)<ListRequestPayload, ListState<WebDecoEffectListItem>, AxiosError>();

export const listWebDecoGroupEffect = createAsyncAction(
  Actions.LIST_WEB_DECO_EFFECT_GROUP_REQUEST,
  Actions.LIST_WEB_DECO_EFFECT_GROUP_SUCCESS,
  Actions.LIST_WEB_DECO_EFFECT_GROUP_FAILURE
)<FlatListRequestPayload, FlatListState<WebDecoEffectGroup>, AxiosError>();

export const createWebDecoEffect = createAsyncAction(
  Actions.CREATE_WEB_DECO_EFFECT_REQUEST,
  Actions.CREATE_WEB_DECO_EFFECT_SUCCESS,
  Actions.CREATE_WEB_DECO_EFFECT_FAILURE
)<
  FormRequestPayload<WebDecoEffectFormValues, WebDecoEffectCreateRequestData>,
  WebDecoEffectCreateResponseData,
  AxiosError
>();

export const updateWebDecoEffect = createAsyncAction(
  Actions.UPDATE_WEB_DECO_EFFECT_REQUEST,
  Actions.UPDATE_WEB_DECO_EFFECT_SUCCESS,
  Actions.UPDATE_WEB_DECO_EFFECT_FAILURE
)<
  FormRequestPayload<WebDecoEffectFormValues, WebDecoEffectUpdateRequestData>,
  WebDecoEffectUpdateResponseData,
  AxiosError
>();

export const readWebDecoEffect = createAsyncAction(
  Actions.READ_WEB_DECO_EFFECT_REQUEST,
  Actions.READ_WEB_DECO_EFFECT_SUCCESS,
  Actions.READ_WEB_DECO_EFFECT_FAILURE
)<ReadRequestPayload, WebDecoEffectDetail, AxiosError>();

export const refreshWebDecoEffect = createAsyncAction(
  Actions.REFRESH_WEB_DECO_EFFECT_REQUEST,
  Actions.REFRESH_WEB_DECO_EFFECT_SUCCESS,
  Actions.REFRESH_WEB_DECO_EFFECT_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
