import {
  GemSettingProductCreateRequestData,
  GemSettingProductCreateSuccessData,
  GemSettingProductUpdateRequestData,
  GemSettingProductUpdateSuccessData,
} from 'store/gemSettingProduct/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createGemSettingProductApi = createPostApi<
  GemSettingProductCreateRequestData,
  GemSettingProductCreateSuccessData
>('/gem_shops/setting_products');

export const updateGemSettingProductApi = createUpdateApi<
  GemSettingProductUpdateRequestData,
  GemSettingProductUpdateSuccessData
>('/gem_shops/setting_products/:id');

export const deleteGemSettingProductApi = createDeleteApi('/gem_shops/setting_products/:id');
