import { combineEpics } from 'redux-observable';

import {
  createDeleteBodyEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createManualSegment,
  deleteManualSegments,
  listManualSegment,
  readManualSegment,
  updateManualSegment,
} from './actions';
import {
  createManualSegmentApi,
  deleteManualSegmentApi,
  listManualSegmentApi,
  readManualSegmentApi,
  updateManualSegmentApi,
} from './api';

const listManualSegmentEpic = createListEpic(listManualSegment, listManualSegmentApi);

const readManualSegmentEpic = createReadEpic(readManualSegment, readManualSegmentApi);

const createManualSegmentEpic = createPostEpic(createManualSegment, createManualSegmentApi);

const createManualSegmentToastEpic = createToastEpic(createManualSegment.success, 'Successfully created');

const updateManualSegmentEpic = createUpdateEpic(updateManualSegment, updateManualSegmentApi);

const updateManualSegmentToastEpic = createToastEpic(updateManualSegment.success, 'Successfully saved');

const deleteManualSegmentEpic = createDeleteBodyEpic(deleteManualSegments, deleteManualSegmentApi);

const deleteManualSegmentToastEpic = createToastEpic(deleteManualSegments.success, 'Successfully deleted');

export default combineEpics(
  listManualSegmentEpic,
  readManualSegmentEpic,
  createManualSegmentEpic,
  createManualSegmentToastEpic,
  updateManualSegmentEpic,
  updateManualSegmentToastEpic,
  deleteManualSegmentEpic,
  deleteManualSegmentToastEpic
);
