import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as remoteConfigActions from './actions';
import { initialState, RemoteConfigState } from './types';

const remoteConfig = createReducer<RemoteConfigState, RootAction>(initialState)
  .handleAction(remoteConfigActions.readRemoteConfig.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(remoteConfigActions.updateRemoteConfig.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(remoteConfigActions.listRemoteConfig.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(remoteConfigActions.listRemoteConfig.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default remoteConfig;
