import { combineEpics } from 'redux-observable';

import { createDeleteBodyEpic, createListEpic, createToastEpic } from 'store/utils';

import { deleteUserItemInventory, listUserItemInventory } from './actions';
import { deleteUserItemInventoryApi, listUserItemInventoryApi } from './api';

const listUserItemInventoryEpic = createListEpic(listUserItemInventory, listUserItemInventoryApi);

const deleteUserItemInventoryEpic = createDeleteBodyEpic(deleteUserItemInventory, deleteUserItemInventoryApi);

const deleteUserItemInventoryToastEpic = createToastEpic(deleteUserItemInventory.success, 'Successfully deleted');

export default combineEpics(listUserItemInventoryEpic, deleteUserItemInventoryEpic, deleteUserItemInventoryToastEpic);
