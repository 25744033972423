import { initialListState, ListState } from 'store/types';

export enum ShopSectionPageActions {
  LIST_SHOP_SECTION_PAGE_REQUEST = 'LIST_SHOP_SECTION_PAGE#REQUEST',
  LIST_SHOP_SECTION_PAGE_SUCCESS = 'LIST_SHOP_SECTION_PAGE#SUCCESS',
  LIST_SHOP_SECTION_PAGE_FAILURE = 'LIST_SHOP_SECTION_PAGE#FAILURE',

  READ_SHOP_SECTION_PAGE_REQUEST = 'READ_SHOP_SECTION_PAGE#REQUEST',
  READ_SHOP_SECTION_PAGE_FAILURE = 'READ_SHOP_SECTION_PAGE#FAILURE',
  READ_SHOP_SECTION_PAGE_SUCCESS = 'READ_SHOP_SECTION_PAGE#SUCCESS',

  CREATE_SHOP_SECTION_PAGE_REQUEST = 'CREATE_SHOP_SECTION_PAGE#REQUEST',
  CREATE_SHOP_SECTION_PAGE_SUCCESS = 'CREATE_SHOP_SECTION_PAGE#SUCCESS',
  CREATE_SHOP_SECTION_PAGE_FAILURE = 'CREATE_SHOP_SECTION_PAGE#FAILURE',

  UPDATE_SHOP_SECTION_PAGE_REQUEST = 'UPDATE_SHOP_SECTION_PAGE#REQUEST',
  UPDATE_SHOP_SECTION_PAGE_SUCCESS = 'UPDATE_SHOP_SECTION_PAGE#SUCCESS',
  UPDATE_SHOP_SECTION_PAGE_FAILURE = 'UPDATE_SHOP_SECTION_PAGE#FAILURE',

  DELETE_SHOP_SECTION_PAGE_REQUEST = 'DELETE_SHOP_SECTION_PAGE#REQUEST',
  DELETE_SHOP_SECTION_PAGE_SUCCESS = 'DELETE_SHOP_SECTION_PAGE#SUCCESS',
  DELETE_SHOP_SECTION_PAGE_FAILURE = 'DELETE_SHOP_SECTION_PAGE#FAILURE',

  // Module
  CONNECT_SHOP_SECTION_MODULE_REQUEST = 'CONNECT_SHOP_SECTION_MODULE#REQUEST',
  CONNECT_SHOP_SECTION_MODULE_SUCCESS = 'CONNECT_SHOP_SECTION_MODULE#SUCCESS',
  CONNECT_SHOP_SECTION_MODULE_FAILURE = 'CONNECT_SHOP_SECTION_MODULE#FAILURE',

  EDIT_SHOP_SECTION_MODULE_REQUEST = 'EDIT_SHOP_SECTION_MODULE#REQUEST',
  EDIT_SHOP_SECTION_MODULE_SUCCESS = 'EDIT_SHOP_SECTION_MODULE#SUCCESS',
  EDIT_SHOP_SECTION_MODULE_FAILURE = 'EDIT_SHOP_SECTION_MODULE#FAILURE',

  DISCONNECT_SHOP_SECTION_MODULE_REQUEST = 'DISCONNECT_SHOP_SECTION_MODULE#REQUEST',
  DISCONNECT_SHOP_SECTION_MODULE_SUCCESS = 'DISCONNECT_SHOP_SECTION_MODULE#SUCCESS',
  DISCONNECT_SHOP_SECTION_MODULE_FAILURE = 'DISCONNECT_SHOP_SECTION_MODULE#FAILURE',

  // Schedule
  CREATE_SHOP_SECTION_PAGE_SCHEDULE_REQUEST = 'CREATE_SHOP_SECTION_PAGE_SCHEDULE#REQUEST',
  CREATE_SHOP_SECTION_PAGE_SCHEDULE_SUCCESS = 'CREATE_SHOP_SECTION_PAGE_SCHEDULE#SUCCESS',
  CREATE_SHOP_SECTION_PAGE_SCHEDULE_FAILURE = 'CREATE_SHOP_SECTION_PAGE_SCHEDULE#FAILURE',

  UPDATE_SHOP_SECTION_PAGE_SCHEDULE_REQUEST = 'UPDATE_SHOP_SECTION_PAGE_SCHEDULE#REQUEST',
  UPDATE_SHOP_SECTION_PAGE_SCHEDULE_SUCCESS = 'UPDATE_SHOP_SECTION_PAGE_SCHEDULE#SUCCESS',
  UPDATE_SHOP_SECTION_PAGE_SCHEDULE_FAILURE = 'UPDATE_SHOP_SECTION_PAGE_SCHEDULE#FAILURE',

  DELETE_SHOP_SECTION_PAGE_SCHEDULE_REQUEST = 'DELETE_SHOP_SECTION_PAGE_SCHEDULE#REQUEST',
  DELETE_SHOP_SECTION_PAGE_SCHEDULE_SUCCESS = 'DELETE_SHOP_SECTION_PAGE_SCHEDULE#SUCCESS',
  DELETE_SHOP_SECTION_PAGE_SCHEDULE_FAILURE = 'DELETE_SHOP_SECTION_PAGE_SCHEDULE#FAILURE',

  // Shop Section Page Module Bulk
  BULK_CREATE_SHOP_SECTION_MODULE_REQUEST = 'BULK_CREATE_SHOP_SECTION_MODULE#REQUEST',
  BULK_CREATE_SHOP_SECTION_MODULE_SUCCESS = 'BULK_CREATE_SHOP_SECTION_MODULE#SUCCESS',
  BULK_CREATE_SHOP_SECTION_MODULE_FAILURE = 'BULK_CREATE_SHOP_SECTION_MODULE#FAILURE',

  BULK_DELETE_SHOP_SECTION_MODULE_REQUEST = 'BULK_DELETE_SHOP_SECTION_MODULE#REQUEST',
  BULK_DELETE_SHOP_SECTION_MODULE_SUCCESS = 'BULK_DELETE_SHOP_SECTION_MODULE#SUCCESS',
  BULK_DELETE_SHOP_SECTION_MODULE_FAILURE = 'BULK_DELETE_SHOP_SECTION_MODULE#FAILURE',
}

export const sectionType = [
  'SPECIAL_BANNER',
  'SPECIAL_PACKAGE',
  'GEM_PRODUCTS',
  'SUB_PRODUCTS',
  'BANNER',
  'MAIN_TEXTS',
  'SINGLE_IMAGE',
  'MULTI_IMAGE',
  'PRODUCT_LINK',
  'POPUP_CAROUSEL',
  'CAROUSEL',
  'SUB_TERMS',
  'SUB_PLANS',
  'FOOTER',
  'VIP_BANNER',
  'SUB_HEADERS',
  'SUB_BENEFITS',
  'PACKAGE_POPUP_PRODUCTS_A',
  'PACKAGE_POPUP_PRODUCTS_B',
  'CAMPAIGN_REWARDS',
] as const;
export type ShopSectionType = typeof sectionType[number];

export type AdditionalProps = {};
export type ShopSectionPageFormValues = {
  id: number;
  sectionType: ShopSectionType;
  name: string;
  description: string;
  sectionTitleKey: string;
  anchorName: string;
  enabled: boolean;
  sectionTitleKeyAsString?: boolean;
  additionalProps: AdditionalProps;
};

export type ShopSectionPageRequestData = ShopSectionPageFormValues;
export type ShopSectionPageResponseData = ShopSectionDetail;

// - Module -
export type ShopSectionModule = {
  linkId: number;
  type: string;
  moduleId: number;
  moduleType: string;
  name: string;
  description: string;
  evalOrder: number;
  enabled?: boolean;
};

export type ShopSectionModuleFormValue = {
  sectionId: number;
  moduleId: number;
  evalOrder: number;
};
export type ShopSectionModuleRequestData = ShopSectionModuleFormValue;
export type ShopSectionModuleResponseData = ShopSectionModule;

// - Schedule -
export type ShopSectionSchedule = {
  id: number;
  segmentCode: string;
  startDate?: string;
  endDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  enabled: boolean;
};

export type ShopSectionScheduleFormValues = Omit<ShopSectionSchedule, 'id'>;
export type ShopSectionScheduleCreateRequestData = ShopSectionScheduleFormValues;
export type ShopSectionScheduleCreateResponseData = ShopSectionSchedule;
export type ShopSectionScheduleUpdateRequestData = ShopSectionScheduleFormValues;
export type ShopSectionScheduleUpdateResponseData = ShopSectionSchedule;

// - Section Bulk -
export type ShopSectionModuleBulkCreateRequestData = {
  links: ShopSectionModuleRequestData[];
};
export type ShopSectionModuleBulkCreateResponseData = ShopSectionModuleFormValue & { linkId: number };

// - State -
export type ShopSectionListItem = Omit<ShopSectionPageFormValues, 'anchorName'> & {
  schedules: ShopSectionSchedule[];
};

export interface ShopSectionDetail extends ShopSectionPageFormValues {
  modules: ShopSectionModule[];
  schedules: ShopSectionSchedule[];
}
export interface ShopSectionState {
  list: ListState<ShopSectionListItem>;
  detail?: ShopSectionDetail;
}

export const initialState = {
  list: initialListState,
  detail: undefined,
};
