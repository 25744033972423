import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic, createUpdateEpic } from '../utils';

import {
  createNotificationContent,
  listNotificationContent,
  readNotificationContent,
  refreshNotificationContent,
  updateNotificationContent,
} from './actions';
import {
  createNotificationContentApi,
  listNotificationContentApi,
  readNotificationContentApi,
  refreshNotificationContentApi,
  updateNotificationContentApi,
} from './api';

const createNotificationContentEpic = createPostEpic(createNotificationContent, createNotificationContentApi);
const readNotificationContentEpic = createReadEpic(readNotificationContent, readNotificationContentApi);
const updateNotificationContentEpic = createUpdateEpic(updateNotificationContent, updateNotificationContentApi);
const listNotificationContentEpic = createListEpic(listNotificationContent, listNotificationContentApi);
const refreshNotificationContentEpic = createPostEpic(refreshNotificationContent, refreshNotificationContentApi);

const updateNotificationContentToastEpic = createToastEpic(updateNotificationContent.success, 'Successfully saved');

const createNotificationContentToastEpic = createToastEpic(createNotificationContent.success, 'Successfully created');

const refreshNotificationContentToastEpic = createToastEpic(
  refreshNotificationContent.success,
  'Successfully refreshed'
);

export default combineEpics(
  updateNotificationContentToastEpic,
  createNotificationContentToastEpic,
  createNotificationContentEpic,
  readNotificationContentEpic,
  updateNotificationContentEpic,
  listNotificationContentEpic,
  refreshNotificationContentEpic,
  refreshNotificationContentToastEpic
);
