import { createPostApi, createUpdateApi } from 'store/utils';

import {
  TeamMessageLinkSettingCreateRequestData,
  TeamMessageLinkSettingCreateResponseData,
  TeamMessageLinkSettingFormValues,
  TeamMessageLinkSettingUpdateRequestData,
  TeamMessageLinkSettingUpdateResponseData,
} from './types';

export const createTeamMessageLinkSettingApi = createPostApi<
  TeamMessageLinkSettingFormValues,
  TeamMessageLinkSettingCreateRequestData,
  TeamMessageLinkSettingCreateResponseData
>('/team_message/:id/link_setting');

export const updateTeamMessageLinkSettingApi = createUpdateApi<
  TeamMessageLinkSettingFormValues,
  TeamMessageLinkSettingUpdateRequestData,
  TeamMessageLinkSettingUpdateResponseData
>('/team_message/:id/link_setting');
