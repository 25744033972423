import { initialListState, ListState } from 'store/types';

export enum UserItemInventoryActions {
  LIST_USER_ITEM_INVENTORY_REQUEST = 'LIST_USER_ITEM_INVENTORY#REQUEST',
  LIST_USER_ITEM_INVENTORY_SUCCESS = 'LIST_USER_ITEM_INVENTORY#SUCCESS',
  LIST_USER_ITEM_INVENTORY_FAILURE = 'LIST_USER_ITEM_INVENTORY#FAILURE',

  DELETE_USER_ITEM_INVENTORY_REQUEST = 'DELETE_USER_ITEM_INVENTORY#REQUEST',
  DELETE_USER_ITEM_INVENTORY_SUCCESS = 'DELETE_USER_ITEM_INVENTORY#SUCCESS',
  DELETE_USER_ITEM_INVENTORY_FAILURE = 'DELETE_USER_ITEM_INVENTORY#FAILURE',
}

export const initialState: UserItemInventoryState = { list: initialListState };

export interface UserItemInventoryState {
  list: ListState<UserItemInventoryListItem>;
}

export interface UserItemInventoryListItem {
  checked?: boolean;
  id: number;
  itemId: string;
  leftCount: number;
  useDate?: string;
  expireDate?: string;
  expired: string;
  deleted: boolean;
  gainDate: string;
  gainType: string;
  gainTypeDetail: string;
}
