import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as matchFilterOptionActions from './actions';
import { initialState, MatchFilterOptionState } from './types';

const matchFilterOption = createReducer<MatchFilterOptionState, RootAction>(initialState)
  .handleAction(matchFilterOptionActions.listMatchFilterOption.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(matchFilterOptionActions.listMatchFilterOption.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default matchFilterOption;
