import { combineEpics } from 'redux-observable';

import ageManageEpic from './ageManage/epics';
import agentHistoryEpic from './agentHistory/epics';
import agentManageEpic from './agentManage/epics';
import bannerSettingEpic from './bannerSetting/epics';
import bannerSettingMapEntryEpic from './bannerSettingMapEntry/epics';
import blockPhoneManageEpic from './blockPhoneManage/epics';
import bookmarkEpic from './bookmark/epics';
import convertUserKeyEpic from './convertUserKey/epics';
import countryManageEpic from './countryManage/epics';
import decoColorPresetEpic from './decoColorPreset/epics';
import decoItemEpic from './decoItem/epics';
import decoResourceEpic from './decoResource/epics';
import decoSettingEpic from './decoSetting/epics';
import decoSettingProductEpic from './decoSettingProduct/epics';
import decoSettingScheduleEpic from './decoSettingSchedule/epics';
import downloadableContentEpic from './downloadableContent/epics';
import expressionSegmentEpic from './expressionSegment/epics';
import fileS3UploadEpic from './fileS3Upload/epics';
import fileUploadEpic from './fileUpload/epics';
import simulationUploadEpic from './simulationUpload/epics';
import fileSettingEpic from './fileSetting/epics';
import gemSettingEpic from './gemSetting/epics';
import gemSettingLimitKeyEpic from './gemSettingLimitKey/epics';
import gemSettingMapEntryEpic from './gemSettingMapEntry/epics';
import gemSettingProductEpic from './gemSettingProduct/epics';
import genderManageEpic from './genderManage/epics';
import iabProductEpic from './iabProduct/epics';
import imageSettingEpic from './imageSetting/epics';
import itemCategoryEpic from './itemCategory/epics';
import itemProductEpic from './itemProduct/epics';
import localizationKeyEpic from './localizationKey/epics';
import manualSegmentEpic from './manualSegment/epics';
import matchFilterEpic from './matchFilter/epics';
import matchFilterConfigEpic from './matchFilterConfig/epics';
import matchFilterOptionEpic from './matchFilterOption/epics';
import matchFilterOptionListingEpic from './matchFilterOptionListing/epics';
import matchFilterSegmentEpic from './matchFilterSegment/epics';
import matchFilterSettingEpic from './matchFilterSetting/epics';
import matchTagManageEpic from './matchTagManage/epics';
import moniNotice from './moniNotice/epics';
import noticeEpic from './notice/epics';
import noticePopupButtonEpic from './noticePopupButton/epics';
import noticePopupCarouselEpic from './noticePopupCarousel/epics';
import noticePopupScheduleEpic from './noticePopupSchedule/epics';
import productEpic from './product/epics';
import productIconEpic from './productIcons/epics';
import profileManageEpic from './profileManage/epics';
import promotionContentEpic from './promotionContent/epics';
import promotionSettingEpic from './promotionSetting/epics';
import punishEpic from './punish/epics';
import punishmentEpic from './punishment/epics';
import matataSuspensionHistoryEpic from './matataSuspensionHistory/epics';
import matataDeviceUnbindingEpic from './matataDeviceUnbinding/epics';
import realtimeMonitoringEpic from './realtimeMonitoring/epics';
import settingEpic from './setting/epics';
import specialPromotionSettingEpic from './specialPromotionSetting/epics';
import specialPromotionSettingLimitKeyEpic from './specialPromotionSettingLimitKey/epics';
import specialPromotionSettingMapEntryEpic from './specialPromotionSettingMapEntry/epics';
import specialPromotionSettingProductEpic from './specialPromotionSettingProduct/epics';
import stringLocalizationEpic from './stringLocalization/epic';
import teamMatchCampaignEpic from './teamMatchCampaign/epics';
import teamMatchProfileEpic from './teamMatchProfile/epics';
import teamMessageEpic from './teamMessage/epics';
import teamMessageContentSettingEpic from './teamMessageContentSetting/epics';
import teamMessageImageSettingEpic from './teamMessageImageSetting/epics';
import teamMessageLinkSettingEpic from './teamMessageLinkSetting/epics';
import userEpic from './user/epics';
import userItemInventoryEpic from './userItemInventory/epics';
import userSegmentEpic from './userSegment/epics';
import waterSlideBannerEpic from './waterSlideBanner/epics';
import shopMainEpic from './shopMainPage/epics';
import shopModuleEpic from './shopModulePage/epics';
import shopSectionEpic from './shopSectionPage/epics';
import shopTabEpic from './shopTabPage/epics';
import shopDetailEpic from './shopDetailPage/epics';
import shopModuleGroupEpic from './shopModuleGroup/epics';
import limitedEditionEpic from './limitedEditionPage/epics';
import friendshipEpic from './friendship/epics';
import shopEntryBannerEpic from './shopEntryBanner/epics';
import shopEntryBannerScheduleEpic from './shopEntryBannerSchedule/epics';
import promotionPublishEpic from './promotionPublish/epics';
import promotionPublishHistoryEpic from './promotionPublishHistory/epics';
import remoteConfigEpic from './remoteConfig/epics';
import gemProductTierEpic from './gemProductTier/epics';
import gemProductTierScheduleEpic from './gemProductTierSchedule/epics';
import messageBannerEpic from './messageBanner/epics';
import abTestEpic from './abTest/epics';
import abTestCriteriaEpic from './abTestCriteria/epics';
import criteriaSegmentEpic from './criteriaSegment/epics';
import notificationCenterBannerEpic from './notificationCenterBanner/epics';
import notificationCenterBannerScheduleEpic from './notificationCenterBannerSchedule/epics';
import notificationContentEpic from './notificationContent/epics';
import pushContentEpic from './pushContent/epics';
import gemProvideEpic from './gemProvide/epics';
import itemProvideEpic from './itemProductProvide/epics';
import itemPackageProvideEpic from './itemPackageProvide/epics';
import configStoreEpic from './configStore/epics';
import gemWithdrawEpic from './gemWithdraw/epics';
import gemCancelPurchaseEpic from './gemCancelPurchase/epics';
import invalidatedPurchaseEpic from './invalidatedPurchase/epics';
import visitorConfigEpic from './visitorConfig/epics';
import visitorAbTestEpic from './visitorAbTest/epics';
import visitorAbTestCriteriaEpic from './visitorAbTestCriteria/epics';
import visitorSegmentEpic from './visitorSegment/epics';
import matatInvalidatedPurchaseEpic from './matataInvalidatedPurchase/epics';
import matataInvalidatedPurchaseBySenderEpic from './matataInvalidatedPurchaseBySender/epics';
import matataInvalidatedPurchaseByReceiverEpic from './matataInvalidatedPurchaseByReceiver/epics';
import promotionScheduleEpic from './promotionSchedule/epics';
import promotionScheduleTargetEpic from './promotionScheduleTarget/epics';
import promotionScheduleTestEpic from './promotionScheduleTest/epics';
import bigquerySegmentEpic from './bigquerySegment/epics';
import bigquerySegmentDeclarationEpic from './bigquerySegmentDeclaration/epics';

import webDecoEffectEpic from './webDecoEffect/epics';
import webDecoSettingEpic from './webDecoSetting/epics';
import webDecoSettingEffectEpic from './webDecoSettingEffect/epics';
import webDecoSettingScheduleEpic from './webDecoSettingSchedule/epics';
import purposeEpic from './purpose/epics';
import purposeScheduleEpic from './purposeSchedule/epics';
import purposeOptionEpic from './purposeOption/epics';
import appStoreReviewEpic from './appStoreReview/epics';
import mlConfigModelEpic from './mlConfigModel/epics';
import mlConfigModelSegmentEpic from './mlConfigModelSegment/epics';
import mlConfigFeatureEpic from './mlConfigFeature/epics';
import mlConfigFeatureSegmentEpic from './mlConfigFeatureSegment/epics';
import promotionContentGroupEpic from './promotionContentGroup/epics';

const epics = [
  userEpic,
  gemSettingEpic,
  gemSettingMapEntryEpic,
  gemSettingProductEpic,
  gemSettingLimitKeyEpic,
  specialPromotionSettingEpic,
  specialPromotionSettingMapEntryEpic,
  specialPromotionSettingProductEpic,
  specialPromotionSettingLimitKeyEpic,
  productIconEpic,
  iabProductEpic,
  productEpic,
  userSegmentEpic,
  imageSettingEpic,
  fileSettingEpic,
  fileUploadEpic,
  simulationUploadEpic,
  bannerSettingEpic,
  bannerSettingMapEntryEpic,
  expressionSegmentEpic,
  ageManageEpic,
  genderManageEpic,
  countryManageEpic,
  matchTagManageEpic,
  convertUserKeyEpic,
  blockPhoneManageEpic,
  profileManageEpic,
  realtimeMonitoringEpic,
  localizationKeyEpic,
  stringLocalizationEpic,
  bookmarkEpic,
  settingEpic,
  noticeEpic,
  noticePopupButtonEpic,
  noticePopupCarouselEpic,
  noticePopupScheduleEpic,
  manualSegmentEpic,
  userItemInventoryEpic,
  moniNotice,
  matchFilterEpic,
  matchFilterConfigEpic,
  matchFilterOptionListingEpic,
  matchFilterOptionEpic,
  matchFilterSegmentEpic,
  matchFilterSettingEpic,
  downloadableContentEpic,
  itemCategoryEpic,
  itemProductEpic,
  decoItemEpic,
  decoResourceEpic,
  decoSettingEpic,
  decoSettingProductEpic,
  decoSettingScheduleEpic,
  teamMatchCampaignEpic,
  teamMatchProfileEpic,
  fileS3UploadEpic,
  promotionSettingEpic,
  promotionContentEpic,
  teamMessageEpic,
  teamMessageImageSettingEpic,
  teamMessageContentSettingEpic,
  teamMessageLinkSettingEpic,
  agentManageEpic,
  agentHistoryEpic,
  punishEpic,
  punishmentEpic,
  matataSuspensionHistoryEpic,
  matataDeviceUnbindingEpic,
  decoColorPresetEpic,
  waterSlideBannerEpic,
  shopMainEpic,
  shopModuleEpic,
  shopSectionEpic,
  shopModuleGroupEpic,
  shopTabEpic,
  shopDetailEpic,
  limitedEditionEpic,
  friendshipEpic,
  shopEntryBannerEpic,
  shopEntryBannerScheduleEpic,
  promotionPublishEpic,
  promotionPublishHistoryEpic,
  remoteConfigEpic,
  gemProductTierEpic,
  gemProductTierScheduleEpic,
  messageBannerEpic,
  abTestEpic,
  abTestCriteriaEpic,
  criteriaSegmentEpic,
  notificationCenterBannerEpic,
  notificationCenterBannerScheduleEpic,
  notificationContentEpic,
  pushContentEpic,
  gemProvideEpic,
  itemProvideEpic,
  itemPackageProvideEpic,
  configStoreEpic,
  gemWithdrawEpic,
  gemCancelPurchaseEpic,
  invalidatedPurchaseEpic,
  visitorConfigEpic,
  visitorAbTestEpic,
  visitorAbTestCriteriaEpic,
  visitorSegmentEpic,
  matatInvalidatedPurchaseEpic,
  matataInvalidatedPurchaseBySenderEpic,
  matataInvalidatedPurchaseByReceiverEpic,
  promotionScheduleEpic,
  promotionScheduleTargetEpic,
  bigquerySegmentEpic,
  bigquerySegmentDeclarationEpic,
  webDecoEffectEpic,
  webDecoSettingEpic,
  webDecoSettingEffectEpic,
  webDecoSettingScheduleEpic,
  purposeEpic,
  purposeScheduleEpic,
  purposeOptionEpic,
  promotionScheduleTestEpic,
  appStoreReviewEpic,
  mlConfigModelEpic,
  mlConfigModelSegmentEpic,
  mlConfigFeatureEpic,
  mlConfigFeatureSegmentEpic,
  promotionContentGroupEpic,
];

const rootEpic = combineEpics(...epics);

export default rootEpic;
