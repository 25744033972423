import { combineEpics } from 'redux-observable';

import { createToastEpic, createAsyncEpic } from 'store/utils';

import { refreshMessageBanner } from './actions';

import { refreshMessageBannerApi } from './api';

const refreshMessageBannerToastEpic = createToastEpic(refreshMessageBanner.success, 'Successfully refreshed server');

const refreshMessageBannerEpic = createAsyncEpic(refreshMessageBanner, refreshMessageBannerApi);

export default combineEpics(refreshMessageBannerToastEpic, refreshMessageBannerEpic);
