import { UseFormMethods } from 'react-hook-form';
import { PayloadAction, TypeConstant } from 'typesafe-actions';

import { PathGenerator } from 'utils/action';

export interface ListState<Item extends any> {
  page: number;
  pageSize: number;
  totalCount: number;
  data: Item[];
}

export type FlatListState<Item extends any> = Item[];

export const initialListState: ListState<any> = {
  page: 1,
  pageSize: 100,
  totalCount: 0,
  data: [],
};

export interface Query {
  [key: string]: string;
}

export type Search = Query;

export interface BaseRequestPayload {
  pathGenerator?: PathGenerator<any>;
  extraQuery?: Query;
}

export interface ListRequestPayload extends BaseRequestPayload {
  page: number;
  pageSize: number;
  ordering?: string;
  search?: Search;
}

export interface FlatListRequestPayload extends BaseRequestPayload {
  ordering?: string;
  search?: Search;
}

export interface PayloadMeta<P, M = undefined> {
  payload: P;
  meta?: M;
}

export interface ReadRequestPayload extends BaseRequestPayload {
  id: string;
}

export type FormProperty<FormValues> = Pick<UseFormMethods<FormValues>, 'reset' | 'setValue'>; // Performance issue
export interface FormRequestPayload<FormValues, RequestPayload = FormValues> extends BaseRequestPayload {
  data: any;
  form?: FormProperty<FormValues>;
  id?: string;
  onSuccess?: (request: RequestPayload, response: any, form?: FormProperty<FormValues>) => void;
  onFailure?: (e: Error) => void;
}

export interface FormRequestParamPayload<FormValues, RequestPayload = FormValues> extends BaseRequestPayload {
  data: any;
  params?: Record<string, string>;
  form?: FormProperty<FormValues>;
  id?: string;
  onSuccess?: (request: RequestPayload, response: any, form?: FormProperty<FormValues>) => void;
  onFailure?: (e: Error) => void;
}

export interface UploadRequestPayload<RequestPayload> extends BaseRequestPayload {
  data: RequestPayload[];
  onSuccess?: (request: RequestPayload[], response: any) => void;
  onFailure?: (e: Error) => void;
}

export interface DeleteRequestPayload extends BaseRequestPayload {
  id: string;
  onSuccess?: (deletedId: string) => void;
}

export interface DeleteParamRequestPayload extends BaseRequestPayload {
  id: string;
  params?: Record<string, string>;
  onSuccess?: (deletedId: string) => void;
}

export type DeleteRequestAction = PayloadAction<TypeConstant, DeleteRequestPayload>;

export type DeleteSuccessPayload = string;

export interface DeletePayloadDataType {
  [key: string]: number[] | string[] | number | string;
}

export interface DeleteBodyRequestPayload extends BaseRequestPayload {
  data: DeletePayloadDataType;
  onSuccess?: (data: DeletePayloadDataType) => void;
}

export type DeleteBodySuccessPayload = DeletePayloadDataType | undefined;
