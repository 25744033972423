import { createPostApi, createUpdateApi } from 'store/utils';

import {
  TeamMessageContentSettingCreateRequestData,
  TeamMessageContentSettingCreateResponseData,
  TeamMessageContentSettingFormValues,
  TeamMessageContentSettingUpdateRequestData,
  TeamMessageContentSettingUpdateResponseData,
} from './types';

export const createTeamMessageContentSettingApi = createPostApi<
  TeamMessageContentSettingFormValues,
  TeamMessageContentSettingCreateRequestData,
  TeamMessageContentSettingCreateResponseData
>('/team_message/:id/content_setting');

export const updateTeamMessageContentSettingApi = createUpdateApi<
  TeamMessageContentSettingFormValues,
  TeamMessageContentSettingUpdateRequestData,
  TeamMessageContentSettingUpdateResponseData
>('/team_message/:id/content_setting');
