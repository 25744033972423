import { combineEpics } from 'redux-observable';

import {
  createNoticePopupButton,
  deleteNoticePopupButton,
  updateNoticePopupButton,
} from 'store/noticePopupButton/actions';
import {
  createNoticePopupButtonApi,
  deleteNoticePopupButtonApi,
  updateNoticePopupButtonApi,
} from 'store/noticePopupButton/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createNoticePopupButtonToastEpic = createToastEpic(
  createNoticePopupButton.success,
  'Successfully created Button Setting'
);

const updateNoticePopupButtonToastEpic = createToastEpic(
  updateNoticePopupButton.success,
  'Successfully saved Button Setting'
);

const deleteNoticePopupButtonToastEpic = createToastEpic(
  deleteNoticePopupButton.success,
  'Successfully deleted Button Setting'
);

const createNoticePopupButtonEpic = createPostEpic(createNoticePopupButton, createNoticePopupButtonApi);

const updateNoticePopupButtonEpic = createUpdateEpic(updateNoticePopupButton, updateNoticePopupButtonApi);

const deleteNoticePopupButtonEpic = createDeleteEpic(deleteNoticePopupButton, deleteNoticePopupButtonApi);

export default combineEpics(
  createNoticePopupButtonToastEpic,
  updateNoticePopupButtonToastEpic,
  deleteNoticePopupButtonToastEpic,
  createNoticePopupButtonEpic,
  updateNoticePopupButtonEpic,
  deleteNoticePopupButtonEpic
);
