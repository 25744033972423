import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import { InvalidatedPurchase, InvalidatedPurchaseActions as Actions } from './types';

export const readInvalidatedPurchase = createAsyncAction(
  Actions.READ_INVALIDATED_PURCHASE_REQUEST,
  Actions.READ_INVALIDATED_PURCHASE_SUCCESS,
  Actions.READ_INVALIDATED_PURCHASE_FAILURE
)<ReadRequestPayload, InvalidatedPurchase, AxiosError>();

export const listInvalidatedPurchase = createAsyncAction(
  Actions.LIST_INVALIDATED_PURCHASE_REQUEST,
  Actions.LIST_INVALIDATED_PURCHASE_SUCCESS,
  Actions.LIST_INVALIDATED_PURCHASE_FAILURE
)<ListRequestPayload, ListState<InvalidatedPurchase>, AxiosError>();
