import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteBodyRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ManualSegmentActions as Actions,
  ManualSegment,
  ManualSegmentCreateRequestData,
  ManualSegmentFormValues,
  ManualSegmentUpdateRequestData,
} from './types';

export const listManualSegment = createAsyncAction(
  Actions.LIST_MANUAL_SEGMENTS_REQUEST,
  Actions.LIST_MANUAL_SEGMENTS_SUCCESS,
  Actions.LIST_MANUAL_SEGMENTS_FAILURE
)<ListRequestPayload, ListState<ManualSegment>, AxiosError>();

export const readManualSegment = createAsyncAction(
  Actions.READ_MANUAL_SEGMENT_REQUEST,
  Actions.READ_MANUAL_SEGMENT_SUCCESS,
  Actions.READ_MANUAL_SEGMENT_FAILURE
)<ReadRequestPayload, ManualSegment, AxiosError>();

export const createManualSegment = createAsyncAction(
  Actions.CREATE_MANUAL_SEGMENTS_REQUEST,
  Actions.CREATE_MANUAL_SEGMENTS_SUCCESS,
  Actions.CREATE_MANUAL_SEGMENTS_FAILURE
)<FormRequestPayload<ManualSegmentFormValues, ManualSegmentCreateRequestData>, undefined, AxiosError>();

export const updateManualSegment = createAsyncAction(
  Actions.UPDATE_MANUAL_SEGMENTS_REQUEST,
  Actions.UPDATE_MANUAL_SEGMENTS_SUCCESS,
  Actions.UPDATE_MANUAL_SEGMENTS_FAILURE
)<FormRequestPayload<ManualSegmentFormValues, ManualSegmentUpdateRequestData>, ManualSegment, AxiosError>();

export const deleteManualSegments = createAsyncAction(
  Actions.DELETE_MANUAL_SEGMENTS_REQUEST,
  Actions.DELETE_MANUAL_SEGMENTS_SUCCESS,
  Actions.DELETE_MANUAL_SEGMENTS_FAILURE
)<DeleteBodyRequestPayload, undefined, AxiosError>();
