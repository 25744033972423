import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import {
  WaterSlideBanner,
  WaterSlideBannerCreateRequestData,
  WaterSlideBannerCreateResponseData,
} from 'store/waterSlideBanner/types';

const BASE_PATH = '/banners/mirror';
export const createWaterSlideBannerApi = createPostApi<
  WaterSlideBannerCreateRequestData,
  WaterSlideBannerCreateResponseData
>(BASE_PATH);

export const readWaterSlideBannerApi = createReadApi<WaterSlideBanner>(`${BASE_PATH}/:id`);
export const listWaterSlideBannerApi = createListApi<WaterSlideBanner>(BASE_PATH);

export const updateWaterSlideBannerApi = createUpdateApi<
  WaterSlideBannerCreateRequestData,
  WaterSlideBannerCreateResponseData
>(`${BASE_PATH}/:id`);

export const deleteWaterSlideBannerApi = createDeleteApi(`${BASE_PATH}/:id`);

export const refreshMessageBannerApi = createPostApi(`${BASE_PATH}/refresh`);
