import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  GemProductTierActions as Actions,
  GemProductTierDetail,
  GemProductTierFormValues,
  GemProductTierListItem,
  GemProductTierRequestData,
  GemProductTierResponseData,
} from './types';

export const listGemProductTier = createAsyncAction(
  Actions.LIST_GEM_PRODUCT_TIERS_REQUEST,
  Actions.LIST_GEM_PRODUCT_TIERS_SUCCESS,
  Actions.LIST_GEM_PRODUCT_TIERS_FAILURE
)<ListRequestPayload, ListState<GemProductTierListItem>, AxiosError>();

export const updateGemProduct = createAsyncAction(
  Actions.UPDATE_GEM_PRODUCT_REQUEST,
  Actions.UPDATE_GEM_PRODUCT_SUCCESS,
  Actions.UPDATE_GEM_PRODUCT_FAILURE
)<FormRequestPayload<GemProductTierFormValues, GemProductTierRequestData>, undefined, AxiosError>();

export const updateGemProductTier = createAsyncAction(
  Actions.UPDATE_GEM_PRODUCT_TIER_REQUEST,
  Actions.UPDATE_GEM_PRODUCT_TIER_SUCCESS,
  Actions.UPDATE_GEM_PRODUCT_TIER_FAILURE
)<FormRequestPayload<GemProductTierFormValues, GemProductTierRequestData>, undefined, AxiosError>();

export const readGemProductTier = createAsyncAction(
  Actions.READ_GEM_PRODUCT_TIER_REQUEST,
  Actions.READ_GEM_PRODUCT_TIER_SUCCESS,
  Actions.READ_GEM_PRODUCT_TIER_FAILURE
)<ReadRequestPayload, GemProductTierDetail, AxiosError>();

export const createGemProductTier = createAsyncAction(
  Actions.CREATE_GEM_PRODUCT_TIER_REQUEST,
  Actions.CREATE_GEM_PRODUCT_TIER_SUCCESS,
  Actions.CREATE_GEM_PRODUCT_TIER_FAILURE
)<FormRequestPayload<GemProductTierFormValues, GemProductTierRequestData>, GemProductTierResponseData, AxiosError>();

export const refreshGemProductTier = createAsyncAction(
  Actions.REFRESH_GEM_PRODUCT_TIER_REQUEST,
  Actions.REFRESH_GEM_PRODUCT_TIER_SUCCESS,
  Actions.REFRESH_GEM_PRODUCT_TIER_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
