import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  VisitorAbTestDetail,
  VisitorAbTestListItem,
  VisitorAbTestRequestData,
  VisitorAbTestResponseData,
} from './types';

export const createVisitorAbTestApi = createPostApi<VisitorAbTestRequestData, VisitorAbTestResponseData>(
  '/visitors/abtests'
);

export const updateVisitorAbTestApi = createUpdateApi<
  VisitorAbTestRequestData,
  VisitorAbTestRequestData,
  VisitorAbTestResponseData
>('/visitors/abtests/:id');

export const listVisitorAbTestApi = createListApi<VisitorAbTestListItem>('/visitors/abtests');

export const readVisitorAbTestApi = createReadApi<VisitorAbTestDetail>('/visitors/abtests/:id');
