import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  ShopEntryBannerCreateRequestData,
  ShopEntryBannerCreateRequestFormValues,
  ShopEntryBannerCreateSuccessData,
  ShopEntryBannerDetail,
  ShopEntryBannerListItem,
  ShopEntryBannerUpdateRequestData,
  ShopEntryBannerUpdateRequestFormValues,
  ShopEntryBannerUpdateSuccessData,
} from './types';

export const listShopEntryBannerApi = createListApi<ShopEntryBannerListItem>('/shop_entry_banners/settings');
export const createShopEntryBannerApi = createPostApi<
  ShopEntryBannerCreateRequestFormValues,
  ShopEntryBannerCreateRequestData,
  ShopEntryBannerCreateSuccessData
>('/shop_entry_banners/settings');
export const readShopEntryBannerApi = createReadApi<ShopEntryBannerDetail>('/shop_entry_banners/settings');
export const updateShopEntryBannerApi = createUpdateApi<
  ShopEntryBannerUpdateRequestFormValues,
  ShopEntryBannerUpdateRequestData,
  ShopEntryBannerUpdateSuccessData
>('/shop_entry_banners/settings/:id');
export const refreshShopEntryBannerApi = createPostApi('/shop_entry_banners/refresh');
