import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  PurposeActions as Actions,
  PurposeCreateRequestData,
  PurposeCreateResponseData,
  PurposeItemDetail,
  PurposeFormValues,
  PurposeListItem,
  PurposeUpdateRequestData,
  PurposeUpdateResponseData,
} from './types';

export const listPurpose = createAsyncAction(
  Actions.LIST_PURPOSE_REQUEST,
  Actions.LIST_PURPOSE_SUCCESS,
  Actions.LIST_PURPOSE_FAILURE
)<ListRequestPayload, ListState<PurposeListItem>, AxiosError>();

export const createPurpose = createAsyncAction(
  Actions.CREATE_PURPOSE_REQUEST,
  Actions.CREATE_PURPOSE_SUCCESS,
  Actions.CREATE_PURPOSE_FAILURE
)<FormRequestPayload<PurposeFormValues, PurposeCreateRequestData>, PurposeCreateResponseData, AxiosError>();

export const updatePurpose = createAsyncAction(
  Actions.UPDATE_PURPOSE_REQUEST,
  Actions.UPDATE_PURPOSE_SUCCESS,
  Actions.UPDATE_PURPOSE_FAILURE
)<FormRequestPayload<PurposeFormValues, PurposeUpdateRequestData>, PurposeUpdateResponseData, AxiosError>();

export const readPurpose = createAsyncAction(
  Actions.READ_PURPOSE_REQUEST,
  Actions.READ_PURPOSE_SUCCESS,
  Actions.READ_PURPOSE_FAILURE
)<ReadRequestPayload, PurposeItemDetail, AxiosError>();
