export enum MatchFilterConfigActions {
  CREATE_MATCH_FILTER_CONFIG_REQUEST = 'CREATE_MATCH_FILTER_CONFIG#REQUEST',
  CREATE_MATCH_FILTER_CONFIG_SUCCESS = 'CREATE_MATCH_FILTER_CONFIG#SUCCESS',
  CREATE_MATCH_FILTER_CONFIG_FAILURE = 'CREATE_MATCH_FILTER_CONFIG#FAILURE',

  UPDATE_MATCH_FILTER_CONFIG_REQUEST = 'UPDATE_MATCH_FILTER_CONFIG#REQUEST',
  UPDATE_MATCH_FILTER_CONFIG_SUCCESS = 'UPDATE_MATCH_FILTER_CONFIG#SUCCESS',
  UPDATE_MATCH_FILTER_CONFIG_FAILURE = 'UPDATE_MATCH_FILTER_CONFIG#FAILURE',

  DELETE_MATCH_FILTER_CONFIG_REQUEST = 'DELETE_MATCH_FILTER_CONFIG#REQUEST',
  DELETE_MATCH_FILTER_CONFIG_SUCCESS = 'DELETE_MATCH_FILTER_CONFIG#SUCCESS',
  DELETE_MATCH_FILTER_CONFIG_FAILURE = 'DELETE_MATCH_FILTER_CONFIG#FAILURE',
}

export interface MatchFilterConfigFormValue {
  filterId: string;
  optionListingId: string;
  oldSegmentCode?: string | null;
  segmentCode?: string;
  defaultGemCost?: number | null;
  userSegmentCode?: string;
  segmentStartDate?: string;
  segmentEndDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder?: number;
  enabled?: boolean;
}

export type MatchFilterConfigCreateRequestData = Pick<MatchFilterConfigFormValue, 'defaultGemCost'>;

export type MatchFilterConfigCreateResponseData = MatchFilterConfigCreateRequestData;

export type MatchFilterConfigUpdateRequestData = Pick<MatchFilterConfigFormValue, 'oldSegmentCode' | 'defaultGemCost'>;

export type MatchFilterConfigUpdateResponseData = MatchFilterConfigUpdateRequestData;
