import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import * as settingActions from './actions';
import { BookmarkState, initialState } from './types';

const persistConfig = {
  key: 'bookmark',
  storage,
};

const settingReducer = createReducer<BookmarkState>(initialState)
  .handleAction(settingActions.setBookmark, (_state, { payload: { data } }) => ({ list: [...data.list] }))
  .handleAction(settingActions.reorderBookmarks, (state, { payload }) => {
    const list = [...state.list];
    const [removed] = list.splice(payload.sourceIndex, 1);
    list.splice(payload.destinationIndex, 0, removed);
    return { list };
  })
  .handleAction(settingActions.addBookmark, (state, { payload }) => ({ list: [...state.list, payload] }))
  .handleAction(settingActions.removeBookmark, (state, { payload }) => ({
    list: state.list.filter((_bookmark, i) => i !== payload),
  }))
  .handleAction(settingActions.renameBookmark, (state, { payload }) => {
    const list = state.list.map((bookmark) =>
      bookmark.path !== payload.path
        ? bookmark
        : {
            path: bookmark.path,
            name: payload.newName,
          }
    );
    return { list };
  });

export default persistReducer(persistConfig, settingReducer);
