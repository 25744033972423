import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  NoticePopupScheduleActions as Actions,
  NoticePopupScheduleCreateRequestData,
  NoticePopupScheduleCreateSuccessData,
  NoticePopupScheduleUpdateRequestData,
  NoticePopupScheduleUpdateSuccessData,
} from './types';

export const createNoticePopupSchedule = createAsyncAction(
  Actions.CREATE_SCHEDULE_REQUEST,
  Actions.CREATE_SCHEDULE_SUCCESS,
  Actions.CREATE_SCHEDULE_FAILURE
)<FormRequestPayload<NoticePopupScheduleCreateRequestData>, NoticePopupScheduleCreateSuccessData, AxiosError>();

export const updateNoticePopupSchedule = createAsyncAction(
  Actions.UPDATE_SCHEDULE_REQUEST,
  Actions.UPDATE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SCHEDULE_FAILURE
)<FormRequestPayload<NoticePopupScheduleUpdateRequestData>, NoticePopupScheduleUpdateSuccessData, AxiosError>();

export const deleteNoticePopupSchedule = createAsyncAction(
  Actions.DELETE_SCHEDULE_REQUEST,
  Actions.DELETE_SCHEDULE_SUCCESS,
  Actions.DELETE_SCHEDULE_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
