import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createSpecialPromotionSetting,
  deleteSpecialPromotionSetting,
  listSpecialPromotionSetting,
  readSpecialPromotionSetting,
  refreshSpecialPromotionSetting,
  updateSpecialPromotionSetting,
} from './actions';
import {
  createSpecialPromotionSettingApi,
  deleteSpecialPromotionSettingApi,
  listSpecialPromotionSettingApi,
  readSpecialPromotionSettingApi,
  refreshSpecialPromotionSettingApi,
  updateSpecialPromotionSettingApi,
} from './api';

const updateSpecialPromotionSettingToastEpic = createToastEpic(
  updateSpecialPromotionSetting.success,
  'Successfully saved'
);

const createSpecialPromotionSettingToastEpic = createToastEpic(
  createSpecialPromotionSetting.success,
  'Successfully created'
);

const refreshSpecialPromotionSettingToastEpic = createToastEpic(
  refreshSpecialPromotionSetting.success,
  'Successfully refreshed server'
);

const listSpecialPromotionSettingEpic = createListEpic(listSpecialPromotionSetting, listSpecialPromotionSettingApi);

const createSpecialPromotionSettingEpic = createPostEpic(
  createSpecialPromotionSetting,
  createSpecialPromotionSettingApi
);

const readSpecialPromotionSettingEpic = createReadEpic(readSpecialPromotionSetting, readSpecialPromotionSettingApi);

const updateSpecialPromotionSettingEpic = createUpdateEpic(
  updateSpecialPromotionSetting,
  updateSpecialPromotionSettingApi
);

const deleteSpecialPromotionSettingEpic = createDeleteEpic(
  deleteSpecialPromotionSetting,
  deleteSpecialPromotionSettingApi
);

const refreshSpecialPromotionSettingEpic = createAsyncEpic(
  refreshSpecialPromotionSetting,
  refreshSpecialPromotionSettingApi
);

export default combineEpics(
  updateSpecialPromotionSettingToastEpic,
  createSpecialPromotionSettingToastEpic,
  refreshSpecialPromotionSettingToastEpic,
  listSpecialPromotionSettingEpic,
  createSpecialPromotionSettingEpic,
  readSpecialPromotionSettingEpic,
  updateSpecialPromotionSettingEpic,
  deleteSpecialPromotionSettingEpic,
  refreshSpecialPromotionSettingEpic
);
