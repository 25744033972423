import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as userSegmentActions from './actions';
import { initialState, UserSegmentState } from './types';

const userSegment = createReducer<UserSegmentState, RootAction>(initialState)
  .handleAction(userSegmentActions.listUserSegment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(userSegmentActions.listUserSegment.success, (state, { payload }) => ({
    ...state,
    list: payload,
    detail: undefined,
  }))
  .handleAction(userSegmentActions.listUserSegmentSearch.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    searchList: {
      ...state.searchList,
      page,
      pageSize,
    },
  }))
  .handleAction(userSegmentActions.listUserSegmentSearch.success, (state, { payload }) => ({
    ...state,
    searchList: payload,
  }))
  .handleAction(userSegmentActions.readUserSegment.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default userSegment;
