export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONBINARY = 'NONBINARY',
}

export enum ChildGender {
  agender = 'agender',
  androgyne = 'androgyne',
  androgynous = 'androgynous',
  bigender = 'bigender',
  genderfluid = 'genderfluid',
  gender_nonconforming = 'gender_nonconforming',
  gender_questioning = 'gender_questioning',
  genderqueer = 'genderqueer',
  intersex = 'intersex',
  neutrois = 'neutrois',
  nonbinary = 'nonbinary',
  pangender = 'pangender',
  trans_person = 'trans_person',
  transfeminine = 'transfeminine',
  transmasculine = 'transmasculine',
  two_spirit = 'two_spirit',
}

export enum GenderManageActions {
  UPDATE_GENDER_MANAGE_REQUEST = 'UPDATE_GENDER_MANAGE#REQUEST',
  UPDATE_GENDER_MANAGE_SUCCESS = 'UPDATE_GENDER_MANAGE#SUCCESS',
  UPDATE_GENDER_MANAGE_FAILURE = 'UPDATE_GENDER_MANAGE#FAILURE',
}

export interface GenderManageUpdateRequestFormValues {
  userIds: string;
  gender: Gender;
  childGender: ChildGender;
}

export interface GenderManageUpdateRequestData {
  userIds: string[];
  gender: Gender;
  childGender: ChildGender;
}
