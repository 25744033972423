import { FlatListState, initialListState, ListState } from 'store/types';

export enum ItemCategoryActions {
  CREATE_ITEM_CATEGORY_REQUEST = 'CREATE_ITEM_CATEGORY#REQUEST',
  CREATE_ITEM_CATEGORY_SUCCESS = 'CREATE_ITEM_CATEGORY#SUCCESS',
  CREATE_ITEM_CATEGORY_FAILURE = 'CREATE_ITEM_CATEGORY#FAILURE',

  GET_ITEM_CATEGORY_REQUEST = 'GET_ITEM_CATEGORY#REQUEST',
  GET_ITEM_CATEGORY_SUCCESS = 'GET_ITEM_CATEGORY#SUCCESS',
  GET_ITEM_CATEGORY_FAILURE = 'GET_ITEM_CATEGORY#FAILURE',

  GET_DECO_CATEGORY_REQUEST = 'GET_DECO_CATEGORY#REQUEST',
  GET_DECO_CATEGORY_SUCCESS = 'GET_DECO_CATEGORY#SUCCESS',
  GET_DECO_CATEGORY_FAILURE = 'GET_DECO_CATEGORY#FAILURE',

  UPDATE_ITEM_CATEGORY_REQUEST = 'UPDATE_ITEM_CATEGORY#REQUEST',
  UPDATE_ITEM_CATEGORY_SUCCESS = 'UPDATE_ITEM_CATEGORY#SUCCESS',
  UPDATE_ITEM_CATEGORY_FAILURE = 'UPDATE_ITEM_CATEGORY#FAILURE',
}

export interface DecoCategory {
  decoCategory: string;
  itemGroupIds: string[];
}

export interface ItemCategory {
  decoCategory: string;
  groupId: string;
  categoryId: string;
  subGroup?: string;
  effectRenderer?: string;
  effectCategory?: string;
  multiPurchasable?: boolean;
}

export type ItemCategoryFormValues = ItemCategory;

export type ItemCategoryCreateRequestData = ItemCategory;
export type ItemCategoryUpdateRequestData = ItemCategoryCreateRequestData;

export type ItemCategoryCreateResponseData = ItemCategory;
export type ItemCategoryUpdateResponseData = ItemCategoryCreateResponseData;

export interface ItemCategoryState {
  list: ListState<ItemCategory>;
  decoCategoryList: FlatListState<DecoCategory>;
}

export const initialState: ItemCategoryState = { list: initialListState, decoCategoryList: [] };
