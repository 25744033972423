import { MatchFilterConfigFormValue } from 'store/matchFilterConfig/types';
import { MatchFilterOptionListingFormValues, MatchFilterOptionListingItem } from 'store/matchFilterOptionListing/types';
import { MatchFilterSegment } from 'store/matchFilterSegment/types';
import { initialListState, ListState } from 'store/types';

export enum MatchFilterSettingActions {
  LIST_MATCH_FILTER_SETTINGS_REQUEST = 'LIST_MATCH_FILTER_SETTINGS#REQUEST',
  LIST_MATCH_FILTER_SETTINGS_SUCCESS = 'LIST_MATCH_FILTER_SETTINGS#SUCCESS',
  LIST_MATCH_FILTER_SETTINGS_FAILURE = 'LIST_MATCH_FILTER_SETTINGS#FAILURE',

  READ_MATCH_FILTER_SETTING_REQUEST = 'READ_MATCH_FILTER_SETTING#REQUEST',
  READ_MATCH_FILTER_SETTING_SUCCESS = 'READ_MATCH_FILTER_SETTING#SUCCESS',
  READ_MATCH_FILTER_SETTING_FAILURE = 'READ_MATCH_FILTER_SETTING#FAILURE',
}

export const initialState: MatchFilterSettingState = {
  list: initialListState,
  detail: undefined,
};

export interface MatchFilterSettingState {
  list: ListState<MatchFilterSettingListItem>;
  detail?: MatchFilterSettingDetail;
}

export interface MatchFilterOption {
  optionId: string;
  displayOrder: number;
  paid: boolean;
  useDefaultGemCost: boolean;
  gemCost?: number;
}

export interface MatchFilterSetting extends Omit<MatchFilterSegment, 'description'> {
  filterId: string;
  optionListingId: string;
  defaultGemCost?: number;
  options: MatchFilterOption[];
}

export interface MatchFilterSettingListItem extends MatchFilterSetting {
  option1?: string;
  option2?: string;
  option3?: string;
}

export interface MatchFilterSettingDetail extends MatchFilterSetting {
  options: MatchFilterOptionListingItem[];
}

export interface MatchFilterSettingFormValues extends MatchFilterConfigFormValue, MatchFilterOptionListingFormValues {}
