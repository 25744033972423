export enum MlConfigFeatureSegmentActions {
  CREATE_SEGMENT_REQUEST = 'CREATE_ML_CONFIG_FEATURE_SEGMENT#REQUEST',
  CREATE_SEGMENT_SUCCESS = 'CREATE_ML_CONFIG_FEATURE_SEGMENT#SUCCESS',
  CREATE_SEGMENT_FAILURE = 'CREATE_ML_CONFIG_FEATURE_SEGMENT#FAILURE',

  UPDATE_SEGMENT_REQUEST = 'UPDATE_ML_CONFIG_FEATURE_SEGMENT#REQUEST',
  UPDATE_SEGMENT_SUCCESS = 'UPDATE_ML_CONFIG_FEATURE_SEGMENT#SUCCESS',
  UPDATE_SEGMENT_FAILURE = 'UPDATE_ML_CONFIG_FEATURE_SEGMENT#FAILURE',

  DELETE_SEGMENT_REQUEST = 'DELETE_ML_CONFIG_FEATURE_SEGMENT#REQUEST',
  DELETE_SEGMENT_SUCCESS = 'DELETE_ML_CONFIG_FEATURE_SEGMENT#SUCCESS',
  DELETE_SEGMENT_FAILURE = 'DELETE_ML_CONFIG_FEATURE_SEGMENT#FAILURE',
}

export enum Platform {
  ALL = 'ALL',
  ANDROID = 'ANDROID',
  ANDROID_HUAWEI = 'ANDROID_HUAWEI',
  IOS = 'IOS',
  WEBCLIENT = 'WEBCLIENT',
  UNKNOWN = 'UNKNOWN',
}

export enum Gender {
  ALL = 'ALL',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export interface MlConfigFeatureSegment {
  id: number;
  featureId: number;
  segmentId?: string;
  platform: string;
  gender: string;
}

export type MlConfigFeatureSegmentCreateRequestData = Omit<MlConfigFeatureSegment, 'id'>;
export type MlConfigFeatureSegmentCreateSuccessData = MlConfigFeatureSegment;
export type MlConfigFeatureSegmentUpdateRequestData = Omit<MlConfigFeatureSegment, 'id'>;
export type MlConfigFeatureSegmentUpdateSuccessData = MlConfigFeatureSegment;
