import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as decoWebSettingActions from './actions';
import { WebDecoSettingState, initialState } from './types';

const EFFECT_COUNT_DEFAULT_VALUE = 0;

const webDecoSetting = createReducer<WebDecoSettingState, RootAction>(initialState)
  .handleAction(decoWebSettingActions.listWebDecoSetting.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(decoWebSettingActions.listWebDecoSetting.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(
        ({
          id,
          name,
          schedules,
          effects,
          effectCount: {
            DECO_WEB_BACKGROUND: backgroundCount = EFFECT_COUNT_DEFAULT_VALUE,
            DECO_WEB_BEAUTY: beautyCount = EFFECT_COUNT_DEFAULT_VALUE,
            DECO_WEB_STYLE: styleCount = EFFECT_COUNT_DEFAULT_VALUE,
            DECO_WEB_FILTER: filterCount = EFFECT_COUNT_DEFAULT_VALUE,
          },
        }) => ({
          id,
          name,
          schedules,
          effects,
          backgroundCount,
          beautyCount,
          styleCount,
          filterCount,
        })
      ),
    });

    return newState.toJS() as WebDecoSettingState;
  })
  .handleAction(decoWebSettingActions.readWebDecoSetting.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default webDecoSetting;
