import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload, ListRequestPayload, ListState } from 'store/types';

import {
  ItemProductActions as Actions,
  ItemProductCreateRequestData,
  ItemProductCreateResponseData,
  ItemProductFormValues,
  ItemProductListItem,
  ItemProductProvideCreateRequestData,
  ItemProductProvideCreateResponseData,
  ItemProductProvideFormValues,
  ItemProductProvideUpdateRequestData,
  ItemProductProvideUpdateResponseData,
  ItemProductUpdateRequestData,
  ItemProductUpdateResponseData,
  PeriodicItemProductCreateRequestData,
  PeriodicItemProductCreateResponseData,
  PeriodicItemProductFormValues,
  PeriodicItemProductUpdateRequestData,
  PeriodicItemProductUpdateResponseData,
} from './types';

export const listItemProduct = createAsyncAction(
  Actions.LIST_ITEM_PRODUCTS_REQUEST,
  Actions.LIST_ITEM_PRODUCTS_SUCCESS,
  Actions.LIST_ITEM_PRODUCTS_FAILURE
)<ListRequestPayload, ListState<ItemProductListItem>, AxiosError>();

export const createItemProduct = createAsyncAction(
  Actions.CREATE_ITEM_PRODUCT_REQUEST,
  Actions.CREATE_ITEM_PRODUCT_SUCCESS,
  Actions.CREATE_ITEM_PRODUCT_FAILURE
)<FormRequestPayload<ItemProductFormValues, ItemProductCreateRequestData>, ItemProductCreateResponseData, AxiosError>();

export const updateItemProduct = createAsyncAction(
  Actions.UPDATE_ITEM_PRODUCT_REQUEST,
  Actions.UPDATE_ITEM_PRODUCT_SUCCESS,
  Actions.UPDATE_ITEM_PRODUCT_FAILURE
)<FormRequestPayload<ItemProductFormValues, ItemProductUpdateRequestData>, ItemProductUpdateResponseData, AxiosError>();

export const deleteItemProduct = createAsyncAction(
  Actions.DELETE_ITEM_PRODUCT_REQUEST,
  Actions.DELETE_ITEM_PRODUCT_SUCCESS,
  Actions.DELETE_ITEM_PRODUCT_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();

// - Item Product Provide -
export const createItemProductProvide = createAsyncAction(
  Actions.CREATE_ITEM_PRODUCT_PROVIDE_REQUEST,
  Actions.CREATE_ITEM_PRODUCT_PROVIDE_SUCCESS,
  Actions.CREATE_ITEM_PRODUCT_PROVIDE_FAILURE
)<
  FormRequestPayload<ItemProductProvideFormValues, ItemProductProvideCreateRequestData>,
  ItemProductProvideCreateResponseData,
  AxiosError
>();

export const updateItemProductProvide = createAsyncAction(
  Actions.UPDATE_ITEM_PRODUCT_PROVIDE_REQUEST,
  Actions.UPDATE_ITEM_PRODUCT_PROVIDE_SUCCESS,
  Actions.UPDATE_ITEM_PRODUCT_PROVIDE_FAILURE
)<
  FormRequestPayload<ItemProductProvideFormValues, ItemProductProvideUpdateRequestData>,
  ItemProductProvideUpdateResponseData,
  AxiosError
>();

export const deleteItemProductProvide = createAsyncAction(
  Actions.DELETE_ITEM_PRODUCT_PROVIDE_REQUEST,
  Actions.DELETE_ITEM_PRODUCT_PROVIDE_SUCCESS,
  Actions.DELETE_ITEM_PRODUCT_PROVIDE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - Periodic Item Product -
export const createPeriodicItemProduct = createAsyncAction(
  Actions.CREATE_PERIODIC_ITEM_PRODUCT_REQUEST,
  Actions.CREATE_PERIODIC_ITEM_PRODUCT_SUCCESS,
  Actions.CREATE_PERIODIC_ITEM_PRODUCT_FAILURE
)<
  FormRequestPayload<PeriodicItemProductFormValues, PeriodicItemProductCreateRequestData>,
  PeriodicItemProductCreateResponseData,
  AxiosError
>();

export const updatePeriodicItemProduct = createAsyncAction(
  Actions.UPDATE_PERIODIC_ITEM_PRODUCT_REQUEST,
  Actions.UPDATE_PERIODIC_ITEM_PRODUCT_SUCCESS,
  Actions.UPDATE_PERIODIC_ITEM_PRODUCT_FAILURE
)<
  FormRequestPayload<PeriodicItemProductFormValues, PeriodicItemProductUpdateRequestData>,
  PeriodicItemProductUpdateResponseData,
  AxiosError
>();

export const deletePeriodicItemProduct = createAsyncAction(
  Actions.DELETE_PERIODIC_ITEM_PRODUCT_REQUEST,
  Actions.DELETE_PERIODIC_ITEM_PRODUCT_SUCCESS,
  Actions.DELETE_PERIODIC_ITEM_PRODUCT_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
