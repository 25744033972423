export enum BannerSettingMapEntryActions {
  CREATE_MAP_ENTRY_REQUEST = 'CREATE_BANNER_SHOP_MAP_ENTRY#REQUEST',
  CREATE_MAP_ENTRY_SUCCESS = 'CREATE_BANNER_SHOP_MAP_ENTRY#SUCCESS',
  CREATE_MAP_ENTRY_FAILURE = 'CREATE_BANNER_SHOP_MAP_ENTRY#FAILURE',

  UPDATE_MAP_ENTRY_REQUEST = 'UPDATE_BANNER_SHOP_MAP_ENTRY#REQUEST',
  UPDATE_MAP_ENTRY_SUCCESS = 'UPDATE_BANNER_SHOP_MAP_ENTRY#SUCCESS',
  UPDATE_MAP_ENTRY_FAILURE = 'UPDATE_BANNER_SHOP_MAP_ENTRY#FAILURE',

  DELETE_MAP_ENTRY_REQUEST = 'DELETE_BANNER_SHOP_MAP_ENTRY#REQUEST',
  DELETE_MAP_ENTRY_SUCCESS = 'DELETE_BANNER_SHOP_MAP_ENTRY#SUCCESS',
  DELETE_MAP_ENTRY_FAILURE = 'DELETE_BANNER_SHOP_MAP_ENTRY#FAILURE',
}

export interface BannerSettingMapEntry {
  id: number;
  bannerInfo: number;
  startDate: string;
  endDate: string;
  segmentCode?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  enabled?: boolean;
}

export type BannerSettingMapEntryCreateRequestData = Omit<BannerSettingMapEntry, 'id' | 'enabled'>;
export type BannerSettingMapEntryCreateSuccessData = BannerSettingMapEntry;
export type BannerSettingMapEntryUpdateRequestData = Omit<BannerSettingMapEntry, 'id' | 'enabled'>;
export type BannerSettingMapEntryUpdateSuccessData = BannerSettingMapEntry;
