export enum PromotionScheduleTestActions {
  CREATE_PROMOTION_SCHEDULE_TEST_REQUEST = 'CREATE_PROMOTION_TEST_TARGET#REQUEST',
  CREATE_PROMOTION_SCHEDULE_TEST_SUCCESS = 'CREATE_PROMOTION_SCHEDULE_TARGET#SUCCESS',
  CREATE_PROMOTION_SCHEDULE_TEST_FAILURE = 'CREATE_PROMOTION_SCHEDULE_TARGET#FAILURE',
}

export interface PromotionScheduleTest {
  taskName: string;
  targetIds: string;
}

export type PromotionScheduleTestFormValues = PromotionScheduleTest;

export type PromotionScheduleTestCreateRequestData = PromotionScheduleTest;

export type PromotionScheduleTestCreateResponseData = PromotionScheduleTest;
