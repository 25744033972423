import React, { FC, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { layoutActions } from 'store/layout/slice';
import { ConfirmState } from 'store/layout/types';
import { RootState } from 'store/root/types';

const selector = ({ layout: { confirm } }: RootState) => confirm;

const useStyles = makeStyles(() => ({ dialogContextText: { whiteSpace: 'pre-line' } }));

const Confirm: FC = () => {
  const { isOpen, title, description, onAgree, onDisagree } = useSelector<RootState, ConfirmState>(selector);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = useCallback(() => {
    dispatch(layoutActions.dismissConfirm());
  }, [dispatch]);
  const handleAgree = useCallback(() => {
    if (onAgree) {
      onAgree();
    }
    handleClose();
  }, [handleClose, onAgree]);
  const handleDisagree = useCallback(() => {
    if (onDisagree) {
      onDisagree();
    }
    handleClose();
  }, [handleClose, onDisagree]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' disableTypography>
        {title}
      </DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText id='alert-dialog-description' className={classes.dialogContextText}>
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleDisagree} color='primary'>
          Disagree
        </Button>
        <Button onClick={handleAgree} color='primary' autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
