import { initialListState, ListState } from 'store/types';
import { Promotion } from '../promotionContent/types';

export enum PromotionPublishHistoryActions {
  LIST_PROMOTION_CODE_PUBLISHING_HISTORY_REQUEST = 'LIST_PROMOTION_CODE_PUBLISHING_HISTORY#REQUEST',
  LIST_PROMOTION_CODE_PUBLISHING_HISTORY_SUCCESS = 'LIST_PROMOTION_CODE_PUBLISHING_HISTORY#SUCCESS',
  LIST_PROMOTION_CODE_PUBLISHING_HISTORY_FAILURE = 'LIST_PROMOTION_CODE_PUBLISHING_HISTORY#FAILURE',

  READ_PROMOTION_CODE_PUBLISHING_HISTORY_REQUEST = 'READ_PROMOTION_CODE_PUBLISHING_HISTORY#REQUEST',
  READ_PROMOTION_CODE_PUBLISHING_HISTORY_SUCCESS = 'READ_PROMOTION_CODE_PUBLISHING_HISTORY#SUCCESS',
  READ_PROMOTION_CODE_PUBLISHING_HISTORY_FAILURE = 'READ_PROMOTION_CODE_PUBLISHING_HISTORY#FAILURE',

  UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY_REQUEST = 'UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY#REQUEST',
  UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY_SUCCESS = 'UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY#SUCCESS',
  UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY_FAILURE = 'UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY#FAILURE',
}

export const initialState: PromotionPublishHistoryState = {
  list: initialListState,
  detail: undefined,
};

export interface PromotionPublishHistoryState {
  list: ListState<PromotionPublishHistoryListItem>;
  detail?: PromotionPublishHistoryDetail;
}

export interface PromotionPublishHistory {
  id: number;
  description: string;
  group: string;
  type: string;
  status: string;
  size: number;

  path: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  promotionContentId?: string;
  limitCount?: number;

  expireTime: string;
  startTime?: string;
  pendingPromotions?: Promotion[];
  pendingSegmentCodePromotions?: SegmentCodePromotions;
  publishPopup: Boolean;
  segmentCode?: string;
  // code setting field
  currentUseCount: number;
  limitUseCount: number;
}

interface SegmentCodePromotions {
  [key: string]: Promotion[];
}

export type PromotionPublishHistoryListItem = PromotionPublishHistory;

export type PromotionPublishHistoryDetail = PromotionPublishHistory;

export type PromotionPublishHistoryFormValues = PromotionPublishHistory;

export type PromotionPublishHistoryUpdateRequestData = PromotionPublishHistoryFormValues;

export type PromotionPublishHistoryUpdateResponseData = PromotionPublishHistoryFormValues;
