import { combineEpics } from 'redux-observable';

import {
  createSpecialPromotionSettingMapEntry,
  deleteSpecialPromotionSettingMapEntry,
  updateSpecialPromotionSettingMapEntry,
} from 'store/specialPromotionSettingMapEntry/actions';
import {
  createSpecialPromotionSettingMapEntryApi,
  deleteSpecialPromotionSettingMapEntryApi,
  updateSpecialPromotionSettingMapEntryApi,
} from 'store/specialPromotionSettingMapEntry/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createSpecialPromotionSettingMapEntryToastEpic = createToastEpic(
  createSpecialPromotionSettingMapEntry.success,
  'Successfully created Schedule Setting'
);

const updateSpecialPromotionSettingMapEntryToastEpic = createToastEpic(
  updateSpecialPromotionSettingMapEntry.success,
  'Successfully saved Schedule Setting'
);

const createMapEntryEpic = createPostEpic(
  createSpecialPromotionSettingMapEntry,
  createSpecialPromotionSettingMapEntryApi
);

const updateMapEntryEpic = createUpdateEpic(
  updateSpecialPromotionSettingMapEntry,
  updateSpecialPromotionSettingMapEntryApi
);

const deleteMapEntryEpic = createDeleteEpic(
  deleteSpecialPromotionSettingMapEntry,
  deleteSpecialPromotionSettingMapEntryApi
);

export default combineEpics(
  createSpecialPromotionSettingMapEntryToastEpic,
  updateSpecialPromotionSettingMapEntryToastEpic,
  createMapEntryEpic,
  updateMapEntryEpic,
  deleteMapEntryEpic
);
