import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  VisitorAbTestCriteriaActions as Actions,
  VisitorAbTestCriteriaCreateRequestData,
  VisitorAbTestCriteriaCreateSuccessData,
  VisitorAbTestCriteriaUpdateRequestData,
  VisitorAbTestCriteriaUpdateSuccessData,
} from './types';

export const createVisitorAbTestCriteria = createAsyncAction(
  Actions.CREATE_SCHEDULE_REQUEST,
  Actions.CREATE_SCHEDULE_SUCCESS,
  Actions.CREATE_SCHEDULE_FAILURE
)<FormRequestPayload<VisitorAbTestCriteriaCreateRequestData>, VisitorAbTestCriteriaCreateSuccessData, AxiosError>();

export const updateVisitorAbTestCriteria = createAsyncAction(
  Actions.UPDATE_SCHEDULE_REQUEST,
  Actions.UPDATE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SCHEDULE_FAILURE
)<FormRequestPayload<VisitorAbTestCriteriaUpdateRequestData>, VisitorAbTestCriteriaUpdateSuccessData, AxiosError>();

export const deleteVisitorAbTestCriteria = createAsyncAction(
  Actions.DELETE_SCHEDULE_REQUEST,
  Actions.DELETE_SCHEDULE_SUCCESS,
  Actions.DELETE_SCHEDULE_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
