import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState } from '../types';

import { AppStoreReview, AppStoreReviewActions as Actions } from './types';

export const listAppStoreReview = createAsyncAction(
  Actions.LIST_APP_STORE_REVIEW_REQUEST,
  Actions.LIST_APP_STORE_REVIEW_SUCCESS,
  Actions.LIST_APP_STORE_REVIEW_FAILURE
)<ListRequestPayload, ListState<AppStoreReview>, AxiosError>();
