export const PURPOSE_OPTION_API_PREFIX = '/purposes/options';

export enum PurposeOptionActions {
  CREATE_PURPOSE_OPTION_REQUEST = 'CREATE_PURPOSE_OPTION#REQUEST',
  CREATE_PURPOSE_OPTION_SUCCESS = 'CREATE_PURPOSE_OPTION#SUCCESS',
  CREATE_PURPOSE_OPTION_FAILURE = 'CREATE_PURPOSE_OPTION#FAILURE',

  UPDATE_PURPOSE_OPTION_REQUEST = 'UPDATE_PURPOSE_OPTION#REQUEST',
  UPDATE_PURPOSE_OPTION_SUCCESS = 'UPDATE_PURPOSE_OPTION#SUCCESS',
  UPDATE_PURPOSE_OPTION_FAILURE = 'UPDATE_PURPOSE_OPTION#FAILURE',

  DELETE_PURPOSE_OPTION_REQUEST = 'DELETE_PURPOSE_OPTION#REQUEST',
  DELETE_PURPOSE_OPTION_SUCCESS = 'DELETE_PURPOSE_OPTION#SUCCESS',
  DELETE_PURPOSE_OPTION_FAILURE = 'DELETE_PURPOSE_OPTION#FAILURE',
}

export interface PurposeOption {
  id: number;
  purposeSetId: number;
  displayNameKey: string;
  backgroundImageUrl: string;
}

export interface PurposeOptionValues extends PurposeOption {
  displayNameKeyAsString: boolean;
}

export type PurposeOptionFormValues = Omit<PurposeOptionValues, 'id'>;

export type PurposeOptionCreateRequestData = Omit<PurposeOption, 'id'>;

export type PurposeOptionCreateResponseData = PurposeOption;

export type PurposeOptionUpdateRequestData = Omit<PurposeOption, 'id'>;

export type PurposeOptionUpdateResponseData = PurposeOption;
