import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  PromotionSchedule,
  PromotionScheduleCreateRequestData,
  PromotionScheduleCreateResponseData,
  PromotionScheduleDetail,
  PromotionScheduleFormValues,
  PromotionScheduleUpdateRequestData,
  PromotionScheduleUpdateResponseData,
} from './types';

export const listPromotionScheduleApi = createListApi<PromotionSchedule>('/scheduler/tasks', {
  params: {
    jobName: 'promotion',
  },
});

export const readPromotionScheduleApi = createReadApi<PromotionScheduleDetail>('/scheduler/tasks/schedules/:taskName');

export const createPromotionScheduleApi = createPostApi<
  PromotionScheduleFormValues,
  PromotionScheduleCreateRequestData,
  PromotionScheduleCreateResponseData
>('/scheduler/tasks');

export const updatePromotionScheduleApi = createUpdateApi<
  PromotionScheduleFormValues,
  PromotionScheduleUpdateRequestData,
  PromotionScheduleUpdateResponseData
>('/scheduler/tasks');

export const deletePromotionScheduleApi = createDeleteApi('/scheduler/tasks/:taskName');

export const refreshPromotionScheduleApi = createPostApi('/scheduler/tasks/refresh');
