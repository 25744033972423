import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as matataDeviceUnbindingActions from './actions';
import { MatataDeviceUnbindingState, initialMatataDeviceUnbindingState } from './types';

const matataDeviceUnbinding = createReducer<MatataDeviceUnbindingState, RootAction>(initialMatataDeviceUnbindingState)
  .handleAction(
    matataDeviceUnbindingActions.listMatataDeviceUnbinding.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(matataDeviceUnbindingActions.listMatataDeviceUnbinding.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(matataDeviceUnbindingActions.readMatataDeviceUnbinding.request, (state, { payload }) => ({
    ...state,
  }))
  .handleAction(matataDeviceUnbindingActions.readMatataDeviceUnbinding.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(matataDeviceUnbindingActions.listMatataDeviceUnbinding.failure, (state) => ({
    ...state,
    list: { ...initialMatataDeviceUnbindingState.list },
  }));

export default matataDeviceUnbinding;
