export enum TeamMessageImageSettingActions {
  CREATE_TEAM_MESSAGE_IMAGE_SETTING_REQUEST = 'CREATE_TEAM_MESSAGE_IMAGE_SETTING#REQUEST',
  CREATE_TEAM_MESSAGE_IMAGE_SETTING_SUCCESS = 'CREATE_TEAM_MESSAGE_IMAGE_SETTING#SUCCESS',
  CREATE_TEAM_MESSAGE_IMAGE_SETTING_FAILURE = 'CREATE_TEAM_MESSAGE_IMAGE_SETTING#FAILURE',

  UPDATE_TEAM_MESSAGE_IMAGE_SETTING_REQUEST = 'UPDATE_TEAM_MESSAGE_IMAGE_SETTING#REQUEST',
  UPDATE_TEAM_MESSAGE_IMAGE_SETTING_SUCCESS = 'UPDATE_TEAM_MESSAGE_IMAGE_SETTING#SUCCESS',
  UPDATE_TEAM_MESSAGE_IMAGE_SETTING_FAILURE = 'UPDATE_TEAM_MESSAGE_IMAGE_SETTING#FAILURE',

  DELETE_TEAM_MESSAGE_IMAGE_SETTING_REQUEST = 'DELETE_TEAM_MESSAGE_IMAGE_SETTING#REQUEST',
  DELETE_TEAM_MESSAGE_IMAGE_SETTING_SUCCESS = 'DELETE_TEAM_MESSAGE_IMAGE_SETTING#SUCCESS',
  DELETE_TEAM_MESSAGE_IMAGE_SETTING_FAILURE = 'DELETE_TEAM_MESSAGE_IMAGE_SETTING#FAILURE',
}

export interface TeamMessageImageSetting {
  language: string;
  imageUrl: string;
}

export type TeamMessageImageSettingFormValues = TeamMessageImageSetting;

export type TeamMessageImageSettingCreateRequestData = TeamMessageImageSettingFormValues;

export type TeamMessageImageSettingCreateResponseData = TeamMessageImageSettingFormValues;

export type TeamMessageImageSettingUpdateRequestData = TeamMessageImageSettingFormValues;

export type TeamMessageImageSettingUpdateResponseData = TeamMessageImageSettingFormValues;
