import { createFlatListApi, createListApi, createReadApi } from 'store/utils';
import { MatataInvalidatedPurchase } from 'store/matataInvalidatedPurchase/types';

const BASE_PATH = '/invalidated-purchases/matata';

export const readMatataInvalidatedPurchaseApi = createReadApi<MatataInvalidatedPurchase>(
  `${BASE_PATH}/gemInventoryIds/:gemInventoryId/transactionIds/:orderTransactionId`
);
export const listMatataInvalidatedPurchaseApi = createListApi<MatataInvalidatedPurchase>(BASE_PATH);
export const downloadMatataInvalidatedPurchaseApi = createFlatListApi<MatataInvalidatedPurchase>(
  `${BASE_PATH}/list-all`
);
