import { combineEpics } from 'redux-observable';

import { createPostEpic } from 'store/utils';

import { createImageSetting } from './actions';
import { createImageSettingApi } from './api';

const createImageSettingEpic = createPostEpic(createImageSetting, createImageSettingApi);

export default combineEpics(createImageSettingEpic);
