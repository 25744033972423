import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic } from 'store/utils';

import { listBigquerySegment, listBigquerySegmentSearch, readBigquerySegment } from './actions';
import { listBigquerySegmentApi, listBigquerySegmentSearchApi, readBigquerySegmentApi } from './api';

const listBigquerySegmentEpic = createListEpic(listBigquerySegment, listBigquerySegmentApi);

const listBigquerySegmentSearchEpic = createListEpic(listBigquerySegmentSearch, listBigquerySegmentSearchApi);

const readBigquerySegmentEpic = createReadEpic(readBigquerySegment, readBigquerySegmentApi);

export default combineEpics(listBigquerySegmentEpic, listBigquerySegmentSearchEpic, readBigquerySegmentEpic);
