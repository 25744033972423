import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  WebDecoSettingActions as Actions,
  WebDecoSetting,
  WebDecoSettingCreateRequestData,
  WebDecoSettingCreateResponseData,
  WebDecoSettingDetail,
  WebDecoSettingFormValues,
  WebDecoSettingUpdateRequestData,
  WebDecoSettingUpdateResponseData,
} from './types';

export const listWebDecoSetting = createAsyncAction(
  Actions.LIST_WEB_DECO_SETTINGS_REQUEST,
  Actions.LIST_WEB_DECO_SETTINGS_SUCCESS,
  Actions.LIST_WEB_DECO_SETTINGS_FAILURE
)<ListRequestPayload, ListState<WebDecoSetting>, AxiosError>();

export const readWebDecoSetting = createAsyncAction(
  Actions.READ_WEB_DECO_SETTING_REQUEST,
  Actions.READ_WEB_DECO_SETTING_SUCCESS,
  Actions.READ_WEB_DECO_SETTING_FAILURE
)<ReadRequestPayload, WebDecoSettingDetail, AxiosError>();

export const createWebDecoSetting = createAsyncAction(
  Actions.CREATE_WEB_DECO_SETTING_REQUEST,
  Actions.CREATE_WEB_DECO_SETTING_SUCCESS,
  Actions.CREATE_WEB_DECO_SETTING_FAILURE
)<
  FormRequestPayload<WebDecoSettingFormValues, WebDecoSettingCreateRequestData>,
  WebDecoSettingCreateResponseData,
  AxiosError
>();

export const updateWebDecoSetting = createAsyncAction(
  Actions.UPDATE_WEB_DECO_SETTING_REQUEST,
  Actions.UPDATE_WEB_DECO_SETTING_SUCCESS,
  Actions.UPDATE_WEB_DECO_SETTING_SUCCESS
)<
  FormRequestPayload<WebDecoSettingFormValues, WebDecoSettingUpdateRequestData>,
  WebDecoSettingUpdateResponseData,
  AxiosError
>();

export const deleteWebDecoSetting = createAsyncAction(
  Actions.DELETE_WEB_DECO_SETTING_REQUEST,
  Actions.DELETE_WEB_DECO_SETTING_SUCCESS,
  Actions.DELETE_WEB_DECO_SETTING_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const refreshWebDecoSetting = createAsyncAction(
  Actions.REFRESH_WEB_DECO_SETTING_REQUEST,
  Actions.REFRESH_WEB_DECO_SETTING_SUCCESS,
  Actions.REFRESH_WEB_DECO_SETTING_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
