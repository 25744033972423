import { createListApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  PromotionPublishHistoryDetail,
  PromotionPublishHistoryFormValues,
  PromotionPublishHistoryListItem,
  PromotionPublishHistoryUpdateRequestData,
  PromotionPublishHistoryUpdateResponseData,
} from './types';

export const listPromotionPublishHistoryApi = createListApi<PromotionPublishHistoryListItem>(
  '/promotion/codes/publish/history'
);

export const readPromotionPublishHistoryApi = createReadApi<PromotionPublishHistoryDetail>(
  '/promotion/codes/publish/history/:id'
);

export const updatePromotionPublishHistoryApi = createUpdateApi<
  PromotionPublishHistoryFormValues,
  PromotionPublishHistoryUpdateRequestData,
  PromotionPublishHistoryUpdateResponseData
>('/promotion/codes/publish/history');
