import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteBodyRequestPayload,
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ShopDetailPageActions as Actions,
  ShopDetailPageRequestData,
  ShopDetailPageResponseData,
  ShopDetailPageFormValues,
  ShopDetailListItem,
  ShopDetailDetail,
  ShopDetailSectionFormValue,
  ShopDetailSectionRequestData,
  ShopDetailSectionResponseData,
  ShopDetailSectionBulkCreateRequestData,
  ShopDetailSectionBulkCreateResponseData,
  ShopDetailScheduleCreateRequestData,
  ShopDetailScheduleCreateResponseData,
  ShopDetailScheduleFormValues,
  ShopDetailScheduleUpdateRequestData,
  ShopDetailScheduleUpdateResponseData,
} from './types';

export const listShopDetailPage = createAsyncAction(
  Actions.LIST_SHOP_DETAIL_PAGE_REQUEST,
  Actions.LIST_SHOP_DETAIL_PAGE_SUCCESS,
  Actions.LIST_SHOP_DETAIL_PAGE_FAILURE
)<ListRequestPayload, ListState<ShopDetailListItem>, AxiosError>();

export const createShopDetailPage = createAsyncAction(
  Actions.CREATE_SHOP_DETAIL_PAGE_REQUEST,
  Actions.CREATE_SHOP_DETAIL_PAGE_SUCCESS,
  Actions.CREATE_SHOP_DETAIL_PAGE_FAILURE
)<FormRequestPayload<ShopDetailPageFormValues, ShopDetailPageRequestData>, ShopDetailPageResponseData, AxiosError>();

export const readShopDetailPage = createAsyncAction(
  Actions.READ_SHOP_DETAIL_PAGE_REQUEST,
  Actions.READ_SHOP_DETAIL_PAGE_SUCCESS,
  Actions.READ_SHOP_DETAIL_PAGE_FAILURE
)<ReadRequestPayload, ShopDetailDetail, AxiosError>();

export const updateShopDetailPage = createAsyncAction(
  Actions.UPDATE_SHOP_DETAIL_PAGE_REQUEST,
  Actions.UPDATE_SHOP_DETAIL_PAGE_SUCCESS,
  Actions.UPDATE_SHOP_DETAIL_PAGE_FAILURE
)<FormRequestPayload<ShopDetailPageFormValues, ShopDetailPageRequestData>, ShopDetailPageResponseData, AxiosError>();

export const deleteShopDetailPage = createAsyncAction(
  Actions.DELETE_SHOP_DETAIL_PAGE_REQUEST,
  Actions.DELETE_SHOP_DETAIL_PAGE_SUCCESS,
  Actions.DELETE_SHOP_DETAIL_PAGE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - SECTION -
export const connectShopDetailSection = createAsyncAction(
  Actions.CONNECT_SHOP_DETAIL_SECTION_REQUEST,
  Actions.CONNECT_SHOP_DETAIL_SECTION_SUCCESS,
  Actions.CONNECT_SHOP_DETAIL_SECTION_FAILURE
)<
  FormRequestPayload<ShopDetailSectionFormValue, ShopDetailSectionRequestData>,
  ShopDetailSectionResponseData,
  AxiosError
>();

export const editShopDetailSection = createAsyncAction(
  Actions.EDIT_SHOP_DETAIL_SECTION_REQUEST,
  Actions.EDIT_SHOP_DETAIL_SECTION_SUCCESS,
  Actions.EDIT_SHOP_DETAIL_SECTION_FAILURE
)<
  FormRequestPayload<ShopDetailSectionFormValue, ShopDetailSectionRequestData>,
  ShopDetailSectionResponseData,
  AxiosError
>();

export const disconnectShopDetailSection = createAsyncAction(
  Actions.DISCONNECT_SHOP_DETAIL_SECTION_REQUEST,
  Actions.DISCONNECT_SHOP_DETAIL_SECTION_SUCCESS,
  Actions.DISCONNECT_SHOP_DETAIL_SECTION_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - SECTION BULK -
export const bulkCreateShopDetailSections = createAsyncAction(
  Actions.BULK_CREATE_SHOP_DETAIL_SECTION_REQUEST,
  Actions.BULK_CREATE_SHOP_DETAIL_SECTION_SUCCESS,
  Actions.BULK_CREATE_SHOP_DETAIL_SECTION_FAILURE
)<FormRequestPayload<ShopDetailSectionBulkCreateRequestData>, ShopDetailSectionBulkCreateResponseData, AxiosError>();

export const bulkDeleteShopDetailSections = createAsyncAction(
  Actions.BULK_DELETE_SHOP_DETAIL_SECTION_REQUEST,
  Actions.BULK_DELETE_SHOP_DETAIL_SECTION_SUCCESS,
  Actions.BULK_DELETE_SHOP_DETAIL_SECTION_FAILURE
)<DeleteBodyRequestPayload, undefined, AxiosError>();

// - SCHEDULE -
export const createShopDetailSchedule = createAsyncAction(
  Actions.CREATE_SHOP_DETAIL_PAGE_SCHEDULE_REQUEST,
  Actions.CREATE_SHOP_DETAIL_PAGE_SCHEDULE_SUCCESS,
  Actions.CREATE_SHOP_DETAIL_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopDetailScheduleFormValues, ShopDetailScheduleCreateRequestData>,
  ShopDetailScheduleCreateResponseData,
  AxiosError
>();

export const updateShopDetailSchedule = createAsyncAction(
  Actions.UPDATE_SHOP_DETAIL_PAGE_SCHEDULE_REQUEST,
  Actions.UPDATE_SHOP_DETAIL_PAGE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SHOP_DETAIL_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopDetailScheduleFormValues, ShopDetailScheduleUpdateRequestData>,
  ShopDetailScheduleUpdateResponseData,
  AxiosError
>();

export const deleteShopDetailSchedule = createAsyncAction(
  Actions.DELETE_SHOP_DETAIL_PAGE_SCHEDULE_REQUEST,
  Actions.DELETE_SHOP_DETAIL_PAGE_SCHEDULE_SUCCESS,
  Actions.DELETE_SHOP_DETAIL_PAGE_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
