import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as punishmentActions from './actions';
import { PunishmentState, initialPunishmentState, PunishmentItem } from './types';

const punishment = createReducer<PunishmentState, RootAction>(initialPunishmentState)
  .handleAction(punishmentActions.listPunishment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(punishmentActions.listPunishment.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(punishmentActions.listPunishment.failure, (state) => ({
    ...state,
    list: { ...initialPunishmentState.list },
  }))
  .handleAction(punishmentActions.deletePunishment.success, (state, { payload }) => {
    const punishments = state?.list.data ?? [];
    const newPunishments = punishments.filter((punishment: PunishmentItem) => punishment.id !== payload);

    return {
      ...state,
      list: state.list
        ? {
            ...state.list,
            data: newPunishments,
          }
        : state.list,
    };
  });

export default punishment;
