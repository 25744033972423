import {
  DeleteBodyRequestPayload,
  FormRequestPayload,
  initialListState,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

export enum AgentManageActions {
  LIST_AGENT_MANAGE_REQUEST = 'LIST_AGENT_MANAGE#REQUEST',
  LIST_AGENT_MANAGE_SUCCESS = 'LIST_AGENT_MANAGE#SUCCESS',
  LIST_AGENT_MANAGE_FAILURE = 'LIST_AGENT_MANAGE#FAILURE',

  CREATE_AGENT_MANAGE_REQUEST = 'CREATE_AGENT_MANAGE#REQUEST',
  CREATE_AGENT_MANAGE_SUCCESS = 'CREATE_AGENT_MANAGE#SUCCESS',
  CREATE_AGENT_MANAGE_FAILURE = 'CREATE_AGENT_MANAGE#FAILURE',

  READ_AGENT_MANAGE_REQUEST = 'READ_AGENT_MANAGE#REQUEST',
  READ_AGENT_MANAGE_SUCCESS = 'READ_AGENT_MANAGE#SUCCESS',
  READ_AGENT_MANAGE_FAILURE = 'READ_AGENT_MANAGE#FAILURE',

  UPDATE_AGENT_MANAGE_REQUEST = 'UPDATE_AGENT_MANAGE#REQUEST',
  UPDATE_AGENT_MANAGE_SUCCESS = 'UPDATE_AGENT_MANAGE#SUCCESS',
  UPDATE_AGENT_MANAGE_FAILURE = 'UPDATE_AGENT_MANAGE#FAILURE',

  DELETE_AGENT_MANAGE_REQUEST = 'DELETE_AGENT_MANAGE#REQUEST',
  DELETE_AGENT_MANAGE_SUCCESS = 'DELETE_AGENT_MANAGE#SUCCESS',
  DELETE_AGENT_MANAGE_FAILURE = 'DELETE_AGENT_MANAGE#FAILURE',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum Level {
  SUPER = 'SUPER',
  BASIC = 'BASIC',
}

export interface Agent {
  birthYear: string;
  gender: Gender;
  simpleName: string;
  id: string;
  name: string;
  userLevel: Level;
  team: string;
}

export interface AgentManageState {
  list: ListState<Agent>;
  detail?: Agent;
}

export type AgentManageListItem = Agent & { checked?: boolean };
export type AgentManageListRequestPayload = ListRequestPayload;
export type AgentManageListResponseData = ListState<AgentManageListItem>;

export type AgentManageReadRequestPayLoad = ReadRequestPayload;
export type AgentManageReadResponseData = Agent;

export type AgentManageCreateFormValues = Omit<Agent, 'simpleName' | 'id' | 'userLevel'> & {
  password: string;
  confirmPassword: string;
};
export type AgentManageCreateRequestData = Omit<AgentManageCreateFormValues, 'confirmPassword'>;
export type AgentManageCreateRequestPayload = FormRequestPayload<
  AgentManageCreateFormValues,
  AgentManageCreateRequestData
>;
export type AgentManageCreateResponseData = Agent;

export type AgentManageUpdateFormValues = Agent & { password: string; confirmPassword: string };
export type AgentManageUpdateRequestData = Omit<AgentManageUpdateFormValues, 'confirmPassword'>;
export type AgentManageUpdateRequestPayload = FormRequestPayload<
  AgentManageUpdateFormValues,
  AgentManageUpdateRequestData
>;
export type AgentManageUpdateResponseData = Agent;

export type AgentManageDeleteRequestPayload = DeleteBodyRequestPayload;

export const initialAgentManageState: AgentManageState = {
  list: initialListState,
  detail: undefined,
};
