import { combineEpics } from 'redux-observable';

import {
  createAgentManage,
  deleteAgentManage,
  listAgentManage,
  readAgentManage,
  updateAgentManage,
} from 'store/agentManage/actions';
import {
  createAgentManageApi,
  deleteAgentManageApi,
  listAgentManageApi,
  readAgentManageApi,
  updateAgentManageApi,
} from 'store/agentManage/apis';
import {
  createDeleteBodyEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

const listAgentMangeEpic = createListEpic(listAgentManage, listAgentManageApi);

const createAgentManageEpic = createPostEpic(createAgentManage, createAgentManageApi);

const createAgentManageToastEpic = createToastEpic(createAgentManage.success, 'Successfully created');

const readAgentManageEpic = createReadEpic(readAgentManage, readAgentManageApi);

const readAgentManageToastEpic = createToastEpic(readAgentManage.success, 'Successfully loaded');

const updateAgentManageEpic = createUpdateEpic(updateAgentManage, updateAgentManageApi);

const updateAgentManageToastEpic = createToastEpic(updateAgentManage.success, 'Successfully saved');

const deleteAgentManageEpic = createDeleteBodyEpic(deleteAgentManage, deleteAgentManageApi);

const deleteAgentManageToastEpic = createToastEpic(deleteAgentManage.success, 'Successfully deleted');

export default combineEpics(
  listAgentMangeEpic,

  createAgentManageEpic,
  readAgentManageEpic,
  updateAgentManageEpic,
  deleteAgentManageEpic,

  createAgentManageToastEpic,
  readAgentManageToastEpic,
  updateAgentManageToastEpic,
  deleteAgentManageToastEpic
);
