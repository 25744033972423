import { AuthState } from '@okta/okta-auth-js';
import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  UserActions as Actions,
  RefreshJWTRequestPayload,
  RefreshJWTSuccessPayload,
  SignInSuccessPayload,
} from './types';

export const signOut = createAction(Actions.SIGN_OUT)<void>();

export const fetchSignIn = createAsyncAction(
  Actions.FETCH_SIGN_IN_REQUEST,
  Actions.FETCH_SIGN_IN_SUCCESS,
  Actions.FETCH_SIGN_IN_FAILURE
)<AuthState, SignInSuccessPayload, undefined>();

export const refreshJWT = createAsyncAction(
  Actions.REFRESH_JWT_REQUEST,
  Actions.REFRESH_JWT_SUCCESS,
  Actions.REFRESH_JWT_FAILURE
)<RefreshJWTRequestPayload, RefreshJWTSuccessPayload, undefined>();
