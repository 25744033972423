import { combineEpics } from 'redux-observable';

import {
  createShopEntryBannerSchedule,
  deleteShopEntryBannerSchedule,
  updateShopEntryBannerSchedule,
} from 'store/shopEntryBannerSchedule/actions';
import {
  createShopEntryBannerScheduleApi,
  deleteShopEntryBannerScheduleApi,
  updateShopEntryBannerScheduleApi,
} from 'store/shopEntryBannerSchedule/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createShopEntryBannerScheduleToastEpic = createToastEpic(
  createShopEntryBannerSchedule.success,
  'Successfully created Schedule Setting'
);

const updateShopEntryBannerScheduleToastEpic = createToastEpic(
  updateShopEntryBannerSchedule.success,
  'Successfully saved Schedule Setting'
);

const deleteShopEntryBannerScheduleToastEpic = createToastEpic(
  deleteShopEntryBannerSchedule.success,
  'Successfully deleted Schedule Setting'
);

const createScheduleEpic = createPostEpic(createShopEntryBannerSchedule, createShopEntryBannerScheduleApi);

const updateScheduleEpic = createUpdateEpic(updateShopEntryBannerSchedule, updateShopEntryBannerScheduleApi);

const deleteScheduleEpic = createDeleteEpic(deleteShopEntryBannerSchedule, deleteShopEntryBannerScheduleApi);

export default combineEpics(
  createShopEntryBannerScheduleToastEpic,
  updateShopEntryBannerScheduleToastEpic,
  deleteShopEntryBannerScheduleToastEpic,
  createScheduleEpic,
  updateScheduleEpic,
  deleteScheduleEpic
);
