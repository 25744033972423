import { initialListState, ListState } from 'store/types';

export enum MatchFilterOptionActions {
  LIST_MATCH_FILTER_OPTIONS_REQUEST = 'LIST_MATCH_FILTER_OPTIONS#REQUEST',
  LIST_MATCH_FILTER_OPTIONS_SUCCESS = 'LIST_MATCH_FILTER_OPTIONS#SUCCESS',
  LIST_MATCH_FILTER_OPTIONS_FAILURE = 'LIST_MATCH_FILTER_OPTIONS#FAILURE',
}

export const initialState: MatchFilterOptionState = { list: initialListState };

export interface MatchFilterOptionState {
  list: ListState<MatchFilterOptionListItem>;
}

export interface MatchFilterOption {
  optionId: string;
  optionKey?: string;
  localizationKey?: string;
  displayName?: string;
  configs?: { [name: string]: any };
}

export type MatchFilterOptionListItem = MatchFilterOption;
