import { combineEpics } from 'redux-observable';

import { createAsyncEpic, createListEpic, createReadEpic, createToastEpic } from 'store/utils';

import { listDecoItem, readDecoItem, refreshDecoItem } from './actions';
import { listDecoItemApi, readDecoItemApi, refreshDecoItemApi } from './api';

const listDecoItemEpic = createListEpic(listDecoItem, listDecoItemApi);

const readDecoItemEpic = createReadEpic(readDecoItem, readDecoItemApi);

const refreshDecoItemEpic = createAsyncEpic(refreshDecoItem, refreshDecoItemApi);

const refreshDecoItemToastEpic = createToastEpic(refreshDecoItem.success, 'Successfully refreshed server');

export default combineEpics(listDecoItemEpic, readDecoItemEpic, refreshDecoItemEpic, refreshDecoItemToastEpic);
