import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
  UploadRequestPayload,
} from 'store/types';

import {
  StringLocalizationActions as Actions,
  StringLocalizationCreateRequestData,
  StringLocalizationCreateSuccessData,
  StringLocalizationDetail,
  StringLocalizationHistoryListItem,
  StringLocalizationListItem,
  StringLocalizationRequestFormValues,
  StringLocalizationUpdateRequestData,
  StringLocalizationUpdateSuccessData,
  StringLocalizationUpsertRequestData,
  StringLocalizationUpsertSuccessData,
} from './types';

export const listStringLocalization = createAsyncAction(
  Actions.LIST_STRING_LOCALIZATIONS_REQUEST,
  Actions.LIST_STRING_LOCALIZATIONS_SUCCESS,
  Actions.LIST_STRING_LOCALIZATIONS_FAILURE
)<ListRequestPayload, ListState<StringLocalizationListItem>, AxiosError>();

export const readStringLocalization = createAsyncAction(
  Actions.READ_STRING_LOCALIZATION_REQUEST,
  Actions.READ_STRING_LOCALIZATION_SUCCESS,
  Actions.READ_STRING_LOCALIZATION_FAILURE
)<ReadRequestPayload, StringLocalizationDetail, AxiosError>();

export const upsertStringLocalizationBulk = createAsyncAction(
  Actions.UPSERT_STRING_LOCALIZATION_BULK_REQUEST,
  Actions.UPSERT_STRING_LOCALIZATION_BULK_SUCCESS,
  Actions.UPSERT_STRING_LOCALIZATION_BULK_FAILURE
)<UploadRequestPayload<StringLocalizationUpsertRequestData>, StringLocalizationUpsertSuccessData[], AxiosError>();

export const createStringLocalization = createAsyncAction(
  Actions.CREATE_STRING_LOCALIZATION_REQUEST,
  Actions.CREATE_STRING_LOCALIZATION_SUCCESS,
  Actions.CREATE_STRING_LOCALIZATION_FAILURE
)<
  FormRequestPayload<StringLocalizationRequestFormValues, StringLocalizationCreateRequestData>,
  StringLocalizationCreateSuccessData,
  AxiosError
>();

export const updateStringLocalization = createAsyncAction(
  Actions.UPDATE_STRING_LOCALIZATION_REQUEST,
  Actions.UPDATE_STRING_LOCALIZATION_SUCCESS,
  Actions.UPDATE_STRING_LOCALIZATION_FAILURE
)<
  FormRequestPayload<StringLocalizationRequestFormValues, StringLocalizationUpdateRequestData>,
  StringLocalizationUpdateSuccessData,
  AxiosError
>();

export const deleteStringLocalization = createAsyncAction(
  Actions.DELETE_STRING_LOCALIZATION_REQUEST,
  Actions.DELETE_STRING_LOCALIZATION_SUCCESS,
  Actions.DELETE_STRING_LOCALIZATION_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const listStringLocalizationHistory = createAsyncAction(
  Actions.LIST_STRING_LOCALIZATION_HISTORY_REQUEST,
  Actions.LIST_STRING_LOCALIZATION_HISTORY_SUCCESS,
  Actions.LIST_STRING_LOCALIZATION_HISTORY_FAILURE
)<ListRequestPayload, ListState<StringLocalizationHistoryListItem>, AxiosError>();

export const refreshStringLocalization = createAsyncAction(
  Actions.REFRESH_STRING_LOCALIZATION_REQUEST,
  Actions.REFRESH_STRING_LOCALIZATION_SUCCESS,
  Actions.REFRESH_STRING_LOCALIZATION_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();

export const pasteStringLocalization = createAction(Actions.PASTE_STRING_LOCALIZATION)<
  UploadRequestPayload<StringLocalizationUpsertRequestData>
>();
