import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { ConfirmPayload, FullScreenModalPayload, initialState, ModalPayload, ToastOption } from './types';

const layoutPersistConfig = {
  key: 'layout',
  storage,
  whitelist: [],
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.drawer.isOpen = payload;
    },
    makeToast: (state, { payload }: PayloadAction<ToastOption>) => {
      state.toast = payload;
    },
    dismissToast: (state) => {
      state.toast = { ...initialState.toast };
    },
    makeConfirm: (state, { payload }: PayloadAction<ConfirmPayload>) => {
      state.confirm = {
        ...payload,
        isOpen: true,
      };
    },
    dismissConfirm: (state) => {
      state.confirm = { ...initialState.confirm };
    },
    makeModal: (state, { payload }: PayloadAction<ModalPayload>) => {
      state.modal = {
        ...payload,
        isOpen: true,
      };
    },
    dismissModal: (state) => {
      state.modal = { ...initialState.modal };
    },
    makeFullScreenModal: (state, { payload }: PayloadAction<FullScreenModalPayload>) => {
      state.fullScreenModal = {
        ...payload,
        isOpen: true,
      };
    },
    dismissFullScreenModal: (state) => {
      state.fullScreenModal = { ...initialState.fullScreenModal };
    },
  },
});

const { actions, reducer } = slice;

export { actions as layoutActions };

export default persistReducer(layoutPersistConfig, reducer);
