import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  AbTestCriteriaActions as Actions,
  AbTestCriteriaCreateRequestData,
  AbTestCriteriaCreateSuccessData,
  AbTestCriteriaUpdateRequestData,
  AbTestCriteriaUpdateSuccessData,
} from './types';

export const createAbTestCriteria = createAsyncAction(
  Actions.CREATE_SCHEDULE_REQUEST,
  Actions.CREATE_SCHEDULE_SUCCESS,
  Actions.CREATE_SCHEDULE_FAILURE
)<FormRequestPayload<AbTestCriteriaCreateRequestData>, AbTestCriteriaCreateSuccessData, AxiosError>();

export const updateAbTestCriteria = createAsyncAction(
  Actions.UPDATE_SCHEDULE_REQUEST,
  Actions.UPDATE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SCHEDULE_FAILURE
)<FormRequestPayload<AbTestCriteriaUpdateRequestData>, AbTestCriteriaUpdateSuccessData, AxiosError>();

export const deleteAbTestCriteria = createAsyncAction(
  Actions.DELETE_SCHEDULE_REQUEST,
  Actions.DELETE_SCHEDULE_SUCCESS,
  Actions.DELETE_SCHEDULE_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
