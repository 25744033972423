import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as UploadFileListActions from './actions';
import { initialState, UploadFileListState } from './types';

const uploadFileList = createReducer<UploadFileListState, RootAction>(initialState)
  .handleAction(UploadFileListActions.uploadFileList, (state, { payload: { type, fileList } }) => ({
    ...state,
    [type]: { fileList },
  }))
  .handleAction(UploadFileListActions.resetFileList, (state, { payload: { type } }) => ({
    ...state,
    [type]: { fileList: [] },
  }));

export default uploadFileList;
