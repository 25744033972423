import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createListEpic, createPostEpic, createReadEpic, createToastEpic } from 'store/utils';

import {
  createConfigStore,
  listConfigStore,
  readConfigStore,
  refreshConfigStore,
  updateConfigStore,
  deleteConfigStore,
} from './actions';
import {
  createConfigStoreApi,
  listConfigStoreApi,
  readConfigStoreMapApi,
  refreshConfigStoreApi,
  updateConfigStoreApi,
  deleteConfigStoreApi,
} from './api';

const updateConfigStoreToastEpic = createToastEpic(updateConfigStore.success, 'Successfully saved Config Store');

const refreshConfigStoreToastEpic = createToastEpic(refreshConfigStore.success, 'Successfully refreshed server');

const createConfigStoreEpic = createPostEpic(createConfigStore, createConfigStoreApi);

const updateConfigStoreEpic = createPostEpic(updateConfigStore, updateConfigStoreApi);

const listConfigStoreEpic = createListEpic(listConfigStore, listConfigStoreApi);

const readConfigStoreEpic = createReadEpic(readConfigStore, readConfigStoreMapApi);

const refreshConfigStoreEpic = createPostEpic(refreshConfigStore, refreshConfigStoreApi);
const deleteRemoteConfigEpic = createDeleteEpic(deleteConfigStore, deleteConfigStoreApi);

export default combineEpics(
  updateConfigStoreToastEpic,
  refreshConfigStoreToastEpic,
  createConfigStoreEpic,
  updateConfigStoreEpic,
  listConfigStoreEpic,
  readConfigStoreEpic,
  refreshConfigStoreEpic,
  deleteRemoteConfigEpic
);
