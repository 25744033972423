import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ReadRequestPayload } from 'store/types';

import {
  ProfileManageActions as Actions,
  ProfileManageDetail,
  ProfileManageUpdateRequestData,
  ProfileManageUpdateSuccessData,
} from './types';

export const readProfileManage = createAsyncAction(
  Actions.READ_PROFILE_MANAGE_REQUEST,
  Actions.READ_PROFILE_MANAGE_SUCCESS,
  Actions.READ_PROFILE_MANAGE_FAILURE
)<ReadRequestPayload, ProfileManageDetail, AxiosError>();

export const updateProfileManage = createAsyncAction(
  Actions.UPDATE_PROFILE_MANAGE_REQUEST,
  Actions.UPDATE_PROFILE_MANAGE_SUCCESS,
  Actions.UPDATE_PROFILE_MANAGE_FAILURE
)<FormRequestPayload<ProfileManageUpdateRequestData>, ProfileManageUpdateSuccessData, AxiosError>();
