import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  GemSettingProductActions as Actions,
  GemSettingProductCreateRequestData,
  GemSettingProductCreateSuccessData,
  GemSettingProductUpdateRequestData,
  GemSettingProductUpdateSuccessData,
} from 'store/gemSettingProduct/types';
import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

export const createGemSettingProduct = createAsyncAction(
  Actions.CREATE_GEM_SETTING_PRODUCT_REQUEST,
  Actions.CREATE_GEM_SETTING_PRODUCT_SUCCESS,
  Actions.CREATE_GEM_SETTING_PRODUCT_FAILURE
)<FormRequestPayload<GemSettingProductCreateRequestData>, GemSettingProductCreateSuccessData, AxiosError>();

export const updateGemSettingProduct = createAsyncAction(
  Actions.UPDATE_GEM_SETTING_PRODUCT_REQUEST,
  Actions.UPDATE_GEM_SETTING_PRODUCT_SUCCESS,
  Actions.UPDATE_GEM_SETTING_PRODUCT_FAILURE
)<FormRequestPayload<GemSettingProductUpdateRequestData>, GemSettingProductUpdateSuccessData, AxiosError>();

export const deleteGemSettingProduct = createAsyncAction(
  Actions.DELETE_GEM_SETTING_PRODUCT_REQUEST,
  Actions.DELETE_GEM_SETTING_PRODUCT_SUCCESS,
  Actions.DELETE_GEM_SETTING_PRODUCT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
