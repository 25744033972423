import { initialListState, ListState } from '../types';

export enum InvalidatedPurchaseActions {
  LIST_INVALIDATED_PURCHASE_REQUEST = 'LIST_INVALIDATED_PURCHASE#REQUEST',
  LIST_INVALIDATED_PURCHASE_SUCCESS = 'LIST_INVALIDATED_PURCHASE#SUCCESS',
  LIST_INVALIDATED_PURCHASE_FAILURE = 'LIST_INVALIDATED_PURCHASE#FAILURE',

  READ_INVALIDATED_PURCHASE_REQUEST = 'READ_INVALIDATED_PURCHASE#REQUEST',
  READ_INVALIDATED_PURCHASE_SUCCESS = 'READ_INVALIDATED_PURCHASE#SUCCESS',
  READ_INVALIDATED_PURCHASE_FAILURE = 'READ_INVALIDATED_PURCHASE#FAILURE',
}

export interface InvalidatedPurchase {
  purchaseId: string;
  purchaseType: string;
  userId: number;
  dateInvalidated: string;
  blocked: boolean;
}

export interface InvalidatedPurchaseState {
  list: ListState<InvalidatedPurchase>;
  detail?: InvalidatedPurchase;
}

export const initialState: InvalidatedPurchaseState = {
  list: initialListState,
  detail: undefined,
};
