import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import * as settingActions from './actions';
import { initialState, SettingState } from './types';

const persistConfig = {
  key: 'setting',
  storage,
};

const settingReducer = createReducer<SettingState>(initialState)
  .handleAction(settingActions.setPalette, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(settingActions.setPresets, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(settingActions.setCurrentPresetIndex, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(settingActions.setSetting, (state, { payload: { data } }) => {
    return {
      ...state,
      ...data,
    };
  });

export default persistReducer(persistConfig, settingReducer);
