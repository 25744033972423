import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic } from '../utils';

import { listMatataInvalidatedPurchaseBySender, readMatataInvalidatedPurchaseBySender } from './actions';
import { listMatataInvalidatedPurchaseBySenderApi, readMatataInvalidatedPurchaseBySenderApi } from './api';

const readMatataInvalidatedPurchaseBySenderEpic = createReadEpic(
  readMatataInvalidatedPurchaseBySender,
  readMatataInvalidatedPurchaseBySenderApi
);
const listMatataInvalidatedPurchaseBySenderEpic = createListEpic(
  listMatataInvalidatedPurchaseBySender,
  listMatataInvalidatedPurchaseBySenderApi
);
export default combineEpics(readMatataInvalidatedPurchaseBySenderEpic, listMatataInvalidatedPurchaseBySenderEpic);
