import { combineEpics } from 'redux-observable';

import {
  createGemProductTierSchedule,
  deleteGemProductTierSchedule,
  updateGemProductTierSchedule,
} from 'store/gemProductTierSchedule/actions';
import {
  createGemProductTierScheduleApi,
  deleteGemProductTierScheduleApi,
  updateGemProductTierScheduleApi,
} from 'store/gemProductTierSchedule/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createGemProductTierScheduleToastEpic = createToastEpic(
  createGemProductTierSchedule.success,
  'Successfully created Schedule Setting'
);

const updateGemProductTierScheduleToastEpic = createToastEpic(
  updateGemProductTierSchedule.success,
  'Successfully saved Schedule Setting'
);

const deleteGemProductTierScheduleToastEpic = createToastEpic(
  deleteGemProductTierSchedule.success,
  'Successfully deleted Schedule Setting'
);

const createScheduleEpic = createPostEpic(createGemProductTierSchedule, createGemProductTierScheduleApi);

const updateScheduleEpic = createUpdateEpic(updateGemProductTierSchedule, updateGemProductTierScheduleApi);

const deleteScheduleEpic = createDeleteEpic(deleteGemProductTierSchedule, deleteGemProductTierScheduleApi);

export default combineEpics(
  createGemProductTierScheduleToastEpic,
  updateGemProductTierScheduleToastEpic,
  deleteGemProductTierScheduleToastEpic,
  createScheduleEpic,
  updateScheduleEpic,
  deleteScheduleEpic
);
