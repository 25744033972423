export enum AgeManageActions {
  UPDATE_AGE_MANAGE_REQUEST = 'UPDATE_AGE_MANAGE#REQUEST',
  UPDATE_AGE_MANAGE_SUCCESS = 'UPDATE_AGE_MANAGE#SUCCESS',
  UPDATE_AGE_MANAGE_FAILURE = 'UPDATE_AGE_MANAGE#FAILURE',
}

export interface AgeManageUpdateRequestFormValues {
  userIds: string;
  birthday: string;
}

export interface AgeManageUpdateRequestData {
  userIds: string[];
  birthday: string;
}
