import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  GemSettingMapEntryActions as Actions,
  GemSettingMapEntryCreateRequestData,
  GemSettingMapEntryCreateSuccessData,
  GemSettingMapEntryUpdateRequestData,
  GemSettingMapEntryUpdateSuccessData,
} from './types';

export const createGemSettingMapEntry = createAsyncAction(
  Actions.CREATE_MAP_ENTRY_REQUEST,
  Actions.CREATE_MAP_ENTRY_SUCCESS,
  Actions.CREATE_MAP_ENTRY_FAILURE
)<FormRequestPayload<GemSettingMapEntryCreateRequestData>, GemSettingMapEntryCreateSuccessData, AxiosError>();

export const updateGemSettingMapEntry = createAsyncAction(
  Actions.UPDATE_MAP_ENTRY_REQUEST,
  Actions.UPDATE_MAP_ENTRY_SUCCESS,
  Actions.UPDATE_MAP_ENTRY_FAILURE
)<FormRequestPayload<GemSettingMapEntryUpdateRequestData>, GemSettingMapEntryUpdateSuccessData, AxiosError>();

export const deleteGemSettingMapEntry = createAsyncAction(
  Actions.DELETE_MAP_ENTRY_REQUEST,
  Actions.DELETE_MAP_ENTRY_SUCCESS,
  Actions.DELETE_MAP_ENTRY_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
