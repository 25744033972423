import { createReducer } from 'typesafe-actions';

import { ShopEntryBannerSchedule } from 'store/shopEntryBannerSchedule/types';

import * as shopEntryBannerScheduleActions from '../shopEntryBannerSchedule/actions';
import { RootAction } from '../root/types';

import * as shopEntryBannerActions from './actions';
import { initialState, ShopEntryBannerState } from './types';

// noinspection TypeScriptValidateJSTypes
const shopEntryBanner = createReducer<ShopEntryBannerState, RootAction>(initialState)
  .handleAction(shopEntryBannerActions.listShopEntryBanner.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(shopEntryBannerActions.listShopEntryBanner.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(shopEntryBannerActions.readShopEntryBanner.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(shopEntryBannerActions.updateShopEntryBanner.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(shopEntryBannerScheduleActions.createShopEntryBannerSchedule.success, (state, { payload }) => {
    const schedules = state?.detail?.shopEntryBannerSchedules ?? [];
    const newSchedules = [...schedules, payload];

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            shopEntryBannerSchedules: newSchedules,
          }
        : undefined,
    };
  })
  .handleAction(shopEntryBannerScheduleActions.updateShopEntryBannerSchedule.success, (state, { payload }) => {
    const schedules = state?.detail?.shopEntryBannerSchedules ?? [];
    const updatedScheduleIndex = schedules.findIndex((schedule: ShopEntryBannerSchedule) => schedule.id === payload.id);

    if (updatedScheduleIndex !== -1) {
      schedules[updatedScheduleIndex] = payload;
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            shopEntryBannerSchedules: schedules,
          }
        : undefined,
    };
  })
  .handleAction(shopEntryBannerScheduleActions.deleteShopEntryBannerSchedule.success, (state, { payload }) => {
    const schedules = state?.detail?.shopEntryBannerSchedules ?? [];
    const newSchedules = schedules.filter((button: ShopEntryBannerSchedule) => button.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            shopEntryBannerSchedules: newSchedules,
          }
        : undefined,
    };
  });

export default shopEntryBanner;
