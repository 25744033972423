import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createBannerSetting,
  deleteBannerSetting,
  listBannerSetting,
  readBannerSetting,
  updateBannerSetting,
} from './actions';
import {
  createBannerSettingApi,
  deleteBannerSettingApi,
  listBannerSettingApi,
  readBannerSettingApi,
  updateBannerSettingApi,
} from './api';

const updateBannerSettingToastEpic = createToastEpic(updateBannerSetting.success, 'Successfully saved');

const createBannerSettingToastEpic = createToastEpic(createBannerSetting.success, 'Successfully created');

const listBannerSettingEpic = createListEpic(listBannerSetting, listBannerSettingApi);

const createBannerSettingEpic = createPostEpic(createBannerSetting, createBannerSettingApi);

const readBannerSettingEpic = createReadEpic(readBannerSetting, readBannerSettingApi);

const updateBannerSettingEpic = createUpdateEpic(updateBannerSetting, updateBannerSettingApi);

const deleteBannerSettingEpic = createDeleteEpic(deleteBannerSetting, deleteBannerSettingApi);

export default combineEpics(
  updateBannerSettingToastEpic,
  createBannerSettingToastEpic,
  listBannerSettingEpic,
  createBannerSettingEpic,
  readBannerSettingEpic,
  updateBannerSettingEpic,
  deleteBannerSettingEpic
);
