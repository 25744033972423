import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import { MatchFilterSettingActions as Actions, MatchFilterSettingDetail, MatchFilterSettingListItem } from './types';

export const listMatchFilterSetting = createAsyncAction(
  Actions.LIST_MATCH_FILTER_SETTINGS_REQUEST,
  Actions.LIST_MATCH_FILTER_SETTINGS_SUCCESS,
  Actions.LIST_MATCH_FILTER_SETTINGS_FAILURE
)<ListRequestPayload, ListState<MatchFilterSettingListItem>, AxiosError>();

export const readMatchFilterSetting = createAsyncAction(
  Actions.READ_MATCH_FILTER_SETTING_REQUEST,
  Actions.READ_MATCH_FILTER_SETTING_SUCCESS,
  Actions.READ_MATCH_FILTER_SETTING_FAILURE
)<ReadRequestPayload, MatchFilterSettingDetail, AxiosError>();
