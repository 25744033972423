import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic } from 'store/utils';

import { createVisitorAbTest, listVisitorAbTest, readVisitorAbTest, updateVisitorAbTest } from './actions';
import { createVisitorAbTestApi, listVisitorAbTestApi, readVisitorAbTestApi, updateVisitorAbTestApi } from './api';

const updateVisitorAbTestToastEpic = createToastEpic(updateVisitorAbTest.success, 'Visitor AB Test saved successfully');

const createVisitorAbTestEpic = createPostEpic(createVisitorAbTest, createVisitorAbTestApi);

const updateVisitorAbTestEpic = createPostEpic(updateVisitorAbTest, updateVisitorAbTestApi);

const listVisitorAbTestEpic = createListEpic(listVisitorAbTest, listVisitorAbTestApi);

const readVisitorAbTestEpic = createReadEpic(readVisitorAbTest, readVisitorAbTestApi);

export default combineEpics(
  updateVisitorAbTestToastEpic,
  createVisitorAbTestEpic,
  updateVisitorAbTestEpic,
  listVisitorAbTestEpic,
  readVisitorAbTestEpic
);
