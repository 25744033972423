import {
  SegmentDeclarationCreateRequestData,
  SegmentDeclarationCreateSuccessData,
  SegmentDeclarationUpdateRequestData,
  SegmentDeclarationUpdateSuccessData,
} from './types';

import { createPostApi, createUpdateApi } from 'store/utils';

export const createBigquerySegmentDeclarationApi = createPostApi<
  SegmentDeclarationCreateRequestData,
  SegmentDeclarationCreateSuccessData
>('/segment/bigquery/declaration');

export const updateBigquerySegmentDeclarationApi = createUpdateApi<
  SegmentDeclarationUpdateRequestData,
  SegmentDeclarationUpdateSuccessData
>('/segment/bigquery/:id/declaration');

export const acceptBigquerySegmentDeclarationApi = createUpdateApi<{}, {}>(
  '/segment/bigquery/:segmentCode/declaration/accept'
);

export const rejectBigquerySegmentDeclarationApi = createUpdateApi<{}, {}>(
  '/segment/bigquery/:segmentCode/declaration/reject'
);

export const manualTriggerBigquerySegmentApi = createPostApi<{}, {}>('/segment/bigquery/:segmentCode/manual-trigger');
