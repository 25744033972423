import { initialListState, ListState } from 'store/types';

export enum StringLocalizationActions {
  LIST_STRING_LOCALIZATIONS_REQUEST = 'LIST_STRING_LOCALIZATIONS#REQUEST',
  LIST_STRING_LOCALIZATIONS_SUCCESS = 'LIST_STRING_LOCALIZATIONS#SUCCESS',
  LIST_STRING_LOCALIZATIONS_FAILURE = 'LIST_STRING_LOCALIZATIONS#FAILURE',

  READ_STRING_LOCALIZATION_REQUEST = 'READ_STRING_LOCALIZATION#REQUEST',
  READ_STRING_LOCALIZATION_SUCCESS = 'READ_STRING_LOCALIZATION#SUCCESS',
  READ_STRING_LOCALIZATION_FAILURE = 'READ_STRING_LOCALIZATION#FAILURE',

  UPSERT_STRING_LOCALIZATION_BULK_REQUEST = 'UPSERT_STRING_LOCALIZATION_BULK#REQUEST',
  UPSERT_STRING_LOCALIZATION_BULK_SUCCESS = 'UPSERT_STRING_LOCALIZATION_BULK#SUCCESS',
  UPSERT_STRING_LOCALIZATION_BULK_FAILURE = 'UPSERT_STRING_LOCALIZATION_BULK#FAILURE',

  CREATE_STRING_LOCALIZATION_REQUEST = 'CREATE_STRING_LOCALIZATION#REQUEST',
  CREATE_STRING_LOCALIZATION_SUCCESS = 'CREATE_STRING_LOCALIZATION#SUCCESS',
  CREATE_STRING_LOCALIZATION_FAILURE = 'CREATE_STRING_LOCALIZATION#FAILURE',

  UPDATE_STRING_LOCALIZATION_REQUEST = 'UPDATE_STRING_LOCALIZATION#REQUEST',
  UPDATE_STRING_LOCALIZATION_SUCCESS = 'UPDATE_STRING_LOCALIZATION#SUCCESS',
  UPDATE_STRING_LOCALIZATION_FAILURE = 'UPDATE_STRING_LOCALIZATION#FAILURE',

  DELETE_STRING_LOCALIZATION_REQUEST = 'DELETE_STRING_LOCALIZATION#REQUEST',
  DELETE_STRING_LOCALIZATION_SUCCESS = 'DELETE_STRING_LOCALIZATION#SUCCESS',
  DELETE_STRING_LOCALIZATION_FAILURE = 'DELETE_STRING_LOCALIZATION#FAILURE',

  LIST_STRING_LOCALIZATION_HISTORY_REQUEST = 'LIST_STRING_LOCALIZATION_HISTORY#REQUEST',
  LIST_STRING_LOCALIZATION_HISTORY_SUCCESS = 'LIST_STRING_LOCALIZATION_HISTORY#SUCCESS',
  LIST_STRING_LOCALIZATION_HISTORY_FAILURE = 'LIST_STRING_LOCALIZATION_HISTORY#FAILURE',

  REFRESH_STRING_LOCALIZATION_REQUEST = 'REFRESH_STRING_LOCALIZATION#REQUEST',
  REFRESH_STRING_LOCALIZATION_SUCCESS = 'REFRESH_STRING_LOCALIZATION#SUCCESS',
  REFRESH_STRING_LOCALIZATION_FAILURE = 'REFRESH_STRING_LOCALIZATION#FAILURE',

  PASTE_STRING_LOCALIZATION = 'PASTE_STRING_LOCALIZATION',
}

export const initialState: StringLocalizationState = {
  list: initialListState,
  detail: undefined,
  history: initialListState,
};

export interface StringLocalizationState {
  list: ListState<StringLocalizationListItem>;
  detail?: StringLocalizationDetail;
  history: ListState<StringLocalizationHistoryListItem>;
}

export interface StringLocalizationLanguages {
  koValue: string;
  enValue: string;
  arValue: string;
  zhValue: string;
  zhCnValue: string;
  jaValue: string;
  ruValue: string;
  esValue: string;
  frValue: string;
  deValue: string;
  hiValue: string;
  ptValue: string;
  thValue: string;
  idValue: string;
  viValue: string;
  itValue: string;
  trValue: string;
  ptPtValue: string;
  esEsValue: string;
  svValue: string;
  nlValue: string;
  azValue: string;
}

export interface StringLocalization extends StringLocalizationLanguages {
  id: number;
  key: string;
  description: string;
}

export interface StringLocalizationListItem {
  id: number;
  key: string;
  description: string;
  koValue: string;
  enValue: string;
  languages: Omit<StringLocalizationLanguages, 'koValue' | 'enValue'>;
}

export type StringLocalizationDetail = StringLocalization;

export interface StringLocalizationRequestFormValues extends Omit<StringLocalization, 'id'> {}

export type StringLocalizationUpsertRequestData = StringLocalizationRequestFormValues;

export type StringLocalizationUpsertSuccessData = StringLocalization[];

export type StringLocalizationCreateRequestData = StringLocalizationRequestFormValues;

export type StringLocalizationCreateSuccessData = StringLocalizationDetail;

export type StringLocalizationUpdateRequestData = StringLocalizationRequestFormValues;

export type StringLocalizationUpdateSuccessData = StringLocalizationDetail;

export interface StringLocalizationHistoryListItem {
  id: number;
  type: string;
  tags: string[];
  createdAt: string;
  requester: string;
  updatedKey: string;
  entity: StringLocalizationDetail;
}
