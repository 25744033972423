export const environmentBackgroundImage = (hasBackground: boolean, backgroundText?: string) => {
  const canvas = document.createElement('canvas');
  if (!hasBackground) {
    return undefined;
  }
  const text = backgroundText;
  if (!text) {
    return undefined;
  }
  const fontSize = 135;
  const size = text.length * fontSize;
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext('2d');
  if (context) {
    context.translate(size / 2, size / 2);
    context.rotate(-Math.PI / 4);
    context.fillStyle = 'rgb(255, 192, 203, .3)';
    context.font = `${fontSize}px sans-serif`;
    context.textAlign = 'center';
    context.fillText(text.toUpperCase(), 0, fontSize / 2);
  }
  return `url('${canvas.toDataURL('image/png')}')`;
};
