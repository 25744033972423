import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import {
  createPromotionContentGroup,
  listPromotionContentGroup,
  readPromotionContentGroup,
  updatePromotionContentGroup,
  listPromotionContentGroupSearch,
} from './actions';
import {
  createPromotionContentGroupApi,
  listPromotionContentGroupApi,
  readPromotionContentGroupApi,
  updatePromotionContentGroupApi,
  listPromotionContentGroupSearchApi,
} from './api';

const listPromotionContentGroupEpic = createListEpic(listPromotionContentGroup, listPromotionContentGroupApi);

const listPromotionContentGroupSearchEpic = createListEpic(
  listPromotionContentGroupSearch,
  listPromotionContentGroupSearchApi
);

const readPromotionContentGroupEpic = createReadEpic(readPromotionContentGroup, readPromotionContentGroupApi);

const createPromotionContentGroupEpic = createPostEpic(createPromotionContentGroup, createPromotionContentGroupApi);

const createPromotionContentGroupToastEpic = createToastEpic(
  createPromotionContentGroup.success,
  'Successfully created'
);

const updatePromotionContentGroupEpic = createUpdateEpic(updatePromotionContentGroup, updatePromotionContentGroupApi);

const updatePromotionContentGroupToastEpic = createToastEpic(updatePromotionContentGroup.success, 'Successfully saved');

export default combineEpics(
  listPromotionContentGroupEpic,
  listPromotionContentGroupSearchEpic,
  readPromotionContentGroupEpic,
  createPromotionContentGroupEpic,
  createPromotionContentGroupToastEpic,
  updatePromotionContentGroupEpic,
  updatePromotionContentGroupToastEpic
);
