import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState } from 'store/types';

import { MatchFilterOptionActions as Actions, MatchFilterOptionListItem } from './types';

export const listMatchFilterOption = createAsyncAction(
  Actions.LIST_MATCH_FILTER_OPTIONS_REQUEST,
  Actions.LIST_MATCH_FILTER_OPTIONS_SUCCESS,
  Actions.LIST_MATCH_FILTER_OPTIONS_FAILURE
)<ListRequestPayload, ListState<MatchFilterOptionListItem>, AxiosError>();
