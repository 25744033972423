import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FlatListRequestPayload, FlatListState } from 'store/types';

import { DecoColorPresetActions as Actions, DecoColorPresetListItem } from './types';

export const listDecoColorPreset = createAsyncAction(
  Actions.LIST_DECO_COLOR_PRESET_REQUEST,
  Actions.LIST_DECO_COLOR_PRESET_SUCCESS,
  Actions.LIST_DECO_COLOR_PRESET_FAILURE
)<FlatListRequestPayload, FlatListState<DecoColorPresetListItem>, AxiosError>();
