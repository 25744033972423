import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { cancelGemPurchase } from './actions';
import { cancelGemPurchaseWithdrawApi } from './api';

const provideCancelGemPurchaseToastEpic = createToastEpic(cancelGemPurchase.success, 'Successfully canceled');

const provideCancelGemPurchaseEpic = createUpdateEpic(cancelGemPurchase, cancelGemPurchaseWithdrawApi);

export default combineEpics(provideCancelGemPurchaseToastEpic, provideCancelGemPurchaseEpic);
