import { createFormApi, RequestOption } from 'store/utils';

import { BlockPhoneManageByUserIdsDeleteRequestData, BlockPhoneManageDeleteRequestData } from './types';

const requestOption: RequestOption = { method: 'delete' };

export const deleteBlockPhoneManageApi = createFormApi<BlockPhoneManageDeleteRequestData, undefined>(
  '/operation/block-phone',
  requestOption
);

export const deleteBlockPhoneManageByUserIdsApi = createFormApi<BlockPhoneManageByUserIdsDeleteRequestData, undefined>(
  '/operation/block-phone-by-user-id',
  requestOption
);
