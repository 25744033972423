import React, { FC } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  text: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
  },
}));

const NotFoundPage: FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapper} container direction='column' alignItems='center' justify='center' spacing={4}>
      <Grid item>
        <Typography variant='h1'>Sorry, page not found!</Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.text} variant='body1'>
          Sorry, we couldn’t find the page you’re looking for.
          <br />
          Perhaps you’ve mistyped the URL? Be sure to check your spelling.
        </Typography>
      </Grid>

      <Grid item>
        <Button<typeof Link> component={Link} to='/' color='primary' variant='contained'>
          Go To Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
