import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  MlConfigFeatureCreateRequestData,
  MlConfigFeatureCreateRequestFormValues,
  MlConfigFeatureCreateSuccessData,
  MlConfigFeatureDetail,
  MlConfigFeatureListItem,
  MlConfigFeatureUpdateRequestData,
  MlConfigFeatureUpdateRequestFormValues,
  MlConfigFeatureUpdateSuccessData,
} from './types';

const BASE_PATH = '/ml-config/features';
export const listMlConfigFeatureApi = createListApi<MlConfigFeatureListItem>(BASE_PATH);
export const createMlConfigFeatureApi = createPostApi<
  MlConfigFeatureCreateRequestFormValues,
  MlConfigFeatureCreateRequestData,
  MlConfigFeatureCreateSuccessData
>(BASE_PATH);
export const readMlConfigFeatureApi = createReadApi<MlConfigFeatureDetail>(`${BASE_PATH}/:id`);
export const updateMlConfigFeatureApi = createUpdateApi<
  MlConfigFeatureUpdateRequestFormValues,
  MlConfigFeatureUpdateRequestData,
  MlConfigFeatureUpdateSuccessData
>(`${BASE_PATH}/:id`);
