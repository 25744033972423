import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createListEpic, createPostEpic, createToastEpic } from 'store/utils';

import {
  deleteMatchFilterOptionListing,
  listMatchFilterOptionListing,
  upsertMatchFilterOptionListing,
} from './actions';
import {
  deleteMatchFilterOptionListingApi,
  listMatchFilterOptionListingApi,
  upsertMatchFilterOptionListingApi,
} from './api';

const listMatchFilterOptionListingEpic = createListEpic(listMatchFilterOptionListing, listMatchFilterOptionListingApi);

const upsertMatchFilterOptionListingEpic = createPostEpic(
  upsertMatchFilterOptionListing,
  upsertMatchFilterOptionListingApi
);

const deleteMatchFilterOptionListingEpic = createDeleteEpic(
  deleteMatchFilterOptionListing,
  deleteMatchFilterOptionListingApi
);

const deleteMatchFilterOptionListingToastEpic = createToastEpic(
  deleteMatchFilterOptionListing.success,
  'Successfully deleted'
);

export default combineEpics(
  listMatchFilterOptionListingEpic,
  upsertMatchFilterOptionListingEpic,
  deleteMatchFilterOptionListingEpic,
  deleteMatchFilterOptionListingToastEpic
);
