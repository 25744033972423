import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  ExpressionSegmentCreateRequestData,
  ExpressionSegmentCreateSuccessData,
  ExpressionSegmentDetail,
  ExpressionSegmentListItem,
  ExpressionSegmentUpdateRequestData,
  ExpressionSegmentUpdateSuccessData,
} from './types';

export const listExpressionSegmentApi = createListApi<ExpressionSegmentListItem>('/segment/expression');
export const createExpressionSegmentApi = createPostApi<
  ExpressionSegmentCreateRequestData,
  ExpressionSegmentCreateSuccessData
>('/segment/expression');
export const readExpressionSegmentApi = createReadApi<ExpressionSegmentDetail>('/segment/expression/:id');
export const updateExpressionSegmentApi = createUpdateApi<
  ExpressionSegmentUpdateRequestData,
  ExpressionSegmentUpdateSuccessData
>('/segment/expression/:id');
export const deleteExpressionSegmentApi = createDeleteApi('/segment/expression/:id');
