import React, { FC, useCallback, useMemo, useState } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { Link } from 'react-router-dom';

import MenuCollapseContext from 'contexts/menuCollapseContext';
import useIsSamePathname from 'hooks/useIsSamePathname';

import { MenuType, MenuProps as Props } from './types';

interface StyleProps {
  isOpenCollapse: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  collapseIcon: {
    transform: (props) => (props.isOpenCollapse ? 'rotate(-180deg)' : 'rotate(0deg)'),
    transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
});

const Menu: FC<Props> = ({
  type = MenuType.DEFAULT,
  label = '',
  IconComponent,
  path = '',
  openCollapse = false,
  children,
}) => {
  const isCollapseMenu = useMemo(() => type === MenuType.COLLAPSE, [type]);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(isCollapseMenu ? openCollapse : false);

  const classes = useStyles({ isOpenCollapse });

  const isSelected = useIsSamePathname(path);

  const handleClick = useCallback(() => {
    if (isCollapseMenu) {
      setIsOpenCollapse((prevState) => !prevState);
    }
  }, [isCollapseMenu]);

  const renderMenu = useCallback(
    () => (
      <ListItem button selected={isSelected} onClick={handleClick}>
        <ListItemIcon>
          <IconComponent />
        </ListItemIcon>

        <ListItemText primary={label} />

        {isCollapseMenu && <ExpandMoreRoundedIcon className={classes.collapseIcon} />}
      </ListItem>
    ),
    [isSelected, isCollapseMenu, IconComponent, label, handleClick, classes.collapseIcon]
  );

  return (
    <>
      {isCollapseMenu ? renderMenu() : <Link to={path}>{renderMenu()}</Link>}

      {isCollapseMenu && (
        <MenuCollapseContext.Provider
          value={{
            isOpenCollapse,
            setIsOpenCollapse,
          }}
        >
          {children}
        </MenuCollapseContext.Provider>
      )}
    </>
  );
};

export default Menu;
