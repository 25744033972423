import LogRocket from 'logrocket';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { Persistor, persistStore } from 'redux-persist';

import { IS_DEV } from 'constants/env';
import { RootAction, RootState } from 'store/root/types';
import { setBaseURL, setToken } from 'utils/axios';

import rootEpic from './epic';
import rootReducer from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loggerMiddleware = createLogger({ collapsed: true });
const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>();

const persistHandler = (store: Store<RootState>) => () => {
  const state = store.getState();
  const token = state?.user?.access;
  if (token) {
    setToken(token);
  }
  const { presets, currentPresetIndex } = state?.setting || {};
  const { azarApiUrl, matchingApiUrl } = presets[currentPresetIndex];
  setBaseURL({ azarApi: azarApiUrl, matchingApi: matchingApiUrl });
};

export default (): [Store<RootState>, Persistor] => {
  const middlewares: any[] = [epicMiddleware];

  if (IS_DEV) {
    middlewares.push(loggerMiddleware);
  } else {
    middlewares.push(LogRocket.reduxMiddleware());
  }

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

  epicMiddleware.run(rootEpic);

  return [store, persistStore(store, undefined, persistHandler(store))];
};
