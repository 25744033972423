import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createNotificationCenterBanner,
  listNotificationCenterBanner,
  readNotificationCenterBanner,
  refreshNotificationCenterBanner,
  updateNotificationCenterBanner,
} from './actions';
import {
  createNotificationCenterBannerApi,
  listNotificationCenterBannerApi,
  readNotificationCenterBannerApi,
  refreshNotificationCenterBannerApi,
  updateNotificationCenterBannerApi,
} from './api';

const updateNotificationCenterBannerToastEpic = createToastEpic(
  updateNotificationCenterBanner.success,
  'Successfully saved'
);

const createNotificationCenterBannerToastEpic = createToastEpic(
  createNotificationCenterBanner.success,
  'Successfully created'
);

const refreshNotificationCenterBannerToastEpic = createToastEpic(
  refreshNotificationCenterBanner.success,
  'Successfully refreshed server'
);

const listNotificationCenterBannerEpic = createListEpic(listNotificationCenterBanner, listNotificationCenterBannerApi);

const createNotificationCenterBannerEpic = createPostEpic(
  createNotificationCenterBanner,
  createNotificationCenterBannerApi
);

const readNotificationCenterBannerEpic = createReadEpic(readNotificationCenterBanner, readNotificationCenterBannerApi);

const updateNotificationCenterBannerEpic = createUpdateEpic(
  updateNotificationCenterBanner,
  updateNotificationCenterBannerApi
);

const refreshNotificationCenterBannerEpic = createAsyncEpic(
  refreshNotificationCenterBanner,
  refreshNotificationCenterBannerApi
);

export default combineEpics(
  updateNotificationCenterBannerToastEpic,
  createNotificationCenterBannerToastEpic,
  refreshNotificationCenterBannerToastEpic,
  listNotificationCenterBannerEpic,
  createNotificationCenterBannerEpic,
  readNotificationCenterBannerEpic,
  updateNotificationCenterBannerEpic,
  refreshNotificationCenterBannerEpic
);
