import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as imageSettingActions from './actions';
import { ImageSettingState, initialState } from './types';

// noinspection TypeScriptValidateJSTypes
const imageSetting = createReducer<ImageSettingState, RootAction>(initialState)
  .handleAction(
    imageSettingActions.createImageSetting.success,
    (state, { payload: { imageUrl }, meta: { fetchKey } }) => ({
      ...state,
      [fetchKey]: imageUrl,
    })
  )
  .handleAction(imageSettingActions.resetImageSetting, (state, { payload: fetchKey }) => ({
    ...state,
    [fetchKey]: undefined,
  }));

export default imageSetting;
