export interface ConvertUserKeyState {
  result: string[];
}

export enum ConvertUserKeyActions {
  CONVERT_USER_IDS_TO_USERNAMES_REQUEST = 'CONVERT_USER_IDS_TO_USERNAMES#REQUEST',
  CONVERT_USER_IDS_TO_USERNAMES_SUCCESS = 'CONVERT_USER_IDS_TO_USERNAMES#SUCCESS',
  CONVERT_USER_IDS_TO_USERNAMES_FAILURE = 'CONVERT_USER_IDS_TO_USERNAMES#FAILURE',

  CONVERT_USERNAMES_TO_USER_IDS_REQUEST = 'CONVERT_USERNAMES_TO_USER_IDS#REQUEST',
  CONVERT_USERNAMES_TO_USER_IDS_SUCCESS = 'CONVERT_USERNAMES_TO_USER_IDS#SUCCESS',
  CONVERT_USERNAMES_TO_USER_IDS_FAILURE = 'CONVERT_USERNAMES_TO_USER_IDS#FAILURE',

  RESET_CONVERT_USER_KEY_RESULT = 'RESET_CONVERT_USER_KEY_RESULT',
}

export const initialState: ConvertUserKeyState = { result: [] };

export interface ConvertUserKeyTypeFormValues {
  type: ConvertUserKeyType;
}

export enum ConvertUserKeyType {
  USER_IDS_TO_USERNAMES = 'USER_IDS_TO_USERNAMES',
  USERNAMES_TO_USER_IDS = 'USERNAMES_TO_USER_IDS',
}

export interface ConvertUsernamesToUserIdsRequestFormValues {
  usernames: string;
}

export interface ConvertUsernamesToUserIdsRequestData {
  usernames: ConvertUserKeyState['result'];
}

export type ConvertUsernamesToUserIdsSuccessData = ConvertUserIdsToUsernamesRequestData;

export interface ConvertUserIdsToUsernamesRequestFormValues {
  userIds: string;
}

export interface ConvertUserIdsToUsernamesRequestData {
  userIds: ConvertUserKeyState['result'];
}

export type ConvertUserIdsToUsernamesSuccessData = ConvertUsernamesToUserIdsRequestData;
