import React, { FC, useContext } from 'react';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { DraggableCollapseMenuProps as Props } from 'components/Menu/types';
import MenuCollapseContext from 'contexts/menuCollapseContext';

const useStyles = makeStyles((theme) => ({
  collapseListItem: {
    height: 50,
    paddingLeft: theme.spacing(4),
  },
}));

const getDraggingItemStyle = (theme: Theme, isDragging: boolean) => ({
  ...(isDragging && { background: theme.palette.action.selected }),
});

const DraggableCollapseMenu: FC<Props> = ({
  timeout = 'auto',
  unmountOnExit = false,
  collapseMenus = [],
  onDragEnd,
  style = {},
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { isOpenCollapse } = useContext(MenuCollapseContext);

  return (
    <Collapse in={isOpenCollapse} timeout={timeout} unmountOnExit={unmountOnExit}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='Sidebar'>
          {(droppableContext) => (
            <List ref={droppableContext.innerRef} disablePadding {...droppableContext.droppableProps}>
              {collapseMenus.map((menu, menuIndex) => (
                <Draggable key={menu.path} draggableId={menu.path} index={menuIndex}>
                  {(draggableContext, draggableSnapshot) => (
                    <ListItem<typeof Link>
                      innerRef={draggableContext.innerRef}
                      className={classes.collapseListItem}
                      button
                      component={Link}
                      to={menu.path}
                      {...draggableContext.draggableProps}
                      {...draggableContext.dragHandleProps}
                      style={{
                        ...style,
                        ...draggableContext.draggableProps.style,
                        ...getDraggingItemStyle(theme, draggableSnapshot.isDragging),
                      }}
                    >
                      {menu.render ? menu.render : <ListItemText secondary={menu.label} />}
                    </ListItem>
                  )}
                </Draggable>
              ))}

              {droppableContext.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Collapse>
  );
};
export default DraggableCollapseMenu;
