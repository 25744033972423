import { createDeleteApi, createListApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  CriteriaSegmentResponseData,
  CriteriaSegmentDetail,
  CriteriaSegmentListItem,
  CriteriaSegmentUpdateRequestData,
} from './types';

export const listCriteriaSegmentApi = createListApi<CriteriaSegmentListItem>('/segment/criteria');
export const readCriteriaSegmentApi = createReadApi<CriteriaSegmentDetail>('/segment/criteria/:id');
export const updateCriteriaSegmentApi = createUpdateApi<CriteriaSegmentUpdateRequestData, CriteriaSegmentResponseData>(
  '/segment/criteria/:id'
);
export const deleteCriteriaSegmentApi = createDeleteApi('/segment/criteria/:id');
