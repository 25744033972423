import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import {
  NotWithdrawRequestPayload,
  MatataInvalidatedPurchaseByReceiver,
  MatataInvalidatedPurchaseByReceiverActions as Actions,
  WithdrawRequestPayload,
} from './types';

export const readMatataInvalidatedPurchaseByReceiver = createAsyncAction(
  Actions.READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST,
  Actions.READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS,
  Actions.READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE
)<ReadRequestPayload, MatataInvalidatedPurchaseByReceiver, AxiosError>();

export const listMatataInvalidatedPurchaseByReceiver = createAsyncAction(
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST,
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS,
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE
)<ListRequestPayload, ListState<MatataInvalidatedPurchaseByReceiver>, AxiosError>();

export const withdrawMatataInvalidatedPurchaseByReceiver = createAsyncAction(
  Actions.WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST,
  Actions.WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS,
  Actions.WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE
)<FormRequestPayload<WithdrawRequestPayload>, undefined, AxiosError>();

export const notWithdrawMatataInvalidatedPurchaseByReceiver = createAsyncAction(
  Actions.NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST,
  Actions.NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS,
  Actions.NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE
)<FormRequestPayload<NotWithdrawRequestPayload>, undefined, AxiosError>();
