import React, { useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useOktaAuth } from '@okta/okta-react';

const Logout: React.FC = () => {
  const { oktaAuth } = useOktaAuth();

  const handleClickLogin = useCallback(() => {
    oktaAuth.signInWithRedirect({ originalUri: `${window.location.origin}` });
  }, [oktaAuth]);

  useEffect(() => {
    // 로그아웃 후, redirect로 돌아왔을 때 인증 정보 삭제
    oktaAuth.tokenManager.clear();
  }, [oktaAuth]);

  return (
    <>
      <Grid container justify='center'>
        <Box px={12} py={5} color='primary.main' bgcolor='background.paper' borderRadius='10px' boxShadow='1'>
          <h1>👋 Logout!</h1>
          <Box pt={5}>
            <Button variant='outlined' color='secondary' onClick={handleClickLogin}>
              Login with Okta
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Logout;
