import { RootAction } from 'store/root/types';
import { createReducer } from 'typesafe-actions';
import { ShopModuleState, initialState } from './types';
import * as shopModuleActions from './actions';

const shopModule = createReducer<ShopModuleState, RootAction>(initialState)
  .handleAction(shopModuleActions.listShopModulePage.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(shopModuleActions.listShopModulePage.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(shopModuleActions.readShopModulePage.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default shopModule;
