import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  FileS3UploadActions as Actions,
  FileS3UploadCreateRequestData,
  FileS3UploadCreateResponseData,
  FileS3UploadDetail,
  FileS3UploadFormValues,
  FileS3UploadListItem,
  FileS3UploadUpdateRequestData,
  FileS3UploadUpdateResponseData,
  MultiFileUploadRequestData,
  MultiFileUploadResponseData,
  SingleFileUploadRequestData,
  SingleFileUploadResponseData,
} from './types';

export const listFileS3Upload = createAsyncAction(
  Actions.LIST_FILE_S3_UPLOAD_REQUEST,
  Actions.LIST_FILE_S3_UPLOAD_SUCCESS,
  Actions.LIST_FILE_S3_UPLOAD_FAILURE
)<ListRequestPayload, ListState<FileS3UploadListItem>, AxiosError>();

export const readFileS3Upload = createAsyncAction(
  Actions.READ_FILE_S3_UPLOAD_REQUEST,
  Actions.READ_FILE_S3_UPLOAD_SUCCESS,
  Actions.READ_FILE_S3_UPLOAD_FAILURE
)<ReadRequestPayload, FileS3UploadDetail, AxiosError>();

export const createFileS3Upload = createAsyncAction(
  Actions.CREATE_FILE_S3_UPLOAD_REQUEST,
  Actions.CREATE_FILE_S3_UPLOAD_SUCCESS,
  Actions.CREATE_FILE_S3_UPLOAD_FAILURE
)<
  FormRequestPayload<FileS3UploadFormValues, FileS3UploadCreateRequestData>,
  FileS3UploadCreateResponseData,
  AxiosError
>();

export const updateFileS3Upload = createAsyncAction(
  Actions.UPDATE_FILE_S3_UPLOAD_REQUEST,
  Actions.UPDATE_FILE_S3_UPLOAD_SUCCESS,
  Actions.UPDATE_FILE_S3_UPLOAD_FAILURE
)<
  FormRequestPayload<FileS3UploadFormValues, FileS3UploadUpdateRequestData>,
  FileS3UploadUpdateResponseData,
  AxiosError
>();

export const deleteFileS3Upload = createAsyncAction(
  Actions.DELETE_FILE_S3_UPLOAD_REQUEST,
  Actions.DELETE_FILE_S3_UPLOAD_SUCCESS,
  Actions.DELETE_FILE_S3_UPLOAD_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const uploadSingleFile = createAsyncAction(
  Actions.UPLOAD_SINGLE_FILE_REQUEST,
  Actions.UPLOAD_SINGLE_FILE_SUCCESS,
  Actions.UPLOAD_SINGLE_FILE_FAILURE
)<
  FormRequestPayload<SingleFileUploadRequestData, SingleFileUploadRequestData>,
  SingleFileUploadResponseData,
  AxiosError
>();

export const uploadMultiFile = createAsyncAction(
  Actions.UPLOAD_MULTI_FILE_REQUEST,
  Actions.UPLOAD_MULTI_FILE_SUCCESS,
  Actions.UPLOAD_MULTI_FILE_FAILURE
)<
  FormRequestPayload<MultiFileUploadRequestData, MultiFileUploadRequestData>,
  MultiFileUploadResponseData,
  AxiosError
>();
