import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  PurposeOptionActions as Actions,
  PurposeOptionCreateRequestData,
  PurposeOptionCreateResponseData,
  PurposeOptionFormValues,
  PurposeOptionUpdateRequestData,
  PurposeOptionUpdateResponseData,
} from './types';

export const createPurposeOption = createAsyncAction(
  Actions.CREATE_PURPOSE_OPTION_REQUEST,
  Actions.CREATE_PURPOSE_OPTION_SUCCESS,
  Actions.CREATE_PURPOSE_OPTION_FAILURE
)<
  FormRequestPayload<PurposeOptionFormValues, PurposeOptionCreateRequestData>,
  PurposeOptionCreateResponseData,
  AxiosError
>();

export const updatePurposeOption = createAsyncAction(
  Actions.UPDATE_PURPOSE_OPTION_REQUEST,
  Actions.UPDATE_PURPOSE_OPTION_SUCCESS,
  Actions.UPDATE_PURPOSE_OPTION_SUCCESS
)<
  FormRequestPayload<PurposeOptionFormValues, PurposeOptionUpdateRequestData>,
  PurposeOptionUpdateResponseData,
  AxiosError
>();

export const deletePurposeOption = createAsyncAction(
  Actions.DELETE_PURPOSE_OPTION_REQUEST,
  Actions.DELETE_PURPOSE_OPTION_SUCCESS,
  Actions.DELETE_PURPOSE_OPTION_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
