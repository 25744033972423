import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState } from 'store/types';

import { MoniNoticeActions as Actions, DetailMoniNoticeRequest, MoniNoticeFormValues, MoniNoticeItem } from './types';

export const listMoniNotice = createAsyncAction(
  Actions.LIST_MONI_NOTICE_REQUEST,
  Actions.LIST_MONI_NOTICE_SUCCESS,
  Actions.LIST_MONI_NOTICE_FAILURE
)<ListRequestPayload, ListState<MoniNoticeItem>, AxiosError>();

export const detailMoniNotice = createAsyncAction(
  Actions.DETAIL_MONI_NOTICE_REQUEST,
  Actions.DETAIL_MONI_NOTICE_SUCCESS,
  Actions.DETAIL_MONI_NOTICE_FAILURE
)<DetailMoniNoticeRequest, MoniNoticeItem, AxiosError>();

export const postMoniNotice = createAsyncAction(
  Actions.POST_MONI_NOTICE_REQUEST,
  Actions.POST_MONI_NOTICE_SUCCESS,
  Actions.POST_MONI_NOTICE_FAILURE
)<FormRequestPayload<MoniNoticeFormValues>, undefined, AxiosError>();
