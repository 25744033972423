import {
  QueryClient,
  QueryObserverOptions,
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
} from 'react-query';
import { QueryFilters, Updater } from 'react-query/types/core/utils';
import { setToken } from './axios';
import { updateToken, updateUserCacheAccessToken } from './okta';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError: (error: any) => {
        if (error.response && error.response.status === 401) {
          // react-query 401 발생 시, Refresh token 시도
          console.log('React query 401 Unauthorized - Refreshing token');
          updateToken()
            .then((updatedAccessToken) => {
              // epic, react-query가 같은 axios client 써서 util로 토큰 업데이트
              setToken(updatedAccessToken);
              updateUserCacheAccessToken(updatedAccessToken);
              // epic과 달리 요청 재시도를 할만한 정보가 부족하여 새로고침
              alert('인증이 만료되어 새로고침 됩니다. 다시 시도해주세요. Auth is expired. Please try again.');
              window.location.reload();
            })
            .catch((err) => {
              console.log('Failed to update token');
              // Refresh token 실패하면 로그아웃 처리
              setToken('');
              localStorage.removeItem('persist:user');
              alert('인증이 만료되어 다시 로그인 해주세요. Auth is expired. Please login.');
              window.location.reload();
            });
        }
      },
    },
  },
});

export type Payload<Fetcher> = Fetcher extends (payload: infer P) => unknown ? P : never;
export type Response<Fetcher> = Fetcher extends (...args: any[]) => Promise<infer R> ? R : never;

export type ApiQueryResult<Fetcher> = QueryObserverResult<Response<Fetcher>, any>;
export type ApiQueryOptions<Fetcher> = QueryObserverOptions<Response<Fetcher>>;
export type ApiMutationResult<Fetcher> = UseMutationResult<Response<Fetcher>, any, Payload<Fetcher>>;
export type ApiMutationOptions<Fetcher> = UseMutationOptions<Response<Fetcher>>;
export type RefetchQueryFilters = QueryFilters;
export type RefetchQueryOptions = RefetchOptions;
export type MutateQueryUpdater<Fetcher> = Updater<Response<Fetcher> | undefined, Response<Fetcher> | undefined>;

export function createApiResource<
  ApiQueryClient extends Record<string, (...args: any[]) => Promise<unknown>>,
  ApiMutationClient extends Record<string, (...args: any[]) => Promise<unknown>>
>(
  resourceName: string,
  {
    useApiQuery: apiQueryClient,
    useApiMutation: apiMutationClient,
  }: {
    useApiQuery: ApiQueryClient;
    useApiMutation: ApiMutationClient;
  }
) {
  function useApiQuery<OperationId extends keyof ApiQueryClient>(
    operationId: OperationId,
    payload: Payload<ApiQueryClient[OperationId]>,
    options: ApiQueryOptions<ApiQueryClient[OperationId]> = {}
  ): ApiQueryResult<ApiQueryClient[OperationId]> {
    const query = useQuery<Response<ApiQueryClient[OperationId]>>(
      [resourceName, operationId, payload],
      async () => apiQueryClient[operationId](payload) as any,
      options
    );

    return query;
  }

  function useApiMutation<OperationId extends keyof ApiMutationClient>(
    operationId: OperationId,
    options: ApiMutationOptions<ApiMutationClient[OperationId]> = {}
  ): ApiMutationResult<ApiMutationClient[OperationId]> {
    const mutation = useMutation<
      Response<ApiMutationClient[OperationId]>,
      unknown,
      Payload<ApiMutationClient[OperationId]>
    >([resourceName, operationId], async (payload) => apiMutationClient[operationId](payload) as any, options);

    return mutation;
  }

  function refetchQuery<OperationId extends keyof ApiQueryClient>(
    operationId: OperationId,
    payload: Payload<ApiQueryClient[OperationId]>,
    filters?: QueryFilters,
    options?: RefetchOptions
  ) {
    return queryClient.refetchQueries([resourceName, operationId, payload], filters, options);
  }

  function mutateQuery<OperationId extends keyof ApiQueryClient>(
    operationId: OperationId,
    payload: Payload<ApiQueryClient[OperationId]>,
    updater: MutateQueryUpdater<ApiQueryClient[OperationId]>
  ) {
    queryClient.setQueryData<Response<ApiQueryClient[OperationId]> | undefined>(
      [resourceName, operationId, payload],
      updater
    );
  }

  function mutateAndRefetchQuery<OperationId extends keyof ApiQueryClient>(
    operationId: OperationId,
    payload: Payload<ApiQueryClient[OperationId]>,
    updater: MutateQueryUpdater<ApiQueryClient[OperationId]>
  ) {
    mutateQuery(operationId, payload, updater);
    return refetchQuery(operationId, payload);
  }

  return {
    useApiQuery,
    useApiMutation,
    refetchQuery,
    mutateQuery,
    mutateAndRefetchQuery,
    apiQueryClient,
    apiMutationClient,
  };
}
