import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  WebDecoSettingEffectActions as Actions,
  WebDecoSettingEffectCreateRequestData,
  WebDecoSettingEffectCreateResponseData,
  WebDecoSettingEffectFormValues,
  WebDecoSettingEffectUpdateRequestData,
  WebDecoSettingEffectUpdateResponseData,
} from './types';

export const createWebDecoSettingEffect = createAsyncAction(
  Actions.CREATE_WEB_DECO_SETTING_EFFECT_REQUEST,
  Actions.CREATE_WEB_DECO_SETTING_EFFECT_SUCCESS,
  Actions.CREATE_WEB_DECO_SETTING_EFFECT_FAILURE
)<
  FormRequestPayload<WebDecoSettingEffectFormValues, WebDecoSettingEffectCreateRequestData>,
  WebDecoSettingEffectCreateResponseData,
  AxiosError
>();

export const updateWebDecoSettingEffect = createAsyncAction(
  Actions.UPDATE_WEB_DECO_SETTING_EFFECT_REQUEST,
  Actions.UPDATE_WEB_DECO_SETTING_EFFECT_SUCCESS,
  Actions.UPDATE_WEB_DECO_SETTING_EFFECT_FAILURE
)<
  FormRequestPayload<WebDecoSettingEffectFormValues, WebDecoSettingEffectUpdateRequestData>,
  WebDecoSettingEffectUpdateResponseData,
  AxiosError
>();

export const deleteWebDecoSettingEffect = createAsyncAction(
  Actions.DELETE_WEB_DECO_SETTING_EFFECT_REQUEST,
  Actions.DELETE_WEB_DECO_SETTING_EFFECT_SUCCESS,
  Actions.DELETE_WEB_DECO_SETTING_EFFECT_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
