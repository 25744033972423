import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as userSegmentActions from './actions';
import { initialState, ProfileManageState } from './types';

const userSegment = createReducer<ProfileManageState, RootAction>(initialState)
  .handleAction(userSegmentActions.readProfileManage.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(userSegmentActions.updateProfileManage.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default userSegment;
