import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createWebDecoSettingSchedule, deleteWebDecoSettingSchedule, updateWebDecoSettingSchedule } from './actions';
import {
  createWebDecoSettingScheduleApi,
  deleteWebDecoSettingScheduleApi,
  updateWebDecoSettingScheduleApi,
} from './api';

const createWebDecoSettingScheduleEpic = createPostEpic(createWebDecoSettingSchedule, createWebDecoSettingScheduleApi);

const createWebDecoSettingScheduleToastEpic = createToastEpic(
  createWebDecoSettingSchedule.success,
  'Successfully created'
);

const updateWebDecoSettingScheduleEpic = createUpdateEpic(
  updateWebDecoSettingSchedule,
  updateWebDecoSettingScheduleApi
);

const updateWebDecoSettingScheduleToastEpic = createToastEpic(
  updateWebDecoSettingSchedule.success,
  'Successfully updated'
);

const deleteWebDecoSettingScheduleEpic = createDeleteEpic(
  deleteWebDecoSettingSchedule,
  deleteWebDecoSettingScheduleApi
);

const deleteWebDecoSettingScheduleToastEpic = createToastEpic(
  deleteWebDecoSettingSchedule.success,
  'Successfully deleted'
);

export default combineEpics(
  createWebDecoSettingScheduleEpic,
  createWebDecoSettingScheduleToastEpic,
  updateWebDecoSettingScheduleEpic,
  updateWebDecoSettingScheduleToastEpic,
  deleteWebDecoSettingScheduleEpic,
  deleteWebDecoSettingScheduleToastEpic
);
