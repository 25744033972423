import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as invalidatedPurchaseActions from './actions';
import { initialState, InvalidatedPurchaseState } from './types';

const invalidatedPurchase = createReducer<InvalidatedPurchaseState, RootAction>(initialState)
  .handleAction(invalidatedPurchaseActions.readInvalidatedPurchase.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(
    invalidatedPurchaseActions.listInvalidatedPurchase.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(invalidatedPurchaseActions.listInvalidatedPurchase.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default invalidatedPurchase;
