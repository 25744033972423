import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, Props>({
  box: ({ fullWidth, fullHeight }) => ({
    padding: 0,
    width: fullWidth ? '100%' : 'auto',
    height: fullHeight ? '100%' : 'auto',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
});

interface Props {
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const LoadingIndicator: FC<Props> = ({ fullWidth = false, fullHeight = false }) => {
  const classes = useStyles({
    fullWidth,
    fullHeight,
  });

  return (
    <Box className={classes.box}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingIndicator;
