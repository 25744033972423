import {
  GemSettingMapEntryCreateRequestData,
  GemSettingMapEntryCreateSuccessData,
  GemSettingMapEntryUpdateRequestData,
  GemSettingMapEntryUpdateSuccessData,
} from 'store/gemSettingMapEntry/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createGemSettingMapEntryApi = createPostApi<
  GemSettingMapEntryCreateRequestData,
  GemSettingMapEntryCreateSuccessData
>('/gem_shops/setting_map_entries');
export const updateGemSettingMapEntryApi = createUpdateApi<
  GemSettingMapEntryUpdateRequestData,
  GemSettingMapEntryUpdateSuccessData
>('/gem_shops/setting_map_entries/:id');
export const deleteGemSettingMapEntryApi = createDeleteApi('/gem_shops/setting_map_entries/:id');
