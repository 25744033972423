import { DeleteBodyRequestPayload } from '../../store/types';

export enum PromotionScheduleTargetActions {
  CREATE_PROMOTION_SCHEDULE_TARGET_REQUEST = 'CREATE_PROMOTION_SCHEDULE_TARGET#REQUEST',
  CREATE_PROMOTION_SCHEDULE_TARGET_SUCCESS = 'CREATE_PROMOTION_SCHEDULE_TARGET#SUCCESS',
  CREATE_PROMOTION_SCHEDULE_TARGET_FAILURE = 'CREATE_PROMOTION_SCHEDULE_TARGET#FAILURE',

  UPDATE_PROMOTION_SCHEDULE_TARGET_REQUEST = 'UPDATE_PROMOTION_SCHEDULE_TARGET#REQUEST',
  UPDATE_PROMOTION_SCHEDULE_TARGET_SUCCESS = 'UPDATE_PROMOTION_SCHEDULE_TARGET#SUCCESS',
  UPDATE_PROMOTION_SCHEDULE_TARGET_FAILURE = 'UPDATE_PROMOTION_SCHEDULE_TARGET#FAILURE',

  DELETE_PROMOTION_SCHEDULE_TARGET_REQUEST = 'DELETE_PROMOTION_SCHEDULE_TARGET#REQUEST',
  DELETE_PROMOTION_SCHEDULE_TARGET_SUCCESS = 'DELETE_PROMOTION_SCHEDULE_TARGET#SUCCESS',
  DELETE_PROMOTION_SCHEDULE_TARGET_FAILURE = 'DELETE_PROMOTION_SCHEDULE_TARGET#FAILURE',
}

export interface PromotionScheduleTarget {
  id: number;
  targetId: string;
  taskData: ContentIdItem;
  startAt: string;
  endAt: string;
}

export interface PromotionScheduleTargetListItem {
  id: number;
  targetId: string;
  taskData: string;
  startAt: string;
  endAt: string;
}

export type PromotionScheduleTargetFormValues = Omit<PromotionScheduleTarget, 'id'>;

export type PromotionScheduleTargetCreateRequestData = PromotionScheduleTargetFormValues;

export type PromotionScheduleTargetCreateResponseData = PromotionScheduleTarget;

export type PromotionScheduleTargetUpdateRequestData = PromotionScheduleTargetFormValues;

export type PromotionScheduleTargetUpdateResponseData = PromotionScheduleTarget;

export interface DeleteScheduleRequestPayload extends DeleteBodyRequestPayload {
  taskName: string;
  segmentCode: string;
}

export interface ContentIdItem {
  contentId: string;
}
