import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  TeamMatchProfileActions as Actions,
  TeamMatchProfileCreateRequestData,
  TeamMatchProfileCreateResponseData,
  TeamMatchProfileDetail,
  TeamMatchProfileFormValues,
  TeamMatchProfileListItem,
  TeamMatchProfileUpdateRequestData,
  TeamMatchProfileUpdateResponseData,
} from './types';

export const listTeamMatchProfile = createAsyncAction(
  Actions.LIST_TEAM_MATCH_PROFILES_REQUEST,
  Actions.LIST_TEAM_MATCH_PROFILES_SUCCESS,
  Actions.LIST_TEAM_MATCH_PROFILES_FAILURE
)<ListRequestPayload, ListState<TeamMatchProfileListItem>, AxiosError>();

export const readTeamMatchProfile = createAsyncAction(
  Actions.READ_TEAM_MATCH_PROFILE_REQUEST,
  Actions.READ_TEAM_MATCH_PROFILE_SUCCESS,
  Actions.READ_TEAM_MATCH_PROFILE_FAILURE
)<ReadRequestPayload, TeamMatchProfileDetail, AxiosError>();

export const createTeamMatchProfile = createAsyncAction(
  Actions.CREATE_TEAM_MATCH_PROFILE_REQUEST,
  Actions.CREATE_TEAM_MATCH_PROFILE_SUCCESS,
  Actions.CREATE_TEAM_MATCH_PROFILE_FAILURE
)<
  FormRequestPayload<TeamMatchProfileFormValues, TeamMatchProfileCreateRequestData>,
  TeamMatchProfileCreateResponseData,
  AxiosError
>();

export const updateTeamMatchProfile = createAsyncAction(
  Actions.UPDATE_TEAM_MATCH_PROFILE_REQUEST,
  Actions.UPDATE_TEAM_MATCH_PROFILE_SUCCESS,
  Actions.UPDATE_TEAM_MATCH_PROFILE_FAILURE
)<
  FormRequestPayload<TeamMatchProfileFormValues, TeamMatchProfileUpdateRequestData>,
  TeamMatchProfileUpdateResponseData,
  AxiosError
>();

export const deleteTeamMatchProfile = createAsyncAction(
  Actions.DELETE_TEAM_MATCH_PROFILE_REQUEST,
  Actions.DELETE_TEAM_MATCH_PROFILE_SUCCESS,
  Actions.DELETE_TEAM_MATCH_PROFILE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
