import { AuthState, AccessToken, RefreshToken, IDToken, UserClaims, OktaAuth } from '@okta/okta-auth-js';

import { OKTA_CLIENT_ID, OKTA_ISSUER, OKTA_CALLBACK } from 'constants/env';
import { SignInSuccessPayload, User } from 'store/user/types';

export enum RealmType {
  hpcnt = 'hpcnt',
  moderation = 'moderation',
  unknown = 'Unknown',
}

export const REALM: RealmType = (process.env.REACT_APP_REALM as RealmType) || RealmType.unknown;

export const isModerationRealm = REALM === RealmType.moderation;
export const isHpcntRealm = REALM === RealmType.hpcnt;

/**
 * interface for typing
 */

export interface TokenParsed extends AccessToken, RefreshToken, IDToken {
  // AccessToken, IDToken
  claims: OktaClaims;
  // IDToken
  email: string;
  name: string;
}

export interface OktaClaims extends UserClaims {
  // AccessToken
  groups: string[];
  // IDToken
  name: string;
  email: string;
}

/**
 * init okta
 */

const config = {
  clientId: `${OKTA_CLIENT_ID}`,
  issuer: `${OKTA_ISSUER}`,
  redirectUri: `${OKTA_CALLBACK}`,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
};
export const oktaAuth = new OktaAuth(config);

/**
 * util
 */

const parse = (email: string) => email.split('@')[0];

/**
 * public
 */

export const setToken = (authState: AuthState) =>
  new Promise<SignInSuccessPayload>((resolve, reject) => {
    const {
      accessToken: token,
      claims: { groups: roles },
    } = authState?.accessToken as TokenParsed;
    const { refreshToken } = authState?.refreshToken as TokenParsed;
    const {
      claims: { email },
    } = authState?.idToken as TokenParsed;

    if (authState?.isAuthenticated) {
      resolve({
        token,
        refreshToken: refreshToken,
        roles,
        username: parse(email),
        email,
      });
    } else reject();
  });

export const getCachedAuthState = (): AuthState | null => {
  try {
    const authStateString = localStorage.getItem('authState');
    if (!authStateString) {
      return null;
    }
    return JSON.parse(authStateString) as AuthState;
  } catch (error) {
    console.error('Failed to parse auth state from localStorage:', error);
    return null;
  }
};

export const updateToken = async (): Promise<string> => {
  try {
    const token = await oktaAuth.tokenManager.renew('accessToken');
    return (token as TokenParsed).accessToken;
  } catch (error) {
    console.error('Error renewing token', error);
    throw new Error('Failed to renew token');
  }
};

export const logout = () =>
  new Promise<void>((resolve, reject) => {
    // Okta CORS 제한으로 직접 로그아웃 API로 redirect 이후, 다시 app 로그아웃 화면으로 돌아옴
    const idToken = oktaAuth.getIdToken();
    const origin = window.location.origin;
    const redirectUrl = encodeURIComponent(`${origin}/logout`);
    window.location.href = `${OKTA_ISSUER}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUrl}`;
  });

export const getCachedUser = (): User | null => {
  try {
    const userJsonString = localStorage.getItem('persist:user');
    if (!userJsonString) {
      return null;
    }
    return JSON.parse(userJsonString) as User;
  } catch (error) {
    console.error('Failed to parse user from localStorage:', error);
    return null;
  }
};

export const updateUserCacheAccessToken = (token: string) => {
  const user = getCachedUser();
  if (!user) {
    return;
  }
  user.access = token;
  localStorage.setItem('persist:user', JSON.stringify(user));
};
