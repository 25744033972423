import { initialListState, ListState } from '../types';

export enum VisitorConfigActions {
  LIST_VISITOR_CONFIG_REQUEST = 'LIST_VISITOR_CONFIG#REQUEST',
  LIST_VISITOR_CONFIG_SUCCESS = 'LIST_VISITOR_CONFIG#SUCCESS',
  LIST_VISITOR_CONFIG_FAILURE = 'LIST_VISITOR_CONFIG#FAILURE',

  CREATE_VISITOR_CONFIG_REQUEST = 'CREATE_VISITOR_CONFIG#REQUEST',
  CREATE_VISITOR_CONFIG_SUCCESS = 'CREATE_VISITOR_CONFIG#SUCCESS',
  CREATE_VISITOR_CONFIG_FAILURE = 'CREATE_VISITOR_CONFIG#FAILURE',

  READ_VISITOR_CONFIG_REQUEST = 'READ_VISITOR_CONFIG#REQUEST',
  READ_VISITOR_CONFIG_SUCCESS = 'READ_VISITOR_CONFIG#SUCCESS',
  READ_VISITOR_CONFIG_FAILURE = 'READ_VISITOR_CONFIG#FAILURE',

  UPDATE_VISITOR_CONFIG_REQUEST = 'UPDATE_VISITOR_CONFIG#REQUEST',
  UPDATE_VISITOR_CONFIG_SUCCESS = 'UPDATE_VISITOR_CONFIG#SUCCESS',
  UPDATE_VISITOR_CONFIG_FAILURE = 'UPDATE_VISITOR_CONFIG#FAILURE',

  DELETE_VISITOR_CONFIG_REQUEST = 'DELETE_VISITOR_CONFIG#REQUEST',
  DELETE_VISITOR_CONFIG_SUCCESS = 'DELETE_VISITOR_CONFIG#SUCCESS',
  DELETE_VISITOR_CONFIG_FAILURE = 'DELETE_VISITOR_CONFIG#FAILURE',
}

export interface VisitorConfig {
  readonly id?: number;
  visitorSegmentCode: string;
  configKey: string;
  configValue: string;
  enabled: boolean;
  startDate: string;
  endDate: string;
  description: string;
  priority: number;
}

export type VisitorConfigFormValues = Omit<VisitorConfig, 'id'>;
export type VisitorConfigCreateRequestData = Omit<VisitorConfig, 'id'>;
export type VisitorConfigCreateResponseData = VisitorConfig;

export interface VisitorConfigState {
  list: ListState<VisitorConfig>;
  detail?: VisitorConfig;
}

export const initialState: VisitorConfigState = {
  list: initialListState,
  detail: undefined,
};
