import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import { RemoteConfigActions as Actions, RemoteConfig, RemoteConfigCreateRequestData } from './types';

export const createRemoteConfig = createAsyncAction(
  Actions.CREATE_REMOTE_CONFIG_REQUEST,
  Actions.CREATE_REMOTE_CONFIG_SUCCESS,
  Actions.CREATE_REMOTE_CONFIG_FAILURE
)<FormRequestPayload<RemoteConfigCreateRequestData>, RemoteConfigCreateRequestData, AxiosError>();

export const readRemoteConfig = createAsyncAction(
  Actions.READ_REMOTE_CONFIG_REQUEST,
  Actions.READ_REMOTE_CONFIG_SUCCESS,
  Actions.READ_REMOTE_CONFIG_FAILURE
)<ReadRequestPayload, RemoteConfig, AxiosError>();

export const updateRemoteConfig = createAsyncAction(
  Actions.UPDATE_REMOTE_CONFIG_REQUEST,
  Actions.UPDATE_REMOTE_CONFIG_SUCCESS,
  Actions.UPDATE_REMOTE_CONFIG_FAILURE
)<FormRequestPayload<RemoteConfigCreateRequestData>, RemoteConfigCreateRequestData, AxiosError>();

export const listRemoteConfig = createAsyncAction(
  Actions.LIST_REMOTE_CONFIG_REQUEST,
  Actions.LIST_REMOTE_CONFIG_SUCCESS,
  Actions.LIST_REMOTE_CONFIG_FAILURE
)<ListRequestPayload, ListState<RemoteConfig>, AxiosError>();

export const deleteRemoteConfig = createAsyncAction(
  Actions.DELETE_REMOTE_CONFIG_REQUEST,
  Actions.DELETE_REMOTE_CONFIG_SUCCESS,
  Actions.DELETE_REMOTE_CONFIG_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
