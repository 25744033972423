import React, { FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Tabs, Tab, AppBar, Modal } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { usePresetList } from './usePresetList';
import PresetForm from './PresetForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    width: 400,
    position: 'absolute',
  },
  tab: { minWidth: 40 },
}));

const SettingModal: FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { currentPresetIndex, presets, setPresetByIndexWithDefaultValue, setCurrentPresetIndex, setPresetByIndex } =
    usePresetList();

  useEffect(() => {
    requestAnimationFrame(() => {
      // solve wrong behavior of tabIndicator ref: https://github.com/mui-org/material-ui/issues/9337
      window.dispatchEvent(new CustomEvent('resize'));
    });
  }, [isOpen]);

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={isOpen}>
        <Paper className={classes.wrapper}>
          <Grid item>
            <TabContext value={`${currentPresetIndex}`}>
              <AppBar position='static' color='default'>
                <Tabs
                  value={`${currentPresetIndex}`}
                  onChange={(e, value) => {
                    const numericValue = Number(value);
                    setCurrentPresetIndex(Number.isNaN(numericValue) ? value : numericValue);
                  }}
                >
                  {presets.map((_preset, index) => (
                    <Tab key={index} label={index + 1} value={`${index}`} className={classes.tab} />
                  ))}
                </Tabs>
              </AppBar>
              {presets.map((preset, index) => (
                <TabPanel key={index} value={`${index}`}>
                  <PresetForm
                    defaultValues={preset}
                    index={index}
                    onSave={(value) => setPresetByIndex(index, value)}
                    onCancel={onClose}
                    onSetDefaultValue={() => setPresetByIndexWithDefaultValue(index)}
                  />
                </TabPanel>
              ))}
            </TabContext>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SettingModal;
