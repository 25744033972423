export enum GemSettingMapEntryActions {
  CREATE_MAP_ENTRY_REQUEST = 'CREATE_GEM_SHOP_MAP_ENTRY#REQUEST',
  CREATE_MAP_ENTRY_SUCCESS = 'CREATE_GEM_SHOP_MAP_ENTRY#SUCCESS',
  CREATE_MAP_ENTRY_FAILURE = 'CREATE_GEM_SHOP_MAP_ENTRY#FAILURE',

  UPDATE_MAP_ENTRY_REQUEST = 'UPDATE_GEM_SHOP_MAP_ENTRY#REQUEST',
  UPDATE_MAP_ENTRY_SUCCESS = 'UPDATE_GEM_SHOP_MAP_ENTRY#SUCCESS',
  UPDATE_MAP_ENTRY_FAILURE = 'UPDATE_GEM_SHOP_MAP_ENTRY#FAILURE',

  DELETE_MAP_ENTRY_REQUEST = 'DELETE_GEM_SHOP_MAP_ENTRY#REQUEST',
  DELETE_MAP_ENTRY_SUCCESS = 'DELETE_GEM_SHOP_MAP_ENTRY#SUCCESS',
  DELETE_MAP_ENTRY_FAILURE = 'DELETE_GEM_SHOP_MAP_ENTRY#FAILURE',
}

export interface GemSettingMapEntry {
  id: number;
  settingId: number;
  startDate: string;
  endDate: string;
  segmentCode?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder: number;
  enabled?: boolean;
}

export type GemSettingMapEntryCreateRequestData = Omit<GemSettingMapEntry, 'id' | 'enabled'>;
export type GemSettingMapEntryCreateSuccessData = GemSettingMapEntry;
export type GemSettingMapEntryUpdateRequestData = Omit<GemSettingMapEntry, 'id' | 'enabled'>;
export type GemSettingMapEntryUpdateSuccessData = GemSettingMapEntry;
