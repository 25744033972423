import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  BlockPhoneManageActions as Actions,
  BlockPhoneManageByUserIdsDeleteRequestData,
  BlockPhoneManageByUserIdsDeleteRequestFormValues,
  BlockPhoneManageDeleteRequestData,
  BlockPhoneManageDeleteRequestFormValues,
} from './types';

export const deleteBlockPhoneManage = createAsyncAction(
  Actions.DELETE_BLOCK_PHONE_MANAGE_REQUEST,
  Actions.DELETE_BLOCK_PHONE_MANAGE_SUCCESS,
  Actions.DELETE_BLOCK_PHONE_MANAGE_FAILURE
)<
  FormRequestPayload<BlockPhoneManageDeleteRequestFormValues, BlockPhoneManageDeleteRequestData>,
  undefined,
  AxiosError
>();

export const deleteBlockPhoneManageByUserIds = createAsyncAction(
  Actions.DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS_REQUEST,
  Actions.DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS_SUCCESS,
  Actions.DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS_FAILURE
)<
  FormRequestPayload<BlockPhoneManageByUserIdsDeleteRequestFormValues, BlockPhoneManageByUserIdsDeleteRequestData>,
  undefined,
  AxiosError
>();
