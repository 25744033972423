import { createFlatListApi, createListApi } from 'store/utils';

import { RealtimeMonitoringListItem, RealtimeMonitoringListResponseData, Role } from './types';
import { parsingListAgentResponse, parsingListManagerResponse } from './utils';

const REQUEST_OPTION = undefined;

export const listMonitoringWorkloadsManagerApi = createListApi<
  RealtimeMonitoringListItem,
  RealtimeMonitoringListResponseData<Role.manager>
>('/monitoring-workloads/admin', REQUEST_OPTION, { parsefn: parsingListManagerResponse });

export const listMonitoringWorkloadsAgentApi = createListApi<
  RealtimeMonitoringListItem,
  RealtimeMonitoringListResponseData<Role.agent>
>('/monitoring-workloads/admin/agents/:agentName', REQUEST_OPTION, { parsefn: parsingListAgentResponse });

export const excelMonitoringWorkloadsAgentApi = createFlatListApi<
  RealtimeMonitoringListItem,
  RealtimeMonitoringListResponseData<Role.manager>
>('/monitoring-workloads/admin/total', REQUEST_OPTION, { parsefn: parsingListAgentResponse });
