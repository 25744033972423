export enum ShopEntryBannerScheduleActions {
  CREATE_SCHEDULE_REQUEST = 'CREATE_SHOP_ENTRY_BANNER_SCHEDULE#REQUEST',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_SHOP_ENTRY_BANNER_SCHEDULE#SUCCESS',
  CREATE_SCHEDULE_FAILURE = 'CREATE_SHOP_ENTRY_BANNER_SCHEDULE#FAILURE',

  UPDATE_SCHEDULE_REQUEST = 'UPDATE_SHOP_ENTRY_BANNER_SCHEDULE#REQUEST',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SHOP_ENTRY_BANNER_SCHEDULE#SUCCESS',
  UPDATE_SCHEDULE_FAILURE = 'UPDATE_SHOP_ENTRY_BANNER_SCHEDULE#FAILURE',

  DELETE_SCHEDULE_REQUEST = 'DELETE_SHOP_ENTRY_BANNER_SCHEDULE#REQUEST',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_SHOP_ENTRY_BANNER_SCHEDULE#SUCCESS',
  DELETE_SCHEDULE_FAILURE = 'DELETE_SHOP_ENTRY_BANNER_SCHEDULE#FAILURE',
}

export interface ShopEntryBannerSchedule {
  id: number;
  segmentCode: string;
  bannerId: number;
  startDate: string;
  endDate: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  activated: boolean;
}

export type ShopEntryBannerScheduleCreateRequestData = Omit<ShopEntryBannerSchedule, 'id' | 'activated'>;
export type ShopEntryBannerScheduleCreateSuccessData = ShopEntryBannerSchedule;
export type ShopEntryBannerScheduleUpdateRequestData = Omit<ShopEntryBannerSchedule, 'id' | 'activated'>;
export type ShopEntryBannerScheduleUpdateSuccessData = ShopEntryBannerSchedule;
