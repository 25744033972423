import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import { matchingApiRequest } from 'utils/axios';

import {
  TeamMatchCampaignCreateRequestData,
  TeamMatchCampaignCreateResponseData,
  TeamMatchCampaignDetail,
  TeamMatchCampaignFormValues,
  TeamMatchCampaignListItem,
  TeamMatchCampaignUpdateRequestData,
  TeamMatchCampaignUpdateResponseData,
  TeamMatchContentCreateRequestData,
  TeamMatchContentCreateResponseData,
  TeamMatchContentFormValues,
  TeamMatchContentUpdateRequestData,
  TeamMatchContentUpdateResponseData,
} from './types';

export const listTeamMatchCampaignApi = createListApi<TeamMatchCampaignListItem>(
  '/api/v1/team-match/campaigns',
  {},
  {},
  { apiRequest: matchingApiRequest }
);

export const readTeamMatchCampaignApi = createReadApi<TeamMatchCampaignDetail>(
  '/api/v1/team-match/campaigns/:id',
  {},
  { apiRequest: matchingApiRequest }
);

export const createTeamMatchCampaignApi = createPostApi<
  TeamMatchCampaignFormValues,
  TeamMatchCampaignCreateRequestData,
  TeamMatchCampaignCreateResponseData
>('/api/v1/team-match/campaigns', {}, { apiRequest: matchingApiRequest });

export const updateTeamMatchCampaignApi = createUpdateApi<
  TeamMatchCampaignFormValues,
  TeamMatchCampaignUpdateRequestData,
  TeamMatchCampaignUpdateResponseData
>('/api/v1/team-match/campaigns/:id', {}, { apiRequest: matchingApiRequest });

export const deleteTeamMatchCampaignApi = createDeleteApi(
  '/api/v1/team-match/campaigns/:id',
  {},
  { apiRequest: matchingApiRequest }
);

export const createTeamMatchContentApi = createPostApi<
  TeamMatchContentFormValues,
  TeamMatchContentCreateRequestData,
  TeamMatchContentCreateResponseData
>('/api/v1/team-match/contents', {}, { apiRequest: matchingApiRequest });

export const updateTeamMatchContentApi = createUpdateApi<
  TeamMatchContentFormValues,
  TeamMatchContentUpdateRequestData,
  TeamMatchContentUpdateResponseData
>('/api/v1/team-match/contents/:id', {}, { apiRequest: matchingApiRequest });

export const deleteTeamMatchContentApi = createDeleteApi(
  '/api/v1/team-match/contents/:id',
  {},
  { apiRequest: matchingApiRequest }
);
