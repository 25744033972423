import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteBodyRequestPayload, DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  DecoSettingProductActions as Actions,
  DecoSettingProductBulkCreateRequestData,
  DecoSettingProductBulkCreateResponseData,
  DecoSettingProductCreateRequestData,
  DecoSettingProductCreateResponseData,
  DecoSettingProductFormValues,
  DecoSettingProductUpdateRequestData,
  DecoSettingProductUpdateResponseData,
} from './types';

export const createDecoSettingProduct = createAsyncAction(
  Actions.CREATE_DECO_SETTING_PRODUCT_REQUEST,
  Actions.CREATE_DECO_SETTING_PRODUCT_SUCCESS,
  Actions.CREATE_DECO_SETTING_PRODUCT_FAILURE
)<
  FormRequestPayload<DecoSettingProductFormValues, DecoSettingProductCreateRequestData>,
  DecoSettingProductCreateResponseData,
  AxiosError
>();

export const updateDecoSettingProduct = createAsyncAction(
  Actions.UPDATE_DECO_SETTING_PRODUCT_REQUEST,
  Actions.UPDATE_DECO_SETTING_PRODUCT_SUCCESS,
  Actions.UPDATE_DECO_SETTING_PRODUCT_FAILURE
)<
  FormRequestPayload<DecoSettingProductFormValues, DecoSettingProductUpdateRequestData>,
  DecoSettingProductUpdateResponseData,
  AxiosError
>();

export const deleteDecoSettingProduct = createAsyncAction(
  Actions.DELETE_DECO_SETTING_PRODUCT_REQUEST,
  Actions.DELETE_DECO_SETTING_PRODUCT_SUCCESS,
  Actions.DELETE_DECO_SETTING_PRODUCT_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const bulkCreateDecoSettingProducts = createAsyncAction(
  Actions.BULK_CREATE_DECO_SETTING_PRODUCTS_REQUEST,
  Actions.BULK_CREATE_DECO_SETTING_PRODUCTS_SUCCESS,
  Actions.BULK_CREATE_DECO_SETTING_PRODUCTS_FAILURE
)<FormRequestPayload<DecoSettingProductBulkCreateRequestData>, DecoSettingProductBulkCreateResponseData, AxiosError>();

export const bulkDeleteDecoSettingProducts = createAsyncAction(
  Actions.BULK_DELETE_DECO_SETTING_PRODUCTS_REQUEST,
  Actions.BULK_DELETE_DECO_SETTING_PRODUCTS_SUCCESS,
  Actions.BULK_DELETE_DECO_SETTING_PRODUCTS_FAILURE
)<DeleteBodyRequestPayload, undefined, AxiosError>();
