export interface BookmarkState {
  list: Bookmark[];
}

export interface Bookmark {
  path: string;
  name: string;
}

export enum BookmarkActions {
  SET_BOOKMARK = 'SET_BOOKMARK',
  ADD_BOOKMARK = 'ADD_BOOKMARK',
  REMOVE_BOOKMARK = 'REMOVE_BOOKMARK',
  REORDER_BOOKMARKS = 'REORDER_BOOKMARKS',
  RENAME_BOOKMARKS = 'RENAME_BOOKMARKS',
}

export const initialState: BookmarkState = { list: [] };
export type SetBookmarkPayload = BookmarkState;
export type AddBookmarkPayload = Bookmark;
export type RenameBookmarkPayload = { newName: string; path: string };
export type RemoveBookmarkPayload = number;
export interface ReorderBookmarksPayload {
  sourceIndex: number;
  destinationIndex: number;
}
