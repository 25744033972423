import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createMlConfigModel, listMlConfigModel, readMlConfigModel, updateMlConfigModel } from './actions';
import { createMlConfigModelApi, listMlConfigModelApi, readMlConfigModelApi, updateMlConfigModelApi } from './api';

const updateMlConfigModelToastEpic = createToastEpic(updateMlConfigModel.success, 'Successfully saved');

const createMlConfigModelToastEpic = createToastEpic(createMlConfigModel.success, 'Successfully created');

const listMlConfigModelEpic = createListEpic(listMlConfigModel, listMlConfigModelApi);

const createMlConfigModelEpic = createPostEpic(createMlConfigModel, createMlConfigModelApi);

const readMlConfigModelEpic = createReadEpic(readMlConfigModel, readMlConfigModelApi);

const updateMlConfigModelEpic = createUpdateEpic(updateMlConfigModel, updateMlConfigModelApi);

export default combineEpics(
  updateMlConfigModelToastEpic,
  createMlConfigModelToastEpic,
  listMlConfigModelEpic,
  createMlConfigModelEpic,
  readMlConfigModelEpic,
  updateMlConfigModelEpic
);
