import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  TeamMessageCreateRequestData,
  TeamMessageCreateResponseData,
  TeamMessageDetail,
  TeamMessageFormValues,
  TeamMessageListItem,
  TeamMessageUpdateRequestData,
  TeamMessageUpdateResponseData,
} from './types';

export const listTeamMessageApi = createListApi<TeamMessageListItem>('/team_message');

export const readTeamMessageApi = createReadApi<TeamMessageDetail>('/team_message/code');

export const createTeamMessageApi = createPostApi<
  TeamMessageFormValues,
  TeamMessageCreateRequestData,
  TeamMessageCreateResponseData
>('/team_message');

export const updateTeamMessageApi = createUpdateApi<
  TeamMessageFormValues,
  TeamMessageUpdateRequestData,
  TeamMessageUpdateResponseData
>('/team_message/:id');
