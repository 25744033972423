import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as promotionContentActions from './actions';
import { initialState, PromotionContentState } from './types';

const promotionContent = createReducer<PromotionContentState, RootAction>(initialState)
  .handleAction(promotionContentActions.listPromotionContent.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(promotionContentActions.listPromotionContent.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(promotionContentActions.readPromotionContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default promotionContent;
