import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createGemSetting,
  deleteGemSetting,
  listGemSetting,
  readGemSetting,
  refreshGemSetting,
  updateGemSetting,
} from './actions';
import {
  createGemSettingApi,
  deleteGemSettingApi,
  listGemSettingApi,
  readGemSettingApi,
  refreshGemSettingApi,
  updateGemSettingApi,
} from './api';

const updateGemSettingToastEpic = createToastEpic(updateGemSetting.success, 'Successfully saved');

const createGemSettingToastEpic = createToastEpic(createGemSetting.success, 'Successfully created');

const refreshGemSettingToastEpic = createToastEpic(refreshGemSetting.success, 'Successfully refreshed server');

const listGemSettingEpic = createListEpic(listGemSetting, listGemSettingApi);

const createGemSettingEpic = createPostEpic(createGemSetting, createGemSettingApi);

const readGemSettingEpic = createReadEpic(readGemSetting, readGemSettingApi);

const updateGemSettingEpic = createUpdateEpic(updateGemSetting, updateGemSettingApi);

const deleteGemSettingEpic = createDeleteEpic(deleteGemSetting, deleteGemSettingApi);

const refreshGemSettingEpic = createAsyncEpic(refreshGemSetting, refreshGemSettingApi);

export default combineEpics(
  updateGemSettingToastEpic,
  createGemSettingToastEpic,
  refreshGemSettingToastEpic,
  listGemSettingEpic,
  createGemSettingEpic,
  readGemSettingEpic,
  updateGemSettingEpic,
  deleteGemSettingEpic,
  refreshGemSettingEpic
);
