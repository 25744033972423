import { initialListState, ListState } from 'store/types';

export enum ProductIconActions {
  LIST_PRODUCT_ICON_REQUEST = 'LIST_PRODUCT_ICON#REQUEST',
  LIST_PRODUCT_ICON_SUCCESS = 'LIST_PRODUCT_ICON#SUCCESS',
  LIST_PRODUCT_ICON_FAILURE = 'LIST_PRODUCT_ICON#FAILURE',
}

export const initialState: ProductIconState = { list: initialListState };

export interface ProductIcon {
  id: number;
  imageUrl: string;
}

export interface ProductIconState {
  list: ListState<ProductIcon>;
}
