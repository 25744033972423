import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import * as gemProductTierActions from 'store/gemProductTier/actions';
import { initialState, GemProductTierState } from 'store/gemProductTier/types';
import { RootAction } from 'store/root/types';

const gemProductTier = createReducer<GemProductTierState, RootAction>(initialState)
  .handleAction(gemProductTierActions.listGemProductTier.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(gemProductTierActions.listGemProductTier.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(({ tierCode, description, schedules }) => ({
        tierCode,
        description,
        schedules,
      })),
    });
    return newState.toJS() as GemProductTierState;
  })
  .handleAction(gemProductTierActions.readGemProductTier.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));
export default gemProductTier;
