import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  PromotionContentActions as Actions,
  PromotionContentCreateRequestData,
  PromotionContentCreateResponseData,
  PromotionContentDetail,
  PromotionContentFormValues,
  PromotionContentListItem,
  PromotionContentUpdateRequestData,
  PromotionContentUpdateResponseData,
} from './types';

export const listPromotionContent = createAsyncAction(
  Actions.LIST_PROMOTION_CONTENTS_REQUEST,
  Actions.LIST_PROMOTION_CONTENTS_SUCCESS,
  Actions.LIST_PROMOTION_CONTENTS_FAILURE
)<ListRequestPayload, ListState<PromotionContentListItem>, AxiosError>();

export const readPromotionContent = createAsyncAction(
  Actions.READ_PROMOTION_CONTENT_REQUEST,
  Actions.READ_PROMOTION_CONTENT_SUCCESS,
  Actions.READ_PROMOTION_CONTENT_FAILURE
)<ReadRequestPayload, PromotionContentDetail, AxiosError>();

export const createPromotionContent = createAsyncAction(
  Actions.CREATE_PROMOTION_CONTENT_REQUEST,
  Actions.CREATE_PROMOTION_CONTENT_SUCCESS,
  Actions.CREATE_PROMOTION_CONTENT_FAILURE
)<
  FormRequestPayload<PromotionContentFormValues, PromotionContentCreateRequestData>,
  PromotionContentCreateResponseData,
  AxiosError
>();

export const updatePromotionContent = createAsyncAction(
  Actions.UPDATE_PROMOTION_CONTENT_REQUEST,
  Actions.UPDATE_PROMOTION_CONTENT_SUCCESS,
  Actions.UPDATE_PROMOTION_CONTENT_FAILURE
)<
  FormRequestPayload<PromotionContentFormValues, PromotionContentUpdateRequestData>,
  PromotionContentUpdateResponseData,
  AxiosError
>();
