import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic } from 'store/utils';

import { createFileUpload } from './actions';
import { createFileUploadApi } from './api';

const createFileUploadEpic = createPostEpic(createFileUpload, createFileUploadApi);

const createFileUploadToastEpic = createToastEpic(createFileUpload.success, 'Successfully uploaded');

export default combineEpics(createFileUploadEpic, createFileUploadToastEpic);
