import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createDecoSettingSchedule, deleteDecoSettingSchedule, updateDecoSettingSchedule } from './actions';
import { createDecoSettingScheduleApi, deleteDecoSettingScheduleApi, updateDecoSettingScheduleApi } from './api';

const createDecoSettingScheduleEpic = createPostEpic(createDecoSettingSchedule, createDecoSettingScheduleApi);

const createDecoSettingScheduleToastEpic = createToastEpic(createDecoSettingSchedule.success, 'Successfully created');

const updateDecoSettingScheduleEpic = createUpdateEpic(updateDecoSettingSchedule, updateDecoSettingScheduleApi);

const updateDecoSettingScheduleToastEpic = createToastEpic(updateDecoSettingSchedule.success, 'Successfully saved');

const deleteDecoSettingScheduleEpic = createDeleteEpic(deleteDecoSettingSchedule, deleteDecoSettingScheduleApi);

const deleteDecoSettingScheduleToastEpic = createToastEpic(deleteDecoSettingSchedule.success, 'Successfully deleted');

export default combineEpics(
  createDecoSettingScheduleEpic,
  createDecoSettingScheduleToastEpic,
  updateDecoSettingScheduleEpic,
  updateDecoSettingScheduleToastEpic,
  deleteDecoSettingScheduleEpic,
  deleteDecoSettingScheduleToastEpic
);
