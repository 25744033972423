import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic } from '../utils';

import { listInvalidatedPurchase, readInvalidatedPurchase } from './actions';
import { listInvalidatedPurchaseApi, readInvalidatedPurchaseApi } from './api';

const readInvalidatedPurchaseEpic = createReadEpic(readInvalidatedPurchase, readInvalidatedPurchaseApi);
const listInvalidatedPurchaseEpic = createListEpic(listInvalidatedPurchase, listInvalidatedPurchaseApi);
export default combineEpics(readInvalidatedPurchaseEpic, listInvalidatedPurchaseEpic);
