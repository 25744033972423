import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createDeleteBodyEpic,
  createListEpic,
  createPostEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createItemProduct,
  createItemProductProvide,
  createPeriodicItemProduct,
  deleteItemProduct,
  deleteItemProductProvide,
  deletePeriodicItemProduct,
  listItemProduct,
  updateItemProduct,
  updateItemProductProvide,
  updatePeriodicItemProduct,
} from './actions';
import {
  createItemProductApi,
  createItemProductProvideApi,
  createPeriodicItemProductApi,
  deleteItemProductApi,
  deleteItemProductProvideApi,
  deletePeriodicItemProductApi,
  listItemProductApi,
  updateItemProductApi,
  updateItemProductProvideApi,
  updatePeriodicItemProductApi,
} from './api';

const listItemProductEpic = createListEpic(listItemProduct, listItemProductApi);

const createItemProductEpic = createPostEpic(createItemProduct, createItemProductApi);

const createItemProductToastEpic = createToastEpic(createItemProduct.success, 'Successfully created');

const updateItemProductEpic = createUpdateEpic(updateItemProduct, updateItemProductApi);

const updateItemProductToastEpic = createToastEpic(updateItemProduct.success, 'Successfully saved');

const deleteItemProductEpic = createDeleteBodyEpic(deleteItemProduct, deleteItemProductApi);

const deleteItemProductToastEpic = createToastEpic(deleteItemProduct.success, 'Successfully deleted');

// Item Product Provide

const createItemProductProvideEpic = createAsyncEpic(createItemProductProvide, createItemProductProvideApi);
const editItemProductProvideEpic = createAsyncEpic(updateItemProductProvide, updateItemProductProvideApi);
const deleteItemProductProvideEpic = createAsyncEpic(deleteItemProductProvide, deleteItemProductProvideApi);
const createItemProductProvideToastEpic = createToastEpic(createItemProductProvide.success, 'Successfully created');

const editItemProductProvideToastEpic = createToastEpic(updateItemProductProvide.success, 'Successfully saved');
const deleteItemProductProvideToastEpic = createToastEpic(deleteItemProductProvide.success, 'Successfully deleted');

// Periodic Item Product

const createPeriodicItemProductEpic = createAsyncEpic(createPeriodicItemProduct, createPeriodicItemProductApi);
const editPeriodicItemProductEpic = createAsyncEpic(updatePeriodicItemProduct, updatePeriodicItemProductApi);
const deletePeriodicItemProductEpic = createAsyncEpic(deletePeriodicItemProduct, deletePeriodicItemProductApi);
const createPeriodicItemProductToastEpic = createToastEpic(createPeriodicItemProduct.success, 'Successfully created');

const editPeriodicItemProductToastEpic = createToastEpic(updatePeriodicItemProduct.success, 'Successfully saved');
const deletePeriodicItemProductToastEpic = createToastEpic(deletePeriodicItemProduct.success, 'Successfully deleted');

export default combineEpics(
  listItemProductEpic,
  createItemProductEpic,
  createItemProductToastEpic,
  updateItemProductEpic,
  updateItemProductToastEpic,
  deleteItemProductEpic,
  deleteItemProductToastEpic,

  // Item Product Provide
  createItemProductProvideEpic,
  editItemProductProvideEpic,
  deleteItemProductProvideEpic,
  createItemProductProvideToastEpic,
  editItemProductProvideToastEpic,
  deleteItemProductProvideToastEpic,

  // Item Product Provide
  createPeriodicItemProductEpic,
  editPeriodicItemProductEpic,
  deletePeriodicItemProductEpic,
  createPeriodicItemProductToastEpic,
  editPeriodicItemProductToastEpic,
  deletePeriodicItemProductToastEpic
);
