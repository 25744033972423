import { ActionType, createReducer } from 'typesafe-actions';

import * as productIconActions from 'store/productIcons/actions';
import { initialState, ProductIconState } from 'store/productIcons/types';

type Action = ActionType<typeof productIconActions>;

const productIcon = createReducer<ProductIconState, Action>(initialState)
  .handleAction(productIconActions.listProductIcon.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(productIconActions.listProductIcon.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));
export default productIcon;
