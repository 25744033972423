import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { provideItemPackage } from './actions';
import { provideItemPackageApi } from './api';

const provideItemPackageToastEpic = createToastEpic(provideItemPackage.success, 'Successfully Provided');

const provideItemPackageEpic = createUpdateEpic(provideItemPackage, provideItemPackageApi);

export default combineEpics(provideItemPackageToastEpic, provideItemPackageEpic);
