import { createApiResource } from 'utils/apiHook';
import axios from 'axios';
import { azarApiRequest } from 'utils/axios';

const client = axios.create({
  baseURL: 'https://devops-bot.svc.hpcnt.com/core/kube/dev/ns/list/azar/',
  headers: {
    Authorization: azarApiRequest.defaults.headers.common.Authorization,
  },
});

const { useApiQuery } = createApiResource('namespace', {
  useApiQuery: {
    getAzarApiNamespaceList: async (payload: {}) => {
      return (
        await client.get<{
          deployments: Array<{
            namespace: string;
            url: string;
          }>;
        }>('/azar-operation-admin')
      ).data;
    },
    getMatchingApiNamespaceList: async (payload: {}) => {
      return (
        await client.get<{
          deployments: Array<{
            namespace: string;
            url: string;
          }>;
        }>('/dev3-admin')
      ).data;
    },
  },
  useApiMutation: {},
});

export function useAzarApiNamepsaceListQuery() {
  return useApiQuery('getAzarApiNamespaceList', {}, { staleTime: Infinity });
}

export function useMatchingApiNamepsaceListQuery() {
  return useApiQuery('getMatchingApiNamespaceList', {}, { staleTime: Infinity });
}
