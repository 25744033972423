import { initialListState, ListRequestPayload, ListState } from 'store/types';

export enum MatataSuspensionHistoryActions {
  LIST_MATATA_SUSPENSION_HISTORY_REQUEST = 'LIST_MATATA_SUSPENSION_HISTORY#REQUEST',
  LIST_MATATA_SUSPENSION_HISTORY_SUCCESS = 'LIST_MATATA_SUSPENSION_HISTORY#SUCCESS',
  LIST_MATATA_SUSPENSION_HISTORY_FAILURE = 'LIST_MATATA_SUSPENSION_HISTORY#FAILURE',
}

export interface MatataSuspensionHistoryState {
  list: ListState<MatataSuspensionHistoryItem>;
}

export interface MatataSuspensionHistoryItem {
  userId: number;
  deleted: boolean;
  matataId: string;
  deviceId: string;
  dateFirstLogin: string;
  dateLastLogin: string;
  nowSuspensionId: number;
  nowSuspensionLabel: string;
  nowSuspensionCategory: string;
  nowSuspensionScope: string;
  suspensionStartAt: string;
  suspensionEndAt: string;
  reportId: string;
}

export type MatataSuspensionHistoryRequestPayload = ListRequestPayload;
export type MatataSuspensionHistoryResponseData = ListState<MatataSuspensionHistoryItem>;

export const initialMatataSuspensionHistoryState: MatataSuspensionHistoryState = {
  list: initialListState,
};
