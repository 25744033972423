import { initialListState, ListState } from '../types';

export enum WaterSlideBannerActions {
  LIST_WATER_SLIDE_BANNER_REQUEST = 'LIST_WATER_SLIDE_BANNER#REQUEST',
  LIST_WATER_SLIDE_BANNER_SUCCESS = 'LIST_WATER_SLIDE_BANNER#SUCCESS',
  LIST_WATER_SLIDE_BANNER_FAILURE = 'LIST_WATER_SLIDE_BANNER#FAILURE',

  CREATE_WATER_SLIDE_BANNER_REQUEST = 'CREATE_WATER_SLIDE_BANNER#REQUEST',
  CREATE_WATER_SLIDE_BANNER_SUCCESS = 'CREATE_WATER_SLIDE_BANNER#SUCCESS',
  CREATE_WATER_SLIDE_BANNER_FAILURE = 'CREATE_WATER_SLIDE_BANNER#FAILURE',

  READ_WATER_SLIDE_BANNER_REQUEST = 'READ_WATER_SLIDE_BANNER#REQUEST',
  READ_WATER_SLIDE_BANNER_SUCCESS = 'READ_WATER_SLIDE_BANNER#SUCCESS',
  READ_WATER_SLIDE_BANNER_FAILURE = 'READ_WATER_SLIDE_BANNER#FAILURE',

  UPDATE_WATER_SLIDE_BANNER_REQUEST = 'UPDATE_WATER_SLIDE_BANNER#REQUEST',
  UPDATE_WATER_SLIDE_BANNER_SUCCESS = 'UPDATE_WATER_SLIDE_BANNER#SUCCESS',
  UPDATE_WATER_SLIDE_BANNER_FAILURE = 'UPDATE_WATER_SLIDE_BANNER#FAILURE',

  DELETE_WATER_SLIDE_BANNER_REQUEST = 'DELETE_WATER_SLIDE_BANNER#REQUEST',
  DELETE_WATER_SLIDE_BANNER_SUCCESS = 'DELETE_WATER_SLIDE_BANNER#SUCCESS',
  DELETE_WATER_SLIDE_BANNER_FAILURE = 'DELETE_WATER_SLIDE_BANNER#FAILURE',

  REFRESH_WATER_SLIDE_BANNER_REQUEST = 'REFRESH_WATER_SLIDE_BANNER#REQUEST',
  REFRESH_WATER_SLIDE_BANNER_SUCCESS = 'REFRESH_WATER_SLIDE_BANNER#SUCCESS',
  REFRESH_WATER_SLIDE_BANNER_FAILURE = 'REFRESH_WATER_SLIDE_BANNER#FAILURE',
}

export enum PlatForm {
  ALL = 'ALL',
  IOS = 'iOS',
  ANDROID = 'Android',
}

export interface WaterSlideBanner {
  checked?: boolean;
  readonly id?: number;
  name: string;
  enabled: boolean;
  startDate: string;
  endDate: string;
  segmentCode: string;
  priority: number;
  titleLocalizationKey: string;
  descriptionLocalizationKey: string;
  platform: PlatForm;
  backgroundUrl?: string;
  backgroundColorCode?: string;
  deeplinkUrl: string;
  rightImageUrl: string;
  includeCountryCodes: string;
  excludeCountryCodes: string;
}

export interface WaterSlideBannerCreateFormValue extends WaterSlideBanner {
  titleLocalizationKeyAsString: boolean;
  descriptionLocalizationKeyAsString: boolean;
}

export type WaterSlideBannerFormValues = Omit<WaterSlideBannerCreateFormValue, 'id'>;
export type WaterSlideBannerCreateRequestData = Omit<WaterSlideBanner, 'id'>;
export type WaterSlideBannerCreateResponseData = WaterSlideBanner;

export interface WaterSlideBannerState {
  list: ListState<WaterSlideBanner>;
  detail?: WaterSlideBanner;
}

export const initialState: WaterSlideBannerState = {
  list: initialListState,
  detail: undefined,
};
