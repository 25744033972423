import { combineEpics } from 'redux-observable';

import { createFlatListEpic } from '../utils';

import { listAppStoreReview } from './actions';
import { downloadAppStoreReview } from './api';

const listAppStoreReviewEpic = createFlatListEpic(listAppStoreReview, downloadAppStoreReview);

export default combineEpics(listAppStoreReviewEpic);
