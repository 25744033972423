import { createContext } from 'react';

import { WEB_TITLE } from 'constants/routes';

export interface PageInfo {
  title: string;
  guideUrl?: string;
}

const PageInfoContext = createContext<PageInfo>({ title: WEB_TITLE });

export default PageInfoContext;
