import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as promotionSettingActions from './actions';
import { initialState, PromotionSettingState } from './types';

const promotionSetting = createReducer<PromotionSettingState, RootAction>(initialState)
  .handleAction(promotionSettingActions.listPromotionSetting.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(promotionSettingActions.listPromotionSetting.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(promotionSettingActions.readPromotionSetting.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default promotionSetting;
