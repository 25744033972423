import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic } from 'store/utils';

import { createSimulationUpload } from './actions';
import { createSimulationUploadApi } from './api';

const createSimulationUploadEpic = createPostEpic(createSimulationUpload, createSimulationUploadApi);

const createSimulationUploadToastEpic = createToastEpic(createSimulationUpload.success, 'Successfully uploaded');

export default combineEpics(createSimulationUploadEpic, createSimulationUploadToastEpic);
