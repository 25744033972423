import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FlatListRequestPayload, FlatListState, FormRequestPayload, ListRequestPayload, ListState } from 'store/types';

import {
  ItemCategoryActions as Actions,
  DecoCategory,
  ItemCategory,
  ItemCategoryCreateRequestData,
  ItemCategoryCreateResponseData,
  ItemCategoryFormValues,
  ItemCategoryUpdateRequestData,
  ItemCategoryUpdateResponseData,
} from './types';

export const createItemCategory = createAsyncAction(
  Actions.CREATE_ITEM_CATEGORY_REQUEST,
  Actions.CREATE_ITEM_CATEGORY_SUCCESS,
  Actions.CREATE_ITEM_CATEGORY_FAILURE
)<
  FormRequestPayload<ItemCategoryFormValues, ItemCategoryCreateRequestData>,
  ItemCategoryCreateResponseData,
  AxiosError
>();

export const getDecoCategory = createAsyncAction(
  /**
   * https://www.notion.so/hpcnt/Deco-API-49939ed5a0d049a1a6b710110edda986#c69c7367e7eb4e2b9c2f78701452da39
   */
  Actions.GET_DECO_CATEGORY_REQUEST,
  Actions.GET_DECO_CATEGORY_SUCCESS,
  Actions.GET_DECO_CATEGORY_FAILURE
)<FlatListRequestPayload, FlatListState<DecoCategory>, AxiosError>();

interface CategoryRequestPayload extends ListRequestPayload {
  /**
   * https://www.notion.so/hpcnt/95148144d569413589e03c280d93d3b0?v=b8cf8ceec0c247ef85f920b83d930b02
   * */
  groupId?: string;
}

export const getItemCategory = createAsyncAction(
  Actions.GET_ITEM_CATEGORY_REQUEST,
  Actions.GET_ITEM_CATEGORY_SUCCESS,
  Actions.GET_ITEM_CATEGORY_FAILURE
)<CategoryRequestPayload, ListState<ItemCategory>, AxiosError>();

export const updateItemCategory = createAsyncAction(
  Actions.UPDATE_ITEM_CATEGORY_REQUEST,
  Actions.UPDATE_ITEM_CATEGORY_SUCCESS,
  Actions.UPDATE_ITEM_CATEGORY_FAILURE
)<
  FormRequestPayload<ItemCategoryFormValues, ItemCategoryUpdateRequestData>,
  ItemCategoryUpdateResponseData,
  AxiosError
>();
