import { combineEpics } from 'redux-observable';

import { createDeleteBodyEpic, createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import {
  bulkCreateDecoSettingProducts,
  bulkDeleteDecoSettingProducts,
  createDecoSettingProduct,
  deleteDecoSettingProduct,
  updateDecoSettingProduct,
} from './actions';
import {
  bulkCreateDecoSettingProductApi,
  bulkDeleteDecoSettingProductApi,
  createDecoSettingProductApi,
  deleteDecoSettingProductApi,
  updateDecoSettingProductApi,
} from './api';

const createDecoSettingProductEpic = createPostEpic(createDecoSettingProduct, createDecoSettingProductApi);

const createDecoSettingProductToastEpic = createToastEpic(createDecoSettingProduct.success, 'Successfully created');

const updateDecoSettingProductEpic = createUpdateEpic(updateDecoSettingProduct, updateDecoSettingProductApi);

const updateDecoSettingProductToastEpic = createToastEpic(updateDecoSettingProduct.success, 'Successfully saved');

const deleteDecoSettingProductEpic = createDeleteEpic(deleteDecoSettingProduct, deleteDecoSettingProductApi);

const deleteDecoSettingProductToastEpic = createToastEpic(deleteDecoSettingProduct.success, 'Successfully deleted');

const bulkCreateDecoSettingProductEpic = createPostEpic(bulkCreateDecoSettingProducts, bulkCreateDecoSettingProductApi);

const bulkCreateDecoSettingProductToastEpic = createToastEpic(
  bulkCreateDecoSettingProducts.success,
  'Successfully copy from'
);

const bulkDeleteDecoSettingProductEpic = createDeleteBodyEpic(
  bulkDeleteDecoSettingProducts,
  bulkDeleteDecoSettingProductApi
);

export default combineEpics(
  createDecoSettingProductEpic,
  createDecoSettingProductToastEpic,
  updateDecoSettingProductEpic,
  updateDecoSettingProductToastEpic,
  deleteDecoSettingProductEpic,
  deleteDecoSettingProductToastEpic,
  bulkCreateDecoSettingProductEpic,
  bulkCreateDecoSettingProductToastEpic,
  bulkDeleteDecoSettingProductEpic
);
