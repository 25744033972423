import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as visitorConfigActions from './actions';
import { initialState, VisitorConfigState } from './types';

const visitorConfig = createReducer<VisitorConfigState, RootAction>(initialState)
  .handleAction(visitorConfigActions.readVisitorConfig.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(visitorConfigActions.updateVisitorConfig.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(visitorConfigActions.listVisitorConfig.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(visitorConfigActions.listVisitorConfig.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default visitorConfig;
