import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  NoticePopupButtonActions as Actions,
  NoticePopupButtonCreateRequestData,
  NoticePopupButtonCreateSuccessData,
  NoticePopupButtonUpdateRequestData,
  NoticePopupButtonUpdateSuccessData,
} from './types';

export const createNoticePopupButton = createAsyncAction(
  Actions.CREATE_BUTTON_REQUEST,
  Actions.CREATE_BUTTON_SUCCESS,
  Actions.CREATE_BUTTON_FAILURE
)<FormRequestPayload<NoticePopupButtonCreateRequestData>, NoticePopupButtonCreateSuccessData, AxiosError>();

export const updateNoticePopupButton = createAsyncAction(
  Actions.UPDATE_BUTTON_REQUEST,
  Actions.UPDATE_BUTTON_SUCCESS,
  Actions.UPDATE_BUTTON_FAILURE
)<FormRequestPayload<NoticePopupButtonUpdateRequestData>, NoticePopupButtonUpdateSuccessData, AxiosError>();

export const deleteNoticePopupButton = createAsyncAction(
  Actions.DELETE_BUTTON_REQUEST,
  Actions.DELETE_BUTTON_SUCCESS,
  Actions.DELETE_BUTTON_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
