import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload, ListRequestPayload, ListState } from 'store/types';

import {
  MatchFilterOptionListingActions as Actions,
  MatchFilterOptionListingItemFormValues,
  MatchFilterOptionListingListItem,
  MatchFilterOptionListingUpsertRequestData,
  MatchFilterOptionListingUpsertResponseData,
} from './types';

export const listMatchFilterOptionListing = createAsyncAction(
  Actions.LIST_MATCH_FILTER_OPTION_LISTINGS_REQUEST,
  Actions.LIST_MATCH_FILTER_OPTION_LISTINGS_SUCCESS,
  Actions.LIST_MATCH_FILTER_OPTION_LISTINGS_FAILURE
)<ListRequestPayload, ListState<MatchFilterOptionListingListItem>, AxiosError>();

export const upsertMatchFilterOptionListing = createAsyncAction(
  Actions.UPSERT_MATCH_FILTER_OPTION_LISTING_REQUEST,
  Actions.UPSERT_MATCH_FILTER_OPTION_LISTING_SUCCESS,
  Actions.UPSERT_MATCH_FILTER_OPTION_LISTING_FAILURE
)<
  FormRequestPayload<MatchFilterOptionListingItemFormValues, MatchFilterOptionListingUpsertRequestData>,
  MatchFilterOptionListingUpsertResponseData,
  AxiosError
>();

export const deleteMatchFilterOptionListing = createAsyncAction(
  Actions.DELETE_MATCH_FILTER_OPTION_LISTING_REQUEST,
  Actions.DELETE_MATCH_FILTER_OPTION_LISTING_SUCCESS,
  Actions.DELETE_MATCH_FILTER_OPTION_LISTING_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
