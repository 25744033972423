import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  MlConfigFeatureActions as Actions,
  MlConfigFeatureCreateRequestData,
  MlConfigFeatureCreateRequestFormValues,
  MlConfigFeatureCreateSuccessData,
  MlConfigFeatureDetail,
  MlConfigFeatureListItem,
  MlConfigFeatureUpdateRequestData,
  MlConfigFeatureUpdateRequestFormValues,
  MlConfigFeatureUpdateSuccessData,
} from './types';

export const listMlConfigFeature = createAsyncAction(
  Actions.LIST_ML_CONFIG_FEATURE_REQUEST,
  Actions.LIST_ML_CONFIG_FEATURE_SUCCESS,
  Actions.LIST_ML_CONFIG_FEATURE_FAILURE
)<ListRequestPayload, ListState<MlConfigFeatureListItem>, AxiosError>();

export const createMlConfigFeature = createAsyncAction(
  Actions.CREATE_ML_CONFIG_FEATURE_REQUEST,
  Actions.CREATE_ML_CONFIG_FEATURE_SUCCESS,
  Actions.CREATE_ML_CONFIG_FEATURE_FAILURE
)<
  FormRequestPayload<MlConfigFeatureCreateRequestFormValues, MlConfigFeatureCreateRequestData>,
  MlConfigFeatureCreateSuccessData,
  AxiosError
>();

export const readMlConfigFeature = createAsyncAction(
  Actions.READ_ML_CONFIG_FEATURE_REQUEST,
  Actions.READ_ML_CONFIG_FEATURE_SUCCESS,
  Actions.READ_ML_CONFIG_FEATURE_FAILURE
)<ReadRequestPayload, MlConfigFeatureDetail, AxiosError>();

export const updateMlConfigFeature = createAsyncAction(
  Actions.UPDATE_ML_CONFIG_FEATURE_REQUEST,
  Actions.UPDATE_ML_CONFIG_FEATURE_SUCCESS,
  Actions.UPDATE_ML_CONFIG_FEATURE_FAILURE
)<
  FormRequestPayload<MlConfigFeatureUpdateRequestFormValues, MlConfigFeatureUpdateRequestData>,
  MlConfigFeatureUpdateSuccessData,
  AxiosError
>();
