import { initialListState, ListState } from 'store/types';

export enum LocalizationKeyActions {
  LIST_LOCALIZATION_KEY_REQUEST = 'LIST_LOCALIZATION_KEY#REQUEST',
  LIST_LOCALIZATION_KEY_SUCCESS = 'LIST_LOCALIZATION_KEY#SUCCESS',
  LIST_LOCALIZATION_KEY_FAILURE = 'LIST_LOCALIZATION_KEY#FAILURE',
}

export interface LocalizationKey {
  key: string;
}

export interface LocalizationKeyState {
  list: ListState<LocalizationKey>;
}

export const initialState: LocalizationKeyState = { list: initialListState };
