export enum TeamMessageContentSettingActions {
  CREATE_TEAM_MESSAGE_CONTENT_SETTING_REQUEST = 'CREATE_TEAM_MESSAGE_CONTENT_SETTING#REQUEST',
  CREATE_TEAM_MESSAGE_CONTENT_SETTING_SUCCESS = 'CREATE_TEAM_MESSAGE_CONTENT_SETTING#SUCCESS',
  CREATE_TEAM_MESSAGE_CONTENT_SETTING_FAILURE = 'CREATE_TEAM_MESSAGE_CONTENT_SETTING#FAILURE',

  UPDATE_TEAM_MESSAGE_CONTENT_SETTING_REQUEST = 'UPDATE_TEAM_MESSAGE_CONTENT_SETTING#REQUEST',
  UPDATE_TEAM_MESSAGE_CONTENT_SETTING_SUCCESS = 'UPDATE_TEAM_MESSAGE_CONTENT_SETTING#SUCCESS',
  UPDATE_TEAM_MESSAGE_CONTENT_SETTING_FAILURE = 'UPDATE_TEAM_MESSAGE_CONTENT_SETTING#FAILURE',
}

export interface TeamMessageContentSetting {
  title: string;
  content: string;
  button?: string;
}

export interface TeamMessageContentSettingFormValues extends TeamMessageContentSetting {
  titleAsString: boolean;
  messageAsString: boolean;
  buttonAsString: boolean;
}

export type TeamMessageContentSettingCreateRequestData = TeamMessageContentSetting;

export type TeamMessageContentSettingCreateResponseData = TeamMessageContentSetting;

export type TeamMessageContentSettingUpdateRequestData = TeamMessageContentSetting;

export type TeamMessageContentSettingUpdateResponseData = TeamMessageContentSetting;
