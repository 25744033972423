import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  PunishmentActions as Actions,
  PunishmentRequestPayload,
  PunishmentResponseData,
  DeletePunishmentRequestPayload,
  DeletePunishmentSuccessPayload,
} from './types';

export const listPunishment = createAsyncAction(
  Actions.LIST_PUNISHMENT_REQUEST,
  Actions.LIST_PUNISHMENT_SUCCESS,
  Actions.LIST_PUNISHMENT_FAILURE
)<PunishmentRequestPayload, PunishmentResponseData, AxiosError>();

export const deletePunishment = createAsyncAction(
  Actions.DELETE_PUNISHMENT_REQUEST,
  Actions.DELETE_PUNISHMENT_SUCCESS,
  Actions.DELETE_PUNISHMENT_FAILURE
)<DeletePunishmentRequestPayload, DeletePunishmentSuccessPayload, AxiosError>();
