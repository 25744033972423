import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic } from 'store/utils';

import {
  createGemProductTier,
  listGemProductTier,
  readGemProductTier,
  refreshGemProductTier,
  updateGemProduct,
  updateGemProductTier,
} from './actions';
import {
  createGemProductTierApi,
  listGemProductTierApi,
  readGemProductTierApi,
  refreshGemProductTierApi,
  updateGemProductApi,
  updateGemProductTierApi,
} from './api';

const updateGemProductToastEpic = createToastEpic(updateGemProduct.success, 'Successfully saved Gem Product');

const updateGemProductTierToastEpic = createToastEpic(
  updateGemProductTier.success,
  'Successfully saved Gem Product Tier'
);

const refreshGemProductTierToastEpic = createToastEpic(refreshGemProductTier.success, 'Successfully refreshed server');

const createGemProductTierEpic = createPostEpic(createGemProductTier, createGemProductTierApi);

const updateGemProductEpic = createPostEpic(updateGemProduct, updateGemProductApi);

const updateGemProductTierEpic = createPostEpic(updateGemProductTier, updateGemProductTierApi);

const listGemProductTierEpic = createListEpic(listGemProductTier, listGemProductTierApi);

const readGemProductTierEpic = createReadEpic(readGemProductTier, readGemProductTierApi);

const refreshGemProductTierEpic = createPostEpic(refreshGemProductTier, refreshGemProductTierApi);

export default combineEpics(
  updateGemProductToastEpic,
  updateGemProductTierToastEpic,
  refreshGemProductTierToastEpic,
  createGemProductTierEpic,
  updateGemProductEpic,
  updateGemProductTierEpic,
  listGemProductTierEpic,
  readGemProductTierEpic,
  refreshGemProductTierEpic
);
