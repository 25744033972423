export enum PurposeScheduleActions {
  CREATE_PURPOSE_SCHEDULE_REQUEST = 'CREATE_PURPOSE_SCHEDULE#REQUEST',
  CREATE_PURPOSE_SCHEDULE_SUCCESS = 'CREATE_PURPOSE_SCHEDULE#SUCCESS',
  CREATE_PURPOSE_SCHEDULE_FAILURE = 'CREATE_PURPOSE_SCHEDULE#FAILURE',

  UPDATE_PURPOSE_SCHEDULE_REQUEST = 'UPDATE_PURPOSE_SCHEDULE#REQUEST',
  UPDATE_PURPOSE_SCHEDULE_SUCCESS = 'UPDATE_PURPOSE_SCHEDULE#SUCCESS',
  UPDATE_PURPOSE_SCHEDULE_FAILURE = 'UPDATE_PURPOSE_SCHEDULE#FAILURE',

  DELETE_PURPOSE_SCHEDULE_REQUEST = 'DELETE_PURPOSE_SCHEDULE#REQUEST',
  DELETE_PURPOSE_SCHEDULE_SUCCESS = 'DELETE_PURPOSE_SCHEDULE#SUCCESS',
  DELETE_PURPOSE_SCHEDULE_FAILURE = 'DELETE_PURPOSE_SCHEDULE#FAILURE',
}

export interface PurposeSchedule {
  id: number;
  purposeSetId: number;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  startDate?: string;
  endDate?: string;
  priority: number;
}

export type PurposeScheduleFormValues = Omit<PurposeSchedule, 'id'>;

export type PurposeScheduleCreateRequestData = PurposeScheduleFormValues;

export type PurposeScheduleCreateResponseData = PurposeSchedule;

export type PurposeScheduleUpdateRequestData = PurposeScheduleFormValues;

export type PurposeScheduleUpdateResponseData = PurposeSchedule;
