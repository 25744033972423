import { Action } from 'typesafe-actions';

export type UserState = User;

export enum UserActions {
  FETCH_SIGN_IN_REQUEST = 'FETCH_SIGN_IN#REQUEST',
  FETCH_SIGN_IN_SUCCESS = 'FETCH_SIGN_IN#SUCCESS',
  FETCH_SIGN_IN_FAILURE = 'FETCH_SIGN_IN#FAILURE',

  REFRESH_JWT_REQUEST = 'REFRESH_JWT#REQUEST',
  REFRESH_JWT_SUCCESS = 'REFRESH_JWT#SUCCESS',
  REFRESH_JWT_FAILURE = 'REFRESH_JWT#FAILURE',

  SIGN_OUT = 'SIGN_OUT',
}

export const initialState: User = {
  access: '',
  refresh: '',
  roles: [],
  username: '',
};

export interface User {
  email?: string;
  access: string;
  refresh: string;
  roles: string[];
  username: string;
}

export type SignInSuccessPayload = {
  token: string;
  refreshToken: string;
  roles: string[];
  username: string;
  email: string;
};

export interface RefreshJWTRequestPayload {
  action: Action<any>;
}

export interface RefreshJWTSuccessPayload {
  token: string;
  action: Action<any>;
}
