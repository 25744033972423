import { MatchFilterOption } from 'store/matchFilterOption/types';
import { initialListState, ListState } from 'store/types';

export enum MatchFilterOptionListingActions {
  LIST_MATCH_FILTER_OPTION_LISTINGS_REQUEST = 'LIST_MATCH_FILTER_OPTION_LISTINGS#REQUEST',
  LIST_MATCH_FILTER_OPTION_LISTINGS_SUCCESS = 'LIST_MATCH_FILTER_OPTION_LISTINGS#SUCCESS',
  LIST_MATCH_FILTER_OPTION_LISTINGS_FAILURE = 'LIST_MATCH_FILTER_OPTION_LISTINGS#FAILURE',

  UPSERT_MATCH_FILTER_OPTION_LISTING_REQUEST = 'UPSERT_MATCH_FILTER_OPTION_LISTING#REQUEST',
  UPSERT_MATCH_FILTER_OPTION_LISTING_SUCCESS = 'UPSERT_MATCH_FILTER_OPTION_LISTING#SUCCESS',
  UPSERT_MATCH_FILTER_OPTION_LISTING_FAILURE = 'UPSERT_MATCH_FILTER_OPTION_LISTING#FAILURE',

  DELETE_MATCH_FILTER_OPTION_LISTING_REQUEST = 'DELETE_MATCH_FILTER_OPTION_LISTING#REQUEST',
  DELETE_MATCH_FILTER_OPTION_LISTING_SUCCESS = 'DELETE_MATCH_FILTER_OPTION_LISTING#SUCCESS',
  DELETE_MATCH_FILTER_OPTION_LISTING_FAILURE = 'DELETE_MATCH_FILTER_OPTION_LISTING#FAILURE',
}

export const initialState: MatchFilterOptionListingState = { list: initialListState };

export interface MatchFilterOptionListingState {
  list: ListState<MatchFilterOptionListingListItem>;
}

export interface MatchFilterOptionListingItem extends MatchFilterOption {
  displayOrder: number;
  displayGroup?: string | null;
  paid: boolean;
  useDefaultGemCost: boolean;
  gemCost: number;
  enabled: boolean;
}

export interface MatchFilterOptionListingListItem {
  optionListingId: string;
  options: MatchFilterOptionListingItem[];
}

export interface MatchFilterOptionListingFormValues {
  options: MatchFilterOptionListingItem[];
}

export type MatchFilterOptionListingItemFormValues = MatchFilterOptionListingItem;

export type MatchFilterOptionListingItemRequestData = Omit<
  MatchFilterOptionListingItem,
  'optionKey' | 'localizationKey' | 'displayName'
>;

export interface MatchFilterOptionListingUpsertRequestData {
  filterId: string;
  segmentCode?: string;
  options: MatchFilterOptionListingItemRequestData[];
}

export type MatchFilterOptionListingUpsertResponseData = MatchFilterOptionListingItem[];
