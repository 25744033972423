import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  SpecialPromotionSettingMapEntryActions as Actions,
  SpecialPromotionSettingMapEntryCreateRequestData,
  SpecialPromotionSettingMapEntryCreateSuccessData,
  SpecialPromotionSettingMapEntryUpdateRequestData,
  SpecialPromotionSettingMapEntryUpdateSuccessData,
} from './types';

export const createSpecialPromotionSettingMapEntry = createAsyncAction(
  Actions.CREATE_MAP_ENTRY_REQUEST,
  Actions.CREATE_MAP_ENTRY_SUCCESS,
  Actions.CREATE_MAP_ENTRY_FAILURE
)<
  FormRequestPayload<SpecialPromotionSettingMapEntryCreateRequestData>,
  SpecialPromotionSettingMapEntryCreateSuccessData,
  AxiosError
>();

export const updateSpecialPromotionSettingMapEntry = createAsyncAction(
  Actions.UPDATE_MAP_ENTRY_REQUEST,
  Actions.UPDATE_MAP_ENTRY_SUCCESS,
  Actions.UPDATE_MAP_ENTRY_FAILURE
)<
  FormRequestPayload<SpecialPromotionSettingMapEntryUpdateRequestData>,
  SpecialPromotionSettingMapEntryUpdateSuccessData,
  AxiosError
>();

export const deleteSpecialPromotionSettingMapEntry = createAsyncAction(
  Actions.DELETE_MAP_ENTRY_REQUEST,
  Actions.DELETE_MAP_ENTRY_SUCCESS,
  Actions.DELETE_MAP_ENTRY_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
