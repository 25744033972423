import {
  GemProductTierScheduleCreateRequestData,
  GemProductTierScheduleCreateSuccessData,
  GemProductTierScheduleUpdateRequestData,
  GemProductTierScheduleUpdateSuccessData,
} from 'store/gemProductTierSchedule/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createGemProductTierScheduleApi = createPostApi<
  GemProductTierScheduleCreateRequestData,
  GemProductTierScheduleCreateSuccessData
>('/gem-products/tiers/schedules');

export const updateGemProductTierScheduleApi = createUpdateApi<
  GemProductTierScheduleUpdateRequestData,
  GemProductTierScheduleUpdateSuccessData
>('/gem-products/tiers/schedules/:id');

export const deleteGemProductTierScheduleApi = createDeleteApi('/gem-products/tiers/schedules/:id');
