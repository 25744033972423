import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as webDecoEffectActions from './actions';
import { WebDecoEffectState, initialState } from './types';

const webDecoEffect = createReducer<WebDecoEffectState, RootAction>(initialState)
  .handleAction(webDecoEffectActions.listWebDecoEffect.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(webDecoEffectActions.listWebDecoEffect.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(webDecoEffectActions.readWebDecoEffect.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(webDecoEffectActions.listWebDecoGroupEffect.success, (state, { payload }) => ({
    ...state,
    groups: payload,
  }));

export default webDecoEffect;
