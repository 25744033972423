import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createMatchFilterConfig, deleteMatchFilterConfig, updateMatchFilterConfig } from './actions';
import { createMatchFilterConfigApi, deleteMatchFilterConfigApi, updateMatchFilterConfigApi } from './api';

const createMatchFilterConfigEpic = createPostEpic(createMatchFilterConfig, createMatchFilterConfigApi);

const createMatchFilterConfigToastEpic = createToastEpic(createMatchFilterConfig.success, 'Successfully created');

const updateMatchFilterConfigEpic = createUpdateEpic(updateMatchFilterConfig, updateMatchFilterConfigApi);

const updateMatchFilterConfigToastEpic = createToastEpic(updateMatchFilterConfig.success, 'Successfully saved');

const deleteMatchFilterConfigEpic = createDeleteEpic(deleteMatchFilterConfig, deleteMatchFilterConfigApi);

export default combineEpics(
  createMatchFilterConfigEpic,
  createMatchFilterConfigToastEpic,
  updateMatchFilterConfigEpic,
  updateMatchFilterConfigToastEpic,
  deleteMatchFilterConfigEpic
);
