import { initialListState, ListState } from 'store/types';

export enum ConfigStoreActions {
  CREATE_CONFIG_STORE_MAP_REQUEST = 'CREATE_CONFIG_STORE_MAP#REQUEST',
  CREATE_CONFIG_STORE_MAP_SUCCESS = 'CREATE_CONFIG_STORE_MAP#SUCCESS',
  CREATE_CONFIG_STORE_MAP_FAILURE = 'CREATE_CONFIG_STORE_MAP#FAILURE',

  UPDATE_CONFIG_STORE_REQUEST = 'UPDATE_CONFIG_STORE#REQUEST',
  UPDATE_CONFIG_STORE_SUCCESS = 'UPDATE_CONFIG_STORE#SUCCESS',
  UPDATE_CONFIG_STORE_FAILURE = 'UPDATE_CONFIG_STORE#FAILURE',

  UPDATE_CONFIG_STORE_MAP_REQUEST = 'UPDATE_CONFIG_STORE_MAP#REQUEST',
  UPDATE_CONFIG_STORE_MAP_SUCCESS = 'UPDATE_CONFIG_STORE_MAP#SUCCESS',
  UPDATE_CONFIG_STORE_MAP_FAILURE = 'UPDATE_CONFIG_STORE_MAP#FAILURE',

  READ_CONFIG_STORE_REQUEST = 'READ_CONFIG_STORE_MAP#REQUEST',
  READ_CONFIG_STORE_SUCCESS = 'READ_CONFIG_STORE_MAP#SUCCESS',
  READ_CONFIG_STORE_FAILURE = 'READ_CONFIG_STORE_MAP#FAILURE',

  REFRESH_CONFIG_STORE_REQUEST = 'REFRESH_CONFIG_STORE#REQUEST',
  REFRESH_CONFIG_STORE_SUCCESS = 'REFRESH_CONFIG_STORE#SUCCESS',
  REFRESH_CONFIG_STORE_FAILURE = 'REFRESH_CONFIG_STORE#FAILURE',

  DELETE_CONFIG_STORE_REQUEST = 'DELETE_CONFIG_STORE#REQUEST',
  DELETE_CONFIG_STORE_SUCCESS = 'DELETE_CONFIG_STORE#SUCCESS',
  DELETE_CONFIG_STORE_FAILURE = 'DELETE_CONFIG_STORE#FAILURE',
}

export const initialState: ConfigStoreState = {
  list: initialListState,
  detail: undefined,
};

export interface ConfigStoreState {
  list: ListState<ConfigStoreListItem>;
  detail?: ConfigStoreDetail;
}

export enum ConfigStoreType {
  SIMPLE = 'SIMPLE',
  LIST = 'LIST',
  MAP = 'MAP',
  SET = 'SET',
}

export interface ConfigStore {
  configStoreId: string;
  configStoreKey: string;
  type: ConfigStoreType;
  configStoreValue: string;
  description?: string;
}

export interface MapEntry {
  id?: number | null;
  mapKey: string;
  mapValue: string;
}

export interface CollectionEntry {
  id?: number | null;
  collectionValue: string;
  order: string;
}

export interface ConfigStoreListItem extends ConfigStore {}

export interface ConfigStoreDetail extends ConfigStore {
  mapElements?: MapEntry[];
  collectionElements?: CollectionEntry[];
}

export interface ConfigStoreFormValues extends ConfigStore {}

export type ConfigStoreRequestData = ConfigStore;

export type ConfigStoreResponseData = ConfigStore;

export type ConfigStoreMapRequestData = ConfigStoreDetail;
export type ConfigStoreMapRequestResponse = ConfigStoreDetail;
