import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from '../types';

import { ItemPackageProvideActions as Actions, ItemPackageRequestFormValues } from './types';

export const provideItemPackage = createAsyncAction(
  Actions.PROVIDE_ITEM_PACKAGE_REQUEST,
  Actions.PROVIDE_ITEM_PACKAGE_SUCCESS,
  Actions.PROVIDE_ITEM_PACKAGE_FAILURE
)<FormRequestPayload<ItemPackageRequestFormValues>, undefined, AxiosError>();
