import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  MatchFilterSegmentCreateRequestData,
  MatchFilterSegmentDetail,
  MatchFilterSegmentListItem,
  MatchFilterSegmentUpdateRequestData,
} from './types';

export const listMatchFilterSegmentApi = createListApi<MatchFilterSegmentListItem>('/match_filters/segments');

export const readMatchFilterSegmentApi = createReadApi<MatchFilterSegmentDetail>('/match_filters/segments/:id');

export const createMatchFilterSegmentApi = createPostApi<
  MatchFilterSegmentCreateRequestData,
  MatchFilterSegmentCreateRequestData
>('/match_filters/segments/:id');

export const updateMatchFilterSegmentApi = createUpdateApi<
  MatchFilterSegmentUpdateRequestData,
  MatchFilterSegmentUpdateRequestData
>('/match_filters/segments/:id');

export const deleteMatchFilterSegmentApi = createDeleteApi('/match_filters/segments/:id');
