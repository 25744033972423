import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import { AgeManageActions as Actions, AgeManageUpdateRequestData, AgeManageUpdateRequestFormValues } from './types';

export const updateAgeManage = createAsyncAction(
  Actions.UPDATE_AGE_MANAGE_REQUEST,
  Actions.UPDATE_AGE_MANAGE_SUCCESS,
  Actions.UPDATE_AGE_MANAGE_FAILURE
)<FormRequestPayload<AgeManageUpdateRequestFormValues, AgeManageUpdateRequestData>, undefined, AxiosError>();
