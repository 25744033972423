import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  DecoResourceActions as Actions,
  DecoResourceBulkUploadRequestData,
  DecoResourceBulkUploadResponseData,
  DecoResourceCreateRequestData,
  DecoResourceCreateResponseData,
  DecoResourceFormValues,
  DecoResourceUpdateRequestData,
  DecoResourceUpdateResponseData,
} from './types';

export const createDecoResource = createAsyncAction(
  Actions.CREATE_DECO_RESOURCE_REQUEST,
  Actions.CREATE_DECO_RESOURCE_SUCCESS,
  Actions.CREATE_DECO_RESOURCE_FAILURE
)<
  FormRequestPayload<DecoResourceFormValues, DecoResourceCreateRequestData>,
  DecoResourceCreateResponseData,
  AxiosError
>();

export const updateDecoResource = createAsyncAction(
  Actions.UPDATE_DECO_RESOURCE_REQUEST,
  Actions.UPDATE_DECO_RESOURCE_SUCCESS,
  Actions.UPDATE_DECO_RESOURCE_FAILURE
)<
  FormRequestPayload<DecoResourceFormValues, DecoResourceUpdateRequestData>,
  DecoResourceUpdateResponseData,
  AxiosError
>();

export const bulkUploadDecoResource = createAsyncAction(
  Actions.BULK_UPLOAD_DECO_RESOURCE_REQUEST,
  Actions.BULK_UPLOAD_DECO_RESOURCE_SUCCESS,
  Actions.BULK_UPLOAD_DECO_RESOURCE_FAILURE
)<
  FormRequestPayload<DecoResourceBulkUploadRequestData, DecoResourceBulkUploadRequestData>,
  DecoResourceBulkUploadResponseData,
  AxiosError
>();
