import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteBodyRequestPayload,
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ShopSectionPageActions as Actions,
  ShopSectionPageRequestData,
  ShopSectionPageResponseData,
  ShopSectionPageFormValues,
  ShopSectionListItem,
  ShopSectionScheduleCreateRequestData,
  ShopSectionScheduleCreateResponseData,
  ShopSectionScheduleFormValues,
  ShopSectionScheduleUpdateRequestData,
  ShopSectionScheduleUpdateResponseData,
  ShopSectionModuleFormValue,
  ShopSectionModuleRequestData,
  ShopSectionModuleResponseData,
  ShopSectionModuleBulkCreateRequestData,
  ShopSectionModuleBulkCreateResponseData,
} from './types';

export const createShopSectionPage = createAsyncAction(
  Actions.CREATE_SHOP_SECTION_PAGE_REQUEST,
  Actions.CREATE_SHOP_SECTION_PAGE_SUCCESS,
  Actions.CREATE_SHOP_SECTION_PAGE_FAILURE
)<FormRequestPayload<ShopSectionPageFormValues, ShopSectionPageRequestData>, ShopSectionPageResponseData, AxiosError>();

export const listShopSectionPage = createAsyncAction(
  Actions.LIST_SHOP_SECTION_PAGE_REQUEST,
  Actions.LIST_SHOP_SECTION_PAGE_SUCCESS,
  Actions.LIST_SHOP_SECTION_PAGE_FAILURE
)<ListRequestPayload, ListState<ShopSectionListItem>, AxiosError>();

export const updateShopSectionPage = createAsyncAction(
  Actions.UPDATE_SHOP_SECTION_PAGE_REQUEST,
  Actions.UPDATE_SHOP_SECTION_PAGE_SUCCESS,
  Actions.UPDATE_SHOP_SECTION_PAGE_FAILURE
)<FormRequestPayload<ShopSectionPageFormValues, ShopSectionPageRequestData>, ShopSectionPageResponseData, AxiosError>();

export const deleteShopSectionPage = createAsyncAction(
  Actions.DELETE_SHOP_SECTION_PAGE_REQUEST,
  Actions.DELETE_SHOP_SECTION_PAGE_SUCCESS,
  Actions.DELETE_SHOP_SECTION_PAGE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const readShopSectionPage = createAsyncAction(
  Actions.READ_SHOP_SECTION_PAGE_REQUEST,
  Actions.READ_SHOP_SECTION_PAGE_SUCCESS,
  Actions.READ_SHOP_SECTION_PAGE_FAILURE
)<ReadRequestPayload, ShopSectionPageResponseData, AxiosError>();

// - Module -
export const connectShopSectionModule = createAsyncAction(
  Actions.CONNECT_SHOP_SECTION_MODULE_REQUEST,
  Actions.CONNECT_SHOP_SECTION_MODULE_SUCCESS,
  Actions.CONNECT_SHOP_SECTION_MODULE_FAILURE
)<
  FormRequestPayload<ShopSectionModuleFormValue, ShopSectionModuleRequestData>,
  ShopSectionModuleResponseData,
  AxiosError
>();

export const editShopSectionModule = createAsyncAction(
  Actions.EDIT_SHOP_SECTION_MODULE_REQUEST,
  Actions.EDIT_SHOP_SECTION_MODULE_SUCCESS,
  Actions.EDIT_SHOP_SECTION_MODULE_FAILURE
)<
  FormRequestPayload<ShopSectionModuleFormValue, ShopSectionModuleRequestData>,
  ShopSectionModuleResponseData,
  AxiosError
>();

export const disconnectShopSectionModule = createAsyncAction(
  Actions.DISCONNECT_SHOP_SECTION_MODULE_REQUEST,
  Actions.DISCONNECT_SHOP_SECTION_MODULE_SUCCESS,
  Actions.DISCONNECT_SHOP_SECTION_MODULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - MODULE BULK -
export const bulkCreateShopSectionModules = createAsyncAction(
  Actions.BULK_CREATE_SHOP_SECTION_MODULE_REQUEST,
  Actions.BULK_CREATE_SHOP_SECTION_MODULE_SUCCESS,
  Actions.BULK_CREATE_SHOP_SECTION_MODULE_FAILURE
)<FormRequestPayload<ShopSectionModuleBulkCreateRequestData>, ShopSectionModuleBulkCreateResponseData, AxiosError>();

export const bulkDeleteShopSectionModules = createAsyncAction(
  Actions.BULK_DELETE_SHOP_SECTION_MODULE_REQUEST,
  Actions.BULK_DELETE_SHOP_SECTION_MODULE_SUCCESS,
  Actions.BULK_DELETE_SHOP_SECTION_MODULE_FAILURE
)<DeleteBodyRequestPayload, undefined, AxiosError>();

// - SCHEDULE -
export const createShopSectionSchedule = createAsyncAction(
  Actions.CREATE_SHOP_SECTION_PAGE_SCHEDULE_REQUEST,
  Actions.CREATE_SHOP_SECTION_PAGE_SCHEDULE_SUCCESS,
  Actions.CREATE_SHOP_SECTION_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopSectionScheduleFormValues, ShopSectionScheduleCreateRequestData>,
  ShopSectionScheduleCreateResponseData,
  AxiosError
>();

export const updateShopSectionSchedule = createAsyncAction(
  Actions.UPDATE_SHOP_SECTION_PAGE_SCHEDULE_REQUEST,
  Actions.UPDATE_SHOP_SECTION_PAGE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SHOP_SECTION_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopSectionScheduleFormValues, ShopSectionScheduleUpdateRequestData>,
  ShopSectionScheduleUpdateResponseData,
  AxiosError
>();

export const deleteShopSectionSchedule = createAsyncAction(
  Actions.DELETE_SHOP_SECTION_PAGE_SCHEDULE_REQUEST,
  Actions.DELETE_SHOP_SECTION_PAGE_SCHEDULE_SUCCESS,
  Actions.DELETE_SHOP_SECTION_PAGE_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
