import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import {
  PushContent,
  PushContentActions as Actions,
  PushContentCreateRequestData,
  PushContentDetail,
  PushContentVariationCreateRequestData,
  PushContentVariationCreateResponseData,
  PushContentVariationFormValues,
  PushContentVariationUpdateRequestData,
  PushContentVariationUpdateResponseData,
} from './types';

export const createPushContent = createAsyncAction(
  Actions.CREATE_PUSH_CONTENT_REQUEST,
  Actions.CREATE_PUSH_CONTENT_SUCCESS,
  Actions.CREATE_PUSH_CONTENT_FAILURE
)<FormRequestPayload<PushContentCreateRequestData>, PushContentDetail, AxiosError>();

export const readPushContent = createAsyncAction(
  Actions.READ_PUSH_CONTENT_REQUEST,
  Actions.READ_PUSH_CONTENT_SUCCESS,
  Actions.READ_PUSH_CONTENT_FAILURE
)<ReadRequestPayload, PushContentDetail, AxiosError>();

export const updatePushContent = createAsyncAction(
  Actions.UPDATE_PUSH_CONTENT_REQUEST,
  Actions.UPDATE_PUSH_CONTENT_SUCCESS,
  Actions.UPDATE_PUSH_CONTENT_FAILURE
)<FormRequestPayload<PushContentCreateRequestData>, PushContentDetail, AxiosError>();

export const listPushContent = createAsyncAction(
  Actions.LIST_PUSH_CONTENT_REQUEST,
  Actions.LIST_PUSH_CONTENT_SUCCESS,
  Actions.LIST_PUSH_CONTENT_FAILURE
)<ListRequestPayload, ListState<PushContent>, AxiosError>();

export const refreshPushContent = createAsyncAction(
  Actions.REFRESH_PUSH_CONTENT_REQUEST,
  Actions.REFRESH_PUSH_CONTENT_SUCCESS,
  Actions.REFRESH_PUSH_CONTENT_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();

// - Variation -
export const createPushContentVariation = createAsyncAction(
  Actions.CREATE_PUSH_CONTENT_VARIATION_REQUEST,
  Actions.CREATE_PUSH_CONTENT_VARIATION_SUCCESS,
  Actions.CREATE_PUSH_CONTENT_VARIATION_FAILURE
)<
  FormRequestPayload<PushContentVariationFormValues, PushContentVariationCreateRequestData>,
  PushContentVariationCreateResponseData,
  AxiosError
>();

export const updatePushContentVariation = createAsyncAction(
  Actions.UPDATE_PUSH_CONTENT_VARIATION_REQUEST,
  Actions.UPDATE_PUSH_CONTENT_VARIATION_SUCCESS,
  Actions.UPDATE_PUSH_CONTENT_VARIATION_FAILURE
)<
  FormRequestPayload<PushContentVariationFormValues, PushContentVariationUpdateRequestData>,
  PushContentVariationUpdateResponseData,
  AxiosError
>();

export const deletePushContentVariation = createAsyncAction(
  Actions.DELETE_PUSH_CONTENT_VARIATION_REQUEST,
  Actions.DELETE_PUSH_CONTENT_VARIATION_SUCCESS,
  Actions.DELETE_PUSH_CONTENT_VARIATION_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const readPushType = createAsyncAction(
  Actions.READ_PUSH_TYPE_REQUEST,
  Actions.READ_PUSH_TYPE_SUCCESS,
  Actions.READ_PUSH_TYPE_FAILURE
)<undefined, Array<string>, AxiosError>();
