import { REALM, RealmType } from 'utils/okta';

import { ENVIRONMENT } from 'constants/env';

export enum MenuEntryKey {
  GEM_SHOP = 'GEM_SHOP',
  USER = 'USER',
  CAMPAIGN = 'CAMPAIGN',
  SEGMENT = 'SEGMENT',
  AB_TEST = 'AB_TEST',
  MATCH_FILTER = 'MATCH_FILTER',
  DECO = 'DECO',
  PROMOTION = 'PROMOTION',
  NOTICE = 'NOTICE',
  LOCALIZATION = 'LOCALIZATION',
  TEAM_MATCH = 'TEAM_MATCH',
  FILE = 'FILE',
  REALTIME_MONITORING = 'REALTIME_MONITORING',
  MONITORING = 'MONITORING',
  EXTERNAL_URL = 'EXTERNAL_URL',
  AGENT = 'AGENT',
  ITEM_PACKAGE = 'ITEM_PACKAGE',
  BANNER = 'BANNER',
  SHOP_PAGE = 'SHOP_PAGE',
  REWARD_AD = 'REWARD_AD',
  PUNISHMENT = 'PUNISHMENT',
  MATATA = 'MATATA',
  AVATAR_ADMIN = 'AVATAR_ADMIN',
  CONFIG = 'CONFIG',
  INVENTORY = 'INVENTORY',
  PURCHASE = 'PURCHASE',
  VISITOR = 'VISITOR',
  PROMOTION_SCHEDULE = 'PROMOTION_SCHEDULE',
  APP_STORE = 'APP_STORE',
  PURPOSE = 'PURPOSE',
  ML_CONFIG = 'ML_CONFIG',
}

export const PageTitle = {
  GEM_SHOP_SETTING: {
    title: 'Gem Shop Setting',
    guideUrl:
      'https://docs.google.com/presentation/d/1oXsmEeDAytm008P4jMEfH69hrQkbw9eQnnVbjTwQlW0/edit#slide=id.g820f0107e8_0_0',
  },
  GEM_SHOP_BANNER: {
    title: 'Gem Shop Banner',
    guideUrl:
      'https://docs.google.com/presentation/d/1l72Ar1ciIwgVs0ySvphNCStz4CE5Fer2ewaNhTCgksY/edit#slide=id.g820f0107e8_0_0',
  },
  GEM_SHOP_SPECIAL_PROMOTION: {
    title: 'Gem Shop Special Promotion',
    guideUrl:
      'https://docs.google.com/presentation/d/1SmKhXNoNVxsG3vjzFmqLtM-QsGUFTD7ojopRjYYEzTc/edit#slide=id.g820f0107e8_0_0',
  },
  USER_LIST: {
    title: 'User List',
  },
  USER_CONVERT_KEY: {
    title: 'User Converted Key',
    guideUrl:
      'https://docs.google.com/presentation/d/1ZUYL3dozt0WdfLd2mMRfKanAt0hxbUjhtV2kjhLQ4g4/edit#slide=id.g820f0107e8_0_206',
  },
  USER_GENDER: {
    title: 'User Gender',
    guideUrl:
      'https://docs.google.com/presentation/d/1ZUYL3dozt0WdfLd2mMRfKanAt0hxbUjhtV2kjhLQ4g4/edit#slide=id.g8b815212c2_0_15',
  },
  USER_PROFILE: {
    title: 'User Profile',
    guideUrl: '',
  },
  USER_COUNTRY: {
    title: 'User Country',
    guideUrl:
      'https://docs.google.com/presentation/d/1ZUYL3dozt0WdfLd2mMRfKanAt0hxbUjhtV2kjhLQ4g4/edit#slide=id.g9367e9f036_0_5',
  },
  USER_MATCH_TAG: {
    title: 'User Match Tag',
    guideUrl:
      'https://docs.google.com/presentation/d/1ZUYL3dozt0WdfLd2mMRfKanAt0hxbUjhtV2kjhLQ4g4/edit#slide=id.g820f0107e8_0_220',
  },
  USER_AGE: {
    title: 'User Age',
    guideUrl:
      'https://docs.google.com/presentation/d/1ZUYL3dozt0WdfLd2mMRfKanAt0hxbUjhtV2kjhLQ4g4/edit#slide=id.g8e1cd4b0d8_0_9',
  },
  USER_BLOCK_PHONE_NUMBER: {
    title: 'User Blocked Phone Number Deleting',
    guideUrl: '',
  },
  USER_ITEM_INVENTORY: {
    title: 'User Item Inventory',
    guideUrl: 'https://docs.google.com/presentation/d/1OeXvG8aKFuHuMd3pdzzNuqAn9rHqCIbcLSmjhtOTHls/edit?usp=sharing',
  },
  USER_FRIENDSHIP: {
    title: 'User Friendship',
    guideUrl: '',
  },
  CAMPAIGN: {
    title: 'Campaign',
    guideUrl: '',
  },
  SEGMENT_USER: {
    title: 'User Segment',
    guideUrl:
      'https://docs.google.com/presentation/d/1K7zb0TmawqekHTiee6O5tN0P_oQ0Yo1UrQxAG6ubAbU/edit#slide=id.g820f0107e8_0_0',
  },
  SEGMENT_EXPRESSION: {
    title: 'Expression Segment',
    guideUrl:
      'https://docs.google.com/presentation/d/1K7zb0TmawqekHTiee6O5tN0P_oQ0Yo1UrQxAG6ubAbU/edit#slide=id.g820f0107e8_0_199',
  },
  SEGMENT_MANUAL: {
    title: 'Manual Segment',
    guideUrl:
      'https://docs.google.com/presentation/d/1K7zb0TmawqekHTiee6O5tN0P_oQ0Yo1UrQxAG6ubAbU/edit#slide=id.g53cba16915_0_0',
  },
  SEGMENT_CRITERIA: {
    title: 'Criteria Segment',
    guideUrl: '',
  },
  SEGMENT_BIGQUERY: {
    title: 'Bigquery Segment',
    guideUrl: '',
  },
  AB_TEST: {
    title: 'AB Test',
    guideUrl: '',
  },
  MATCH_FILTER_SEGMENT: {
    title: 'Match Filter Segment',
    guideUrl: 'https://docs.google.com/presentation/d/1MRm73eu3clZom2ZL1yZClKAPQJZ9DDFkFlEsZ1MJuqs/edit?usp=sharing',
  },
  MATCH_FILTER_SETTING: {
    title: 'Match Filter Setting',
    guideUrl: 'https://docs.google.com/presentation/d/1MRm73eu3clZom2ZL1yZClKAPQJZ9DDFkFlEsZ1MJuqs/edit?usp=sharing',
  },
  DECO_ITEM: {
    title: 'Deco Item',
    guideUrl: 'https://docs.google.com/presentation/d/1xTi0XVew-oVRdtz28KpB-iARhw-foN_D2EeX20kQASw/edit?usp=sharing',
  },
  DECO_SETTING: {
    title: 'Deco Setting',
    guideUrl: 'https://docs.google.com/presentation/d/1xTi0XVew-oVRdtz28KpB-iARhw-foN_D2EeX20kQASw/edit?usp=sharing',
  },
  PROMOTION_SETTING: {
    title: 'Promotion Setting',
    guideUrl: 'https://docs.google.com/presentation/d/18FKvKgaTCtpIo1asFagY9iUZSkZIf4LWfLmv7HC9mHM/edit#slide=id.p',
  },
  PROMOTION_CONTENT: {
    title: 'Promotion Content',
    guideUrl: 'https://docs.google.com/presentation/d/1xpi1k0AklEFGSseOHfkb4UoEjJGaq_0mr5KatqK0ckI/edit#slide=id.p',
  },
  PROMOTION_CODE_PUBLISH: {
    title: 'Promotion Code Publish',
    guideUrl: 'https://www.notion.so/hpcnt/Promotion-Code-Publish-e9e08d60f97c49ae9ed9bcd957223ad2',
  },
  NOTICE_POPUP: {
    title: 'Notice Popup',
    guideUrl: 'https://www.notion.so/hpcnt/Notice-Popup-0871ecd0bdd243989351e1abd3f4bc0e',
  },
  NOTICE_TEAM_MESSAGE: {
    title: 'Team Message Notice',
    guideUrl: 'https://docs.google.com/presentation/d/1Z8pXt2a2FGaUE-bW41iK8l9yuDuNMX1w2kqjvDeFKx4/edit#slide=id.p',
  },
  NOTIFICATION_CONTENT: {
    title: 'Notification Content',
    guideUrl: 'https://www.notion.so/hpcnt/Notification-Content-b477248bc1a248dbb64174593b88b65d',
  },
  NOTIFICATION_SCHEDULE: {
    title: 'Notification Schedule',
    guideUrl: 'https://www.notion.so/hpcnt/Notification-Schedule-4049a40ef987408eaa7984700dd93f89',
  },
  NOTICE_PUSH_CONTENT: {
    title: 'Push Content',
    guideUrl: 'https://www.notion.so/hpcnt/Push-Content-a850c40c202a42deafab371db40a1ad8',
  },
  NOTICE_PUSH_SCHEDULE: {
    title: 'Push Schedule',
    guideUrl: '',
  },
  NOTIFICATION_CENTER_BANNER: {
    title: 'Notification Center Banner',
    guideUrl: '',
  },
  LOCALIZATION_STRING: {
    title: 'String Localization',
    guideUrl:
      'https://docs.google.com/presentation/d/1isRsUXOtCa0Lrn4bFPCMP_jG7909Jw4oCanj9IQYHOk/edit#slide=id.g820f0107e8_0_0',
  },
  TEAM_MATCH_CAMPAIGN: {
    title: 'Team Match Campaign',
    guideUrl: '',
  },
  TEAM_MATCH_PROFILE: {
    title: 'Team Match Profile',
    guideUrl: '',
  },
  DOWNLOADABLE_CONTENT: {
    title: 'Downloadable content',
    guideUrl: '',
  },
  EXTERNAL_URL_RN_VERSION: {
    title: 'RN Version Check URL',
    guideUrl: 'https://www.notion.so/hpcnt/ef38b38f334f4bff83f7fdc5bd0a5cb4',
  },
  EXTERNAL_URL_BUILD_RELEASE_RATE_CHECK: {
    title: 'Build Release Rate URL',
    guideUrl: '',
  },
  REALTIME_MONITORING_WORKLOAD: {
    title: 'RealTime Monitoring',
    guideUrl: '',
  },
  REALTIME_MONITORING_NOTICE: {
    title: 'Notice',
    guideUrl: '',
  },
  AGENT_MANAGEMENT: {
    title: 'Agent Management',
    guideUrl: '',
  },
  AGENT_HISTORY: {
    title: 'Agent History',
    guideUrl: '',
  },
  PUNISH: {
    title: 'Punish',
    guideUrl: '',
  },
  GEM_PRODUCT: {
    title: 'Gem Product',
    guideUrl: '',
  },
  ITEM: {
    title: 'Item Product',
    guideUrl: 'https://www.notion.so/hpcnt/Item-Prodcut-98460d5c33fe440aac08efca7ac753fa',
  },
  ITEM_PACKAGE: {
    title: 'Item Package',
    guideUrl: '',
  },
  WATER_SLIDE_BANNER: {
    title: 'Mirror Banner',
    guideUrl: '',
  },
  MESSAGE_BANNER: {
    title: 'Message Banner',
    guideUrl: '',
  },
  SHOP_ENTRY_BANNER: {
    title: 'Shop Entry Banner',
    guideUrl: '',
  },

  SHOP_MAIN_PAGE: {
    title: 'Shop Main Page',
    guideUrl: '',
  },
  SHOP_DETAIL_PAGE: {
    title: 'Shop Detail Page',
    guideUrl: '',
  },
  SHOP_SECTION_PAGE: {
    title: 'Shop Section',
    guideUrl: '',
  },
  SHOP_MODULE_PAGE: {
    title: 'Shop Module',
    guideUrl: '',
  },
  SHOP_TAB_PAGE: {
    title: 'Shop Tab',
    guideUrl: '',
  },
  WEB_TIER_PRICE: {
    title: 'Upload Web price',
    guideUrl: '',
  },
  LIMITED_EDITION: {
    title: 'Limited Edition',
    guideUrl: '',
  },
  PUNISHMENT: {
    title: 'Active Punishment Search',
    guideUrl: '',
  },
  MATATA_SUSPENSION_HISTORY: {
    title: 'Matata Suspension History',
    guideUrl: '',
  },
  MATATA_DEVICE_UNBINDING: {
    title: 'Matata Device Unbinding',
    guideUrl: '',
  },
  MATATA_ORDER_TRANSACTION: {
    title: 'Matata Order Transaction',
    guideUrl: 'https://www.notion.so/hpcnt/Matata-Order-Transaction-a597c1f0a82245cbaaf308db5998b241',
  },
  AVATAR_ADMIN: {
    title: 'Avatar Admin Page',
    guideUrl: '',
  },
  AVATAR_SIMULATOR: {
    title: 'Simulator Page',
    guideUrl: '',
  },
  AVATAR_DECO: {
    title: 'Deco Page',
    guideUrl: '',
  },
  AVATAR_CHARACTER: {
    title: 'Character Page',
    guideUrl: '',
  },
  AVATAR_BGSET: {
    title: 'BGSet Page',
    guideUrl: '',
  },
  AVATAR_ANIMATION: {
    title: 'Animation Page',
    guideUrl: '',
  },
  AVATAR_INITIALSET: {
    title: 'Initial Set Page',
    guideUrl: '',
  },
  AVATAR_BESTSET: {
    title: 'Best Set Page',
    guideUrl: '',
  },
  REMOTE_CONFIG: {
    title: 'Remote Config',
    guideUrl: '',
  },
  GEM_PROVIDE: {
    title: 'Gem Provide',
    guideUrl: '',
  },
  ITEM_PROVIDE: {
    title: 'Item Product Provide',
    guideUrl: '',
  },
  ITEM_PACKAGE_PROVIDE: {
    title: 'Item Package Provide',
    guideUrl: '',
  },
  CONFIG_STORE: {
    title: 'Config Store',
    guideUrl: '',
  },
  GEM_WITHDRAW: {
    title: 'Gem Withdraw',
    guideUrl: '',
  },
  GEM_CANCEL_PURCHASE: {
    title: 'Gem Cancel Purchase',
    guideUrl: '',
  },
  INVALIDATED_PURCHASE: {
    title: 'Invalidated Purchase',
    guideUrl: 'https://www.notion.so/hpcnt/Invalidated-Purchase-7d82b6c42275411d85f6949f9b92dd88',
  },
  SHOP_MODULE_GROUP: {
    title: 'Shop Module Group',
    guideUrl: '',
  },
  VISITOR_CONFIG: {
    title: 'Visitor Config',
    guideUrl: 'https://www.notion.so/hpcnt/Visitor-Config-ba8a65530d76470eb705d924be566770',
  },
  VISITOR_AB_TEST: {
    title: 'Visitor AB Test',
    guideUrl: 'https://www.notion.so/hpcnt/Visitor-AB-Test-5c221c6ba7da403fa00581e31ab0cf7d',
  },
  VISITOR_SEGMENT: {
    title: 'Visitor Segment',
    guideUrl: 'https://www.notion.so/hpcnt/Visitor-Segmentation-c7897bd6fb7540b497385f0a18298eeb',
  },
  PROMOTION_SCHEDULE: {
    title: 'Promotion Schedule',
    guideUrl: '',
  },
  WEB_EFFECT: {
    title: 'Web Effect',
    guideUrl: 'https://www.notion.so/Deco-Studio-Web-e565bd8d52944945a7e92fe5411c955b',
  },
  WEB_EFFECT_SETTING: {
    title: 'Web Effect Setting',
    guideUrl: 'https://www.notion.so/Deco-Studio-Web-e565bd8d52944945a7e92fe5411c955b',
  },
  APP_STORE_REVIEW: {
    title: 'App Store Review',
    guideUrl: '',
  },
  PURPOSE: {
    title: 'Purpose',
  },
  ML_CONFIG_MODEL: {
    title: 'ML Config Model',
    guideUrl: '',
  },
  ML_CONFIG_FEATURE: {
    title: 'ML Config Feature',
    guideUrl: '',
  },
  PROMOTION_CONTENT_GROUP: {
    title: 'Promotion Content Group',
    guideUrl: '',
  },
} as const;

const KeycloakRealmWebTitle = {
  [RealmType.hpcnt]: 'Azar Backoffice ' + ENVIRONMENT,
  [RealmType.moderation]: 'Backoffice ' + ENVIRONMENT,
  [RealmType.unknown]: '',
};

export const WEB_TITLE = KeycloakRealmWebTitle[REALM];
