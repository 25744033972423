import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  NewUserSegment,
  UserSegment,
  UserSegmentCreateRequestData,
  UserSegmentCreateSuccessData,
  UserSegmentDetail,
  UserSegmentUpdateRequestData,
  UserSegmentUpdateSuccessData,
} from './types';

export const listUserSegmentApi = createListApi<UserSegment>('/segment');
export const listUserSegmentSearchApi = createListApi<NewUserSegment>('users/segments');
export const createUserSegmentApi = createPostApi<UserSegmentCreateRequestData, UserSegmentCreateSuccessData>(
  '/segment'
);
export const readUserSegmentApi = createReadApi<UserSegmentDetail>('/segment/:id/types/:segmentType');
export const updateUserSegmentApi = createUpdateApi<UserSegmentUpdateRequestData, UserSegmentUpdateSuccessData>(
  '/segment/:id'
);
export const deleteUserSegmentApi = createDeleteApi('/segment/:id');
