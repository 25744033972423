import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import * as gemSettingMapEntryActions from '../gemSettingMapEntry/actions';
import * as gemSettingProductActions from '../gemSettingProduct/actions';
import { RootAction } from '../root/types';

import * as gemSettingActions from './actions';
import { GemSettingState, initialState } from './types';

const compareNumberFunction = (a?: number, b?: number): number => {
  if (!b) {
    return 1;
  }
  if (!a) {
    return -1;
  }
  return a - b;
};

// noinspection TypeScriptValidateJSTypes
const gemSetting = createReducer<GemSettingState, RootAction>(initialState)
  .handleAction(gemSettingActions.listGemSetting.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(gemSettingActions.listGemSetting.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(gemSettingActions.readGemSetting.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(gemSettingActions.setGemSettingProducts, (state, { payload }) => {
    if (!state.detail) {
      return state;
    }
    return {
      ...state,
      detail: {
        ...state.detail,
        gemShopSettingProducts: payload,
      },
    };
  })
  .handleAction(gemSettingProductActions.updateGemSettingProduct.success, (state, { payload }) => {
    if (payload.settingId !== state.detail?.id) {
      return state;
    }
    const index = state.detail?.gemShopSettingProducts.findIndex((product) => product.id === payload.id);
    if (index !== null && index > -1) {
      let newState: Map<keyof typeof state, any> = fromJS(state);
      newState = newState.setIn(['detail', 'gemShopSettingProducts', index], payload);
      return newState.toJS() as GemSettingState;
    }
    return state;
  })
  .handleAction(gemSettingProductActions.createGemSettingProduct.success, (state, { payload }) => {
    if (!state.detail || payload.settingId !== state.detail?.id) {
      return state;
    }
    const products = state.detail.gemShopSettingProducts.filter(
      (product) =>
        product.appStoreIabProductId !== payload.appStoreIabProductId &&
        product.googlePlayIabProductId !== payload.googlePlayIabProductId
    );
    let newProducts = [...products, payload];
    newProducts = newProducts.sort((a, b) => {
      const rowDiff = compareNumberFunction(a.rowOrder, b.rowOrder);
      if (rowDiff === 0) {
        return compareNumberFunction(a.columnOrder, b.columnOrder);
      }
      return rowDiff;
    });
    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            gemShopSettingProducts: newProducts,
          }
        : undefined,
    };
  })
  .handleAction(gemSettingMapEntryActions.createGemSettingMapEntry.success, (state, { payload }) => {
    if (state.detail?.id !== payload.settingId) {
      return state;
    }
    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            gemShopSettingMapEntries: [...state.detail.gemShopSettingMapEntries, payload],
          }
        : undefined,
    };
  })
  .handleAction(gemSettingMapEntryActions.updateGemSettingMapEntry.success, (state, { payload }) => {
    if (state.detail?.id !== payload.settingId) {
      return state;
    }
    const index = state.detail?.gemShopSettingMapEntries.findIndex((mapEntry) => mapEntry.id === payload.id);
    if (index !== null && index > -1) {
      let newState: Map<keyof typeof state, any> = fromJS(state);
      newState = newState.setIn(['detail', 'gemShopSettingMapEntries', index], payload);
      return newState.toJS() as GemSettingState;
    }
    return state;
  })
  .handleAction(gemSettingMapEntryActions.deleteGemSettingMapEntry.success, (state, { payload }) => {
    const mapEntries = state.detail?.gemShopSettingMapEntries;
    if (mapEntries) {
      return {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              gemShopSettingMapEntries: mapEntries.filter((mapEntry) => `${mapEntry.id}` !== `${payload}`),
            }
          : undefined,
      };
    }
    return state;
  });

export default gemSetting;
