import { IabProduct } from 'store/iabProduct/types';
import { createListApi } from 'store/utils';

export const listWebProductApi = createListApi<IabProduct>('/iab_products/web');
export const listAppStoreIabProductApi = createListApi<IabProduct>('/iab_products/app_store');
export const listAppStoreIabSubscriptionProductApi = createListApi<IabProduct>('/iab_products/app_store_subscription');
export const listGooglePlayIabProductApi = createListApi<IabProduct>('/iab_products/google_play');
export const listGooglePlayIabSubscriptionProductApi = createListApi<IabProduct>(
  '/iab_products/google_play_subscription'
);
