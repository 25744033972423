import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  DecoSettingScheduleActions as Actions,
  DecoSettingScheduleCreateRequestData,
  DecoSettingScheduleCreateResponseData,
  DecoSettingScheduleFormValues,
  DecoSettingScheduleUpdateRequestData,
  DecoSettingScheduleUpdateResponseData,
} from './types';

export const createDecoSettingSchedule = createAsyncAction(
  Actions.CREATE_DECO_SETTING_SCHEDULE_REQUEST,
  Actions.CREATE_DECO_SETTING_SCHEDULE_SUCCESS,
  Actions.CREATE_DECO_SETTING_SCHEDULE_FAILURE
)<
  FormRequestPayload<DecoSettingScheduleFormValues, DecoSettingScheduleCreateRequestData>,
  DecoSettingScheduleCreateResponseData,
  AxiosError
>();

export const updateDecoSettingSchedule = createAsyncAction(
  Actions.UPDATE_DECO_SETTING_SCHEDULE_REQUEST,
  Actions.UPDATE_DECO_SETTING_SCHEDULE_SUCCESS,
  Actions.UPDATE_DECO_SETTING_SCHEDULE_FAILURE
)<
  FormRequestPayload<DecoSettingScheduleFormValues, DecoSettingScheduleUpdateRequestData>,
  DecoSettingScheduleUpdateResponseData,
  AxiosError
>();

export const deleteDecoSettingSchedule = createAsyncAction(
  Actions.DELETE_DECO_SETTING_SCHEDULE_REQUEST,
  Actions.DELETE_DECO_SETTING_SCHEDULE_SUCCESS,
  Actions.DELETE_DECO_SETTING_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
