import { initialListState, ListState } from '../types';

export enum RemoteConfigActions {
  LIST_REMOTE_CONFIG_REQUEST = 'LIST_REMOTE_CONFIG#REQUEST',
  LIST_REMOTE_CONFIG_SUCCESS = 'LIST_REMOTE_CONFIG#SUCCESS',
  LIST_REMOTE_CONFIG_FAILURE = 'LIST_REMOTE_CONFIG#FAILURE',

  CREATE_REMOTE_CONFIG_REQUEST = 'CREATE_REMOTE_CONFIG#REQUEST',
  CREATE_REMOTE_CONFIG_SUCCESS = 'CREATE_REMOTE_CONFIG#SUCCESS',
  CREATE_REMOTE_CONFIG_FAILURE = 'CREATE_REMOTE_CONFIG#FAILURE',

  READ_REMOTE_CONFIG_REQUEST = 'READ_REMOTE_CONFIG#REQUEST',
  READ_REMOTE_CONFIG_SUCCESS = 'READ_REMOTE_CONFIG#SUCCESS',
  READ_REMOTE_CONFIG_FAILURE = 'READ_REMOTE_CONFIG#FAILURE',

  UPDATE_REMOTE_CONFIG_REQUEST = 'UPDATE_REMOTE_CONFIG#REQUEST',
  UPDATE_REMOTE_CONFIG_SUCCESS = 'UPDATE_REMOTE_CONFIG#SUCCESS',
  UPDATE_REMOTE_CONFIG_FAILURE = 'UPDATE_REMOTE_CONFIG#FAILURE',

  DELETE_REMOTE_CONFIG_REQUEST = 'DELETE_REMOTE_CONFIG#REQUEST',
  DELETE_REMOTE_CONFIG_SUCCESS = 'DELETE_REMOTE_CONFIG#SUCCESS',
  DELETE_REMOTE_CONFIG_FAILURE = 'DELETE_REMOTE_CONFIG#FAILURE',
}

export interface RemoteConfig {
  readonly id?: number;
  segmentCode: string;
  configKey: string;
  configValue: string;
  enabled: boolean;
  startDate: string;
  endDate: string;
  description: string;
  evalOrder: number;
}

export type RemoteConfigFormValues = Omit<RemoteConfig, 'id'>;
export type RemoteConfigCreateRequestData = Omit<RemoteConfig, 'id'>;
export type RemoteConfigCreateResponseData = RemoteConfig;

export interface RemoteConfigState {
  list: ListState<RemoteConfig>;
  detail?: RemoteConfig;
}

export const initialState: RemoteConfigState = {
  list: initialListState,
  detail: undefined,
};
