import React, { FC, useCallback, useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MuiTypography from '@material-ui/core/Typography';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useDispatch, useSelector } from 'react-redux';

import { layoutActions } from 'store/layout/slice';
import { ModalState } from 'store/layout/types';
import { RootState } from 'store/root/types';

const selector = ({ layout: { fullScreenModal } }: RootState) => fullScreenModal;

const Typography = withStyles((theme) => ({ root: { marginLeft: theme.spacing(2) } }))(MuiTypography);

const DialogContent = withStyles((theme) => ({ root: { padding: theme.spacing(4) } }))(MuiDialogContent);

const Modal: FC = () => {
  const dispatch = useDispatch();

  const { isOpen, title, children } = useSelector<RootState, ModalState>(selector);

  const handleClose = useCallback(() => {
    dispatch(layoutActions.dismissFullScreenModal());
  }, [dispatch]);

  useEffect(() => {
    window.onpopstate = () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <Dialog fullScreen open={isOpen} TransitionComponent={Slide}>
      <AppBar position='relative'>
        <Toolbar>
          <IconButton aria-label='close' edge='start' color='inherit' onClick={handleClose}>
            <CloseRoundedIcon />
          </IconButton>

          <Typography variant='h2'>{title}</Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
