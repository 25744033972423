import { TeamMessageImageSetting } from 'store/teamMessageImageSetting/types';
import { TeamMessageLinkSetting } from 'store/teamMessageLinkSetting/types';
import { initialListState, ListState } from 'store/types';

export enum TeamMessageActions {
  LIST_TEAM_MESSAGES_REQUEST = 'LIST_TEAM_MESSAGES#REQUEST',
  LIST_TEAM_MESSAGES_SUCCESS = 'LIST_TEAM_MESSAGES#SUCCESS',
  LIST_TEAM_MESSAGES_FAILURE = 'LIST_TEAM_MESSAGES#FAILURE',

  READ_TEAM_MESSAGE_REQUEST = 'READ_TEAM_MESSAGE#REQUEST',
  READ_TEAM_MESSAGE_SUCCESS = 'READ_TEAM_MESSAGE#SUCCESS',
  READ_TEAM_MESSAGE_FAILURE = 'READ_TEAM_MESSAGE#FAILURE',

  CREATE_TEAM_MESSAGE_REQUEST = 'CREATE_TEAM_MESSAGE#REQUEST',
  CREATE_TEAM_MESSAGE_SUCCESS = 'CREATE_TEAM_MESSAGE#SUCCESS',
  CREATE_TEAM_MESSAGE_FAILURE = 'CREATE_TEAM_MESSAGE#FAILURE',

  UPDATE_TEAM_MESSAGE_REQUEST = 'UPDATE_TEAM_MESSAGE#REQUEST',
  UPDATE_TEAM_MESSAGE_SUCCESS = 'UPDATE_TEAM_MESSAGE#SUCCESS',
  UPDATE_TEAM_MESSAGE_FAILURE = 'UPDATE_TEAM_MESSAGE#FAILURE',
}

export const initialState: TeamMessageState = {
  list: initialListState,
  detail: undefined,
};

export interface TeamMessageState {
  list: ListState<TeamMessageListItem>;
  detail?: TeamMessageDetail;
}

export type Content = TeamMessageContentSetting;

export interface TeamMessage {
  id: number;
  botKey: string;
  messageKey: string;
  description?: string;
  title: string;
  content: string;
  button?: string;
  dateExpires?: string;
}

export interface TeamMessageMapEntry extends TeamMessage, Partial<TeamMessageLinkSetting> {
  botMessageMasterCode: string;
  image?: TeamMessageImageSetting[];
}

export interface TeamMessageContentSetting {
  title: string;
  content: string;
  button?: string;
}

export type TeamMessageListItem = TeamMessageMapEntry;

export type TeamMessageDetail = TeamMessageMapEntry;

export interface TeamMessageValues extends TeamMessage {
  titleAsString: boolean;
  contentAsString: boolean;
  buttonAsString: boolean;
}

export type TeamMessageFormValues = Omit<TeamMessageValues, 'id'>;

export type TeamMessageCreateRequestData = TeamMessageFormValues;

export type TeamMessageCreateResponseData = TeamMessageMapEntry;

export type TeamMessageUpdateRequestData = TeamMessageFormValues;

export type TeamMessageUpdateResponseData = TeamMessageMapEntry;
