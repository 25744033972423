export enum CancelGemPurchaseActions {
  CANCEL_GEM_PURCHASE_REQUEST = 'CANCEL_GEM_PURCHASE#REQUEST',
  CANCEL_GEM_PURCHASE_SUCCESS = 'CANCEL_GEM_PURCHASE#SUCCESS',
  CANCEL_GEM_PURCHASE_FAILURE = 'CANCEL_GEM_PURCHASE#FAILURE',
}

export enum GemPurchaseType {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  PAYPAL = 'PAYPAL',
}

export interface CancelGemPurchaseRequestFormValues {
  userId: number;
  purchaseType: GemPurchaseType;
  purchaseId: string;
  reason: string;
}
