import { combineEpics } from 'redux-observable';

import {
  createShopModulePage,
  createShopModuleSchedule,
  deleteShopModulePage,
  deleteShopModuleSchedule,
  listShopModulePage,
  readShopModulePage,
  updateShopModulePage,
  updateShopModuleSchedule,
} from './actions';
import {
  createShopModuleApi,
  createShopModulePageScheduleApi,
  deleteShopModuleApi,
  deleteShopModulePageScheduleApi,
  listShopModuleApi,
  readShopModuleApi,
  updateShopModuleApi,
  updateShopModulePageScheduleApi,
} from './api';

import { createAsyncEpic, createToastEpic } from 'store/utils';

export const createShopModuleEpic = createAsyncEpic(createShopModulePage, createShopModuleApi);
export const listShopModuleEpic = createAsyncEpic(listShopModulePage, listShopModuleApi);
export const readShopModuleEpic = createAsyncEpic(readShopModulePage, readShopModuleApi);
export const updateShopModuleEpic = createAsyncEpic(updateShopModulePage, updateShopModuleApi);
export const deleteShopModuleEpic = createAsyncEpic(deleteShopModulePage, deleteShopModuleApi);

// - Schedule -
const createShopModuleScheduleEpic = createAsyncEpic(createShopModuleSchedule, createShopModulePageScheduleApi);
const editShopModuleScheduleEpic = createAsyncEpic(updateShopModuleSchedule, updateShopModulePageScheduleApi);
const deleteShopModuleScheduleEpic = createAsyncEpic(deleteShopModuleSchedule, deleteShopModulePageScheduleApi);

const updateShopModuleToastEpic = createToastEpic(updateShopModulePage.success, 'Successfully updated');

export default combineEpics(
  listShopModuleEpic,
  createShopModuleEpic,
  readShopModuleEpic,
  updateShopModuleEpic,
  deleteShopModuleEpic,
  //schedule
  createShopModuleScheduleEpic,
  editShopModuleScheduleEpic,
  deleteShopModuleScheduleEpic,
  updateShopModuleToastEpic
);
