import { combineEpics } from 'redux-observable';
import { createAsyncEpic, createDeleteBodyEpic, createPostEpic, createToastEpic } from 'store/utils';

import {
  bulkCreateShopMainSections,
  bulkDeleteShopMainSections,
  connectShopMainSection,
  createShopMainPage,
  createShopMainSchedule,
  deleteShopMainPage,
  deleteShopMainSchedule,
  disconnectShopMainSection,
  editShopMainSection,
  listShopMainPage,
  readShopMainPage,
  updateShopMainPage,
  updateShopMainSchedule,
} from './actions';
import {
  bulkCreateShopMainSectionApi,
  bulkDeleteShopMainSectionApi,
  connectShopMainSectionApi,
  createShopMainApi,
  createShopMainPageScheduleApi,
  deleteShopMainApi,
  deleteShopMainPageScheduleApi,
  disconnectShopMainSectionApi,
  editShopMainSectionApi,
  listShopMainApi,
  readShopMainApi,
  updateShopMainApi,
  updateShopMainPageScheduleApi,
} from './api';

const listShopMainEpic = createAsyncEpic(listShopMainPage, listShopMainApi);
const createShopMainEpic = createAsyncEpic(createShopMainPage, createShopMainApi);
const readShopMainEpic = createAsyncEpic(readShopMainPage, readShopMainApi);
const deleteShopMainEpic = createAsyncEpic(deleteShopMainPage, deleteShopMainApi);
const updateShopMainEpic = createAsyncEpic(updateShopMainPage, updateShopMainApi);

// - Section -
const connectShopMainSectionEpic = createAsyncEpic(connectShopMainSection, connectShopMainSectionApi);
const editShopMainSectionEpic = createAsyncEpic(editShopMainSection, editShopMainSectionApi);
const disconnectShopMainSectionEpic = createAsyncEpic(disconnectShopMainSection, disconnectShopMainSectionApi);

// - Section Bulk -
const bulkCreateShopMainSectionEpic = createPostEpic(bulkCreateShopMainSections, bulkCreateShopMainSectionApi);

const bulkCreateShopMainSectionToastEpic = createToastEpic(
  bulkCreateShopMainSections.success,
  'Successfully copy from'
);
const bulkCreateFailureShopMainSectionToastEpic = createToastEpic(
  bulkCreateShopMainSections.failure,
  'Failed to copy sections'
);

const bulkDeleteShopMainSectionEpic = createDeleteBodyEpic(bulkDeleteShopMainSections, bulkDeleteShopMainSectionApi);

// - Schedule -
const createShopMainScheduleEpic = createAsyncEpic(createShopMainSchedule, createShopMainPageScheduleApi);
const editShopMainScheduleEpic = createAsyncEpic(updateShopMainSchedule, updateShopMainPageScheduleApi);
const deleteShopMainScheduleEpic = createAsyncEpic(deleteShopMainSchedule, deleteShopMainPageScheduleApi);

export default combineEpics(
  createShopMainEpic,
  listShopMainEpic,
  readShopMainEpic,
  deleteShopMainEpic,
  updateShopMainEpic,
  //section
  connectShopMainSectionEpic,
  editShopMainSectionEpic,
  disconnectShopMainSectionEpic,
  //section bulk
  bulkCreateShopMainSectionEpic,
  bulkCreateShopMainSectionToastEpic,
  bulkDeleteShopMainSectionEpic,
  bulkCreateFailureShopMainSectionToastEpic,
  //schedule
  createShopMainScheduleEpic,
  editShopMainScheduleEpic,
  deleteShopMainScheduleEpic
);
