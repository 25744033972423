import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as matataSuspensionHistoryActions from './actions';
import { MatataSuspensionHistoryState, initialMatataSuspensionHistoryState } from './types';

const matataSuspensionHistory = createReducer<MatataSuspensionHistoryState, RootAction>(
  initialMatataSuspensionHistoryState
)
  .handleAction(
    matataSuspensionHistoryActions.listMatataSuspensionHistory.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(matataSuspensionHistoryActions.listMatataSuspensionHistory.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(matataSuspensionHistoryActions.listMatataSuspensionHistory.failure, (state) => ({
    ...state,
    list: { ...initialMatataSuspensionHistoryState.list },
  }));

export default matataSuspensionHistory;
