import React, { FC, useCallback, useRef } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { KEYCODE_ENTER } from 'constants/keycode';
import { layoutActions } from 'store/layout/slice';
import { ToastType } from 'store/layout/types';

interface EditableTextProps {
  value: string;
  onEditEnd: (value: string) => void;
}

const TOAST_PAYLOAD = {
  SUCCESS: {
    type: ToastType.info,
    message: 'Successfully saved',
  },
};

const useStyles = makeStyles((theme) => ({
  wrapper: { '&:not(:hover) $icon': { display: 'none' } },
  input: {
    '& input': {
      fontSize: '0.85rem',
      fontWeight: 400,
      lineHeight: 1.5,

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '&.view-mode input': { cursor: 'pointer' },
    '&.view-mode fieldset': { border: 'none' },
  },
  icon: {
    color: theme.palette.grey['400'],
    cursor: 'pointer',
  },
}));

const EditableText: FC<EditableTextProps> = (props) => {
  const editEl = useRef<HTMLInputElement>(null);

  const { onEditEnd, value: propsValue } = props;

  const [isModifying, setIsModifying] = React.useState(false);
  const [value, setValue] = React.useState(propsValue);

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClickModify = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (!editEl.current) return;

      setIsModifying(true);
      editEl.current.focus();
      editEl.current.setSelectionRange(0, value.length);
    },
    [value.length]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.keyCode === KEYCODE_ENTER) {
      editEl.current?.blur();
    }
  }, []);

  const handleBlur = useCallback(() => {
    setIsModifying(false);

    const isPossibleChange = value && value.trim().length > 0 && value !== propsValue;

    if (isPossibleChange) {
      onEditEnd(value);
      dispatch(layoutActions.makeToast(TOAST_PAYLOAD.SUCCESS));
    } else {
      setValue(propsValue);
    }
  }, [dispatch, onEditEnd, propsValue, value]);

  return (
    <Box className={classes.wrapper} display='flex' flexGrow={1}>
      <TextField
        inputRef={editEl}
        className={clsx(classes.input, { 'view-mode': !isModifying })}
        value={value}
        fullWidth
        size='small'
        variant='outlined'
        InputProps={{
          readOnly: !isModifying,
          endAdornment: <EditRoundedIcon className={classes.icon} onClick={handleClickModify} />,
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export default EditableText;
