import { combineEpics } from 'redux-observable';

import { createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { readProfileManage, updateProfileManage } from './actions';
import { readProfileManageApi, updateProfileManageApi } from './api';

const updateProfileManageToastEpic = createToastEpic(updateProfileManage.success, 'Successfully saved');

const readProfileManageEpic = createReadEpic(readProfileManage, readProfileManageApi);

const updateProfileManageEpic = createUpdateEpic(updateProfileManage, updateProfileManageApi);

export default combineEpics(updateProfileManageToastEpic, readProfileManageEpic, updateProfileManageEpic);
