import { ActionType, createReducer } from 'typesafe-actions';

import * as iabProductActions from 'store/iabProduct/actions';
import { IabProductState, IabProductType, initialState } from 'store/iabProduct/types';

type Action = ActionType<typeof iabProductActions>;

const iabProduct = createReducer<IabProductState, Action>(initialState)
  .handleAction(iabProductActions.listWebProduct.request, (state, { payload: { page, pageSize } }) => {
    const newState = { ...state };
    newState[IabProductType.WEB].list.page = page;
    newState[IabProductType.WEB].list.pageSize = pageSize;
    return newState;
  })
  .handleAction(iabProductActions.listAppStoreIabProduct.request, (state, { payload: { page, pageSize } }) => {
    const newState = { ...state };
    newState[IabProductType.APP_STORE].list.page = page;
    newState[IabProductType.APP_STORE].list.pageSize = pageSize;
    return newState;
  })
  .handleAction(iabProductActions.listGooglePlayIabProduct.request, (state, { payload: { page, pageSize } }) => {
    const newState = { ...state };
    newState[IabProductType.GOOGLE_PLAY].list.page = page;
    newState[IabProductType.GOOGLE_PLAY].list.pageSize = pageSize;
    return newState;
  })
  .handleAction(iabProductActions.listWebProduct.success, (state, { payload }) => {
    const newState = { ...state };
    newState[IabProductType.WEB].list = payload;
    return newState;
  })
  .handleAction(iabProductActions.listAppStoreIabProduct.success, (state, { payload }) => {
    const newState = { ...state };
    newState[IabProductType.APP_STORE].list = payload;
    return newState;
  })
  .handleAction(iabProductActions.listGooglePlayIabProduct.success, (state, { payload }) => {
    const newState = { ...state };
    newState[IabProductType.GOOGLE_PLAY].list = payload;
    return newState;
  })
  .handleAction(
    iabProductActions.listAppStoreIabSubscriptionProduct.request,
    (state, { payload: { page, pageSize } }) => {
      const newState = { ...state };
      newState[IabProductType.APP_STORE_SUBSCRIPTION].list.page = page;
      newState[IabProductType.APP_STORE_SUBSCRIPTION].list.pageSize = pageSize;
      return newState;
    }
  )
  .handleAction(
    iabProductActions.listGooglePlayIabSubscriptionProduct.request,
    (state, { payload: { page, pageSize } }) => {
      const newState = { ...state };
      newState[IabProductType.GOOGLE_PLAY_SUBSCRIPTION].list.page = page;
      newState[IabProductType.GOOGLE_PLAY_SUBSCRIPTION].list.pageSize = pageSize;
      return newState;
    }
  )
  .handleAction(iabProductActions.listAppStoreIabSubscriptionProduct.success, (state, { payload }) => {
    const newState = { ...state };
    newState[IabProductType.APP_STORE_SUBSCRIPTION].list = payload;
    return newState;
  })
  .handleAction(iabProductActions.listGooglePlayIabSubscriptionProduct.success, (state, { payload }) => {
    const newState = { ...state };
    newState[IabProductType.GOOGLE_PLAY_SUBSCRIPTION].list = payload;
    return newState;
  });

export default iabProduct;
