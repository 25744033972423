export enum SpecialPromotionSettingProductActions {
  CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT_REQUEST = 'CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT#REQUEST',
  CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT_SUCCESS = 'CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT#SUCCESS',
  CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT_FAILURE = 'CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT#FAILURE',

  UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT_REQUEST = 'UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT#REQUEST',
  UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT_SUCCESS = 'UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT#SUCCESS',
  UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT_FAILURE = 'UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT#FAILURE',

  DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT_REQUEST = 'DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT#REQUEST',
  DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT_SUCCESS = 'DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT#SUCCESS',
  DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT_FAILURE = 'DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT#FAILURE',
}

export enum SpecialPromotionSettingProductType {
  GEM = 'GEM',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

interface SpecialPromotionSettingProductBase {
  type?: SpecialPromotionSettingProductType;
  id?: number;
  settingId: number;
  displayName: string;
  icon: number | string;
  badge?: string;
  rowOrder?: number;
  columnOrder?: number;
}

export interface SpecialPromotionSettingGemProduct extends SpecialPromotionSettingProductBase {
  googlePlayIabProductId: string;
  appStoreIabProductId: string;
  baseGemIncrement: number;
  promoGemIncrement: number;
  bonusGemIncrement: number;
  pgBonusPercent?: number;
  limitKey?: -1 | number;
  maxPurchaseCount?: number;
  fallbackProductId?: number;
}

export interface SpecialPromotionSettingSubscriptionProduct extends SpecialPromotionSettingProductBase {
  googlePlayIabProductId: string;
  appStoreIabProductId: string;
  deepLink: string;
}

export type SpecialPromotionSettingProduct =
  | SpecialPromotionSettingGemProduct
  | SpecialPromotionSettingSubscriptionProduct;

export type SpecialPromotionSettingProductCreateRequestData = Omit<SpecialPromotionSettingProduct, 'id'>;
export type SpecialPromotionSettingProductCreateSuccessData = SpecialPromotionSettingProduct;
export type SpecialPromotionSettingProductUpdateRequestData = Omit<SpecialPromotionSettingProduct, 'id'>;
export type SpecialPromotionSettingProductUpdateSuccessData = SpecialPromotionSettingProduct;

export enum BadgeType {
  BEST = 'BEST',
  HOT = 'HOT',
  BONUS = 'BONUS',
  SPECIAL = 'SPECIAL',
  LIMITED = 'LIMITED',
  ONE_TIME = 'ONE TIME',
  SALE = 'SALE',
}
