import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteBodyRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  NoticeActions as Actions,
  NoticeCreateRequestData,
  NoticeCreateRequestFormValues,
  NoticeCreateSuccessData,
  NoticeDetail,
  NoticeListItem,
  NoticePopupButtonsBulkDeleteSuccessData,
  NoticePopupButtonsBulkInsertRequestData,
  NoticePopupButtonsBulkInsertSuccessData,
  NoticePopupCarouselsBulkDeleteSuccessData,
  NoticePopupCarouselsBulkInsertRequestData,
  NoticePopupCarouselsBulkInsertSuccessData,
  NoticeUpdateRequestData,
  NoticeUpdateRequestFormValues,
  NoticeUpdateSuccessData,
} from './types';

export const listNotice = createAsyncAction(
  Actions.LIST_NOTICE_REQUEST,
  Actions.LIST_NOTICE_SUCCESS,
  Actions.LIST_NOTICE_FAILURE
)<ListRequestPayload, ListState<NoticeListItem>, AxiosError>();

export const createNotice = createAsyncAction(
  Actions.CREATE_NOTICE_REQUEST,
  Actions.CREATE_NOTICE_SUCCESS,
  Actions.CREATE_NOTICE_FAILURE
)<FormRequestPayload<NoticeCreateRequestFormValues, NoticeCreateRequestData>, NoticeCreateSuccessData, AxiosError>();

export const readNotice = createAsyncAction(
  Actions.READ_NOTICE_REQUEST,
  Actions.READ_NOTICE_SUCCESS,
  Actions.READ_NOTICE_FAILURE
)<ReadRequestPayload, NoticeDetail, AxiosError>();

export const updateNotice = createAsyncAction(
  Actions.UPDATE_NOTICE_REQUEST,
  Actions.UPDATE_NOTICE_SUCCESS,
  Actions.UPDATE_NOTICE_FAILURE
)<FormRequestPayload<NoticeUpdateRequestFormValues, NoticeUpdateRequestData>, NoticeUpdateSuccessData, AxiosError>();

export const refreshNotice = createAsyncAction(
  Actions.REFRESH_NOTICE_REQUEST,
  Actions.REFRESH_NOTICE_SUCCESS,
  Actions.REFRESH_NOTICE_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();

export const bulkInsertNoticeButtons = createAsyncAction(
  Actions.BULK_INSERT_NOTICE_BUTTONS_REQUEST,
  Actions.BULK_INSERT_NOTICE_BUTTONS_SUCCESS,
  Actions.BULK_INSERT_NOTICE_BUTTONS_FAILURE
)<FormRequestPayload<NoticePopupButtonsBulkInsertRequestData>, NoticePopupButtonsBulkInsertSuccessData, AxiosError>();

export const bulkInsertNoticeCarousels = createAsyncAction(
  Actions.BULK_INSERT_NOTICE_CAROUSELS_REQUEST,
  Actions.BULK_INSERT_NOTICE_CAROUSELS_SUCCESS,
  Actions.BULK_INSERT_NOTICE_CAROUSELS_FAILURE
)<
  FormRequestPayload<NoticePopupCarouselsBulkInsertRequestData>,
  NoticePopupCarouselsBulkInsertSuccessData,
  AxiosError
>();

export const bulkDeleteNoticeButtons = createAsyncAction(
  Actions.BULK_DELETE_NOTICE_BUTTONS_REQUEST,
  Actions.BULK_DELETE_NOTICE_BUTTONS_SUCCESS,
  Actions.BULK_DELETE_NOTICE_BUTTONS_FAILURE
)<DeleteBodyRequestPayload, NoticePopupButtonsBulkDeleteSuccessData, AxiosError>();

export const bulkDeleteNoticeCarousels = createAsyncAction(
  Actions.BULK_DELETE_NOTICE_CAROUSELS_REQUEST,
  Actions.BULK_DELETE_NOTICE_CAROUSELS_SUCCESS,
  Actions.BULK_DELETE_NOTICE_CAROUSELS_FAILURE
)<DeleteBodyRequestPayload, NoticePopupCarouselsBulkDeleteSuccessData, AxiosError>();
