import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from '../utils';

import {
  createRemoteConfig,
  deleteRemoteConfig,
  listRemoteConfig,
  readRemoteConfig,
  updateRemoteConfig,
} from './actions';
import {
  createRemoteConfigApi,
  deleteRemoteConfigApi,
  listRemoteConfigApi,
  readRemoteConfigApi,
  updateRemoteConfigApi,
} from './api';

const createRemoteConfigEpic = createPostEpic(createRemoteConfig, createRemoteConfigApi);
const readRemoteConfigEpic = createReadEpic(readRemoteConfig, readRemoteConfigApi);
const updateRemoteConfigEpic = createUpdateEpic(updateRemoteConfig, updateRemoteConfigApi);
const listRemoteConfigEpic = createListEpic(listRemoteConfig, listRemoteConfigApi);
const deleteRemoteConfigEpic = createDeleteEpic(deleteRemoteConfig, deleteRemoteConfigApi);

const updateRemoteConfigToastEpic = createToastEpic(updateRemoteConfig.success, 'Successfully saved');

const createRemoteConfigToastEpic = createToastEpic(createRemoteConfig.success, 'Successfully created');

export default combineEpics(
  updateRemoteConfigToastEpic,
  createRemoteConfigToastEpic,
  createRemoteConfigEpic,
  readRemoteConfigEpic,
  updateRemoteConfigEpic,
  listRemoteConfigEpic,
  deleteRemoteConfigEpic
);
