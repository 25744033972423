import { createFormApi, RequestOption } from 'store/utils';

import {
  PromotionPublishCouponRequestData,
  PromotionPublishGroupCouponRequestData,
  PromotionPublishGroupSegmentCouponRequestData,
  PromotionPublishReusableCouponRequestData,
} from './types';

const requestOption: RequestOption = { method: 'post' };

export const createReusableCouponApi = createFormApi<PromotionPublishReusableCouponRequestData, undefined>(
  '/promotion/codes/publish/types/reusable-coupon',
  requestOption
);

export const createGroupCouponApi = createFormApi<PromotionPublishGroupCouponRequestData, undefined>(
  '/promotion/codes/publish/types/group-coupon',
  requestOption
);

export const createGroupSegmentCouponApi = createFormApi<PromotionPublishGroupSegmentCouponRequestData, undefined>(
  '/promotion/codes/publish/types/group-segment-coupon',
  requestOption
);

export const createCouponApi = createFormApi<PromotionPublishCouponRequestData, undefined>(
  '/promotion/codes/publish/types/coupon',
  requestOption
);
