import { combineEpics } from 'redux-observable';
import { createAsyncEpic } from 'store/utils';
import {
  createLimitedEdition,
  deleteLimitedEdition,
  listLimitedEdition,
  readLimitedEdition,
  updateLimitedEdition,
} from './actions';
import {
  createLimitedEditionApi,
  deleteLimitedEditionApi,
  listLimitedEditionApi,
  readLimitedEditionApi,
  updateLimitedEditionApi,
} from './api';

export const createLimitedEditionEpic = createAsyncEpic(createLimitedEdition, createLimitedEditionApi);
export const listLimitedEditionEpic = createAsyncEpic(listLimitedEdition, listLimitedEditionApi);
export const readLimitedEditionEpic = createAsyncEpic(readLimitedEdition, readLimitedEditionApi);
export const deleteLimitedEditionEpic = createAsyncEpic(deleteLimitedEdition, deleteLimitedEditionApi);
export const updateLimitedEditionEpic = createAsyncEpic(updateLimitedEdition, updateLimitedEditionApi);

export default combineEpics(
  createLimitedEditionEpic,
  listLimitedEditionEpic,
  readLimitedEditionEpic,
  deleteLimitedEditionEpic,
  updateLimitedEditionEpic
);
