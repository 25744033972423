import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  ShopEntryBannerActions as Actions,
  ShopEntryBannerCreateRequestData,
  ShopEntryBannerCreateRequestFormValues,
  ShopEntryBannerCreateSuccessData,
  ShopEntryBannerDetail,
  ShopEntryBannerListItem,
  ShopEntryBannerUpdateRequestData,
  ShopEntryBannerUpdateRequestFormValues,
  ShopEntryBannerUpdateSuccessData,
} from './types';

export const listShopEntryBanner = createAsyncAction(
  Actions.LIST_SHOP_ENTRY_BANNER_REQUEST,
  Actions.LIST_SHOP_ENTRY_BANNER_SUCCESS,
  Actions.LIST_SHOP_ENTRY_BANNER_FAILURE
)<ListRequestPayload, ListState<ShopEntryBannerListItem>, AxiosError>();

export const createShopEntryBanner = createAsyncAction(
  Actions.CREATE_SHOP_ENTRY_BANNER_REQUEST,
  Actions.CREATE_SHOP_ENTRY_BANNER_SUCCESS,
  Actions.CREATE_SHOP_ENTRY_BANNER_FAILURE
)<
  FormRequestPayload<ShopEntryBannerCreateRequestFormValues, ShopEntryBannerCreateRequestData>,
  ShopEntryBannerCreateSuccessData,
  AxiosError
>();

export const readShopEntryBanner = createAsyncAction(
  Actions.READ_SHOP_ENTRY_BANNER_REQUEST,
  Actions.READ_SHOP_ENTRY_BANNER_SUCCESS,
  Actions.READ_SHOP_ENTRY_BANNER_FAILURE
)<ReadRequestPayload, ShopEntryBannerDetail, AxiosError>();

export const updateShopEntryBanner = createAsyncAction(
  Actions.UPDATE_SHOP_ENTRY_BANNER_REQUEST,
  Actions.UPDATE_SHOP_ENTRY_BANNER_SUCCESS,
  Actions.UPDATE_SHOP_ENTRY_BANNER_FAILURE
)<
  FormRequestPayload<ShopEntryBannerUpdateRequestFormValues, ShopEntryBannerUpdateRequestData>,
  ShopEntryBannerUpdateSuccessData,
  AxiosError
>();

export const refreshShopEntryBanner = createAsyncAction(
  Actions.REFRESH_SHOP_ENTRY_BANNER_REQUEST,
  Actions.REFRESH_SHOP_ENTRY_BANNER_SUCCESS,
  Actions.REFRESH_SHOP_ENTRY_BANNER_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
