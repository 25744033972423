import { combineEpics } from 'redux-observable';

import { createPostEpic, createReadEpic, createToastEpic } from 'store/utils';

import { identifyUserByUserID, identifyUserByUsername, punishByUserId } from './actions';
import { identifyUserByUserIDApi, identifyUserByUsernameApi, punishByUserIdApi } from './api';

/**
 * identify
 */

const identifyUserByUsernameEpic = createReadEpic(identifyUserByUsername, identifyUserByUsernameApi);

const identifyUserByUserIdEpic = createReadEpic(identifyUserByUserID, identifyUserByUserIDApi);

/**
 * punish
 */

const punishByUserIdEpic = createPostEpic(punishByUserId, punishByUserIdApi);

const userPunishSuccessEpic = createToastEpic(punishByUserId.success, 'Successfully punished');

export default combineEpics(
  identifyUserByUsernameEpic,
  identifyUserByUserIdEpic,
  punishByUserIdEpic,
  userPunishSuccessEpic
);
