import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ProductActions as Actions, GeneralProduct, ItemProduct } from 'store/product/types';
import { ListRequestPayload, ListState } from 'store/types';

export const listItemProduct = createAsyncAction(
  Actions.LIST_ITEM_PRODUCT_REQUEST,
  Actions.LIST_ITEM_PRODUCT_SUCCESS,
  Actions.LIST_ITEM_PRODUCT_FAILURE
)<ListRequestPayload, ListState<ItemProduct>, AxiosError>();

export const listGeneralProduct = createAsyncAction(
  Actions.LIST_GENERAL_PRODUCT_REQUEST,
  Actions.LIST_GENERAL_PRODUCT_SUCCESS,
  Actions.LIST_GENERAL_PRODUCT_FAILURE
)<ListRequestPayload, ListState<GeneralProduct>, AxiosError>();
