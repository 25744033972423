import { combineReducers } from 'redux';

import iabProduct from 'store/iabProduct/reducer';
import product from 'store/product/reducer';
import productIcon from 'store/productIcons/reducer';
import userSegment from 'store/userSegment/reducer';

import agentHistory from './agentHistory/reducer';
import agentManage from './agentManage/reducer';
import bannerSetting from './bannerSetting/reducer';
import bookmark from './bookmark/reducer';
import convertUserKey from './convertUserKey/reducer';
import decoColorPreset from './decoColorPreset/reducer';
import decoItem from './decoItem/reducer';
import decoSetting from './decoSetting/reducer';
import downloadableContent from './downloadableContent/reducer';
import expressionSegment from './expressionSegment/reducer';
import fileS3Upload from './fileS3Upload/reducer';
import fileUpload from './fileUpload/reducer';
import fileSetting from './fileSetting/reducer';
import simulationUpload from './simulationUpload/reducer';
import gemSetting from './gemSetting/reducer';
import gemSettingLimitKey from './gemSettingLimitKey/reducer';
import imageSetting from './imageSetting/reducer';
import isFetching from './isFetching/reducer';
import itemCategory from './itemCategory/reducer';
import itemProduct from './itemProduct/reducer';
import layout from './layout/slice';
import localizationKey from './localizationKey/reducer';
import manualSegment from './manualSegment/reducer';
import matchFilter from './matchFilter/reducer';
import matchFilterOption from './matchFilterOption/reducers';
import matchFilterOptionListing from './matchFilterOptionListing/reducer';
import matchFilterSegment from './matchFilterSegment/reducer';
import matchFilterSetting from './matchFilterSetting/reducer';
import matchTag from './matchTagManage/reducer';
import moniNotice from './moniNotice/reducer';
import notice from './notice/reducer';
import profileManage from './profileManage/reducer';
import promotionContent from './promotionContent/reducer';
import promotionSetting from './promotionSetting/reducer';
import punish from './punish/reducer';
import punishment from './punishment/reducer';
import matataSuspensionHistory from './matataSuspensionHistory/reducer';
import matataDeviceUnbinding from './matataDeviceUnbinding/reducer';
import realtimeMonitoring from './realtimeMonitoring/reducer';
import setting from './setting/reducer';
import specialPromotionSetting from './specialPromotionSetting/reducer';
import specialPromotionSettingLimitKey from './specialPromotionSettingLimitKey/reducer';
import stringLocalization from './stringLocalization/reducer';
import teamMatchCampaign from './teamMatchCampaign/reducer';
import teamMatchProfile from './teamMatchProfile/reducer';
import teamMessage from './teamMessage/reducer';
import uploadFileList from './uploadFileList/reducer';
import user from './user/reducer';
import userItemInventory from './userItemInventory/reducer';
import waterSlideBanner from './waterSlideBanner/reducer';
import shopMain from './shopMainPage/reducer';
import shopDetail from './shopDetailPage/reducer';
import shopSection from './shopSectionPage/reducer';
import shopModule from './shopModulePage/reducer';
import shopTab from './shopTabPage/reducer';
import shopModuleGroup from './shopModuleGroup/reducer';
import limitedEdition from './limitedEditionPage/reducer';
import shopEntryBanner from './shopEntryBanner/reducer';
import promotionPublishHistory from './promotionPublishHistory/reducer';
import remoteConfig from './remoteConfig/reducer';
import gemProductTier from './gemProductTier/reducer';
import abTest from './abTest/reducer';
import criteriaSegment from './criteriaSegment/reducer';
import notificationCenterBanner from './notificationCenterBanner/reducer';
import notificationContent from './notificationContent/reducer';
import pushContent from './pushContent/reducer';
import configStore from './configStore/reducer';
import invalidatedPurchase from './invalidatedPurchase/reducer';
import visitorConfig from './visitorConfig/reducer';
import visitorAbTest from './visitorAbTest/reducer';
import visitorSegment from './visitorSegment/reducer';
import matataInvalidatedPurchase from './matataInvalidatedPurchase/reducer';
import matataInvalidatedPurchaseBySender from './matataInvalidatedPurchaseBySender/reducer';
import matataInvalidatedPurchaseByReceiver from './matataInvalidatedPurchaseByReceiver/reducer';
import promotionSchedule from './promotionSchedule/reducer';

import bigquerySegment from 'store/bigquerySegment/reducer';

import webDecoEffect from './webDecoEffect/reducer';
import webDecoSetting from './webDecoSetting/reducer';
import appStoreReview from './appStoreReview/reducer';
import purpose from './purpose/reducer';
import mlConfigModel from './mlConfigModel/reducer';
import mlConfigFeature from './mlConfigFeature/reducer';
import promotionContentGroup from './promotionContentGroup/reducer';

const rootReducer = combineReducers({
  layout,
  user,
  isFetching,
  gemSetting,
  gemSettingLimitKey,
  specialPromotionSetting,
  specialPromotionSettingLimitKey,
  productIcon,
  iabProduct,
  product,
  userSegment,
  bannerSetting,
  profileManage,
  realtimeMonitoring,
  expressionSegment,
  imageSetting,
  fileSetting,
  fileUpload,
  simulationUpload,
  localizationKey,
  convertUserKey,
  stringLocalization,
  setting,
  notice,
  bookmark,
  manualSegment,
  userItemInventory,
  moniNotice,
  matchFilter,
  matchFilterOptionListing,
  matchFilterOption,
  matchFilterSegment,
  matchFilterSetting,
  downloadableContent,
  itemProduct,
  itemCategory,
  decoItem,
  decoSetting,
  teamMatchCampaign,
  teamMatchProfile,
  fileS3Upload,
  promotionSetting,
  promotionContent,
  teamMessage,
  agentManage,
  agentHistory,
  punish,
  punishment,
  matataSuspensionHistory,
  matataDeviceUnbinding,
  matchTag,
  decoColorPreset,
  uploadFileList,
  waterSlideBanner,
  shopMain,
  shopDetail,
  shopSection,
  shopModule,
  shopTab,
  shopModuleGroup,
  limitedEdition,
  shopEntryBanner,
  promotionPublishHistory,
  remoteConfig,
  gemProductTier,
  abTest,
  criteriaSegment,
  notificationCenterBanner,
  notificationContent,
  pushContent,
  configStore,
  invalidatedPurchase,
  visitorConfig,
  visitorAbTest,
  visitorSegment,
  matataInvalidatedPurchase,
  matataInvalidatedPurchaseBySender,
  matataInvalidatedPurchaseByReceiver,
  promotionSchedule,
  bigquerySegment,
  webDecoEffect,
  webDecoSetting,
  appStoreReview,
  purpose,
  mlConfigModel,
  mlConfigFeature,
  promotionContentGroup,
});

export default rootReducer;
