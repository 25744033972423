import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as punishAction from './actions';
import { initialState, PunishState } from './types';

const punish = createReducer<PunishState, RootAction>(initialState)
  .handleAction(punishAction.resetIdentifyUser, (state) => ({
    ...state,
    identifiedUserInfo: undefined,
  }))
  .handleAction(
    [punishAction.identifyUserByUserID.success, punishAction.identifyUserByUsername.success],
    (state, { payload }) => ({
      ...state,
      identifiedUserInfo: payload,
    })
  );

export default punish;
