import { createDeleteApi, createDeleteBodyApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  DecoSettingProductBulkCreateRequestData,
  DecoSettingProductCreateRequestData,
  DecoSettingProductCreateResponseData,
  DecoSettingProductFormValues,
  DecoSettingProductUpdateRequestData,
  DecoSettingProductUpdateResponseData,
} from './types';

export const createDecoSettingProductApi = createPostApi<
  DecoSettingProductFormValues,
  DecoSettingProductCreateRequestData,
  DecoSettingProductCreateResponseData
>('/deco_settings/products');

export const updateDecoSettingProductApi = createUpdateApi<
  DecoSettingProductFormValues,
  DecoSettingProductUpdateRequestData,
  DecoSettingProductUpdateResponseData
>('/deco_settings/products/:id');

export const deleteDecoSettingProductApi = createDeleteApi('/deco_settings/products/:id');

export const bulkCreateDecoSettingProductApi =
  createPostApi<DecoSettingProductBulkCreateRequestData>('/deco_settings/products/bulk');

export const bulkDeleteDecoSettingProductApi = createDeleteBodyApi('/deco_settings/products/bulk');
