import { combineEpics } from 'redux-observable';

import { createFormEpic, createToastEpic } from 'store/utils';

import { deleteBlockPhoneManage, deleteBlockPhoneManageByUserIds } from './actions';
import { deleteBlockPhoneManageApi, deleteBlockPhoneManageByUserIdsApi } from './api';

const deleteBlockPhoneManageToastEpic = createToastEpic(deleteBlockPhoneManage.success, 'Successfully deleted');

const deleteBlockPhoneManageByUserIdsToastEpic = createToastEpic(
  deleteBlockPhoneManageByUserIds.success,
  'Successfully deleted'
);

const deleteBlockPhoneManageEpic = createFormEpic(deleteBlockPhoneManage, deleteBlockPhoneManageApi);

const deleteBlockPhoneManageByUserIdsEpic = createFormEpic(
  deleteBlockPhoneManageByUserIds,
  deleteBlockPhoneManageByUserIdsApi
);

export default combineEpics(
  deleteBlockPhoneManageToastEpic,
  deleteBlockPhoneManageByUserIdsToastEpic,
  deleteBlockPhoneManageEpic,
  deleteBlockPhoneManageByUserIdsEpic
);
