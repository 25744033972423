import { API_HOST, DEV3_API_HOST, ENVIRONMENT } from 'constants/env';

export enum SettingActions {
  SET_PALETTE = 'SET_PALETTE',
  SET_PRESETS = 'SET_PRESETS',
  SET_CURRENT_PRESET = 'SET_CURRENT_PRESET',
  SET_SETTING = 'SET_SETTING',
}

export enum Palette {
  DEVICE = 'device',
  LIGHT = 'light',
  DARK = 'dark',
}

export interface SettingState {
  palette: Palette;
  hasBackground: boolean;
  currentPresetIndex: number;
  presets: Array<{
    azarApiUrl?: string;
    matchingApiUrl?: string;
    environment: string;
  }>;
}

export const initialPreset = {
  environment: ENVIRONMENT,
};

export const defaultPreset = {
  azarApiUrl: API_HOST,
  matchingApiUrl: DEV3_API_HOST,
  environment: ENVIRONMENT,
};

export const initialState: SettingState = {
  palette: Palette.DEVICE,
  hasBackground: process.env.REACT_APP_ENVIRONMENT !== 'Production',
  currentPresetIndex: 0,
  presets: [defaultPreset, initialPreset, initialPreset],
};

export type SetPalettePayload = Pick<SettingState, 'palette'>;
export type SetPresetsPayload = Pick<SettingState, 'presets'>;
export type SetCurrentPresetIndexPayload = Pick<SettingState, 'currentPresetIndex'>;
export type SetSettingPayload = Omit<SettingState, 'palette'>;
