import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as waterSlideBannerActions from './actions';
import { initialState, WaterSlideBannerState } from './types';

const waterSlideBanner = createReducer<WaterSlideBannerState, RootAction>(initialState)
  .handleAction(waterSlideBannerActions.readWaterSlideBanner.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(waterSlideBannerActions.updateWaterSlideBanner.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(waterSlideBannerActions.listWaterSlideBanner.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(waterSlideBannerActions.listWaterSlideBanner.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default waterSlideBanner;
