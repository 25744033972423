import { initialListState, ListState } from '../types';

export enum NotificationContentActions {
  LIST_NOTIFICATION_CONTENT_REQUEST = 'LIST_NOTIFICATION_CONTENT#REQUEST',
  LIST_NOTIFICATION_CONTENT_SUCCESS = 'LIST_NOTIFICATION_CONTENT#SUCCESS',
  LIST_NOTIFICATION_CONTENT_FAILURE = 'LIST_NOTIFICATION_CONTENT#FAILURE',

  CREATE_NOTIFICATION_CONTENT_REQUEST = 'CREATE_NOTIFICATION_CONTENT#REQUEST',
  CREATE_NOTIFICATION_CONTENT_SUCCESS = 'CREATE_NOTIFICATION_CONTENT#SUCCESS',
  CREATE_NOTIFICATION_CONTENT_FAILURE = 'CREATE_NOTIFICATION_CONTENT#FAILURE',

  READ_NOTIFICATION_CONTENT_REQUEST = 'READ_NOTIFICATION_CONTENT#REQUEST',
  READ_NOTIFICATION_CONTENT_SUCCESS = 'READ_NOTIFICATION_CONTENT#SUCCESS',
  READ_NOTIFICATION_CONTENT_FAILURE = 'READ_NOTIFICATION_CONTENT#FAILURE',

  UPDATE_NOTIFICATION_CONTENT_REQUEST = 'UPDATE_NOTIFICATION_CONTENT#REQUEST',
  UPDATE_NOTIFICATION_CONTENT_SUCCESS = 'UPDATE_NOTIFICATION_CONTENT#SUCCESS',
  UPDATE_NOTIFICATION_CONTENT_FAILURE = 'UPDATE_NOTIFICATION_CONTENT#FAILURE',

  REFRESH_NOTIFICATION_CONTENT_REQUEST = 'REFRESH_NOTIFICATION_CONTENT#REQUEST',
  REFRESH_NOTIFICATION_CONTENT_SUCCESS = 'REFRESH_NOTIFICATION_CONTENT#SUCCESS',
  REFRESH_NOTIFICATION_CONTENT_FAILURE = 'REFRESH_NOTIFICATION_CONTENT#FAILURE',
}

export interface NotificationContent {
  notificationId: string;
  description: string;
  titleKey: string;
  messageKey: string;
  buttonTextKey: string;
  deeplink: string;
  imageUrls: string[];
  filterId: string;
  badgeKey: string;
  badgeColor: string;
  badgeBackgroundColor: string;
  buttonIconUrl: string;
  attachedImageUrls: string[];
  contentVersion: string;
  expireTime: string;
  retentionSeconds: number;
}

export interface NotificationContentFormValues extends NotificationContent {
  titleKeyAsString: boolean;
  messageKeyAsString: boolean;
  buttonTextKeyAsString: boolean;
  badgeKeyAsString: boolean;
}

export type NotificationContentCreateRequestData = NotificationContent;
export type NotificationContentCreateResponseData = NotificationContent;

export interface NotificationContentState {
  list: ListState<NotificationContent>;
  detail?: NotificationContent;
}

export const initialState: NotificationContentState = {
  list: initialListState,
  detail: undefined,
};

export enum NotificationFilter {
  NOTIFICATIONS = 'Notifications',
  EVENTS = 'Events',
  UPDATES = 'Updates',
}

export enum NotificationContentVersion {
  V1 = 'V1',
  V2 = 'V2',
}
