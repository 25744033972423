import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  PurposeOptionCreateRequestData,
  PurposeOptionCreateResponseData,
  PurposeOptionFormValues,
  PurposeOptionUpdateRequestData,
  PurposeOptionUpdateResponseData,
  PURPOSE_OPTION_API_PREFIX,
} from './types';

export const createPurposeOptionApi = createPostApi<
  PurposeOptionFormValues,
  PurposeOptionCreateRequestData,
  PurposeOptionCreateResponseData
>(PURPOSE_OPTION_API_PREFIX);

export const updatePurposeOptionApi = createUpdateApi<
  PurposeOptionFormValues,
  PurposeOptionUpdateRequestData,
  PurposeOptionUpdateResponseData
>(`${PURPOSE_OPTION_API_PREFIX}/:id`);

export const deletePurposeOptionApi = createDeleteApi(`${PURPOSE_OPTION_API_PREFIX}/:id`);
