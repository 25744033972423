import axios from 'axios';

import { API_HOST, DEV3_API_HOST } from 'constants/env';
import { isModerationRealm } from 'utils/okta';

export const azarApiRequest = axios.create();

export const matchingApiRequest = axios.create();

export const setBaseURL = (urls: { azarApi?: string; matchingApi?: string } = {}) => {
  if (isModerationRealm) {
    azarApiRequest.defaults.baseURL = API_HOST;
    matchingApiRequest.defaults.baseURL = API_HOST;
    return;
  }

  const { azarApi, matchingApi } = urls;
  azarApiRequest.defaults.baseURL = azarApi || API_HOST;
  matchingApiRequest.defaults.baseURL = matchingApi || DEV3_API_HOST;
};

export const setToken = (token: string) => {
  if (token) {
    azarApiRequest.defaults.headers.common = { Authorization: `Bearer ${token}` };
    matchingApiRequest.defaults.headers.common = { Authorization: `Bearer ${token}` };
  } else {
    azarApiRequest.defaults.headers.common = {};
    matchingApiRequest.defaults.headers.common = {};
  }
};
