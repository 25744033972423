import { initialListState, ListState } from 'store/types';

export enum VisitorSegmentActions {
  LIST_VISITOR_SEGMENT_REQUEST = 'LIST_VISITOR_SEGMENT#REQUEST',
  LIST_VISITOR_SEGMENT_SUCCESS = 'LIST_VISITOR_SEGMENT#SUCCESS',
  LIST_VISITOR_SEGMENT_FAILURE = 'LIST_VISITOR_SEGMENT#FAILURE',

  LIST_VISITOR_SEGMENT_SEARCH_REQUEST = 'LIST_VISITOR_SEGMENT_SEARCH#REQUEST',
  LIST_VISITOR_SEGMENT_SEARCH_SUCCESS = 'LIST_VISITOR_SEGMENT_SEARCH#SUCCESS',
  LIST_VISITOR_SEGMENT_SEARCH_FAILURE = 'LIST_VISITOR_SEGMENT_SEARCH_#FAILURE',

  LIST_ALL_VISITOR_SEGMENT_SEARCH_REQUEST = 'LIST_ALL_VISITOR_SEGMENT_SEARCH#REQUEST',
  LIST_ALL_VISITOR_SEGMENT_SEARCH_SUCCESS = 'LIST_ALL_VISITOR_SEGMENT_SEARCH#SUCCESS',
  LIST_ALL_VISITOR_SEGMENT_SEARCH_FAILURE = 'LIST_ALL_VISITOR_SEGMENT_SEARCH_#FAILURE',

  CREATE_VISITOR_SEGMENT_REQUEST = 'CREATE_VISITOR_SEGMENT#REQUEST',
  CREATE_VISITOR_SEGMENT_SUCCESS = 'CREATE_VISITOR_SEGMENT#SUCCESS',
  CREATE_VISITOR_SEGMENT_FAILURE = 'CREATE_VISITOR_SEGMENT#FAILURE',

  READ_VISITOR_SEGMENT_REQUEST = 'READ_VISITOR_SEGMENT#REQUEST',
  READ_VISITOR_SEGMENT_SUCCESS = 'READ_VISITOR_SEGMENT#SUCCESS',
  READ_VISITOR_SEGMENT_FAILURE = 'READ_VISITOR_SEGMENT#FAILURE',

  UPDATE_VISITOR_SEGMENT_REQUEST = 'UPDATE_VISITOR_SEGMENT#REQUEST',
  UPDATE_VISITOR_SEGMENT_SUCCESS = 'UPDATE_VISITOR_SEGMENT#SUCCESS',
  UPDATE_VISITOR_SEGMENT_FAILURE = 'UPDATE_VISITOR_SEGMENT#FAILURE',

  DELETE_VISITOR_SEGMENT_REQUEST = 'DELETE_VISITOR_SEGMENT#REQUEST',
  DELETE_VISITOR_SEGMENT_SUCCESS = 'DELETE_VISITOR_SEGMENT#SUCCESS',
  DELETE_VISITOR_SEGMENT_FAILURE = 'DELETE_VISITOR_SEGMENT#FAILURE',

  REFRESH_VISITOR_SEGMENT_REQUEST = 'REFRESH_VISITOR_SEGMENT#REQUEST',
  REFRESH_VISITOR_SEGMENT_SUCCESS = 'REFRESH_VISITOR_SEGMENT#SUCCESS',
  REFRESH_VISITOR_SEGMENT_FAILURE = 'REFRESH_VISITOR_SEGMENTT#FAILURE',
}

export const initialState: VisitorSegmentState = {
  list: initialListState,
  searchList: initialListState,
  detail: undefined,
};

export type VisitorSegmentDetail = VisitorSegment;

export interface VisitorSegment {
  segmentCode: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
}

export interface NewVisitorSegment {
  segmentCode: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
}

export interface VisitorSegmentState {
  list: ListState<VisitorSegment>;
  searchList: ListState<NewVisitorSegment>;
  detail?: VisitorSegmentDetail;
}

export type VisitorSegmentCreateRequestData = VisitorSegment;

export type VisitorSegmentCreateSuccessData = VisitorSegmentDetail;

export type VisitorSegmentUpdateRequestData = VisitorSegmentCreateRequestData;

export type VisitorSegmentUpdateSuccessData = VisitorSegmentDetail;
