import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { bulkUploadDecoResource, createDecoResource, updateDecoResource } from './actions';
import { bulkUploadDecoResourceApi, createDecoResourceApi, updateDecoResourceApi } from './api';

const createDecoResourceEpic = createPostEpic(createDecoResource, createDecoResourceApi);

const createDecoResourceToastEpic = createToastEpic(createDecoResource.success, 'Successfully created');

const updateDecoResourceEpic = createUpdateEpic(updateDecoResource, updateDecoResourceApi);

const updateDecoResourceToastEpic = createToastEpic(updateDecoResource.success, 'Successfully saved');

const bulkUploadDecoResourceEpic = createPostEpic(bulkUploadDecoResource, bulkUploadDecoResourceApi);

export default combineEpics(
  createDecoResourceEpic,
  createDecoResourceToastEpic,
  updateDecoResourceEpic,
  updateDecoResourceToastEpic,
  bulkUploadDecoResourceEpic
);
