import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic } from 'store/utils';

import { createPromotionScheduleTest } from './actions';
import { createPromotionScheduleTestApi } from './api';

const createPromotionScheduleTargetEpic = createPostEpic(createPromotionScheduleTest, createPromotionScheduleTestApi);

const createPromotionScheduleTargetToastEpic = createToastEpic(
  createPromotionScheduleTest.success,
  'Successfully sended'
);

export default combineEpics(createPromotionScheduleTargetEpic, createPromotionScheduleTargetToastEpic);
