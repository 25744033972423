export enum MlConfigModelSegmentActions {
  CREATE_SEGMENT_REQUEST = 'CREATE_ML_CONFIG_MODEL_SEGMENT#REQUEST',
  CREATE_SEGMENT_SUCCESS = 'CREATE_ML_CONFIG_MODEL_SEGMENT#SUCCESS',
  CREATE_SEGMENT_FAILURE = 'CREATE_ML_CONFIG_MODEL_SEGMENT#FAILURE',

  UPDATE_SEGMENT_REQUEST = 'UPDATE_ML_CONFIG_MODEL_SEGMENT#REQUEST',
  UPDATE_SEGMENT_SUCCESS = 'UPDATE_ML_CONFIG_MODEL_SEGMENT#SUCCESS',
  UPDATE_SEGMENT_FAILURE = 'UPDATE_ML_CONFIG_MODEL_SEGMENT#FAILURE',

  DELETE_SEGMENT_REQUEST = 'DELETE_ML_CONFIG_MODEL_SEGMENT#REQUEST',
  DELETE_SEGMENT_SUCCESS = 'DELETE_ML_CONFIG_MODEL_SEGMENT#SUCCESS',
  DELETE_SEGMENT_FAILURE = 'DELETE_ML_CONFIG_MODEL_SEGMENT#FAILURE',
}

export interface MlConfigModelSegment {
  id: number;
  modelId: number;
  segmentId: string;
}

export type MlConfigModelSegmentCreateRequestData = Omit<MlConfigModelSegment, 'id'>;
export type MlConfigModelSegmentCreateSuccessData = MlConfigModelSegment;
export type MlConfigModelSegmentUpdateRequestData = Omit<MlConfigModelSegment, 'id'>;
export type MlConfigModelSegmentUpdateSuccessData = MlConfigModelSegment;
