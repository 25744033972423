import { PayloadMetaAction, Reducer } from 'typesafe-actions';

import { RootActionTypes, rootActionTypes } from 'store/root/types';

import { initialState, IsFetchingState, Middleware } from './types';

const middlewares: {
  [key: string]: Middleware;
} = {};

const createMiddleware = (type: string, bool: boolean) => (state: IsFetchingState) => ({
  ...state,
  [type]: bool,
});

rootActionTypes.forEach((action) => {
  const [type, status] = action.split('#');
  if (!status) {
    return;
  }
  if (initialState[type] !== false) {
    initialState[type] = false;
  }
  middlewares[action] = createMiddleware(type, status === 'REQUEST');
});

const isFetching: Reducer<IsFetchingState, PayloadMetaAction<RootActionTypes, any, any | undefined>> = (
  state = initialState,
  action
) => {
  const handler = middlewares[action.type];
  if (handler) {
    return handler(state);
  }
  return state;
};

export default isFetching;
