import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import {
  ShopModuleGroupRequestData,
  ShopModuleGroupResponseData,
  ShopModuleGroupFormValues,
  ShopModuleGroup,
  ShopModuleNestedGroupFormValues,
  ShopModuleNestedGroupRequestData,
} from './types';

export const listShopModuleGroupsApi = createListApi('/shop_module_groups');
export const createShopModuleGroupApi = createPostApi<
  ShopModuleGroupFormValues,
  ShopModuleGroupRequestData,
  ShopModuleGroupResponseData
>('/shop_module_groups');
export const updateShopModuleGroupApi = createUpdateApi<ShopModuleGroupRequestData, undefined>('/shop_module_groups');
export const readShopModuleGroupApi = createReadApi<ShopModuleGroup>('/shop_module_groups/:id');
export const deleteShopModuleGroupApi = createDeleteApi('/shop_module_groups/:id');

export const createShopModuleNestedGroupApi = createPostApi<
  ShopModuleNestedGroupFormValues,
  ShopModuleNestedGroupRequestData,
  ShopModuleNestedGroupRequestData
>('/shop_module_groups/subscribing_groups');

export const updateShopModuleNestedGroupApi = createUpdateApi<
  ShopModuleNestedGroupFormValues,
  ShopModuleNestedGroupRequestData,
  ShopModuleNestedGroupRequestData
>('/shop_module_groups/subscribing_groups/:id');

export const deleteShopModuleNestedGroupApi = createDeleteApi('/shop_module_groups/subscribing_groups/:id');
