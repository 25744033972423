import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteBodyRequestPayload,
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ShopMainPageActions as Actions,
  ShopMainPageRequestData,
  ShopMainPageResponseData,
  ShopMainPageFormValues,
  ShopMainDetail,
  ShopMainScheduleFormValues,
  ShopMainScheduleCreateRequestData,
  ShopMainScheduleCreateResponseData,
  ShopMainScheduleUpdateRequestData,
  ShopMainScheduleUpdateResponseData,
  ShopMainListItem,
  ShopMainSectionFormValue,
  ShopMainSectionRequestData,
  ShopMainSectionResponseData,
  ShopMainSectionBulkCreateResponseData,
  ShopMainSectionBulkCreateRequestData,
} from './types';

export const listShopMainPage = createAsyncAction(
  Actions.LIST_SHOP_MAIN_PAGE_REQUEST,
  Actions.LIST_SHOP_MAIN_PAGE_SUCCESS,
  Actions.LIST_SHOP_MAIN_PAGE_FAILURE
)<ListRequestPayload, ListState<ShopMainListItem>, AxiosError>();

export const createShopMainPage = createAsyncAction(
  Actions.CREATE_SHOP_MAIN_PAGE_REQUEST,
  Actions.CREATE_SHOP_MAIN_PAGE_SUCCESS,
  Actions.CREATE_SHOP_MAIN_PAGE_FAILURE
)<FormRequestPayload<ShopMainPageFormValues, ShopMainPageRequestData>, ShopMainPageResponseData, AxiosError>();

export const readShopMainPage = createAsyncAction(
  Actions.READ_SHOP_MAIN_PAGE_REQUEST,
  Actions.READ_SHOP_MAIN_PAGE_SUCCESS,
  Actions.READ_SHOP_MAIN_PAGE_FAILURE
)<ReadRequestPayload, ShopMainDetail, AxiosError>();

export const updateShopMainPage = createAsyncAction(
  Actions.UPDATE_SHOP_MAIN_PAGE_REQUEST,
  Actions.UPDATE_SHOP_MAIN_PAGE_SUCCESS,
  Actions.UPDATE_SHOP_MAIN_PAGE_FAILURE
)<FormRequestPayload<ShopMainPageFormValues, ShopMainPageRequestData>, ShopMainPageResponseData, AxiosError>();

export const deleteShopMainPage = createAsyncAction(
  Actions.DELETE_SHOP_MAIN_PAGE_REQUEST,
  Actions.DELETE_SHOP_MAIN_PAGE_SUCCESS,
  Actions.DELETE_SHOP_MAIN_PAGE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - SECTION -
export const connectShopMainSection = createAsyncAction(
  Actions.CONNECT_SHOP_MAIN_SECTION_REQUEST,
  Actions.CONNECT_SHOP_MAIN_SECTION_SUCCESS,
  Actions.CONNECT_SHOP_MAIN_SECTION_FAILURE
)<FormRequestPayload<ShopMainSectionFormValue, ShopMainSectionRequestData>, ShopMainSectionResponseData, AxiosError>();

export const editShopMainSection = createAsyncAction(
  Actions.EDIT_SHOP_MAIN_SECTION_REQUEST,
  Actions.EDIT_SHOP_MAIN_SECTION_SUCCESS,
  Actions.EDIT_SHOP_MAIN_SECTION_FAILURE
)<FormRequestPayload<ShopMainSectionFormValue, ShopMainSectionRequestData>, ShopMainSectionResponseData, AxiosError>();

export const disconnectShopMainSection = createAsyncAction(
  Actions.DISCONNECT_SHOP_MAIN_SECTION_REQUEST,
  Actions.DISCONNECT_SHOP_MAIN_SECTION_SUCCESS,
  Actions.DISCONNECT_SHOP_MAIN_SECTION_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - SECTION BULK -
export const bulkCreateShopMainSections = createAsyncAction(
  Actions.BULK_CREATE_SHOP_MAIN_SECTION_REQUEST,
  Actions.BULK_CREATE_SHOP_MAIN_SECTION_SUCCESS,
  Actions.BULK_CREATE_SHOP_MAIN_SECTION_FAILURE
)<FormRequestPayload<ShopMainSectionBulkCreateRequestData>, ShopMainSectionBulkCreateResponseData, AxiosError>();

export const bulkDeleteShopMainSections = createAsyncAction(
  Actions.BULK_DELETE_SHOP_MAIN_SECTION_REQUEST,
  Actions.BULK_DELETE_SHOP_MAIN_SECTION_SUCCESS,
  Actions.BULK_DELETE_SHOP_MAIN_SECTION_FAILURE
)<DeleteBodyRequestPayload, undefined, AxiosError>();

// - SCHEDULE -
export const createShopMainSchedule = createAsyncAction(
  Actions.CREATE_SHOP_MAIN_PAGE_SCHEDULE_REQUEST,
  Actions.CREATE_SHOP_MAIN_PAGE_SCHEDULE_SUCCESS,
  Actions.CREATE_SHOP_MAIN_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopMainScheduleFormValues, ShopMainScheduleCreateRequestData>,
  ShopMainScheduleCreateResponseData,
  AxiosError
>();

export const updateShopMainSchedule = createAsyncAction(
  Actions.UPDATE_SHOP_MAIN_PAGE_SCHEDULE_REQUEST,
  Actions.UPDATE_SHOP_MAIN_PAGE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SHOP_MAIN_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopMainScheduleFormValues, ShopMainScheduleUpdateRequestData>,
  ShopMainScheduleUpdateResponseData,
  AxiosError
>();

export const deleteShopMainSchedule = createAsyncAction(
  Actions.DELETE_SHOP_MAIN_PAGE_SCHEDULE_REQUEST,
  Actions.DELETE_SHOP_MAIN_PAGE_SCHEDULE_SUCCESS,
  Actions.DELETE_SHOP_MAIN_PAGE_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
