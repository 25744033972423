import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  PurposeScheduleCreateRequestData,
  PurposeScheduleCreateResponseData,
  PurposeScheduleFormValues,
  PurposeScheduleUpdateRequestData,
  PurposeScheduleUpdateResponseData,
} from './types';

const PURPOSE_SCHEDULES_API_PREFIX = `/purposes/schedules`;

export const createPurposeScheduleApi = createPostApi<
  PurposeScheduleFormValues,
  PurposeScheduleCreateRequestData,
  PurposeScheduleCreateResponseData
>(PURPOSE_SCHEDULES_API_PREFIX);

export const updatePurposeScheduleApi = createUpdateApi<
  PurposeScheduleFormValues,
  PurposeScheduleUpdateRequestData,
  PurposeScheduleUpdateResponseData
>(`${PURPOSE_SCHEDULES_API_PREFIX}/:id`);

export const deletePurposeScheduleApi = createDeleteApi(`${PURPOSE_SCHEDULES_API_PREFIX}/:id`);
