import { initialListState, ListState } from 'store/types';

export enum RealtimeMonitoringActions {
  LIST_REAL_TIME_MONITORING_MANAGER_REQUEST = 'LIST_REAL_TIME_MONITORING_MANAGER#REQUEST',
  LIST_REAL_TIME_MONITORING_MANAGER_SUCCESS = 'LIST_REAL_TIME_MONITORING_MANAGER#SUCCESS',
  LIST_REAL_TIME_MONITORING_MANAGER_FAILURE = 'LIST_REAL_TIME_MONITORING_MANAGER#FAILURE',

  LIST_REAL_TIME_MONITORING_AGENT_REQUEST = 'LIST_REAL_TIME_MONITORING_AGENT#REQUEST',
  LIST_REAL_TIME_MONITORING_AGENT_SUCCESS = 'LIST_REAL_TIME_MONITORING_AGENT#SUCCESS',
  LIST_REAL_TIME_MONITORING_AGENT_FAILURE = 'LIST_REAL_TIME_MONITORING_AGENT#FAILURE',

  LIST_MONI_NOTICE_REQUEST = 'LIST_MONI_NOTICE#REQUEST',
  LIST_MONI_NOTICE_SUCCESS = 'LIST_MONI_NOTICE#SUCCESS',
  LIST_MONI_NOTICE_FAILURE = 'LIST_MONI_NOTICE#FAILURE',

  DETAIL_MONI_NOTICE_REQUEST = 'LIST_MONI_NOTICE#REQUEST',
  DETAIL_MONI_NOTICE_SUCCESS = 'LIST_MONI_NOTICE#SUCCESS',
  DETAIL_MONI_NOTICE_FAILURE = 'LIST_MONI_NOTICE#FAILURE',

  POST_MONI_NOTICE_REQUEST = 'POST_MONI_NOTICE#REQUEST',
  POST_MONI_NOTICE_SUCCESS = 'POST_MONI_NOTICE#SUCCESS',
  POST_MONI_NOTICE_FAILURE = 'POST_MONI_NOTICE#FAILURE',
}

export enum ReportType {
  visual_abuse = 'visualAbuse',
  topless = 'topless',
  verbal = 'verbal',
  gender = 'gender',
  child = 'child',
  minor = 'minor',
  adult = 'adult',
}

export enum Role {
  manager = 'app-azar-backoffice-moderator-realtime-monitoring-manager',
  agent = 'app-azar-backoffice-moderator-realtime-monitoring-agent',
}

type Workload = {
  [key in ReportType]: number;
};

export interface RealtimeMonitoringListResponseData<T> {
  date: string;
  hour: T extends Role.agent ? string : never;
  agentName: T extends Role.manager ? string : never;
  workload: {
    [key in keyof typeof ReportType]: number;
  };
}

export interface RealtimeMonitoringListItem
  extends Workload,
    Omit<RealtimeMonitoringListResponseData<Role>, 'workload'> {
  total: number;
}

export interface MoniNoticeItem {
  id: string;
  title: string;
  date: string;
  manager: string;
}

export interface RealtimeMonitoringState {
  list: ListState<RealtimeMonitoringListItem>;
  detail: ListState<RealtimeMonitoringListItem>;
}

export const initialState: RealtimeMonitoringState = {
  list: initialListState,
  detail: initialListState,
};
