import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  NoticePopupCarouselActions as Actions,
  NoticePopupCarouselCreateRequestData,
  NoticePopupCarouselCreateSuccessData,
  NoticePopupCarouselUpdateRequestData,
  NoticePopupCarouselUpdateSuccessData,
} from './types';

export const createNoticePopupCarousel = createAsyncAction(
  Actions.CREATE_CAROUSEL_REQUEST,
  Actions.CREATE_CAROUSEL_SUCCESS,
  Actions.CREATE_CAROUSEL_FAILURE
)<FormRequestPayload<NoticePopupCarouselCreateRequestData>, NoticePopupCarouselCreateSuccessData, AxiosError>();

export const updateNoticePopupCarousel = createAsyncAction(
  Actions.UPDATE_CAROUSEL_REQUEST,
  Actions.UPDATE_CAROUSEL_SUCCESS,
  Actions.UPDATE_CAROUSEL_FAILURE
)<FormRequestPayload<NoticePopupCarouselUpdateRequestData>, NoticePopupCarouselUpdateSuccessData, AxiosError>();

export const deleteNoticePopupCarousel = createAsyncAction(
  Actions.DELETE_CAROUSEL_REQUEST,
  Actions.DELETE_CAROUSEL_SUCCESS,
  Actions.DELETE_CAROUSEL_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
