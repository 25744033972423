import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  TeamMessageImageSettingActions as Actions,
  TeamMessageImageSettingCreateRequestData,
  TeamMessageImageSettingCreateResponseData,
  TeamMessageImageSettingFormValues,
  TeamMessageImageSettingUpdateRequestData,
  TeamMessageImageSettingUpdateResponseData,
} from './types';

export const createTeamMessageImageSetting = createAsyncAction(
  Actions.CREATE_TEAM_MESSAGE_IMAGE_SETTING_REQUEST,
  Actions.CREATE_TEAM_MESSAGE_IMAGE_SETTING_SUCCESS,
  Actions.CREATE_TEAM_MESSAGE_IMAGE_SETTING_FAILURE
)<
  FormRequestPayload<TeamMessageImageSettingFormValues, TeamMessageImageSettingCreateRequestData>,
  TeamMessageImageSettingCreateResponseData,
  AxiosError
>();

export const updateTeamMessageImageSetting = createAsyncAction(
  Actions.UPDATE_TEAM_MESSAGE_IMAGE_SETTING_REQUEST,
  Actions.UPDATE_TEAM_MESSAGE_IMAGE_SETTING_SUCCESS,
  Actions.UPDATE_TEAM_MESSAGE_IMAGE_SETTING_FAILURE
)<
  FormRequestPayload<TeamMessageImageSettingFormValues, TeamMessageImageSettingUpdateRequestData>,
  TeamMessageImageSettingUpdateResponseData,
  AxiosError
>();

export const deleteTeamMessageImageSetting = createAsyncAction(
  Actions.DELETE_TEAM_MESSAGE_IMAGE_SETTING_REQUEST,
  Actions.DELETE_TEAM_MESSAGE_IMAGE_SETTING_SUCCESS,
  Actions.DELETE_TEAM_MESSAGE_IMAGE_SETTING_REQUEST
)<DeleteRequestPayload, undefined, AxiosError>();
