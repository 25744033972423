import { FlatListState } from 'store/types';

export enum DecoColorPresetActions {
  LIST_DECO_COLOR_PRESET_REQUEST = 'LIST_DECO_COLOR_PRESET#REQUEST',
  LIST_DECO_COLOR_PRESET_SUCCESS = 'LIST_DECO_COLOR_PRESET#SUCCESS',
  LIST_DECO_COLOR_PRESET_FAILURE = 'LIST_DECO_COLOR_PRESET#FAILURE',
}

export const initialState: DecoColorPresetState = { colorPresets: [] };

export interface DecoColorPresetState {
  colorPresets: FlatListState<DecoColorPresetListItem>;
}

export interface DecoColorPreset {
  color: string;
  presetPath: string;
}

export type DecoColorPresetListItem = {
  itemGroupId: string;
  colors: string[];
};
