import { useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';

import { RootState } from 'store/root/types';
import { Palette } from 'store/setting/types';

const selector = ({ setting: { palette } }: RootState) => palette;

const useIsDarkMode = () => {
  const palette = useSelector(selector);
  const isPreferDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const isDarkMode = useMemo(
    () => (palette === Palette.DEVICE ? isPreferDarkMode : palette === Palette.DARK),
    [palette, isPreferDarkMode]
  );
  return isDarkMode;
};

export default useIsDarkMode;
