import { initialListState, ListState } from 'store/types';

export enum FileS3UploadActions {
  LIST_FILE_S3_UPLOAD_REQUEST = 'LIST_FILE_S3_UPLOAD#REQUEST',
  LIST_FILE_S3_UPLOAD_SUCCESS = 'LIST_FILE_S3_UPLOAD#SUCCESS',
  LIST_FILE_S3_UPLOAD_FAILURE = 'LIST_FILE_S3_UPLOAD#FAILURE',

  READ_FILE_S3_UPLOAD_REQUEST = 'READ_FILE_S3_UPLOAD#REQUEST',
  READ_FILE_S3_UPLOAD_SUCCESS = 'READ_FILE_S3_UPLOAD#SUCCESS',
  READ_FILE_S3_UPLOAD_FAILURE = 'READ_FILE_S3_UPLOAD#FAILURE',

  CREATE_FILE_S3_UPLOAD_REQUEST = 'CREATE_FILE_S3_UPLOAD#REQUEST',
  CREATE_FILE_S3_UPLOAD_SUCCESS = 'CREATE_FILE_S3_UPLOAD#SUCCESS',
  CREATE_FILE_S3_UPLOAD_FAILURE = 'CREATE_FILE_S3_UPLOAD#FAILURE',

  UPDATE_FILE_S3_UPLOAD_REQUEST = 'UPDATE_FILE_S3_UPLOAD#REQUEST',
  UPDATE_FILE_S3_UPLOAD_SUCCESS = 'UPDATE_FILE_S3_UPLOAD#SUCCESS',
  UPDATE_FILE_S3_UPLOAD_FAILURE = 'UPDATE_FILE_S3_UPLOAD#FAILURE',

  DELETE_FILE_S3_UPLOAD_REQUEST = 'DELETE_FILE_S3_UPLOAD#REQUEST',
  DELETE_FILE_S3_UPLOAD_SUCCESS = 'DELETE_FILE_S3_UPLOAD#SUCCESS',
  DELETE_FILE_S3_UPLOAD_FAILURE = 'DELETE_FILE_S3_UPLOAD#FAILURE',

  UPLOAD_SINGLE_FILE_REQUEST = 'UPLOAD_SINGLE_FILE#REQUEST',
  UPLOAD_SINGLE_FILE_SUCCESS = 'UPLOAD_SINGLE_FILE#SUCCESS',
  UPLOAD_SINGLE_FILE_FAILURE = 'UPLOAD_SINGLE_FILE#FAILURE',

  UPLOAD_MULTI_FILE_REQUEST = 'UPLOAD_MULTI_FILE#REQUEST',
  UPLOAD_MULTI_FILE_SUCCESS = 'UPLOAD_MULTI_FILE#SUCCESS',
  UPLOAD_MULTI_FILE_FAILURE = 'UPLOAD_MULTI_FILE#FAILURE',
}

export const initialState: FileS3UploadState = {
  list: initialListState,
  detail: undefined,
  data: undefined,
};

export interface FileS3UploadState {
  list: ListState<FileS3UploadListItem>;
  detail?: FileS3Upload;
  data?: FileUrl | MultiFileUrl;
}

export interface FileUrl {
  fileUrl: string;
}

export interface MultiFileUrl {
  fileUrls: string[];
}

export interface FileS3Upload {
  id: number;
  title: string;
  description: string;
  fileUrls: string[];
}

export interface FileS3UploadListItem extends Omit<FileS3Upload, 'fileUrls'> {
  fileUrls: FileUrl[];
}

export type FileS3UploadDetail = FileS3Upload;

export type FileS3UploadFormValues = FileS3Upload;

export type FileS3UploadCreateRequestData = Omit<FileS3Upload, 'id'>;

export type FileS3UploadCreateResponseData = FileS3Upload;

export type FileS3UploadUpdateRequestData = Omit<FileS3Upload, 'id'>;

export type FileS3UploadUpdateResponseData = FileS3Upload;

export type SingleFileUploadRequestData = any;

export type SingleFileUploadResponseData = FileUrl;

export type MultiFileUploadRequestData = any;

export type MultiFileUploadResponseData = MultiFileUrl;
