import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteBodyRequestPayload, ListRequestPayload, ListState } from 'store/types';

import { UserItemInventoryActions as Actions, UserItemInventoryListItem } from './types';

export const listUserItemInventory = createAsyncAction(
  Actions.LIST_USER_ITEM_INVENTORY_REQUEST,
  Actions.LIST_USER_ITEM_INVENTORY_SUCCESS,
  Actions.LIST_USER_ITEM_INVENTORY_FAILURE
)<ListRequestPayload, ListState<UserItemInventoryListItem>, AxiosError>();

export const deleteUserItemInventory = createAsyncAction(
  Actions.DELETE_USER_ITEM_INVENTORY_REQUEST,
  Actions.DELETE_USER_ITEM_INVENTORY_SUCCESS,
  Actions.DELETE_USER_ITEM_INVENTORY_FAILURE
)<DeleteBodyRequestPayload, undefined, AxiosError>();
