import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  LimitedEditionPageActions as Actions,
  LimitedEditionFormValues,
  LimitedEditionRequestPayload,
  LimitedEditionResponsePayload,
  LimitedEditionListItem,
  LimitedEdition,
} from './types';

export const createLimitedEdition = createAsyncAction(
  Actions.CREATE_LIMITED_EDITION_PAGE_REQUEST,
  Actions.CREATE_LIMITED_EDITION_PAGE_SUCCESS,
  Actions.CREATE_LIMITED_EDITION_PAGE_FAILURE
)<
  FormRequestPayload<LimitedEditionFormValues, LimitedEditionRequestPayload>,
  LimitedEditionResponsePayload,
  AxiosError
>();

export const listLimitedEdition = createAsyncAction(
  Actions.LIST_LIMITED_EDITION_PAGE_REQUEST,
  Actions.LIST_LIMITED_EDITION_PAGE_SUCCESS,
  Actions.LIST_LIMITED_EDITION_PAGE_FAILURE
)<ListRequestPayload, ListState<LimitedEditionListItem>, AxiosError>();

export const readLimitedEdition = createAsyncAction(
  Actions.READ_LIMITED_EDITION_PAGE_REQUEST,
  Actions.READ_LIMITED_EDITION_PAGE_SUCCESS,
  Actions.READ_LIMITED_EDITION_PAGE_FAILURE
)<ReadRequestPayload, LimitedEdition, AxiosError>();

export const updateLimitedEdition = createAsyncAction(
  Actions.UPDATE_LIMITED_EDITION_PAGE_REQUEST,
  Actions.UPDATE_LIMITED_EDITION_PAGE_SUCCESS,
  Actions.UPDATE_LIMITED_EDITION_PAGE_FAILURE
)<
  FormRequestPayload<LimitedEditionFormValues, LimitedEditionRequestPayload>,
  LimitedEditionResponsePayload,
  AxiosError
>();

export const deleteLimitedEdition = createAsyncAction(
  Actions.DELETE_LIMITED_EDITION_PAGE_REQUEST,
  Actions.DELETE_LIMITED_EDITION_PAGE_SUCCESS,
  Actions.DELETE_LIMITED_EDITION_PAGE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
