import { createFlatListApi, createListApi, createPostApi, createReadApi } from 'store/utils';
import { MatataInvalidatedPurchaseByReceiver } from 'store/matataInvalidatedPurchaseByReceiver/types';

const BASE_PATH = '/invalidated-purchases/matata/aggregate/by-receiver';

export const readMatataInvalidatedPurchaseByReceiverApi = createReadApi<MatataInvalidatedPurchaseByReceiver>(
  `${BASE_PATH}/:id`
);
export const listMatataInvalidatedPurchaseByReceiverApi = createListApi<MatataInvalidatedPurchaseByReceiver>(BASE_PATH);

export const downloadMatataInvalidatedPurchaseByReceiverApi = createFlatListApi<MatataInvalidatedPurchaseByReceiver>(
  `${BASE_PATH}/list-all`
);

export const withdrawMatataInvalidatedPurchaseByReceiverApi = createPostApi<MatataInvalidatedPurchaseByReceiver>(
  `${BASE_PATH}/withdraw`
);

export const notWithdrawMatataInvalidatedPurchaseByReceiverApi = createPostApi<MatataInvalidatedPurchaseByReceiver>(
  `${BASE_PATH}/not-withdraw`
);
