import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { provideGemPackage, provideGem } from './actions';
import { provideGemApi, provideGemPackageApi } from './api';

const provideGemPackageToastEpic = createToastEpic(provideGemPackage.success, 'Successfully Provided');

const provideGemToastEpic = createToastEpic(provideGem.success, 'Successfully Provided');

const provideGemPackageEpic = createUpdateEpic(provideGemPackage, provideGemPackageApi);

const provideGemEpic = createUpdateEpic(provideGem, provideGemApi);

export default combineEpics(provideGemPackageToastEpic, provideGemToastEpic, provideGemEpic, provideGemPackageEpic);
