import {
  badgeType,
  commonModuleType,
  generalModule,
  periodUnitType,
  productModule,
  productType,
  purchaseLimitBehavior,
} from './constants';
import { initialListState, ListState } from 'store/types';

export enum ShopModulePageActions {
  CREATE_SHOP_MODULE_PAGE_REQUEST = 'CREATE_SHOP_MODULE_PAGE#REQUEST',
  CREATE_SHOP_MODULE_PAGE_SUCCESS = 'CREATE_SHOP_MODULE_PAGE#SUCCESS',
  CREATE_SHOP_MODULE_PAGE_FAILURE = 'CREATE_SHOP_MODULE_PAGE#FAILURE',

  LIST_SHOP_MODULE_PAGE_REQUEST = 'LIST_SHOP_MODULE_PAGE#REQUEST',
  LIST_SHOP_MODULE_PAGE_SUCCESS = 'LIST_SHOP_MODULE_PAGE#SUCCESS',
  LIST_SHOP_MODULE_PAGE_FAILURE = 'LIST_SHOP_MODULE_PAGE#FAILURE',

  READ_SHOP_MODULE_PAGE_REQUEST = 'READ_SHOP_MODULE_PAGE#REQUEST',
  READ_SHOP_MODULE_PAGE_FAILURE = 'READ_SHOP_MODULE_PAGE#FAILURE',
  READ_SHOP_MODULE_PAGE_SUCCESS = 'READ_SHOP_MODULE_PAGE#SUCCESS',

  UPDATE_SHOP_MODULE_PAGE_REQUEST = 'UPDATE_SHOP_MODULE_PAGE#REQUEST',
  UPDATE_SHOP_MODULE_PAGE_SUCCESS = 'UPDATE_SHOP_MODULE_PAGE#SUCCESS',
  UPDATE_SHOP_MODULE_PAGE_FAILURE = 'UPDATE_SHOP_MODULE_PAGE#FAILURE',

  DELETE_SHOP_MODULE_PAGE_REQUEST = 'DELETE_SHOP_MODULE_PAGE#REQUEST',
  DELETE_SHOP_MODULE_PAGE_SUCCESS = 'DELETE_SHOP_MODULE_PAGE#SUCCESS',
  DELETE_SHOP_MODULE_PAGE_FAILURE = 'DELETE_SHOP_MODULE_PAGE#FAILURE',

  // Schedule
  CREATE_SHOP_MODULE_PAGE_SCHEDULE_REQUEST = 'CREATE_SHOP_MODULE_PAGE_SCHEDULE#REQUEST',
  CREATE_SHOP_MODULE_PAGE_SCHEDULE_SUCCESS = 'CREATE_SHOP_MODULE_PAGE_SCHEDULE#SUCCESS',
  CREATE_SHOP_MODULE_PAGE_SCHEDULE_FAILURE = 'CREATE_SHOP_MODULE_PAGE_SCHEDULE#FAILURE',

  UPDATE_SHOP_MODULE_PAGE_SCHEDULE_REQUEST = 'UPDATE_SHOP_MODULE_PAGE_SCHEDULE#REQUEST',
  UPDATE_SHOP_MODULE_PAGE_SCHEDULE_SUCCESS = 'UPDATE_SHOP_MODULE_PAGE_SCHEDULE#SUCCESS',
  UPDATE_SHOP_MODULE_PAGE_SCHEDULE_FAILURE = 'UPDATE_SHOP_MODULE_PAGE_SCHEDULE#FAILURE',

  DELETE_SHOP_MODULE_PAGE_SCHEDULE_REQUEST = 'DELETE_SHOP_MODULE_PAGE_SCHEDULE#REQUEST',
  DELETE_SHOP_MODULE_PAGE_SCHEDULE_SUCCESS = 'DELETE_SHOP_MODULE_PAGE_SCHEDULE#SUCCESS',
  DELETE_SHOP_MODULE_PAGE_SCHEDULE_FAILURE = 'DELETE_SHOP_MODULE_PAGE_SCHEDULE#FAILURE',
}

export type CommonType = typeof commonModuleType[number];
export type ProductType = typeof productType[number];
export type PeriodUnitType = typeof periodUnitType[number];
export type BadgeType = typeof badgeType[number];
export type PurchaseLimitBehaviorType = typeof purchaseLimitBehavior[number];

export type GeneralModuleType = typeof generalModule[number];
export type ProductModuleType = typeof productModule[number];
export type ModuleType = ProductModuleType | GeneralModuleType;
// - Detail -
export interface ShopModuleDetail extends ShopModulePageFormValues {
  schedules: ShopModuleSchedule[];
}
// STATE
export interface ShopModuleState {
  list: ListState<ShopModuleListItem>;
  detail?: ShopModuleDetail;
}
export const initialState: ShopModuleState = {
  list: initialListState,
  detail: undefined,
};

// Payload
export type ShopModulePageFormValues = {
  type: CommonType;
  id?: number;
  moduleType: ModuleType;
  name: string;
  description: string;
  moduleEndDate: string;
  enabled: boolean;
  product?: Product; // Product Type
  module?: Module; // Module Type
  content?: Content; // Content Type
  badge?: Badge; // Badge Type
};
export type ShopModulePageRequestData = Omit<ShopModulePageFormValues, 'moduleId'>;
export type ShopModulePageResponseData = ShopModulePageFormValues;

// LIST
export type ShopModuleListItem = {
  type: CommonType;
  id: number;
  moduleType: ModuleType;
  name: string;
  description: string;
  enabled: boolean;
  schedules: ShopModuleSchedule[];
};

// - PRODUCT -
export type Product = {
  productNameKey: string;
  iconUrl: string;
  productType: ProductType;
  discountRate: string;
  period: number;
  periodUnit: PeriodUnitType;
  purchaseLimitBehavior: PurchaseLimitBehaviorType;
  //extra
  itemProductId: string;
  itemPackageId: string;
  appStoreIabProductId: string;
  googlePlayIabProductId: string;
  appStoreIabSubscriptionProductId: string;
  googlePlayIabSubscriptionProductId: string;
};

// - MODULE -
export type Module = {
  mainTitle: string;
  mainTextTitle: string;
  mainText: string;
  subTitle: string;
  backgroundImageUrl: string;
  footerTitle: string;
  footerIconUrl: string;
  footerButtonText: string;
  deeplink: string;
  imageUrl: string;
  leftImageUrl: string;
  rightImageUrl: string;
  title: string;
  description: string;
  iconUrl: string;
  cardId: string;
  text: string;
  titleImageUrl: string;
  badgeText: string;
  badgeTextColor: string;
  darkModeBadgeTextColor: string;
  badgeBackgroundColor: string;
  anchorPageId: string;
  featureId: string;
  label: string;
  contentDescription: string;
  rewardId: string;
  buttonText: string;
  buttonUrl: string;
  reclaimAvailableAtMillis: number;
};

// - CONTENT -
export type Content = {
  mainTitle: string;
  subTitle: string;
  backgroundImageUrl: string;
  deeplink: string;
  gemIconUrl: string;
  backgroundColor: string;
  description: string;
  discountRateTextEnabled: boolean;
  limitedTimeTextEnabled: boolean;
  limitedTimeTextColor: string;
  guidePopupTitle: string;
  guidePopupButtonText: string;
  bundleItems: BundleItem[];
};

export type BundleItem = {
  iconUrl: string;
  itemName: string;
  description?: string;
};

// - BADGE -
export type Badge = {
  badgeType: BadgeType;
  text: string;
  mainColor: string;
  subColor: string;
};

// - SCHEDULE -
export interface ShopModuleSchedule {
  id: number;
  segmentCode: string;
  startDate?: string;
  endDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  enabled: boolean;
  subscribingProductId?: string;
}

export type ShopModuleScheduleFormValues = Omit<ShopModuleSchedule, 'id'>;
export type ShopModuleScheduleCreateRequestData = ShopModuleScheduleFormValues;
export type ShopModuleScheduleCreateResponseData = ShopModuleSchedule;
export type ShopModuleScheduleUpdateRequestData = ShopModuleScheduleFormValues;
export type ShopModuleScheduleUpdateResponseData = ShopModuleSchedule;
