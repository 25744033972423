import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import { MatataInvalidatedPurchase, MatataInvalidatedPurchaseActions as Actions } from './types';

export const readMatataInvalidatedPurchase = createAsyncAction(
  Actions.READ_MATATA_INVALIDATED_PURCHASE_REQUEST,
  Actions.READ_MATATA_INVALIDATED_PURCHASE_SUCCESS,
  Actions.READ_MATATA_INVALIDATED_PURCHASE_FAILURE
)<ReadRequestPayload, MatataInvalidatedPurchase, AxiosError>();

export const listMatataInvalidatedPurchase = createAsyncAction(
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_REQUEST,
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_SUCCESS,
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_FAILURE
)<ListRequestPayload, ListState<MatataInvalidatedPurchase>, AxiosError>();
