import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  NotificationCenterBannerCreateRequestData,
  NotificationCenterBannerCreateRequestFormValues,
  NotificationCenterBannerCreateSuccessData,
  NotificationCenterBannerDetail,
  NotificationCenterBannerListItem,
  NotificationCenterBannerUpdateRequestData,
  NotificationCenterBannerUpdateRequestFormValues,
  NotificationCenterBannerUpdateSuccessData,
} from './types';

export const listNotificationCenterBannerApi =
  createListApi<NotificationCenterBannerListItem>('/banners/notification-center');
export const createNotificationCenterBannerApi = createPostApi<
  NotificationCenterBannerCreateRequestFormValues,
  NotificationCenterBannerCreateRequestData,
  NotificationCenterBannerCreateSuccessData
>('/banners/notification-center');
export const readNotificationCenterBannerApi = createReadApi<NotificationCenterBannerDetail>(
  '/banners/notification-center/:id'
);
export const updateNotificationCenterBannerApi = createUpdateApi<
  NotificationCenterBannerUpdateRequestFormValues,
  NotificationCenterBannerUpdateRequestData,
  NotificationCenterBannerUpdateSuccessData
>('/banners/notification-center/:id');
export const refreshNotificationCenterBannerApi = createPostApi('/banners/notification-center/refresh');
