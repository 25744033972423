import { createFlatListApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  WebDecoEffectCreateRequestData,
  WebDecoEffectCreateResponseData,
  WebDecoEffectDetail,
  WebDecoEffectFormValues,
  WebDecoEffectGroup,
  WebDecoEffectListItem,
  WebDecoEffectUpdateRequestData,
  WebDecoEffectUpdateResponseData,
} from './types';

const DECO_V2_EFFECT_API_PREFIX = '/v1/deco-effects';

export const listWebDecoEffectApi = createListApi<WebDecoEffectListItem>(DECO_V2_EFFECT_API_PREFIX);

export const listWebDecoEffectGroupApi = createFlatListApi<WebDecoEffectGroup>(`${DECO_V2_EFFECT_API_PREFIX}/groups`);

export const createWebDecoEffectApi = createPostApi<
  WebDecoEffectFormValues,
  WebDecoEffectCreateRequestData,
  WebDecoEffectCreateResponseData
>(DECO_V2_EFFECT_API_PREFIX);

export const updateWebDecoEffectApi = createUpdateApi<
  WebDecoEffectFormValues,
  WebDecoEffectUpdateRequestData,
  WebDecoEffectUpdateResponseData
>(`${DECO_V2_EFFECT_API_PREFIX}/:id`);

export const readWebDecoEffectApi = createReadApi<WebDecoEffectDetail>(`${DECO_V2_EFFECT_API_PREFIX}/:id`);

export const refreshWebDecoEffectApi = createPostApi(`${DECO_V2_EFFECT_API_PREFIX}/refresh`);
