import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic } from '../utils';

import { listMatataInvalidatedPurchase, readMatataInvalidatedPurchase } from './actions';
import { listMatataInvalidatedPurchaseApi, readMatataInvalidatedPurchaseApi } from './api';

const readMatataInvalidatedPurchaseEpic = createReadEpic(
  readMatataInvalidatedPurchase,
  readMatataInvalidatedPurchaseApi
);
const listMatataInvalidatedPurchaseEpic = createListEpic(
  listMatataInvalidatedPurchase,
  listMatataInvalidatedPurchaseApi
);
export default combineEpics(readMatataInvalidatedPurchaseEpic, listMatataInvalidatedPurchaseEpic);
