import { initialListState, ListState } from 'store/types';

export enum MatchTagActions {
  LIST_MATCH_TAGS_REQUEST = 'LIST_MATCH_TAGS#REQUEST',
  LIST_MATCH_TAGS_SUCCESS = 'LIST_MATCH_TAGS#SUCCESS',
  LIST_MATCH_TAGS_FAILURE = 'LIST_MATCH_TAGS#FAILURE',

  READ_MATCH_TAG_REQUEST = 'READ_MATCH_TAG#REQUEST',
  READ_MATCH_TAG_SUCCESS = 'READ_MATCH_TAG#SUCCESS',
  READ_MATCH_TAG_FAILURE = 'READ_MATCH_TAG#FAILURE',

  CREATE_MATCH_TAG_REQUEST = 'CREATE_MATCH_TAG#REQUEST',
  CREATE_MATCH_TAG_SUCCESS = 'CREATE_MATCH_TAG#SUCCESS',
  CREATE_MATCH_TAG_FAILURE = 'CREATE_MATCH_TAG#FAILURE',

  UPDATE_MATCH_TAG_REQUEST = 'UPDATE_MATCH_TAG#REQUEST',
  UPDATE_MATCH_TAG_SUCCESS = 'UPDATE_MATCH_TAG#SUCCESS',
  UPDATE_MATCH_TAG_FAILURE = 'UPDATE_MATCH_TAG#FAILURE',

  DELETE_MATCH_TAG_REQUEST = 'DELETE_MATCH_TAG#REQUEST',
  DELETE_MATCH_TAG_SUCCESS = 'DELETE_MATCH_TAG#SUCCESS',
  DELETE_MATCH_TAG_FAILURE = 'DELETE_MATCH_TAG#FAILURE',
}

export const initialState: MatchTagState = { list: initialListState };
export interface MatchTag {
  checked: boolean;
  id: string;
  userId: string;
  matchTag: string;
  userName: string;
}

export interface MatchTagState {
  list: ListState<MatchTag>;
}

export interface MatchTagCreateRequestData {
  userId: string;
  matchTag: string;
}
