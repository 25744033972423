import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as stringLocalizationActions from './actions';
import { initialState, StringLocalizationState } from './types';

const stringLocalization = createReducer<StringLocalizationState, RootAction>(initialState)
  .handleAction(stringLocalizationActions.listStringLocalization.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(stringLocalizationActions.listStringLocalization.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(({ id, key, description, koValue, enValue, ...languages }) => ({
        id,
        key,
        description,
        koValue,
        enValue,
        languages,
      })),
    });

    return newState.toJS() as StringLocalizationState;
  })
  .handleAction(stringLocalizationActions.readStringLocalization.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(stringLocalizationActions.upsertStringLocalizationBulk.success, (state) => ({ ...state }))
  .handleAction(stringLocalizationActions.createStringLocalization.success, (state) => ({ ...state }))
  .handleAction(stringLocalizationActions.updateStringLocalization.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(stringLocalizationActions.deleteStringLocalization.success, (state) => ({
    ...state,
    detail: undefined,
  }))
  .handleAction(
    stringLocalizationActions.listStringLocalizationHistory.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      history: {
        ...state.history,
        page,
        pageSize,
      },
    })
  )
  .handleAction(stringLocalizationActions.listStringLocalizationHistory.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('history', {
      ...payload,
      data: payload.data.map(({ type, ...item }) => ({
        ...item,
        type: item.tags[0] === 'BULK' ? `IMPORT (${type})` : type,
        updatedKey: `${(item.entity || {}).key}`,
      })),
    });

    return newState.toJS() as StringLocalizationState;
  });
export default stringLocalization;
