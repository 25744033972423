import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import { CountryManageActions as Actions, CountryManageUpdateRequestData } from './types';

export const updateCountryManage = createAsyncAction(
  Actions.UPDATE_COUNTRY_MANAGE_REQUEST,
  Actions.UPDATE_COUNTRY_MANAGE_SUCCESS,
  Actions.UPDATE_COUNTRY_MANAGE_FAILURE
)<FormRequestPayload<CountryManageUpdateRequestData>, undefined, AxiosError>();
