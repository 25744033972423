import { initialListState, ListState } from '../types';

export enum MatataInvalidatedPurchaseActions {
  LIST_MATATA_INVALIDATED_PURCHASE_REQUEST = 'LIST_MATATA_INVALIDATED_PURCHASE#REQUEST',
  LIST_MATATA_INVALIDATED_PURCHASE_SUCCESS = 'LIST_MATATA_INVALIDATED_PURCHASE#SUCCESS',
  LIST_MATATA_INVALIDATED_PURCHASE_FAILURE = 'LIST_MATATA_INVALIDATED_PURCHASE#FAILURE',

  READ_MATATA_INVALIDATED_PURCHASE_REQUEST = 'READ_MATATA_INVALIDATED_PURCHASE#REQUEST',
  READ_MATATA_INVALIDATED_PURCHASE_SUCCESS = 'READ_MATATA_INVALIDATED_PURCHASE#SUCCESS',
  READ_MATATA_INVALIDATED_PURCHASE_FAILURE = 'READ_MATATA_INVALIDATED_PURCHASE#FAILURE',
}

export interface MatataInvalidatedPurchase {
  gemInventoryId: number;
  orderTransactionId: string;
  status: string;
  type: string;
  senderId: number;
  senderMatataId: string;
  senderCountryCode: string;
  senderPlatform: string;
  receiverId: number;
  receiverMatataId: string;
  receiverCountryCode: string;
  gemInventoryInitialBalance: number;
  gemInventoryAmount: number;
  gemAmount: number;
  starAmount: number;
  invalidatedAt: string;
  orderedAt: string;
  createdAt: string;

  processedAt?: string;
  withdrawnAmount?: number;
}

export const fileHeaders: string[] = [
  'gemInventoryId',
  'orderTransactionId',
  'status',
  'type',
  'senderId',
  'senderMatataId',
  'senderCountryCode',
  'senderPlatform',
  'receiverId',
  'receiverMatataId',
  'receiverCountryCode',
  'gemInventoryInitialBalance',
  'gemInventoryAmount',
  'gemAmount',
  'starAmount',
  'invalidatedAt',
  'orderedAt',
  'createdAt',
  'processedAt',
  'withdrawnAmount',
];

export const sortMatataInvalidatedPurchase = (data: MatataInvalidatedPurchase) => [
  data.gemInventoryId,
  data.orderTransactionId,
  data.status,
  data.type,
  data.senderId,
  data.senderMatataId,
  data.senderCountryCode,
  data.senderPlatform,
  data.receiverId,
  data.receiverMatataId,
  data.receiverCountryCode,
  data.gemInventoryInitialBalance,
  data.gemInventoryAmount,
  data.gemAmount,
  data.starAmount,
  data.invalidatedAt,
  data.orderedAt,
  data.createdAt,
  data.processedAt,
  data.withdrawnAmount,
];

export interface MatataInvalidatedPurchaseState {
  list: ListState<MatataInvalidatedPurchase>;
  detail?: MatataInvalidatedPurchase;
}

export const initialState: MatataInvalidatedPurchaseState = {
  list: initialListState,
  detail: undefined,
};
