import { combineEpics } from 'redux-observable';

import { createFlatListEpic } from '../utils';

import { listSpecialPromotionSettingLimitKey } from './actions';
import { listSpecialPromotionSettingLimitKeyApi } from './api';

const listSpecialPromotionSettingLimitKeyEpic = createFlatListEpic(
  listSpecialPromotionSettingLimitKey,
  listSpecialPromotionSettingLimitKeyApi
);

export default combineEpics(listSpecialPromotionSettingLimitKeyEpic);
