import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  BigquerySegmentActions as Actions,
  BigqueryNewUserSegment,
  BigquerySegment,
  BigquerySegmentDetail,
} from './types';

export const listBigquerySegment = createAsyncAction(
  Actions.LIST_BIGQUERY_SEGMENTS_REQUEST,
  Actions.LIST_BIGQUERY_SEGMENTS_SUCCESS,
  Actions.LIST_BIGQUERY_SEGMENTS_FAILURE
)<ListRequestPayload, ListState<BigquerySegment>, AxiosError>();

export const listBigquerySegmentSearch = createAsyncAction(
  Actions.LIST_BIGQUERY_SEGMENTS_SEARCH_REQUEST,
  Actions.LIST_BIGQUERY_SEGMENTS_SEARCH_SUCCESS,
  Actions.LIST_BIGQUERY_SEGMENTS_SEARCH_FAILURE
)<ListRequestPayload, ListState<BigqueryNewUserSegment>, AxiosError>();

export const readBigquerySegment = createAsyncAction(
  Actions.READ_BIGQUERY_SEGMENT_REQUEST,
  Actions.READ_BIGQUERY_SEGMENT_SUCCESS,
  Actions.READ_BIGQUERY_SEGMENT_FAILURE
)<ReadRequestPayload, BigquerySegmentDetail, AxiosError>();
