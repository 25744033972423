import { combineEpics } from 'redux-observable';

import { createAbTestCriteria, deleteAbTestCriteria, updateAbTestCriteria } from 'store/abTestCriteria/actions';
import { createAbTestCriteriaApi, deleteAbTestCriteriaApi, updateAbTestCriteriaApi } from 'store/abTestCriteria/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createAbTestCriteriaToastEpic = createToastEpic(createAbTestCriteria.success, 'Test group saved successfully');

const updateAbTestCriteriaToastEpic = createToastEpic(updateAbTestCriteria.success, 'Test group updated successfully');

const deleteAbTestCriteriaToastEpic = createToastEpic(deleteAbTestCriteria.success, 'Test group deleted successfully');

const createScheduleEpic = createPostEpic(createAbTestCriteria, createAbTestCriteriaApi);

const updateScheduleEpic = createUpdateEpic(updateAbTestCriteria, updateAbTestCriteriaApi);

const deleteScheduleEpic = createDeleteEpic(deleteAbTestCriteria, deleteAbTestCriteriaApi);

export default combineEpics(
  createAbTestCriteriaToastEpic,
  updateAbTestCriteriaToastEpic,
  deleteAbTestCriteriaToastEpic,
  createScheduleEpic,
  updateScheduleEpic,
  deleteScheduleEpic
);
