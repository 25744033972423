import { ShopSectionType } from 'store/shopSectionPage/types';
import { initialListState, ListState } from 'store/types';

export enum ShopMainPageActions {
  LIST_SHOP_MAIN_PAGE_REQUEST = 'LIST_SHOP_MAIN_PAGE#REQUEST',
  LIST_SHOP_MAIN_PAGE_SUCCESS = 'LIST_SHOP_MAIN_PAGE#SUCCESS',
  LIST_SHOP_MAIN_PAGE_FAILURE = 'LIST_SHOP_MAIN_PAGE#FAILURE',

  READ_SHOP_MAIN_PAGE_REQUEST = 'READ_SHOP_MAIN_PAGE#REQUEST',
  READ_SHOP_MAIN_PAGE_FAILURE = 'READ_SHOP_MAIN_PAGE#FAILURE',
  READ_SHOP_MAIN_PAGE_SUCCESS = 'READ_SHOP_MAIN_PAGE#SUCCESS',

  CREATE_SHOP_MAIN_PAGE_REQUEST = 'CREATE_SHOP_MAIN_PAGE#REQUEST',
  CREATE_SHOP_MAIN_PAGE_SUCCESS = 'CREATE_SHOP_MAIN_PAGE#SUCCESS',
  CREATE_SHOP_MAIN_PAGE_FAILURE = 'CREATE_SHOP_MAIN_PAGE#FAILURE',

  UPDATE_SHOP_MAIN_PAGE_REQUEST = 'UPDATE_SHOP_MAIN_PAGE#REQUEST',
  UPDATE_SHOP_MAIN_PAGE_SUCCESS = 'UPDATE_SHOP_MAIN_PAGE#SUCCESS',
  UPDATE_SHOP_MAIN_PAGE_FAILURE = 'UPDATE_SHOP_MAIN_PAGE#FAILURE',

  DELETE_SHOP_MAIN_PAGE_REQUEST = 'DELETE_SHOP_MAIN_PAGE#REQUEST',
  DELETE_SHOP_MAIN_PAGE_SUCCESS = 'DELETE_SHOP_MAIN_PAGE#SUCCESS',
  DELETE_SHOP_MAIN_PAGE_FAILURE = 'DELETE_SHOP_MAIN_PAGE#FAILURE',

  // Section
  CONNECT_SHOP_MAIN_SECTION_REQUEST = 'CONNECT_SHOP_MAIN_SECTION#REQUEST',
  CONNECT_SHOP_MAIN_SECTION_SUCCESS = 'CONNECT_SHOP_MAIN_SECTION#SUCCESS',
  CONNECT_SHOP_MAIN_SECTION_FAILURE = 'CONNECT_SHOP_MAIN_SECTION#FAILURE',

  EDIT_SHOP_MAIN_SECTION_REQUEST = 'EDIT_SHOP_MAIN_SECTION#REQUEST',
  EDIT_SHOP_MAIN_SECTION_SUCCESS = 'EDIT_SHOP_MAIN_SECTION#SUCCESS',
  EDIT_SHOP_MAIN_SECTION_FAILURE = 'EDIT_SHOP_MAIN_SECTION#FAILURE',

  DISCONNECT_SHOP_MAIN_SECTION_REQUEST = 'DISCONNECT_SHOP_MAIN_SECTION#REQUEST',
  DISCONNECT_SHOP_MAIN_SECTION_SUCCESS = 'DISCONNECT_SHOP_MAIN_SECTION#SUCCESS',
  DISCONNECT_SHOP_MAIN_SECTION_FAILURE = 'DISCONNECT_SHOP_MAIN_SECTION#FAILURE',

  // Schedule
  CREATE_SHOP_MAIN_PAGE_SCHEDULE_REQUEST = 'CREATE_SHOP_MAIN_PAGE_SCHEDULE#REQUEST',
  CREATE_SHOP_MAIN_PAGE_SCHEDULE_SUCCESS = 'CREATE_SHOP_MAIN_PAGE_SCHEDULE#SUCCESS',
  CREATE_SHOP_MAIN_PAGE_SCHEDULE_FAILURE = 'CREATE_SHOP_MAIN_PAGE_SCHEDULE#FAILURE',

  UPDATE_SHOP_MAIN_PAGE_SCHEDULE_REQUEST = 'UPDATE_SHOP_MAIN_PAGE_SCHEDULE#REQUEST',
  UPDATE_SHOP_MAIN_PAGE_SCHEDULE_SUCCESS = 'UPDATE_SHOP_MAIN_PAGE_SCHEDULE#SUCCESS',
  UPDATE_SHOP_MAIN_PAGE_SCHEDULE_FAILURE = 'UPDATE_SHOP_MAIN_PAGE_SCHEDULE#FAILURE',

  DELETE_SHOP_MAIN_PAGE_SCHEDULE_REQUEST = 'DELETE_SHOP_MAIN_PAGE_SCHEDULE#REQUEST',
  DELETE_SHOP_MAIN_PAGE_SCHEDULE_SUCCESS = 'DELETE_SHOP_MAIN_PAGE_SCHEDULE#SUCCESS',
  DELETE_SHOP_MAIN_PAGE_SCHEDULE_FAILURE = 'DELETE_SHOP_MAIN_PAGE_SCHEDULE#FAILURE',

  // Shop Main Page Section Bulk
  BULK_CREATE_SHOP_MAIN_SECTION_REQUEST = 'BULK_CREATE_SHOP_MAIN_SECTION#REQUEST',
  BULK_CREATE_SHOP_MAIN_SECTION_SUCCESS = 'BULK_CREATE_SHOP_MAIN_SECTION#SUCCESS',
  BULK_CREATE_SHOP_MAIN_SECTION_FAILURE = 'BULK_CREATE_SHOP_MAIN_SECTION#FAILURE',

  BULK_DELETE_SHOP_MAIN_SECTION_REQUEST = 'BULK_DELETE_SHOP_MAIN_SECTION#REQUEST',
  BULK_DELETE_SHOP_MAIN_SECTION_SUCCESS = 'BULK_DELETE_SHOP_MAIN_SECTION#SUCCESS',
  BULK_DELETE_SHOP_MAIN_SECTION_FAILURE = 'BULK_DELETE_SHOP_MAIN_SECTION#FAILURE',
}

export const mainTemplateIds = ['MAIN_PAGE'] as const;
export type MainTemplateIds = typeof mainTemplateIds[number];

// - Payload -
export type ShopMainPageFormValues = {
  pageId: string;
  templateId: MainTemplateIds;
  description: string;
  enabled: boolean;
};
export type ShopMainPageRequestData = ShopMainPageFormValues;
export type ShopMainPageResponseData = ShopMainPageFormValues;

// - Tab -
export interface ShopMainTab {
  id: number;
  name: string;
  activeName: string;
  anchor: string;
}

// - Section -
export interface ShopMainSection {
  sectionId: number;
  linkId: number;
  sectionType: ShopSectionType;
  name: string;
  description: string;
  sectionTitleKey: string;
  enabled: boolean;
  evalOrder: number;
}

export type ShopMainSectionFormValue = {
  pageId: string;
  sectionId: number;
  evalOrder: number;
};
export type ShopMainSectionRequestData = ShopMainSectionFormValue;
export type ShopMainSectionResponseData = ShopMainSection;

// - Section Bulk -
export type ShopMainSectionBulkCreateRequestData = {
  links: ShopMainSectionRequestData[];
};
export type ShopMainSectionBulkCreateResponseData = ShopMainSectionFormValue & { linkId: number };

// - Detail -
export interface ShopMainDetail extends ShopMainPageFormValues {
  tabs: ShopMainTab[];
  sections: ShopMainSection[];
  schedules: ShopMainSchedule[];
}

// - SCHEDULE -
export interface ShopMainSchedule {
  id: number;
  segmentCode: string;
  startDate?: string;
  endDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder: number;
  enabled: boolean;
}

export type ShopMainScheduleFormValues = Omit<ShopMainSchedule, 'id'>;
export type ShopMainScheduleCreateRequestData = ShopMainScheduleFormValues;
export type ShopMainScheduleCreateResponseData = ShopMainSchedule;
export type ShopMainScheduleUpdateRequestData = ShopMainScheduleFormValues;
export type ShopMainScheduleUpdateResponseData = ShopMainSchedule;

// - State -
export interface ShopMainListItem extends ShopMainPageFormValues {
  schedules: ShopMainSchedule[];
  sectionCount: number;
}
export interface ShopMainState {
  list: ListState<ShopMainListItem>;
  detail?: ShopMainDetail;
}

export const initialState: ShopMainState = {
  list: initialListState,
  detail: undefined,
};
