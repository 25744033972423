import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  SpecialPromotionSettingCreateRequestData,
  SpecialPromotionSettingCreateSuccessData,
  SpecialPromotionSettingDetail,
  SpecialPromotionSettingListItem,
  SpecialPromotionSettingUpdateRequestData,
  SpecialPromotionSettingUpdateSuccessData,
} from './types';

export const listSpecialPromotionSettingApi =
  createListApi<SpecialPromotionSettingListItem>('/special_promotions/settings');
export const createSpecialPromotionSettingApi = createPostApi<
  SpecialPromotionSettingCreateRequestData,
  SpecialPromotionSettingCreateSuccessData
>('/special_promotions/settings');
export const readSpecialPromotionSettingApi = createReadApi<SpecialPromotionSettingDetail>(
  '/special_promotions/settings/:id'
);
export const updateSpecialPromotionSettingApi = createUpdateApi<
  SpecialPromotionSettingUpdateRequestData,
  SpecialPromotionSettingUpdateSuccessData
>('/special_promotions/settings/:id');
export const deleteSpecialPromotionSettingApi = createDeleteApi('/special_promotions/settings/:id');
export const refreshSpecialPromotionSettingApi = createPostApi('/gem_shops/refresh');
