import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  PromotionSettingCreateRequestData,
  PromotionSettingCreateResponseData,
  PromotionSettingDetail,
  PromotionSettingFormValues,
  PromotionSettingListItem,
  PromotionSettingUpdateRequestData,
  PromotionSettingUpdateResponseData,
} from './types';

export const listPromotionSettingApi = createListApi<PromotionSettingListItem>('/promotion/settings');

export const readPromotionSettingApi = createReadApi<PromotionSettingDetail>('/promotion/settings/:id');

export const createPromotionSettingApi = createPostApi<
  PromotionSettingFormValues,
  PromotionSettingCreateRequestData,
  PromotionSettingCreateResponseData
>('/promotion/settings');

export const updatePromotionSettingApi = createUpdateApi<
  PromotionSettingFormValues,
  PromotionSettingUpdateRequestData,
  PromotionSettingUpdateResponseData
>('/promotion/settings');

export const deletePromotionSettingApi = createDeleteApi('/promotion/settings/:id');
