import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createDeleteEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createWebDecoSetting,
  deleteWebDecoSetting,
  listWebDecoSetting,
  readWebDecoSetting,
  refreshWebDecoSetting,
  updateWebDecoSetting,
} from './actions';
import {
  createWebDecoSettingApi,
  deleteWebDecoSettingApi,
  listWebDecoSettingApi,
  readWebDecoSettingApi,
  refreshWebDecoSettingApi,
  updateWebDecoSettingApi,
} from './api';

const listWebDecoSettingEpic = createAsyncEpic(listWebDecoSetting, listWebDecoSettingApi);

const readWebDecoSettingEpic = createReadEpic(readWebDecoSetting, readWebDecoSettingApi);

const createWebDecoSettingEpic = createPostEpic(createWebDecoSetting, createWebDecoSettingApi);

const createWebDecoSettingToastEpic = createToastEpic(createWebDecoSetting.success, 'Successfully created');

const updateWebDecoSettingEpic = createUpdateEpic(updateWebDecoSetting, updateWebDecoSettingApi);

const updateWebDecoSettingToastEpic = createToastEpic(updateWebDecoSetting.success, 'Successfully updated');

const deleteWebDecoSettingEpic = createDeleteEpic(deleteWebDecoSetting, deleteWebDecoSettingApi);

const deleteWebDecoSettingToastEpic = createToastEpic(deleteWebDecoSetting.success, 'Successfully deleted');

const refreshWebDecoSettingEpic = createAsyncEpic(refreshWebDecoSetting, refreshWebDecoSettingApi);

const refreshWebDecoSettingToastEpic = createToastEpic(refreshWebDecoSetting.success, 'Successfully refreshed server');

export default combineEpics(
  listWebDecoSettingEpic,
  readWebDecoSettingEpic,
  createWebDecoSettingEpic,
  createWebDecoSettingToastEpic,
  updateWebDecoSettingEpic,
  updateWebDecoSettingToastEpic,
  deleteWebDecoSettingEpic,
  deleteWebDecoSettingToastEpic,
  refreshWebDecoSettingEpic,
  refreshWebDecoSettingToastEpic
);
