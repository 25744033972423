import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  DeleteSuccessPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  UserSegmentActions as Actions,
  NewUserSegment,
  UserSegment,
  UserSegmentCreateRequestData,
  UserSegmentCreateSuccessData,
  UserSegmentDetail,
  UserSegmentUpdateRequestData,
  UserSegmentUpdateSuccessData,
} from './types';

export const listUserSegment = createAsyncAction(
  Actions.LIST_USER_SEGMENTS_REQUEST,
  Actions.LIST_USER_SEGMENTS_SUCCESS,
  Actions.LIST_USER_SEGMENTS_FAILURE
)<ListRequestPayload, ListState<UserSegment>, AxiosError>();

export const listUserSegmentSearch = createAsyncAction(
  Actions.LIST_USER_SEGMENTS_SEARCH_REQUEST,
  Actions.LIST_USER_SEGMENTS_SEARCH_SUCCESS,
  Actions.LIST_USER_SEGMENTS_SEARCH_FAILURE
)<ListRequestPayload, ListState<NewUserSegment>, AxiosError>();

export const createUserSegment = createAsyncAction(
  Actions.CREATE_USER_SEGMENT_REQUEST,
  Actions.CREATE_USER_SEGMENT_SUCCESS,
  Actions.CREATE_USER_SEGMENT_FAILURE
)<FormRequestPayload<UserSegmentCreateRequestData>, UserSegmentCreateSuccessData, AxiosError>();

export const readUserSegment = createAsyncAction(
  Actions.READ_USER_SEGMENT_REQUEST,
  Actions.READ_USER_SEGMENT_SUCCESS,
  Actions.READ_USER_SEGMENT_FAILURE
)<ReadRequestPayload, UserSegmentDetail, AxiosError>();

export const updateUserSegment = createAsyncAction(
  Actions.UPDATE_USER_SEGMENT_REQUEST,
  Actions.UPDATE_USER_SEGMENT_SUCCESS,
  Actions.UPDATE_USER_SEGMENT_FAILURE
)<FormRequestPayload<UserSegmentUpdateRequestData>, UserSegmentUpdateSuccessData, AxiosError>();

export const deleteUserSegment = createAsyncAction(
  Actions.DELETE_USER_SEGMENT_REQUEST,
  Actions.DELETE_USER_SEGMENT_SUCCESS,
  Actions.DELETE_USER_SEGMENT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
