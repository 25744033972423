import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

import { MatchFilterConfigCreateRequestData, MatchFilterConfigUpdateRequestData } from './types';

export const createMatchFilterConfigApi = createPostApi<
  MatchFilterConfigCreateRequestData,
  MatchFilterConfigCreateRequestData
>('/match_filters/configs/:id');

export const updateMatchFilterConfigApi = createUpdateApi<
  MatchFilterConfigUpdateRequestData,
  MatchFilterConfigUpdateRequestData
>('/match_filters/configs/:id');

export const deleteMatchFilterConfigApi = createDeleteApi('/match_filters/configs/:id');
