import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import {
  VisitorConfig,
  VisitorConfigCreateRequestData,
  VisitorConfigCreateResponseData,
} from 'store/visitorConfig/types';

const BASE_PATH = '/visitors/configs';
export const createVisitorConfigApi = createPostApi<VisitorConfigCreateRequestData, VisitorConfigCreateResponseData>(
  BASE_PATH
);

export const readVisitorConfigApi = createReadApi<VisitorConfig>(`${BASE_PATH}/:id`);
export const listVisitorConfigApi = createListApi<VisitorConfig>(BASE_PATH);

export const updateVisitorConfigApi = createUpdateApi<VisitorConfigCreateRequestData, VisitorConfigCreateResponseData>(
  `${BASE_PATH}/:id`
);

export const deleteVisitorConfigApi = createDeleteApi(`${BASE_PATH}/:id`);
