import { createReducer } from 'typesafe-actions';
import { ShopModuleGroupState, initialState } from './types';
import { RootAction } from 'store/root/types';
import * as shopModuleGroupActions from './actions';

const shopModuleGroup = createReducer<ShopModuleGroupState, RootAction>(initialState)
  .handleAction(shopModuleGroupActions.listShopModuleGroup.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(shopModuleGroupActions.listShopModuleGroup.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(shopModuleGroupActions.readShopModuleGroup.success, (state, { payload }) => {
    payload.groups.sort((a, b) => a.priority - b.priority);
    return {
      ...state,
      detail: payload,
    };
  });

export default shopModuleGroup;
