import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createPromotionContent, listPromotionContent, readPromotionContent, updatePromotionContent } from './actions';
import {
  createPromotionContentApi,
  listPromotionContentApi,
  readPromotionContentApi,
  updatePromotionContentApi,
} from './api';

const listPromotionContentEpic = createListEpic(listPromotionContent, listPromotionContentApi);

const readPromotionContentEpic = createReadEpic(readPromotionContent, readPromotionContentApi);

const createPromotionContentEpic = createPostEpic(createPromotionContent, createPromotionContentApi);

const createPromotionContentToastEpic = createToastEpic(createPromotionContent.success, 'Successfully created');

const updatePromotionContentEpic = createUpdateEpic(updatePromotionContent, updatePromotionContentApi);

const updatePromotionContentToastEpic = createToastEpic(updatePromotionContent.success, 'Successfully saved');

export default combineEpics(
  listPromotionContentEpic,
  readPromotionContentEpic,
  createPromotionContentEpic,
  createPromotionContentToastEpic,
  updatePromotionContentEpic,
  updatePromotionContentToastEpic
);
