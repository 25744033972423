import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import { refreshGemSetting } from '../gemSetting/actions';

import {
  createUserSegment,
  deleteUserSegment,
  listUserSegment,
  listUserSegmentSearch,
  readUserSegment,
  updateUserSegment,
} from './actions';
import {
  createUserSegmentApi,
  deleteUserSegmentApi,
  listUserSegmentApi,
  listUserSegmentSearchApi,
  readUserSegmentApi,
  updateUserSegmentApi,
} from './api';

const updateUserSegmentToastEpic = createToastEpic(updateUserSegment.success, 'Successfully saved');

const createUserSegmentToastEpic = createToastEpic(createUserSegment.success, 'Successfully created');

const refreshGemSettingToastEpic = createToastEpic(refreshGemSetting.success, 'Successfully refreshed server');

const listUserSegmentEpic = createListEpic(listUserSegment, listUserSegmentApi);

const listUserSegmentSearchEpic = createListEpic(listUserSegmentSearch, listUserSegmentSearchApi);

const createUserSegmentEpic = createPostEpic(createUserSegment, createUserSegmentApi);

const readUserSegmentEpic = createReadEpic(readUserSegment, readUserSegmentApi);

const updateUserSegmentEpic = createUpdateEpic(updateUserSegment, updateUserSegmentApi);

const deleteUserSegmentEpic = createDeleteEpic(deleteUserSegment, deleteUserSegmentApi);

export default combineEpics(
  updateUserSegmentToastEpic,
  createUserSegmentToastEpic,
  refreshGemSettingToastEpic,
  listUserSegmentEpic,
  listUserSegmentSearchEpic,
  createUserSegmentEpic,
  readUserSegmentEpic,
  updateUserSegmentEpic,
  deleteUserSegmentEpic
);
