import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  DecoSettingActions as Actions,
  DecoSetting,
  DecoSettingCreateRequestData,
  DecoSettingCreateResponseData,
  DecoSettingDetail,
  DecoSettingFormValues,
  DecoSettingUpdateRequestData,
  DecoSettingUpdateResponseData,
} from './types';

export const listDecoSetting = createAsyncAction(
  Actions.LIST_DECO_SETTINGS_REQUEST,
  Actions.LIST_DECO_SETTINGS_SUCCESS,
  Actions.LIST_DECO_SETTINGS_FAILURE
)<ListRequestPayload, ListState<DecoSetting>, AxiosError>();

export const readDecoSetting = createAsyncAction(
  Actions.READ_DECO_SETTING_REQUEST,
  Actions.READ_DECO_SETTING_SUCCESS,
  Actions.READ_DECO_SETTING_FAILURE
)<ReadRequestPayload, DecoSettingDetail, AxiosError>();

export const createDecoSetting = createAsyncAction(
  Actions.CREATE_DECO_SETTING_REQUEST,
  Actions.CREATE_DECO_SETTING_SUCCESS,
  Actions.CREATE_DECO_SETTING_FAILURE
)<FormRequestPayload<DecoSettingFormValues, DecoSettingCreateRequestData>, DecoSettingCreateResponseData, AxiosError>();

export const updateDecoSetting = createAsyncAction(
  Actions.UPDATE_DECO_SETTING_REQUEST,
  Actions.UPDATE_DECO_SETTING_SUCCESS,
  Actions.UPDATE_DECO_SETTING_SUCCESS
)<FormRequestPayload<DecoSettingFormValues, DecoSettingUpdateRequestData>, DecoSettingUpdateResponseData, AxiosError>();

export const deleteDecoSetting = createAsyncAction(
  Actions.DELETE_DECO_SETTING_REQUEST,
  Actions.DELETE_DECO_SETTING_SUCCESS,
  Actions.DELETE_DECO_SETTING_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const refreshDecoSetting = createAsyncAction(
  Actions.REFRESH_DECO_SETTING_REQUEST,
  Actions.REFRESH_DECO_SETTING_SUCCESS,
  Actions.REFRESH_DECO_SETTING_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
