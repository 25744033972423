import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import { AbTestDetail, AbTestListItem, AbTestRequestData, AbTestResponseData } from './types';

export const createAbTestApi = createPostApi<AbTestRequestData, AbTestResponseData>('/abtests/:id');

export const updateAbTestApi = createUpdateApi<AbTestRequestData, AbTestRequestData, AbTestResponseData>(
  '/abtests/:id'
);

export const listAbTestApi = createListApi<AbTestListItem>('/abtests');

export const readAbTestApi = createReadApi<AbTestDetail>('/abtests/:id');
