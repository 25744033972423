import { RootAction } from 'store/root/types';
import { createReducer } from 'typesafe-actions';

import { initialState, ShopMainState } from './types';
import * as shopMainActions from './actions';

const shopMain = createReducer<ShopMainState, RootAction>(initialState)
  .handleAction(shopMainActions.listShopMainPage.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(shopMainActions.listShopMainPage.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(shopMainActions.readShopMainPage.success, (state, { payload }) => {
    return {
      ...state,
      detail: payload,
    };
  });

export default shopMain;
