export enum BlockPhoneManageActions {
  DELETE_BLOCK_PHONE_MANAGE_REQUEST = 'DELETE_BLOCK_PHONE_MANAGE#REQUEST',
  DELETE_BLOCK_PHONE_MANAGE_SUCCESS = 'DELETE_BLOCK_PHONE_MANAGE#SUCCESS',
  DELETE_BLOCK_PHONE_MANAGE_FAILURE = 'DELETE_BLOCK_PHONE_MANAGE#FAILURE',

  DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS_REQUEST = 'DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS#REQUEST',
  DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS_SUCCESS = 'DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS#SUCCESS',
  DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS_FAILURE = 'DELETE_BLOCK_PHONE_MANAGE_BY_USER_IDS#FAILURE',
}

export interface BlockPhoneManageTypeFormValues {
  type: BlockPhoneManageType;
}

export enum BlockPhoneManageType {
  BY_PHONE_NUMBERS = 'BY_PHONE_NUMBERS',
  BY_USER_IDS = 'BY_USER_IDS',
}

export interface BlockPhoneManageDeleteRequestFormValues {
  formattedPhoneNumbers: string;
}

export interface BlockPhoneManageDeleteRequestData {
  formattedPhoneNumbers: string[];
}

export interface BlockPhoneManageByUserIdsDeleteRequestFormValues {
  userIds: string;
}

export interface BlockPhoneManageByUserIdsDeleteRequestData {
  userIds: string[];
}
