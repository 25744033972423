import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createTeamMessageLinkSetting, updateTeamMessageLinkSetting } from './actions';
import { createTeamMessageLinkSettingApi, updateTeamMessageLinkSettingApi } from './api';

const createTeamMessageLinkSettingEpic = createPostEpic(createTeamMessageLinkSetting, createTeamMessageLinkSettingApi);

const createTeamMessageLinkSettingToastEpic = createToastEpic(
  createTeamMessageLinkSetting.success,
  'Successfully created'
);

const updateTeamMessageLinkSettingEpic = createUpdateEpic(
  updateTeamMessageLinkSetting,
  updateTeamMessageLinkSettingApi
);

const updateTeamMessageLinkSettingToastEpic = createToastEpic(
  updateTeamMessageLinkSetting.success,
  'Successfully saved'
);

export default combineEpics(
  createTeamMessageLinkSettingEpic,
  createTeamMessageLinkSettingToastEpic,
  updateTeamMessageLinkSettingEpic,
  updateTeamMessageLinkSettingToastEpic
);
