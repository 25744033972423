import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  NotificationCenterBannerScheduleActions as Actions,
  NotificationCenterBannerScheduleCreateRequestData,
  NotificationCenterBannerScheduleCreateSuccessData,
  NotificationCenterBannerScheduleUpdateRequestData,
  NotificationCenterBannerScheduleUpdateSuccessData,
} from './types';

export const createNotificationCenterBannerSchedule = createAsyncAction(
  Actions.CREATE_SCHEDULE_REQUEST,
  Actions.CREATE_SCHEDULE_SUCCESS,
  Actions.CREATE_SCHEDULE_FAILURE
)<
  FormRequestPayload<NotificationCenterBannerScheduleCreateRequestData>,
  NotificationCenterBannerScheduleCreateSuccessData,
  AxiosError
>();

export const updateNotificationCenterBannerSchedule = createAsyncAction(
  Actions.UPDATE_SCHEDULE_REQUEST,
  Actions.UPDATE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SCHEDULE_FAILURE
)<
  FormRequestPayload<NotificationCenterBannerScheduleUpdateRequestData>,
  NotificationCenterBannerScheduleUpdateSuccessData,
  AxiosError
>();

export const deleteNotificationCenterBannerSchedule = createAsyncAction(
  Actions.DELETE_SCHEDULE_REQUEST,
  Actions.DELETE_SCHEDULE_SUCCESS,
  Actions.DELETE_SCHEDULE_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
