import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
  createAsyncEpic,
} from 'store/utils';

import {
  createVisitorSegment,
  deleteVisitorSegment,
  listVisitorSegment,
  listVisitorSegmentSearch,
  listAllVisitorSegmentSearch,
  readVisitorSegment,
  updateVisitorSegment,
  refreshVisitorSegment,
} from './actions';
import {
  createVisitorSegmentApi,
  deleteVisitorSegmentApi,
  listVisitorSegmentApi,
  listVisitorSegmentSearchApi,
  listAllVisitorSegmentSearchApi,
  readVisitorSegmentApi,
  refreshVisitorSegmentApi,
  updateVisitorSegmentApi,
} from './api';

const updateVisitorSegmentToastEpic = createToastEpic(updateVisitorSegment.success, 'Successfully saved');

const createVisitorSegmentToastEpic = createToastEpic(createVisitorSegment.success, 'Successfully created');

const listVisitorSegmentEpic = createListEpic(listVisitorSegment, listVisitorSegmentApi);

const listVisitorSegmentSearchEpic = createListEpic(listVisitorSegmentSearch, listVisitorSegmentSearchApi);

const listAllVisitorSegmentSearchEpic = createListEpic(listAllVisitorSegmentSearch, listAllVisitorSegmentSearchApi);

const createVisitorSegmentEpic = createPostEpic(createVisitorSegment, createVisitorSegmentApi);

const readVisitorSegmentEpic = createReadEpic(readVisitorSegment, readVisitorSegmentApi);

const updateVisitorSegmentEpic = createUpdateEpic(updateVisitorSegment, updateVisitorSegmentApi);

const deleteVisitorSegmentEpic = createDeleteEpic(deleteVisitorSegment, deleteVisitorSegmentApi);

const refreshVisitorSegmentEpic = createAsyncEpic(refreshVisitorSegment, refreshVisitorSegmentApi);
const refreshVisitorSegmentToastEpic = createToastEpic(refreshVisitorSegment.success, 'Successfully refreshed server');

export default combineEpics(
  updateVisitorSegmentToastEpic,
  createVisitorSegmentToastEpic,
  listVisitorSegmentEpic,
  listVisitorSegmentSearchEpic,
  listAllVisitorSegmentSearchEpic,
  createVisitorSegmentEpic,
  readVisitorSegmentEpic,
  updateVisitorSegmentEpic,
  deleteVisitorSegmentEpic,
  refreshVisitorSegmentEpic,
  refreshVisitorSegmentToastEpic
);
