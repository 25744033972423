import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as agentManageActions from './actions';
import { AgentManageState, initialAgentManageState } from './types';

const agentManage = createReducer<AgentManageState, RootAction>(initialAgentManageState)
  .handleAction(agentManageActions.listAgentManage.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(agentManageActions.listAgentManage.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(agentManageActions.readAgentManage.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(agentManageActions.updateAgentManage.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default agentManage;
