import {
  SpecialPromotionSettingMapEntryCreateRequestData,
  SpecialPromotionSettingMapEntryCreateSuccessData,
  SpecialPromotionSettingMapEntryUpdateRequestData,
  SpecialPromotionSettingMapEntryUpdateSuccessData,
} from 'store/specialPromotionSettingMapEntry/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createSpecialPromotionSettingMapEntryApi = createPostApi<
  SpecialPromotionSettingMapEntryCreateRequestData,
  SpecialPromotionSettingMapEntryCreateSuccessData
>('/special_promotions/setting_map_entries');
export const updateSpecialPromotionSettingMapEntryApi = createUpdateApi<
  SpecialPromotionSettingMapEntryUpdateRequestData,
  SpecialPromotionSettingMapEntryUpdateSuccessData
>('/special_promotions/setting_map_entries/:id');
export const deleteSpecialPromotionSettingMapEntryApi = createDeleteApi('/special_promotions/setting_map_entries/:id');
