import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import {
  NotificationContent,
  NotificationContentCreateRequestData,
  NotificationContentCreateResponseData,
} from 'store/notificationContent/types';

const BASE_PATH = '/notifications/contents';
export const createNotificationContentApi = createPostApi<
  NotificationContentCreateRequestData,
  NotificationContentCreateResponseData
>(BASE_PATH);

export const readNotificationContentApi = createReadApi<NotificationContent>(`${BASE_PATH}/:id`);
export const listNotificationContentApi = createListApi<NotificationContent>(BASE_PATH);

export const updateNotificationContentApi = createUpdateApi<
  NotificationContentCreateRequestData,
  NotificationContentCreateResponseData
>(BASE_PATH);

export const refreshNotificationContentApi = createPostApi(`${BASE_PATH}/refresh`);
