import { DecoCategoryType, DecoResource } from 'store/decoResource/types';
import { DownloadableContent } from 'store/downloadableContent/types';
import { ItemProductListItem as BaseItemProduct } from 'store/itemProduct/types';
import { initialListState, ListState } from 'store/types';

export enum DecoItemActions {
  LIST_DECO_ITEMS_REQUEST = 'LIST_DECO_ITEMS#REQUEST',
  LIST_DECO_ITEMS_SUCCESS = 'LIST_DECO_ITEMS#SUCCESS',
  LIST_DECO_ITEMS_FAILURE = 'LIST_DECO_ITEMS#FAILURE',

  READ_DECO_ITEM_REQUEST = 'READ_DECO_ITEM#REQUEST',
  READ_DECO_ITEM_SUCCESS = 'READ_DECO_ITEM#SUCCESS',
  READ_DECO_ITEM_FAILURE = 'READ_DECO_ITEM#FAILURE',

  REFRESH_DECO_ITEM_REQUEST = 'REFRESH_DECO_ITEM#REQUEST',
  REFRESH_DECO_ITEM_SUCCESS = 'REFRESH_DECO_ITEM#SUCCESS',
  REFRESH_DECO_ITEM_FAILURE = 'REFRESH_DECO_ITEM#FAILURE',
}

export const initialState: DecoItemState = {
  list: initialListState,
  detail: undefined,
};

export interface DecoItemState {
  list: ListState<DecoItemListItem>;
  detail?: DecoItemDetail;
}

export type DecoItemProduct = BaseItemProduct;

export interface DecoItem {
  groupId: string;
  categoryId: string;
  decoCategory: DecoCategoryType;
  itemProducts?: DecoItemProduct[];
}

export type DecoItemListItem = DecoItem;

export interface DecoItemDetail extends DecoItem {
  resourceInfo?: DecoResource;
  downloadableContentInfo?: DownloadableContent;
}
