import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  WebDecoSettingScheduleActions as Actions,
  WebDecoSettingScheduleCreateRequestData,
  WebDecoSettingScheduleCreateResponseData,
  WebDecoSettingScheduleFormValues,
  WebDecoSettingScheduleUpdateRequestData,
  WebDecoSettingScheduleUpdateResponseData,
} from './types';

export const createWebDecoSettingSchedule = createAsyncAction(
  Actions.CREATE_WEB_DECO_SETTING_SCHEDULE_REQUEST,
  Actions.CREATE_WEB_DECO_SETTING_SCHEDULE_SUCCESS,
  Actions.CREATE_WEB_DECO_SETTING_SCHEDULE_FAILURE
)<
  FormRequestPayload<WebDecoSettingScheduleFormValues, WebDecoSettingScheduleCreateRequestData>,
  WebDecoSettingScheduleCreateResponseData,
  AxiosError
>();

export const updateWebDecoSettingSchedule = createAsyncAction(
  Actions.UPDATE_WEB_DECO_SETTING_SCHEDULE_REQUEST,
  Actions.UPDATE_WEB_DECO_SETTING_SCHEDULE_SUCCESS,
  Actions.UPDATE_WEB_DECO_SETTING_SCHEDULE_FAILURE
)<
  FormRequestPayload<WebDecoSettingScheduleFormValues, WebDecoSettingScheduleUpdateRequestData>,
  WebDecoSettingScheduleUpdateResponseData,
  AxiosError
>();

export const deleteWebDecoSettingSchedule = createAsyncAction(
  Actions.DELETE_WEB_DECO_SETTING_SCHEDULE_REQUEST,
  Actions.DELETE_WEB_DECO_SETTING_SCHEDULE_SUCCESS,
  Actions.DELETE_WEB_DECO_SETTING_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
