import React, { Suspense, useMemo } from 'react';
import { QueryClientProvider } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import Modal from 'components/Modal';
import Toast from 'components/Toast';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Confirm from 'components/Confirm';
import PrivateRoute from 'components/PrivateRoute';
import FullScreenModal from 'components/FullScreenModal';
import LoadingIndicator from 'components/LoadingIndicator';

import Main from 'pages/Main';
import Logout from 'pages/Logout';
import NotFoundPage from 'pages/NotFound';

import { WEB_TITLE } from 'constants/routes';
import { overrides, palettes, typography } from 'constants/themes';
import { ENVIRONMENT, IS_DEV, LOG_ROCKET_APP, RELEASE, SENTRY_DSN, SENTRY_TRACES_SAMPLE_RATE } from 'constants/env';

import PageInfoContext from 'contexts/pageInfoContext';

import useIsDarkMode from 'hooks/useIsDarkMode';

import { RootState } from 'store/root/types';

import { queryClient } from 'utils/apiHook';
import { oktaAuth, REALM } from 'utils/okta';
import { environmentBackgroundImage } from 'utils/backgroundImage';
import entryRoutes, { Route as RouteType, SubRoute } from 'utils/routes';

import { Security, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';

const subRoutes = entryRoutes
  .filter(({ realm }) => realm.includes(REALM))
  .map((route) => route.subRoutes)
  .reduce<SubRoute[]>((acc, cur) => acc.concat(cur), []);

const routes: RouteType[] = [
  ...subRoutes,
  {
    path: '/',
    Component: Main,
  },
];

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: RELEASE,
    tracesSampleRate: parseFloat(SENTRY_TRACES_SAMPLE_RATE),
  });
} else {
  // eslint-disable-next-line no-console
  console.log('Sentry DSN is not set');
}

if (!IS_DEV) {
  if (LOG_ROCKET_APP) {
    LogRocket.init(LOG_ROCKET_APP);
    if (SENTRY_DSN) {
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('LogRocket App is not set');
  }
}

const selector = ({ setting }: RootState) => setting;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    minWidth: '100vw',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const App: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { hasBackground, presets, currentPresetIndex } = useSelector(selector, shallowEqual);
  const environment = presets[currentPresetIndex].environment;

  const isDarkMode = useIsDarkMode();
  const backgroundImage = useMemo(
    () => environmentBackgroundImage(hasBackground, environment),
    [environment, hasBackground]
  );

  const paletteType = useMemo(() => (isDarkMode ? 'dark' : 'light'), [isDarkMode]);
  const theme = useMemo(
    () =>
      createMuiTheme({
        overrides,
        palette: {
          type: paletteType,
          ...palettes[paletteType],
        },
        typography,
      }),
    [paletteType]
  );

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <div className={classes.root} style={{ backgroundImage }}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <CssBaseline />
            <Sidebar />
            <Box className={classes.box}>
              <Header />
              <main className={classes.content}>
                <Suspense fallback={<LoadingIndicator fullWidth fullHeight />}>
                  <Switch>
                    <Route path='/login/callback' component={LoginCallback} />
                    <Route path='/logout' render={() => <Logout />} />
                    {routes.map((route) => (
                      <PrivateRoute
                        exact={route.path === '/' || route.exact}
                        key={route.path}
                        path={route.path}
                        render={() => (
                          <PageInfoContext.Provider value={route.pageInfo || { title: WEB_TITLE }}>
                            <route.Component />
                          </PageInfoContext.Provider>
                        )}
                      />
                    ))}

                    <Route path='*'>
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </Suspense>
              </main>
            </Box>
            <Toast />
            <Confirm />
            <Modal />
            <FullScreenModal />
          </Security>
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
