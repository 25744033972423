import { createDeleteApi, createListApi, createPostApi } from 'store/utils';

import { MatchFilterOptionListingListItem, MatchFilterOptionListingUpsertRequestData } from './types';

export const listMatchFilterOptionListingApi =
  createListApi<MatchFilterOptionListingListItem>('/match_filters/listings');

export const upsertMatchFilterOptionListingApi = createPostApi<
  MatchFilterOptionListingUpsertRequestData,
  MatchFilterOptionListingUpsertRequestData
>('/match_filters/listings/:id');

export const deleteMatchFilterOptionListingApi = createDeleteApi('/match_filters/listings/:id');
