export enum GemSettingProductActions {
  CREATE_GEM_SETTING_PRODUCT_REQUEST = 'CREATE_GEM_SETTING_PRODUCT#REQUEST',
  CREATE_GEM_SETTING_PRODUCT_SUCCESS = 'CREATE_GEM_SETTING_PRODUCT#SUCCESS',
  CREATE_GEM_SETTING_PRODUCT_FAILURE = 'CREATE_GEM_SETTING_PRODUCT#FAILURE',

  UPDATE_GEM_SETTING_PRODUCT_REQUEST = 'UPDATE_GEM_SETTING_PRODUCT#REQUEST',
  UPDATE_GEM_SETTING_PRODUCT_SUCCESS = 'UPDATE_GEM_SETTING_PRODUCT#SUCCESS',
  UPDATE_GEM_SETTING_PRODUCT_FAILURE = 'UPDATE_GEM_SETTING_PRODUCT#FAILURE',

  DELETE_GEM_SETTING_PRODUCT_REQUEST = 'DELETE_GEM_SETTING_PRODUCT#REQUEST',
  DELETE_GEM_SETTING_PRODUCT_SUCCESS = 'DELETE_GEM_SETTING_PRODUCT#SUCCESS',
  DELETE_GEM_SETTING_PRODUCT_FAILURE = 'DELETE_GEM_SETTING_PRODUCT#FAILURE',
}

export enum GemSettingProductType {
  GEM = 'GEM',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

interface GemSettingProductBase {
  id?: number;
  settingId: number;
  displayName: string;
  icon: number | string;
  productEndDate?: string;
  badgeInfo?: BadgeInfo;
  rowOrder?: number;
  columnOrder?: number;
  type: GemSettingProductType;
  backgroundColor: string;
  discountRate?: string;
  segmentCode?: string;
}

export interface GemSettingGemProduct extends GemSettingProductBase {
  googlePlayIabProductId: string;
  appStoreIabProductId: string;
  webProductId: string;
  baseGemIncrement: number;
  promoGemIncrement: number;
  bonusGemIncrement: number;
  pgBonusPercent?: number;
  limitKey?: -1 | number;
  maxPurchaseCount?: number;
  fallbackProductId?: number;
}

export interface GemSettingSubscriptionProduct extends GemSettingProductBase {
  googlePlayIabProductId: string;
  appStoreIabProductId: string;
  webProductId: string; // 실제로 사용 안함. GemSettingProduct 지원하기 위해서 추가
  deepLink: string;
}

export type GemSettingProduct = GemSettingGemProduct | GemSettingSubscriptionProduct;

export type GemSettingProductCreateRequestData = Omit<GemSettingProduct, 'id'>;
export type GemSettingProductCreateSuccessData = GemSettingProduct;
export type GemSettingProductUpdateRequestData = Omit<GemSettingProduct, 'id'>;
export type GemSettingProductUpdateSuccessData = GemSettingProduct;

export type BadgeType = 'SIMPLE_TEXT' | 'LIMITED_TIME';
export type BadgeInfo = {
  badgeType: BadgeType;
  text: string;
  mainColor: string;
  subColor: string;
};
