import { initialListState, ListState } from 'store/types';

export enum ExpressionSegmentActions {
  LIST_EXPRESSION_SEGMENTS_REQUEST = 'LIST_EXPRESSION_SEGMENTS#REQUEST',
  LIST_EXPRESSION_SEGMENTS_SUCCESS = 'LIST_EXPRESSION_SEGMENTS#SUCCESS',
  LIST_EXPRESSION_SEGMENTS_FAILURE = 'LIST_EXPRESSION_SEGMENTS#FAILURE',

  CREATE_EXPRESSION_SEGMENT_REQUEST = 'CREATE_EXPRESSION_SEGMENT#REQUEST',
  CREATE_EXPRESSION_SEGMENT_SUCCESS = 'CREATE_EXPRESSION_SEGMENT#SUCCESS',
  CREATE_EXPRESSION_SEGMENT_FAILURE = 'CREATE_EXPRESSION_SEGMENT#FAILURE',

  READ_EXPRESSION_SEGMENT_REQUEST = 'READ_EXPRESSION_SEGMENT#REQUEST',
  READ_EXPRESSION_SEGMENT_SUCCESS = 'READ_EXPRESSION_SEGMENT#SUCCESS',
  READ_EXPRESSION_SEGMENT_FAILURE = 'READ_EXPRESSION_SEGMENT#FAILURE',

  UPDATE_EXPRESSION_SEGMENT_REQUEST = 'UPDATE_EXPRESSION_SEGMENT#REQUEST',
  UPDATE_EXPRESSION_SEGMENT_SUCCESS = 'UPDATE_EXPRESSION_SEGMENT#SUCCESS',
  UPDATE_EXPRESSION_SEGMENT_FAILURE = 'UPDATE_EXPRESSION_SEGMENT#FAILURE',

  DELETE_EXPRESSION_SEGMENT_REQUEST = 'DELETE_EXPRESSION_SEGMENT#REQUEST',
  DELETE_EXPRESSION_SEGMENT_SUCCESS = 'DELETE_EXPRESSION_SEGMENT#SUCCESS',
  DELETE_EXPRESSION_SEGMENT_FAILURE = 'DELETE_EXPRESSION_SEGMENT#FAILURE',

  REFRESH_EXPRESSION_SEGMENT_REQUEST = 'REFRESH_EXPRESSION_SEGMENT#REQUEST',
  REFRESH_EXPRESSION_SEGMENT_SUCCESS = 'REFRESH_EXPRESSION_SEGMENT#SUCCESS',
  REFRESH_EXPRESSION_SEGMENT_FAILURE = 'REFRESH_EXPRESSION_SEGMENT#FAILURE',
}

export const initialState: ExpressionSegmentState = {
  list: initialListState,
  detail: undefined,
};

export interface ExpressionSegmentListItem extends ExpressionSegmentCreateRequestData {
  id: string;
  userSegmentInfo: {
    enabled: boolean;
    startDate: string;
    endDate: string;
  };
}

export type ExpressionSegmentDetail = ExpressionSegmentListItem;

export interface ExpressionSegmentState {
  list: ListState<ExpressionSegmentListItem>;
  detail?: ExpressionSegmentDetail;
}

export enum Platform {
  IOS = 'iOS',
  ANDROID = 'Android',
  WEBCLIENT = 'WEBCLIENT',
}

export enum Subscription {
  GEM_PLUS_LITE = 'GEM_PLUS_LITE',
  VIP = 'VIP',
  ASYNC_PREMIUM = 'ASYNC_PREMIUM',
}

export enum StorePlatform {
  GOOGLE_PLAY = 'GOOGLE_PLAY',
  APP_STORE = 'APP_STORE',
  PG = 'PG',
}

export enum LoginType {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  KAKAO = 'KAKAO',
  QQ = 'QQ',
  GOOGLE = 'GOOGLE',
  TWITTER = 'TWITTER',
  WEIBO = 'WEIBO',
  LINE = 'LINE',
  APPLE = 'APPLE',
  PHONE = 'PHONE',
}

export interface ExpressionSegmentCreateRequestFormValues {
  segmentCode: string;

  sessionSegmentCode?: string;

  language?: string;

  includeCountryCodes?: string;
  excludeCountryCodes?: string;

  gender?: string;
  excludeGender?: string;

  postProcessPlatform?: string;
  postExcludeProcessPlatform?: string;
  platform?: string;
  excludePlatform?: string;

  subscription?: string[];

  minGem?: string;
  maxGem?: string;

  birthStartDate?: string;
  birthEndDate?: string;

  minAge?: string;
  maxAge?: string;

  afterSignUpStartDate?: string;
  afterSignUpEndDate?: string;

  minAfterSignUpDay?: string;
  maxAfterSignUpDay?: string;

  lastLoginStartDate?: string;
  lastLoginEndDate?: string;

  minLastLoginDay?: string;
  maxLastLoginDay?: string;

  loginCount?: string;
  minLoginCount?: string;
  maxLoginCount?: string;

  minLastPurchaseDay?: string;
  maxLastPurchaseDay?: string;

  minSuspensionCount?: string;
  maxSuspensionCount?: string;

  minVersionApi?: string;

  minPurchaseCountTotal?: string;
  maxPurchaseCountTotal?: string;
  storePlatform?: string[];
  minPgPurchaseCount?: string;
  maxPgPurchaseCount?: string;
  minGooglePlayPurchaseCount?: string;
  maxGooglePlayPurchaseCount?: string;
  minAppStorePurchaseCount?: string;
  maxAppStorePurchaseCount?: string;

  loginType?: string;
  excludeLoginType?: string;

  postProcess: boolean;
  expression: string;
}

export type ExpressionSegmentCreateRequestData = Pick<
  ExpressionSegmentCreateRequestFormValues,
  'segmentCode' | 'postProcess' | 'expression' | 'storePlatform'
>;

export type ExpressionSegmentCreateSuccessData = ExpressionSegmentDetail;

export type ExpressionSegmentUpdateRequestFormValues = ExpressionSegmentCreateRequestFormValues;

export type ExpressionSegmentUpdateRequestData = ExpressionSegmentCreateRequestData;

export type ExpressionSegmentUpdateSuccessData = ExpressionSegmentDetail;
