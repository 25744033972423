import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import {
  PushContent,
  PushContentCreateRequestData,
  PushContentCreateResponseData,
  PushContentVariationCreateRequestData,
  PushContentVariationCreateResponseData,
  PushContentVariationFormValues,
  PushContentVariationUpdateRequestData,
  PushContentVariationUpdateResponseData,
} from 'store/pushContent/types';

const BASE_PATH = '/push/contents';
export const createPushContentApi = createPostApi<PushContentCreateRequestData, PushContentCreateResponseData>(
  BASE_PATH
);

export const readPushContentApi = createReadApi<PushContent>(`${BASE_PATH}/:id`);
export const listPushContentApi = createListApi<PushContent>(BASE_PATH);

export const updatePushContentApi = createUpdateApi<PushContentCreateRequestData, PushContentCreateResponseData>(
  BASE_PATH
);

export const refreshPushContentApi = createPostApi(`${BASE_PATH}/refresh`);

// - Variation -
export const createPushContentVariationApi = createPostApi<
  PushContentVariationFormValues,
  PushContentVariationCreateRequestData,
  PushContentVariationCreateResponseData
>(`${BASE_PATH}/:id/variations`);

export const updatePushContentVariationApi = createUpdateApi<
  PushContentVariationFormValues,
  PushContentVariationUpdateRequestData,
  PushContentVariationUpdateResponseData
>(`${BASE_PATH}/:id/variations/:variationId`);

export const deletePushContentVariationApi = createDeleteApi(`${BASE_PATH}/:id/variations/:variationId`);

export const readPushTypeApi = createReadApi<Array<String>>('/push/types');
