import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import { RemoteConfig, RemoteConfigCreateRequestData, RemoteConfigCreateResponseData } from 'store/remoteConfig/types';

const BASE_PATH = '/config/remote';
export const createRemoteConfigApi = createPostApi<RemoteConfigCreateRequestData, RemoteConfigCreateResponseData>(
  BASE_PATH
);

export const readRemoteConfigApi = createReadApi<RemoteConfig>(`${BASE_PATH}/:id`);
export const listRemoteConfigApi = createListApi<RemoteConfig>(BASE_PATH);

export const updateRemoteConfigApi = createUpdateApi<RemoteConfigCreateRequestData, RemoteConfigCreateResponseData>(
  `${BASE_PATH}/:id`
);

export const deleteRemoteConfigApi = createDeleteApi(`${BASE_PATH}/:id`);
