import { createDeleteBodyApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  PromotionScheduleTargetCreateRequestData,
  PromotionScheduleTargetCreateResponseData,
  PromotionScheduleTargetFormValues,
  PromotionScheduleTargetUpdateRequestData,
  PromotionScheduleTargetUpdateResponseData,
} from './types';

export const createPromotionScheduleTargetApi = createPostApi<
  PromotionScheduleTargetFormValues,
  PromotionScheduleTargetCreateRequestData,
  PromotionScheduleTargetCreateResponseData
>('/scheduler/schedules');

export const updatePromotionScheduleTargetApi = createUpdateApi<
  PromotionScheduleTargetFormValues,
  PromotionScheduleTargetUpdateRequestData,
  PromotionScheduleTargetUpdateResponseData
>('/scheduler/schedules');

export const deletePromotionScheduleTargetApi = createDeleteBodyApi('/scheduler/schedules');
