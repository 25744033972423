import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  PromotionSettingActions as Actions,
  PromotionSettingCreateRequestData,
  PromotionSettingCreateResponseData,
  PromotionSettingDetail,
  PromotionSettingFormValues,
  PromotionSettingListItem,
  PromotionSettingUpdateRequestData,
  PromotionSettingUpdateResponseData,
} from './types';

export const listPromotionSetting = createAsyncAction(
  Actions.LIST_PROMOTION_SETTINGS_REQUEST,
  Actions.LIST_PROMOTION_SETTINGS_SUCCESS,
  Actions.LIST_PROMOTION_SETTINGS_FAILURE
)<ListRequestPayload, ListState<PromotionSettingListItem>, AxiosError>();

export const readPromotionSetting = createAsyncAction(
  Actions.READ_PROMOTION_SETTING_REQUEST,
  Actions.READ_PROMOTION_SETTING_SUCCESS,
  Actions.READ_PROMOTION_SETTING_FAILURE
)<ReadRequestPayload, PromotionSettingDetail, AxiosError>();

export const createPromotionSetting = createAsyncAction(
  Actions.CREATE_PROMOTION_SETTING_REQUEST,
  Actions.CREATE_PROMOTION_SETTING_SUCCESS,
  Actions.CREATE_PROMOTION_SETTING_FAILURE
)<
  FormRequestPayload<PromotionSettingFormValues, PromotionSettingCreateRequestData>,
  PromotionSettingCreateResponseData,
  AxiosError
>();

export const updatePromotionSetting = createAsyncAction(
  Actions.UPDATE_PROMOTION_SETTING_REQUEST,
  Actions.UPDATE_PROMOTION_SETTING_SUCCESS,
  Actions.UPDATE_PROMOTION_SETTING_FAILURE
)<
  FormRequestPayload<PromotionSettingFormValues, PromotionSettingUpdateRequestData>,
  PromotionSettingUpdateResponseData,
  AxiosError
>();

export const deletePromotionSetting = createAsyncAction(
  Actions.DELETE_PROMOTION_SETTING_REQUEST,
  Actions.DELETE_PROMOTION_SETTING_SUCCESS,
  Actions.DELETE_PROMOTION_SETTING_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
