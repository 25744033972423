import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from '../types';

import { GemWithdrawActions as Actions, GemWithdrawRequestFormValues } from './types';

export const withdrawGem = createAsyncAction(
  Actions.WITHDRAW_GEM_REQUEST,
  Actions.WITHDRAW_GEM_SUCCESS,
  Actions.WITHDRAW_GEM_FAILURE
)<FormRequestPayload<GemWithdrawRequestFormValues>, undefined, AxiosError>();
