import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  NewVisitorSegment,
  VisitorSegment,
  VisitorSegmentCreateRequestData,
  VisitorSegmentCreateSuccessData,
  VisitorSegmentDetail,
  VisitorSegmentUpdateRequestData,
  VisitorSegmentUpdateSuccessData,
} from './types';

export const listVisitorSegmentApi = createListApi<VisitorSegment>('/visitors/segments');
export const listVisitorSegmentSearchApi = createListApi<NewVisitorSegment>('/visitors/segments');
export const listAllVisitorSegmentSearchApi = createListApi<NewVisitorSegment>('/visitors/segments', {
  params: {
    includeAbtestSegment: true,
  },
});

export const createVisitorSegmentApi = createPostApi<VisitorSegmentCreateRequestData, VisitorSegmentCreateSuccessData>(
  '/visitors/segments/segment'
);
export const readVisitorSegmentApi = createReadApi<VisitorSegmentDetail>('/visitors/segments/:id');
export const updateVisitorSegmentApi = createUpdateApi<
  VisitorSegmentUpdateRequestData,
  VisitorSegmentUpdateSuccessData
>('/visitors/segments/:id');
export const deleteVisitorSegmentApi = createDeleteApi('/visitors/segments/:id');

export const refreshVisitorSegmentApi = createPostApi(`/visitors/segments/refresh`);
