export enum FileUploadActions {
  RESET_FILE_UPLOAD = 'RESET_FILE_UPLOAD',

  CREATE_FILE_UPLOAD_REQUEST = 'CREATE_FILE_UPLOAD#REQUEST',
  CREATE_FILE_UPLOAD_SUCCESS = 'CREATE_FILE_UPLOAD#SUCCESS',
  CREATE_FILE_UPLOAD_FAILURE = 'CREATE_FILE_UPLOAD#FAILURE',
}

export const initialState: FileUploadState = {
  count: '',
  data: {
    ios: undefined,
    android: undefined,
  },
};

export interface FileUploadState {
  count: string;
  data?: any;
}

export interface FileResponse {
  type: string;
  fileUpload: FileUpload;
}

export interface FileUpload {
  url: string;
  encryptionKey: string;
  md5Hash: string;
}

export type FileUploadCreateRequestData = any;

export type FileUploadCreateSuccessData = FileResponse;
