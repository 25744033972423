import { SpecialPromotionSettingMapEntry } from 'store/specialPromotionSettingMapEntry/types';
import { SpecialPromotionSettingProduct } from 'store/specialPromotionSettingProduct/types';
import { initialListState, ListState } from 'store/types';

export enum SpecialPromotionSettingActions {
  LIST_SPECIAL_PROMOTION_SETTINGS_REQUEST = 'LIST_SPECIAL_PROMOTION_SETTINGS#REQUEST',
  LIST_SPECIAL_PROMOTION_SETTINGS_SUCCESS = 'LIST_SPECIAL_PROMOTION_SETTINGS#SUCCESS',
  LIST_SPECIAL_PROMOTION_SETTINGS_FAILURE = 'LIST_SPECIAL_PROMOTION_SETTINGS#FAILURE',

  CREATE_SPECIAL_PROMOTION_SETTING_REQUEST = 'CREATE_SPECIAL_PROMOTION_SETTING#REQUEST',
  CREATE_SPECIAL_PROMOTION_SETTING_SUCCESS = 'CREATE_SPECIAL_PROMOTION_SETTING#SUCCESS',
  CREATE_SPECIAL_PROMOTION_SETTING_FAILURE = 'CREATE_SPECIAL_PROMOTION_SETTING#FAILURE',

  READ_SPECIAL_PROMOTION_SETTING_REQUEST = 'READ_SPECIAL_PROMOTION_SETTING#REQUEST',
  READ_SPECIAL_PROMOTION_SETTING_SUCCESS = 'READ_SPECIAL_PROMOTION_SETTING#SUCCESS',
  READ_SPECIAL_PROMOTION_SETTING_FAILURE = 'READ_SPECIAL_PROMOTION_SETTING#FAILURE',

  UPDATE_SPECIAL_PROMOTION_SETTING_REQUEST = 'UPDATE_SPECIAL_PROMOTION_SETTING#REQUEST',
  UPDATE_SPECIAL_PROMOTION_SETTING_SUCCESS = 'UPDATE_SPECIAL_PROMOTION_SETTING#SUCCESS',
  UPDATE_SPECIAL_PROMOTION_SETTING_FAILURE = 'UPDATE_SPECIAL_PROMOTION_SETTING#FAILURE',

  DELETE_SPECIAL_PROMOTION_SETTING_REQUEST = 'DELETE_SPECIAL_PROMOTION_SETTING#REQUEST',
  DELETE_SPECIAL_PROMOTION_SETTING_SUCCESS = 'DELETE_SPECIAL_PROMOTION_SETTING#SUCCESS',
  DELETE_SPECIAL_PROMOTION_SETTING_FAILURE = 'DELETE_SPECIAL_PROMOTION_SETTING#FAILURE',

  SET_SPECIAL_PROMOTION_SETTING_PRODUCTS = 'SET_SPECIAL_PROMOTION_SETTING_PRODUCTS',

  REFRESH_SPECIAL_PROMOTION_SETTING_REQUEST = 'REFRESH_SPECIAL_PROMOTION_SETTING#REQUEST',
  REFRESH_SPECIAL_PROMOTION_SETTING_SUCCESS = 'REFRESH_SPECIAL_PROMOTION_SETTING#SUCCESS',
  REFRESH_SPECIAL_PROMOTION_SETTING_FAILURE = 'REFRESH_SPECIAL_PROMOTION_SETTING#FAILURE',
}

export const initialState: SpecialPromotionSettingState = {
  list: initialListState,
  detail: undefined,
};

export interface SpecialPromotionSettingListItem {
  id: number;
  name: string;
  titleKey: string;
  descriptionKey: string;
  backgroundImageUrl: string;
  overlayImageUrl: string;
  purchasableSegment: string;
  unpurchasableAlertKey: string;
  unpurchasableDeeplink: string;
  enabled: boolean;
  productCount: number;
  specialPromotionSettingMapEntries: SpecialPromotionSettingMapEntry[];
  specialPromotionSettingProducts: SpecialPromotionSettingProduct[];
}

export type SpecialPromotionSettingDetail = SpecialPromotionSettingListItem;

export interface SpecialPromotionSettingState {
  list: ListState<SpecialPromotionSettingListItem>;
  detail?: SpecialPromotionSettingDetail;
}

export interface SpecialPromotionSettingCreateRequestData {
  name: string;
  titleKey: string;
  descriptionKey: string;
  backgroundImageUrl: string;
  overlayImageUrl: string;
  purchasableSegment: string;
  unpurchasableAlertKey: string;
  unpurchasableDeeplink: string;
}

export interface SpecialPromotionSettingCreateRequestFormValues extends SpecialPromotionSettingCreateRequestData {
  unpurchasableAlertKeyAsString: boolean;
  descriptionKeyAsString: boolean;
  titleKeyAsString: boolean;
}

export type SpecialPromotionSettingCreateSuccessData = SpecialPromotionSettingDetail;

export interface SpecialPromotionSettingUpdateRequestData {
  name: string;
  titleKey: string;
  descriptionKey: string;
  backgroundImageUrl: string;
  overlayImageUrl: string;
  purchasableSegment: string;
  unpurchasableAlertKey: string;
  unpurchasableDeeplink: string;
}

export interface SpecialPromotionSettingUpdateRequestFormValues extends SpecialPromotionSettingUpdateRequestData {
  unpurchasableAlertKeyAsString: boolean;
  descriptionKeyAsString: boolean;
  titleKeyAsString: boolean;
}

export type SpecialPromotionSettingUpdateSuccessData = SpecialPromotionSettingDetail;
