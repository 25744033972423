import { createReducer } from 'typesafe-actions';

import { MlConfigFeatureSegment } from 'store/mlConfigFeatureSegment/types';

import * as mlConfigFeatureSegmentActions from '../mlConfigFeatureSegment/actions';
import { RootAction } from '../root/types';

import * as mlConfigFeatureActions from './actions';
import { initialState, MlConfigFeatureState } from './types';

// noinspection TypeScriptValidateJSTypes
const mlConfigFeature = createReducer<MlConfigFeatureState, RootAction>(initialState)
  .handleAction(mlConfigFeatureActions.listMlConfigFeature.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(mlConfigFeatureActions.listMlConfigFeature.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(mlConfigFeatureActions.readMlConfigFeature.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(mlConfigFeatureActions.updateMlConfigFeature.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(mlConfigFeatureSegmentActions.createMlConfigFeatureSegment.success, (state, { payload }) => {
    const segments = state?.detail?.segments ?? [];
    const newSegments = [...segments, payload];

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            segments: newSegments,
          }
        : undefined,
    };
  })
  .handleAction(mlConfigFeatureSegmentActions.updateMlConfigFeatureSegment.success, (state, { payload }) => {
    const segments = state?.detail?.segments ?? [];
    const updatedSegmentIndex = segments.findIndex((segment: MlConfigFeatureSegment) => segment.id === payload.id);

    if (updatedSegmentIndex !== -1) {
      segments[updatedSegmentIndex] = payload;
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            segments: segments,
          }
        : undefined,
    };
  })
  .handleAction(mlConfigFeatureSegmentActions.deleteMlConfigFeatureSegment.success, (state, { payload }) => {
    const segments = state?.detail?.segments ?? [];
    const newSegments = segments.filter((button: MlConfigFeatureSegment) => button.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            segments: newSegments,
          }
        : undefined,
    };
  });

export default mlConfigFeature;
