import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createTeamMatchCampaign,
  createTeamMatchContent,
  deleteTeamMatchCampaign,
  deleteTeamMatchContent,
  listTeamMatchCampaign,
  readTeamMatchCampaign,
  updateTeamMatchCampaign,
  updateTeamMatchContent,
} from './actions';
import {
  createTeamMatchCampaignApi,
  createTeamMatchContentApi,
  deleteTeamMatchCampaignApi,
  deleteTeamMatchContentApi,
  listTeamMatchCampaignApi,
  readTeamMatchCampaignApi,
  updateTeamMatchCampaignApi,
  updateTeamMatchContentApi,
} from './api';

const listTeamMatchCampaignEpic = createListEpic(listTeamMatchCampaign, listTeamMatchCampaignApi);

const readTeamMatchCampaignEpic = createReadEpic(readTeamMatchCampaign, readTeamMatchCampaignApi);

const createTeamMatchCampaignEpic = createPostEpic(createTeamMatchCampaign, createTeamMatchCampaignApi);

const createTeamMatchCampaignToastEpic = createToastEpic(createTeamMatchCampaign.success, 'Successfully created');

const updateTeamMatchCampaignEpic = createUpdateEpic(updateTeamMatchCampaign, updateTeamMatchCampaignApi);

const updateTeamMatchCampaignToastEpic = createToastEpic(updateTeamMatchCampaign.success, 'Successfully saved');

const deleteTeamMatchCampaignEpic = createDeleteEpic(deleteTeamMatchCampaign, deleteTeamMatchCampaignApi);

const deleteTeamMatchCampaignToastEpic = createToastEpic(deleteTeamMatchCampaign.success, 'Successfully deleted');

const createTeamMatchContentEpic = createPostEpic(createTeamMatchContent, createTeamMatchContentApi);

const createTeamMatchContentToastEpic = createToastEpic(createTeamMatchContent.success, 'Successfully created');

const updateTeamMatchContentEpic = createUpdateEpic(updateTeamMatchContent, updateTeamMatchContentApi);

const updateTeamMatchContentToastEpic = createToastEpic(updateTeamMatchContent.success, 'Successfully saved');

const deleteTeamMatchContentEpic = createDeleteEpic(deleteTeamMatchContent, deleteTeamMatchContentApi);

const deleteTeamMatchContentToastEpic = createToastEpic(deleteTeamMatchContent.success, 'Successfully deleted');

export default combineEpics(
  listTeamMatchCampaignEpic,
  readTeamMatchCampaignEpic,
  createTeamMatchCampaignEpic,
  createTeamMatchCampaignToastEpic,
  updateTeamMatchCampaignEpic,
  updateTeamMatchCampaignToastEpic,
  deleteTeamMatchCampaignEpic,
  deleteTeamMatchCampaignToastEpic,
  createTeamMatchContentEpic,
  createTeamMatchContentToastEpic,
  updateTeamMatchContentEpic,
  updateTeamMatchContentToastEpic,
  deleteTeamMatchContentEpic,
  deleteTeamMatchContentToastEpic
);
