import { createPostApi, createUpdateApi } from 'store/utils';

import {
  DecoResourceBulkUploadRequestData,
  DecoResourceBulkUploadResponseData,
  DecoResourceCreateRequestData,
  DecoResourceCreateResponseData,
  DecoResourceFormValues,
  DecoResourceUpdateRequestData,
  DecoResourceUpdateResponseData,
} from './types';

export const createDecoResourceApi = createPostApi<
  DecoResourceFormValues,
  DecoResourceCreateRequestData,
  DecoResourceCreateResponseData
>('/deco_resources');

export const updateDecoResourceApi = createUpdateApi<
  DecoResourceFormValues,
  DecoResourceUpdateRequestData,
  DecoResourceUpdateResponseData
>('/deco_resources');

export const bulkUploadDecoResourceApi = createPostApi<
  DecoResourceBulkUploadRequestData,
  DecoResourceBulkUploadRequestData,
  DecoResourceBulkUploadResponseData
>('/deco_resources/bulk_upload');
