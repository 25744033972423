import { RootAction } from 'store/root/types';
import { createReducer } from 'typesafe-actions';
import { ShopTabState, initialState } from './types';
import * as shopTabActions from './actions';

const shopModule = createReducer<ShopTabState, RootAction>(initialState)
  .handleAction(shopTabActions.listShopTab.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(shopTabActions.listShopTab.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(shopTabActions.readShopTab.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default shopModule;
