import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ConfigStoreActions as Actions,
  ConfigStoreDetail,
  ConfigStoreFormValues,
  ConfigStoreListItem,
  ConfigStoreRequestData,
  ConfigStoreResponseData,
} from './types';

export const listConfigStore = createAsyncAction(
  Actions.UPDATE_CONFIG_STORE_REQUEST,
  Actions.UPDATE_CONFIG_STORE_SUCCESS,
  Actions.UPDATE_CONFIG_STORE_FAILURE
)<ListRequestPayload, ListState<ConfigStoreListItem>, AxiosError>();

export const updateConfigStore = createAsyncAction(
  Actions.UPDATE_CONFIG_STORE_MAP_REQUEST,
  Actions.UPDATE_CONFIG_STORE_MAP_SUCCESS,
  Actions.UPDATE_CONFIG_STORE_MAP_FAILURE
)<FormRequestPayload<ConfigStoreFormValues, ConfigStoreRequestData>, undefined, AxiosError>();

export const readConfigStore = createAsyncAction(
  Actions.READ_CONFIG_STORE_REQUEST,
  Actions.READ_CONFIG_STORE_SUCCESS,
  Actions.READ_CONFIG_STORE_FAILURE
)<ReadRequestPayload, ConfigStoreDetail, AxiosError>();

export const createConfigStore = createAsyncAction(
  Actions.CREATE_CONFIG_STORE_MAP_REQUEST,
  Actions.CREATE_CONFIG_STORE_MAP_SUCCESS,
  Actions.CREATE_CONFIG_STORE_MAP_FAILURE
)<FormRequestPayload<ConfigStoreFormValues, ConfigStoreRequestData>, ConfigStoreResponseData, AxiosError>();

export const refreshConfigStore = createAsyncAction(
  Actions.REFRESH_CONFIG_STORE_REQUEST,
  Actions.REFRESH_CONFIG_STORE_SUCCESS,
  Actions.REFRESH_CONFIG_STORE_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();

export const deleteConfigStore = createAsyncAction(
  Actions.DELETE_CONFIG_STORE_REQUEST,
  Actions.DELETE_CONFIG_STORE_SUCCESS,
  Actions.DELETE_CONFIG_STORE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
