import { createFlatListApi, createListApi, createReadApi } from 'store/utils';
import { MatataInvalidatedPurchaseBySender } from 'store/matataInvalidatedPurchaseBySender/types';

const BASE_PATH = '/invalidated-purchases/matata/aggregate/by-sender';

export const readMatataInvalidatedPurchaseBySenderApi = createReadApi<MatataInvalidatedPurchaseBySender>(
  `${BASE_PATH}/:id`
);
export const listMatataInvalidatedPurchaseBySenderApi = createListApi<MatataInvalidatedPurchaseBySender>(BASE_PATH);

export const downloadMatataInvalidatedPurchaseBySenderApi = createFlatListApi<MatataInvalidatedPurchaseBySender>(
  `${BASE_PATH}/list-all`
);
