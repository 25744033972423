import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import { FriendshipActions as Actions, FriendshipCreateRequestData } from './types';

export const createFriendship = createAsyncAction(
  Actions.CREATE_FRIENDSHIP_REQUEST,
  Actions.CREATE_FRIENDSHIP_SUCCESS,
  Actions.CREATE_FRIENDSHIP_FAILURE
)<FormRequestPayload<FriendshipCreateRequestData>, undefined, AxiosError>();
