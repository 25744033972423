import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import { WaterSlideBannerActions as Actions, WaterSlideBanner, WaterSlideBannerCreateRequestData } from './types';

export const createWaterSlideBanner = createAsyncAction(
  Actions.CREATE_WATER_SLIDE_BANNER_REQUEST,
  Actions.CREATE_WATER_SLIDE_BANNER_SUCCESS,
  Actions.CREATE_WATER_SLIDE_BANNER_FAILURE
)<FormRequestPayload<WaterSlideBannerCreateRequestData>, WaterSlideBannerCreateRequestData, AxiosError>();

export const readWaterSlideBanner = createAsyncAction(
  Actions.READ_WATER_SLIDE_BANNER_REQUEST,
  Actions.READ_WATER_SLIDE_BANNER_SUCCESS,
  Actions.READ_WATER_SLIDE_BANNER_FAILURE
)<ReadRequestPayload, WaterSlideBanner, AxiosError>();

export const updateWaterSlideBanner = createAsyncAction(
  Actions.UPDATE_WATER_SLIDE_BANNER_REQUEST,
  Actions.UPDATE_WATER_SLIDE_BANNER_SUCCESS,
  Actions.UPDATE_WATER_SLIDE_BANNER_FAILURE
)<FormRequestPayload<WaterSlideBannerCreateRequestData>, WaterSlideBannerCreateRequestData, AxiosError>();

export const listWaterSlideBanner = createAsyncAction(
  Actions.LIST_WATER_SLIDE_BANNER_REQUEST,
  Actions.LIST_WATER_SLIDE_BANNER_SUCCESS,
  Actions.LIST_WATER_SLIDE_BANNER_FAILURE
)<ListRequestPayload, ListState<WaterSlideBanner>, AxiosError>();

export const deleteWaterSlideBanner = createAsyncAction(
  Actions.DELETE_WATER_SLIDE_BANNER_REQUEST,
  Actions.DELETE_WATER_SLIDE_BANNER_SUCCESS,
  Actions.DELETE_WATER_SLIDE_BANNER_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const refreshWaterSlideBanner = createAsyncAction(
  Actions.REFRESH_WATER_SLIDE_BANNER_REQUEST,
  Actions.REFRESH_WATER_SLIDE_BANNER_SUCCESS,
  Actions.REFRESH_WATER_SLIDE_BANNER_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
