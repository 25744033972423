import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { updateAgeManage } from './actions';
import { updateAgeManageApi } from './api';

const updateAgeManageToastEpic = createToastEpic(updateAgeManage.success, 'Successfully saved');

const updateAgeManageEpic = createUpdateEpic(updateAgeManage, updateAgeManageApi);

export default combineEpics(updateAgeManageToastEpic, updateAgeManageEpic);
