export enum TeamMessageLinkSettingActions {
  CREATE_TEAM_MESSAGE_LINK_SETTING_REQUEST = 'CREATE_TEAM_MESSAGE_LINK_SETTING#REQUEST',
  CREATE_TEAM_MESSAGE_LINK_SETTING_SUCCESS = 'CREATE_TEAM_MESSAGE_LINK_SETTING#SUCCESS',
  CREATE_TEAM_MESSAGE_LINK_SETTING_FAILURE = 'CREATE_TEAM_MESSAGE_LINK_SETTING#FAILURE',

  UPDATE_TEAM_MESSAGE_LINK_SETTING_REQUEST = 'UPDATE_TEAM_MESSAGE_LINK_SETTING#REQUEST',
  UPDATE_TEAM_MESSAGE_LINK_SETTING_SUCCESS = 'UPDATE_TEAM_MESSAGE_LINK_SETTING#SUCCESS',
  UPDATE_TEAM_MESSAGE_LINK_SETTING_FAILURE = 'UPDATE_TEAM_MESSAGE_LINK_SETTING#FAILURE',
}

export interface TeamMessageLinkSetting {
  linkUrl: string;
}

export type TeamMessageLinkSettingFormValues = TeamMessageLinkSetting;

export type TeamMessageLinkSettingCreateRequestData = TeamMessageLinkSettingFormValues;

export type TeamMessageLinkSettingCreateResponseData = TeamMessageLinkSettingFormValues;

export type TeamMessageLinkSettingUpdateRequestData = TeamMessageLinkSettingFormValues;

export type TeamMessageLinkSettingUpdateResponseData = TeamMessageLinkSettingFormValues;
