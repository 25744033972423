import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  SimulationUploadActions as Actions,
  SimulationUploadCreateRequestData,
  SimulationUploadCreateSuccessData,
} from './types';

export const createSimulationUpload = createAsyncAction(
  Actions.CREATE_SIMULATION_UPLOAD_REQUEST,
  Actions.CREATE_SIMULATION_UPLOAD_SUCCESS,
  Actions.CREATE_SIMULATION_UPLOAD_FAILURE
)<
  FormRequestPayload<SimulationUploadCreateRequestData, SimulationUploadCreateRequestData>,
  SimulationUploadCreateSuccessData,
  AxiosError
>();

export const resetSimulationUpload = createAction(Actions.RESET_SIMULATION_UPLOAD)<string>();
