import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import {
  ShopTabScheduleCreateRequestData,
  ShopTabScheduleCreateResponseData,
  ShopTabScheduleFormValues,
  ShopTabScheduleUpdateRequestData,
  ShopTabScheduleUpdateResponseData,
} from './types';

export const createShopTabApi = createPostApi('/shop_tabs');
export const listShopTabApi = createListApi('/shop_tabs');
export const readShopTabApi = createReadApi('/shop_tabs/:id');
export const deleteShopTabApi = createDeleteApi('/shop_tabs/:id');
export const updateShopTabApi = createUpdateApi('/shop_tabs/:id');

// - SCHEDULE -
export const createShopTabPageScheduleApi = createPostApi<
  ShopTabScheduleFormValues,
  ShopTabScheduleCreateRequestData,
  ShopTabScheduleCreateResponseData
>('/shop_tabs/schedules');

export const updateShopTabPageScheduleApi = createUpdateApi<
  ShopTabScheduleFormValues,
  ShopTabScheduleUpdateRequestData,
  ShopTabScheduleUpdateResponseData
>('/shop_tabs/schedules/:id');

export const deleteShopTabPageScheduleApi = createDeleteApi('/shop_tabs/schedules/:id');
