import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  PromotionScheduleTestActions as Actions,
  PromotionScheduleTestCreateRequestData,
  PromotionScheduleTestCreateResponseData,
  PromotionScheduleTestFormValues,
} from './types';

export const createPromotionScheduleTest = createAsyncAction(
  Actions.CREATE_PROMOTION_SCHEDULE_TEST_REQUEST,
  Actions.CREATE_PROMOTION_SCHEDULE_TEST_SUCCESS,
  Actions.CREATE_PROMOTION_SCHEDULE_TEST_FAILURE
)<
  FormRequestPayload<PromotionScheduleTestFormValues, PromotionScheduleTestCreateRequestData>,
  PromotionScheduleTestCreateResponseData,
  AxiosError
>();
