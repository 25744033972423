import {
  createDeleteApi,
  createDeleteBodyApi,
  createListApi,
  createPostApi,
  createReadApi,
  createUpdateApi,
} from 'store/utils';
import {
  ShopDetailDetail,
  ShopDetailListItem,
  ShopDetailPageFormValues,
  ShopDetailPageRequestData,
  ShopDetailPageResponseData,
  ShopDetailScheduleCreateRequestData,
  ShopDetailScheduleCreateResponseData,
  ShopDetailScheduleFormValues,
  ShopDetailScheduleUpdateRequestData,
  ShopDetailScheduleUpdateResponseData,
  ShopDetailSectionBulkCreateRequestData,
  ShopDetailSectionFormValue,
  ShopDetailSectionResponseData,
} from './types';

export const createShopDetailApi = createPostApi<
  ShopDetailPageFormValues,
  ShopDetailPageRequestData,
  ShopDetailPageResponseData
>('/shop_pages');

export const updateShopDetailApi = createUpdateApi<ShopDetailPageRequestData, undefined>('/shop_pages');

export const listShopDetailApi = createListApi<ShopDetailListItem>('/shop_pages', {
  params: {
    detailPage: true,
  },
});
export const readShopDetailApi = createReadApi<ShopDetailDetail>('/shop_pages/:id');
export const deleteShopDetailApi = createDeleteApi('/shop_pages/:id');

// - SECTION -
export const connectShopDetailSectionApi = createPostApi<ShopDetailSectionResponseData>('/shop_sections/links');
export const editShopDetailSectionApi = createUpdateApi<ShopDetailSectionFormValue, ShopDetailSectionResponseData>(
  '/shop_sections/links/:linkId'
);
export const disconnectShopDetailSectionApi = createDeleteApi('/shop_sections/links/:linkId');

// - SECTION BULK -
export const bulkCreateShopDetailSectionApi =
  createPostApi<ShopDetailSectionBulkCreateRequestData>('/shop_sections/links/bulk');

export const bulkDeleteShopDetailSectionApi = createDeleteBodyApi('/shop_sections/links/bulk');

// - SCHEDULE -
export const createShopDetailPageScheduleApi = createPostApi<
  ShopDetailScheduleFormValues,
  ShopDetailScheduleCreateRequestData,
  ShopDetailScheduleCreateResponseData
>('/shop_pages/schedules');

export const updateShopDetailPageScheduleApi = createUpdateApi<
  ShopDetailScheduleFormValues,
  ShopDetailScheduleUpdateRequestData,
  ShopDetailScheduleUpdateResponseData
>('/shop_pages/schedules/:id');

export const deleteShopDetailPageScheduleApi = createDeleteApi('/shop_pages/schedules/:id');
