import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { updateCountryManage } from './actions';
import { updateCountryManageApi } from './api';

const updateCountryManageToastEpic = createToastEpic(updateCountryManage.success, 'Successfully updated');

const updateCountryManageEpic = createUpdateEpic(updateCountryManage, updateCountryManageApi);

export default combineEpics(updateCountryManageToastEpic, updateCountryManageEpic);
