import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  BannerSettingCreateRequestData,
  BannerSettingCreateSuccessData,
  BannerSettingDetail,
  BannerSettingListItem,
  BannerSettingUpdateRequestData,
  BannerSettingUpdateSuccessData,
} from './types';

export const listBannerSettingApi = createListApi<BannerSettingListItem>('/gem_shops/banner_info');
export const createBannerSettingApi = createPostApi<BannerSettingCreateRequestData, BannerSettingCreateSuccessData>(
  '/gem_shops/banner_info'
);
export const readBannerSettingApi = createReadApi<BannerSettingDetail>('/gem_shops/banner_info/:id');
export const updateBannerSettingApi = createUpdateApi<BannerSettingUpdateRequestData, BannerSettingUpdateSuccessData>(
  '/gem_shops/banner_info/:id'
);
export const deleteBannerSettingApi = createDeleteApi('/gem_shops/banner_info/:id');
