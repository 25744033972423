import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  WebDecoSettingEffectCreateRequestData,
  WebDecoSettingEffectCreateResponseData,
  WebDecoSettingEffectFormValues,
  WebDecoSettingEffectUpdateRequestData,
  WebDecoSettingEffectUpdateResponseData,
} from './types';

import { WEB_DECO_SETTING_API_PREFIX } from '../webDecoSetting/types';

const WEB_DECO_SETTING_EFFECT_API_PREFIX = `${WEB_DECO_SETTING_API_PREFIX}/effects`;

export const createWebDecoSettingEffectApi = createPostApi<
  WebDecoSettingEffectFormValues,
  WebDecoSettingEffectCreateRequestData,
  WebDecoSettingEffectCreateResponseData
>(WEB_DECO_SETTING_EFFECT_API_PREFIX);

export const updateWebDecoSettingEffectApi = createUpdateApi<
  WebDecoSettingEffectFormValues,
  WebDecoSettingEffectUpdateRequestData,
  WebDecoSettingEffectUpdateResponseData
>(`${WEB_DECO_SETTING_EFFECT_API_PREFIX}/:id`);

export const deleteWebDecoSettingEffectApi = createDeleteApi(`${WEB_DECO_SETTING_EFFECT_API_PREFIX}/:id`);
