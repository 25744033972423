import { createAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  BookmarkActions as Actions,
  AddBookmarkPayload,
  RemoveBookmarkPayload,
  RenameBookmarkPayload,
  ReorderBookmarksPayload,
  SetBookmarkPayload,
} from './types';

export const setBookmark = createAction(Actions.SET_BOOKMARK)<FormRequestPayload<SetBookmarkPayload>>();

export const reorderBookmarks = createAction(Actions.REORDER_BOOKMARKS)<ReorderBookmarksPayload>();

export const addBookmark = createAction(Actions.ADD_BOOKMARK)<AddBookmarkPayload>();

export const renameBookmark = createAction(Actions.RENAME_BOOKMARKS)<RenameBookmarkPayload>();

export const removeBookmark = createAction(Actions.REMOVE_BOOKMARK)<RemoveBookmarkPayload>();
