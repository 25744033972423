import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import { matchingApiRequest } from 'utils/axios';

import {
  TeamMatchProfileCreateRequestData,
  TeamMatchProfileCreateResponseData,
  TeamMatchProfileDetail,
  TeamMatchProfileFormValues,
  TeamMatchProfileListItem,
  TeamMatchProfileUpdateRequestData,
  TeamMatchProfileUpdateResponseData,
} from './types';

export const listTeamMatchProfileApi = createListApi<TeamMatchProfileListItem>(
  '/api/v1/team-match/profiles',
  {},
  {},
  { apiRequest: matchingApiRequest }
);

export const readTeamMatchProfileApi = createReadApi<TeamMatchProfileDetail>(
  '/api/v1/team-match/profiles/:id',
  {},
  { apiRequest: matchingApiRequest }
);

export const createTeamMatchProfileApi = createPostApi<
  TeamMatchProfileFormValues,
  TeamMatchProfileCreateRequestData,
  TeamMatchProfileCreateResponseData
>('/api/v1/team-match/profiles', {}, { apiRequest: matchingApiRequest });

export const updateTeamMatchProfileApi = createUpdateApi<
  TeamMatchProfileFormValues,
  TeamMatchProfileUpdateRequestData,
  TeamMatchProfileUpdateResponseData
>('/api/v1/team-match/profiles/:id', {}, { apiRequest: matchingApiRequest });

export const deleteTeamMatchProfileApi = createDeleteApi(
  '/api/v1/team-match/profiles/:id',
  {},
  { apiRequest: matchingApiRequest }
);
