import { combineEpics } from 'redux-observable';
import { createAsyncEpic, createDeleteBodyEpic, createPostEpic, createToastEpic } from 'store/utils';

import {
  bulkCreateShopDetailSections,
  bulkDeleteShopDetailSections,
  connectShopDetailSection,
  createShopDetailPage,
  createShopDetailSchedule,
  deleteShopDetailPage,
  deleteShopDetailSchedule,
  disconnectShopDetailSection,
  editShopDetailSection,
  listShopDetailPage,
  readShopDetailPage,
  updateShopDetailPage,
  updateShopDetailSchedule,
} from './actions';
import {
  bulkCreateShopDetailSectionApi,
  bulkDeleteShopDetailSectionApi,
  connectShopDetailSectionApi,
  createShopDetailApi,
  createShopDetailPageScheduleApi,
  deleteShopDetailApi,
  deleteShopDetailPageScheduleApi,
  disconnectShopDetailSectionApi,
  editShopDetailSectionApi,
  listShopDetailApi,
  readShopDetailApi,
  updateShopDetailApi,
  updateShopDetailPageScheduleApi,
} from './api';

const listShopDetailEpic = createAsyncEpic(listShopDetailPage, listShopDetailApi);
const createShopDetailEpic = createAsyncEpic(createShopDetailPage, createShopDetailApi);
const readShopDetailEpic = createAsyncEpic(readShopDetailPage, readShopDetailApi);
const deleteShopDetailEpic = createAsyncEpic(deleteShopDetailPage, deleteShopDetailApi);
const updateShopDetailEpic = createAsyncEpic(updateShopDetailPage, updateShopDetailApi);
const updateShopDetailToastEpic = createToastEpic(updateShopDetailPage.success, 'Successfully updated');

// - Section -
const connectShopDetailSectionEpic = createAsyncEpic(connectShopDetailSection, connectShopDetailSectionApi);
const editShopDetailSectionEpic = createAsyncEpic(editShopDetailSection, editShopDetailSectionApi);
const disconnectShopDetailSectionEpic = createAsyncEpic(disconnectShopDetailSection, disconnectShopDetailSectionApi);

// - Section Bulk -
const bulkCreateShopDetailSectionEpic = createPostEpic(bulkCreateShopDetailSections, bulkCreateShopDetailSectionApi);

const bulkCreateShopDetailSectionToastEpic = createToastEpic(
  bulkCreateShopDetailSections.success,
  'Successfully copy from'
);
const bulkCreateFailureShopDetailSectionToastEpic = createToastEpic(
  bulkCreateShopDetailSections.failure,
  'Failed to copy sections'
);

const bulkDeleteShopDetailSectionEpic = createDeleteBodyEpic(
  bulkDeleteShopDetailSections,
  bulkDeleteShopDetailSectionApi
);

// - Schedule -
const createShopDetailScheduleEpic = createAsyncEpic(createShopDetailSchedule, createShopDetailPageScheduleApi);
const editShopDetailScheduleEpic = createAsyncEpic(updateShopDetailSchedule, updateShopDetailPageScheduleApi);
const deleteShopDetailScheduleEpic = createAsyncEpic(deleteShopDetailSchedule, deleteShopDetailPageScheduleApi);

export default combineEpics(
  createShopDetailEpic,
  listShopDetailEpic,
  readShopDetailEpic,
  deleteShopDetailEpic,
  updateShopDetailEpic,
  updateShopDetailToastEpic,
  //section
  connectShopDetailSectionEpic,
  editShopDetailSectionEpic,
  disconnectShopDetailSectionEpic,
  //section bulk
  bulkCreateShopDetailSectionEpic,
  bulkCreateShopDetailSectionToastEpic,
  bulkCreateFailureShopDetailSectionToastEpic,
  bulkDeleteShopDetailSectionEpic,
  //schedule
  createShopDetailScheduleEpic,
  editShopDetailScheduleEpic,
  deleteShopDetailScheduleEpic
);
