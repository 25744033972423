import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as MoniNoticeActions from './actions';
import { initialState, MoniNoticeState } from './types';

const moniNotice = createReducer<MoniNoticeState, RootAction>(initialState)
  .handleAction(MoniNoticeActions.listMoniNotice.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(MoniNoticeActions.detailMoniNotice.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default moniNotice;
