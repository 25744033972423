export enum NoticePopupButtonActions {
  CREATE_BUTTON_REQUEST = 'CREATE_NOTICE_POPUP_BUTTON#REQUEST',
  CREATE_BUTTON_SUCCESS = 'CREATE_NOTICE_POPUP_BUTTON#SUCCESS',
  CREATE_BUTTON_FAILURE = 'CREATE_NOTICE_POPUP_BUTTON#FAILURE',

  UPDATE_BUTTON_REQUEST = 'UPDATE_NOTICE_POPUP_BUTTON#REQUEST',
  UPDATE_BUTTON_SUCCESS = 'UPDATE_NOTICE_POPUP_BUTTON#SUCCESS',
  UPDATE_BUTTON_FAILURE = 'UPDATE_NOTICE_POPUP_BUTTON#FAILURE',

  DELETE_BUTTON_REQUEST = 'DELETE_NOTICE_POPUP_BUTTON#REQUEST',
  DELETE_BUTTON_SUCCESS = 'DELETE_NOTICE_POPUP_BUTTON#SUCCESS',
  DELETE_BUTTON_FAILURE = 'DELETE_NOTICE_POPUP_BUTTON#FAILURE',
}

export enum NoticePopupButtonType {
  LINK = 'LINK',
  PRODUCT = 'PRODUCT',
  PURCHASABLE = 'PURCHASABLE',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface NoticePopupButton {
  backgroundColor: string;
  buttonType: NoticePopupButtonType;
  deepLink: string;
  displayOrder: 1 | 2;
  id: number;
  noticePopupProduct: NoticePopupProduct;
  popupButtonLabel: PopupButtonLabel;
  settingId: number;
  darkModeBackgroundColor: string;
}

export interface NoticePopupProduct {
  generalProductId: string;
  itemProductId: string;
}

interface Product {
  product: NoticePopupProduct;
}

export interface PopupButtonLabel {
  label: string;
  labelColor: string;
  darkModeLabelColor: string;
}

export interface StringLocalizationFormValues {
  labelAsString: boolean;
}

type Dig<T, U extends keyof T> = Pick<T, U>[U];
export type NoticePopupButtonCreateRequestData = Omit<
  NoticePopupButton,
  'id' | 'popupButtonLabel' | 'noticePopupProduct'
> &
  Dig<NoticePopupButton, 'popupButtonLabel'> &
  Product;
export type NoticePopupButtonCreateFormValues = NoticePopupButtonCreateRequestData & StringLocalizationFormValues;
export type NoticePopupButtonCreateSuccessData = NoticePopupButton;

export type NoticePopupButtonUpdateRequestData = Omit<
  NoticePopupButton,
  'id' | 'popupButtonLabel' | 'noticePopupProduct'
> &
  Dig<NoticePopupButton, 'popupButtonLabel'> &
  Product;
export type NoticePopupButtonUpdateSuccessData = NoticePopupButton;
export type NoticePopupButtonUpdateFormValues = NoticePopupButtonUpdateRequestData & StringLocalizationFormValues;
