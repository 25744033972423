import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  NotificationCenterBannerActions as Actions,
  NotificationCenterBannerCreateRequestData,
  NotificationCenterBannerCreateRequestFormValues,
  NotificationCenterBannerCreateSuccessData,
  NotificationCenterBannerDetail,
  NotificationCenterBannerListItem,
  NotificationCenterBannerUpdateRequestData,
  NotificationCenterBannerUpdateRequestFormValues,
  NotificationCenterBannerUpdateSuccessData,
} from './types';

export const listNotificationCenterBanner = createAsyncAction(
  Actions.LIST_NOTIFICATION_CENTER_BANNER_REQUEST,
  Actions.LIST_NOTIFICATION_CENTER_BANNER_SUCCESS,
  Actions.LIST_NOTIFICATION_CENTER_BANNER_FAILURE
)<ListRequestPayload, ListState<NotificationCenterBannerListItem>, AxiosError>();

export const createNotificationCenterBanner = createAsyncAction(
  Actions.CREATE_NOTIFICATION_CENTER_BANNER_REQUEST,
  Actions.CREATE_NOTIFICATION_CENTER_BANNER_SUCCESS,
  Actions.CREATE_NOTIFICATION_CENTER_BANNER_FAILURE
)<
  FormRequestPayload<NotificationCenterBannerCreateRequestFormValues, NotificationCenterBannerCreateRequestData>,
  NotificationCenterBannerCreateSuccessData,
  AxiosError
>();

export const readNotificationCenterBanner = createAsyncAction(
  Actions.READ_NOTIFICATION_CENTER_BANNER_REQUEST,
  Actions.READ_NOTIFICATION_CENTER_BANNER_SUCCESS,
  Actions.READ_NOTIFICATION_CENTER_BANNER_FAILURE
)<ReadRequestPayload, NotificationCenterBannerDetail, AxiosError>();

export const updateNotificationCenterBanner = createAsyncAction(
  Actions.UPDATE_NOTIFICATION_CENTER_BANNER_REQUEST,
  Actions.UPDATE_NOTIFICATION_CENTER_BANNER_SUCCESS,
  Actions.UPDATE_NOTIFICATION_CENTER_BANNER_FAILURE
)<
  FormRequestPayload<NotificationCenterBannerUpdateRequestFormValues, NotificationCenterBannerUpdateRequestData>,
  NotificationCenterBannerUpdateSuccessData,
  AxiosError
>();

export const refreshNotificationCenterBanner = createAsyncAction(
  Actions.REFRESH_NOTIFICATION_CENTER_BANNER_REQUEST,
  Actions.REFRESH_NOTIFICATION_CENTER_BANNER_SUCCESS,
  Actions.REFRESH_NOTIFICATION_CENTER_BANNER_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
