import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as criteriaSegmentActions from './actions';
import { CriteriaSegmentState, initialState } from './types';

// noinspection TypeScriptValidateJSTypes
const criteriaSegment = createReducer<CriteriaSegmentState, RootAction>(initialState)
  .handleAction(criteriaSegmentActions.listCriteriaSegment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(criteriaSegmentActions.listCriteriaSegment.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(criteriaSegmentActions.readCriteriaSegment.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default criteriaSegment;
