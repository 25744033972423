import { initialListState, ListState } from '../types';

export enum MatataInvalidatedPurchaseByReceiverActions {
  LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST = 'LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#REQUEST',
  LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS = 'LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#SUCCESS',
  LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE = 'LIST_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#FAILURE',

  READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST = 'READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#REQUEST',
  READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS = 'READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#SUCCESS',
  READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE = 'READ_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#FAILURE',

  WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST = 'WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#REQUEST',
  WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS = 'WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#SUCCESS',
  WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE = 'WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#FAILURE',

  NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_REQUEST = 'NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#REQUEST',
  NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_SUCCESS = 'NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#SUCCESS',
  NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER_FAILURE = 'NOT_WITHDRAW_MATATA_INVALIDATED_PURCHASE_BY_RECEIVER#FAILURE',
}

export interface MatataInvalidatedPurchaseByReceiver {
  checked?: boolean;
  receiverId: number;
  receiverMatataId: string;
  receiverCountryCode: string;
  totalGemAmount: number;
  totalStarAmount: number;
  invalidatedAt: string;
  processedAt: string;
  withdrawnAmount: number;
  status: string;
}

export const fileHeadersByReceiver: string[] = [
  'receiverId',
  'receiverMatataId',
  'receiverCountryCode',
  'totalGemAmount',
  'totalStarAmount',
  'invalidatedAt',
];

export const sortMatataInvalidatedPurchaseByReceiver = (data: MatataInvalidatedPurchaseByReceiver) => [
  data.receiverId,
  data.receiverMatataId,
  data.receiverCountryCode,
  data.totalGemAmount,
  data.totalStarAmount,
  data.invalidatedAt,
];

export interface MatataInvalidatedPurchaseByReceiverState {
  list: ListState<MatataInvalidatedPurchaseByReceiver>;
  detail?: MatataInvalidatedPurchaseByReceiver;
}

export const initialState: MatataInvalidatedPurchaseByReceiverState = {
  list: initialListState,
  detail: undefined,
};

export interface WithdrawRequestPayload {
  receiverIds: number[];
  from: string;
  to: string;
}

export type NotWithdrawRequestPayload = WithdrawRequestPayload;

export const STATUS_WITHDRAWN = 'WITHDRAWN';
