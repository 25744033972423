import { initialListState, ListState } from 'store/types';

export enum IabProductActions {
  LIST_APP_STORE_IAP_PRODUCT_REQUEST = 'LIST_APP_STORE_IAP_PRODUCT#REQUEST',
  LIST_APP_STORE_IAP_PRODUCT_SUCCESS = 'LIST_APP_STORE_IAP_PRODUCT#SUCCESS',
  LIST_APP_STORE_IAP_PRODUCT_FAILURE = 'LIST_APP_STORE_IAP_PRODUCT#FAILURE',

  LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT_REQUEST = 'LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT#REQUEST',
  LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT_SUCCESS = 'LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT#SUCCESS',
  LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT_FAILURE = 'LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT#FAILURE',

  LIST_WEB_PRODUCT_REQUEST = 'LIST_WEB_PRODUCT#REQUEST',
  LIST_WEB_PRODUCT_SUCCESS = 'LIST_WEB_PRODUCT#SUCCESS',
  LIST_WEB_PRODUCT_FAILURE = 'LIST_WEB_PRODUCT#FAILURE',

  LIST_GOOGLE_PLAY_IAP_PRODUCT_REQUEST = 'LIST_GOOGLE_PLAY_IAP_PRODUCT#REQUEST',
  LIST_GOOGLE_PLAY_IAP_PRODUCT_SUCCESS = 'LIST_GOOGLE_PLAY_IAP_PRODUCT#SUCCESS',
  LIST_GOOGLE_PLAY_IAP_PRODUCT_FAILURE = 'LIST_GOOGLE_PLAY_IAP_PRODUCT#FAILURE',

  LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT_REQUEST = 'LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT#REQUEST',
  LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT_SUCCESS = 'LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT#SUCCESS',
  LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT_FAILURE = 'LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT#FAILURE',
}

export interface IabProduct {
  productId: string;
}

export enum IabProductType {
  APP_STORE = 'appStore',
  GOOGLE_PLAY = 'googlePlay',
  WEB = 'web',
  APP_STORE_SUBSCRIPTION = 'appStoreSubscription',
  GOOGLE_PLAY_SUBSCRIPTION = 'googlePlaySubscription',
}

export const initialState: IabProductState = {
  [IabProductType.APP_STORE]: { list: initialListState },
  [IabProductType.GOOGLE_PLAY]: { list: initialListState },
  [IabProductType.WEB]: { list: initialListState },
  [IabProductType.APP_STORE_SUBSCRIPTION]: { list: initialListState },
  [IabProductType.GOOGLE_PLAY_SUBSCRIPTION]: { list: initialListState },
};

export type IabProductState = {
  [type in IabProductType]: {
    list: ListState<IabProduct>;
  };
};
