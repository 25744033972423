import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as filleS3UploadActions from './actions';
import { FileS3UploadState, initialState } from './types';

const fileS3Upload = createReducer<FileS3UploadState, RootAction>(initialState)
  .handleAction(filleS3UploadActions.listFileS3Upload.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(filleS3UploadActions.listFileS3Upload.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(({ fileUrls, ...values }) => ({
        ...values,
        fileUrls: fileUrls.map((fileUrl) => ({ fileUrl })),
      })),
    });

    return newState.toJS() as FileS3UploadState;
  })
  .handleAction(filleS3UploadActions.readFileS3Upload.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(filleS3UploadActions.uploadSingleFile.success, (state, { payload }) => ({
    ...state,
    data: payload,
  }))
  .handleAction(filleS3UploadActions.uploadMultiFile.success, (state, { payload }) => ({
    ...state,
    data: payload,
  }));

export default fileS3Upload;
