export enum CountryManageActions {
  UPDATE_COUNTRY_MANAGE_REQUEST = 'UPDATE_COUNTRY_MANAGE#REQUEST',
  UPDATE_COUNTRY_MANAGE_SUCCESS = 'UPDATE_COUNTRY_MANAGE#SUCCESS',
  UPDATE_COUNTRY_MANAGE_FAILURE = 'UPDATE_COUNTRY_MANAGE#FAILURE',
}

export interface CountryManageUpdateRequestData {
  userId: string;
  countryCode: string;
}
