import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import { matchingApiRequest } from 'utils/axios';

import {
  FileS3UploadCreateRequestData,
  FileS3UploadCreateResponseData,
  FileS3UploadDetail,
  FileS3UploadFormValues,
  FileS3UploadListItem,
  FileS3UploadUpdateRequestData,
  FileS3UploadUpdateResponseData,
  MultiFileUploadRequestData,
  MultiFileUploadResponseData,
  SingleFileUploadRequestData,
  SingleFileUploadResponseData,
} from './types';

export const listFileS3UploadApi = createListApi<FileS3UploadListItem>(
  '/api/v1/file/bulk-uploads',
  {},
  {},
  { apiRequest: matchingApiRequest }
);

export const readFileS3UploadApi = createReadApi<FileS3UploadDetail>(
  '/api/v1/file/bulk-uploads/:id',
  {},
  { apiRequest: matchingApiRequest }
);

export const createFileS3UploadApi = createPostApi<
  FileS3UploadFormValues,
  FileS3UploadCreateRequestData,
  FileS3UploadCreateResponseData
>('/api/v1/file/bulk-uploads', {}, { apiRequest: matchingApiRequest });

export const updateFileS3UploadApi = createUpdateApi<
  FileS3UploadFormValues,
  FileS3UploadUpdateRequestData,
  FileS3UploadUpdateResponseData
>('/api/v1/file/bulk-uploads/:id', {}, { apiRequest: matchingApiRequest });

export const deleteFileS3UploadApi = createDeleteApi(
  '/api/v1/file/bulk-uploads/:id',
  {},
  { apiRequest: matchingApiRequest }
);

export const uploadSingleFileApi = createPostApi<SingleFileUploadRequestData, SingleFileUploadResponseData>(
  '/api/v1/file/single-upload',
  {},
  { apiRequest: matchingApiRequest }
);

export const uploadMultiFileApi = createPostApi<MultiFileUploadRequestData, MultiFileUploadResponseData>(
  '/api/v1/file/multi-upload',
  {},
  { apiRequest: matchingApiRequest }
);
