export enum SpecialPromotionSettingMapEntryActions {
  CREATE_MAP_ENTRY_REQUEST = 'CREATE_SPECIAL_PROMOTION_MAP_ENTRY#REQUEST',
  CREATE_MAP_ENTRY_SUCCESS = 'CREATE_SPECIAL_PROMOTION_MAP_ENTRY#SUCCESS',
  CREATE_MAP_ENTRY_FAILURE = 'CREATE_SPECIAL_PROMOTION_MAP_ENTRY#FAILURE',

  UPDATE_MAP_ENTRY_REQUEST = 'UPDATE_SPECIAL_PROMOTION_MAP_ENTRY#REQUEST',
  UPDATE_MAP_ENTRY_SUCCESS = 'UPDATE_SPECIAL_PROMOTION_MAP_ENTRY#SUCCESS',
  UPDATE_MAP_ENTRY_FAILURE = 'UPDATE_SPECIAL_PROMOTION_MAP_ENTRY#FAILURE',

  DELETE_MAP_ENTRY_REQUEST = 'DELETE_SPECIAL_PROMOTION_MAP_ENTRY#REQUEST',
  DELETE_MAP_ENTRY_SUCCESS = 'DELETE_SPECIAL_PROMOTION_MAP_ENTRY#SUCCESS',
  DELETE_MAP_ENTRY_FAILURE = 'DELETE_SPECIAL_PROMOTION_MAP_ENTRY#FAILURE',
}

export interface SpecialPromotionSettingMapEntry {
  id: number;
  settingId: number;
  startDate: string;
  endDate: string;
  segmentCode?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder: number;
  enabled?: boolean;
}

export type SpecialPromotionSettingMapEntryCreateRequestData = Omit<SpecialPromotionSettingMapEntry, 'id' | 'enabled'>;
export type SpecialPromotionSettingMapEntryCreateSuccessData = SpecialPromotionSettingMapEntry;
export type SpecialPromotionSettingMapEntryUpdateRequestData = Omit<SpecialPromotionSettingMapEntry, 'id' | 'enabled'>;
export type SpecialPromotionSettingMapEntryUpdateSuccessData = SpecialPromotionSettingMapEntry;
