import { combineEpics } from 'redux-observable';

import {
  createVisitorAbTestCriteria,
  deleteVisitorAbTestCriteria,
  updateVisitorAbTestCriteria,
} from 'store/visitorAbTestCriteria/actions';
import {
  createVisitorAbTestCriteriaApi,
  deleteVisitorAbTestCriteriaApi,
  updateVisitorAbTestCriteriaApi,
} from 'store/visitorAbTestCriteria/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createVisitorAbTestCriteriaToastEpic = createToastEpic(
  createVisitorAbTestCriteria.success,
  'Test group saved successfully'
);

const updateVisitorAbTestCriteriaToastEpic = createToastEpic(
  updateVisitorAbTestCriteria.success,
  'Test group updated successfully'
);

const deleteVisitorAbTestCriteriaToastEpic = createToastEpic(
  deleteVisitorAbTestCriteria.success,
  'Test group deleted successfully'
);

const createScheduleEpic = createPostEpic(createVisitorAbTestCriteria, createVisitorAbTestCriteriaApi);

const updateScheduleEpic = createUpdateEpic(updateVisitorAbTestCriteria, updateVisitorAbTestCriteriaApi);

const deleteScheduleEpic = createDeleteEpic(deleteVisitorAbTestCriteria, deleteVisitorAbTestCriteriaApi);

export default combineEpics(
  createVisitorAbTestCriteriaToastEpic,
  updateVisitorAbTestCriteriaToastEpic,
  deleteVisitorAbTestCriteriaToastEpic,
  createScheduleEpic,
  updateScheduleEpic,
  deleteScheduleEpic
);
