import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteBodyRequestPayload, FormRequestPayload } from 'store/types';

import {
  PromotionScheduleTargetActions as Actions,
  PromotionScheduleTargetCreateRequestData,
  PromotionScheduleTargetCreateResponseData,
  PromotionScheduleTargetFormValues,
  PromotionScheduleTargetUpdateRequestData,
  PromotionScheduleTargetUpdateResponseData,
} from './types';

export const createPromotionScheduleTarget = createAsyncAction(
  Actions.CREATE_PROMOTION_SCHEDULE_TARGET_REQUEST,
  Actions.CREATE_PROMOTION_SCHEDULE_TARGET_SUCCESS,
  Actions.CREATE_PROMOTION_SCHEDULE_TARGET_FAILURE
)<
  FormRequestPayload<PromotionScheduleTargetFormValues, PromotionScheduleTargetCreateRequestData>,
  PromotionScheduleTargetCreateResponseData,
  AxiosError
>();

export const updatePromotionScheduleTarget = createAsyncAction(
  Actions.UPDATE_PROMOTION_SCHEDULE_TARGET_REQUEST,
  Actions.UPDATE_PROMOTION_SCHEDULE_TARGET_SUCCESS,
  Actions.UPDATE_PROMOTION_SCHEDULE_TARGET_FAILURE
)<
  FormRequestPayload<PromotionScheduleTargetFormValues, PromotionScheduleTargetUpdateRequestData>,
  PromotionScheduleTargetUpdateResponseData,
  AxiosError
>();

export const deletePromotionScheduleTarget = createAsyncAction(
  Actions.DELETE_PROMOTION_SCHEDULE_TARGET_REQUEST,
  Actions.DELETE_PROMOTION_SCHEDULE_TARGET_SUCCESS,
  Actions.DELETE_PROMOTION_SCHEDULE_TARGET_FAILURE
)<DeleteBodyRequestPayload, undefined, AxiosError>();
