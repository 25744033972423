import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as simulationUploadActions from './actions';
import { SimulationUploadState, initialState } from './types';

const simulationUpload = createReducer<SimulationUploadState, RootAction>(initialState)
  .handleAction(simulationUploadActions.createSimulationUpload.success, (state, { payload }) => ({
    ...state,
    data: payload,
  }))
  .handleAction(simulationUploadActions.resetSimulationUpload, (state) => ({
    ...state,
    data: undefined,
  }));

export default simulationUpload;
