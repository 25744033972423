import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';
import * as specialPromotionSettingMapEntryActions from '../specialPromotionSettingMapEntry/actions';
import * as specialPromotionSettingProductActions from '../specialPromotionSettingProduct/actions';

import * as specialPromotionSettingActions from './actions';
import { initialState, SpecialPromotionSettingState } from './types';

const compareNumberFunction = (a?: number, b?: number): number => {
  if (!b) {
    return 1;
  }
  if (!a) {
    return -1;
  }
  return a - b;
};

const specialPromotionSetting = createReducer<SpecialPromotionSettingState, RootAction>(initialState)
  .handleAction(
    specialPromotionSettingActions.listSpecialPromotionSetting.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(specialPromotionSettingActions.listSpecialPromotionSetting.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(specialPromotionSettingActions.readSpecialPromotionSetting.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(specialPromotionSettingActions.setSpecialPromotionSettingProducts, (state, { payload }) =>
    !state.detail
      ? state
      : {
          ...state,
          detail: {
            ...state.detail,
            specialPromotionSettingProducts: payload,
          },
        }
  )
  .handleAction(
    specialPromotionSettingProductActions.updateSpecialPromotionSettingProduct.success,
    (state, { payload }) => {
      if (payload.settingId !== state.detail?.id) {
        return state;
      }
      const index = state.detail?.specialPromotionSettingProducts.findIndex((product) => product.id === payload.id);
      if (index !== null && index > -1) {
        let newState: Map<keyof typeof state, any> = fromJS(state);
        newState = newState.setIn(['detail', 'specialPromotionSettingProducts', index], payload);
        return newState.toJS() as SpecialPromotionSettingState;
      }
      return state;
    }
  )
  .handleAction(
    specialPromotionSettingProductActions.createSpecialPromotionSettingProduct.success,
    (state, { payload }) => {
      if (!state.detail || payload.settingId !== state.detail?.id) {
        return state;
      }
      const products = state.detail.specialPromotionSettingProducts;
      let newProducts = [...products, payload];
      newProducts = newProducts.sort((a, b) => {
        const rowDiff = compareNumberFunction(a.rowOrder, b.rowOrder);
        if (rowDiff === 0) {
          return compareNumberFunction(a.columnOrder, b.columnOrder);
        }
        return rowDiff;
      });
      return {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              specialPromotionSettingProducts: newProducts,
            }
          : undefined,
      };
    }
  )
  .handleAction(
    specialPromotionSettingMapEntryActions.createSpecialPromotionSettingMapEntry.success,
    (state, { payload }) => {
      if (state.detail?.id !== payload.settingId) {
        return state;
      }
      return {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              specialPromotionSettingMapEntries: [...state.detail.specialPromotionSettingMapEntries, payload],
            }
          : undefined,
      };
    }
  )
  .handleAction(
    specialPromotionSettingMapEntryActions.updateSpecialPromotionSettingMapEntry.success,
    (state, { payload }) => {
      if (state.detail?.id !== payload.settingId) {
        return state;
      }
      const index = state.detail?.specialPromotionSettingMapEntries.findIndex((mapEntry) => mapEntry.id === payload.id);
      if (index !== null && index > -1) {
        let newState: Map<keyof typeof state, any> = fromJS(state);
        newState = newState.setIn(['detail', 'specialPromotionSettingMapEntries', index], payload);
        return newState.toJS() as SpecialPromotionSettingState;
      }
      return state;
    }
  )
  .handleAction(
    specialPromotionSettingMapEntryActions.deleteSpecialPromotionSettingMapEntry.success,
    (state, { payload }) => {
      const mapEntries = state.detail?.specialPromotionSettingMapEntries;
      if (mapEntries) {
        return {
          ...state,
          detail: state.detail
            ? {
                ...state.detail,
                specialPromotionSettingMapEntries: mapEntries.filter((mapEntry) => `${mapEntry.id}` !== `${payload}`),
              }
            : undefined,
        };
      }
      return state;
    }
  );

export default specialPromotionSetting;
