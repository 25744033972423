import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as agentHistoryActions from './actions';
import { AgentHistoryState, initialAgentHistoryState } from './types';

const agentHistory = createReducer<AgentHistoryState, RootAction>(initialAgentHistoryState)
  .handleAction(agentHistoryActions.listAgentHistory.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(agentHistoryActions.listAgentHistory.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default agentHistory;
