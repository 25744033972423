import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  MlConfigModelSegmentActions as Actions,
  MlConfigModelSegmentCreateRequestData,
  MlConfigModelSegmentCreateSuccessData,
  MlConfigModelSegmentUpdateRequestData,
  MlConfigModelSegmentUpdateSuccessData,
} from './types';

export const createMlConfigModelSegment = createAsyncAction(
  Actions.CREATE_SEGMENT_REQUEST,
  Actions.CREATE_SEGMENT_SUCCESS,
  Actions.CREATE_SEGMENT_FAILURE
)<FormRequestPayload<MlConfigModelSegmentCreateRequestData>, MlConfigModelSegmentCreateSuccessData, AxiosError>();

export const updateMlConfigModelSegment = createAsyncAction(
  Actions.UPDATE_SEGMENT_REQUEST,
  Actions.UPDATE_SEGMENT_SUCCESS,
  Actions.UPDATE_SEGMENT_FAILURE
)<FormRequestPayload<MlConfigModelSegmentUpdateRequestData>, MlConfigModelSegmentUpdateSuccessData, AxiosError>();

export const deleteMlConfigModelSegment = createAsyncAction(
  Actions.DELETE_SEGMENT_REQUEST,
  Actions.DELETE_SEGMENT_SUCCESS,
  Actions.DELETE_SEGMENT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
