import React, { FC, useEffect } from 'react';

import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import LoadingIndicator from 'components/LoadingIndicator';
import useSignedIn from 'hooks/useSignedIn';
import { IS_TEST } from 'constants/env';

import * as userActions from 'store/user/actions';

interface Props extends Omit<RouteProps, 'render'> {
  render: (props: RouteComponentProps<any>) => React.ReactNode;
}

const PrivateRoute: FC<Props> = ({ render, path, exact, sensitive, strict, location }) => {
  const dispatch = useDispatch();
  const signedIn = useSignedIn();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    // 인증 검증
    if (!signedIn && !IS_TEST) {
      if (authState?.isAuthenticated) {
        dispatch(userActions.fetchSignIn.request(authState));
      } else {
        oktaAuth.signInWithRedirect({ originalUri: `${window.location.href}` });
      }
    }
  }, [signedIn, authState, oktaAuth, dispatch]);

  return (
    <Route
      location={location}
      path={path}
      exact={exact}
      sensitive={sensitive}
      strict={strict}
      render={(routeProps) => (signedIn ? render(routeProps) : <LoadingIndicator fullWidth fullHeight />)}
    />
  );
};

export default PrivateRoute;
