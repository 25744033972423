import { createReadApi, createUpdateApi } from 'store/utils';

import {
  ProfileManageDetail,
  // ProfileManage,
  ProfileManageUpdateRequestData,
  ProfileManageUpdateSuccessData,
} from './types';

export const readProfileManageApi = createReadApi<ProfileManageDetail>('/operation/profile/report/:id');
export const updateProfileManageApi = createUpdateApi<ProfileManageUpdateRequestData, ProfileManageUpdateSuccessData>(
  '/operation/profile/report/:id'
);
