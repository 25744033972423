import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createMatchFilterSegment,
  deleteMatchFilterSegment,
  listMatchFilterSegment,
  readMatchFilterSegment,
  updateMatchFilterSegment,
} from './actions';
import {
  createMatchFilterSegmentApi,
  deleteMatchFilterSegmentApi,
  listMatchFilterSegmentApi,
  readMatchFilterSegmentApi,
  updateMatchFilterSegmentApi,
} from './api';

const listMatchFilterSegmentEpic = createListEpic(listMatchFilterSegment, listMatchFilterSegmentApi);

const readMatchFilterSegmentEpic = createReadEpic(readMatchFilterSegment, readMatchFilterSegmentApi);

const createMatchFilterSegmentEpic = createPostEpic(createMatchFilterSegment, createMatchFilterSegmentApi);

const createMatchFilterSegmentToastEpic = createToastEpic(createMatchFilterSegment.success, 'Successfully created');

const updateMatchFilterSegmentEpic = createUpdateEpic(updateMatchFilterSegment, updateMatchFilterSegmentApi);

const updateMatchFilterSegmentToastEpic = createToastEpic(updateMatchFilterSegment.success, 'Successfully saved');

const deleteMatchFilterSegmentEpic = createDeleteEpic(deleteMatchFilterSegment, deleteMatchFilterSegmentApi);

export default combineEpics(
  listMatchFilterSegmentEpic,
  readMatchFilterSegmentEpic,
  createMatchFilterSegmentEpic,
  createMatchFilterSegmentToastEpic,
  updateMatchFilterSegmentEpic,
  updateMatchFilterSegmentToastEpic,
  deleteMatchFilterSegmentEpic
);
