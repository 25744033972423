import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  MatataSuspensionHistoryActions as Actions,
  MatataSuspensionHistoryRequestPayload,
  MatataSuspensionHistoryResponseData,
} from './types';

export const listMatataSuspensionHistory = createAsyncAction(
  Actions.LIST_MATATA_SUSPENSION_HISTORY_REQUEST,
  Actions.LIST_MATATA_SUSPENSION_HISTORY_SUCCESS,
  Actions.LIST_MATATA_SUSPENSION_HISTORY_FAILURE
)<MatataSuspensionHistoryRequestPayload, MatataSuspensionHistoryResponseData, AxiosError>();
