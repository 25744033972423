import { createReducer } from 'typesafe-actions';

import * as configStoreActions from 'store/configStore/actions';
import { initialState, ConfigStoreState } from 'store/configStore/types';
import { RootAction } from 'store/root/types';

const configStore = createReducer<ConfigStoreState, RootAction>(initialState)
  .handleAction(configStoreActions.listConfigStore.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(configStoreActions.listConfigStore.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(configStoreActions.readConfigStore.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));
export default configStore;
