import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { IabProductActions as Actions, IabProduct } from 'store/iabProduct/types';
import { ListRequestPayload, ListState } from 'store/types';

export const listAppStoreIabProduct = createAsyncAction(
  Actions.LIST_APP_STORE_IAP_PRODUCT_REQUEST,
  Actions.LIST_APP_STORE_IAP_PRODUCT_SUCCESS,
  Actions.LIST_APP_STORE_IAP_PRODUCT_FAILURE
)<ListRequestPayload, ListState<IabProduct>, AxiosError>();

export const listGooglePlayIabProduct = createAsyncAction(
  Actions.LIST_GOOGLE_PLAY_IAP_PRODUCT_REQUEST,
  Actions.LIST_GOOGLE_PLAY_IAP_PRODUCT_SUCCESS,
  Actions.LIST_GOOGLE_PLAY_IAP_PRODUCT_FAILURE
)<ListRequestPayload, ListState<IabProduct>, AxiosError>();

export const listWebProduct = createAsyncAction(
  Actions.LIST_WEB_PRODUCT_REQUEST,
  Actions.LIST_WEB_PRODUCT_SUCCESS,
  Actions.LIST_WEB_PRODUCT_FAILURE
)<ListRequestPayload, ListState<IabProduct>, AxiosError>();

export const listAppStoreIabSubscriptionProduct = createAsyncAction(
  Actions.LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT_REQUEST,
  Actions.LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT_SUCCESS,
  Actions.LIST_APP_STORE_IAP_SUBSCRIPTION_PRODUCT_FAILURE
)<ListRequestPayload, ListState<IabProduct>, AxiosError>();

export const listGooglePlayIabSubscriptionProduct = createAsyncAction(
  Actions.LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT_REQUEST,
  Actions.LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT_SUCCESS,
  Actions.LIST_GOOGLE_PLAY_IAP_SUBSCRIPTION_PRODUCT_FAILURE
)<ListRequestPayload, ListState<IabProduct>, AxiosError>();
