import { ShopEntryBannerSchedule } from 'store/shopEntryBannerSchedule/types';
import { initialListState, ListState } from 'store/types';

export enum ShopEntryBannerActions {
  LIST_SHOP_ENTRY_BANNER_REQUEST = 'LIST_SHOP_ENTRY_BANNER#REQUEST',
  LIST_SHOP_ENTRY_BANNER_SUCCESS = 'LIST_SHOP_ENTRY_BANNER#SUCCESS',
  LIST_SHOP_ENTRY_BANNER_FAILURE = 'LIST_SHOP_ENTRY_BANNER#FAILURE',

  CREATE_SHOP_ENTRY_BANNER_REQUEST = 'CREATE_SHOP_ENTRY_BANNER#REQUEST',
  CREATE_SHOP_ENTRY_BANNER_SUCCESS = 'CREATE_SHOP_ENTRY_BANNER#SUCCESS',
  CREATE_SHOP_ENTRY_BANNER_FAILURE = 'CREATE_SHOP_ENTRY_BANNER#FAILURE',

  READ_SHOP_ENTRY_BANNER_REQUEST = 'READ_SHOP_ENTRY_BANNER#REQUEST',
  READ_SHOP_ENTRY_BANNER_SUCCESS = 'READ_SHOP_ENTRY_BANNER#SUCCESS',
  READ_SHOP_ENTRY_BANNER_FAILURE = 'READ_SHOP_ENTRY_BANNER#FAILURE',

  UPDATE_SHOP_ENTRY_BANNER_REQUEST = 'UPDATE_SHOP_ENTRY_BANNER#REQUEST',
  UPDATE_SHOP_ENTRY_BANNER_SUCCESS = 'UPDATE_SHOP_ENTRY_BANNER#SUCCESS',
  UPDATE_SHOP_ENTRY_BANNER_FAILURE = 'UPDATE_SHOP_ENTRY_BANNER#FAILURE',

  REFRESH_SHOP_ENTRY_BANNER_REQUEST = 'REFRESH_SHOP_ENTRY_BANNER#REQUEST',
  REFRESH_SHOP_ENTRY_BANNER_SUCCESS = 'REFRESH_SHOP_ENTRY_BANNER#SUCCESS',
  REFRESH_SHOP_ENTRY_BANNER_FAILURE = 'REFRESH_SHOP_ENTRY_BANNER#FAILURE',
}

export enum Platform {
  ALL = 'ALL',
  IOS = 'iOS',
  ANDROID = 'Android',
  WEBCLIENT = 'WEBCLIENT',
}

export const initialState: ShopEntryBannerState = {
  list: initialListState,
  detail: undefined,
};

export interface ShopEntryBannerListItem {
  id: number;
  bannerTextKey: string;
  description: string;
  intervalMinutes: number;
  exposureTimeSeconds: number;
  exposureRepeatCount: number;
  exposureLimitCount: number;
  iconUrl: string;
  backgroundColor: string;
  textColor: string;
  deeplink: string;
  platform: string;
  excludeItemCategoryId: string;
  evalOrder: number;
  enabled: boolean;
  shopEntryBannerSchedules: ShopEntryBannerSchedule[];
}
export interface StringLocalizationFormValues {
  headerTitleAsString: boolean;
}

export type ShopEntryBannerDetail = ShopEntryBannerListItem;

export interface ShopEntryBannerState {
  list: ListState<ShopEntryBannerListItem>;
  detail?: ShopEntryBannerDetail;
}

export type ShopEntryBannerCreateRequestFormValues = Omit<
  ShopEntryBannerListItem,
  'id' | 'shopEntryBannerPopupSchedules'
> &
  StringLocalizationFormValues;
export type ShopEntryBannerCreateRequestData = Omit<ShopEntryBannerListItem, 'id' | 'shopEntryBannerPopupSchedules'>;
export type ShopEntryBannerCreateSuccessData = ShopEntryBannerDetail;
export type ShopEntryBannerUpdateRequestFormValues = Omit<ShopEntryBannerListItem, 'shopEntryBannerPopupSchedules'> &
  StringLocalizationFormValues;
export type ShopEntryBannerUpdateRequestData = Omit<ShopEntryBannerListItem, 'shopEntryBannerPopupSchedules'>;
export type ShopEntryBannerUpdateSuccessData = ShopEntryBannerDetail;
