import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as downloadableContentActions from './actions';
import { DownloadableContentState, initialState } from './types';

const downloadableContent = createReducer<DownloadableContentState, RootAction>(initialState)
  .handleAction(
    downloadableContentActions.listDownloadableContent.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(downloadableContentActions.listDownloadableContent.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(downloadableContentActions.readDownloadableContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(downloadableContentActions.createDownloadableContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(downloadableContentActions.updateDownloadableContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(downloadableContentActions.resetDownloadableContent, (state) => ({
    ...state,
    data: undefined,
  }));

export default downloadableContent;
