import { createPostApi } from 'store/utils';

import {
  ConvertUserIdsToUsernamesRequestData,
  ConvertUserIdsToUsernamesSuccessData,
  ConvertUsernamesToUserIdsRequestData,
  ConvertUsernamesToUserIdsSuccessData,
} from './types';

export const convertUserIdsToUsernamesApi = createPostApi<
  ConvertUserIdsToUsernamesRequestData,
  ConvertUserIdsToUsernamesSuccessData
>('/operation/users/id/to/username');

export const convertUsernamesToUserIdsApi = createPostApi<
  ConvertUsernamesToUserIdsRequestData,
  ConvertUsernamesToUserIdsSuccessData
>('/operation/users/username/to/id');
