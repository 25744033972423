import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as decoItemActions from './actions';
import { DecoItemState, initialState } from './types';

const decoItem = createReducer<DecoItemState, RootAction>(initialState)
  .handleAction(decoItemActions.listDecoItem.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(decoItemActions.listDecoItem.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(decoItemActions.readDecoItem.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default decoItem;
