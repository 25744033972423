import { createAction } from 'typesafe-actions';

import { UploadFileListActions as Actions, UploadFileListPayload } from './types';

export const uploadFileList = createAction(Actions.UPLOAD_FILE_LIST, (payload: UploadFileListPayload) => payload)();

export const resetFileList = createAction(
  Actions.RESET_FILE_LIST,
  (payload: Omit<UploadFileListPayload, 'fileList'>) => payload
)();
