import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as bigquerySegmentActions from './actions';
import * as bigquerySegmentDeclarationActions from '../bigquerySegmentDeclaration/actions';
import { initialState, BigquerySegmentState } from './types';

const bigquerySegment = createReducer<BigquerySegmentState, RootAction>(initialState)
  .handleAction(bigquerySegmentActions.listBigquerySegment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(bigquerySegmentActions.listBigquerySegment.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(bigquerySegmentActions.listBigquerySegmentSearch.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    searchList: {
      ...state.searchList,
      page,
      pageSize,
    },
  }))
  .handleAction(bigquerySegmentActions.listBigquerySegmentSearch.success, (state, { payload }) => ({
    ...state,
    searchList: payload,
  }))
  .handleAction(bigquerySegmentActions.readBigquerySegment.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(bigquerySegmentDeclarationActions.acceptBigquerySegmentDeclaration.success, (state, { payload }) => {
    const detail = state.detail;
    if (!detail) return { ...state };
    return {
      ...state,
      detail: {
        segmentCode: detail.segmentCode,
        schedule: detail.schedule,
        declaration: payload,
      },
    };
  })
  .handleAction(bigquerySegmentDeclarationActions.rejectBigquerySegmentDeclaration.success, (state, { payload }) => {
    const detail = state.detail;
    if (!detail) return { ...state };
    return {
      ...state,
      detail: {
        segmentCode: detail.segmentCode,
        schedule: detail.schedule,
        declaration: payload,
      },
    };
  });

export default bigquerySegment;
