import { combineEpics } from 'redux-observable';

import {
  createBigquerySegmentDeclaration,
  updateBigquerySegmentDeclaration,
  acceptBigquerySegmentDeclaration,
  rejectBigquerySegmentDeclaration,
  manualTriggerBigquerySegment,
} from './actions';
import {
  createBigquerySegmentDeclarationApi,
  updateBigquerySegmentDeclarationApi,
  acceptBigquerySegmentDeclarationApi,
  rejectBigquerySegmentDeclarationApi,
  manualTriggerBigquerySegmentApi,
} from './api';
import { createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createBigquerySegmentDeclarationToastEpic = createToastEpic(
  createBigquerySegmentDeclaration.success,
  'Segment Declaration created successfully'
);

const updateBigquerySegmentDeclarationToastEpic = createToastEpic(
  updateBigquerySegmentDeclaration.success,
  'Segment Declaration updated successfully'
);

const createBigquerySegmentDeclarationEpic = createPostEpic(
  createBigquerySegmentDeclaration,
  createBigquerySegmentDeclarationApi
);

const updateBigquerySegmentDeclarationEpic = createUpdateEpic(
  updateBigquerySegmentDeclaration,
  updateBigquerySegmentDeclarationApi
);

const acceptBigquerySegmentDeclarationEpic = createUpdateEpic(
  acceptBigquerySegmentDeclaration,
  acceptBigquerySegmentDeclarationApi
);

const acceptBigquerySegmentDeclarationToastEpic = createToastEpic(
  acceptBigquerySegmentDeclaration.success,
  'Segment Declaration successfully accepted'
);

const rejectBigquerySegmentDeclarationEpic = createUpdateEpic(
  rejectBigquerySegmentDeclaration,
  rejectBigquerySegmentDeclarationApi
);

const rejectBigquerySegmentDeclarationToastEpic = createToastEpic(
  rejectBigquerySegmentDeclaration.success,
  'Segment Declaration successfully rejected'
);

const manualTriggerBigquerySegmentEpic = createUpdateEpic(
  manualTriggerBigquerySegment,
  manualTriggerBigquerySegmentApi
);

const manualTriggerBigquerySegmentToastEpic = createToastEpic(
  manualTriggerBigquerySegment.success,
  'Manual Trigger has successfully worked'
);

export default combineEpics(
  createBigquerySegmentDeclarationToastEpic,
  createBigquerySegmentDeclarationEpic,
  updateBigquerySegmentDeclarationToastEpic,
  updateBigquerySegmentDeclarationEpic,
  acceptBigquerySegmentDeclarationEpic,
  acceptBigquerySegmentDeclarationToastEpic,
  rejectBigquerySegmentDeclarationEpic,
  rejectBigquerySegmentDeclarationToastEpic,
  manualTriggerBigquerySegmentEpic,
  manualTriggerBigquerySegmentToastEpic
);
