export enum DecoSettingProductActions {
  CREATE_DECO_SETTING_PRODUCT_REQUEST = 'CREATE_DECO_SETTING_PRODUCT#REQUEST',
  CREATE_DECO_SETTING_PRODUCT_SUCCESS = 'CREATE_DECO_SETTING_PRODUCT#SUCCESS',
  CREATE_DECO_SETTING_PRODUCT_FAILURE = 'CREATE_DECO_SETTING_PRODUCT#FAILURE',

  UPDATE_DECO_SETTING_PRODUCT_REQUEST = 'UPDATE_DECO_SETTING_PRODUCT#REQUEST',
  UPDATE_DECO_SETTING_PRODUCT_SUCCESS = 'UPDATE_DECO_SETTING_PRODUCT#SUCCESS',
  UPDATE_DECO_SETTING_PRODUCT_FAILURE = 'UPDATE_DECO_SETTING_PRODUCT#FAILURE',

  DELETE_DECO_SETTING_PRODUCT_REQUEST = 'DELETE_DECO_SETTING_PRODUCT#REQUEST',
  DELETE_DECO_SETTING_PRODUCT_SUCCESS = 'DELETE_DECO_SETTING_PRODUCT#SUCCESS',
  DELETE_DECO_SETTING_PRODUCT_FAILURE = 'DELETE_DECO_SETTING_PRODUCT#FAILURE',

  BULK_CREATE_DECO_SETTING_PRODUCTS_REQUEST = 'BULK_CREATE_DECO_SETTING_PRODUCTS#REQUEST',
  BULK_CREATE_DECO_SETTING_PRODUCTS_SUCCESS = 'BULK_CREATE_DECO_SETTING_PRODUCTS#SUCCESS',
  BULK_CREATE_DECO_SETTING_PRODUCTS_FAILURE = 'BULK_CREATE_DECO_SETTING_PRODUCTS#FAILURE',

  BULK_DELETE_DECO_SETTING_PRODUCTS_REQUEST = 'BULK_DELETE_DECO_SETTING_PRODUCTS#REQUEST',
  BULK_DELETE_DECO_SETTING_PRODUCTS_SUCCESS = 'BULK_DELETE_DECO_SETTING_PRODUCTS#SUCCESS',
  BULK_DELETE_DECO_SETTING_PRODUCTS_FAILURE = 'BULK_DELETE_DECO_SETTING_PRODUCTS#FAILURE',
}

export interface DecoSettingProduct {
  id: number;
  categoryId: string;
  itemId: string;
  displayOrder: number;
  settingId: number;
  segmentCode?: string;
}

export type DecoSettingProductFormValues = Omit<DecoSettingProduct, 'id'>;

export type DecoSettingProductCreateRequestData = Omit<DecoSettingProduct, 'id' | 'categoryId'>;

export type DecoSettingProductCreateResponseData = Omit<DecoSettingProduct, 'id' | 'categoryId'>;

export type DecoSettingProductUpdateRequestData = DecoSettingProductCreateRequestData;

export type DecoSettingProductUpdateResponseData = DecoSettingProductCreateResponseData;

export type DecoSettingProductBulkCreateRequestData = {
  products: DecoSettingProductCreateRequestData[];
};

export type DecoSettingProductBulkCreateResponseData = DecoSettingProductCreateResponseData[];
