import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as specialPromotionSettingLimitKeyActions from './actions';
import { initialState, SpecialPromotionSettingLimitKeyState } from './types';

const specialPromotionSettingLimitKey = createReducer<SpecialPromotionSettingLimitKeyState, RootAction>(
  initialState
).handleAction(
  specialPromotionSettingLimitKeyActions.listSpecialPromotionSettingLimitKey.success,
  (state, { payload }) => ({
    ...state,
    list: payload,
  })
);

export default specialPromotionSettingLimitKey;
