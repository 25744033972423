import { combineEpics } from 'redux-observable';

import {
  createMlConfigFeatureSegment,
  deleteMlConfigFeatureSegment,
  updateMlConfigFeatureSegment,
} from 'store/mlConfigFeatureSegment/actions';
import {
  createMlConfigFeatureSegmentApi,
  deleteMlConfigFeatureSegmentApi,
  updateMlConfigFeatureSegmentApi,
} from 'store/mlConfigFeatureSegment/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createMlConfigFeatureSegmentToastEpic = createToastEpic(
  createMlConfigFeatureSegment.success,
  'Successfully created Segment Setting'
);

const updateMlConfigFeatureSegmentToastEpic = createToastEpic(
  updateMlConfigFeatureSegment.success,
  'Successfully saved Segment Setting'
);

const deleteMlConfigFeatureSegmentToastEpic = createToastEpic(
  deleteMlConfigFeatureSegment.success,
  'Successfully deleted Segment Setting'
);

const createSegmentEpic = createPostEpic(createMlConfigFeatureSegment, createMlConfigFeatureSegmentApi);

const updateSegmentEpic = createUpdateEpic(updateMlConfigFeatureSegment, updateMlConfigFeatureSegmentApi);

const deleteSegmentEpic = createDeleteEpic(deleteMlConfigFeatureSegment, deleteMlConfigFeatureSegmentApi);

export default combineEpics(
  createMlConfigFeatureSegmentToastEpic,
  updateMlConfigFeatureSegmentToastEpic,
  deleteMlConfigFeatureSegmentToastEpic,
  createSegmentEpic,
  updateSegmentEpic,
  deleteSegmentEpic
);
