import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as fileUploadActions from './actions';
import { FileUploadState, initialState } from './types';

const fileUpload = createReducer<FileUploadState, RootAction>(initialState)
  .handleAction(fileUploadActions.createFileUpload.success, (state, { payload }) => ({
    ...state,
    type: payload.type,
    data: makeFunction(payload.type, state.data, {
      url: payload.fileUpload.url,
      encryptionKey: payload.fileUpload.encryptionKey,
      md5Hash: payload.fileUpload.md5Hash,
    }),
  }))
  .handleAction(fileUploadActions.resetFileUpload, (state) => ({
    ...state,
    type: 0,
    data: state.data,
  }));

const makeFunction = (type: string, data: any, payload: any) => {
  if (type.includes('iOS')) {
    return {
      ios: payload,
      android: data.android,
    };
  } else if (type.includes('Android')) {
    return {
      ios: data.ios,
      android: payload,
    };
  } else {
    return {
      ios: data.ios,
      android: data.android,
    };
  }
};

export default fileUpload;
