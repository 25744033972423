import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic } from 'store/utils';

import { createFileSetting } from './actions';
import { createFileSettingApi } from './api';

const createFileSettingEpic = createPostEpic(createFileSetting, createFileSettingApi);

const createFileSettingToastEpic = createToastEpic(createFileSetting.success, 'Successfully uploaded');

export default combineEpics(createFileSettingEpic, createFileSettingToastEpic);
