import { ListState, initialListState } from 'store/types';

export const groupTypes = ['SUBSCRIBING_GROUP'] as const;
export type GroupTypes = typeof groupTypes[number];

export enum ShopModuleGroupPageActions {
  LIST_SHOP_MODULE_GROUP_REQUEST = 'LIST_SHOP_MODULE_GROUP#REQUEST',
  LIST_SHOP_MODULE_GROUP_SUCCESS = 'LIST_SHOP_MODULE_GROUP#SUCCESS',
  LIST_SHOP_MODULE_GROUP_FAILURE = 'LIST_SHOP_MODULE_GROUP#FAILURE',

  CREATE_SHOP_MODULE_GROUP_REQUEST = 'CREATE_SHOP_MODULE_GROUP#REQUEST',
  CREATE_SHOP_MODULE_GROUP_SUCCESS = 'CREATE_SHOP_MODULE_GROUP#SUCCESS',
  CREATE_SHOP_MODULE_GROUP_FAILURE = 'CREATE_SHOP_MODULE_GROUP#FAILURE',

  UPDATE_SHOP_MODULE_GROUP_REQUEST = 'UPDATE_SHOP_MODULE_GROUP#REQUEST',
  UPDATE_SHOP_MODULE_GROUP_SUCCESS = 'UPDATE_SHOP_MODULE_GROUP#SUCCESS',
  UPDATE_SHOP_MODULE_GROUP_FAILURE = 'UPDATE_SHOP_MODULE_GROUP#FAILURE',

  DELETE_SHOP_MODULE_GROUP_REQUEST = 'DELETE_SHOP_MODULE_GROUP#REQUEST',
  DELETE_SHOP_MODULE_GROUP_SUCCESS = 'DELETE_SHOP_MODULE_GROUP#SUCCESS',
  DELETE_SHOP_MODULE_GROUP_FAILURE = 'DELETE_SHOP_MODULE_GROUP#FAILURE',

  READ_SHOP_MODULE_GROUP_REQUEST = 'READ_SHOP_MODULE_GROUP#REQUEST',
  READ_SHOP_MODULE_GROUP_SUCCESS = 'READ_SHOP_MODULE_GROUP#SUCCESS',
  READ_SHOP_MODULE_GROUP_FAILURE = 'READ_SHOP_MODULE_GROUP#FAILURE',

  CREATE_SHOP_MODULE_NESTED_GROUP_REQUEST = 'CREATE_SHOP_MODULE_NESTED_GROUP#REQUEST',
  CREATE_SHOP_MODULE_NESTED_GROUP_SUCCESS = 'CREATE_SHOP_MODULE_NESTED_GROUP#SUCCESS',
  CREATE_SHOP_MODULE_NESTED_GROUP_FAILURE = 'CREATE_SHOP_MODULE_NESTED_GROUP#FAILURE',

  UPDATE_SHOP_MODULE_NESTED_GROUP_REQUEST = 'UPDATE_SHOP_MODULE_NESTED_GROUP#REQUEST',
  UPDATE_SHOP_MODULE_NESTED_GROUP_SUCCESS = 'UPDATE_SHOP_MODULE_NESTED_GROUP#SUCCESS',
  UPDATE_SHOP_MODULE_NESTED_GROUP_FAILURE = 'UPDATE_SHOP_MODULE_NESTED_GROUP#FAILURE',

  DELETE_SHOP_MODULE_NESTED_GROUP_REQUEST = 'DELETE_SHOP_MODULE_NESTED_GROUP#REQUEST',
  DELETE_SHOP_MODULE_NESTED_GROUP_SUCCESS = 'DELETE_SHOP_MODULE_NESTED_GROUP#SUCCESS',
  DELETE_SHOP_MODULE_NESTED_GROUP_FAILURE = 'DELETE_SHOP_MODULE_NESTED_GROUP#FAILURE',
}

export interface ShopModuleGroup {
  name: string;
  type: string;
  groups: ShopModuleNestedGroup[];
}

export interface ShopModuleNestedGroup {
  id: number;
  name: string;
  moduleId: number;
  moduleName: number;
  segmentCode: string;
  subscribingGooglePlayProductId: string;
  subscribingAppStoreProductId: string;
  priority: number;
}

export type ShopModuleGroupFormValues = ShopModuleGroup;
export type ShopModuleGroupRequestData = ShopModuleGroupFormValues;
export type ShopModuleGroupResponseData = ShopModuleGroup;

export type ShopModuleNestedGroupFormValues = Omit<Omit<ShopModuleNestedGroup, 'id'>, 'moduleName'>;
export type ShopModuleNestedGroupRequestData = ShopModuleNestedGroupFormValues;
export type ShopModuleNestedGroupResponseData = ShopModuleNestedGroup;

export interface ShopModuleGroupItem extends ShopModuleGroupFormValues {}

export interface ShopModuleGroupState {
  list: ListState<ShopModuleGroupItem>;
  detail?: ShopModuleGroup;
}

export const initialState: ShopModuleGroupState = {
  list: initialListState,
  detail: undefined,
};
