import { createReducer } from 'typesafe-actions';

import * as visitorAbTestActions from 'store/visitorAbTest/actions';
import * as visitorAbTestCriteriaActions from 'store/visitorAbTestCriteria/actions';
import { initialState, VisitorAbTestState } from 'store/visitorAbTest/types';
import { RootAction } from 'store/root/types';
import { VisitorAbTestCriteria } from 'store/visitorAbTestCriteria/types';

const VisitorAbTest = createReducer<VisitorAbTestState, RootAction>(initialState)
  .handleAction(visitorAbTestActions.listVisitorAbTest.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(visitorAbTestActions.listVisitorAbTest.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(visitorAbTestActions.readVisitorAbTest.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(visitorAbTestCriteriaActions.createVisitorAbTestCriteria.success, (state, { payload }) => {
    const criteria = state?.detail?.criteria ?? [];
    const newCriteria = [...criteria, payload];

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            criteria: newCriteria,
          }
        : undefined,
    };
  })
  .handleAction(visitorAbTestCriteriaActions.updateVisitorAbTestCriteria.success, (state, { payload }) => {
    const criteria = state?.detail?.criteria ?? [];
    const updatedScheduleIndex = criteria.findIndex((criteria: VisitorAbTestCriteria) => criteria.id === payload.id);

    if (updatedScheduleIndex !== -1) {
      criteria[updatedScheduleIndex] = payload;
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            criteria: criteria,
          }
        : undefined,
    };
  })
  .handleAction(visitorAbTestCriteriaActions.deleteVisitorAbTestCriteria.success, (state, { payload }) => {
    const criteria = state?.detail?.criteria ?? [];
    const newCriteria = criteria.filter((criteria: VisitorAbTestCriteria) => criteria.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            criteria: newCriteria,
          }
        : undefined,
    };
  });

export default VisitorAbTest;
