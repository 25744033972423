import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createListEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { deleteCriteriaSegment, listCriteriaSegment, readCriteriaSegment, updateCriteriaSegment } from './actions';
import {
  deleteCriteriaSegmentApi,
  listCriteriaSegmentApi,
  readCriteriaSegmentApi,
  updateCriteriaSegmentApi,
} from './api';

const updateCriteriaSegmentToastEpic = createToastEpic(updateCriteriaSegment.success, 'Successfully saved');

const listCriteriaSegmentEpic = createListEpic(listCriteriaSegment, listCriteriaSegmentApi);

const readCriteriaSegmentEpic = createReadEpic(readCriteriaSegment, readCriteriaSegmentApi);

const updateCriteriaSegmentEpic = createUpdateEpic(updateCriteriaSegment, updateCriteriaSegmentApi);

const deleteCriteriaSegmentEpic = createDeleteEpic(deleteCriteriaSegment, deleteCriteriaSegmentApi);

export default combineEpics(
  updateCriteriaSegmentToastEpic,
  listCriteriaSegmentEpic,
  readCriteriaSegmentEpic,
  updateCriteriaSegmentEpic,
  deleteCriteriaSegmentEpic
);
