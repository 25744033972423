import { createAsyncEpic, createToastEpic } from 'store/utils';
import {
  createShopModuleGroupApi,
  createShopModuleNestedGroupApi,
  deleteShopModuleGroupApi,
  deleteShopModuleNestedGroupApi,
  listShopModuleGroupsApi,
  readShopModuleGroupApi,
  updateShopModuleGroupApi,
  updateShopModuleNestedGroupApi,
} from './api';
import {
  createShopModuleGroup,
  createShopModuleNestedGroup,
  deleteShopModuleGroup,
  deleteShopModuleNestedGroup,
  listShopModuleGroup,
  readShopModuleGroup,
  updateShopModuleGroup,
  updateShopModuleNestedGroup,
} from './actions';
import { combineEpics } from 'redux-observable';

export const listShopModuleGroupsEpic = createAsyncEpic(listShopModuleGroup, listShopModuleGroupsApi);
export const createShopModuleGroupEpic = createAsyncEpic(createShopModuleGroup, createShopModuleGroupApi);
export const updateShopModuleGroupEpic = createAsyncEpic(updateShopModuleGroup, updateShopModuleGroupApi);
export const readShopModuleGroupEpic = createAsyncEpic(readShopModuleGroup, readShopModuleGroupApi);
export const deleteShopModuleGroupEpic = createAsyncEpic(deleteShopModuleGroup, deleteShopModuleGroupApi);
export const createShopModuleNestedGroupEpic = createAsyncEpic(
  createShopModuleNestedGroup,
  createShopModuleNestedGroupApi
);
export const updateShopModuleNestedGroupEpic = createAsyncEpic(
  updateShopModuleNestedGroup,
  updateShopModuleNestedGroupApi
);
export const deleteShopModuleNestedGroupEpic = createAsyncEpic(
  deleteShopModuleNestedGroup,
  deleteShopModuleNestedGroupApi
);

export const createShopModuleGroupToastEpic = createToastEpic(
  createShopModuleGroup.success,
  'Shop module group saved successfully'
);
export const updateShopModuleGroupToastEpic = createToastEpic(
  updateShopModuleGroup.success,
  'Shop module group updated successfully'
);
export const deleteShopModuleGroupToastEpic = createToastEpic(
  deleteShopModuleGroup.success,
  'Shop module group deleted successfully'
);

export const createShopModuleNestedGroupToastEpic = createToastEpic(
  createShopModuleNestedGroup.success,
  'Shop module nested group saved successfully'
);
export const updateShopModuleNestedGroupToastEpic = createToastEpic(
  updateShopModuleNestedGroup.success,
  'Shop module nested group updated successfully'
);
export const deleteShopModuleNestedGroupToastEpic = createToastEpic(
  deleteShopModuleNestedGroup.success,
  'Shop module nested group deleted successfully'
);

export default combineEpics(
  listShopModuleGroupsEpic,
  readShopModuleGroupEpic,
  createShopModuleGroupEpic,
  updateShopModuleGroupEpic,
  deleteShopModuleGroupEpic,
  createShopModuleNestedGroupEpic,
  updateShopModuleNestedGroupEpic,
  deleteShopModuleNestedGroupEpic,
  createShopModuleGroupToastEpic,
  updateShopModuleGroupToastEpic,
  deleteShopModuleGroupToastEpic,
  createShopModuleNestedGroupToastEpic,
  updateShopModuleNestedGroupToastEpic,
  deleteShopModuleNestedGroupToastEpic
);
