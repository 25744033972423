import LogRocket from 'logrocket';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ActionType, createReducer } from 'typesafe-actions';

import { setToken } from 'utils/axios';

import * as userActions from './actions';
import { initialState, User } from './types';

const userPersistConfig = {
  key: 'user',
  storage,
};

const user = createReducer<User, ActionType<typeof userActions>>(initialState)
  .handleAction(userActions.signOut, () => {
    setToken('');
    return { ...initialState };
  })
  .handleAction(
    userActions.fetchSignIn.success,
    (state, { payload: { token, refreshToken, roles, username, email } }) => {
      setToken(token);
      if (email) {
        LogRocket.identify(email, { email });
      }
      return {
        ...state,
        access: token,
        refresh: refreshToken,
        roles,
        username,
        email,
      };
    }
  )
  .handleAction(userActions.refreshJWT.success, (state, { payload: { token } }) => {
    setToken(token);
    return {
      ...state,
      access: token,
    };
  });

export default persistReducer(userPersistConfig, user);
