import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as ConvertUserKeyActions from './actions';
import { ConvertUserKeyState, initialState } from './types';

const convertUserKey = createReducer<ConvertUserKeyState, RootAction>(initialState)
  .handleAction(ConvertUserKeyActions.convertUsernamesToUserIds.success, (state, { payload: { userIds } }) => ({
    result: userIds,
  }))
  .handleAction(ConvertUserKeyActions.convertUserIdsToUsernames.success, (state, { payload: { usernames } }) => ({
    result: usernames,
  }))
  .handleAction(ConvertUserKeyActions.resetConvertUserKeyResult, () => ({ ...initialState }));

export default convertUserKey;
