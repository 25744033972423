import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as notificationContentActions from './actions';
import { initialState, NotificationContentState } from './types';

const notificationContent = createReducer<NotificationContentState, RootAction>(initialState)
  .handleAction(notificationContentActions.readNotificationContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(notificationContentActions.updateNotificationContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(
    notificationContentActions.listNotificationContent.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(notificationContentActions.listNotificationContent.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default notificationContent;
