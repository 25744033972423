import { combineEpics, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ActionType, isActionOf } from 'typesafe-actions';

import { layoutActions } from 'store/layout/slice';
import { ToastType } from 'store/layout/types';
import { RootAction, RootState } from 'store/root/types';
import { setToken, updateToken, logout } from 'utils/okta';

import * as userActions from './actions';

const signInEpic: Epic<RootAction, ActionType<typeof userActions.fetchSignIn.success>, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(userActions.fetchSignIn.request)),
    mergeMap((action) =>
      from(setToken(action.payload)).pipe(map((payload) => userActions.fetchSignIn.success(payload)))
    )
  );

const refreshJWTEpic: Epic<
  RootAction,
  | ActionType<typeof userActions.refreshJWT.success>
  | ActionType<typeof userActions.refreshJWT.failure>
  | ActionType<typeof userActions.signOut>
  | ActionType<typeof layoutActions.makeToast>,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(userActions.refreshJWT.request)),
    mergeMap((action) =>
      from(updateToken()).pipe(
        map((token) =>
          userActions.refreshJWT.success({
            token,
            action: action.payload.action,
          })
        ),
        catchError(() => [
          layoutActions.makeToast({
            type: ToastType.error,
            message: 'token has been expired',
          }),
          userActions.refreshJWT.failure(),
          userActions.signOut(),
        ])
      )
    )
  );

const retryApiEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(userActions.refreshJWT.success)),
    map((action) => action.payload.action)
  );

const removeJWTEpic: Epic<RootAction, never, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(userActions.signOut)),
    mergeMap(() => from(logout()).pipe(mergeMap(() => [])))
  );

export default combineEpics(signInEpic, refreshJWTEpic, retryApiEpic, removeJWTEpic);
