import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  WebDecoSettingScheduleCreateRequestData,
  WebDecoSettingScheduleCreateResponseData,
  WebDecoSettingScheduleFormValues,
  WebDecoSettingScheduleUpdateRequestData,
  WebDecoSettingScheduleUpdateResponseData,
} from './types';

import { WEB_DECO_SETTING_API_PREFIX } from '../webDecoSetting/types';

const WEB_DECO_SETTING_SCHEDULES_API_PREFIX = `${WEB_DECO_SETTING_API_PREFIX}/schedules`;

export const createWebDecoSettingScheduleApi = createPostApi<
  WebDecoSettingScheduleFormValues,
  WebDecoSettingScheduleCreateRequestData,
  WebDecoSettingScheduleCreateResponseData
>(WEB_DECO_SETTING_SCHEDULES_API_PREFIX);

export const updateWebDecoSettingScheduleApi = createUpdateApi<
  WebDecoSettingScheduleFormValues,
  WebDecoSettingScheduleUpdateRequestData,
  WebDecoSettingScheduleUpdateResponseData
>(`${WEB_DECO_SETTING_SCHEDULES_API_PREFIX}/:id`);

export const deleteWebDecoSettingScheduleApi = createDeleteApi(`${WEB_DECO_SETTING_SCHEDULES_API_PREFIX}/:id`);
