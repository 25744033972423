import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  GenderManageActions as Actions,
  GenderManageUpdateRequestData,
  GenderManageUpdateRequestFormValues,
} from './types';

export const updateGenderManage = createAsyncAction(
  Actions.UPDATE_GENDER_MANAGE_REQUEST,
  Actions.UPDATE_GENDER_MANAGE_SUCCESS,
  Actions.UPDATE_GENDER_MANAGE_FAILURE
)<FormRequestPayload<GenderManageUpdateRequestFormValues, GenderManageUpdateRequestData>, undefined, AxiosError>();
