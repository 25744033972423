import { ActionType, createReducer } from 'typesafe-actions';

import * as localizationKeyActions from 'store/localizationKey/actions';
import { initialState, LocalizationKeyState } from 'store/localizationKey/types';

type Action = ActionType<typeof localizationKeyActions>;

const localizationKey = createReducer<LocalizationKeyState, Action>(initialState)
  .handleAction(localizationKeyActions.listLocalizationKey.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(localizationKeyActions.listLocalizationKey.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default localizationKey;
