import { FlatListState, initialListState, ListState } from 'store/types';
import { WebDecoEffectGroupId } from 'store/webDecoSetting/types';

export enum WebDecoEffectActions {
  LIST_WEB_DECO_EFFECT_REQUEST = 'LIST_WEB_DECO_EFFECT#REQUEST',
  LIST_WEB_DECO_EFFECT_SUCCESS = 'LIST_WEB_DECO_EFFECT#SUCCESS',
  LIST_WEB_DECO_EFFECT_FAILURE = 'LIST_WEB_DECO_EFFECT#FAILURE',

  LIST_WEB_DECO_EFFECT_GROUP_REQUEST = 'LIST_WEB_DECO_EFFECT_GROUP#REQUEST',
  LIST_WEB_DECO_EFFECT_GROUP_SUCCESS = 'LIST_WEB_DECO_EFFECT_GROUP#SUCCESS',
  LIST_WEB_DECO_EFFECT_GROUP_FAILURE = 'LIST_WEB_DECO_EFFECT_GROUP#FAILURE',

  READ_WEB_DECO_EFFECT_REQUEST = 'READ_WEB_DECO_EFFECT#REQUEST',
  READ_WEB_DECO_EFFECT_SUCCESS = 'READ_WEB_DECO_EFFECT#SUCCESS',
  READ_WEB_DECO_EFFECT_FAILURE = 'READ_WEB_DECO_EFFECT#FAILURE',

  CREATE_WEB_DECO_EFFECT_REQUEST = 'CREATE_WEB_DECO_EFFECT#REQUEST',
  CREATE_WEB_DECO_EFFECT_SUCCESS = 'CREATE_WEB_DECO_EFFECT#SUCCESS',
  CREATE_WEB_DECO_EFFECT_FAILURE = 'CREATE_WEB_DECO_EFFECT#FAILURE',

  UPDATE_WEB_DECO_EFFECT_REQUEST = 'UPDATE_WEB_DECO_EFFECT#REQUEST',
  UPDATE_WEB_DECO_EFFECT_SUCCESS = 'UPDATE_WEB_DECO_EFFECT#SUCCESS',
  UPDATE_WEB_DECO_EFFECT_FAILURE = 'UPDATE_WEB_DECO_EFFECT#FAILURE',

  REFRESH_WEB_DECO_EFFECT_REQUEST = 'REFRESH_DECO_ITEM#REQUEST',
  REFRESH_WEB_DECO_EFFECT_SUCCESS = 'REFRESH_DECO_ITEM#SUCCESS',
  REFRESH_WEB_DECO_EFFECT_FAILURE = 'REFRESH_DECO_ITEM#FAILURE',
}

export const initialState: WebDecoEffectState = {
  list: initialListState,
  groups: [],
  detail: undefined,
};

export interface WebDecoEffectState {
  list: ListState<WebDecoEffectListItem>;
  groups: FlatListState<WebDecoEffectGroup>;
  detail?: WebDecoEffectDetail;
}

export enum EffectRenderer {
  BACKGROUND = 'Background',
  BACKGROUND_BLUR = 'BackgroundBlur',
  SKIN_SMOOTH = 'SkinSmooth',
  STICKER_3D = '3DSticker',
  STICKER_2D = '2DSticker',
  MASK = 'Mask',
  LIQUIFY = 'Liquify',
  STRETCH = 'Stretch',
  LUT_FILTER = 'LutFilter',
}

export interface WebDecoEffect {
  groupId: string;
  effectId: string;
  description?: string;
  resourceUrl?: string;
  md5Hash?: string;
  encryptionKey?: string;
  thumbnailImageUrl?: string;
  occludingEnable?: boolean;
  directLightEnable?: boolean;
  effectRenderer: EffectRenderer[];
  newBadgeEnabled: boolean;
}

export interface WebDecoEffectGroup {
  groupId: WebDecoEffectGroupId;
}

export type WebDecoEffectListItem = Omit<WebDecoEffect, 'resourceUrl' | 'md5Hash' | 'encryptionKey'>;
export type WebDecoEffectFormValues = WebDecoEffect;

export type WebDecoEffectCreateRequestData = WebDecoEffectFormValues;
export type WebDecoEffectCreateSuccessData = WebDecoEffectDetail;
export type WebDecoEffectCreateResponseData = WebDecoEffectDetail;

export interface WebDecoEffectUpdateRequestData extends Omit<WebDecoEffectDetail, 'effectId'> {}
export interface WebDecoEffectUpdateSuccessData extends Omit<WebDecoEffectDetail, 'effectId'> {}
export interface WebDecoEffectUpdateResponseData extends Omit<WebDecoEffectDetail, 'effectId'> {}

export type WebDecoEffectDetail = WebDecoEffect;
