import { combineEpics } from 'redux-observable';

import { createFormEpic, createToastEpic } from 'store/utils';

import { publishCoupon, publishGroupCoupon, publishGroupSegmentCoupon, publishReusableCoupon } from './actions';
import { createCouponApi, createGroupCouponApi, createGroupSegmentCouponApi, createReusableCouponApi } from './api';

const publishReusableCouponToastEpic = createToastEpic(publishReusableCoupon.success, 'Successfully generated');
const publishReusableCouponEpic = createFormEpic(publishReusableCoupon, createReusableCouponApi);

const publishGroupCouponToastEpic = createToastEpic(publishGroupCoupon.success, 'Successfully generated');
const publishGroupCouponEpic = createFormEpic(publishGroupCoupon, createGroupCouponApi);

const publishGroupSegmentCouponToastEpic = createToastEpic(publishGroupSegmentCoupon.success, 'Successfully generated');
const publishGroupSegmentCouponEpic = createFormEpic(publishGroupSegmentCoupon, createGroupSegmentCouponApi);

const publishCouponToastEpic = createToastEpic(publishCoupon.success, 'Successfully generated');
const publishCouponEpic = createFormEpic(publishCoupon, createCouponApi);

export default combineEpics(
  publishReusableCouponToastEpic,
  publishReusableCouponEpic,
  publishGroupCouponToastEpic,
  publishGroupCouponEpic,
  publishGroupSegmentCouponToastEpic,
  publishGroupSegmentCouponEpic,
  publishCouponToastEpic,
  publishCouponEpic
);
