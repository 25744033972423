import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ShopTabPageActions as Actions,
  ShopTabFormValue,
  ShopTabRequestData,
  ShopTabResponseData,
  ShopTabListItem,
  ShopTab,
  ShopTabScheduleFormValues,
  ShopTabScheduleCreateRequestData,
  ShopTabScheduleCreateResponseData,
  ShopTabScheduleUpdateRequestData,
  ShopTabScheduleUpdateResponseData,
} from './types';

export const createShopTab = createAsyncAction(
  Actions.CREATE_SHOP_TAB_PAGE_REQUEST,
  Actions.CREATE_SHOP_TAB_PAGE_SUCCESS,
  Actions.CREATE_SHOP_TAB_PAGE_FAILURE
)<FormRequestPayload<ShopTabFormValue, ShopTabRequestData>, ShopTabResponseData, AxiosError>();

export const listShopTab = createAsyncAction(
  Actions.LIST_SHOP_TAB_PAGE_REQUEST,
  Actions.LIST_SHOP_TAB_PAGE_SUCCESS,
  Actions.LIST_SHOP_TAB_PAGE_FAILURE
)<ListRequestPayload, ListState<ShopTabListItem>, AxiosError>();

export const readShopTab = createAsyncAction(
  Actions.READ_SHOP_TAB_PAGE_REQUEST,
  Actions.READ_SHOP_TAB_PAGE_SUCCESS,
  Actions.READ_SHOP_TAB_PAGE_FAILURE
)<ReadRequestPayload, ShopTab, AxiosError>();

export const updateShopTab = createAsyncAction(
  Actions.UPDATE_SHOP_TAB_PAGE_REQUEST,
  Actions.UPDATE_SHOP_TAB_PAGE_SUCCESS,
  Actions.UPDATE_SHOP_TAB_PAGE_FAILURE
)<FormRequestPayload<ShopTabFormValue, ShopTabRequestData>, ShopTabResponseData, AxiosError>();

export const deleteShopTab = createAsyncAction(
  Actions.DELETE_SHOP_TAB_PAGE_REQUEST,
  Actions.DELETE_SHOP_TAB_PAGE_SUCCESS,
  Actions.DELETE_SHOP_TAB_PAGE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - SCHEDULE -
export const createShopTabSchedule = createAsyncAction(
  Actions.CREATE_SHOP_TAB_PAGE_SCHEDULE_REQUEST,
  Actions.CREATE_SHOP_TAB_PAGE_SCHEDULE_SUCCESS,
  Actions.CREATE_SHOP_TAB_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopTabScheduleFormValues, ShopTabScheduleCreateRequestData>,
  ShopTabScheduleCreateResponseData,
  AxiosError
>();

export const updateShopTabSchedule = createAsyncAction(
  Actions.UPDATE_SHOP_TAB_PAGE_SCHEDULE_REQUEST,
  Actions.UPDATE_SHOP_TAB_PAGE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SHOP_TAB_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopTabScheduleFormValues, ShopTabScheduleUpdateRequestData>,
  ShopTabScheduleUpdateResponseData,
  AxiosError
>();

export const deleteShopTabSchedule = createAsyncAction(
  Actions.DELETE_SHOP_TAB_PAGE_SCHEDULE_REQUEST,
  Actions.DELETE_SHOP_TAB_PAGE_SCHEDULE_SUCCESS,
  Actions.DELETE_SHOP_TAB_PAGE_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
