export enum ItemPackageProvideActions {
  PROVIDE_ITEM_PACKAGE_REQUEST = 'PROVIDE_ITEM_PACKAGE#REQUEST',
  PROVIDE_ITEM_PACKAGE_SUCCESS = 'PROVIDE_ITEM_PACKAGE#SUCCESS',
  PROVIDE_ITEM_PACKAGE_FAILURE = 'PROVIDE_ITEM_PACKAGE#FAILURE',
}

export interface ItemPackageRequestFormValues {
  userId: number;
  code: string;
  expireTime?: string;
}
