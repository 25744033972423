import {
  NotificationCenterBannerScheduleCreateRequestData,
  NotificationCenterBannerScheduleCreateSuccessData,
  NotificationCenterBannerScheduleUpdateRequestData,
  NotificationCenterBannerScheduleUpdateSuccessData,
} from 'store/notificationCenterBannerSchedule/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createNotificationCenterBannerScheduleApi = createPostApi<
  NotificationCenterBannerScheduleCreateRequestData,
  NotificationCenterBannerScheduleCreateSuccessData
>('/banners/notification-center/schedules');

export const updateNotificationCenterBannerScheduleApi = createUpdateApi<
  NotificationCenterBannerScheduleUpdateRequestData,
  NotificationCenterBannerScheduleUpdateSuccessData
>('/banners/notification-center/schedules/:id');

export const deleteNotificationCenterBannerScheduleApi = createDeleteApi('/banners/notification-center/schedules/:id');
