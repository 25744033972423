import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { listPromotionPublishHistory, readPromotionPublishHistory, updatePromotionPublishHistory } from './actions';
import {
  listPromotionPublishHistoryApi,
  readPromotionPublishHistoryApi,
  updatePromotionPublishHistoryApi,
} from './api';

const listPromotionPublishHistoryEpic = createListEpic(listPromotionPublishHistory, listPromotionPublishHistoryApi);

const readPromotionPublishHistoryEpic = createReadEpic(readPromotionPublishHistory, readPromotionPublishHistoryApi);

const updatePromotionPublishHistoryEpic = createUpdateEpic(
  updatePromotionPublishHistory,
  updatePromotionPublishHistoryApi
);

const updatePromotionPublishHistoryToastEpic = createToastEpic(
  updatePromotionPublishHistory.success,
  'Successfully saved'
);

export default combineEpics(
  listPromotionPublishHistoryEpic,
  readPromotionPublishHistoryEpic,
  updatePromotionPublishHistoryEpic,
  updatePromotionPublishHistoryToastEpic
);
