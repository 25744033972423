export enum AbTestCriteriaActions {
  CREATE_SCHEDULE_REQUEST = 'CREATE_AB_TEST_CRITERIA#REQUEST',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_AB_TEST_CRITERIA#SUCCESS',
  CREATE_SCHEDULE_FAILURE = 'CREATE_AB_TEST_CRITERIA#FAILURE',

  UPDATE_SCHEDULE_REQUEST = 'UPDATE_AB_TEST_CRITERIA#REQUEST',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_AB_TEST_CRITERIA#SUCCESS',
  UPDATE_SCHEDULE_FAILURE = 'UPDATE_AB_TEST_CRITERIA#FAILURE',

  DELETE_SCHEDULE_REQUEST = 'DELETE_AB_TEST_CRITERIA#REQUEST',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_AB_TEST_CRITERIA#SUCCESS',
  DELETE_SCHEDULE_FAILURE = 'DELETE_AB_TEST_CRITERIA#FAILURE',
}

export interface AbTestCriteria {
  id: number;
  testName: string;
  targetGroupName: string;
  hashModularValue: number;
  gaCustomDimensionValue: string;
  targetSegmentCode: string;
}

export type AbTestCriteriaCreateRequestData = Omit<AbTestCriteria, 'id'>;
export type AbTestCriteriaCreateSuccessData = AbTestCriteria;
export type AbTestCriteriaUpdateRequestData = Omit<AbTestCriteria, 'id'>;
export type AbTestCriteriaUpdateSuccessData = AbTestCriteria;
