import { combineEpics } from 'redux-observable';

import { createListEpic } from 'store/utils';

import { listMatataSuspensionHistory } from './actions';

import { matataSuspensionHistoryApi } from './api';

export const matataSuspensionHistoryEpic = createListEpic(listMatataSuspensionHistory, matataSuspensionHistoryApi);

export default combineEpics(matataSuspensionHistoryEpic);
