import { initialListState, ListState } from 'store/types';

export enum MoniNoticeActions {
  LIST_MONI_NOTICE_REQUEST = 'LIST_MONI_NOTICE#REQUEST',
  LIST_MONI_NOTICE_SUCCESS = 'LIST_MONI_NOTICE#SUCCESS',
  LIST_MONI_NOTICE_FAILURE = 'LIST_MONI_NOTICE#FAILURE',

  DETAIL_MONI_NOTICE_REQUEST = 'DETAIL_MONI_NOTICE_REQUEST#REQUEST',
  DETAIL_MONI_NOTICE_SUCCESS = 'DETAIL_MONI_NOTICE_REQUEST#SUCCESS',
  DETAIL_MONI_NOTICE_FAILURE = 'DETAIL_MONI_NOTICE_REQUEST#FAILURE',

  POST_MONI_NOTICE_REQUEST = 'POST_MONI_NOTICE#REQUEST',
  POST_MONI_NOTICE_SUCCESS = 'POST_MONI_NOTICE#SUCCESS',
  POST_MONI_NOTICE_FAILURE = 'POST_MONI_NOTICE#FAILURE',
}

export interface MoniNoticeItem {
  id: string;
  title: string;
  date: string;
  content: string;
  manager: string;
}

export interface MoniNoticeState {
  list: ListState<MoniNoticeItem>;
  detail?: MoniNoticeItem;
}

export const initialState: MoniNoticeState = {
  list: initialListState,
  detail: undefined,
};

export interface MoniNoticeFormValues {
  title: string;
  content: string;
  manager: string;
}

export interface MoniNoticeCreateRequestData {
  title: string;
  content: string;
  manager: string;
}

export interface DetailMoniNoticeRequest {
  id: string;
}
