import { NotificationCenterBannerSchedule } from 'store/notificationCenterBannerSchedule/types';
import { initialListState, ListState } from 'store/types';

export enum NotificationCenterBannerActions {
  LIST_NOTIFICATION_CENTER_BANNER_REQUEST = 'LIST_NOTIFICATION_CENTER_BANNER#REQUEST',
  LIST_NOTIFICATION_CENTER_BANNER_SUCCESS = 'LIST_NOTIFICATION_CENTER_BANNER#SUCCESS',
  LIST_NOTIFICATION_CENTER_BANNER_FAILURE = 'LIST_NOTIFICATION_CENTER_BANNER#FAILURE',

  CREATE_NOTIFICATION_CENTER_BANNER_REQUEST = 'CREATE_NOTIFICATION_CENTER_BANNER#REQUEST',
  CREATE_NOTIFICATION_CENTER_BANNER_SUCCESS = 'CREATE_NOTIFICATION_CENTER_BANNER#SUCCESS',
  CREATE_NOTIFICATION_CENTER_BANNER_FAILURE = 'CREATE_NOTIFICATION_CENTER_BANNER#FAILURE',

  READ_NOTIFICATION_CENTER_BANNER_REQUEST = 'READ_NOTIFICATION_CENTER_BANNER#REQUEST',
  READ_NOTIFICATION_CENTER_BANNER_SUCCESS = 'READ_NOTIFICATION_CENTER_BANNER#SUCCESS',
  READ_NOTIFICATION_CENTER_BANNER_FAILURE = 'READ_NOTIFICATION_CENTER_BANNER#FAILURE',

  UPDATE_NOTIFICATION_CENTER_BANNER_REQUEST = 'UPDATE_NOTIFICATION_CENTER_BANNER#REQUEST',
  UPDATE_NOTIFICATION_CENTER_BANNER_SUCCESS = 'UPDATE_NOTIFICATION_CENTER_BANNER#SUCCESS',
  UPDATE_NOTIFICATION_CENTER_BANNER_FAILURE = 'UPDATE_NOTIFICATION_CENTER_BANNER#FAILURE',

  REFRESH_NOTIFICATION_CENTER_BANNER_REQUEST = 'REFRESH_NOTIFICATION_CENTER_BANNER#REQUEST',
  REFRESH_NOTIFICATION_CENTER_BANNER_SUCCESS = 'REFRESH_NOTIFICATION_CENTER_BANNER#SUCCESS',
  REFRESH_NOTIFICATION_CENTER_BANNER_FAILURE = 'REFRESH_NOTIFICATION_CENTER_BANNER#FAILURE',
}

export const initialState: NotificationCenterBannerState = {
  list: initialListState,
  detail: undefined,
};

export interface NotificationCenterBannerListItem {
  id: number;
  titleKey: string;
  titleColor: string;
  contentKey: string;
  contentColor: string;
  buttonTextKey?: string;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  backgroundColor: string;
  imageUrl?: string;
  deeplink?: string;
  priority: number;
  description: string;
  enabled: boolean;
  activated: boolean;
  notificationCenterBannerSchedules: NotificationCenterBannerSchedule[];
}

export type NotificationCenterBannerDetail = NotificationCenterBannerListItem;

export interface NotificationCenterBannerState {
  list: ListState<NotificationCenterBannerListItem>;
  detail?: NotificationCenterBannerDetail;
}

export type NotificationCenterBannerCreateRequestFormValues = Omit<
  NotificationCenterBannerListItem,
  'id' | 'notificationCenterBannerSchedules' | 'activated'
>;
export type NotificationCenterBannerCreateRequestData = Omit<
  NotificationCenterBannerListItem,
  'notificationCenterBannerSchedules' | 'activated'
>;
export type NotificationCenterBannerCreateSuccessData = NotificationCenterBannerDetail;
export type NotificationCenterBannerUpdateRequestFormValues = Omit<
  NotificationCenterBannerListItem,
  'notificationCenterBannerSchedules' | 'activated'
>;
export type NotificationCenterBannerUpdateRequestData = Omit<
  NotificationCenterBannerListItem,
  'notificationCenterBannerSchedules' | 'activated'
>;
export type NotificationCenterBannerUpdateSuccessData = NotificationCenterBannerDetail;
