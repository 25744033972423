import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createPurpose, listPurpose, readPurpose, updatePurpose } from './actions';
import { createPurposeApi, listPurposeApi, readPurposeApi, updatePurposeApi } from './api';

const listPurposeEpic = createListEpic(listPurpose, listPurposeApi);

const readPurposeEpic = createReadEpic(readPurpose, readPurposeApi);

const createPurposeEpic = createPostEpic(createPurpose, createPurposeApi);

const createPurposeToastEpic = createToastEpic(createPurpose.success, 'Successfully created');

const updatePurposeEpic = createUpdateEpic(updatePurpose, updatePurposeApi);

const updatePurposeToastEpic = createToastEpic(updatePurpose.success, 'Sucessfully updated');

export default combineEpics(
  listPurposeEpic,
  readPurposeEpic,
  createPurposeEpic,
  createPurposeToastEpic,
  updatePurposeEpic,
  updatePurposeToastEpic
);
