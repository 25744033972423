import React, { FC, useContext } from 'react';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';

import { CollapseMenuProps as Props } from 'components/Menu/types';
import MenuCollapseContext from 'contexts/menuCollapseContext';

const useStyles = makeStyles((theme) => ({
  collapseListItem: {
    height: 50,
    paddingLeft: theme.spacing(4),
  },
}));

const CollapseMenu: FC<Props> = ({ timeout = 'auto', unmountOnExit = false, collapseMenus = [], style = {} }) => {
  const classes = useStyles();

  const { isOpenCollapse } = useContext(MenuCollapseContext);

  const { pathname } = useLocation();

  return (
    <Collapse in={isOpenCollapse} timeout={timeout} unmountOnExit={unmountOnExit}>
      <List disablePadding>
        {collapseMenus.map((menu) => (
          <ListItem<typeof Link>
            key={menu.label}
            className={classes.collapseListItem}
            button
            component={Link}
            to={menu.path}
            selected={pathname === menu.path}
            style={style}
          >
            <ListItemText secondary={menu.label} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default CollapseMenu;
