import { combineEpics } from 'redux-observable';

import {
  createNotificationCenterBannerSchedule,
  deleteNotificationCenterBannerSchedule,
  updateNotificationCenterBannerSchedule,
} from 'store/notificationCenterBannerSchedule/actions';
import {
  createNotificationCenterBannerScheduleApi,
  deleteNotificationCenterBannerScheduleApi,
  updateNotificationCenterBannerScheduleApi,
} from 'store/notificationCenterBannerSchedule/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createNotificationCenterBannerScheduleToastEpic = createToastEpic(
  createNotificationCenterBannerSchedule.success,
  'Successfully created Schedule Setting'
);

const updateNotificationCenterBannerScheduleToastEpic = createToastEpic(
  updateNotificationCenterBannerSchedule.success,
  'Successfully saved Schedule Setting'
);

const deleteNotificationCenterBannerScheduleToastEpic = createToastEpic(
  deleteNotificationCenterBannerSchedule.success,
  'Successfully deleted Schedule Setting'
);

const createScheduleEpic = createPostEpic(
  createNotificationCenterBannerSchedule,
  createNotificationCenterBannerScheduleApi
);

const updateScheduleEpic = createUpdateEpic(
  updateNotificationCenterBannerSchedule,
  updateNotificationCenterBannerScheduleApi
);

const deleteScheduleEpic = createDeleteEpic(
  deleteNotificationCenterBannerSchedule,
  deleteNotificationCenterBannerScheduleApi
);

export default combineEpics(
  createNotificationCenterBannerScheduleToastEpic,
  updateNotificationCenterBannerScheduleToastEpic,
  deleteNotificationCenterBannerScheduleToastEpic,
  createScheduleEpic,
  updateScheduleEpic,
  deleteScheduleEpic
);
