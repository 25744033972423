/**
 * https://www.notion.so/hpcnt/Emoji-API-a3c268784805400d905766eeac1afca8#f659307dce364db1872e26fe202f5ac8
 */

import { createListApi, createDeleteApi } from 'store/utils';

import { PunishmentItem } from './types';

export const listPunishmentApi = createListApi<PunishmentItem>('/punish_histories/active');
export const deletePunishmentApi = createDeleteApi('/punish_histories/active/:id');
