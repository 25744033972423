import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  GemProductTierDetail,
  GemProductTierListItem,
  GemProductTierRequestData,
  GemProductTierResponseData,
} from './types';

export const createGemProductTierApi = createPostApi<GemProductTierRequestData, GemProductTierResponseData>(
  '/gem-products/tiers'
);

export const updateGemProductTierApi = createUpdateApi<
  GemProductTierRequestData,
  GemProductTierRequestData,
  GemProductTierResponseData
>('/gem-products/tiers/:id');

export const updateGemProductApi = createUpdateApi<
  GemProductTierRequestData,
  GemProductTierRequestData,
  GemProductTierResponseData
>('/gem-products');

export const listGemProductTierApi = createListApi<GemProductTierListItem>('/gem-products/tiers');

export const readGemProductTierApi = createReadApi<GemProductTierDetail>('/gem-products/tiers/:id');

export const refreshGemProductTierApi = createPostApi('/gem-products/tiers/refresh');
