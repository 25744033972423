import { createReducer } from 'typesafe-actions';

import * as matchFilterActions from 'store/matchFilter/actions';
import { initialState, MatchFilterState } from 'store/matchFilter/types';
import { RootAction } from 'store/root/types';

const matchFilter = createReducer<MatchFilterState, RootAction>(initialState).handleAction(
  matchFilterActions.listMatchFilter.success,
  (state, { payload }) => ({
    ...state,
    list: payload,
  })
);
export default matchFilter;
