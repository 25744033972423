export enum WebDecoSettingEffectActions {
  CREATE_WEB_DECO_SETTING_EFFECT_REQUEST = 'CREATE_WEB_DECO_SETTING_EFFECT#REQUEST',
  CREATE_WEB_DECO_SETTING_EFFECT_SUCCESS = 'CREATE_WEB_DECO_SETTING_EFFECT#SUCCESS',
  CREATE_WEB_DECO_SETTING_EFFECT_FAILURE = 'CREATE_WEB_DECO_SETTING_EFFECT#FAILURE',

  UPDATE_WEB_DECO_SETTING_EFFECT_REQUEST = 'UPDATE_WEB_DECO_SETTING_EFFECT#REQUEST',
  UPDATE_WEB_DECO_SETTING_EFFECT_SUCCESS = 'UPDATE_WEB_DECO_SETTING_EFFECT#SUCCESS',
  UPDATE_WEB_DECO_SETTING_EFFECT_FAILURE = 'UPDATE_WEB_DECO_SETTING_EFFECT#FAILURE',

  DELETE_WEB_DECO_SETTING_EFFECT_REQUEST = 'DELETE_WEB_DECO_SETTING_EFFECT#REQUEST',
  DELETE_WEB_DECO_SETTING_EFFECT_SUCCESS = 'DELETE_WEB_DECO_SETTING_EFFECT#SUCCESS',
  DELETE_WEB_DECO_SETTING_EFFECT_FAILURE = 'DELETE_WEB_DECO_SETTING_EFFECT#FAILURE',
}

export interface WebDecoSettingEffect {
  id: number;
  groupId: string;
  effectId: string;
  displayOrder: number;
  settingId: number;
}

export type WebDecoSettingEffectFormValues = Omit<WebDecoSettingEffect, 'id'>;

export type WebDecoSettingEffectCreateRequestData = Omit<WebDecoSettingEffect, 'id' | 'groupId'>;

export type WebDecoSettingEffectCreateResponseData = Omit<WebDecoSettingEffect, 'id' | 'groupId'>;

export type WebDecoSettingEffectUpdateRequestData = WebDecoSettingEffectCreateRequestData;

export type WebDecoSettingEffectUpdateResponseData = WebDecoSettingEffectCreateResponseData;
