import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from '../utils';

import {
  createVisitorConfig,
  deleteVisitorConfig,
  listVisitorConfig,
  readVisitorConfig,
  updateVisitorConfig,
} from './actions';
import {
  createVisitorConfigApi,
  deleteVisitorConfigApi,
  listVisitorConfigApi,
  readVisitorConfigApi,
  updateVisitorConfigApi,
} from './api';

const createVisitorConfigEpic = createPostEpic(createVisitorConfig, createVisitorConfigApi);
const readVisitorConfigEpic = createReadEpic(readVisitorConfig, readVisitorConfigApi);
const updateVisitorConfigEpic = createUpdateEpic(updateVisitorConfig, updateVisitorConfigApi);
const listVisitorConfigEpic = createListEpic(listVisitorConfig, listVisitorConfigApi);
const deleteVisitorConfigEpic = createDeleteEpic(deleteVisitorConfig, deleteVisitorConfigApi);

const updateVisitorConfigToastEpic = createToastEpic(updateVisitorConfig.success, 'Successfully saved');

const createVisitorConfigToastEpic = createToastEpic(createVisitorConfig.success, 'Successfully created');

export default combineEpics(
  updateVisitorConfigToastEpic,
  createVisitorConfigToastEpic,
  createVisitorConfigEpic,
  readVisitorConfigEpic,
  updateVisitorConfigEpic,
  listVisitorConfigEpic,
  deleteVisitorConfigEpic
);
