import { combineEpics } from 'redux-observable';

import {
  createMlConfigModelSegment,
  deleteMlConfigModelSegment,
  updateMlConfigModelSegment,
} from 'store/mlConfigModelSegment/actions';
import {
  createMlConfigModelSegmentApi,
  deleteMlConfigModelSegmentApi,
  updateMlConfigModelSegmentApi,
} from 'store/mlConfigModelSegment/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createMlConfigModelSegmentToastEpic = createToastEpic(
  createMlConfigModelSegment.success,
  'Successfully created Segment Setting'
);

const updateMlConfigModelSegmentToastEpic = createToastEpic(
  updateMlConfigModelSegment.success,
  'Successfully saved Segment Setting'
);

const deleteMlConfigModelSegmentToastEpic = createToastEpic(
  deleteMlConfigModelSegment.success,
  'Successfully deleted Segment Setting'
);

const createSegmentEpic = createPostEpic(createMlConfigModelSegment, createMlConfigModelSegmentApi);

const updateSegmentEpic = createUpdateEpic(updateMlConfigModelSegment, updateMlConfigModelSegmentApi);

const deleteSegmentEpic = createDeleteEpic(deleteMlConfigModelSegment, deleteMlConfigModelSegmentApi);

export default combineEpics(
  createMlConfigModelSegmentToastEpic,
  updateMlConfigModelSegmentToastEpic,
  deleteMlConfigModelSegmentToastEpic,
  createSegmentEpic,
  updateSegmentEpic,
  deleteSegmentEpic
);
