import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as itemProductActions from './actions';
import { initialState, ItemProductState } from './types';

const itemProduct = createReducer<ItemProductState, RootAction>(initialState)
  .handleAction(itemProductActions.listItemProduct.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(itemProductActions.listItemProduct.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default itemProduct;
