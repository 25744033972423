import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, ReadRequestPayload } from '../types';

import {
  MatataDeviceUnbindingActions as Actions,
  MatataDeviceUnbindingRequestPayload,
  MatataDeviceUnbindingResponseData,
  MatataDeviceUnbindingItem,
} from './types';

export const listMatataDeviceUnbinding = createAsyncAction(
  Actions.LIST_MATATA_DEVICE_UNBINDING_REQUEST,
  Actions.LIST_MATATA_DEVICE_UNBINDING_SUCCESS,
  Actions.LIST_MATATA_DEVICE_UNBINDING_FAILURE
)<MatataDeviceUnbindingRequestPayload, MatataDeviceUnbindingResponseData, AxiosError>();

export const readMatataDeviceUnbinding = createAsyncAction(
  Actions.READ_MATATA_DEVICE_UNBINDING_REQUEST,
  Actions.READ_MATATA_DEVICE_UNBINDING_SUCCESS,
  Actions.READ_MATATA_DEVICE_UNBINDING_FAILURE
)<ReadRequestPayload, MatataDeviceUnbindingItem, AxiosError>();

export const deleteMatataDeviceUnbinding = createAsyncAction(
  Actions.DELETE_MATATA_DEVICE_UNBINDING_REQUEST,
  Actions.DELETE_MATATA_DEVICE_UNBINDING_SUCCESS,
  Actions.DELETE_MATATA_DEVICE_UNBINDING_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
