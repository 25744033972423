import { ShopSectionType } from 'store/shopSectionPage/types';
import { initialListState, ListState } from 'store/types';

export enum ShopDetailPageActions {
  LIST_SHOP_DETAIL_PAGE_REQUEST = 'LIST_SHOP_DETAIL_PAGE#REQUEST',
  LIST_SHOP_DETAIL_PAGE_SUCCESS = 'LIST_SHOP_DETAIL_PAGE#SUCCESS',
  LIST_SHOP_DETAIL_PAGE_FAILURE = 'LIST_SHOP_DETAIL_PAGE#FAILURE',

  READ_SHOP_DETAIL_PAGE_REQUEST = 'READ_SHOP_DETAIL_PAGE#REQUEST',
  READ_SHOP_DETAIL_PAGE_FAILURE = 'READ_SHOP_DETAIL_PAGE#FAILURE',
  READ_SHOP_DETAIL_PAGE_SUCCESS = 'READ_SHOP_DETAIL_PAGE#SUCCESS',

  CREATE_SHOP_DETAIL_PAGE_REQUEST = 'CREATE_SHOP_DETAIL_PAGE#REQUEST',
  CREATE_SHOP_DETAIL_PAGE_SUCCESS = 'CREATE_SHOP_DETAIL_PAGE#SUCCESS',
  CREATE_SHOP_DETAIL_PAGE_FAILURE = 'CREATE_SHOP_DETAIL_PAGE#FAILURE',

  UPDATE_SHOP_DETAIL_PAGE_REQUEST = 'UPDATE_SHOP_DETAIL_PAGE#REQUEST',
  UPDATE_SHOP_DETAIL_PAGE_SUCCESS = 'UPDATE_SHOP_DETAIL_PAGE#SUCCESS',
  UPDATE_SHOP_DETAIL_PAGE_FAILURE = 'UPDATE_SHOP_DETAIL_PAGE#FAILURE',

  DELETE_SHOP_DETAIL_PAGE_REQUEST = 'DELETE_SHOP_DETAIL_PAGE#REQUEST',
  DELETE_SHOP_DETAIL_PAGE_SUCCESS = 'DELETE_SHOP_DETAIL_PAGE#SUCCESS',
  DELETE_SHOP_DETAIL_PAGE_FAILURE = 'DELETE_SHOP_DETAIL_PAGE#FAILURE',

  //  - Section -
  CONNECT_SHOP_DETAIL_SECTION_REQUEST = 'CONNECT_SHOP_DETAIL_SECTION#REQUEST',
  CONNECT_SHOP_DETAIL_SECTION_SUCCESS = 'CONNECT_SHOP_DETAIL_SECTION#SUCCESS',
  CONNECT_SHOP_DETAIL_SECTION_FAILURE = 'CONNECT_SHOP_DETAIL_SECTION#FAILURE',

  EDIT_SHOP_DETAIL_SECTION_REQUEST = 'EDIT_SHOP_DETAIL_SECTION#REQUEST',
  EDIT_SHOP_DETAIL_SECTION_SUCCESS = 'EDIT_SHOP_DETAIL_SECTION#SUCCESS',
  EDIT_SHOP_DETAIL_SECTION_FAILURE = 'EDIT_SHOP_DETAIL_SECTION#FAILURE',

  DISCONNECT_SHOP_DETAIL_SECTION_REQUEST = 'DISCONNECT_SHOP_DETAIL_SECTION#REQUEST',
  DISCONNECT_SHOP_DETAIL_SECTION_SUCCESS = 'DISCONNECT_SHOP_DETAIL_SECTION#SUCCESS',
  DISCONNECT_SHOP_DETAIL_SECTION_FAILURE = 'DISCONNECT_SHOP_DETAIL_SECTION#FAILURE',

  // - Shop Detail Page Section Bulk
  BULK_CREATE_SHOP_DETAIL_SECTION_REQUEST = 'BULK_CREATE_SHOP_DETAIL_SECTION#REQUEST',
  BULK_CREATE_SHOP_DETAIL_SECTION_SUCCESS = 'BULK_CREATE_SHOP_DETAIL_SECTION#SUCCESS',
  BULK_CREATE_SHOP_DETAIL_SECTION_FAILURE = 'BULK_CREATE_SHOP_DETAIL_SECTION#FAILURE',

  BULK_DELETE_SHOP_DETAIL_SECTION_REQUEST = 'BULK_DELETE_SHOP_DETAIL_SECTION#REQUEST',
  BULK_DELETE_SHOP_DETAIL_SECTION_SUCCESS = 'BULK_DELETE_SHOP_DETAIL_SECTION#SUCCESS',
  BULK_DELETE_SHOP_DETAIL_SECTION_FAILURE = 'BULK_DELETE_SHOP_DETAIL_SECTION#FAILURE',

  // Schedule
  CREATE_SHOP_DETAIL_PAGE_SCHEDULE_REQUEST = 'CREATE_SHOP_DETAIL_PAGE_SCHEDULE#REQUEST',
  CREATE_SHOP_DETAIL_PAGE_SCHEDULE_SUCCESS = 'CREATE_SHOP_DETAIL_PAGE_SCHEDULE#SUCCESS',
  CREATE_SHOP_DETAIL_PAGE_SCHEDULE_FAILURE = 'CREATE_SHOP_DETAIL_PAGE_SCHEDULE#FAILURE',

  UPDATE_SHOP_DETAIL_PAGE_SCHEDULE_REQUEST = 'UPDATE_SHOP_DETAIL_PAGE_SCHEDULE#REQUEST',
  UPDATE_SHOP_DETAIL_PAGE_SCHEDULE_SUCCESS = 'UPDATE_SHOP_DETAIL_PAGE_SCHEDULE#SUCCESS',
  UPDATE_SHOP_DETAIL_PAGE_SCHEDULE_FAILURE = 'UPDATE_SHOP_DETAIL_PAGE_SCHEDULE#FAILURE',

  DELETE_SHOP_DETAIL_PAGE_SCHEDULE_REQUEST = 'DELETE_SHOP_DETAIL_PAGE_SCHEDULE#REQUEST',
  DELETE_SHOP_DETAIL_PAGE_SCHEDULE_SUCCESS = 'DELETE_SHOP_DETAIL_PAGE_SCHEDULE#SUCCESS',
  DELETE_SHOP_DETAIL_PAGE_SCHEDULE_FAILURE = 'DELETE_SHOP_DETAIL_PAGE_SCHEDULE#FAILURE',
}

export const eventPageTemplateIds = ['EID_LOGIN_PLEDGE', 'LOGIN_CHALLENGE', 'SA_WIN_BACK'];
export const detailTemplateIds = [
  'PRODUCT_DETAIL',
  'ACTION_DETAIL',
  'CONTENT_DETAIL',
  'PRODUCT_DETAIL_V2',
  'ACTION_DETAIL_V2',
  'CONTENT_DETAIL_V2',
  'INFO_POPUP',
  'PURCHASE_POPUP',
  'PURCHASE_CAROUSEL_POPUP',
  'SUB_PAGE',
  'GEM_LACK_POPUP',
  'MATCH_EXPERIENCE_BAD',
  'SUB_PAGE_V2',
  'PACKAGE_POPUP_A',
  'PACKAGE_POPUP_B',
  'CAMPAIGN_REWARDS',
  ...eventPageTemplateIds,
] as const;
export type DetailTemplateIds = typeof detailTemplateIds[number];

export const backgroundType = ['SOLID', 'GRADIENT'];
export type BackgroundType = typeof backgroundType[number];

export const iconType = ['CIRCLE', 'SQUARE'];
export type IconType = typeof iconType[number];

// - Payload -

export type AdditionalProps = {
  mainTitle: string;
  subTitle: string;
  backgroundImageUrl: string;
  ctaButtonUrl: string;
  ctaButtonText: string;
  footerTitle: string;
  footerIconUrl: string;
  footerButtonText: string;
  imageUrl: string;
  title: string;
  description: string;
  headerTitle: string;
  contentTitle: string;
  contentDescription: string;
  contentIconUrl: string;
  buttonText: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
  backgroundType: BackgroundType;
  backgroundColor: string;
  iconType: IconType;
  planAccentColor: string;
  badgeText: string;
  purchaseCompleteBackgroundImageUrl: string;
  purchaseCompleteGiftBoxUrl: string;
  pollId: string;
  modalTitle: string;
  gradientBackgroundColor: string;
  upgradeAnchorPageId: string;
  upgradeButtonTitle: string;
  upgradeButtonDescription: string;
  indicatorName: string;
  backgroundAnimationUrl?: string;
  bannerImageUrl: string;
};
export type ShopDetailPageFormValues = {
  pageId: string;
  templateId: DetailTemplateIds;
  description: string;
  enabled: boolean;
  additionalProps: AdditionalProps;
  isPublic: boolean;
  pageUrl: string;
};

export type ShopDetailPageRequestData = ShopDetailPageFormValues;
export type ShopDetailPageResponseData = ShopDetailPageFormValues;

// - Section -
export interface ShopDetailSection {
  sectionId: number;
  linkId: number;
  sectionType: ShopSectionType;
  name: string;
  description: string;
  sectionTitleKey: string;
  enabled: boolean;
  evalOrder: number;
}

export type ShopDetailSectionFormValue = {
  pageId: string;
  sectionId: number;
  evalOrder: number;
};

export type ShopDetailSectionRequestData = ShopDetailSectionFormValue;
export type ShopDetailSectionResponseData = ShopDetailSection;

// - Section Bulk -
export type ShopDetailSectionBulkCreateRequestData = {
  links: ShopDetailSectionRequestData[];
};
export type ShopDetailSectionBulkCreateResponseData = ShopDetailSectionFormValue & { linkId: number };

// - Detail -
export interface ShopDetailDetail extends ShopDetailPageFormValues {
  sections: ShopDetailSection[];
  schedules: ShopDetailSchedule[];
}

// - SCHEDULE -
export interface ShopDetailSchedule {
  id: number;
  segmentCode: string;
  startDate?: string;
  endDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  enabled: boolean;
}

export type ShopDetailScheduleFormValues = Omit<ShopDetailSchedule, 'id'>;
export type ShopDetailScheduleCreateRequestData = ShopDetailScheduleFormValues;
export type ShopDetailScheduleCreateResponseData = ShopDetailSchedule;
export type ShopDetailScheduleUpdateRequestData = ShopDetailScheduleFormValues;
export type ShopDetailScheduleUpdateResponseData = ShopDetailSchedule;

// - State -
export interface ShopDetailListItem extends ShopDetailPageFormValues {
  schedules: ShopDetailSchedule[];
  sectionCount: number;
}
export interface ShopDetailState {
  list: ListState<ShopDetailListItem>;
  detail?: ShopDetailDetail;
}

export const initialState: ShopDetailState = {
  list: initialListState,
  detail: undefined,
};
