import {
  createDeleteApi,
  createDeleteBodyApi,
  createListApi,
  createPostApi,
  createReadApi,
  createUpdateApi,
} from 'store/utils';
import {
  ShopMainDetail,
  ShopMainListItem,
  ShopMainPageFormValues,
  ShopMainPageRequestData,
  ShopMainPageResponseData,
  ShopMainScheduleCreateRequestData,
  ShopMainScheduleCreateResponseData,
  ShopMainScheduleFormValues,
  ShopMainScheduleUpdateRequestData,
  ShopMainScheduleUpdateResponseData,
  ShopMainSectionBulkCreateRequestData,
  ShopMainSectionFormValue,
  ShopMainSectionResponseData,
} from './types';

export const createShopMainApi = createPostApi<
  ShopMainPageFormValues,
  ShopMainPageRequestData,
  ShopMainPageResponseData
>('/shop_pages');

export const updateShopMainApi = createUpdateApi<ShopMainPageRequestData, undefined>('/shop_pages');
export const listShopMainApi = createListApi<ShopMainListItem>('/shop_pages', {
  params: {
    detailPage: false,
  },
});
export const readShopMainApi = createReadApi<ShopMainDetail>('/shop_pages/:id');
export const deleteShopMainApi = createDeleteApi('/shop_pages/:id');

// - SECTION -
export const connectShopMainSectionApi = createPostApi<ShopMainSectionResponseData>('/shop_sections/links');
export const editShopMainSectionApi = createUpdateApi<ShopMainSectionFormValue, ShopMainSectionResponseData>(
  '/shop_sections/links/:linkId'
);
export const disconnectShopMainSectionApi = createDeleteApi('/shop_sections/links/:linkId');

// - SECTION BULK -
export const bulkCreateShopMainSectionApi =
  createPostApi<ShopMainSectionBulkCreateRequestData>('/shop_sections/links/bulk');

export const bulkDeleteShopMainSectionApi = createDeleteBodyApi('/shop_sections/links/bulk');

// - SCHEDULE -
export const createShopMainPageScheduleApi = createPostApi<
  ShopMainScheduleFormValues,
  ShopMainScheduleCreateRequestData,
  ShopMainScheduleCreateResponseData
>('/shop_pages/schedules');

export const updateShopMainPageScheduleApi = createUpdateApi<
  ShopMainScheduleFormValues,
  ShopMainScheduleUpdateRequestData,
  ShopMainScheduleUpdateResponseData
>('/shop_pages/schedules/:id');

export const deleteShopMainPageScheduleApi = createDeleteApi('/shop_pages/schedules/:id');
