import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  AbTestActions as Actions,
  AbTestDetail,
  AbTestFormValues,
  AbTestListItem,
  AbTestRequestData,
  AbTestResponseData,
} from './types';

export const listAbTest = createAsyncAction(
  Actions.LIST_AB_TESTS_REQUEST,
  Actions.LIST_AB_TESTS_SUCCESS,
  Actions.LIST_AB_TESTS_FAILURE
)<ListRequestPayload, ListState<AbTestListItem>, AxiosError>();

export const updateAbTest = createAsyncAction(
  Actions.UPDATE_AB_TEST_REQUEST,
  Actions.UPDATE_AB_TEST_SUCCESS,
  Actions.UPDATE_AB_TEST_FAILURE
)<FormRequestPayload<AbTestFormValues, AbTestRequestData>, undefined, AxiosError>();

export const readAbTest = createAsyncAction(
  Actions.READ_AB_TEST_REQUEST,
  Actions.READ_AB_TEST_SUCCESS,
  Actions.READ_AB_TEST_FAILURE
)<ReadRequestPayload, AbTestDetail, AxiosError>();

export const createAbTest = createAsyncAction(
  Actions.CREATE_AB_TEST_REQUEST,
  Actions.CREATE_AB_TEST_SUCCESS,
  Actions.CREATE_AB_TEST_FAILURE
)<FormRequestPayload<AbTestFormValues, AbTestRequestData>, AbTestResponseData, AxiosError>();
