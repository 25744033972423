import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic, createDeleteEpic } from 'store/utils';

import { listMatataDeviceUnbinding, readMatataDeviceUnbinding, deleteMatataDeviceUnbinding } from './actions';

import { listMatataDeviceUnbindingApi, readMatataDeviceUnbindingApi, deleteMatataDeviceUnbindingApi } from './api';

export const listMatataDeviceUnbindingEpic = createListEpic(listMatataDeviceUnbinding, listMatataDeviceUnbindingApi);
export const readMatataDeviceUnbindingEpic = createReadEpic(readMatataDeviceUnbinding, readMatataDeviceUnbindingApi);
export const deleteMatataDeviceUnbindingEpic = createDeleteEpic(
  deleteMatataDeviceUnbinding,
  deleteMatataDeviceUnbindingApi
);

export default combineEpics(
  listMatataDeviceUnbindingEpic,
  readMatataDeviceUnbindingEpic,
  deleteMatataDeviceUnbindingEpic
);
