import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  VisitorAbTestActions as Actions,
  VisitorAbTestDetail,
  VisitorAbTestFormValues,
  VisitorAbTestListItem,
  VisitorAbTestRequestData,
  VisitorAbTestResponseData,
} from './types';

export const listVisitorAbTest = createAsyncAction(
  Actions.LIST_VISITOR_AB_TESTS_REQUEST,
  Actions.LIST_VISITOR_AB_TESTS_SUCCESS,
  Actions.LIST_VISITOR_AB_TESTS_FAILURE
)<ListRequestPayload, ListState<VisitorAbTestListItem>, AxiosError>();

export const updateVisitorAbTest = createAsyncAction(
  Actions.UPDATE_VISITOR_AB_TEST_REQUEST,
  Actions.UPDATE_VISITOR_AB_TEST_SUCCESS,
  Actions.UPDATE_VISITOR_AB_TEST_FAILURE
)<FormRequestPayload<VisitorAbTestFormValues, VisitorAbTestRequestData>, undefined, AxiosError>();

export const readVisitorAbTest = createAsyncAction(
  Actions.READ_VISITOR_AB_TEST_REQUEST,
  Actions.READ_VISITOR_AB_TEST_SUCCESS,
  Actions.READ_VISITOR_AB_TEST_FAILURE
)<ReadRequestPayload, VisitorAbTestDetail, AxiosError>();

export const createVisitorAbTest = createAsyncAction(
  Actions.CREATE_VISITOR_AB_TEST_REQUEST,
  Actions.CREATE_VISITOR_AB_TEST_SUCCESS,
  Actions.CREATE_VISITOR_AB_TEST_FAILURE
)<FormRequestPayload<VisitorAbTestFormValues, VisitorAbTestRequestData>, VisitorAbTestResponseData, AxiosError>();
