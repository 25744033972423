import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { createFriendship } from './actions';
import { createFriendshipApi } from './api';

const createFriendshipToastEpic = createToastEpic(createFriendship.success, 'Successfully updated');

const createFriendshipEpic = createUpdateEpic(createFriendship, createFriendshipApi);

export default combineEpics(createFriendshipToastEpic, createFriendshipEpic);
