import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as realtimeMonitoringActions from './actions';
import { initialState, RealtimeMonitoringState } from './types';

const realtimeMonitoring = createReducer<RealtimeMonitoringState, RootAction>(initialState)
  .handleAction(
    realtimeMonitoringActions.listRealtimeMonitoringManagerWorkloads.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(
    realtimeMonitoringActions.listRealtimeMonitoringManagerWorkloads.success,
    (state, { payload: list }) => ({
      ...state,
      list,
    })
  )
  .handleAction(realtimeMonitoringActions.listRealtimeMonitoringManagerWorkloads.failure, (state) => ({
    ...state,
    list: { ...initialState.list },
  }))
  .handleAction(
    realtimeMonitoringActions.listRealtimeMonitoringAgentWorkloads.success,
    (state, { payload: detail }) => ({
      ...state,
      detail,
    })
  )
  .handleAction(realtimeMonitoringActions.listRealtimeMonitoringAgentWorkloads.failure, (state) => ({
    ...state,
    detail: { ...initialState.detail },
  }));

export default realtimeMonitoring;
