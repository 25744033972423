import { Promotion } from 'store/promotionContent/types';
import { initialListState, ListState } from 'store/types';

export enum PromotionSettingActions {
  LIST_PROMOTION_SETTINGS_REQUEST = 'LIST_PROMOTION_SETTINGS#REQUEST',
  LIST_PROMOTION_SETTINGS_SUCCESS = 'LIST_PROMOTION_SETTINGS#SUCCESS',
  LIST_PROMOTION_SETTINGS_FAILURE = 'LIST_PROMOTION_SETTINGS#FAILURE',

  READ_PROMOTION_SETTING_REQUEST = 'READ_PROMOTION_SETTING#REQUEST',
  READ_PROMOTION_SETTING_SUCCESS = 'READ_PROMOTION_SETTING#SUCCESS',
  READ_PROMOTION_SETTING_FAILURE = 'READ_PROMOTION_SETTING#FAILURE',

  CREATE_PROMOTION_SETTING_REQUEST = 'CREATE_PROMOTION_SETTING#REQUEST',
  CREATE_PROMOTION_SETTING_SUCCESS = 'CREATE_PROMOTION_SETTING#SUCCESS',
  CREATE_PROMOTION_SETTING_FAILURE = 'CREATE_PROMOTION_SETTING#FAILURE',

  UPDATE_PROMOTION_SETTING_REQUEST = 'UPDATE_PROMOTION_SETTING#REQUEST',
  UPDATE_PROMOTION_SETTING_SUCCESS = 'UPDATE_PROMOTION_SETTING#SUCCESS',
  UPDATE_PROMOTION_SETTING_FAILURE = 'UPDATE_PROMOTION_SETTING#FAILURE',

  DELETE_PROMOTION_SETTING_REQUEST = 'DELETE_PROMOTION_SETTING#REQUEST',
  DELETE_PROMOTION_SETTING_SUCCESS = 'DELETE_PROMOTION_SETTING#SUCCESS',
  DELETE_PROMOTION_SETTING_FAILURE = 'DELETE_PROMOTION_SETTING#FAILURE',
}

export enum EventType {
  LOGIN = 'LOGIN',
  INSTALL_REFERRER = 'INSTALL_REFERRER',
  MATCH_REQUEST = 'MATCH_REQUEST',
  SEGMENT_ADDED = 'SEGMENT_ADDED',
  LIVE = 'LIVE',
  TAB_SELECTED = 'TAB_SELECTED',
}

export const initialState: PromotionSettingState = {
  list: initialListState,
  detail: undefined,
};

export interface PromotionSettingState {
  list: ListState<PromotionSettingListItem>;
  detail?: PromotionSettingDetail;
}

export interface PromotionSetting {
  id: string;
  segmentCode?: string;
  startDate?: string;
  endDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  promotionContentsId: string;
  eventType?: string;
  eventTarget?: string;
  promotion?: Promotion[];
  enabled: boolean;
  activated: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export type PromotionSettingListItem = PromotionSetting;

export type PromotionSettingDetail = PromotionSetting;

export type PromotionSettingFormValues = PromotionSetting;

export type PromotionSettingCreateRequestData = Omit<PromotionSettingFormValues, 'promotion'>;

export type PromotionSettingCreateResponseData = PromotionSettingFormValues;

export type PromotionSettingUpdateRequestData = Omit<PromotionSettingFormValues, 'promotion'>;

export type PromotionSettingUpdateResponseData = PromotionSettingFormValues;
