import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  MatchFilterSegmentActions as Actions,
  MatchFilterSegmentCreateRequestData,
  MatchFilterSegmentDetail,
  MatchFilterSegmentFormValues,
  MatchFilterSegmentListItem,
  MatchFilterSegmentUpdateRequestData,
} from './types';

export const listMatchFilterSegment = createAsyncAction(
  Actions.LIST_MATCH_FILTER_SEGMENTS_REQUEST,
  Actions.LIST_MATCH_FILTER_SEGMENTS_SUCCESS,
  Actions.LIST_MATCH_FILTER_SEGMENTS_FAILURE
)<ListRequestPayload, ListState<MatchFilterSegmentListItem>, AxiosError>();

export const readMatchFilterSegment = createAsyncAction(
  Actions.READ_MATCH_FILTER_SEGMENT_REQUEST,
  Actions.READ_MATCH_FILTER_SEGMENT_SUCCESS,
  Actions.READ_MATCH_FILTER_SEGMENT_FAILURE
)<ReadRequestPayload, MatchFilterSegmentDetail, AxiosError>();

export const createMatchFilterSegment = createAsyncAction(
  Actions.CREATE_MATCH_FILTER_SEGMENT_REQUEST,
  Actions.CREATE_MATCH_FILTER_SEGMENT_SUCCESS,
  Actions.CREATE_MATCH_FILTER_SEGMENT_FAILURE
)<FormRequestPayload<MatchFilterSegmentFormValues, MatchFilterSegmentCreateRequestData>, undefined, AxiosError>();

export const updateMatchFilterSegment = createAsyncAction(
  Actions.UPDATE_MATCH_FILTER_SEGMENT_REQUEST,
  Actions.UPDATE_MATCH_FILTER_SEGMENT_SUCCESS,
  Actions.UPDATE_MATCH_FILTER_SEGMENT_FAILURE
)<FormRequestPayload<MatchFilterSegmentFormValues, MatchFilterSegmentUpdateRequestData>, undefined, AxiosError>();

export const deleteMatchFilterSegment = createAsyncAction(
  Actions.DELETE_MATCH_FILTER_SEGMENT_REQUEST,
  Actions.DELETE_MATCH_FILTER_SEGMENT_SUCCESS,
  Actions.DELETE_MATCH_FILTER_SEGMENT_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
