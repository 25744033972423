import { AxiosError } from 'axios';
import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';
import { createAsyncAction } from 'typesafe-actions';
import {
  ShopModuleGroupPageActions as Actions,
  ShopModuleGroupFormValues,
  ShopModuleGroupItem,
  ShopModuleGroupRequestData,
  ShopModuleGroupResponseData,
  ShopModuleNestedGroupFormValues,
  ShopModuleNestedGroupRequestData,
  ShopModuleNestedGroupResponseData,
} from './types';

export const listShopModuleGroup = createAsyncAction(
  Actions.LIST_SHOP_MODULE_GROUP_REQUEST,
  Actions.LIST_SHOP_MODULE_GROUP_SUCCESS,
  Actions.LIST_SHOP_MODULE_GROUP_FAILURE
)<ListRequestPayload, ListState<ShopModuleGroupItem>, AxiosError>();

export const readShopModuleGroup = createAsyncAction(
  Actions.READ_SHOP_MODULE_GROUP_REQUEST,
  Actions.READ_SHOP_MODULE_GROUP_SUCCESS,
  Actions.READ_SHOP_MODULE_GROUP_FAILURE
)<ReadRequestPayload, ShopModuleGroupItem, AxiosError>();

export const createShopModuleGroup = createAsyncAction(
  Actions.CREATE_SHOP_MODULE_GROUP_REQUEST,
  Actions.CREATE_SHOP_MODULE_GROUP_SUCCESS,
  Actions.CREATE_SHOP_MODULE_GROUP_FAILURE
)<FormRequestPayload<ShopModuleGroupFormValues, ShopModuleGroupRequestData>, ShopModuleGroupResponseData, AxiosError>();

export const updateShopModuleGroup = createAsyncAction(
  Actions.UPDATE_SHOP_MODULE_GROUP_REQUEST,
  Actions.UPDATE_SHOP_MODULE_GROUP_SUCCESS,
  Actions.UPDATE_SHOP_MODULE_GROUP_FAILURE
)<FormRequestPayload<ShopModuleGroupFormValues, ShopModuleGroupRequestData>, ShopModuleGroupResponseData, AxiosError>();

export const deleteShopModuleGroup = createAsyncAction(
  Actions.DELETE_SHOP_MODULE_GROUP_REQUEST,
  Actions.DELETE_SHOP_MODULE_GROUP_SUCCESS,
  Actions.DELETE_SHOP_MODULE_GROUP_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const createShopModuleNestedGroup = createAsyncAction(
  Actions.CREATE_SHOP_MODULE_NESTED_GROUP_REQUEST,
  Actions.CREATE_SHOP_MODULE_NESTED_GROUP_SUCCESS,
  Actions.CREATE_SHOP_MODULE_NESTED_GROUP_FAILURE
)<
  FormRequestPayload<ShopModuleNestedGroupFormValues, ShopModuleNestedGroupRequestData>,
  ShopModuleNestedGroupResponseData,
  AxiosError
>();

export const updateShopModuleNestedGroup = createAsyncAction(
  Actions.UPDATE_SHOP_MODULE_NESTED_GROUP_REQUEST,
  Actions.UPDATE_SHOP_MODULE_NESTED_GROUP_SUCCESS,
  Actions.UPDATE_SHOP_MODULE_NESTED_GROUP_FAILURE
)<
  FormRequestPayload<ShopModuleNestedGroupFormValues, ShopModuleNestedGroupRequestData>,
  ShopModuleNestedGroupResponseData,
  AxiosError
>();

export const deleteShopModuleNestedGroup = createAsyncAction(
  Actions.DELETE_SHOP_MODULE_NESTED_GROUP_REQUEST,
  Actions.DELETE_SHOP_MODULE_NESTED_GROUP_SUCCESS,
  Actions.DELETE_SHOP_MODULE_NESTED_GROUP_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
