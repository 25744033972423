import { NoticePopupButton, NoticePopupButtonCreateRequestData } from 'store/noticePopupButton/types';
import { NoticePopupCarousel } from 'store/noticePopupCarousel/types';
import { NoticePopupSchedule } from 'store/noticePopupSchedule/types';
import { initialListState, ListState } from 'store/types';

export enum NoticeActions {
  LIST_NOTICE_REQUEST = 'LIST_NOTICE#REQUEST',
  LIST_NOTICE_SUCCESS = 'LIST_NOTICE#SUCCESS',
  LIST_NOTICE_FAILURE = 'LIST_NOTICE#FAILURE',

  CREATE_NOTICE_REQUEST = 'CREATE_NOTICE#REQUEST',
  CREATE_NOTICE_SUCCESS = 'CREATE_NOTICE#SUCCESS',
  CREATE_NOTICE_FAILURE = 'CREATE_NOTICE#FAILURE',

  READ_NOTICE_REQUEST = 'READ_NOTICE#REQUEST',
  READ_NOTICE_SUCCESS = 'READ_NOTICE#SUCCESS',
  READ_NOTICE_FAILURE = 'READ_NOTICE#FAILURE',

  UPDATE_NOTICE_REQUEST = 'UPDATE_NOTICE#REQUEST',
  UPDATE_NOTICE_SUCCESS = 'UPDATE_NOTICE#SUCCESS',
  UPDATE_NOTICE_FAILURE = 'UPDATE_NOTICE#FAILURE',

  REFRESH_NOTICE_REQUEST = 'REFRESH_NOTICE#REQUEST',
  REFRESH_NOTICE_SUCCESS = 'REFRESH_NOTICE#SUCCESS',
  REFRESH_NOTICE_FAILURE = 'REFRESH_NOTICE#FAILURE',

  BULK_INSERT_NOTICE_CAROUSELS_REQUEST = 'BULK_INSERT_NOTICE_CAROUSELS#REQUEST',
  BULK_INSERT_NOTICE_CAROUSELS_SUCCESS = 'BULK_INSERT_NOTICE_CAROUSELS#SUCCESS',
  BULK_INSERT_NOTICE_CAROUSELS_FAILURE = 'BULK_INSERT_NOTICE_CAROUSELS#FAILURE',

  BULK_INSERT_NOTICE_BUTTONS_REQUEST = 'BULK_INSERT_NOTICE_BUTTONS#REQUEST',
  BULK_INSERT_NOTICE_BUTTONS_SUCCESS = 'BULK_INSERT_NOTICE_BUTTONS#SUCCESS',
  BULK_INSERT_NOTICE_BUTTONS_FAILURE = 'BULK_INSERT_NOTICE_BUTTONS#FAILURE',

  BULK_DELETE_NOTICE_CAROUSELS_REQUEST = 'BULK_DELETE_NOTICE_CAROUSELS#REQUEST',
  BULK_DELETE_NOTICE_CAROUSELS_SUCCESS = 'BULK_DELETE_NOTICE_CAROUSELS#SUCCESS',
  BULK_DELETE_NOTICE_CAROUSELS_FAILURE = 'BULK_DELETE_NOTICE_CAROUSELS#FAILURE',

  BULK_DELETE_NOTICE_BUTTONS_REQUEST = 'BULK_DELETE_NOTICE_BUTTONS#REQUEST',
  BULK_DELETE_NOTICE_BUTTONS_SUCCESS = 'BULK_DELETE_NOTICE_BUTTONS#SUCCESS',
  BULK_DELETE_NOTICE_BUTTONS_FAILURE = 'BULK_DELETE_NOTICE_BUTTONS#FAILURE',
}

export const initialState: NoticeState = {
  list: initialListState,
  detail: undefined,
};

export interface NoticeListItem {
  displayIntervalMins: number;
  enabled: boolean;
  closeButtonText: string;
  headerTitle: string;
  headerTitleColor: string;
  id: number;
  noticePopupButtons: NoticePopupButton[];
  noticePopupContents: NoticePopupCarousel[];
  noticePopupSchedules: NoticePopupSchedule[];
  popupId: string;
}
export interface StringLocalizationFormValues {
  headerTitleAsString: boolean;
  closeButtonTextAsString: boolean;
}

export type NoticeDetail = NoticeListItem;

export interface NoticeState {
  list: ListState<NoticeListItem>;
  detail?: NoticeDetail;
}

export type NoticeCreateRequestFormValues = Omit<
  NoticeListItem,
  'id' | 'noticePopupButtons' | 'noticePopupContents' | 'noticePopupSchedules'
> &
  StringLocalizationFormValues;
export type NoticeCreateRequestData = Omit<
  NoticeListItem,
  'id' | 'noticePopupButtons' | 'noticePopupContents' | 'noticePopupSchedules'
>;
export type NoticeCreateSuccessData = NoticeDetail;
export type NoticeUpdateRequestFormValues = Omit<
  NoticeListItem,
  'noticePopupButtons' | 'noticePopupContents' | 'noticePopupSchedules'
> &
  StringLocalizationFormValues;
export type NoticeUpdateRequestData = Omit<
  NoticeListItem,
  'noticePopupButtons' | 'noticePopupContents' | 'noticePopupSchedules'
>;
export type NoticeUpdateSuccessData = NoticeDetail;
export type NoticePopupCarouselsBulkInsertRequestData = { noticePopupContents: NoticePopupCarousel[] };
export type NoticePopupCarouselsBulkInsertSuccessData = NoticePopupCarousel[];
export type NoticePopupButtonsBulkInsertRequestData = { data: NoticePopupButtonCreateRequestData[] };
export type NoticePopupButtonsBulkInsertSuccessData = NoticePopupButton[];
export type NoticePopupCarouselsBulkDeleteSuccessData = NoticePopupCarousel[];
export type NoticePopupButtonsBulkDeleteSuccessData = NoticePopupButton[];
