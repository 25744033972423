import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  DeleteSuccessPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ExpressionSegmentActions as Actions,
  ExpressionSegmentCreateRequestData,
  ExpressionSegmentCreateSuccessData,
  ExpressionSegmentDetail,
  ExpressionSegmentListItem,
  ExpressionSegmentUpdateRequestData,
  ExpressionSegmentUpdateSuccessData,
} from './types';

export const listExpressionSegment = createAsyncAction(
  Actions.LIST_EXPRESSION_SEGMENTS_REQUEST,
  Actions.LIST_EXPRESSION_SEGMENTS_SUCCESS,
  Actions.LIST_EXPRESSION_SEGMENTS_FAILURE
)<ListRequestPayload, ListState<ExpressionSegmentListItem>, AxiosError>();

export const createExpressionSegment = createAsyncAction(
  Actions.CREATE_EXPRESSION_SEGMENT_REQUEST,
  Actions.CREATE_EXPRESSION_SEGMENT_SUCCESS,
  Actions.CREATE_EXPRESSION_SEGMENT_FAILURE
)<FormRequestPayload<ExpressionSegmentCreateRequestData>, ExpressionSegmentCreateSuccessData, AxiosError>();

export const readExpressionSegment = createAsyncAction(
  Actions.READ_EXPRESSION_SEGMENT_REQUEST,
  Actions.READ_EXPRESSION_SEGMENT_SUCCESS,
  Actions.READ_EXPRESSION_SEGMENT_FAILURE
)<ReadRequestPayload, ExpressionSegmentDetail, AxiosError>();

export const updateExpressionSegment = createAsyncAction(
  Actions.UPDATE_EXPRESSION_SEGMENT_REQUEST,
  Actions.UPDATE_EXPRESSION_SEGMENT_SUCCESS,
  Actions.UPDATE_EXPRESSION_SEGMENT_FAILURE
)<FormRequestPayload<ExpressionSegmentUpdateRequestData>, ExpressionSegmentUpdateSuccessData, AxiosError>();

export const deleteExpressionSegment = createAsyncAction(
  Actions.DELETE_EXPRESSION_SEGMENT_REQUEST,
  Actions.DELETE_EXPRESSION_SEGMENT_SUCCESS,
  Actions.DELETE_EXPRESSION_SEGMENT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
