import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  PromotionContentGroupActions as Actions,
  PromotionContentGroupListItem,
  PromotionContentGroupSearchItem,
  PromotionContentGroupCreateRequestData,
  PromotionContentGroupCreateResponseData,
  PromotionContentGroupDetail,
  PromotionContentGroupFormValues,
  PromotionContentGroupUpdateRequestData,
  PromotionContentGroupUpdateResponseData,
} from './types';

export const listPromotionContentGroup = createAsyncAction(
  Actions.LIST_PROMOTION_CONTENT_GROUP_REQUEST,
  Actions.LIST_PROMOTION_CONTENT_GROUP_SUCCESS,
  Actions.LIST_PROMOTION_CONTENT_GROUP_FAILURE
)<ListRequestPayload, ListState<PromotionContentGroupListItem>, AxiosError>();

export const listPromotionContentGroupSearch = createAsyncAction(
  Actions.LIST_PROMOTION_CONTENT_GROUP_SEARCH_REQUEST,
  Actions.LIST_PROMOTION_CONTENT_GROUP_SEARCH_SUCCESS,
  Actions.LIST_PROMOTION_CONTENT_GROUP_SEARCH_FAILURE
)<ListRequestPayload, ListState<PromotionContentGroupSearchItem>, AxiosError>();

export const readPromotionContentGroup = createAsyncAction(
  Actions.READ_PROMOTION_CONTENT_GROUP_REQUEST,
  Actions.READ_PROMOTION_CONTENT_GROUP_SUCCESS,
  Actions.READ_PROMOTION_CONTENT_GROUP_FAILURE
)<ReadRequestPayload, PromotionContentGroupDetail, AxiosError>();

export const createPromotionContentGroup = createAsyncAction(
  Actions.CREATE_PROMOTION_CONTENT_GROUP_REQUEST,
  Actions.CREATE_PROMOTION_CONTENT_GROUP_SUCCESS,
  Actions.CREATE_PROMOTION_CONTENT_GROUP_FAILURE
)<
  FormRequestPayload<PromotionContentGroupFormValues, PromotionContentGroupCreateRequestData>,
  PromotionContentGroupCreateResponseData,
  AxiosError
>();

export const updatePromotionContentGroup = createAsyncAction(
  Actions.UPDATE_PROMOTION_CONTENT_GROUP_REQUEST,
  Actions.UPDATE_PROMOTION_CONTENT_GROUP_SUCCESS,
  Actions.UPDATE_PROMOTION_CONTENT_GROUP_FAILURE
)<
  FormRequestPayload<PromotionContentGroupFormValues, PromotionContentGroupUpdateRequestData>,
  PromotionContentGroupUpdateResponseData,
  AxiosError
>();
