import { DecoColorPreset } from 'store/decoColorPreset/types';
import { FileSetting } from 'store/fileSetting/types';

export enum DecoResourceActions {
  CREATE_DECO_RESOURCE_REQUEST = 'CREATE_DECO_RESOURCE#REQUEST',
  CREATE_DECO_RESOURCE_SUCCESS = 'CREATE_DECO_RESOURCE#SUCCESS',
  CREATE_DECO_RESOURCE_FAILURE = 'CREATE_DECO_RESOURCE#FAILURE',

  UPDATE_DECO_RESOURCE_REQUEST = 'UPDATE_DECO_RESOURCE#REQUEST',
  UPDATE_DECO_RESOURCE_SUCCESS = 'UPDATE_DECO_RESOURCE#SUCCESS',
  UPDATE_DECO_RESOURCE_FAILURE = 'UPDATE_DECO_RESOURCE#FAILURE',

  BULK_UPLOAD_DECO_RESOURCE_REQUEST = 'BULK_UPLOAD_DECO_RESOURCE#REQUEST',
  BULK_UPLOAD_DECO_RESOURCE_SUCCESS = 'BULK_UPLOAD_DECO_RESOURCE#SUCCESS',
  BULK_UPLOAD_DECO_RESOURCE_FAILURE = 'BULK_UPLOAD_DECO_RESOURCE#FAILURE',
}

export enum DecoItemGroupId {
  DECO_STICKER = 'DECO_STICKER',
  DECO_ENTRANCE_EFFECT = 'DECO_ENTRANCE_EFFECT',
  DECO_FILTER_EFFECT = 'DECO_FILTER_EFFECT',
  DECO_GRAPHIC_EFFECT = 'DECO_GRAPHIC_EFFECT',

  //JCV
  DECO_JCV_FILTER = 'DECO_JCV_FILTER',
  DECO_JCV_BACKGROUND = 'DECO_JCV_BACKGROUND',
  DECO_JCV_MAKEUP = 'DECO_JCV_MAKEUP',
  DECO_JCV_STYLE = 'DECO_JCV_STYLE',

  //BETA
  DECO_AVATAR_BACKGROUND_IMAGE_BETA = 'DECO_AVATAR_BACKGROUND_IMAGE_BETA',
  DECO_AVATAR_BODY_SHAPE_BETA = 'DECO_AVATAR_BODY_SHAPE_BETA',
  DECO_AVATAR_BODY_TOP_BETA = 'DECO_AVATAR_BODY_TOP_BETA',
  DECO_AVATAR_HEAD_FACE_BETA = 'DECO_AVATAR_HEAD_FACE_BETA',
  DECO_AVATAR_HEAD_FACIALHAIR_BETA = 'DECO_AVATAR_HEAD_FACIALHAIR_BETA',
  DECO_AVATAR_HEAD_HAIR_BETA = 'DECO_AVATAR_HEAD_HAIR_BETA',

  //REAL
  DECO_AVATAR_BACKGROUND_IMAGE = 'DECO_AVATAR_BACKGROUND_IMAGE',
  DECO_AVATAR_BODY_SHAPE = 'DECO_AVATAR_BODY_SHAPE',
  DECO_AVATAR_BODY_TOP = 'DECO_AVATAR_BODY_TOP',

  DECO_AVATAR_HEAD_FACE = 'DECO_AVATAR_HEAD_FACE',
  DECO_AVATAR_HEAD_FACIALHAIR = 'DECO_AVATAR_HEAD_FACIALHAIR',
  DECO_AVATAR_HEAD_HAIR = 'DECO_AVATAR_HEAD_HAIR',

  DECO_AVATAR_ACCESSORY_EYEWEAR = 'DECO_AVATAR_ACCESSORY_EYEWEAR',
  DECO_AVATAR_ACCESSORY_NECKLACE = 'DECO_AVATAR_ACCESSORY_NECKLACE',
  DECO_AVATAR_ACCESSORY_EARRING = 'DECO_AVATAR_ACCESSORY_EARRING',

  // REAL CUSTOM
  DECO_AVATAR_C_HEAD_SHAPE = 'DECO_AVATAR_C_HEAD_SHAPE',
  DECO_AVATAR_C_HEAD_EYEBROWS = 'DECO_AVATAR_C_HEAD_EYEBROWS',
  DECO_AVATAR_C_HEAD_EYES = 'DECO_AVATAR_C_HEAD_EYES',
  DECO_AVATAR_C_HEAD_NOSE = 'DECO_AVATAR_C_HEAD_NOSE',
  DECO_AVATAR_C_HEAD_MOUTH = 'DECO_AVATAR_C_HEAD_MOUTH',
  DECO_AVATAR_C_HEAD_HAIR = 'DECO_AVATAR_C_HEAD_HAIR',
  DECO_AVATAR_C_BODY_SHAPE = 'DECO_AVATAR_C_BODY_SHAPE',
}

export enum DecoCategoryType {
  DECO_AVATAR = 'DECO_AVATAR',
  DECO_GRAPHIC_EFFECT = 'DECO_GRAPHIC_EFFECT',
  DECO_STICKER = 'DECO_STICKER',
  DECO_FILTER_EFFECT = 'DECO_FILTER_EFFECT',
  DECO_ENTRANCE_EFFECT = 'DECO_ENTRANCE_EFFECT',
  DECO_JCV_FILTER = 'DECO_JCV_FILTER',
  DECO_JCV_BACKGROUND = 'DECO_JCV_BACKGROUND',
  DECO_JCV_MAKEUP = 'DECO_JCV_MAKEUP',
  DECO_JCV_STYLE = 'DECO_JCV_STYLE',
  DECO_BACKGROUND = 'DECO_BACKGROUND',
}

export enum AvatarGroups {
  HEAD_FACE = 'HEAD_FACE',
  HEAD_HAIR = 'HEAD_HAIR',
  HEAD_FACIALHAIR = 'HEAD_FACIALHAIR',
  BODY_SHAPE = 'BODY_SHAPE',
  BODY_TOP = 'BODY_TOP',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
}

export enum EffectRenderer {
  BeautyTone = 'BeautyTone',
  Filter = 'Filter',
  Makeup = 'Makeup',
  Sticker = 'Sticker',
}

export enum JcvFilterEffectCategory {
  EFFECT_BEAUTY_FILTER = 'EFFECT_BEAUTY_FILTER',
}

export enum JcvBackgroundEffectCategory {
  TONE_BOKEH = 'TONE_BOKEH',
}

export enum JcvMakeupEffectCategory {
  LIPSTICK = 'LIPSTICK',
  BLUSHER = 'BLUSHER',
  EYEBROWS = 'EYEBROWS',
  EYELASHES = 'EYELASHES',
}

// JCV 스타일 의 EffectCategory 의 경우 빈값 으로 두는게 맞음
export enum JcvStyleEffectCategory {
  EFFECT_BEAUTY_STYLE = '',
}

export interface BaseDecoResource {
  encryptionKey: string;
  resourceUrl: string;
  md5Hash: string;
  effectRenderer?: string;
  effectCategory?: string;
}

export interface DecoResourceSticker {
  sheetWidth: number;
  sheetHeight: number;
  spriteWidth: number;
  spriteHeight: number;
  rows: number;
  cols: number;
  duration: number;
  totalFrames: number;
  userViewSize: number;
}

export interface DecoResourceEffect {
  ulSeeRequired: boolean;
  combinable: boolean;
}

export interface JcvDecoResourceEffect {
  effectCategory?: string;
  effectRenderer?: string;
}

export interface DecoResourceAvatar {
  avatarGroups: string[];
  requiredAvatarGroups: string[] | null;
  colorPresets?: DecoColorPreset[];
  bundled: boolean;
}

export type DecoResource = (BaseDecoResource & DecoResourceSticker) | DecoResourceEffect | DecoResourceAvatar;

export type DecoResourceFormValues = DecoResource & {
  itemCategoryId: string;
};

export interface DecoResourceCreateRequestData {
  itemCategoryId: string;
  resourceInfo: DecoResource;
}

export type DecoResourceCreateResponseData = DecoResource;

export type DecoResourceUpdateRequestData = DecoResourceCreateRequestData;

export type DecoResourceUpdateResponseData = DecoResource;

export type DecoResourceBulkUploadRequestData = any;

export type DecoResourceBulkUploadResponseData = FileSetting;
