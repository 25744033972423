import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createUpdateEpic,
  createToastEpic,
  createAsyncEpic,
} from '../utils';

import {
  createWaterSlideBanner,
  deleteWaterSlideBanner,
  listWaterSlideBanner,
  readWaterSlideBanner,
  updateWaterSlideBanner,
  refreshWaterSlideBanner,
} from './actions';
import {
  createWaterSlideBannerApi,
  deleteWaterSlideBannerApi,
  listWaterSlideBannerApi,
  readWaterSlideBannerApi,
  updateWaterSlideBannerApi,
  refreshMessageBannerApi,
} from './api';

const createWaterSlideBannerEpic = createPostEpic(createWaterSlideBanner, createWaterSlideBannerApi);
const readWaterSlideBannerEpic = createReadEpic(readWaterSlideBanner, readWaterSlideBannerApi);
const updateWaterSlideBannerEpic = createUpdateEpic(updateWaterSlideBanner, updateWaterSlideBannerApi);
const listWaterSlideBannerEpic = createListEpic(listWaterSlideBanner, listWaterSlideBannerApi);
const deleteWaterSlideBannerEpic = createDeleteEpic(deleteWaterSlideBanner, deleteWaterSlideBannerApi);

const refreshWaterSlideBannerToastEpic = createToastEpic(
  refreshWaterSlideBanner.success,
  'Successfully refreshed server'
);

const refreshWaterSlideBannerEpic = createAsyncEpic(refreshWaterSlideBanner, refreshMessageBannerApi);

export default combineEpics(
  createWaterSlideBannerEpic,
  readWaterSlideBannerEpic,
  updateWaterSlideBannerEpic,
  listWaterSlideBannerEpic,
  deleteWaterSlideBannerEpic,
  refreshWaterSlideBannerToastEpic,
  refreshWaterSlideBannerEpic
);
