import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as itemCategoryActions from './actions';
import { initialState, ItemCategoryState } from './types';

const itemCategory = createReducer<ItemCategoryState, RootAction>(initialState)
  .handleAction(itemCategoryActions.getItemCategory.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(itemCategoryActions.getItemCategory.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(itemCategoryActions.getDecoCategory.success, (state, { payload }) => ({
    ...state,
    decoCategoryList: payload,
  }));

export default itemCategory;
