import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { SpecialPromotionSettingProduct } from 'store/specialPromotionSettingProduct/types';
import {
  DeleteRequestPayload,
  DeleteSuccessPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  SpecialPromotionSettingActions as Actions,
  SpecialPromotionSettingCreateRequestData,
  SpecialPromotionSettingCreateSuccessData,
  SpecialPromotionSettingDetail,
  SpecialPromotionSettingListItem,
  SpecialPromotionSettingUpdateRequestData,
  SpecialPromotionSettingUpdateSuccessData,
} from './types';

export const listSpecialPromotionSetting = createAsyncAction(
  Actions.LIST_SPECIAL_PROMOTION_SETTINGS_REQUEST,
  Actions.LIST_SPECIAL_PROMOTION_SETTINGS_SUCCESS,
  Actions.LIST_SPECIAL_PROMOTION_SETTINGS_FAILURE
)<ListRequestPayload, ListState<SpecialPromotionSettingListItem>, AxiosError>();

export const createSpecialPromotionSetting = createAsyncAction(
  Actions.CREATE_SPECIAL_PROMOTION_SETTING_REQUEST,
  Actions.CREATE_SPECIAL_PROMOTION_SETTING_SUCCESS,
  Actions.CREATE_SPECIAL_PROMOTION_SETTING_FAILURE
)<FormRequestPayload<SpecialPromotionSettingCreateRequestData>, SpecialPromotionSettingCreateSuccessData, AxiosError>();

export const readSpecialPromotionSetting = createAsyncAction(
  Actions.READ_SPECIAL_PROMOTION_SETTING_REQUEST,
  Actions.READ_SPECIAL_PROMOTION_SETTING_SUCCESS,
  Actions.READ_SPECIAL_PROMOTION_SETTING_FAILURE
)<ReadRequestPayload, SpecialPromotionSettingDetail, AxiosError>();

export const updateSpecialPromotionSetting = createAsyncAction(
  Actions.UPDATE_SPECIAL_PROMOTION_SETTING_REQUEST,
  Actions.UPDATE_SPECIAL_PROMOTION_SETTING_SUCCESS,
  Actions.UPDATE_SPECIAL_PROMOTION_SETTING_FAILURE
)<FormRequestPayload<SpecialPromotionSettingUpdateRequestData>, SpecialPromotionSettingUpdateSuccessData, AxiosError>();

export const deleteSpecialPromotionSetting = createAsyncAction(
  Actions.DELETE_SPECIAL_PROMOTION_SETTING_REQUEST,
  Actions.DELETE_SPECIAL_PROMOTION_SETTING_SUCCESS,
  Actions.DELETE_SPECIAL_PROMOTION_SETTING_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();

export const setSpecialPromotionSettingProducts = createAction(Actions.SET_SPECIAL_PROMOTION_SETTING_PRODUCTS)<
  SpecialPromotionSettingProduct[]
>();

export const refreshSpecialPromotionSetting = createAsyncAction(
  Actions.REFRESH_SPECIAL_PROMOTION_SETTING_REQUEST,
  Actions.REFRESH_SPECIAL_PROMOTION_SETTING_SUCCESS,
  Actions.REFRESH_SPECIAL_PROMOTION_SETTING_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
