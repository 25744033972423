import { RootAction } from 'store/root/types';
import { createReducer } from 'typesafe-actions';
import { LimitedEditionState, initialState } from './types';
import * as LimitedEditionActions from './actions';

const LimitedEdition = createReducer<LimitedEditionState, RootAction>(initialState)
  .handleAction(LimitedEditionActions.listLimitedEdition.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(LimitedEditionActions.listLimitedEdition.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(LimitedEditionActions.readLimitedEdition.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default LimitedEdition;
