export enum FileSettingActions {
  RESET_FILE_SETTING = 'RESET_FILE_SETTING',

  CREATE_FILE_SETTING_REQUEST = 'CREATE_FILE_SETTING#REQUEST',
  CREATE_FILE_SETTING_SUCCESS = 'CREATE_FILE_SETTING#SUCCESS',
  CREATE_FILE_SETTING_FAILURE = 'CREATE_FILE_SETTING#FAILURE',
}

export const initialState: FileSettingState = { data: undefined };

export interface FileSettingState {
  data?: FileSetting;
}

export interface FileSetting {
  url: string;
  encryptionKey: string;
  md5Hash: string;
}

export type FileSettingCreateRequestData = any;

export type FileSettingCreateSuccessData = FileSetting;
