import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  DeleteSuccessPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  VisitorSegmentActions as Actions,
  NewVisitorSegment,
  VisitorSegment,
  VisitorSegmentCreateRequestData,
  VisitorSegmentCreateSuccessData,
  VisitorSegmentDetail,
  VisitorSegmentUpdateRequestData,
  VisitorSegmentUpdateSuccessData,
} from './types';

export const listVisitorSegment = createAsyncAction(
  Actions.LIST_VISITOR_SEGMENT_REQUEST,
  Actions.LIST_VISITOR_SEGMENT_SUCCESS,
  Actions.LIST_VISITOR_SEGMENT_FAILURE
)<ListRequestPayload, ListState<VisitorSegment>, AxiosError>();

export const listVisitorSegmentSearch = createAsyncAction(
  Actions.LIST_VISITOR_SEGMENT_SEARCH_REQUEST,
  Actions.LIST_VISITOR_SEGMENT_SEARCH_SUCCESS,
  Actions.LIST_VISITOR_SEGMENT_SEARCH_FAILURE
)<ListRequestPayload, ListState<NewVisitorSegment>, AxiosError>();

export const listAllVisitorSegmentSearch = createAsyncAction(
  Actions.LIST_ALL_VISITOR_SEGMENT_SEARCH_REQUEST,
  Actions.LIST_ALL_VISITOR_SEGMENT_SEARCH_SUCCESS,
  Actions.LIST_ALL_VISITOR_SEGMENT_SEARCH_FAILURE
)<ListRequestPayload, ListState<NewVisitorSegment>, AxiosError>();

export const createVisitorSegment = createAsyncAction(
  Actions.CREATE_VISITOR_SEGMENT_REQUEST,
  Actions.CREATE_VISITOR_SEGMENT_SUCCESS,
  Actions.CREATE_VISITOR_SEGMENT_FAILURE
)<FormRequestPayload<VisitorSegmentCreateRequestData>, VisitorSegmentCreateSuccessData, AxiosError>();

export const readVisitorSegment = createAsyncAction(
  Actions.READ_VISITOR_SEGMENT_REQUEST,
  Actions.READ_VISITOR_SEGMENT_SUCCESS,
  Actions.READ_VISITOR_SEGMENT_FAILURE
)<ReadRequestPayload, VisitorSegmentDetail, AxiosError>();

export const updateVisitorSegment = createAsyncAction(
  Actions.UPDATE_VISITOR_SEGMENT_REQUEST,
  Actions.UPDATE_VISITOR_SEGMENT_SUCCESS,
  Actions.UPDATE_VISITOR_SEGMENT_FAILURE
)<FormRequestPayload<VisitorSegmentUpdateRequestData>, VisitorSegmentUpdateSuccessData, AxiosError>();

export const deleteVisitorSegment = createAsyncAction(
  Actions.DELETE_VISITOR_SEGMENT_REQUEST,
  Actions.DELETE_VISITOR_SEGMENT_SUCCESS,
  Actions.DELETE_VISITOR_SEGMENT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();

export const refreshVisitorSegment = createAsyncAction(
  Actions.REFRESH_VISITOR_SEGMENT_REQUEST,
  Actions.REFRESH_VISITOR_SEGMENT_SUCCESS,
  Actions.REFRESH_VISITOR_SEGMENT_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
