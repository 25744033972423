import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState } from 'store/types';

import { RealtimeMonitoringActions as Actions, RealtimeMonitoringListItem } from './types';

export const listRealtimeMonitoringManagerWorkloads = createAsyncAction(
  Actions.LIST_REAL_TIME_MONITORING_MANAGER_REQUEST,
  Actions.LIST_REAL_TIME_MONITORING_MANAGER_SUCCESS,
  Actions.LIST_REAL_TIME_MONITORING_MANAGER_FAILURE
)<ListRequestPayload, ListState<RealtimeMonitoringListItem>, AxiosError>();

export const listRealtimeMonitoringAgentWorkloads = createAsyncAction(
  Actions.LIST_REAL_TIME_MONITORING_AGENT_REQUEST,
  Actions.LIST_REAL_TIME_MONITORING_AGENT_SUCCESS,
  Actions.LIST_REAL_TIME_MONITORING_AGENT_FAILURE
)<ListRequestPayload, ListState<RealtimeMonitoringListItem>, AxiosError>();
