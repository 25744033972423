export enum GemProvideActions {
  PROVIDE_GEM_REQUEST = 'PROVIDE_GEM#REQUEST',
  PROVIDE_GEM_SUCCESS = 'PROVIDE_GEM#SUCCESS',
  PROVIDE_GEM_FAILURE = 'PROVIDE_GEM#FAILURE',

  PROVIDE_GEM_PACKAGE_REQUEST = 'PROVIDE_GEM_PACKAGE#REQUEST',
  PROVIDE_GEM_PACKAGE_SUCCESS = 'PROVIDE_GEM_PACKAGE#SUCCESS',
  PROVIDE_GEM_PACKAGE_FAILURE = 'PROVIDE_GEM_PACKAGE#FAILURE',
}

export enum GemProvideType {
  GEM = 'GEM',
  GEM_PACKAGE = 'GEM_PACKAGE',
}

export interface GemProvideTypeForm {
  type: GemProvideType;
}

interface ProvideGemAbstractFormValues {
  ticketId: string;
  userId: number;
}

export interface ProvideGemFormValues extends ProvideGemAbstractFormValues {
  gemAmount: number;
  isTestProvide: boolean;
}

export interface GemPackageRequestFormValues extends ProvideGemAbstractFormValues {
  productId: string;
  useMins: number;
  useCount: number;
}

export const ticketIdRegExp = /^HPCG-[0-9]{2,6}$/;
