import { initialListState, ListState } from 'store/types';

export enum PromotionContentActions {
  LIST_PROMOTION_CONTENTS_REQUEST = 'LIST_PROMOTION_CONTENTS#REQUEST',
  LIST_PROMOTION_CONTENTS_SUCCESS = 'LIST_PROMOTION_CONTENTS#SUCCESS',
  LIST_PROMOTION_CONTENTS_FAILURE = 'LIST_PROMOTION_CONTENTS#FAILURE',

  READ_PROMOTION_CONTENT_REQUEST = 'READ_PROMOTION_CONTENT#REQUEST',
  READ_PROMOTION_CONTENT_SUCCESS = 'READ_PROMOTION_CONTENT#SUCCESS',
  READ_PROMOTION_CONTENT_FAILURE = 'READ_PROMOTION_CONTENT#FAILURE',

  CREATE_PROMOTION_CONTENT_REQUEST = 'CREATE_PROMOTION_CONTENT#REQUEST',
  CREATE_PROMOTION_CONTENT_SUCCESS = 'CREATE_PROMOTION_CONTENT#SUCCESS',
  CREATE_PROMOTION_CONTENT_FAILURE = 'CREATE_PROMOTION_CONTENT#FAILURE',

  UPDATE_PROMOTION_CONTENT_REQUEST = 'UPDATE_PROMOTION_CONTENT#REQUEST',
  UPDATE_PROMOTION_CONTENT_SUCCESS = 'UPDATE_PROMOTION_CONTENT#SUCCESS',
  UPDATE_PROMOTION_CONTENT_FAILURE = 'UPDATE_PROMOTION_CONTENT#FAILURE',
}

export const initialState: PromotionContentState = {
  list: initialListState,
  detail: undefined,
};

export interface PromotionContentState {
  list: ListState<PromotionContentListItem>;
  detail?: PromotionContentDetail;
}

export enum PromotionType {
  NOTICE_POPUP = 'NOTICE_POPUP',
  TEAM_MESSAGE = 'TEAM_MESSAGE',
  ITEM_PACKAGE = 'ITEM_PACKAGE',
  NOTIFICATION_V1 = 'NOTIFICATION',
  NOTIFICATION_V2 = 'NOTIFICATION_V2',
  PUSH = 'PUSH',
  BRAZE_CAMPAIGN = 'BRAZE_CAMPAIGN',
}

export interface Promotion {
  id: string;
  type: string;
}

export interface PromotionContent {
  id: string;

  description: string;
  promotion: Promotion[];
  createdAt?: string;
  updatedAt?: string;
  groupId?: string;
}

export type PromotionContentListItem = PromotionContent;

export type PromotionContentDetail = PromotionContent;

export type PromotionFormValues = Promotion;

export type PromotionContentFormValues = PromotionContent;

export type PromotionContentCreateRequestData = PromotionContentFormValues;

export type PromotionContentCreateResponseData = PromotionContentFormValues;

export type PromotionContentUpdateRequestData = PromotionContentFormValues;

export type PromotionContentUpdateResponseData = PromotionContentFormValues;
