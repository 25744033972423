import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as decoSettingActions from './actions';
import { DecoSettingState, initialState } from './types';

const PRODUCT_COUNT_DEFAULT_VALUE = 0;

const decoSetting = createReducer<DecoSettingState, RootAction>(initialState)
  .handleAction(decoSettingActions.listDecoSetting.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(decoSettingActions.listDecoSetting.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(
        ({
          id,
          name,
          productCount: {
            DECO_STICKER: stickerCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_ENTRANCE_EFFECT: entranceEffectCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_FILTER_EFFECT: filterEffectCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_GRAPHIC_EFFECT: graphicEffectCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_JCV_FILTER: jcvFilterEffectCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_JCV_BACKGROUND: jcvBackgroundEffectCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_JCV_MAKEUP: jcvMakeupEffectCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_JCV_STYLE: jcvStyleEffectCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_BACKGROUND: backgroundCount = PRODUCT_COUNT_DEFAULT_VALUE,
            DECO_AVATAR: avatarCount = PRODUCT_COUNT_DEFAULT_VALUE,
          },
          schedules,
          products,
        }) => ({
          id,
          name,
          jcvFilterEffectCount,
          jcvBackgroundEffectCount,
          jcvMakeupEffectCount,
          jcvStyleEffectCount,
          stickerCount,
          entranceEffectCount,
          filterEffectCount,
          graphicEffectCount,
          backgroundCount,
          avatarCount,
          schedules,
          products,
        })
      ),
    });

    return newState.toJS() as DecoSettingState;
  })
  .handleAction(decoSettingActions.readDecoSetting.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default decoSetting;
