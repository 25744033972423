import {
  SpecialPromotionSettingProductCreateRequestData,
  SpecialPromotionSettingProductCreateSuccessData,
  SpecialPromotionSettingProductUpdateRequestData,
  SpecialPromotionSettingProductUpdateSuccessData,
} from 'store/specialPromotionSettingProduct/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createSpecialPromotionSettingProductApi = createPostApi<
  SpecialPromotionSettingProductCreateRequestData,
  SpecialPromotionSettingProductCreateSuccessData
>('/special_promotions/setting_products');

export const updateSpecialPromotionSettingProductApi = createUpdateApi<
  SpecialPromotionSettingProductUpdateRequestData,
  SpecialPromotionSettingProductUpdateSuccessData
>('/special_promotions/setting_products/:id');

export const deleteSpecialPromotionSettingProductApi = createDeleteApi('/special_promotions/setting_products/:id');
