import { FC, lazy } from 'react';

import { SvgIconProps } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AddToQueueRoundedIcon from '@material-ui/icons/AddToQueueRounded';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import DesktopMacRoundedIcon from '@material-ui/icons/DesktopMacRounded';
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import InsertFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import PhotoFilterRoundedIcon from '@material-ui/icons/PhotoFilterRounded';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AppRegistrationIcon from '@material-ui/icons/AccountBalanceWallet';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import { MenuEntryKey, PageTitle } from 'constants/routes';
import { PageInfo } from 'contexts/pageInfoContext';

import { RealmType } from './okta';
import { AssessmentOutlined, BallotOutlined, DehazeRounded, ScreenShare, Settings } from '@material-ui/icons';

export interface Route {
  Component: FC<any>;
  path: string;
  exact?: true;
  pageInfo?: PageInfo;
}

export interface EntryRoute {
  key: MenuEntryKey;
  label: string;
  IconComponent: (props: SvgIconProps) => JSX.Element;
  subRoutes: SubRoute[];
  realm: RealmType[]; // Keycloak realm whitelist
  roles?: string[]; // page visibility option
}

export type SubRoute = Omit<EntryRoute, 'key' | 'subRoutes' | 'realm' | 'IconComponent'> & Route;

const routes: EntryRoute[] = [
  {
    key: MenuEntryKey.SHOP_PAGE,
    label: 'Shop',
    IconComponent: ShoppingBasketIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Main Page',
        path: '/shop/main',
        Component: lazy(() => import('pages/ShopMainPage')),
        pageInfo: {
          title: PageTitle.SHOP_MAIN_PAGE.title,
          guideUrl: PageTitle.SHOP_MAIN_PAGE.guideUrl,
        },
      },
      {
        label: 'Detail Page',
        path: '/shop/detail',
        Component: lazy(() => import('pages/ShopDetailPage')),
        pageInfo: {
          title: PageTitle.SHOP_DETAIL_PAGE.title,
          guideUrl: PageTitle.SHOP_DETAIL_PAGE.guideUrl,
        },
      },
      {
        label: 'Tab',
        path: '/shop/tab',
        Component: lazy(() => import('pages/ShopTabPage')),
        pageInfo: {
          title: PageTitle.SHOP_TAB_PAGE.title,
          guideUrl: PageTitle.SHOP_TAB_PAGE.guideUrl,
        },
      },
      {
        label: 'Section',
        path: '/shop/section',
        Component: lazy(() => import('pages/ShopSectionPage')),
        pageInfo: {
          title: PageTitle.SHOP_SECTION_PAGE.title,
          guideUrl: PageTitle.SHOP_SECTION_PAGE.guideUrl,
        },
      },
      {
        label: 'Module',
        path: '/shop/module',
        Component: lazy(() => import('pages/ShopModulePage')),
        pageInfo: {
          title: PageTitle.SHOP_MODULE_PAGE.title,
          guideUrl: PageTitle.SHOP_MODULE_PAGE.guideUrl,
        },
      },
      {
        label: 'Module Group',
        path: '/shop/module-group',
        Component: lazy(() => import('pages/ShopModuleGroup')),
        pageInfo: {
          title: PageTitle.SHOP_MODULE_GROUP.title,
          guideUrl: PageTitle.SHOP_MODULE_GROUP.guideUrl,
        },
      },
      {
        label: 'Upload Web price',
        path: '/shop/webtierprice/upload',
        Component: lazy(() => import('pages/WebTierPrice')),
        pageInfo: {
          title: PageTitle.WEB_TIER_PRICE.title,
          guideUrl: PageTitle.WEB_TIER_PRICE.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.GEM_SHOP,
    label: 'Gem Shop',
    IconComponent: StorefrontIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Gem Shop Setting',
        path: '/gem-shop/setting',
        Component: lazy(() => import('pages/GemSetting')),
        pageInfo: {
          title: PageTitle.GEM_SHOP_SETTING.title,
          guideUrl: PageTitle.GEM_SHOP_SETTING.guideUrl,
        },
      },
      {
        label: 'Gem Shop Banner',
        path: '/gem-shop/banner',
        Component: lazy(() => import('pages/BannerSetting')),
        pageInfo: {
          title: PageTitle.GEM_SHOP_BANNER.title,
          guideUrl: PageTitle.GEM_SHOP_BANNER.guideUrl,
        },
      },
      {
        label: 'Special Promotion',
        path: '/gem-shop/special-promotion',
        Component: lazy(() => import('pages/SpecialPromotionSetting')),
        pageInfo: {
          title: PageTitle.GEM_SHOP_SPECIAL_PROMOTION.title,
          guideUrl: PageTitle.GEM_SHOP_SPECIAL_PROMOTION.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.USER,
    label: 'User',
    IconComponent: AccountCircleOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'User List',
        path: '/user',
        exact: true,
        Component: lazy(() => import('pages/User')),
        pageInfo: {
          title: PageTitle.USER_LIST.title,
        },
      },
      {
        label: 'Converted Key',
        path: '/user/convert-key',
        Component: lazy(() => import('pages/ConvertUserKey')),
        pageInfo: {
          title: PageTitle.USER_CONVERT_KEY.title,
          guideUrl: PageTitle.USER_CONVERT_KEY.guideUrl,
        },
      },
      {
        label: 'Gender',
        path: '/user/gender',
        Component: lazy(() => import('pages/GenderManage')),
        pageInfo: {
          title: PageTitle.USER_GENDER.title,
          guideUrl: PageTitle.USER_GENDER.guideUrl,
        },
      },
      {
        label: 'Profile',
        path: '/user/profile-manage',
        Component: lazy(() => import('pages/ProfileManage')),
        pageInfo: {
          title: PageTitle.USER_PROFILE.title,
          guideUrl: PageTitle.USER_PROFILE.guideUrl,
        },
      },
      {
        label: 'Country',
        path: '/user/country',
        Component: lazy(() => import('pages/CountryManage')),
        pageInfo: {
          title: PageTitle.USER_COUNTRY.title,
          guideUrl: PageTitle.USER_COUNTRY.guideUrl,
        },
      },
      {
        label: 'Match Tag',
        path: '/user/match-tag',
        Component: lazy(() => import('pages/MatchTagManage')),
        pageInfo: {
          title: PageTitle.USER_MATCH_TAG.title,
          guideUrl: PageTitle.USER_MATCH_TAG.guideUrl,
        },
      },
      {
        label: 'Age',
        path: '/user/age',
        Component: lazy(() => import('pages/AgeManage')),
        pageInfo: {
          title: PageTitle.USER_AGE.title,
          guideUrl: PageTitle.USER_AGE.guideUrl,
        },
      },
      {
        label: 'Blocked Phone Number',
        path: '/user/block-phone',
        Component: lazy(() => import('pages/BlockPhoneManage')),
        pageInfo: {
          title: PageTitle.USER_BLOCK_PHONE_NUMBER.title,
          guideUrl: PageTitle.USER_BLOCK_PHONE_NUMBER.guideUrl,
        },
      },
      {
        label: 'Item Inventory',
        path: '/user/item-inventory',
        Component: lazy(() => import('pages/UserItemInventory')),
        pageInfo: {
          title: PageTitle.USER_ITEM_INVENTORY.title,
          guideUrl: PageTitle.USER_ITEM_INVENTORY.guideUrl,
        },
      },
      {
        label: 'Friendship',
        path: '/user/friendship',
        Component: lazy(() => import('pages/Friendship')),
        pageInfo: {
          title: PageTitle.USER_FRIENDSHIP.title,
          guideUrl: PageTitle.USER_FRIENDSHIP.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.CAMPAIGN,
    label: 'Campaign',
    IconComponent: BallotOutlined,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Campaign',
        path: '/campaigns',
        Component: lazy(() => import('pages/Campaign')),
        pageInfo: {
          title: PageTitle.CAMPAIGN.title,
          guideUrl: PageTitle.CAMPAIGN.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.SEGMENT,
    label: 'Segment',
    IconComponent: ExtensionOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'User Segment',
        path: '/segment/user',
        Component: lazy(() => import('pages/UserSegment')),
        pageInfo: {
          title: PageTitle.SEGMENT_USER.title,
          guideUrl: PageTitle.SEGMENT_USER.guideUrl,
        },
      },
      {
        label: 'Expression Segment',
        path: '/segment/expression',
        Component: lazy(() => import('pages/ExpressionSegment')),
        pageInfo: {
          title: PageTitle.SEGMENT_EXPRESSION.title,
          guideUrl: PageTitle.SEGMENT_EXPRESSION.guideUrl,
        },
      },
      {
        label: 'Manual Segment',
        path: '/segment/manual',
        Component: lazy(() => import('pages/ManualSegment')),
        pageInfo: {
          title: PageTitle.SEGMENT_MANUAL.title,
          guideUrl: PageTitle.SEGMENT_MANUAL.guideUrl,
        },
      },
      {
        label: 'Criteria Segment',
        path: '/segment/criteria',
        Component: lazy(() => import('pages/CriteriaSegment')),
        pageInfo: {
          title: PageTitle.SEGMENT_CRITERIA.title,
          guideUrl: PageTitle.SEGMENT_CRITERIA.guideUrl,
        },
      },
      {
        label: 'Bigquery Segment',
        path: '/segment/bigquery',
        Component: lazy(() => import('pages/BigquerySegment')),
        pageInfo: {
          title: PageTitle.SEGMENT_BIGQUERY.title,
          guideUrl: PageTitle.SEGMENT_BIGQUERY.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.AB_TEST,
    label: 'AB Test',
    IconComponent: AssessmentOutlined,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'AB Test',
        path: '/abtest',
        Component: lazy(() => import('pages/AbTest')),
        pageInfo: {
          title: PageTitle.AB_TEST.title,
          guideUrl: PageTitle.AB_TEST.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.MATCH_FILTER,
    label: 'Match Filter',
    IconComponent: PhotoFilterRoundedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Filter Segment',
        path: '/match-filter/segment',
        Component: lazy(() => import('pages/MatchFilterSegment')),
        pageInfo: {
          title: PageTitle.MATCH_FILTER_SEGMENT.title,
          guideUrl: PageTitle.MATCH_FILTER_SEGMENT.guideUrl,
        },
      },
      {
        label: 'Filter Setting',
        path: '/match-filter/setting',
        Component: lazy(() => import('pages/MatchFilterSetting')),
        pageInfo: {
          title: PageTitle.MATCH_FILTER_SETTING.title,
          guideUrl: PageTitle.MATCH_FILTER_SETTING.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.DECO,
    label: 'Deco',
    IconComponent: ColorLensOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Deco Item',
        path: '/deco/item',
        Component: lazy(() => import('pages/DecoItem')),
        pageInfo: {
          title: PageTitle.DECO_ITEM.title,
          guideUrl: PageTitle.DECO_ITEM.guideUrl,
        },
      },
      {
        label: 'Deco Setting',
        path: '/deco/setting',
        Component: lazy(() => import('pages/DecoSetting')),
        pageInfo: {
          title: PageTitle.DECO_SETTING.title,
          guideUrl: PageTitle.DECO_SETTING.guideUrl,
        },
      },
      {
        label: 'Web Effect',
        path: '/deco/web/effect',
        Component: lazy(() => import('pages/WebDecoEffect')),
        pageInfo: {
          title: PageTitle.WEB_EFFECT.title,
          guideUrl: PageTitle.WEB_EFFECT.guideUrl,
        },
      },
      {
        label: 'Web Effect Setting',
        path: '/deco/web/setting',
        Component: lazy(() => import('pages/WebDecoSetting')),
        pageInfo: {
          title: PageTitle.WEB_EFFECT_SETTING.title,
          guideUrl: PageTitle.WEB_EFFECT_SETTING.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.PROMOTION,
    label: 'Promotion',
    IconComponent: EventNoteRoundedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Promotion Setting',
        path: '/promotion/setting',
        Component: lazy(() => import('pages/PromotionSetting')),
        pageInfo: {
          title: PageTitle.PROMOTION_SETTING.title,
          guideUrl: PageTitle.PROMOTION_SETTING.guideUrl,
        },
      },
      {
        label: 'Promotion Content',
        path: '/promotion/content',
        Component: lazy(() => import('pages/PromotionContent')),
        pageInfo: {
          title: PageTitle.PROMOTION_CONTENT.title,
          guideUrl: PageTitle.PROMOTION_CONTENT.guideUrl,
        },
      },
      {
        label: 'Promotion Content Group',
        path: '/promotion/groups',
        Component: lazy(() => import('pages/PromotionContentGroup')),
        pageInfo: {
          title: PageTitle.PROMOTION_CONTENT_GROUP.title,
        },
      },
      {
        label: 'Promotion Code Publish',
        path: '/promotion/publish',
        Component: lazy(() => import('pages/PromotionPublish')),
        pageInfo: {
          title: PageTitle.PROMOTION_CODE_PUBLISH.title,
          guideUrl: PageTitle.PROMOTION_CODE_PUBLISH.guideUrl,
        },
      },
      {
        label: 'Promotion Schedule',
        path: '/promotion-schedule/schedule',
        Component: lazy(() => import('pages/PromotionSchedule')),
        pageInfo: {
          title: PageTitle.PROMOTION_SCHEDULE.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.NOTICE,
    label: 'Notice',
    IconComponent: NotificationsNoneRoundedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Notice Popup',
        path: '/notice/popup',
        Component: lazy(() => import('pages/RNNotice')),
        pageInfo: {
          title: PageTitle.NOTICE_POPUP.title,
          guideUrl: PageTitle.NOTICE_POPUP.guideUrl,
        },
      },
      {
        label: 'Team Message',
        path: '/notice/team-message',
        Component: lazy(() => import('pages/TeamMessage')),
        pageInfo: {
          title: PageTitle.NOTICE_TEAM_MESSAGE.title,
          guideUrl: PageTitle.NOTICE_TEAM_MESSAGE.guideUrl,
        },
      },
      {
        label: 'Notification Content',
        path: '/notice/notification-content',
        Component: lazy(() => import('pages/NotificationContent')),
        pageInfo: {
          title: PageTitle.NOTIFICATION_CONTENT.title,
          guideUrl: PageTitle.NOTIFICATION_CONTENT.guideUrl,
        },
      },
      {
        label: 'Notification Schedule',
        path: '/notice/notification-schedule',
        Component: lazy(() => import('pages/NotificationSchedule')),
        pageInfo: {
          title: PageTitle.NOTIFICATION_SCHEDULE.title,
          guideUrl: PageTitle.NOTIFICATION_SCHEDULE.guideUrl,
        },
      },
      {
        label: 'Push Content',
        path: '/notice/push-content',
        Component: lazy(() => import('pages/PushContent')),
        pageInfo: {
          title: PageTitle.NOTICE_PUSH_CONTENT.title,
          guideUrl: PageTitle.NOTICE_PUSH_CONTENT.guideUrl,
        },
      },
      {
        label: 'Push Schedule',
        path: '/notice/push-schedule',
        Component: lazy(() => import('pages/PushSchedule')),
        pageInfo: {
          title: PageTitle.NOTICE_PUSH_SCHEDULE.title,
          guideUrl: PageTitle.NOTICE_PUSH_SCHEDULE.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.LOCALIZATION,
    label: 'Localization',
    IconComponent: LanguageRoundedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'String Localization',
        path: '/localization/string',
        Component: lazy(() => import('pages/StringLocalization')),
        pageInfo: {
          title: PageTitle.LOCALIZATION_STRING.title,
          guideUrl: PageTitle.LOCALIZATION_STRING.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.TEAM_MATCH,
    label: 'Team Match',
    IconComponent: GroupAddOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Team Match Campaign',
        path: '/team-match/campaign',
        Component: lazy(() => import('pages/TeamMatchCampaign')),
        pageInfo: {
          title: PageTitle.TEAM_MATCH_CAMPAIGN.title,
          guideUrl: PageTitle.TEAM_MATCH_CAMPAIGN.guideUrl,
        },
      },
      {
        label: 'Team Match Profile',
        path: '/team-match/profile',
        Component: lazy(() => import('pages/TeamMatchProfile')),
        pageInfo: {
          title: PageTitle.TEAM_MATCH_PROFILE.title,
          guideUrl: PageTitle.TEAM_MATCH_PROFILE.guideUrl,
        },
      },
      {
        label: 'Lounge Team Match Content',
        path: '/team-match/lounge/content',
        Component: lazy(() => import('pages/LoungeTeamMatchContent')),
        pageInfo: {
          title: 'Lounge Team Match Content',
        },
      },
      {
        label: 'Lounge Team Match Schedule',
        path: '/team-match/lounge/schedule',
        Component: lazy(() => import('pages/LoungeTeamMatchSchedule')),
        pageInfo: {
          title: 'Lounge Team Match Schedule',
        },
      },
    ],
  },
  {
    key: MenuEntryKey.FILE,
    label: 'File',
    IconComponent: InsertFileOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Downloadable contents',
        path: '/file/downloadable-contents',
        Component: lazy(() => import('pages/DownloadableContent')),
        pageInfo: {
          title: PageTitle.DOWNLOADABLE_CONTENT.title,
          guideUrl: PageTitle.DOWNLOADABLE_CONTENT.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.ML_CONFIG,
    label: 'ML Config',
    IconComponent: LocalLibraryIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Model',
        path: '/ml-config/model',
        Component: lazy(() => import('pages/MlConfigModel')),
        pageInfo: {
          title: PageTitle.ML_CONFIG_MODEL.title,
          guideUrl: PageTitle.ML_CONFIG_MODEL.guideUrl,
        },
      },
      {
        label: 'Feature',
        path: '/ml-config/feature',
        Component: lazy(() => import('pages/MlConfigFeature')),
        pageInfo: {
          title: PageTitle.ML_CONFIG_FEATURE.title,
          guideUrl: PageTitle.ML_CONFIG_FEATURE.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.EXTERNAL_URL,
    label: 'External URL',
    IconComponent: LinkRoundedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'RN Version',
        path: '/external-url/rn-version',
        Component: lazy(() => import('pages/RNVersionCheck')),
        pageInfo: {
          title: PageTitle.EXTERNAL_URL_RN_VERSION.title,
          guideUrl: PageTitle.EXTERNAL_URL_RN_VERSION.guideUrl,
        },
      },
      {
        label: 'Build Release Rate',
        path: '/external-url/build-release-rate',
        Component: lazy(() => import('pages/BuildReleaseRateCheck')),
        pageInfo: {
          title: PageTitle.EXTERNAL_URL_BUILD_RELEASE_RATE_CHECK.title,
          guideUrl: PageTitle.EXTERNAL_URL_BUILD_RELEASE_RATE_CHECK.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.REALTIME_MONITORING,
    label: 'Realtime Monitoring',
    IconComponent: DesktopMacRoundedIcon,
    realm: [RealmType.moderation],
    subRoutes: [
      {
        label: 'Realtime Monitoring Workload',
        path: '/realtime-monitoring',
        Component: lazy(() => import('pages/RealtimeMonitoring')),
        pageInfo: {
          title: PageTitle.REALTIME_MONITORING_WORKLOAD.title,
          guideUrl: PageTitle.REALTIME_MONITORING_WORKLOAD.guideUrl,
        },
      },
      {
        label: 'Notice',
        path: '/realtime-monitoring-notice',
        Component: lazy(() => import('pages/MoniNotice')),
        pageInfo: {
          title: PageTitle.REALTIME_MONITORING_NOTICE.title,
          guideUrl: PageTitle.REALTIME_MONITORING_NOTICE.guideUrl,
        },
      },
      {
        label: 'Punish',
        path: '/punish',
        Component: lazy(() => import('pages/Punish')),
        pageInfo: {
          title: PageTitle.PUNISH.title,
          guideUrl: PageTitle.PUNISH.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.AGENT,
    label: 'Agent',
    IconComponent: AddToQueueRoundedIcon,
    realm: [RealmType.hpcnt, RealmType.moderation],
    subRoutes: [
      {
        label: 'Agent Management',
        path: '/agent/management',
        Component: lazy(() => import('pages/AgentManage')),
        pageInfo: {
          title: PageTitle.AGENT_MANAGEMENT.title,
          guideUrl: PageTitle.AGENT_MANAGEMENT.guideUrl,
        },
      },
      {
        label: 'Agent History',
        path: '/agent/history',
        Component: lazy(() => import('pages/AgentHistory')),
        pageInfo: {
          title: PageTitle.AGENT_HISTORY.title,
          guideUrl: PageTitle.AGENT_HISTORY.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.ITEM_PACKAGE,
    label: 'Product',
    IconComponent: CardGiftcardIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Gem Product',
        path: '/gem-products',
        Component: lazy(() => import('pages/GemProduct')),
        pageInfo: {
          title: PageTitle.GEM_PRODUCT.title,
          guideUrl: PageTitle.GEM_PRODUCT.guideUrl,
        },
      },
      {
        label: 'Item Product',
        path: '/item',
        Component: lazy(() => import('pages/ItemProduct')),
        pageInfo: {
          title: PageTitle.ITEM.title,
          guideUrl: PageTitle.ITEM.guideUrl,
        },
      },
      {
        label: 'Item Package',
        path: '/item-package',
        Component: lazy(() => import('pages/ItemPackage')),
        pageInfo: {
          title: PageTitle.ITEM_PACKAGE.title,
          guideUrl: PageTitle.ITEM_PACKAGE.guideUrl,
        },
      },
      {
        label: 'Limited Edition',
        path: '/limited-edition',
        Component: lazy(() => import('pages/LimitedEditionPage')),
        pageInfo: {
          title: PageTitle.LIMITED_EDITION.title,
          guideUrl: PageTitle.LIMITED_EDITION.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.INVENTORY,
    label: 'Inventory',
    IconComponent: MonetizationOnIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Gem Provide',
        path: '/inventory/gem-provide',
        Component: lazy(() => import('pages/GemProvide')),
        pageInfo: {
          title: PageTitle.GEM_PROVIDE.title,
        },
        roles: ['app-azar-backoffice-hpcnt-gem-provide', 'app-azar-backoffice-hpcnt-gem-package-provide'],
      },
      {
        label: 'Gem Withdraw',
        path: '/inventory/gem-withdraw',
        Component: lazy(() => import('pages/GemWithdraw')),
        pageInfo: {
          title: PageTitle.GEM_WITHDRAW.title,
        },
      },
      {
        label: 'Gem Cancel Purchase',
        path: '/inventory/gem-cancel-purchase',
        Component: lazy(() => import('pages/GemCancelPurchase')),
        pageInfo: {
          title: PageTitle.GEM_CANCEL_PURCHASE.title,
        },
      },
      {
        label: 'Item Product Provide',
        path: '/inventory/item-provide',
        Component: lazy(() => import('pages/ItemProductProvide')),
        pageInfo: {
          title: PageTitle.ITEM_PROVIDE.title,
        },
      },
      {
        label: 'Item Package Provide',
        path: '/inventory/item-package-provide',
        Component: lazy(() => import('pages/ItemPackageProvide')),
        pageInfo: {
          title: PageTitle.ITEM_PACKAGE_PROVIDE.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.PURCHASE,
    label: 'Purchase',
    IconComponent: AccountBalanceWalletIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Invalidated Purchase',
        path: '/invalidated-purchase',
        Component: lazy(() => import('pages/InvalidatedPurchase')),
        pageInfo: {
          title: PageTitle.INVALIDATED_PURCHASE.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.BANNER,
    label: 'Banner',
    IconComponent: ViewCarouselOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Mirror Banner',
        path: '/banner/water-slide-mirror',
        Component: lazy(() => import('pages/WaterSlideBanner')),
        pageInfo: {
          title: PageTitle.WATER_SLIDE_BANNER.title,
          guideUrl: PageTitle.WATER_SLIDE_BANNER.guideUrl,
        },
      },
      {
        label: 'Message Banner',
        path: '/banner/message',
        Component: lazy(() => import('pages/MessageBanner')),
        pageInfo: {
          title: PageTitle.MESSAGE_BANNER.title,
          guideUrl: PageTitle.MESSAGE_BANNER.guideUrl,
        },
      },
      {
        label: 'Inventory Banner',
        path: '/banner/inventory/settings',
        Component: lazy(() => import('pages/InventoryBanner')),
        pageInfo: {
          title: 'Inventory Banner',
        },
      },
      {
        label: 'Inventory Banner Schedule',
        path: '/banner/inventory/schedules',
        Component: lazy(() => import('pages/InventoryBannerSchedule')),
        pageInfo: {
          title: 'Inventory Banner Schedule',
        },
      },
      {
        label: 'Shop Entry Banner',
        path: '/banner/shop_entry',
        Component: lazy(() => import('pages/ShopEntryBanner')),
        pageInfo: {
          title: PageTitle.SHOP_ENTRY_BANNER.title,
          guideUrl: PageTitle.SHOP_ENTRY_BANNER.guideUrl,
        },
      },
      {
        label: 'Notification Center Banner',
        path: '/banners/notification-center',
        Component: lazy(() => import('pages/NotificationCenterBanner')),
        pageInfo: {
          title: PageTitle.NOTIFICATION_CENTER_BANNER.title,
          guideUrl: PageTitle.NOTIFICATION_CENTER_BANNER.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.REWARD_AD,
    label: 'Reward Ad',
    IconComponent: VideoLibraryIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Reward Ad',
        path: '/reward-ads',
        Component: lazy(() => import('pages/RewardAd')),
        pageInfo: {
          title: 'Reward Ad',
        },
      },
    ],
  },
  {
    key: MenuEntryKey.PUNISHMENT,
    label: 'Punishment',
    IconComponent: AccountCircleOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Active Punishment Search',
        path: '/punishments',
        Component: lazy(() => import('pages/Punishment')),
        pageInfo: {
          title: PageTitle.PUNISHMENT.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.MATATA,
    label: 'Matata',
    IconComponent: ScreenShare,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Matata Suspension History',
        path: '/matata/suspension',
        Component: lazy(() => import('pages/MatataSuspensionHistory')),
        pageInfo: {
          title: PageTitle.MATATA_SUSPENSION_HISTORY.title,
        },
      },
      {
        label: 'Matata Device Unbinding',
        path: '/matata/device',
        Component: lazy(() => import('pages/MatataDeviceUnbinding')),
        pageInfo: {
          title: PageTitle.MATATA_DEVICE_UNBINDING.title,
        },
      },
      {
        label: 'Matata Order Transaction',
        path: '/matata/order',
        Component: lazy(() => import('pages/MatataOrderTransaction')),
        pageInfo: {
          title: PageTitle.MATATA_ORDER_TRANSACTION.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.AVATAR_ADMIN,
    label: 'Avatar Admin',
    IconComponent: AccountCircleOutlinedIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Simulator Page',
        path: '/simulator',
        Component: lazy(() => import('pages/AvatarSimulator')),
        pageInfo: {
          title: PageTitle.AVATAR_SIMULATOR.title,
        },
      },
      {
        label: 'Deco Page',
        path: '/avatar-deco',
        Component: lazy(() => import('pages/AvatarDeco')),
        pageInfo: {
          title: PageTitle.AVATAR_DECO.title,
        },
      },
      {
        label: 'Character Page',
        path: '/avatar-character',
        Component: lazy(() => import('pages/AvatarCharacter')),
        pageInfo: {
          title: PageTitle.AVATAR_CHARACTER.title,
        },
      },
      {
        label: 'BGSet Page',
        path: '/avatar-bgset',
        Component: lazy(() => import('pages/AvatarBGSet')),
        pageInfo: {
          title: PageTitle.AVATAR_BGSET.title,
        },
      },
      {
        label: 'Animation Page',
        path: '/avatar-animation',
        Component: lazy(() => import('pages/AvatarAnimation')),
        pageInfo: {
          title: PageTitle.AVATAR_ANIMATION.title,
        },
      },
      {
        label: 'Reaction Animation Page',
        path: '/avatar-reaction-animation',
        Component: lazy(() => import('pages/AvatarReactionAnimation')),
        pageInfo: {
          title: PageTitle.AVATAR_ANIMATION.title,
        },
      },
      {
        label: 'InitialSet Page',
        path: '/avatar-initialset',
        Component: lazy(() => import('pages/AvatarInitialSet')),
        pageInfo: {
          title: PageTitle.AVATAR_INITIALSET.title,
        },
      },
      {
        label: 'BestSet Page',
        path: '/avatar-bestset',
        Component: lazy(() => import('pages/AvatarBestSet')),
        pageInfo: {
          title: PageTitle.AVATAR_BESTSET.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.CONFIG,
    label: 'Config',
    IconComponent: Settings,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Remote Config',
        path: '/config/remote',
        Component: lazy(() => import('pages/RemoteConfig')),
        pageInfo: {
          title: PageTitle.REMOTE_CONFIG.title,
        },
      },
      {
        label: 'Config Store',
        path: '/config/store',
        Component: lazy(() => import('pages/ConfigStore')),
        pageInfo: {
          title: PageTitle.CONFIG_STORE.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.VISITOR,
    label: 'Visitor',
    IconComponent: DehazeRounded,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Config',
        path: '/visitors/configs',
        Component: lazy(() => import('pages/VisitorConfig')),
        pageInfo: {
          title: PageTitle.VISITOR_CONFIG.title,
          guideUrl: PageTitle.VISITOR_CONFIG.guideUrl,
        },
      },
      {
        label: 'AB Test',
        path: '/visitors/abtests',
        Component: lazy(() => import('pages/VisitorAbTest')),
        pageInfo: {
          title: PageTitle.VISITOR_AB_TEST.title,
          guideUrl: PageTitle.VISITOR_AB_TEST.guideUrl,
        },
      },
      {
        label: 'Segment',
        path: '/visitors/segments',
        Component: lazy(() => import('pages/VisitorSegment')),
        pageInfo: {
          title: PageTitle.VISITOR_SEGMENT.title,
          guideUrl: PageTitle.VISITOR_SEGMENT.guideUrl,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.PURPOSE,
    label: 'Purpose',
    IconComponent: QuestionAnswer,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Purpose Setting',
        path: '/purposes',
        Component: lazy(() => import('pages/Purpose')),
        pageInfo: {
          title: PageTitle.PURPOSE.title,
        },
      },
    ],
  },
  {
    key: MenuEntryKey.APP_STORE,
    label: 'App Store',
    IconComponent: AppRegistrationIcon,
    realm: [RealmType.hpcnt],
    subRoutes: [
      {
        label: 'Review',
        path: '/app-store/reviews',
        Component: lazy(() => import('pages/AppStoreReview')),
        pageInfo: {
          title: PageTitle.APP_STORE_REVIEW.title,
        },
      },
    ],
  },
];

export default routes;
