import { combineEpics } from 'redux-observable';

import { createFlatListEpic, createPostEpic, createToastEpic } from 'store/utils';

import { listMatchFilter, refreshMatchFilter } from './actions';
import { listMatchFilterApi, refreshMatchFilterApi } from './api';

const listMatchFilterEpic = createFlatListEpic(listMatchFilter, listMatchFilterApi);

const refreshMatchFilterEpic = createPostEpic(refreshMatchFilter, refreshMatchFilterApi);

const refreshMatchFilterToastEpic = createToastEpic(refreshMatchFilter.success, 'Successfully refreshed server');

export default combineEpics(listMatchFilterEpic, refreshMatchFilterEpic, refreshMatchFilterToastEpic);
