import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  MatchFilterConfigActions as Actions,
  MatchFilterConfigCreateRequestData,
  MatchFilterConfigCreateResponseData,
  MatchFilterConfigFormValue,
  MatchFilterConfigUpdateRequestData,
  MatchFilterConfigUpdateResponseData,
} from './types';

export const createMatchFilterConfig = createAsyncAction(
  Actions.CREATE_MATCH_FILTER_CONFIG_REQUEST,
  Actions.CREATE_MATCH_FILTER_CONFIG_SUCCESS,
  Actions.CREATE_MATCH_FILTER_CONFIG_FAILURE
)<
  FormRequestPayload<MatchFilterConfigFormValue, MatchFilterConfigCreateRequestData>,
  MatchFilterConfigCreateResponseData,
  AxiosError
>();

export const updateMatchFilterConfig = createAsyncAction(
  Actions.UPDATE_MATCH_FILTER_CONFIG_REQUEST,
  Actions.UPDATE_MATCH_FILTER_CONFIG_SUCCESS,
  Actions.UPDATE_MATCH_FILTER_CONFIG_FAILURE
)<
  FormRequestPayload<MatchFilterConfigFormValue, MatchFilterConfigUpdateRequestData>,
  MatchFilterConfigUpdateResponseData,
  AxiosError
>();

export const deleteMatchFilterConfig = createAsyncAction(
  Actions.DELETE_MATCH_FILTER_CONFIG_REQUEST,
  Actions.DELETE_MATCH_FILTER_CONFIG_SUCCESS,
  Actions.DELETE_MATCH_FILTER_CONFIG_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
