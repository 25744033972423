import { initialListState, ListState } from 'store/types';
import { periodUnitType, productType, limitType } from './constant';

export enum LimitedEditionPageActions {
  LIST_LIMITED_EDITION_PAGE_REQUEST = 'LIST_LIMITED_EDITION_PAGE#REQUEST',
  LIST_LIMITED_EDITION_PAGE_SUCCESS = 'LIST_LIMITED_EDITION_PAGE#SUCCESS',
  LIST_LIMITED_EDITION_PAGE_FAILURE = 'LIST_LIMITED_EDITION_PAGE#FAILURE',

  READ_LIMITED_EDITION_PAGE_REQUEST = 'READ_LIMITED_EDITION_PAGE#REQUEST',
  READ_LIMITED_EDITION_PAGE_FAILURE = 'READ_LIMITED_EDITION_PAGE#FAILURE',
  READ_LIMITED_EDITION_PAGE_SUCCESS = 'READ_LIMITED_EDITION_PAGE#SUCCESS',

  CREATE_LIMITED_EDITION_PAGE_REQUEST = 'CREATE_LIMITED_EDITION_PAGE#REQUEST',
  CREATE_LIMITED_EDITION_PAGE_SUCCESS = 'CREATE_LIMITED_EDITION_PAGE#SUCCESS',
  CREATE_LIMITED_EDITION_PAGE_FAILURE = 'CREATE_LIMITED_EDITION_PAGE#FAILURE',

  UPDATE_LIMITED_EDITION_PAGE_REQUEST = 'UPDATE_LIMITED_EDITION_PAGE#REQUEST',
  UPDATE_LIMITED_EDITION_PAGE_SUCCESS = 'UPDATE_LIMITED_EDITION_PAGE#SUCCESS',
  UPDATE_LIMITED_EDITION_PAGE_FAILURE = 'UPDATE_LIMITED_EDITION_PAGE#FAILURE',

  DELETE_LIMITED_EDITION_PAGE_REQUEST = 'DELETE_LIMITED_EDITION_PAGE#REQUEST',
  DELETE_LIMITED_EDITION_PAGE_SUCCESS = 'DELETE_LIMITED_EDITION_PAGE#SUCCESS',
  DELETE_LIMITED_EDITION_PAGE_FAILURE = 'DELETE_LIMITED_EDITION_PAGE#FAILURE',
}

export type ProductType = typeof productType[number];
export type PeriodUnitType = typeof periodUnitType[number];
export type LimitType = Partial<typeof limitType[number]>;

export interface LimitedEdition {
  id: number;
  productType: ProductType;
  productId: string;
  platform: string;
  limitType: LimitType;
  manualLimitKey: string;
  maxPurchaseCount: number;
  period: number;
  periodUnit: PeriodUnitType;
}

export type LimitedEditionListItem = LimitedEdition;
export type LimitedEditionFormValues = Partial<LimitedEdition>;
export type LimitedEditionRequestPayload = LimitedEditionFormValues;
export type LimitedEditionResponsePayload = LimitedEditionFormValues;

export interface LimitedEditionState {
  list: ListState<LimitedEditionListItem>;
  detail?: LimitedEdition;
}

export const initialState = {
  list: initialListState,
  detail: undefined,
};
