import { initialListState, ListState } from 'store/types';

export enum PromotionContentGroupActions {
  LIST_PROMOTION_CONTENT_GROUP_REQUEST = 'LIST_PROMOTION_CONTENT_GROUP#REQUEST',
  LIST_PROMOTION_CONTENT_GROUP_SUCCESS = 'LIST_PROMOTION_CONTENT_GROUP#SUCCESS',
  LIST_PROMOTION_CONTENT_GROUP_FAILURE = 'LIST_PROMOTION_CONTENT_GROUP#FAILURE',

  LIST_PROMOTION_CONTENT_GROUP_SEARCH_REQUEST = 'LIST_PROMOTION_CONTENT_GROUP_SEARCH#REQUEST',
  LIST_PROMOTION_CONTENT_GROUP_SEARCH_SUCCESS = 'LIST_PROMOTION_CONTENT_GROUP_SEARCH#SUCCESS',
  LIST_PROMOTION_CONTENT_GROUP_SEARCH_FAILURE = 'LIST_PROMOTION_CONTENT_GROUP_SEARCH#FAILURE',

  READ_PROMOTION_CONTENT_GROUP_REQUEST = 'READ_PROMOTION_CONTENT_GROUP#REQUEST',
  READ_PROMOTION_CONTENT_GROUP_FAILURE = 'READ_PROMOTION_CONTENT_GROUP#FAILURE',
  READ_PROMOTION_CONTENT_GROUP_SUCCESS = 'READ_PROMOTION_CONTENT_GROUP#SUCCESS',

  CREATE_PROMOTION_CONTENT_GROUP_REQUEST = 'CREATE_PROMOTION_CONTENT_GROUP#REQUEST',
  CREATE_PROMOTION_CONTENT_GROUP_SUCCESS = 'CREATE_PROMOTION_CONTENT_GROUP#SUCCESS',
  CREATE_PROMOTION_CONTENT_GROUP_FAILURE = 'CREATE_PROMOTION_CONTENT_GROUP#FAILURE',

  UPDATE_PROMOTION_CONTENT_GROUP_REQUEST = 'UPDATE_PROMOTION_CONTENT_GROUP#REQUEST',
  UPDATE_PROMOTION_CONTENT_GROUP_SUCCESS = 'UPDATE_PROMOTION_CONTENT_GROUP#SUCCESS',
  UPDATE_PROMOTION_CONTENT_GROUP_FAILURE = 'UPDATE_PROMOTION_CONTENT_GROUP#FAILURE',
}

export const initialState: PromotionContentGroupState = {
  list: initialListState,
  searchList: initialListState,
  detail: undefined,
};

export interface PromotionContentGroupState {
  list: ListState<PromotionContentGroupListItem>;
  searchList: ListState<PromotionContentGroupSearchItem>;
  detail?: PromotionContentGroupDetail;
}

export interface PromotionContentGroupSearchItem {
  id: string;
}

export interface PromotionContentGroupDetail {
  id: string;
  description?: string;
  limitCount?: number;
  limitIntervalSeconds?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface PromotionContentGroupFormValues {
  id: string;
  description?: string;
  limitCount?: number;
  limitIntervalSeconds?: number;
}

export type PromotionContentGroupListItem = PromotionContentGroupDetail;

export type PromotionContentGroupCreateRequestData = PromotionContentGroupFormValues;

export type PromotionContentGroupCreateResponseData = PromotionContentGroupFormValues;

export type PromotionContentGroupUpdateRequestData = PromotionContentGroupFormValues;

export type PromotionContentGroupUpdateResponseData = PromotionContentGroupFormValues;
