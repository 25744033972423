import {
  MlConfigModelSegmentCreateRequestData,
  MlConfigModelSegmentCreateSuccessData,
  MlConfigModelSegmentUpdateRequestData,
  MlConfigModelSegmentUpdateSuccessData,
} from 'store/mlConfigModelSegment/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

const BASE_PATH = '/ml-config/models/segments';
export const createMlConfigModelSegmentApi = createPostApi<
  MlConfigModelSegmentCreateRequestData,
  MlConfigModelSegmentCreateSuccessData
>(BASE_PATH);

export const updateMlConfigModelSegmentApi = createUpdateApi<
  MlConfigModelSegmentUpdateRequestData,
  MlConfigModelSegmentUpdateSuccessData
>(`${BASE_PATH}/:id`);

export const deleteMlConfigModelSegmentApi = createDeleteApi(`/ml-config/models/:modelId/segments/:segmentId`);
