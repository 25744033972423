import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createTeamMatchProfile,
  deleteTeamMatchProfile,
  listTeamMatchProfile,
  readTeamMatchProfile,
  updateTeamMatchProfile,
} from './actions';
import {
  createTeamMatchProfileApi,
  deleteTeamMatchProfileApi,
  listTeamMatchProfileApi,
  readTeamMatchProfileApi,
  updateTeamMatchProfileApi,
} from './api';

const listTeamMatchProfileEpic = createListEpic(listTeamMatchProfile, listTeamMatchProfileApi);

const readTeamMatchProfileEpic = createReadEpic(readTeamMatchProfile, readTeamMatchProfileApi);

const createTeamMatchProfileEpic = createPostEpic(createTeamMatchProfile, createTeamMatchProfileApi);

const createTeamMatchProfileToastEpic = createToastEpic(createTeamMatchProfile.success, 'Successfully created');

const updateTeamMatchProfileEpic = createUpdateEpic(updateTeamMatchProfile, updateTeamMatchProfileApi);

const updateTeamMatchProfileToastEpic = createToastEpic(updateTeamMatchProfile.success, 'Successfully saved');

const deleteTeamMatchProfileEpic = createDeleteEpic(deleteTeamMatchProfile, deleteTeamMatchProfileApi);

const deleteTeamMatchProfileToastEpic = createToastEpic(deleteTeamMatchProfile.success, 'Successfully deleted');

export default combineEpics(
  listTeamMatchProfileEpic,
  readTeamMatchProfileEpic,
  createTeamMatchProfileEpic,
  createTeamMatchProfileToastEpic,
  updateTeamMatchProfileEpic,
  updateTeamMatchProfileToastEpic,
  deleteTeamMatchProfileEpic,
  deleteTeamMatchProfileToastEpic
);
