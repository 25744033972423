import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic } from 'store/utils';

import { convertUserIdsToUsernames, convertUsernamesToUserIds } from './actions';
import { convertUserIdsToUsernamesApi, convertUsernamesToUserIdsApi } from './api';

const convertUserIdsToUsernamesToastEpic = createToastEpic(convertUserIdsToUsernames.success, 'Successfully Converted');

const convertUsernamesToUserIdsToastEpic = createToastEpic(convertUsernamesToUserIds.success, 'Successfully Converted');

const convertUserIdsToUsernamesEpic = createPostEpic(convertUserIdsToUsernames, convertUserIdsToUsernamesApi);

const convertUsernamesToUserIdsEpic = createPostEpic(convertUsernamesToUserIds, convertUsernamesToUserIdsApi);

export default combineEpics(
  convertUsernamesToUserIdsToastEpic,
  convertUserIdsToUsernamesToastEpic,
  convertUsernamesToUserIdsEpic,
  convertUserIdsToUsernamesEpic
);
