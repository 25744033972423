import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Role } from 'store/realtimeMonitoring/types';
import { RootState } from 'store/root/types';

const selector = ({ user }: RootState) => user;

const useRole = () => {
  const { roles, username: agentId } = useSelector(selector);

  return useMemo(() => {
    const isManager = roles?.includes(Role.manager);

    return {
      roles,
      isManager,
      agentId,
    };
  }, [agentId, roles]);
};

export default useRole;
