import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  FormRequestPayload,
  ReadRequestPayload,
  ListRequestPayload,
  DeleteRequestPayload,
  ListState,
} from 'store/types';

import {
  DownloadableContentActions as Actions,
  DownloadableContentData,
  DownloadableContentCreateRequestData,
} from './types';

export const listDownloadableContent = createAsyncAction(
  Actions.LIST_DOWNLOADABLE_CONTENT_REQUEST,
  Actions.LIST_DOWNLOADABLE_CONTENT_SUCCESS,
  Actions.LIST_DOWNLOADABLE_CONTENT_FAILURE
)<ListRequestPayload, ListState<DownloadableContentData>, AxiosError>();

export const readDownloadableContent = createAsyncAction(
  Actions.READ_DOWNLOADABLE_CONTENT_REQUEST,
  Actions.READ_DOWNLOADABLE_CONTENT_SUCCESS,
  Actions.READ_DOWNLOADABLE_CONTENT_FAILURE
)<ReadRequestPayload, DownloadableContentData, AxiosError>();

export const createDownloadableContent = createAsyncAction(
  Actions.CREATE_DOWNLOADABLE_CONTENT_REQUEST,
  Actions.CREATE_DOWNLOADABLE_CONTENT_SUCCESS,
  Actions.CREATE_DOWNLOADABLE_CONTENT_FAILURE
)<
  FormRequestPayload<DownloadableContentCreateRequestData, DownloadableContentCreateRequestData>,
  DownloadableContentData,
  AxiosError
>();

export const updateDownloadableContent = createAsyncAction(
  Actions.UPDATE_DOWNLOADABLE_CONTENT_REQUEST,
  Actions.UPDATE_DOWNLOADABLE_CONTENT_SUCCESS,
  Actions.UPDATE_DOWNLOADABLE_CONTENT_FAILURE
)<
  FormRequestPayload<DownloadableContentCreateRequestData, DownloadableContentCreateRequestData>,
  DownloadableContentData,
  AxiosError
>();

export const deleteDownloadableContent = createAsyncAction(
  Actions.DELETE_DOWNLOADABLE_CONTENT_FAILURE,
  Actions.DELETE_DOWNLOADABLE_CONTENT_SUCCESS,
  Actions.DELETE_DOWNLOADABLE_CONTENT_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const resetDownloadableContent = createAction(Actions.RESET_DOWNLOADABLE_CONTENT)<string>();
