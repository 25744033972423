import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import { FileSettingActions as Actions, FileSettingCreateRequestData, FileSettingCreateSuccessData } from './types';

export const createFileSetting = createAsyncAction(
  Actions.CREATE_FILE_SETTING_REQUEST,
  Actions.CREATE_FILE_SETTING_SUCCESS,
  Actions.CREATE_FILE_SETTING_FAILURE
)<
  FormRequestPayload<FileSettingCreateRequestData, FileSettingCreateRequestData>,
  FileSettingCreateSuccessData,
  AxiosError
>();

export const resetFileSetting = createAction(Actions.RESET_FILE_SETTING)<string>();
