import { createAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  SettingActions as Actions,
  SetPalettePayload,
  SetSettingPayload,
  SetPresetsPayload,
  SetCurrentPresetIndexPayload,
} from './types';

export const setPalette = createAction(Actions.SET_PALETTE)<SetPalettePayload>();

export const setPresets = createAction(Actions.SET_PRESETS)<SetPresetsPayload>();

export const setCurrentPresetIndex = createAction(Actions.SET_CURRENT_PRESET)<SetCurrentPresetIndexPayload>();

export const setSetting = createAction(Actions.SET_SETTING)<FormRequestPayload<SetSettingPayload>>();
