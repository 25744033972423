import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic } from '../utils';

import {
  notWithdrawMatataInvalidatedPurchaseByReceiver,
  listMatataInvalidatedPurchaseByReceiver,
  readMatataInvalidatedPurchaseByReceiver,
  withdrawMatataInvalidatedPurchaseByReceiver,
} from './actions';
import {
  notWithdrawMatataInvalidatedPurchaseByReceiverApi,
  listMatataInvalidatedPurchaseByReceiverApi,
  readMatataInvalidatedPurchaseByReceiverApi,
  withdrawMatataInvalidatedPurchaseByReceiverApi,
} from './api';

const readMatataInvalidatedPurchaseByReceiverEpic = createReadEpic(
  readMatataInvalidatedPurchaseByReceiver,
  readMatataInvalidatedPurchaseByReceiverApi
);
const listMatataInvalidatedPurchaseByReceiverEpic = createListEpic(
  listMatataInvalidatedPurchaseByReceiver,
  listMatataInvalidatedPurchaseByReceiverApi
);

const withdrawMatataInvalidatedPurchaseByReceiverEpic = createPostEpic(
  withdrawMatataInvalidatedPurchaseByReceiver,
  withdrawMatataInvalidatedPurchaseByReceiverApi
);

const withdrawMatataInvalidatedPurchaseByReceiverToastEpic = createToastEpic(
  withdrawMatataInvalidatedPurchaseByReceiver.success,
  'Successfully withdrawn'
);

const notWithdrawMatataInvalidatedPurchaseByReceiverEpic = createPostEpic(
  notWithdrawMatataInvalidatedPurchaseByReceiver,
  notWithdrawMatataInvalidatedPurchaseByReceiverApi
);

const notWithdrawMatataInvalidatedPurchaseByReceiverToastEpic = createToastEpic(
  notWithdrawMatataInvalidatedPurchaseByReceiver.success,
  'Successfully ignored(NOT WITHDRAWN)'
);

export default combineEpics(
  readMatataInvalidatedPurchaseByReceiverEpic,
  listMatataInvalidatedPurchaseByReceiverEpic,
  withdrawMatataInvalidatedPurchaseByReceiverEpic,
  withdrawMatataInvalidatedPurchaseByReceiverToastEpic,
  notWithdrawMatataInvalidatedPurchaseByReceiverEpic,
  notWithdrawMatataInvalidatedPurchaseByReceiverToastEpic
);
