import {
  AbTestCriteriaCreateRequestData,
  AbTestCriteriaCreateSuccessData,
  AbTestCriteriaUpdateRequestData,
  AbTestCriteriaUpdateSuccessData,
} from 'store/abTestCriteria/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createAbTestCriteriaApi = createPostApi<AbTestCriteriaCreateRequestData, AbTestCriteriaCreateSuccessData>(
  '/abtests/criteria'
);

export const updateAbTestCriteriaApi = createUpdateApi<
  AbTestCriteriaUpdateRequestData,
  AbTestCriteriaUpdateSuccessData
>('/abtests/criteria/:id');

export const deleteAbTestCriteriaApi = createDeleteApi('/abtests/criteria/:id');
