import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs from 'dayjs';

export interface DateTimeInputOption {
  isUnix?: boolean;
  isUTC?: boolean;
  format?: string;
}

export const FORMAT = {
  DATE: 'YYYY-MM-DD',
  TIME: 'YYYY-MM-DDTHH:mm:ss',
  UTC_TIME: 'YYYY-MM-DDTHH:mm:ss[Z]',
  LOCAL_TIME: 'YYYY-MM-DD HH:mm:ss',
  HOUR: 'H',
};

const utc = (time?: string) => dayjs.utc(time);

export const setUtcHour = (time: string) => {
  if (!time) return undefined;

  const utcNowHour = utc().hour();
  return utc(time).hour(utcNowHour).format(FORMAT.UTC_TIME);
};

export const parseUtc = (time?: string, format?: string) => {
  if (!time) return null;
  return utc(time).format(format);
};

const truncateMinutes = (time: dayjs.Dayjs) => {
  return time.startOf('minute');
};

export const getParsedTime = (time: MaterialUiPickersDate | string, { isUnix, isUTC, format }: DateTimeInputOption) => {
  if (!time) return null;

  let parsedTime = dayjs(time);
  parsedTime = truncateMinutes(parsedTime);

  if (isUTC) parsedTime = parsedTime.utc();
  if (isUnix) return parsedTime.valueOf();

  return parsedTime.format(format);
};

export const startOfDay = () => dayjs().startOf('day').toISOString();

export const endOfDay = () => dayjs().endOf('day').toISOString();

export const getNow = (format = 'YYYYMMDD_HHmmss') => dayjs().format(format);

export const formatIfValid = (date: string, format: string) => {
  const time = dayjs(date);

  return time.isValid() && time.format(format);
};
