import { initialListState, ListRequestPayload, ListState } from 'store/types';

export enum MatataDeviceUnbindingActions {
  LIST_MATATA_DEVICE_UNBINDING_REQUEST = 'LIST_MATATA_DEVICE_UNBINDING#REQUEST',
  LIST_MATATA_DEVICE_UNBINDING_SUCCESS = 'LIST_MATATA_DEVICE_UNBINDING#SUCCESS',
  LIST_MATATA_DEVICE_UNBINDING_FAILURE = 'LIST_MATATA_DEVICE_UNBINDING#FAILURE',

  READ_MATATA_DEVICE_UNBINDING_REQUEST = 'READ_MATATA_DEVICE_UNBINDING#REQUEST',
  READ_MATATA_DEVICE_UNBINDING_SUCCESS = 'READ_MATATA_DEVICE_UNBINDING#SUCCESS',
  READ_MATATA_DEVICE_UNBINDING_FAILURE = 'READ_MATATA_DEVICE_UNBINDING#FAILURE',

  DELETE_MATATA_DEVICE_UNBINDING_REQUEST = 'DELETE_MATATA_DEVICE_UNBINDING#REQUEST',
  DELETE_MATATA_DEVICE_UNBINDING_SUCCESS = 'DELETE_MATATA_DEVICE_UNBINDING#SUCCESS',
  DELETE_MATATA_DEVICE_UNBINDING_FAILURE = 'DELETE_MATATA_DEVICE_UNBINDING#FAILURE',
}

export interface MatataDeviceUnbindingState {
  list: ListState<MatataDeviceUnbindingItem>;
  detail?: MatataDeviceUnbindingItem;
}

export interface MatataDeviceUnbindingItem {
  readonly id?: number;
  deviceId: string;
  userId: number;
  deleted: boolean;
  matataId: string;
  deviceIdType: string;
  dateFirstLogin: string;
  dateLastLogin: string;
  nowSuspensionId: number;
  nowSuspensionLabel: string;
  nowSuspensionCategory: string;
  nowSuspensionScope: string;
  suspensionStartAt: string;
  suspensionEndAt: string;
  reportId: string;
}

export type MatataDeviceUnbindingRequestPayload = ListRequestPayload;
export type MatataDeviceUnbindingResponseData = ListState<MatataDeviceUnbindingItem>;

export const initialMatataDeviceUnbindingState: MatataDeviceUnbindingState = {
  list: initialListState,
  detail: undefined,
};
