export enum ItemProvideActions {
  PROVIDE_ITEM_PRODUCT_REQUEST = 'PROVIDE_ITEM_PRODUCT#REQUEST',
  PROVIDE_ITEM_PRODUCT_SUCCESS = 'PROVIDE_ITEM_PRODUCT#SUCCESS',
  PROVIDE_ITEM_PRODUCT_FAILURE = 'PROVIDE_ITEM_PRODUCT#FAILURE',
}

export interface ItemProductRequestFormValues {
  userId: number;
  productId: string;
  useMins: number;
  useCount: number;
}
