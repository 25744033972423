import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  TeamMessageImageSettingCreateRequestData,
  TeamMessageImageSettingCreateResponseData,
  TeamMessageImageSettingFormValues,
  TeamMessageImageSettingUpdateRequestData,
  TeamMessageImageSettingUpdateResponseData,
} from './types';

export const createTeamMessageImageSettingApi = createPostApi<
  TeamMessageImageSettingFormValues,
  TeamMessageImageSettingCreateRequestData,
  TeamMessageImageSettingCreateResponseData
>('/team_message/:id/image_setting');

export const updateTeamMessageImageSettingApi = createUpdateApi<
  TeamMessageImageSettingFormValues,
  TeamMessageImageSettingUpdateRequestData,
  TeamMessageImageSettingUpdateResponseData
>('/team_message/:id/image_setting');

export const deleteTeamMessageImageSettingApi = createDeleteApi('/team_message/:id/image_setting');
