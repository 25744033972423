import { combineEpics } from 'redux-observable';

import { createListEpic, createDeleteEpic } from 'store/utils';

import { listPunishment, deletePunishment } from './actions';

import { listPunishmentApi, deletePunishmentApi } from './api';

export const listPunishmentEpic = createListEpic(listPunishment, listPunishmentApi);
export const deletePunishmentEpic = createDeleteEpic(deletePunishment, deletePunishmentApi);

export default combineEpics(listPunishmentEpic, deletePunishmentEpic);
