import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import { VisitorConfigActions as Actions, VisitorConfig, VisitorConfigCreateRequestData } from './types';

export const createVisitorConfig = createAsyncAction(
  Actions.CREATE_VISITOR_CONFIG_REQUEST,
  Actions.CREATE_VISITOR_CONFIG_SUCCESS,
  Actions.CREATE_VISITOR_CONFIG_FAILURE
)<FormRequestPayload<VisitorConfigCreateRequestData>, VisitorConfigCreateRequestData, AxiosError>();

export const readVisitorConfig = createAsyncAction(
  Actions.READ_VISITOR_CONFIG_REQUEST,
  Actions.READ_VISITOR_CONFIG_SUCCESS,
  Actions.READ_VISITOR_CONFIG_FAILURE
)<ReadRequestPayload, VisitorConfig, AxiosError>();

export const updateVisitorConfig = createAsyncAction(
  Actions.UPDATE_VISITOR_CONFIG_REQUEST,
  Actions.UPDATE_VISITOR_CONFIG_SUCCESS,
  Actions.UPDATE_VISITOR_CONFIG_FAILURE
)<FormRequestPayload<VisitorConfigCreateRequestData>, VisitorConfigCreateRequestData, AxiosError>();

export const listVisitorConfig = createAsyncAction(
  Actions.LIST_VISITOR_CONFIG_REQUEST,
  Actions.LIST_VISITOR_CONFIG_SUCCESS,
  Actions.LIST_VISITOR_CONFIG_FAILURE
)<ListRequestPayload, ListState<VisitorConfig>, AxiosError>();

export const deleteVisitorConfig = createAsyncAction(
  Actions.DELETE_VISITOR_CONFIG_REQUEST,
  Actions.DELETE_VISITOR_CONFIG_SUCCESS,
  Actions.DELETE_VISITOR_CONFIG_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
