import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as teamMessageActions from './actions';
import { initialState, TeamMessageState } from './types';

const teamMessage = createReducer<TeamMessageState, RootAction>(initialState)
  .handleAction(teamMessageActions.listTeamMessage.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(teamMessageActions.listTeamMessage.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(teamMessageActions.readTeamMessage.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default teamMessage;
