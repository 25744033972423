import {
  ShopEntryBannerScheduleCreateRequestData,
  ShopEntryBannerScheduleCreateSuccessData,
  ShopEntryBannerScheduleUpdateRequestData,
  ShopEntryBannerScheduleUpdateSuccessData,
} from 'store/shopEntryBannerSchedule/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createShopEntryBannerScheduleApi = createPostApi<
  ShopEntryBannerScheduleCreateRequestData,
  ShopEntryBannerScheduleCreateSuccessData
>('/shop_entry_banners/schedules');

export const updateShopEntryBannerScheduleApi = createUpdateApi<
  ShopEntryBannerScheduleUpdateRequestData,
  ShopEntryBannerScheduleUpdateSuccessData
>('/shop_entry_banners/schedules/:id');

export const deleteShopEntryBannerScheduleApi = createDeleteApi('/shop_entry_banners/schedules/:id');
