import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import * as matchFilterSettingActions from 'store/matchFilterSetting/actions';
import { initialState, MatchFilterOption, MatchFilterSettingState } from 'store/matchFilterSetting/types';
import { RootAction } from 'store/root/types';

const DEFAULT_GEM_COST_VALUE = 0;

const matchFilterSetting = createReducer<MatchFilterSettingState, RootAction>(initialState)
  .handleAction(matchFilterSettingActions.listMatchFilterSetting.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(matchFilterSettingActions.listMatchFilterSetting.success, (state, { payload }) => {
    const parseOptionField = (option: MatchFilterOption) => {
      if (!option) return null;

      const { optionId, paid = false, useDefaultGemCost = false, gemCost } = option;
      const optionCost = paid ? gemCost : DEFAULT_GEM_COST_VALUE;

      return `${optionId} / ${useDefaultGemCost ? 'default' : optionCost}`;
    };

    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(({ options = [], ...values }) => ({
        ...values,
        option1: parseOptionField(options[0]),
        option2: parseOptionField(options[1]),
        option3: parseOptionField(options[2]),
      })),
    });

    return newState.toJS() as MatchFilterSettingState;
  })
  .handleAction(matchFilterSettingActions.readMatchFilterSetting.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));
export default matchFilterSetting;
