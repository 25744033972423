import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import { ConfigStoreDetail, ConfigStoreListItem, ConfigStoreRequestData, ConfigStoreResponseData } from './types';

const BASE_URL = '/configs';
export const createConfigStoreApi = createPostApi<ConfigStoreRequestData, ConfigStoreResponseData>(BASE_URL);

export const updateConfigStoreApi = createUpdateApi<
  ConfigStoreRequestData,
  ConfigStoreRequestData,
  ConfigStoreResponseData
>(`${BASE_URL}/categories/:id/:key`);
export const listConfigStoreApi = createListApi<ConfigStoreListItem>(BASE_URL);

export const readConfigStoreMapApi = createReadApi<ConfigStoreDetail>(`${BASE_URL}/categories/:id/:key`);

export const deleteConfigStoreApi = createDeleteApi(`${BASE_URL}/categories/:id/:key`);

export const refreshConfigStoreApi = createPostApi(`${BASE_URL}/refresh`);
