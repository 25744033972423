export enum VisitorAbTestCriteriaActions {
  CREATE_SCHEDULE_REQUEST = 'CREATE_VISITOR_AB_TEST_CRITERIA#REQUEST',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_VISITOR_AB_TEST_CRITERIA#SUCCESS',
  CREATE_SCHEDULE_FAILURE = 'CREATE_VISITOR_AB_TEST_CRITERIA#FAILURE',

  UPDATE_SCHEDULE_REQUEST = 'UPDATE_VISITOR_AB_TEST_CRITERIA#REQUEST',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_VISITOR_AB_TEST_CRITERIA#SUCCESS',
  UPDATE_SCHEDULE_FAILURE = 'UPDATE_VISITOR_AB_TEST_CRITERIA#FAILURE',

  DELETE_SCHEDULE_REQUEST = 'DELETE_VISITOR_AB_TEST_CRITERIA#REQUEST',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_VISITOR_AB_TEST_CRITERIA#SUCCESS',
  DELETE_SCHEDULE_FAILURE = 'DELETE_VISITOR_AB_TEST_CRITERIA#FAILURE',
}

export interface VisitorAbTestCriteria {
  id: number;
  testName: string;
  targetGroupName: string;
  hashModularValue: number;
  visitorSegmentCode: string;
}

export type VisitorAbTestCriteriaCreateRequestData = Omit<VisitorAbTestCriteria, 'id'>;
export type VisitorAbTestCriteriaCreateSuccessData = VisitorAbTestCriteria;
export type VisitorAbTestCriteriaUpdateRequestData = Omit<VisitorAbTestCriteria, 'id'>;
export type VisitorAbTestCriteriaUpdateSuccessData = VisitorAbTestCriteria;
