import { createDeleteApi, createDeleteBodyApi, createListApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  ItemProductCreateRequestData,
  ItemProductCreateResponseData,
  ItemProductFormValues,
  ItemProductListItem,
  ItemProductProvideCreateRequestData,
  ItemProductProvideCreateResponseData,
  ItemProductProvideFormValues,
  ItemProductProvideUpdateRequestData,
  ItemProductProvideUpdateResponseData,
  ItemProductUpdateRequestData,
  ItemProductUpdateResponseData,
  PeriodicItemProductCreateRequestData,
  PeriodicItemProductCreateResponseData,
  PeriodicItemProductFormValues,
  PeriodicItemProductUpdateRequestData,
  PeriodicItemProductUpdateResponseData,
} from './types';

export const listItemProductApi = createListApi<ItemProductListItem>('/items');

export const createItemProductApi = createPostApi<
  ItemProductFormValues,
  ItemProductCreateRequestData,
  ItemProductCreateResponseData
>('/items/:id');

export const updateItemProductApi = createUpdateApi<
  ItemProductFormValues,
  ItemProductUpdateRequestData,
  ItemProductUpdateResponseData
>('/items/:id');

export const deleteItemProductApi = createDeleteBodyApi('/items/:id');

// - ItemProductProvide -
export const createItemProductProvideApi = createPostApi<
  ItemProductProvideFormValues,
  ItemProductProvideCreateRequestData,
  ItemProductProvideCreateResponseData
>(`/items/:id/provides`);

export const updateItemProductProvideApi = createUpdateApi<
  ItemProductProvideFormValues,
  ItemProductProvideUpdateRequestData,
  ItemProductProvideUpdateResponseData
>(`/items/:id/provides/:provideId`);

export const deleteItemProductProvideApi = createDeleteApi(`/items/:id/provides/:provideId`);

// - PeriodicItemProduct -
export const createPeriodicItemProductApi = createPostApi<
  PeriodicItemProductFormValues,
  PeriodicItemProductCreateRequestData,
  PeriodicItemProductCreateResponseData
>(`/items/:id/periodic-items`);

export const updatePeriodicItemProductApi = createUpdateApi<
  PeriodicItemProductFormValues,
  PeriodicItemProductUpdateRequestData,
  PeriodicItemProductUpdateResponseData
>(`/items/:id/periodic-items/:periodicItemId`);

export const deletePeriodicItemProductApi = createDeleteApi(`/items/:id/periodic-items/:periodicItemId`);
