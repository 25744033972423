import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ProductIconActions as Actions, ProductIcon } from 'store/productIcons/types';
import { ListRequestPayload, ListState } from 'store/types';

export const listProductIcon = createAsyncAction(
  Actions.LIST_PRODUCT_ICON_REQUEST,
  Actions.LIST_PRODUCT_ICON_SUCCESS,
  Actions.LIST_PRODUCT_ICON_FAILURE
)<ListRequestPayload, ListState<ProductIcon>, AxiosError>();
