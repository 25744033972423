import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  WebDecoSetting,
  WebDecoSettingCreateRequestData,
  WebDecoSettingCreateResponseData,
  WebDecoSettingDetail,
  WebDecoSettingFormValues,
  WebDecoSettingUpdateRequestData,
  WebDecoSettingUpdateResponseData,
  WEB_DECO_SETTING_API_PREFIX,
} from './types';

export const listWebDecoSettingApi = createListApi<WebDecoSetting>(WEB_DECO_SETTING_API_PREFIX);

export const readWebDecoSettingApi = createReadApi<WebDecoSettingDetail>(`${WEB_DECO_SETTING_API_PREFIX}/:id`);

export const createWebDecoSettingApi = createPostApi<
  WebDecoSettingFormValues,
  WebDecoSettingCreateRequestData,
  WebDecoSettingCreateResponseData
>(WEB_DECO_SETTING_API_PREFIX);

export const updateWebDecoSettingApi = createUpdateApi<
  WebDecoSettingFormValues,
  WebDecoSettingUpdateRequestData,
  WebDecoSettingUpdateResponseData
>(`${WEB_DECO_SETTING_API_PREFIX}/:id`);

export const deleteWebDecoSettingApi = createDeleteApi(`${WEB_DECO_SETTING_API_PREFIX}/:id`);

export const refreshWebDecoSettingApi = createPostApi(`${WEB_DECO_SETTING_API_PREFIX}/refresh`);
