import { createPostApi } from 'store/utils';

import {
  PromotionScheduleTestCreateRequestData,
  PromotionScheduleTestCreateResponseData,
  PromotionScheduleTestFormValues,
} from './types';

export const createPromotionScheduleTestApi = createPostApi<
  PromotionScheduleTestFormValues,
  PromotionScheduleTestCreateRequestData,
  PromotionScheduleTestCreateResponseData
>('/scheduler/schedules/bulk');
