import { initialListState, ListState } from 'store/types';

export enum DownloadableContentActions {
  RESET_DOWNLOADABLE_CONTENT = 'RESET_DOWNLOADABLE_CONTENT',

  LIST_DOWNLOADABLE_CONTENT_REQUEST = 'LIST_DOWNLOADABLE_CONTENT#REQUEST',
  LIST_DOWNLOADABLE_CONTENT_SUCCESS = 'LIST_DOWNLOADABLE_CONTENT#SUCCESS',
  LIST_DOWNLOADABLE_CONTENT_FAILURE = 'LIST_DOWNLOADABLE_CONTENT#FAILURE',

  READ_DOWNLOADABLE_CONTENT_REQUEST = 'READ_DOWNLOADABLE_CONTENT#REQUEST',
  READ_DOWNLOADABLE_CONTENT_SUCCESS = 'READ_DOWNLOADABLE_CONTENT#SUCCESS',
  READ_DOWNLOADABLE_CONTENT_FAILURE = 'READ_DOWNLOADABLE_CONTENT#FAILURE',

  CREATE_DOWNLOADABLE_CONTENT_REQUEST = 'CREATE_DOWNLOADABLE_CONTENT#REQUEST',
  CREATE_DOWNLOADABLE_CONTENT_SUCCESS = 'CREATE_DOWNLOADABLE_CONTENT#SUCCESS',
  CREATE_DOWNLOADABLE_CONTENT_FAILURE = 'CREATE_DOWNLOADABLE_CONTENT#FAILURE',

  UPDATE_DOWNLOADABLE_CONTENT_REQUEST = 'UPDATE_DOWNLOADABLE_CONTENT#REQUEST',
  UPDATE_DOWNLOADABLE_CONTENT_SUCCESS = 'UPDATE_DOWNLOADABLE_CONTENT#SUCCESS',
  UPDATE_DOWNLOADABLE_CONTENT_FAILURE = 'UPDATE_DOWNLOADABLE_CONTENT#FAILURE',

  DELETE_DOWNLOADABLE_CONTENT_REQUEST = 'DELETE_DOWNLOADABLE_CONTENT#REQUEST',
  DELETE_DOWNLOADABLE_CONTENT_SUCCESS = 'DELETE_DOWNLOADABLE_CONTENT#SUCCESS',
  DELETE_DOWNLOADABLE_CONTENT_FAILURE = 'DELETE_DOWNLOADABLE_CONTENT#FAILURE',
}

export enum FileType {
  DECO_RESOURCE = 'DECO_RESOURCE',
  ML_MODEL = 'ML_MODEL',
}

export const initialState: DownloadableContentState = {
  list: initialListState,
  detail: undefined,
};

export interface DownloadableContentState {
  list: ListState<DownloadableContentData>;
  detail?: DownloadableContentData;
}

export interface DownloadableContentData {
  // 공통
  contentId: string;
  categoryId: string;
  enabled: boolean;
  minApiVersion?: number;
  countryCodes?: string;
  visitorSegmentCode?: string;
  // 생성 후에만 유효
  url: string;
  encryptionKey: string;
  // 생성 시에만 유효
  file: File;
  fileType: string;
}

// Deco Item component에서 사용하는 Type
export type DownloadableContent = Omit<
  DownloadableContentData,
  'file' | 'fileType' | 'minApiVersion' | 'countryCodes' | 'enabled'
>;
export type DownloadableContentCreateRequestData = any;
export type DownloadableContentCreateSuccessData = DownloadableContent;
export type DownloadableContentFormValues = Pick<DownloadableContent, 'contentId' | 'categoryId'> &
  Pick<Partial<DownloadableContent>, 'url' | 'encryptionKey'>;

// 이하 Downloadable content page에서 사용

// detail page
export type DownloadableContentUpdateForm = Omit<DownloadableContentData, 'encryptionKey' | 'url'>;
export type DownloadableContentUpdateResponse = DownloadableContent;

// create page
export type DownloadableContentCreateForm = Omit<DownloadableContentData, 'encryptionKey' | 'url'>;
export type DownloadableContentCreateSuccess = DownloadableContent;
