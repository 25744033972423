import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import * as bannerSettingMapEntryActions from '../bannerSettingMapEntry/actions';
import { RootAction } from '../root/types';

import * as bannerSettingActions from './actions';
import { BannerSettingState, initialState } from './types';

const bannerSetting = createReducer<BannerSettingState, RootAction>(initialState)
  .handleAction(bannerSettingActions.listBannerSetting.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(bannerSettingActions.listBannerSetting.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(bannerSettingActions.readBannerSetting.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(bannerSettingMapEntryActions.createBannerSettingMapEntry.success, (state, { payload }) => {
    if (state.detail?.id !== payload.bannerInfo) {
      return state;
    }
    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            gemShopBannerMapEntries: [...state.detail.gemShopBannerMapEntries, payload],
          }
        : undefined,
    };
  })
  .handleAction(bannerSettingMapEntryActions.updateBannerSettingMapEntry.success, (state, { payload }) => {
    if (state.detail?.id !== payload.bannerInfo) {
      return state;
    }
    const index = state.detail?.gemShopBannerMapEntries.findIndex((mapEntry) => mapEntry.id === payload.id);
    if (index !== null && index > -1) {
      let newState: Map<keyof typeof state, any> = fromJS(state);
      newState = newState.setIn(['detail', 'gemShopBannerMapEntries', index], payload);
      return newState.toJS() as BannerSettingState;
    }
    return state;
  })
  .handleAction(bannerSettingMapEntryActions.deleteBannerSettingMapEntry.success, (state, { payload }) => {
    const mapEntries = state.detail?.gemShopBannerMapEntries;
    if (mapEntries) {
      return {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              gemShopBannerMapEntries: mapEntries.filter((mapEntry) => `${mapEntry.id}` !== `${payload}`),
            }
          : undefined,
      };
    }
    return state;
  });

export default bannerSetting;
