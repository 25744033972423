import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic } from '../utils';

import { detailMoniNotice, listMoniNotice, postMoniNotice } from './actions';
import { detailMoniNoticeApi, listMoniNoticeApi, postMoniNoticeApi } from './api';

const MoniNoticeCreatedSuccessToast = createToastEpic(postMoniNotice.success, 'Successfully saved');

const listMoniNoticeEpic = createListEpic(listMoniNotice, listMoniNoticeApi);

const postMoniNoticeEpic = createPostEpic(postMoniNotice, postMoniNoticeApi);

const detailMoniNoticeEpic = createReadEpic(detailMoniNotice, detailMoniNoticeApi);

export default combineEpics(
  detailMoniNoticeEpic,
  MoniNoticeCreatedSuccessToast,
  listMoniNoticeEpic,
  postMoniNoticeEpic
);
