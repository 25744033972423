import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createWebDecoSettingEffect, deleteWebDecoSettingEffect, updateWebDecoSettingEffect } from './actions';
import { createWebDecoSettingEffectApi, deleteWebDecoSettingEffectApi, updateWebDecoSettingEffectApi } from './api';

const createWebDecoSettingEffectEpic = createPostEpic(createWebDecoSettingEffect, createWebDecoSettingEffectApi);

const createWebDecoSettingEffectToastEpic = createToastEpic(createWebDecoSettingEffect.success, 'Successfully created');

const updateWebDecoSettingEffectEpic = createUpdateEpic(updateWebDecoSettingEffect, updateWebDecoSettingEffectApi);

const updateWebDecoSettingEffectToastEpic = createToastEpic(updateWebDecoSettingEffect.success, 'Successfully updated');

const deleteWebDecoSettingEffectEpic = createDeleteEpic(deleteWebDecoSettingEffect, deleteWebDecoSettingEffectApi);

const deleteWebDecoSettingEffectToastEpic = createToastEpic(deleteWebDecoSettingEffect.success, 'Successfully deleted');

export default combineEpics(
  createWebDecoSettingEffectEpic,
  createWebDecoSettingEffectToastEpic,
  updateWebDecoSettingEffectEpic,
  updateWebDecoSettingEffectToastEpic,
  deleteWebDecoSettingEffectEpic,
  deleteWebDecoSettingEffectToastEpic
);
