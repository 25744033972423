import { createReducer } from 'typesafe-actions';

import { NotificationCenterBannerSchedule } from 'store/notificationCenterBannerSchedule/types';

import * as notificationCenterBannerScheduleActions from '../notificationCenterBannerSchedule/actions';
import { RootAction } from '../root/types';

import * as notificationCenterBannerActions from './actions';
import { initialState, NotificationCenterBannerState } from './types';

// noinspection TypeScriptValidateJSTypes
const notificationCenterBanner = createReducer<NotificationCenterBannerState, RootAction>(initialState)
  .handleAction(
    notificationCenterBannerActions.listNotificationCenterBanner.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(notificationCenterBannerActions.listNotificationCenterBanner.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(notificationCenterBannerActions.readNotificationCenterBanner.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(notificationCenterBannerActions.updateNotificationCenterBanner.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(
    notificationCenterBannerScheduleActions.createNotificationCenterBannerSchedule.success,
    (state, { payload }) => {
      const schedules = state?.detail?.notificationCenterBannerSchedules ?? [];
      const newSchedules = [...schedules, payload];

      return {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              notificationCenterBannerSchedules: newSchedules,
            }
          : undefined,
      };
    }
  )
  .handleAction(
    notificationCenterBannerScheduleActions.updateNotificationCenterBannerSchedule.success,
    (state, { payload }) => {
      const schedules = state?.detail?.notificationCenterBannerSchedules ?? [];
      const updatedScheduleIndex = schedules.findIndex(
        (schedule: NotificationCenterBannerSchedule) => schedule.id === payload.id
      );

      if (updatedScheduleIndex !== -1) {
        schedules[updatedScheduleIndex] = payload;
      }

      return {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              notificationCenterBannerSchedules: schedules,
            }
          : undefined,
      };
    }
  )
  .handleAction(
    notificationCenterBannerScheduleActions.deleteNotificationCenterBannerSchedule.success,
    (state, { payload }) => {
      const schedules = state?.detail?.notificationCenterBannerSchedules ?? [];
      const newSchedules = schedules.filter(
        (button: NotificationCenterBannerSchedule) => button.id !== Number(payload)
      );

      return {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              notificationCenterBannerSchedules: newSchedules,
            }
          : undefined,
      };
    }
  );

export default notificationCenterBanner;
