import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  TeamMessageActions as Actions,
  TeamMessageCreateRequestData,
  TeamMessageCreateResponseData,
  TeamMessageDetail,
  TeamMessageFormValues,
  TeamMessageListItem,
  TeamMessageUpdateRequestData,
  TeamMessageUpdateResponseData,
} from './types';

export const listTeamMessage = createAsyncAction(
  Actions.LIST_TEAM_MESSAGES_REQUEST,
  Actions.LIST_TEAM_MESSAGES_SUCCESS,
  Actions.LIST_TEAM_MESSAGES_FAILURE
)<ListRequestPayload, ListState<TeamMessageListItem>, AxiosError>();

export const readTeamMessage = createAsyncAction(
  Actions.READ_TEAM_MESSAGE_REQUEST,
  Actions.READ_TEAM_MESSAGE_SUCCESS,
  Actions.READ_TEAM_MESSAGE_FAILURE
)<ReadRequestPayload, TeamMessageDetail, AxiosError>();

export const createTeamMessage = createAsyncAction(
  Actions.CREATE_TEAM_MESSAGE_REQUEST,
  Actions.CREATE_TEAM_MESSAGE_SUCCESS,
  Actions.CREATE_TEAM_MESSAGE_FAILURE
)<FormRequestPayload<TeamMessageFormValues, TeamMessageCreateRequestData>, TeamMessageCreateResponseData, AxiosError>();

export const updateTeamMessage = createAsyncAction(
  Actions.UPDATE_TEAM_MESSAGE_REQUEST,
  Actions.UPDATE_TEAM_MESSAGE_SUCCESS,
  Actions.UPDATE_TEAM_MESSAGE_FAILURE
)<FormRequestPayload<TeamMessageFormValues, TeamMessageUpdateRequestData>, TeamMessageUpdateResponseData, AxiosError>();
