import { FlatListState } from 'store/types';

export enum MatchFilterActions {
  LIST_MATCH_FILTERS_REQUEST = 'LIST_MATCH_FILTERS#REQUEST',
  LIST_MATCH_FILTERS_SUCCESS = 'LIST_MATCH_FILTERS#SUCCESS',
  LIST_MATCH_FILTERS_FAILURE = 'LIST_MATCH_FILTERS#FAILURE',

  REFRESH_MATCH_FILTER_REQUEST = 'REFRESH_MATCH_FILTER#REQUEST',
  REFRESH_MATCH_FILTER_SUCCESS = 'REFRESH_MATCH_FILTER#SUCCESS',
  REFRESH_MATCH_FILTER_FAILURE = 'REFRESH_MATCH_FILTER#FAILURE',
}

export const initialState: MatchFilterState = { list: [] };

export interface MatchFilterState {
  list: FlatListState<MatchFilter>;
}

export interface MatchFilter {
  filterId: string;
  consumableCategoryId?: string;
  periodicCategoryId?: string;
  requiredCategoryId?: string;
  discountCategoryId?: string;
}
