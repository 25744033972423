import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as fileSettingActions from './actions';
import { FileSettingState, initialState } from './types';

const fileSetting = createReducer<FileSettingState, RootAction>(initialState)
  .handleAction(fileSettingActions.createFileSetting.success, (state, { payload }) => ({
    ...state,
    data: payload,
  }))
  .handleAction(fileSettingActions.resetFileSetting, (state) => ({
    ...state,
    data: undefined,
  }));

export default fileSetting;
