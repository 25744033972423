import { initialListState, ListState } from 'store/types';

export enum UserSegmentActions {
  LIST_USER_SEGMENTS_REQUEST = 'LIST_USER_SEGMENTS#REQUEST',
  LIST_USER_SEGMENTS_SUCCESS = 'LIST_USER_SEGMENTS#SUCCESS',
  LIST_USER_SEGMENTS_FAILURE = 'LIST_USER_SEGMENTS#FAILURE',

  LIST_USER_SEGMENTS_SEARCH_REQUEST = 'LIST_USER_SEGMENTS_SEARCH#REQUEST',
  LIST_USER_SEGMENTS_SEARCH_SUCCESS = 'LIST_USER_SEGMENTS_SEARCH#SUCCESS',
  LIST_USER_SEGMENTS_SEARCH_FAILURE = 'LIST_USER_SEGMENTS_SEARCH_#FAILURE',

  CREATE_USER_SEGMENT_REQUEST = 'CREATE_USER_SEGMENT#REQUEST',
  CREATE_USER_SEGMENT_SUCCESS = 'CREATE_USER_SEGMENT#SUCCESS',
  CREATE_USER_SEGMENT_FAILURE = 'CREATE_USER_SEGMENT#FAILURE',

  READ_USER_SEGMENT_REQUEST = 'READ_USER_SEGMENT#REQUEST',
  READ_USER_SEGMENT_SUCCESS = 'READ_USER_SEGMENT#SUCCESS',
  READ_USER_SEGMENT_FAILURE = 'READ_USER_SEGMENT#FAILURE',

  UPDATE_USER_SEGMENT_REQUEST = 'UPDATE_USER_SEGMENT#REQUEST',
  UPDATE_USER_SEGMENT_SUCCESS = 'UPDATE_USER_SEGMENT#SUCCESS',
  UPDATE_USER_SEGMENT_FAILURE = 'UPDATE_USER_SEGMENT#FAILURE',

  DELETE_USER_SEGMENT_REQUEST = 'DELETE_USER_SEGMENT#REQUEST',
  DELETE_USER_SEGMENT_SUCCESS = 'DELETE_USER_SEGMENT#SUCCESS',
  DELETE_USER_SEGMENT_FAILURE = 'DELETE_USER_SEGMENT#FAILURE',

  REFRESH_USER_SEGMENT_REQUEST = 'REFRESH_USER_SEGMENT#REQUEST',
  REFRESH_USER_SEGMENT_SUCCESS = 'REFRESH_USER_SEGMENT#SUCCESS',
  REFRESH_USER_SEGMENT_FAILURE = 'REFRESH_USER_SEGMENT#FAILURE',
}

export const initialState: UserSegmentState = {
  list: initialListState,
  searchList: initialListState,
  detail: undefined,
};

export enum SegmentType {
  CRITERIA = 'CRITERIA',
  MANUAL = 'MANUAL',
  CAPABILITY = 'CAPABILITY',
  EXPRESSION = 'EXPRESSION',
  REDIS = 'REDIS',
  BIGQUERY = 'BIGQUERY',
}

export type UserSegmentDetail = UserSegment;

export interface UserSegment {
  segmentCode: string;
  description?: string;
  enabled: boolean;
  segmentName: string;
  startDate?: string;
  endDate?: string;
  segmentType: SegmentType;
  link: string;
}

export interface NewUserSegment {
  segmentCode: string;
  segmentName: string;
  description?: string;
  enabled: boolean;
}

export interface UserSegmentState {
  list: ListState<UserSegment>;
  searchList: ListState<NewUserSegment>;
  detail?: UserSegmentDetail;
}

export type UserSegmentCreateRequestData = UserSegment;

export type UserSegmentCreateSuccessData = UserSegmentDetail;

export type UserSegmentUpdateRequestData = UserSegmentCreateRequestData;

export type UserSegmentUpdateSuccessData = UserSegmentDetail;
