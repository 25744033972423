import { combineEpics } from 'redux-observable';

import { createListEpic } from '../utils';

import { listRealtimeMonitoringAgentWorkloads, listRealtimeMonitoringManagerWorkloads } from './actions';
import { listMonitoringWorkloadsAgentApi, listMonitoringWorkloadsManagerApi } from './api';

const listRealtimeMonitoringManagerEpic = createListEpic(
  listRealtimeMonitoringManagerWorkloads,
  listMonitoringWorkloadsManagerApi
);

const listRealtimeMonitoringAgentEpic = createListEpic(
  listRealtimeMonitoringAgentWorkloads,
  listMonitoringWorkloadsAgentApi
);

export default combineEpics(listRealtimeMonitoringManagerEpic, listRealtimeMonitoringAgentEpic);
