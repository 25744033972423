import { BannerSettingMapEntry } from 'store/bannerSettingMapEntry/types';
import { initialListState, ListState } from 'store/types';

export enum BannerSettingActions {
  LIST_BANNER_SETTINGS_REQUEST = 'LIST_BANNER_SETTINGS#REQUEST',
  LIST_BANNER_SETTINGS_SUCCESS = 'LIST_BANNER_SETTINGS#SUCCESS',
  LIST_BANNER_SETTINGS_FAILURE = 'LIST_BANNER_SETTINGS#FAILURE',

  CREATE_BANNER_SETTING_REQUEST = 'CREATE_BANNER_SETTING#REQUEST',
  CREATE_BANNER_SETTING_SUCCESS = 'CREATE_BANNER_SETTING#SUCCESS',
  CREATE_BANNER_SETTING_FAILURE = 'CREATE_BANNER_SETTING#FAILURE',

  READ_BANNER_SETTING_REQUEST = 'READ_BANNER_SETTING#REQUEST',
  READ_BANNER_SETTING_SUCCESS = 'READ_BANNER_SETTING#SUCCESS',
  READ_BANNER_SETTING_FAILURE = 'READ_BANNER_SETTING#FAILURE',

  UPDATE_BANNER_SETTING_REQUEST = 'UPDATE_BANNER_SETTING#REQUEST',
  UPDATE_BANNER_SETTING_SUCCESS = 'UPDATE_BANNER_SETTING#SUCCESS',
  UPDATE_BANNER_SETTING_FAILURE = 'UPDATE_BANNER_SETTING#FAILURE',

  DELETE_BANNER_SETTING_REQUEST = 'DELETE_BANNER_SETTING#REQUEST',
  DELETE_BANNER_SETTING_SUCCESS = 'DELETE_BANNER_SETTING#SUCCESS',
  DELETE_BANNER_SETTING_FAILURE = 'DELETE_BANNER_SETTING#FAILURE',

  REFRESH_BANNER_SETTING_REQUEST = 'REFRESH_BANNER_SETTING#REQUEST',
  REFRESH_BANNER_SETTING_SUCCESS = 'REFRESH_BANNER_SETTING#SUCCESS',
  REFRESH_BANNER_SETTING_FAILURE = 'REFRESH_BANNER_SETTING#FAILURE',
}

export enum BannerPosition {
  SCREEN_TOP = 'SCREEN_TOP',
  BEFORE_DEFAULT_GEM_PRODUCTS = 'BEFORE_DEFAULT_GEM_PRODUCTS',
  AFTER_DEFAULT_GEM_PRODUCTS = 'AFTER_DEFAULT_GEM_PRODUCTS',
  BEFORE_SUBSCRIPTIONS = 'BEFORE_SUBSCRIPTIONS',
}

export const initialState: BannerSettingState = {
  list: initialListState,
  detail: undefined,
};

export interface BannerSettingListItem extends BannerSettingCreateRequestData {
  id: number;
  enabledCount: number;
  gemShopBannerMapEntries: BannerSettingMapEntry[];
}

export type BannerSettingDetail = BannerSettingListItem;

export interface BannerSettingState {
  list: ListState<BannerSettingListItem>;
  detail?: BannerSettingDetail;
}

export interface BannerSettingCreateRequestData {
  name: string;
  imageUrl: string;
  titleKey?: string;
  descriptionKey?: string;
  linkUrl?: string;
  buttonTextKey?: string;
  displayOrder: number;
  position: string;
  iconUrl: string;
}

export interface BannerSettingCreateRequestFormValues extends BannerSettingCreateRequestData {
  titleKeyAsString: boolean;
  descriptionKeyAsString: boolean;
  buttonTextKeyAsString: boolean;
}

export type BannerSettingCreateSuccessData = BannerSettingDetail;

export type BannerSettingUpdateRequestFormValues = BannerSettingCreateRequestFormValues;
export type BannerSettingUpdateRequestData = BannerSettingCreateRequestData;

export type BannerSettingUpdateSuccessData = BannerSettingDetail;
