import { combineEpics } from 'redux-observable';

import { createDeleteBodyEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createPromotionScheduleTarget, deletePromotionScheduleTarget, updatePromotionScheduleTarget } from './actions';
import {
  createPromotionScheduleTargetApi,
  deletePromotionScheduleTargetApi,
  updatePromotionScheduleTargetApi,
} from './api';

const createPromotionScheduleTargetEpic = createPostEpic(
  createPromotionScheduleTarget,
  createPromotionScheduleTargetApi
);

const createPromotionScheduleTargetToastEpic = createToastEpic(
  createPromotionScheduleTarget.success,
  'Successfully created'
);

const updatePromotionScheduleTargetEpic = createUpdateEpic(
  updatePromotionScheduleTarget,
  updatePromotionScheduleTargetApi
);

const updatePromotionScheduleTargetToastEpic = createToastEpic(
  updatePromotionScheduleTarget.success,
  'Successfully saved'
);

const deletePromotionScheduleTargetEpic = createDeleteBodyEpic(
  deletePromotionScheduleTarget,
  deletePromotionScheduleTargetApi
);

const deletePromotionScheduleTargetToastEpic = createToastEpic(
  deletePromotionScheduleTarget.success,
  'Successfully deleted'
);

export default combineEpics(
  createPromotionScheduleTargetEpic,
  createPromotionScheduleTargetToastEpic,
  updatePromotionScheduleTargetEpic,
  updatePromotionScheduleTargetToastEpic,
  deletePromotionScheduleTargetEpic,
  deletePromotionScheduleTargetToastEpic
);
