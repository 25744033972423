import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as promotionPublishHistoryActions from './actions';
import { initialState, PromotionPublishHistoryState } from './types';

const promotionPublishHistory = createReducer<PromotionPublishHistoryState, RootAction>(initialState)
  .handleAction(
    promotionPublishHistoryActions.listPromotionPublishHistory.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(promotionPublishHistoryActions.listPromotionPublishHistory.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(promotionPublishHistoryActions.readPromotionPublishHistory.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default promotionPublishHistory;
