import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  MlConfigModelCreateRequestData,
  MlConfigModelCreateRequestFormValues,
  MlConfigModelCreateSuccessData,
  MlConfigModelDetail,
  MlConfigModelListItem,
  MlConfigModelUpdateRequestData,
  MlConfigModelUpdateRequestFormValues,
  MlConfigModelUpdateSuccessData,
} from './types';

const BASE_PATH = '/ml-config/models';
export const listMlConfigModelApi = createListApi<MlConfigModelListItem>(BASE_PATH);
export const createMlConfigModelApi = createPostApi<
  MlConfigModelCreateRequestFormValues,
  MlConfigModelCreateRequestData,
  MlConfigModelCreateSuccessData
>(BASE_PATH);
export const readMlConfigModelApi = createReadApi<MlConfigModelDetail>(`${BASE_PATH}/:id`);
export const updateMlConfigModelApi = createUpdateApi<
  MlConfigModelUpdateRequestFormValues,
  MlConfigModelUpdateRequestData,
  MlConfigModelUpdateSuccessData
>(`${BASE_PATH}/:id`);
