import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  GemProductTierScheduleActions as Actions,
  GemProductTierScheduleCreateRequestData,
  GemProductTierScheduleCreateSuccessData,
  GemProductTierScheduleUpdateRequestData,
  GemProductTierScheduleUpdateSuccessData,
} from './types';

export const createGemProductTierSchedule = createAsyncAction(
  Actions.CREATE_SCHEDULE_REQUEST,
  Actions.CREATE_SCHEDULE_SUCCESS,
  Actions.CREATE_SCHEDULE_FAILURE
)<FormRequestPayload<GemProductTierScheduleCreateRequestData>, GemProductTierScheduleCreateSuccessData, AxiosError>();

export const updateGemProductTierSchedule = createAsyncAction(
  Actions.UPDATE_SCHEDULE_REQUEST,
  Actions.UPDATE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SCHEDULE_FAILURE
)<FormRequestPayload<GemProductTierScheduleUpdateRequestData>, GemProductTierScheduleUpdateSuccessData, AxiosError>();

export const deleteGemProductTierSchedule = createAsyncAction(
  Actions.DELETE_SCHEDULE_REQUEST,
  Actions.DELETE_SCHEDULE_SUCCESS,
  Actions.DELETE_SCHEDULE_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
