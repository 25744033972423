import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import { MessageBannerActions as Actions } from './types';

export const refreshMessageBanner = createAsyncAction(
  Actions.REFRESH_MESSAGE_BANNER_REQUEST,
  Actions.REFRESH_MESSAGE_BANNER_SUCCESS,
  Actions.REFRESH_MESSAGE_BANNER_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
