import {
  VisitorAbTestCriteriaCreateRequestData,
  VisitorAbTestCriteriaCreateSuccessData,
  VisitorAbTestCriteriaUpdateRequestData,
  VisitorAbTestCriteriaUpdateSuccessData,
} from 'store/visitorAbTestCriteria/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createVisitorAbTestCriteriaApi = createPostApi<
  VisitorAbTestCriteriaCreateRequestData,
  VisitorAbTestCriteriaCreateSuccessData
>('/visitors/abtests/criteria');

export const updateVisitorAbTestCriteriaApi = createUpdateApi<
  VisitorAbTestCriteriaUpdateRequestData,
  VisitorAbTestCriteriaUpdateSuccessData
>('/visitors/abtests/criteria/:id');

export const deleteVisitorAbTestCriteriaApi = createDeleteApi('/visitors/abtests/criteria/:id');
