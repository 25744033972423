import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  DeleteSuccessPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  CriteriaSegmentActions as Actions,
  CriteriaSegmentResponseData,
  CriteriaSegmentListItem,
  CriteriaSegmentUpdateRequestData,
} from './types';

export const listCriteriaSegment = createAsyncAction(
  Actions.LIST_CRITERIA_SEGMENTS_REQUEST,
  Actions.LIST_CRITERIA_SEGMENTS_SUCCESS,
  Actions.LIST_CRITERIA_SEGMENTS_FAILURE
)<ListRequestPayload, ListState<CriteriaSegmentListItem>, AxiosError>();

export const readCriteriaSegment = createAsyncAction(
  Actions.READ_CRITERIA_SEGMENT_REQUEST,
  Actions.READ_CRITERIA_SEGMENT_SUCCESS,
  Actions.READ_CRITERIA_SEGMENT_FAILURE
)<ReadRequestPayload, CriteriaSegmentResponseData, AxiosError>();

export const updateCriteriaSegment = createAsyncAction(
  Actions.UPDATE_CRITERIA_SEGMENT_REQUEST,
  Actions.UPDATE_CRITERIA_SEGMENT_SUCCESS,
  Actions.UPDATE_CRITERIA_SEGMENT_FAILURE
)<FormRequestPayload<CriteriaSegmentUpdateRequestData>, CriteriaSegmentResponseData, AxiosError>();

export const deleteCriteriaSegment = createAsyncAction(
  Actions.DELETE_CRITERIA_SEGMENT_REQUEST,
  Actions.DELETE_CRITERIA_SEGMENT_SUCCESS,
  Actions.DELETE_CRITERIA_SEGMENT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
