import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  PromotionPublishActions as Actions,
  PromotionPublishCouponFormValues,
  PromotionPublishCouponRequestData,
  PromotionPublishGroupCouponFormValues,
  PromotionPublishGroupCouponRequestData,
  PromotionPublishGroupSegmentCouponFormValues,
  PromotionPublishGroupSegmentCouponRequestData,
  PromotionPublishReusableCouponFormValues,
  PromotionPublishReusableCouponRequestData,
} from './types';

export const publishReusableCoupon = createAsyncAction(
  Actions.CREATE_REUSABLE_COUPON_REQUEST,
  Actions.CREATE_REUSABLE_COUPON_SUCCESS,
  Actions.CREATE_REUSABLE_COUPON_FAILURE
)<
  FormRequestPayload<PromotionPublishReusableCouponFormValues, PromotionPublishReusableCouponRequestData>,
  undefined,
  AxiosError
>();

export const publishGroupCoupon = createAsyncAction(
  Actions.CREATE_GROUP_COUPON_REQUEST,
  Actions.CREATE_GROUP_COUPON_SUCCESS,
  Actions.CREATE_GROUP_COUPON_FAILURE
)<
  FormRequestPayload<PromotionPublishGroupCouponFormValues, PromotionPublishGroupCouponRequestData>,
  undefined,
  AxiosError
>();

export const publishGroupSegmentCoupon = createAsyncAction(
  Actions.CREATE_GROUP_SEGMENT_COUPON_REQUEST,
  Actions.CREATE_GROUP_SEGMENT_COUPON_SUCCESS,
  Actions.CREATE_GROUP_SEGMENT_COUPON_FAILURE
)<
  FormRequestPayload<PromotionPublishGroupSegmentCouponFormValues, PromotionPublishGroupSegmentCouponRequestData>,
  undefined,
  AxiosError
>();

export const publishCoupon = createAsyncAction(
  Actions.CREATE_COUPON_REQUEST,
  Actions.CREATE_COUPON_SUCCESS,
  Actions.CREATE_COUPON_FAILURE
)<FormRequestPayload<PromotionPublishCouponFormValues, PromotionPublishCouponRequestData>, undefined, AxiosError>();
