import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createFileS3Upload,
  deleteFileS3Upload,
  listFileS3Upload,
  readFileS3Upload,
  updateFileS3Upload,
  uploadMultiFile,
  uploadSingleFile,
} from './actions';
import {
  createFileS3UploadApi,
  deleteFileS3UploadApi,
  listFileS3UploadApi,
  readFileS3UploadApi,
  updateFileS3UploadApi,
  uploadMultiFileApi,
  uploadSingleFileApi,
} from './api';

const listFileS3UploadEpic = createListEpic(listFileS3Upload, listFileS3UploadApi);

const readFileS3UploadEpic = createReadEpic(readFileS3Upload, readFileS3UploadApi);

const createFileS3UploadEpic = createPostEpic(createFileS3Upload, createFileS3UploadApi);

const createFileS3UploadToastEpic = createToastEpic(createFileS3Upload.success, 'Successfully created');

const updateFileS3UploadEpic = createUpdateEpic(updateFileS3Upload, updateFileS3UploadApi);

const updateFileS3UploadToastEpic = createToastEpic(updateFileS3Upload.success, 'Successfully saved');

const deleteFileS3UploadEpic = createDeleteEpic(deleteFileS3Upload, deleteFileS3UploadApi);

const deleteFileS3UploadToastEpic = createToastEpic(deleteFileS3Upload.success, 'Successfully deleted');

const uploadSingleFileEpic = createPostEpic(uploadSingleFile, uploadSingleFileApi);

const uploadSingleFileToastEpic = createToastEpic(uploadSingleFile.success, 'Successfully uploaded');

const uploadMultiFileEpic = createPostEpic(uploadMultiFile, uploadMultiFileApi);

const uploadMultiFileToastEpic = createToastEpic(uploadMultiFile.success, 'Successfully uploaded');

export default combineEpics(
  listFileS3UploadEpic,
  readFileS3UploadEpic,
  createFileS3UploadEpic,
  createFileS3UploadToastEpic,
  updateFileS3UploadEpic,
  updateFileS3UploadToastEpic,
  deleteFileS3UploadEpic,
  deleteFileS3UploadToastEpic,
  uploadSingleFileEpic,
  uploadSingleFileToastEpic,
  uploadMultiFileEpic,
  uploadMultiFileToastEpic
);
