export enum NotificationCenterBannerScheduleActions {
  CREATE_SCHEDULE_REQUEST = 'CREATE_NOTIFICATION_CENTER_BANNER_SCHEDULE#REQUEST',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_NOTIFICATION_CENTER_BANNER_SCHEDULE#SUCCESS',
  CREATE_SCHEDULE_FAILURE = 'CREATE_NOTIFICATION_CENTER_BANNER_SCHEDULE#FAILURE',

  UPDATE_SCHEDULE_REQUEST = 'UPDATE_NOTIFICATION_CENTER_BANNER_SCHEDULE#REQUEST',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_NOTIFICATION_CENTER_BANNER_SCHEDULE#SUCCESS',
  UPDATE_SCHEDULE_FAILURE = 'UPDATE_NOTIFICATION_CENTER_BANNER_SCHEDULE#FAILURE',

  DELETE_SCHEDULE_REQUEST = 'DELETE_NOTIFICATION_CENTER_BANNER_SCHEDULE#REQUEST',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_NOTIFICATION_CENTER_BANNER_SCHEDULE#SUCCESS',
  DELETE_SCHEDULE_FAILURE = 'DELETE_NOTIFICATION_CENTER_BANNER_SCHEDULE#FAILURE',
}

export interface NotificationCenterBannerSchedule {
  id: number;
  segmentCode: string;
  bannerId: number;
  startDateTime: string;
  endDateTime: string;
  activated: boolean;
}

export type NotificationCenterBannerScheduleCreateRequestData = Omit<
  NotificationCenterBannerSchedule,
  'id' | 'activated'
>;
export type NotificationCenterBannerScheduleCreateSuccessData = NotificationCenterBannerSchedule;
export type NotificationCenterBannerScheduleUpdateRequestData = Omit<
  NotificationCenterBannerSchedule,
  'id' | 'activated'
>;
export type NotificationCenterBannerScheduleUpdateSuccessData = NotificationCenterBannerSchedule;
