export enum FriendshipActions {
  CREATE_FRIENDSHIP_REQUEST = 'CREATE_FRIENDSHIP#REQUEST',
  CREATE_FRIENDSHIP_SUCCESS = 'CREATE_FRIENDSHIP#SUCCESS',
  CREATE_FRIENDSHIP_FAILURE = 'CREATE_FRIENDSHIP#FAILURE',
}

export interface FriendshipCreateRequestData {
  userId: string;
  peerId: string;
}
