import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  DecoSetting,
  DecoSettingCreateRequestData,
  DecoSettingCreateResponseData,
  DecoSettingDetail,
  DecoSettingFormValues,
  DecoSettingUpdateRequestData,
  DecoSettingUpdateResponseData,
} from './types';

export const listDecoSettingApi = createListApi<DecoSetting>('/deco_settings');

export const readDecoSettingApi = createReadApi<DecoSettingDetail>('/deco_settings/:id');

export const createDecoSettingApi = createPostApi<
  DecoSettingFormValues,
  DecoSettingCreateRequestData,
  DecoSettingCreateResponseData
>('/deco_settings');

export const updateDecoSettingApi = createUpdateApi<
  DecoSettingFormValues,
  DecoSettingUpdateRequestData,
  DecoSettingUpdateResponseData
>('/deco_settings/:id');

export const deleteDecoSettingApi = createDeleteApi('/deco_settings/:id');

export const refreshDecoSettingApi = createPostApi('/deco_settings/refresh');
