import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import {
  NotificationContentActions as Actions,
  NotificationContent,
  NotificationContentCreateRequestData,
} from './types';

export const createNotificationContent = createAsyncAction(
  Actions.CREATE_NOTIFICATION_CONTENT_REQUEST,
  Actions.CREATE_NOTIFICATION_CONTENT_SUCCESS,
  Actions.CREATE_NOTIFICATION_CONTENT_FAILURE
)<FormRequestPayload<NotificationContentCreateRequestData>, NotificationContentCreateRequestData, AxiosError>();

export const readNotificationContent = createAsyncAction(
  Actions.READ_NOTIFICATION_CONTENT_REQUEST,
  Actions.READ_NOTIFICATION_CONTENT_SUCCESS,
  Actions.READ_NOTIFICATION_CONTENT_FAILURE
)<ReadRequestPayload, NotificationContent, AxiosError>();

export const updateNotificationContent = createAsyncAction(
  Actions.UPDATE_NOTIFICATION_CONTENT_REQUEST,
  Actions.UPDATE_NOTIFICATION_CONTENT_SUCCESS,
  Actions.UPDATE_NOTIFICATION_CONTENT_FAILURE
)<FormRequestPayload<NotificationContentCreateRequestData>, NotificationContentCreateRequestData, AxiosError>();

export const listNotificationContent = createAsyncAction(
  Actions.LIST_NOTIFICATION_CONTENT_REQUEST,
  Actions.LIST_NOTIFICATION_CONTENT_SUCCESS,
  Actions.LIST_NOTIFICATION_CONTENT_FAILURE
)<ListRequestPayload, ListState<NotificationContent>, AxiosError>();

export const refreshNotificationContent = createAsyncAction(
  Actions.REFRESH_NOTIFICATION_CONTENT_REQUEST,
  Actions.REFRESH_NOTIFICATION_CONTENT_SUCCESS,
  Actions.REFRESH_NOTIFICATION_CONTENT_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
