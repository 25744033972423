import { createReducer } from 'typesafe-actions';

import { NoticePopupButton } from 'store/noticePopupButton/types';
import { NoticePopupCarousel } from 'store/noticePopupCarousel/types';
import { NoticePopupSchedule } from 'store/noticePopupSchedule/types';

import * as noticePopupButtonActions from '../noticePopupButton/actions';
import * as noticePopupCarouselActions from '../noticePopupCarousel/actions';
import * as noticePopupScheduleActions from '../noticePopupSchedule/actions';
import { RootAction } from '../root/types';

import * as noticeActions from './actions';
import { initialState, NoticeState } from './types';

// noinspection TypeScriptValidateJSTypes
const notice = createReducer<NoticeState, RootAction>(initialState)
  .handleAction(noticeActions.listNotice.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(noticeActions.listNotice.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(noticeActions.readNotice.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(noticeActions.updateNotice.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(noticePopupCarouselActions.createNoticePopupCarousel.success, (state, { payload }) => {
    const carousels = state?.detail?.noticePopupContents ?? [];
    const newCarousels = [...carousels, payload];
    newCarousels.sort((a, b) => a.displayOrder - b.displayOrder);

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupContents: newCarousels,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupCarouselActions.updateNoticePopupCarousel.success, (state, { payload }) => {
    const carousels = state?.detail?.noticePopupContents ?? [];
    const updatedCarouselIndex = carousels.findIndex((carousel) => carousel.id === payload.id);

    if (updatedCarouselIndex !== -1) {
      carousels[updatedCarouselIndex] = payload;
      carousels.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupContents: carousels,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupCarouselActions.deleteNoticePopupCarousel.success, (state, { payload }) => {
    const carousels = state?.detail?.noticePopupContents ?? [];
    const newCarousels = carousels.filter((carousel: NoticePopupCarousel) => carousel.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupContents: newCarousels,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupButtonActions.createNoticePopupButton.success, (state, { payload }) => {
    const buttons = state?.detail?.noticePopupButtons ?? [];
    const newButtons = [...buttons, payload];
    newButtons.sort((a, b) => a.displayOrder - b.displayOrder);

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupButtons: newButtons,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupButtonActions.updateNoticePopupButton.success, (state, { payload }) => {
    const buttons = state?.detail?.noticePopupButtons ?? [];
    const updatedButtonIndex = buttons.findIndex((button: NoticePopupButton) => button.id === payload.id);

    if (updatedButtonIndex !== -1) {
      buttons[updatedButtonIndex] = payload;
      buttons.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupButtons: buttons,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupButtonActions.deleteNoticePopupButton.success, (state, { payload }) => {
    const buttons = state?.detail?.noticePopupButtons ?? [];
    const newButtons = buttons.filter((button: NoticePopupButton) => button.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupButtons: newButtons,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupScheduleActions.createNoticePopupSchedule.success, (state, { payload }) => {
    const schedules = state?.detail?.noticePopupSchedules ?? [];
    const newSchedules = [...schedules, payload];

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupSchedules: newSchedules,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupScheduleActions.updateNoticePopupSchedule.success, (state, { payload }) => {
    const schedules = state?.detail?.noticePopupSchedules ?? [];
    const updatedScheduleIndex = schedules.findIndex((schedule: NoticePopupSchedule) => schedule.id === payload.id);

    if (updatedScheduleIndex !== -1) {
      schedules[updatedScheduleIndex] = payload;
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupSchedules: schedules,
          }
        : undefined,
    };
  })
  .handleAction(noticePopupScheduleActions.deleteNoticePopupSchedule.success, (state, { payload }) => {
    const schedules = state?.detail?.noticePopupSchedules ?? [];
    const newSchedules = schedules.filter((button: NoticePopupSchedule) => button.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            noticePopupSchedules: newSchedules,
          }
        : undefined,
    };
  })
  .handleAction(noticeActions.bulkDeleteNoticeButtons.success, (state) => ({
    ...state,
    detail: state.detail
      ? {
          ...state.detail,
          noticePopupButtons: [],
        }
      : undefined,
  }))
  .handleAction(noticeActions.bulkDeleteNoticeCarousels.success, (state) => ({
    ...state,
    detail: state.detail
      ? {
          ...state.detail,
          noticePopupContents: [],
        }
      : undefined,
  }))
  .handleAction(noticeActions.bulkInsertNoticeButtons.success, (state, { payload }) => ({
    ...state,
    detail: state.detail
      ? {
          ...state.detail,
          noticePopupButtons: payload,
        }
      : undefined,
  }))
  .handleAction(noticeActions.bulkInsertNoticeCarousels.success, (state, { payload }) => ({
    ...state,
    detail: state.detail
      ? {
          ...state.detail,
          noticePopupContents: payload,
        }
      : undefined,
  }));

export default notice;
