import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from '../types';

import { CancelGemPurchaseActions as Actions, CancelGemPurchaseRequestFormValues } from './types';

export const cancelGemPurchase = createAsyncAction(
  Actions.CANCEL_GEM_PURCHASE_REQUEST,
  Actions.CANCEL_GEM_PURCHASE_SUCCESS,
  Actions.CANCEL_GEM_PURCHASE_FAILURE
)<FormRequestPayload<CancelGemPurchaseRequestFormValues>, undefined, AxiosError>();
