export enum NoticePopupScheduleActions {
  CREATE_SCHEDULE_REQUEST = 'CREATE_NOTICE_POPUP_SCHEDULE#REQUEST',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_NOTICE_POPUP_SCHEDULE#SUCCESS',
  CREATE_SCHEDULE_FAILURE = 'CREATE_NOTICE_POPUP_SCHEDULE#FAILURE',

  UPDATE_SCHEDULE_REQUEST = 'UPDATE_NOTICE_POPUP_SCHEDULE#REQUEST',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_NOTICE_POPUP_SCHEDULE#SUCCESS',
  UPDATE_SCHEDULE_FAILURE = 'UPDATE_NOTICE_POPUP_SCHEDULE#FAILURE',

  DELETE_SCHEDULE_REQUEST = 'DELETE_NOTICE_POPUP_SCHEDULE#REQUEST',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_NOTICE_POPUP_SCHEDULE#SUCCESS',
  DELETE_SCHEDULE_FAILURE = 'DELETE_NOTICE_POPUP_SCHEDULE#FAILURE',
}

export interface NoticePopupSchedule {
  id: number;
  segmentCode: string;
  settingId: number;
  startDate: string;
  endDate: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder: number;
  activated: boolean;
}

export type NoticePopupScheduleCreateRequestData = Omit<NoticePopupSchedule, 'id' | 'activated'>;
export type NoticePopupScheduleCreateSuccessData = NoticePopupSchedule;
export type NoticePopupScheduleUpdateRequestData = Omit<NoticePopupSchedule, 'id' | 'activated'>;
export type NoticePopupScheduleUpdateSuccessData = NoticePopupSchedule;
