import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as matataInvalidatedPurchaseActions from './actions';
import { initialState, MatataInvalidatedPurchaseState } from './types';

const matataInvalidatedPurchase = createReducer<MatataInvalidatedPurchaseState, RootAction>(initialState)
  .handleAction(matataInvalidatedPurchaseActions.readMatataInvalidatedPurchase.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(
    matataInvalidatedPurchaseActions.listMatataInvalidatedPurchase.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(matataInvalidatedPurchaseActions.listMatataInvalidatedPurchase.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default matataInvalidatedPurchase;
