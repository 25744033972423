import { AbTestCriteria } from 'store/abTestCriteria/types';
import { Gender } from 'store/genderManage/types';
import { initialListState, ListState } from 'store/types';

export enum AbTestActions {
  CREATE_AB_TEST_REQUEST = 'CREATE_AB_TEST#REQUEST',
  CREATE_AB_TEST_SUCCESS = 'CREATE_AB_TEST#SUCCESS',
  CREATE_AB_TEST_FAILURE = 'CREATE_AB_TEST#FAILURE',

  UPDATE_AB_TEST_REQUEST = 'UPDATE_AB_TEST#REQUEST',
  UPDATE_AB_TEST_SUCCESS = 'UPDATE_AB_TEST#SUCCESS',
  UPDATE_AB_TEST_FAILURE = 'UPDATE_AB_TEST#FAILURE',

  LIST_AB_TESTS_REQUEST = 'LIST_AB_TESTS#REQUEST',
  LIST_AB_TESTS_SUCCESS = 'LIST_AB_TESTS#SUCCESS',
  LIST_AB_TESTS_FAILURE = 'LIST_AB_TESTS#FAILURE',

  READ_AB_TEST_REQUEST = 'READ_AB_TEST#REQUEST',
  READ_AB_TEST_SUCCESS = 'READ_AB_TEST#SUCCESS',
  READ_AB_TEST_FAILURE = 'READ_AB_TEST#FAILURE',
}

export const initialState: AbTestState = {
  list: initialListState,
  detail: undefined,
};

export interface AbTestState {
  list: ListState<AbTestListItem>;
  detail?: AbTestDetail;
}

export enum Platform {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export interface AbTest {
  testName: string;
  forNewUser: boolean;
  dateStart: string;
  dateEnd: string;
  minApiVersion?: string;
  countryCodes?: string;
  platform?: Platform;
  gender?: Gender;
  gaCustomDimensionKey?: string;
  hashModularBasis: number;
  segmentCode?: string;
}

export interface AbTestListItem extends AbTest {}

export interface AbTestDetail extends AbTest {
  criteria: AbTestCriteria[];
}

export interface AbTestFormValues {
  testName: string;
  forNewUser: boolean;
  dateStart: string;
  dateEnd: string;
  minApiVersion?: string;
  countryCodes?: boolean;
  platform?: string;
  gender?: string;
  gaCustomDimensionKey?: string;
  hashModularBasis: number;
  segmentCode?: string;
}

export type AbTestRequestData = AbTest;

export type AbTestResponseData = AbTest;
