import { BigquerySegmentDetail, SegmentSchedule } from 'store/bigquerySegment/types';

export enum BigquerySegmentDeclarationActions {
  CREATE_BIGQUERY_SEGMENT_DECLARATION_REQUEST = 'CREATE_BIGQUERY_SEGMENT_DECLARATION#REQUEST',
  CREATE_BIGQUERY_SEGMENT_DECLARATION_SUCCESS = 'CREATE_BIGQUERY_SEGMENT_DECLARATION#SUCCESS',
  CREATE_BIGQUERY_SEGMENT_DECLARATION_FAILURE = 'CREATE_BIGQUERY_SEGMENT_DECLARATION#FAILURE',

  UPDATE_BIGQUERY_SEGMENT_DECLARATION_REQUEST = 'UPDATE_BIGQUERY_SEGMENT_DECLARATION#REQUEST',
  UPDATE_BIGQUERY_SEGMENT_DECLARATION_SUCCESS = 'UPDATE_BIGQUERY_SEGMENT_DECLARATION#SUCCESS',
  UPDATE_BIGQUERY_SEGMENT_DECLARATION_FAILURE = 'UPDATE_BIGQUERY_SEGMENT_DECLARATION#FAILURE',

  ACCEPT_BIGQUERY_SEGMENT_DECLARATION_REQUEST = 'ACCEPT_BIGQUERY_SEGMENT_DECLARATION#REQUEST',
  ACCEPT_BIGQUERY_SEGMENT_DECLARATION_SUCCESS = 'ACCEPT_BIGQUERY_SEGMENT_DECLARATION#SUCCESS',
  ACCEPT_BIGQUERY_SEGMENT_DECLARATION_FAILURE = 'ACCEPT_BIGQUERY_SEGMENT_DECLARATION#FAILURE',

  REJECT_BIGQUERY_SEGMENT_DECLARATION_REQUEST = 'REJECT_BIGQUERY_SEGMENT_DECLARATION#REQUEST',
  REJECT_BIGQUERY_SEGMENT_DECLARATION_SUCCESS = 'REJECT_BIGQUERY_SEGMENT_DECLARATION#SUCCESS',
  REJECT_BIGQUERY_SEGMENT_DECLARATION_FAILURE = 'REJECT_BIGQUERY_SEGMENT_DECLARATION#FAILURE',

  MANUAL_TRIGGER_BIGQUERY_SEGMENT_REQUEST = 'MANUAL_TRIGGER_BIGQUERY_SEGMENT#REQUEST',
  MANUAL_TRIGGER_BIGQUERY_SEGMENT_SUCCESS = 'MANUAL_TRIGGER_BIGQUERY_SEGMENT#SUCCESS',
  MANUAL_TRIGGER_BIGQUERY_SEGMENT_FAILURE = 'MANUAL_TRIGGER_BIGQUERY_SEGMENT#FAILURE',
}

export interface SegmentDeclaration {
  query: string;
  hasReviewPermission: boolean;
  totalBytesProcessed?: bigint;
  reviewState: ReviewState;
  reviewer?: string;
}

export enum ReviewState {
  WAITING = 'WAITING',
  DRY_RUN_PASSED = 'DRY_RUN_PASSED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export type SegmentDeclarationCreateRequestData = SegmentDeclarationFormValues;

export interface SegmentDeclarationFormValues {
  segmentCode?: string;
  declaration?: SegmentDeclaration;
  schedule?: SegmentSchedule;
}
export type SegmentDeclarationCreateSuccessData = BigquerySegmentDetail;

export interface SegmentDeclarationUpdateRequestData {
  reviewState?: string;
  enabled?: boolean;
}

export type SegmentDeclarationUpdateSuccessData = SegmentDeclaration;
