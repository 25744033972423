export enum DecoSettingScheduleActions {
  CREATE_DECO_SETTING_SCHEDULE_REQUEST = 'CREATE_DECO_SETTING_SCHEDULE#REQUEST',
  CREATE_DECO_SETTING_SCHEDULE_SUCCESS = 'CREATE_DECO_SETTING_SCHEDULE#SUCCESS',
  CREATE_DECO_SETTING_SCHEDULE_FAILURE = 'CREATE_DECO_SETTING_SCHEDULE#FAILURE',

  UPDATE_DECO_SETTING_SCHEDULE_REQUEST = 'UPDATE_DECO_SETTING_SCHEDULE#REQUEST',
  UPDATE_DECO_SETTING_SCHEDULE_SUCCESS = 'UPDATE_DECO_SETTING_SCHEDULE#SUCCESS',
  UPDATE_DECO_SETTING_SCHEDULE_FAILURE = 'UPDATE_DECO_SETTING_SCHEDULE#FAILURE',

  DELETE_DECO_SETTING_SCHEDULE_REQUEST = 'DELETE_DECO_SETTING_SCHEDULE#REQUEST',
  DELETE_DECO_SETTING_SCHEDULE_SUCCESS = 'DELETE_DECO_SETTING_SCHEDULE#SUCCESS',
  DELETE_DECO_SETTING_SCHEDULE_FAILURE = 'DELETE_DECO_SETTING_SCHEDULE#FAILURE',
}

export interface DecoSettingSchedule {
  id: number;
  settingId?: number;
  segmentCode: string;
  startDate?: string;
  endDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder: number;
  enabled: boolean;
}

export type DecoSettingScheduleFormValues = Omit<DecoSettingSchedule, 'id' | 'enabled'>;

export type DecoSettingScheduleCreateRequestData = DecoSettingScheduleFormValues;

export type DecoSettingScheduleCreateResponseData = DecoSettingSchedule;

export type DecoSettingScheduleUpdateRequestData = DecoSettingScheduleFormValues;

export type DecoSettingScheduleUpdateResponseData = DecoSettingSchedule;
