import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic, createPostEpic, createToastEpic, createDeleteEpic } from 'store/utils';

import {
  listDownloadableContent,
  readDownloadableContent,
  createDownloadableContent,
  updateDownloadableContent,
  deleteDownloadableContent,
} from './actions';
import {
  listDownloadableContentApi,
  readDownloadableContentApi,
  createDownloadableContentApi,
  updateDownloadableContentApi,
  deleteDownloadableContentApi,
} from './api';

const listDownloadableContentEpic = createListEpic(listDownloadableContent, listDownloadableContentApi);

const readDownloadableContentEpic = createReadEpic(readDownloadableContent, readDownloadableContentApi);

const createDownloadableContentEpic = createPostEpic(createDownloadableContent, createDownloadableContentApi);

const createDownloadableContentToastEpic = createToastEpic(createDownloadableContent.success, 'Successfully uploaded');

const updateDownloadableContentEpic = createPostEpic(updateDownloadableContent, updateDownloadableContentApi);

const updateDownloadableContentToastEpic = createToastEpic(updateDownloadableContent.success, 'Successfully edited');

const deleteDownloadableContentEpic = createDeleteEpic(deleteDownloadableContent, deleteDownloadableContentApi);

export default combineEpics(
  listDownloadableContentEpic,
  readDownloadableContentEpic,
  createDownloadableContentEpic,
  createDownloadableContentToastEpic,
  updateDownloadableContentEpic,
  updateDownloadableContentToastEpic,
  deleteDownloadableContentEpic
);
