import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as purposeActions from './actions';
import { PurposeState, initialState } from './types';

const purpose = createReducer<PurposeState, RootAction>(initialState)
  .handleAction(purposeActions.listPurpose.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(purposeActions.listPurpose.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(purposeActions.readPurpose.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default purpose;
