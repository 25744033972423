export enum ProfileManageActions {
  READ_PROFILE_MANAGE_REQUEST = 'READ_PROFILE_MANAGE#REQUEST',
  READ_PROFILE_MANAGE_SUCCESS = 'READ_PROFILE_MANAGE#SUCCESS',
  READ_PROFILE_MANAGE_FAILURE = 'READ_PROFILE_MANAGE#FAILURE',

  UPDATE_PROFILE_MANAGE_REQUEST = 'UPDATE_PROFILE_MANAGE#REQUEST',
  UPDATE_PROFILE_MANAGE_SUCCESS = 'UPDATE_PROFILE_MANAGE#SUCCESS',
  UPDATE_PROFILE_MANAGE_FAILURE = 'UPDATE_PROFILE_MANAGE#FAILURE',
}

export const initialState: ProfileManageState = { detail: undefined };

export interface ProfileManageState {
  detail?: ProfileManageDetail;
}

export interface ProfileManage {
  userId: number;
  username: string;
  reportedCount: number;
  languageCode?: string;
  profileStatuses: ProfileStatus[];
  blocked: boolean;
}

export interface ProfileStatus {
  profileId: number;
  profileVideoUrl?: string;
}

export type ProfileManageDetail = ProfileManage;

export type ProfileManageUpdateRequestData = {
  userId: number;
};

export type ProfileManageUpdateSuccessData = ProfileManageDetail;
