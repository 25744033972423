import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createPromotionSetting,
  deletePromotionSetting,
  listPromotionSetting,
  readPromotionSetting,
  updatePromotionSetting,
} from './actions';
import {
  createPromotionSettingApi,
  deletePromotionSettingApi,
  listPromotionSettingApi,
  readPromotionSettingApi,
  updatePromotionSettingApi,
} from './api';

const listPromotionSettingEpic = createListEpic(listPromotionSetting, listPromotionSettingApi);

const readPromotionSettingEpic = createReadEpic(readPromotionSetting, readPromotionSettingApi);

const createPromotionSettingEpic = createPostEpic(createPromotionSetting, createPromotionSettingApi);

const createPromotionSettingToastEpic = createToastEpic(createPromotionSetting.success, 'Successfully created');

const updatePromotionSettingEpic = createUpdateEpic(updatePromotionSetting, updatePromotionSettingApi);

const updatePromotionSettingToastEpic = createToastEpic(updatePromotionSetting.success, 'Successfully saved');

const deletePromotionSettingEpic = createDeleteEpic(deletePromotionSetting, deletePromotionSettingApi);

const deletePromotionSettingToastEpic = createToastEpic(deletePromotionSetting.success, 'Successfully deleted');

export default combineEpics(
  listPromotionSettingEpic,
  readPromotionSettingEpic,
  createPromotionSettingEpic,
  createPromotionSettingToastEpic,
  updatePromotionSettingEpic,
  updatePromotionSettingToastEpic,
  deletePromotionSettingEpic,
  deletePromotionSettingToastEpic
);
