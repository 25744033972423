import { GemProductTierSchedule } from 'store/gemProductTierSchedule/types';
import { initialListState, ListState } from 'store/types';

export enum GemProductTierActions {
  CREATE_GEM_PRODUCT_TIER_REQUEST = 'CREATE_GEM_PRODUCT_TIER#REQUEST',
  CREATE_GEM_PRODUCT_TIER_SUCCESS = 'CREATE_GEM_PRODUCT_TIER#SUCCESS',
  CREATE_GEM_PRODUCT_TIER_FAILURE = 'CREATE_GEM_PRODUCT_TIER#FAILURE',

  UPDATE_GEM_PRODUCT_REQUEST = 'UPDATE_GEM_PRODUCT#REQUEST',
  UPDATE_GEM_PRODUCT_SUCCESS = 'UPDATE_GEM_PRODUCT#SUCCESS',
  UPDATE_GEM_PRODUCT_FAILURE = 'UPDATE_GEM_PRODUCT#FAILURE',

  UPDATE_GEM_PRODUCT_TIER_REQUEST = 'UPDATE_GEM_PRODUCT_TIER#REQUEST',
  UPDATE_GEM_PRODUCT_TIER_SUCCESS = 'UPDATE_GEM_PRODUCT_TIER#SUCCESS',
  UPDATE_GEM_PRODUCT_TIER_FAILURE = 'UPDATE_GEM_PRODUCT_TIER#FAILURE',

  LIST_GEM_PRODUCT_TIERS_REQUEST = 'LIST_GEM_PRODUCT_TIERS#REQUEST',
  LIST_GEM_PRODUCT_TIERS_SUCCESS = 'LIST_GEM_PRODUCT_TIERS#SUCCESS',
  LIST_GEM_PRODUCT_TIERS_FAILURE = 'LIST_GEM_PRODUCT_TIERS#FAILURE',

  READ_GEM_PRODUCT_TIER_REQUEST = 'READ_GEM_PRODUCT_TIER#REQUEST',
  READ_GEM_PRODUCT_TIER_SUCCESS = 'READ_GEM_PRODUCT_TIER#SUCCESS',
  READ_GEM_PRODUCT_TIER_FAILURE = 'READ_GEM_PRODUCT_TIER#FAILURE',

  REFRESH_GEM_PRODUCT_TIER_REQUEST = 'REFRESH_GEM_PRODUCT_TIER#REQUEST',
  REFRESH_GEM_PRODUCT_TIER_SUCCESS = 'REFRESH_GEM_PRODUCT_TIER#SUCCESS',
  REFRESH_GEM_PRODUCT_TIER_FAILURE = 'REFRESH_GEM_PRODUCT_TIER#FAILURE',
}

export const initialState: GemProductTierState = {
  list: initialListState,
  detail: undefined,
};

export interface GemProductTierState {
  list: ListState<GemProductTierListItem>;
  detail?: GemProductTierDetail;
}

export interface GemProductTier {
  tierCode: string;
  description: string;
  evalOrder: number;
  schedules: GemProductTierSchedule[];
}

export interface GemProduct {
  productId: string;
  baseGemIncrement: number;
  promoGemIncrement: number;
  bonusGemIncrement: number;
}

export interface GemProductTierListItem extends GemProductTier {}

export interface GemProductTierDetail extends GemProductTier {
  gemProducts: GemProduct[];
}

export interface GemProductTierFormValues extends GemProductTier {}

export type GemProductTierRequestData = Omit<GemProductTier, 'schedules'>;

export type GemProductTierResponseData = Omit<GemProductTier, 'schedules'>;
