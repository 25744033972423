import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createTeamMessageImageSetting, deleteTeamMessageImageSetting, updateTeamMessageImageSetting } from './actions';
import {
  createTeamMessageImageSettingApi,
  deleteTeamMessageImageSettingApi,
  updateTeamMessageImageSettingApi,
} from './api';

const createTeamMessageImageSettingEpic = createPostEpic(
  createTeamMessageImageSetting,
  createTeamMessageImageSettingApi
);

const createTeamMessageImageSettingToastEpic = createToastEpic(
  createTeamMessageImageSetting.success,
  'Successfully created'
);

const updateTeamMessageImageSettingEpic = createUpdateEpic(
  updateTeamMessageImageSetting,
  updateTeamMessageImageSettingApi
);

const updateTeamMessageImageSettingToastEpic = createToastEpic(
  updateTeamMessageImageSetting.success,
  'Successfully saved'
);

const deleteTeamMessageImageSettingEpic = createDeleteEpic(
  deleteTeamMessageImageSetting,
  deleteTeamMessageImageSettingApi
);

const deleteTeamMessageImageSettingToastEpic = createToastEpic(
  deleteTeamMessageImageSetting.success,
  'Successfully deleted'
);

export default combineEpics(
  createTeamMessageImageSettingEpic,
  createTeamMessageImageSettingToastEpic,
  updateTeamMessageImageSettingEpic,
  updateTeamMessageImageSettingToastEpic,
  deleteTeamMessageImageSettingEpic,
  deleteTeamMessageImageSettingToastEpic
);
