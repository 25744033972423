export enum GemWithdrawActions {
  WITHDRAW_GEM_REQUEST = 'WITHDRAW_GEM#REQUEST',
  WITHDRAW_GEM_SUCCESS = 'WITHDRAW_GEM#SUCCESS',
  WITHDRAW_GEM_FAILURE = 'WITHDRAW_GEM#FAILURE',
}

export interface GemWithdrawRequestFormValues {
  userId: number;
  normalGemAmount: number;
  bonusGemAmount: number;
  reason: string;
}
