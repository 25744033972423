import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteBodyRequestPayload, FormRequestPayload, ListRequestPayload, ListState } from 'store/types';

import { MatchTagActions as Actions, MatchTag, MatchTagCreateRequestData } from './types';

export const listMatchTag = createAsyncAction(
  Actions.LIST_MATCH_TAGS_REQUEST,
  Actions.LIST_MATCH_TAGS_SUCCESS,
  Actions.LIST_MATCH_TAGS_FAILURE
)<ListRequestPayload, ListState<MatchTag>, AxiosError>();

export const createMatchTag = createAsyncAction(
  Actions.CREATE_MATCH_TAG_REQUEST,
  Actions.CREATE_MATCH_TAG_SUCCESS,
  Actions.CREATE_MATCH_TAG_FAILURE
)<FormRequestPayload<MatchTagCreateRequestData>, undefined, AxiosError>();

export const deleteMatchTag = createAsyncAction(
  Actions.DELETE_MATCH_TAG_REQUEST,
  Actions.DELETE_MATCH_TAG_SUCCESS,
  Actions.DELETE_MATCH_TAG_FAILURE
)<DeleteBodyRequestPayload, string, AxiosError>();
