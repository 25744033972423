import { combineEpics } from 'redux-observable';

import { createDeleteBodyEpic, createListEpic, createPostEpic, createToastEpic } from 'store/utils';

import { createMatchTag, deleteMatchTag, listMatchTag } from './actions';
import { createMatchTagApi, deleteMatchTagApi, listMatchTagApi } from './api';

const listMatchTagEpic = createListEpic(listMatchTag, listMatchTagApi);

const createMatchTagEpic = createPostEpic(createMatchTag, createMatchTagApi);

const createMatchTagToastEpic = createToastEpic(createMatchTag.success, 'Successfully saved');

const deleteMatchTagEpic = createDeleteBodyEpic(deleteMatchTag, deleteMatchTagApi);

export default combineEpics(listMatchTagEpic, createMatchTagToastEpic, createMatchTagEpic, deleteMatchTagEpic);
