import {
  MlConfigFeatureSegmentCreateRequestData,
  MlConfigFeatureSegmentCreateSuccessData,
  MlConfigFeatureSegmentUpdateRequestData,
  MlConfigFeatureSegmentUpdateSuccessData,
} from 'store/mlConfigFeatureSegment/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

const BASE_PATH = '/ml-config/features/segments';
export const createMlConfigFeatureSegmentApi = createPostApi<
  MlConfigFeatureSegmentCreateRequestData,
  MlConfigFeatureSegmentCreateSuccessData
>(BASE_PATH);

export const updateMlConfigFeatureSegmentApi = createUpdateApi<
  MlConfigFeatureSegmentUpdateRequestData,
  MlConfigFeatureSegmentUpdateSuccessData
>(`${BASE_PATH}/:id`);

export const deleteMlConfigFeatureSegmentApi = createDeleteApi(`/ml-config/features/:featureId/segments/:segmentId`);
