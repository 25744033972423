import { initialListState, ListState } from 'store/types';

export enum TeamMatchProfileActions {
  LIST_TEAM_MATCH_PROFILES_REQUEST = 'LIST_TEAM_MATCH_PROFILES#REQUEST',
  LIST_TEAM_MATCH_PROFILES_SUCCESS = 'LIST_TEAM_MATCH_PROFILES#SUCCESS',
  LIST_TEAM_MATCH_PROFILES_FAILURE = 'LIST_TEAM_MATCH_PROFILES#FAILURE',

  READ_TEAM_MATCH_PROFILE_REQUEST = 'READ_TEAM_MATCH_PROFILE#REQUEST',
  READ_TEAM_MATCH_PROFILE_SUCCESS = 'READ_TEAM_MATCH_PROFILE#SUCCESS',
  READ_TEAM_MATCH_PROFILE_FAILURE = 'READ_TEAM_MATCH_PROFILE#FAILURE',

  CREATE_TEAM_MATCH_PROFILE_REQUEST = 'CREATE_TEAM_MATCH_PROFILE#REQUEST',
  CREATE_TEAM_MATCH_PROFILE_SUCCESS = 'CREATE_TEAM_MATCH_PROFILE#SUCCESS',
  CREATE_TEAM_MATCH_PROFILE_FAILURE = 'CREATE_TEAM_MATCH_PROFILE#FAILURE',

  UPDATE_TEAM_MATCH_PROFILE_REQUEST = 'UPDATE_TEAM_MATCH_PROFILE#REQUEST',
  UPDATE_TEAM_MATCH_PROFILE_SUCCESS = 'UPDATE_TEAM_MATCH_PROFILE#SUCCESS',
  UPDATE_TEAM_MATCH_PROFILE_FAILURE = 'UPDATE_TEAM_MATCH_PROFILE#FAILURE',

  DELETE_TEAM_MATCH_PROFILE_REQUEST = 'DELETE_TEAM_MATCH_PROFILE#REQUEST',
  DELETE_TEAM_MATCH_PROFILE_SUCCESS = 'DELETE_TEAM_MATCH_PROFILE#SUCCESS',
  DELETE_TEAM_MATCH_PROFILE_FAILURE = 'DELETE_TEAM_MATCH_PROFILE#FAILURE',
}

export const initialState: TeamMatchProfileState = {
  list: initialListState,
  detail: undefined,
};

export interface TeamMatchProfileState {
  list: ListState<TeamMatchProfileListItem>;
  detail?: TeamMatchProfileDetail;
}

export interface TeamMatchProfile {
  id: number;
  clearProfile: boolean;
  profileComment?: string;
  profileName?: string;
  imageUrl?: string;
  sponsored: boolean;
  teamMatchContentIds: number[];
  thumbnailImageUrl?: string;
  connectingTextColor: string;
}

export type TeamMatchProfileListItem = TeamMatchProfile;

export type TeamMatchProfileDetail = TeamMatchProfile;

export type TeamMatchProfileFormValues = Omit<TeamMatchProfile, 'id'>;

export type TeamMatchProfileCreateRequestData = TeamMatchProfileFormValues;

export type TeamMatchProfileCreateResponseData = TeamMatchProfile;

export type TeamMatchProfileUpdateRequestData = TeamMatchProfileFormValues;

export type TeamMatchProfileUpdateResponseData = TeamMatchProfile;
