import { initialListState, ListState } from 'store/types';

export enum MatchFilterSegmentActions {
  LIST_MATCH_FILTER_SEGMENTS_REQUEST = 'LIST_MATCH_FILTER_SEGMENTS#REQUEST',
  LIST_MATCH_FILTER_SEGMENTS_SUCCESS = 'LIST_MATCH_FILTER_SEGMENTS#SUCCESS',
  LIST_MATCH_FILTER_SEGMENTS_FAILURE = 'LIST_MATCH_FILTER_SEGMENTS#FAILURE',

  READ_MATCH_FILTER_SEGMENT_REQUEST = 'READ_MATCH_FILTER_SEGMENT#REQUEST',
  READ_MATCH_FILTER_SEGMENT_SUCCESS = 'READ_MATCH_FILTER_SEGMENT#SUCCESS',
  READ_MATCH_FILTER_SEGMENT_FAILURE = 'READ_MATCH_FILTER_SEGMENT#FAILURE',

  CREATE_MATCH_FILTER_SEGMENT_REQUEST = 'CREATE_MATCH_FILTER_SEGMENT#REQUEST',
  CREATE_MATCH_FILTER_SEGMENT_SUCCESS = 'CREATE_MATCH_FILTER_SEGMENT#SUCCESS',
  CREATE_MATCH_FILTER_SEGMENT_FAILURE = 'CREATE_MATCH_FILTER_SEGMENT#FAILURE',

  UPDATE_MATCH_FILTER_SEGMENT_REQUEST = 'UPDATE_MATCH_FILTER_SEGMENT#REQUEST',
  UPDATE_MATCH_FILTER_SEGMENT_SUCCESS = 'UPDATE_MATCH_FILTER_SEGMENT#SUCCESS',
  UPDATE_MATCH_FILTER_SEGMENT_FAILURE = 'UPDATE_MATCH_FILTER_SEGMENT#FAILURE',

  DELETE_MATCH_FILTER_SEGMENT_REQUEST = 'DELETE_MATCH_FILTER_SEGMENT#REQUEST',
  DELETE_MATCH_FILTER_SEGMENT_SUCCESS = 'DELETE_MATCH_FILTER_SEGMENT#SUCCESS',
  DELETE_MATCH_FILTER_SEGMENT_FAILURE = 'DELETE_MATCH_FILTER_SEGMENT#FAILURE',
}

export const initialState: MatchFilterSegmentState = {
  list: initialListState,
  detail: undefined,
};

export interface MatchFilterSegmentState {
  list: ListState<MatchFilterSegmentListItem>;
  detail?: MatchFilterSegmentDetail;
}

export interface MatchFilterSegment {
  segmentCode: string;
  description?: string;
  userSegmentCode?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder: number;
  segmentStartDate?: string;
  segmentEndDate?: string;
  enabled: boolean;
}

export type MatchFilterSegmentListItem = Omit<MatchFilterSegment, 'segmentStartDate' | 'segmentEndDate'>;

export type MatchFilterSegmentDetail = MatchFilterSegment;

export interface MatchFilterSegmentFormValues {
  segmentCode: string;
  description?: string;
  userSegmentCode?: string;
  segmentStartDate?: string;
  segmentEndDate?: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  evalOrder?: number | null;
  enabled?: boolean;
}

export type MatchFilterSegmentCreateRequestData = Omit<
  MatchFilterSegmentFormValues,
  'segmentCode' | 'segmentStartDate' | 'segmentEndDate'
>;

export type MatchFilterSegmentCreateResponseData = Omit<
  MatchFilterSegmentFormValues,
  'segmentStartDate' | 'segmentEndDate'
>;

export type MatchFilterSegmentUpdateRequestData = Omit<
  MatchFilterSegmentFormValues,
  'segmentCode' | 'segmentStartDate' | 'segmentEndDate'
>;

export type MatchFilterSegmentUpdateResponseData = Omit<
  MatchFilterSegmentFormValues,
  'segmentStartDate' | 'segmentEndDate'
>;
