import { DecoSettingProduct } from 'store/decoSettingProduct/types';
import { DecoSettingSchedule as BaseDecoSettingSchedule } from 'store/decoSettingSchedule/types';
import { DecoItemGroupId, DecoCategoryType } from 'store/decoResource/types';
import { initialListState, ListState } from 'store/types';

export enum DecoSettingActions {
  LIST_DECO_SETTINGS_REQUEST = 'LIST_DECO_SETTINGS#REQUEST',
  LIST_DECO_SETTINGS_SUCCESS = 'LIST_DECO_SETTINGS#SUCCESS',
  LIST_DECO_SETTINGS_FAILURE = 'LIST_DECO_SETTINGS#FAILURE',

  READ_DECO_SETTING_REQUEST = 'READ_DECO_SETTING#REQUEST',
  READ_DECO_SETTING_FAILURE = 'READ_DECO_SETTING#FAILURE',
  READ_DECO_SETTING_SUCCESS = 'READ_DECO_SETTING#SUCCESS',

  CREATE_DECO_SETTING_REQUEST = 'CREATE_DECO_SETTING#REQUEST',
  CREATE_DECO_SETTING_SUCCESS = 'CREATE_DECO_SETTING#SUCCESS',
  CREATE_DECO_SETTING_FAILURE = 'CREATE_DECO_SETTING#FAILURE',

  UPDATE_DECO_SETTING_REQUEST = 'UPDATE_DECO_SETTING#REQUEST',
  UPDATE_DECO_SETTING_SUCCESS = 'UPDATE_DECO_SETTING#SUCCESS',
  UPDATE_DECO_SETTING_FAILURE = 'UPDATE_DECO_SETTING#FAILURE',

  DELETE_DECO_SETTING_REQUEST = 'DELETE_DECO_SETTING#REQUEST',
  DELETE_DECO_SETTING_SUCCESS = 'DELETE_DECO_SETTING#SUCCESS',
  DELETE_DECO_SETTING_FAILURE = 'DELETE_DECO_SETTING#FAILURE',

  REFRESH_DECO_SETTING_REQUEST = 'REFRESH_DECO_SETTING#REQUEST',
  REFRESH_DECO_SETTING_SUCCESS = 'REFRESH_DECO_SETTING#SUCCESS',
  REFRESH_DECO_SETTING_FAILURE = 'REFRESH_DECO_SETTING#FAILURE',
}

export const initialState: DecoSettingState = {
  list: initialListState,
  detail: undefined,
};

export interface DecoSettingState {
  list: ListState<DecoSettingListItem>;
  detail?: DecoSettingDetail;
}

export type IndexableType<K extends string, V> = {
  [key in K]: V;
};

export type DecoSettingProductMap = IndexableType<DecoItemGroupId, DecoSettingProduct[]>;

export type DecoSettingSchedule = BaseDecoSettingSchedule;

export type DecoSettingProductCount = IndexableType<DecoCategoryType, number>;

export interface DecoSetting {
  id: number;
  name: string;
  products: DecoSettingProductMap;
  schedules: DecoSettingSchedule[];
  productCount: DecoSettingProductCount;
}

export interface DecoSettingListItem extends Omit<DecoSetting, 'productCount'> {
  stickerCount: number;
  jcvFilterEffectCount: number;
  jcvBackgroundEffectCount: number;
  jcvStyleEffectCount: number;
  jcvMakeupEffectCount: number;
  entranceEffectCount: number;
  graphicEffectCount: number;
  filterEffectCount: number;
  backgroundCount: number;
  avatarCount: number;
}

export type DecoSettingDetail = DecoSetting;

export interface DecoSettingFormValues {
  name: string;
}

export type DecoSettingCreateRequestData = DecoSettingFormValues;

export type DecoSettingCreateResponseData = Pick<DecoSetting, 'id' | 'name'>;

export type DecoSettingUpdateRequestData = DecoSettingCreateRequestData;

export type DecoSettingUpdateResponseData = DecoSettingCreateResponseData;
