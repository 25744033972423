import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/root/types';
import * as settingActions from 'store/setting/actions';
import { defaultPreset, SettingState } from 'store/setting/types';

const selector = ({ setting }: RootState) => setting;

export function usePresetList() {
  const dispatch = useDispatch();
  const { presets, currentPresetIndex } = useSelector(selector, shallowEqual);

  const setPresetByIndex = useCallback(
    (targetIndex: number, value: SettingState['presets'][0]) => {
      dispatch(
        settingActions.setPresets({
          presets: presets.map((preset, index) => (index === targetIndex ? value : preset)),
        })
      );
    },
    [dispatch, presets]
  );

  const setPresetByIndexWithDefaultValue = useCallback(
    (targetIndex: number) => {
      dispatch(
        settingActions.setPresets({
          presets: presets.map((preset, index) => (index === targetIndex ? defaultPreset : preset)),
        })
      );
      return defaultPreset;
    },
    [dispatch, presets]
  );

  const setCurrentPresetIndex = useCallback(
    (targetIndex: number) => {
      dispatch(
        settingActions.setCurrentPresetIndex({
          currentPresetIndex: targetIndex,
        })
      );
    },
    [dispatch]
  );

  return {
    presets,
    setPresetByIndex,
    currentPresetIndex,
    setCurrentPresetIndex,
    setPresetByIndexWithDefaultValue,
  };
}
