import { initialListState, ListState } from 'store/types';
import { Gender } from '../agentManage/types';

export enum CriteriaSegmentActions {
  LIST_CRITERIA_SEGMENTS_REQUEST = 'LIST_CRITERIA_SEGMENTS#REQUEST',
  LIST_CRITERIA_SEGMENTS_SUCCESS = 'LIST_CRITERIA_SEGMENTS#SUCCESS',
  LIST_CRITERIA_SEGMENTS_FAILURE = 'LIST_CRITERIA_SEGMENTS#FAILURE',

  READ_CRITERIA_SEGMENT_REQUEST = 'READ_CRITERIA_SEGMENT#REQUEST',
  READ_CRITERIA_SEGMENT_SUCCESS = 'READ_CRITERIA_SEGMENT#SUCCESS',
  READ_CRITERIA_SEGMENT_FAILURE = 'READ_CRITERIA_SEGMENT#FAILURE',

  UPDATE_CRITERIA_SEGMENT_REQUEST = 'UPDATE_CRITERIA_SEGMENT#REQUEST',
  UPDATE_CRITERIA_SEGMENT_SUCCESS = 'UPDATE_CRITERIA_SEGMENT#SUCCESS',
  UPDATE_CRITERIA_SEGMENT_FAILURE = 'UPDATE_CRITERIA_SEGMENT#FAILURE',

  DELETE_CRITERIA_SEGMENT_REQUEST = 'DELETE_CRITERIA_SEGMENT#REQUEST',
  DELETE_CRITERIA_SEGMENT_SUCCESS = 'DELETE_CRITERIA_SEGMENT#SUCCESS',
  DELETE_CRITERIA_SEGMENT_FAILURE = 'DELETE_CRITERIA_SEGMENT#FAILURE',
}

export const initialState: CriteriaSegmentState = {
  list: initialListState,
  detail: undefined,
};

export interface CriteriaSegmentListItem extends CriteriaSegmentUpdateRequestData {
  id: number;
  enabled: boolean;
  startDate: string;
  endDate: string;
}

export type CriteriaSegmentDetail = CriteriaSegmentListItem;

export interface CriteriaSegmentState {
  list: ListState<CriteriaSegmentListItem>;
  detail?: CriteriaSegmentDetail;
}

export enum Platform {
  IOS = 'iOS',
  ANDROID = 'Android',
}

export interface CriteriaSegmentFormValues {
  id: number;
  segmentCode: string;
  language?: string;
  country?: string;
  gender?: Gender;
  hasEmailLogin?: boolean;
  hasFacebookLogin?: boolean;
  hasKakaoLogin?: boolean;
  hasQqLogin?: boolean;
  hasGoogleLogin?: boolean;
  maxAge?: number;
  maxDaysAfterLastLogin?: number;
  maxDaysAfterLastPurchase?: number;
  maxDaysAfterSignUp?: number;
  maxLoginCount?: number;
  maxPurchases?: number;
  maxTimeZoneOffset?: number;
  minAge?: number;
  minDaysAfterLastLogin?: number;
  minDaysAfterLastPurchase?: number;
  minDaysAfterSignUp?: number;
  minLoginCount?: number;
  minPurchases?: number;
  minTimeZoneOffset?: number;
  platform?: string;
  minApiVersion?: number;
  hasPendingNotification?: boolean;
  emailValid?: boolean;
  hasUnsentReminderEmail?: boolean;
  usePendingNotificationCount?: boolean;
  hasTwitterLogin?: boolean;
  hasWeiboLogin?: boolean;
  hasLineLogin?: boolean;
  minSuspensionCount?: number;
  maxSuspensionCount?: number;
  hasAppleLogin?: boolean;
}

export type CriteriaSegmentUpdateRequestData = CriteriaSegmentFormValues;
export type CriteriaSegmentResponseData = CriteriaSegmentDetail;
