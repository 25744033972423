import { createDeleteBodyApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  NoticeCreateRequestData,
  NoticeCreateRequestFormValues,
  NoticeCreateSuccessData,
  NoticeDetail,
  NoticeListItem,
  NoticePopupButtonsBulkInsertRequestData,
  NoticePopupCarouselsBulkInsertRequestData,
  NoticeUpdateRequestData,
  NoticeUpdateRequestFormValues,
  NoticeUpdateSuccessData,
} from './types';

export const listNoticeApi = createListApi<NoticeListItem>('/notice_popups/settings');
export const createNoticeApi = createPostApi<
  NoticeCreateRequestFormValues,
  NoticeCreateRequestData,
  NoticeCreateSuccessData
>('/notice_popups/settings');
export const readNoticeApi = createReadApi<NoticeDetail>('/notice_popups/settings/popup');
export const updateNoticeApi = createUpdateApi<
  NoticeUpdateRequestFormValues,
  NoticeUpdateRequestData,
  NoticeUpdateSuccessData
>('/notice_popups/settings/:id');
export const refreshNoticeApi = createPostApi('/notice_popups/refresh');
export const bulkInsertNoticeButtonsApi = createPostApi<NoticePopupButtonsBulkInsertRequestData>(
  '/notice_popups/settings/:id/buttons'
);
export const bulkInsertNoticeCarouselsApi = createPostApi<NoticePopupCarouselsBulkInsertRequestData>(
  '/notice_popups/settings/:id/carousels'
);
export const bulkDeleteNoticeButtonsApi = createDeleteBodyApi('/notice_popups/buttons');
export const bulkDeleteNoticeCarouselsApi = createDeleteBodyApi('/notice_popups/carousels');
