import { createReducer } from 'typesafe-actions';

import { MlConfigModelSegment } from 'store/mlConfigModelSegment/types';

import * as mlConfigModelSegmentActions from '../mlConfigModelSegment/actions';
import { RootAction } from '../root/types';

import * as mlConfigModelActions from './actions';
import { initialState, MlConfigModelState } from './types';

// noinspection TypeScriptValidateJSTypes
const mlConfigModel = createReducer<MlConfigModelState, RootAction>(initialState)
  .handleAction(mlConfigModelActions.listMlConfigModel.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(mlConfigModelActions.listMlConfigModel.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(mlConfigModelActions.readMlConfigModel.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(mlConfigModelActions.updateMlConfigModel.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(mlConfigModelSegmentActions.createMlConfigModelSegment.success, (state, { payload }) => {
    const segments = state?.detail?.segments ?? [];
    const newSegments = [...segments, payload];

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            segments: newSegments,
          }
        : undefined,
    };
  })
  .handleAction(mlConfigModelSegmentActions.updateMlConfigModelSegment.success, (state, { payload }) => {
    const segments = state?.detail?.segments ?? [];
    const updatedSegmentIndex = segments.findIndex((segment: MlConfigModelSegment) => segment.id === payload.id);

    if (updatedSegmentIndex !== -1) {
      segments[updatedSegmentIndex] = payload;
    }

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            segments: segments,
          }
        : undefined,
    };
  })
  .handleAction(mlConfigModelSegmentActions.deleteMlConfigModelSegment.success, (state, { payload }) => {
    const segments = state?.detail?.segments ?? [];
    const newSegments = segments.filter((button: MlConfigModelSegment) => button.id !== Number(payload));

    return {
      ...state,
      detail: state.detail
        ? {
            ...state.detail,
            segments: newSegments,
          }
        : undefined,
    };
  });

export default mlConfigModel;
