import { initialListState, ListState } from 'store/types';
import { NewUserSegment } from 'store/userSegment/types';

export enum ManualSegmentActions {
  LIST_MANUAL_SEGMENTS_REQUEST = 'LIST_MANUAL_SEGMENTS#REQUEST',
  LIST_MANUAL_SEGMENTS_SUCCESS = 'LIST_MANUAL_SEGMENTS#SUCCESS',
  LIST_MANUAL_SEGMENTS_FAILURE = 'LIST_MANUAL_SEGMENTS#FAILURE',

  READ_MANUAL_SEGMENT_REQUEST = 'READ_MANUAL_SEGMENT#REQUEST',
  READ_MANUAL_SEGMENT_SUCCESS = 'READ_MANUAL_SEGMENT#SUCCESS',
  READ_MANUAL_SEGMENT_FAILURE = 'READ_MANUAL_SEGMENT#FAILURE',

  CREATE_MANUAL_SEGMENTS_REQUEST = 'CREATE_MANUAL_SEGMENTS#REQUEST',
  CREATE_MANUAL_SEGMENTS_SUCCESS = 'CREATE_MANUAL_SEGMENTS#SUCCESS',
  CREATE_MANUAL_SEGMENTS_FAILURE = 'CREATE_MANUAL_SEGMENTS#FAILURE',

  UPDATE_MANUAL_SEGMENTS_REQUEST = 'UPDATE_MANUAL_SEGMENTS#REQUEST',
  UPDATE_MANUAL_SEGMENTS_SUCCESS = 'UPDATE_MANUAL_SEGMENTS#SUCCESS',
  UPDATE_MANUAL_SEGMENTS_FAILURE = 'UPDATE_MANUAL_SEGMENTS#FAILURE',

  DELETE_MANUAL_SEGMENTS_REQUEST = 'DELETE_MANUAL_SEGMENTS#REQUEST',
  DELETE_MANUAL_SEGMENTS_SUCCESS = 'DELETE_MANUAL_SEGMENTS#SUCCESS',
  DELETE_MANUAL_SEGMENTS_FAILURE = 'DELETE_MANUAL_SEGMENTS#FAILURE',
}

export const initialState: ManualSegmentState = {
  list: initialListState,
  detail: undefined,
};

export interface ManualSegmentState {
  list: ListState<ManualSegmentListItem>;
  detail?: ManualSegmentDetail;
}

export interface Segment extends NewUserSegment {}

export interface User {
  id: string;
  userName?: string;
}

export interface ManualSegment {
  id: number;
  segment: Segment;
  user: User;
}

export interface ManualSegmentListItem {
  id: number;
  checked?: boolean;
  segmentCode: string;
  segmentName?: string;
  description?: string;
  userId: string;
  userName?: string;
}

export interface ManualSegmentDetail {
  id: number;
  segmentCode: string;
  userId: string;
  userName?: string;
}

export interface ManualSegmentFormValues {
  segmentCode: string;
  userId?: string;
  userIds?: string;
  userName?: string;
  userNames?: string;
}

export interface ManualSegmentCreateRequestData {
  segmentCode: string;
  userIds?: number[];
  userNames?: string[];
}

export interface ManualSegmentUpdateRequestData {
  segmentCode: string;
  userId?: string;
  userName?: string;
}

export type ManualSegmentUpdateSuccessData = ManualSegmentDetail;
