import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';

import { layoutActions } from 'store/layout/slice';
import { RootState } from 'store/root/types';

const useStyles = makeStyles((theme) => ({
  margin: { margin: theme.spacing(1) },
  error: { backgroundColor: theme.palette.error.dark },
  info: { backgroundColor: theme.palette.primary.main },
  icon: { fontSize: 20 },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const selector = ({
  layout: {
    toast: { message, type },
  },
}: RootState) => ({
  message,
  type,
});

const Toast: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector(selector);
  const [alertOption, setAlertOption] = useState(state);

  useEffect(() => {
    if (state.type) {
      setAlertOption(state);
    }
  }, [state]);

  const { type, message } = alertOption;

  const handleClose = () => {
    dispatch(layoutActions.dismissToast());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={!!state.type}
      autoHideDuration={3000}
      onClose={() => handleClose()}
    >
      <SnackbarContent
        className={type ? classes[type] : undefined}
        aria-describedby='client-snackbar'
        message={
          <Typography id='client-snackbar' className={classes.message} component='span'>
            {message}
          </Typography>
        }
        action={[
          <IconButton key='close' aria-label='close' color='inherit' onClick={handleClose}>
            <Close className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default Toast;
