import React, { FC, useCallback } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';

import { layoutActions } from 'store/layout/slice';
import { ModalState } from 'store/layout/types';
import { RootState } from 'store/root/types';

const selector = ({ layout: { modal } }: RootState) => modal;

const Modal: FC = () => {
  const dispatch = useDispatch();

  const { isOpen, title, children, isOutsideClose = true } = useSelector<RootState, ModalState>(selector);

  const handleClose = useCallback(() => {
    if (!isOutsideClose) return;

    dispatch(layoutActions.dismissModal());
  }, [dispatch, isOutsideClose]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle disableTypography>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
