import { FlatListState } from '../types';

export enum GemSettingLimitKeyActions {
  LIST_LIMIT_KEY_REQUEST = 'LIST_GEM_SHOP_LIMIT_KEY#REQUEST',
  LIST_LIMIT_KEY_SUCCESS = 'LIST_GEM_SHOP_LIMIT_KEY#SUCCESS',
  LIST_LIMIT_KEY_FAILURE = 'LIST_GEM_SHOP_LIMIT_KEY#FAILURE',
}

export interface GemSettingLimitKeyState {
  list: FlatListState<GemSettingLimitKey>;
}

export const initialListState: FlatListState<GemSettingLimitKey> = [];

export const initialState: GemSettingLimitKeyState = { list: initialListState };

export interface GemSettingLimitKey {
  id: number;
  name: string;
  maxPurchaseCount: number;
}
