import { combineEpics } from 'redux-observable';

import {
  createNoticePopupSchedule,
  deleteNoticePopupSchedule,
  updateNoticePopupSchedule,
} from 'store/noticePopupSchedule/actions';
import {
  createNoticePopupScheduleApi,
  deleteNoticePopupScheduleApi,
  updateNoticePopupScheduleApi,
} from 'store/noticePopupSchedule/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createNoticePopupScheduleToastEpic = createToastEpic(
  createNoticePopupSchedule.success,
  'Successfully created Schedule Setting'
);

const updateNoticePopupScheduleToastEpic = createToastEpic(
  updateNoticePopupSchedule.success,
  'Successfully saved Schedule Setting'
);

const deleteNoticePopupScheduleToastEpic = createToastEpic(
  deleteNoticePopupSchedule.success,
  'Successfully deleted Schedule Setting'
);

const createScheduleEpic = createPostEpic(createNoticePopupSchedule, createNoticePopupScheduleApi);

const updateScheduleEpic = createUpdateEpic(updateNoticePopupSchedule, updateNoticePopupScheduleApi);

const deleteScheduleEpic = createDeleteEpic(deleteNoticePopupSchedule, deleteNoticePopupScheduleApi);

export default combineEpics(
  createNoticePopupScheduleToastEpic,
  updateNoticePopupScheduleToastEpic,
  deleteNoticePopupScheduleToastEpic,
  createScheduleEpic,
  updateScheduleEpic,
  deleteScheduleEpic
);
