import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as matchTagActions from './actions';
import { initialState, MatchTagState } from './types';

const matchTag = createReducer<MatchTagState, RootAction>(initialState)
  .handleAction(matchTagActions.listMatchTag.request, (state, { payload: { page, pageSize } }) => ({
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(matchTagActions.listMatchTag.success, (state, { payload }) => ({ list: payload }));

export default matchTag;
