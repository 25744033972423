import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { ListRequestPayload, ListState, ReadRequestPayload } from '../types';

import { MatataInvalidatedPurchaseBySender, MatataInvalidatedPurchaseBySenderActions as Actions } from './types';

export const readMatataInvalidatedPurchaseBySender = createAsyncAction(
  Actions.READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER_REQUEST,
  Actions.READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER_SUCCESS,
  Actions.READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER_FAILURE
)<ReadRequestPayload, MatataInvalidatedPurchaseBySender, AxiosError>();

export const listMatataInvalidatedPurchaseBySender = createAsyncAction(
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER_REQUEST,
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER_SUCCESS,
  Actions.LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER_FAILURE
)<ListRequestPayload, ListState<MatataInvalidatedPurchaseBySender>, AxiosError>();
