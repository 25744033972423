import { initialListState, ListRequestPayload, ListState } from 'store/types';

export enum PunishmentActions {
  LIST_PUNISHMENT_REQUEST = 'LIST_USER_PUNISHMENT#REQUEST',
  LIST_PUNISHMENT_SUCCESS = 'LIST_USER_PUNISHMENT#SUCCESS',
  LIST_PUNISHMENT_FAILURE = 'LIST_USER_PUNISHMENT#FAILURE',
  DELETE_PUNISHMENT_REQUEST = 'DELETE_PUNISHMENT#REQUEST',
  DELETE_PUNISHMENT_SUCCESS = 'DELETE_PUNISHMENT#SUCCESS',
  DELETE_PUNISHMENT_FAILURE = 'DELETE_PUNISHMENT#FAILURE',
}

export interface PunishmentState {
  list: ListState<PunishmentItem>;
}

export interface PunishmentItem {
  id: string;
  group: string;
  type: string;
  reason: string;
  startDate: string;
  endDate: string;
  delete: string;
  reporter?: string;

  /**
   * GROUP = deviceId 인 경우에만, 추가정보를 제공하는 필드입니다.
   */
  /** Group에 해당하는 Device ID로 로그인한 이력이 있는 사용자 ID 목록 (최근 7일) */
  userIds: string;
  /** Group에 해당하는 Device ID로 제재당한 본래의 사용자 ID (CS 멀티 디바이스 제재 추적에 사용) */
  punishedUserIds: string;
}

export type PunishmentRequestPayload = ListRequestPayload;
export type PunishmentResponseData = ListState<PunishmentItem>;

export type DeletePunishmentRequestPayload = {
  id: string;
};

export type DeletePunishmentSuccessPayload = string;

export const initialPunishmentState: PunishmentState = {
  list: initialListState,
};
