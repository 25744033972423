import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  SpecialPromotionSettingProductActions as Actions,
  SpecialPromotionSettingProductCreateRequestData,
  SpecialPromotionSettingProductCreateSuccessData,
  SpecialPromotionSettingProductUpdateRequestData,
  SpecialPromotionSettingProductUpdateSuccessData,
} from 'store/specialPromotionSettingProduct/types';
import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

export const createSpecialPromotionSettingProduct = createAsyncAction(
  Actions.CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT_REQUEST,
  Actions.CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT_SUCCESS,
  Actions.CREATE_SPECIAL_PROMOTION_SETTING_PRODUCT_FAILURE
)<
  FormRequestPayload<SpecialPromotionSettingProductCreateRequestData>,
  SpecialPromotionSettingProductCreateSuccessData,
  AxiosError
>();

export const updateSpecialPromotionSettingProduct = createAsyncAction(
  Actions.UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT_REQUEST,
  Actions.UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT_SUCCESS,
  Actions.UPDATE_SPECIAL_PROMOTION_SETTING_PRODUCT_FAILURE
)<
  FormRequestPayload<SpecialPromotionSettingProductUpdateRequestData>,
  SpecialPromotionSettingProductUpdateSuccessData,
  AxiosError
>();

export const deleteSpecialPromotionSettingProduct = createAsyncAction(
  Actions.DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT_REQUEST,
  Actions.DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT_SUCCESS,
  Actions.DELETE_SPECIAL_PROMOTION_SETTING_PRODUCT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
