export enum NoticePopupCarouselActions {
  CREATE_CAROUSEL_REQUEST = 'CREATE_NOTICE_POPUP_CAROUSEL#REQUEST',
  CREATE_CAROUSEL_SUCCESS = 'CREATE_NOTICE_POPUP_CAROUSEL#SUCCESS',
  CREATE_CAROUSEL_FAILURE = 'CREATE_NOTICE_POPUP_CAROUSEL#FAILURE',

  UPDATE_CAROUSEL_REQUEST = 'UPDATE_NOTICE_POPUP_CAROUSEL#REQUEST',
  UPDATE_CAROUSEL_SUCCESS = 'UPDATE_NOTICE_POPUP_CAROUSEL#SUCCESS',
  UPDATE_CAROUSEL_FAILURE = 'UPDATE_NOTICE_POPUP_CAROUSEL#FAILURE',

  DELETE_CAROUSEL_REQUEST = 'DELETE_NOTICE_POPUP_CAROUSEL#REQUEST',
  DELETE_CAROUSEL_SUCCESS = 'DELETE_NOTICE_POPUP_CAROUSEL#SUCCESS',
  DELETE_CAROUSEL_FAILURE = 'DELETE_NOTICE_POPUP_CAROUSEL#FAILURE',
}

export enum NoticePopupContentType {
  CARD = 'CARD',
  IMAGE = 'IMAGE',
}

export interface NoticePopupCarousel {
  id: number;
  settingId: number;
  image: PopupImage | null;
  title: string;
  content: string;
  displayOrder: number;
  type: NoticePopupContentType;
}

export interface StringLocalizationFormValues {
  titleAsString: boolean;
  contentAsString: boolean;
  imageUrlAsString: boolean;
}

interface PopupImage {
  imageUrl: string;
  width: number;
  height: number;
}

export type NoticePopupCarouselCreateRequestData = Omit<NoticePopupCarousel, 'id'>;
export type NoticePopupCarouselCreateFormValues = Omit<NoticePopupCarouselCreateRequestData, 'image'> &
  StringLocalizationFormValues &
  PopupImage;
export type NoticePopupCarouselCreateSuccessData = NoticePopupCarousel;

export type NoticePopupCarouselUpdateRequestData = Omit<NoticePopupCarousel, 'id'>;
export type NoticePopupCarouselUpdateFormValues = Omit<NoticePopupCarouselUpdateRequestData, 'image'> &
  StringLocalizationFormValues &
  PopupImage;
export type NoticePopupCarouselUpdateSuccessData = NoticePopupCarousel;
