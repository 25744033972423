import {
  NoticePopupScheduleCreateRequestData,
  NoticePopupScheduleCreateSuccessData,
  NoticePopupScheduleUpdateRequestData,
  NoticePopupScheduleUpdateSuccessData,
} from 'store/noticePopupSchedule/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createNoticePopupScheduleApi = createPostApi<
  NoticePopupScheduleCreateRequestData,
  NoticePopupScheduleCreateSuccessData
>('/notice_popups/schedules');

export const updateNoticePopupScheduleApi = createUpdateApi<
  NoticePopupScheduleUpdateRequestData,
  NoticePopupScheduleUpdateSuccessData
>('/notice_popups/schedules/:id');

export const deleteNoticePopupScheduleApi = createDeleteApi('/notice_popups/schedules/:id');
