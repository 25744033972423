import { initialListState, ListState } from 'store/types';

export enum ProductActions {
  LIST_ITEM_PRODUCT_REQUEST = 'LIST_ITEM_PRODUCT#REQUEST',
  LIST_ITEM_PRODUCT_SUCCESS = 'LIST_ITEM_PRODUCT#SUCCESS',
  LIST_ITEM_PRODUCT_FAILURE = 'LIST_ITEM_PRODUCT#FAILURE',

  LIST_GENERAL_PRODUCT_REQUEST = 'LIST_GENERAL_PRODUCT#REQUEST',
  LIST_GENERAL_PRODUCT_SUCCESS = 'LIST_GENERAL_PRODUCT#SUCCESS',
  LIST_GENERAL_PRODUCT_FAILURE = 'LIST_GENERAL_PRODUCT#FAILURE',
}

export interface Product {
  productId: string;
}

export interface GeneralProduct {
  generalProductId: string;
}

export interface ItemProduct {
  itemId: string;
}

export enum ProductType {
  ITEM = 'item',
  GENERAL = 'general',
}

export const initialState: ProductState = {
  [ProductType.ITEM]: { list: initialListState },
  [ProductType.GENERAL]: { list: initialListState },
};

export type ProductState = {
  [ProductType.ITEM]: {
    list: ListState<ItemProduct>;
  };
  [ProductType.GENERAL]: {
    list: ListState<GeneralProduct>;
  };
};
