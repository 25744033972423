import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createFlatListEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createWebDecoEffect,
  listWebDecoEffect,
  listWebDecoGroupEffect,
  readWebDecoEffect,
  refreshWebDecoEffect,
  updateWebDecoEffect,
} from './actions';
import {
  createWebDecoEffectApi,
  listWebDecoEffectApi,
  listWebDecoEffectGroupApi,
  readWebDecoEffectApi,
  refreshWebDecoEffectApi,
  updateWebDecoEffectApi,
} from './api';

const listWebDecoEffectEpic = createListEpic(listWebDecoEffect, listWebDecoEffectApi);

const listWebDecoEffectGroupEpic = createFlatListEpic(listWebDecoGroupEffect, listWebDecoEffectGroupApi);

const readWebDecoEffectEpic = createReadEpic(readWebDecoEffect, readWebDecoEffectApi);

const createWebDecoEffectEpic = createPostEpic(createWebDecoEffect, createWebDecoEffectApi);

const createWebDecoEffectToastEpic = createToastEpic(createWebDecoEffect.success, 'Successfully created from server');

const updateWebDecoEffectEpic = createUpdateEpic(updateWebDecoEffect, updateWebDecoEffectApi);

const updateWebDecoEffectToastEpic = createToastEpic(updateWebDecoEffect.success, 'Successfully updated from server');

const refreshWebDecoEffectEpic = createAsyncEpic(refreshWebDecoEffect, refreshWebDecoEffectApi);

const refreshWebDecoEffectToastEpic = createToastEpic(
  refreshWebDecoEffect.success,
  'Successfully refreshed from server'
);

export default combineEpics(
  listWebDecoEffectEpic,
  listWebDecoEffectGroupEpic,
  readWebDecoEffectEpic,
  createWebDecoEffectEpic,
  createWebDecoEffectToastEpic,
  updateWebDecoEffectEpic,
  updateWebDecoEffectToastEpic,
  refreshWebDecoEffectEpic,
  refreshWebDecoEffectToastEpic
);
