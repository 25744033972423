import { SegmentDeclaration } from 'store/bigquerySegmentDeclaration/types';
import { initialListState, ListState } from 'store/types';

export enum BigquerySegmentActions {
  LIST_BIGQUERY_SEGMENTS_REQUEST = 'LIST_BIGQUERY_SEGMENTS#REQUEST',
  LIST_BIGQUERY_SEGMENTS_SUCCESS = 'LIST_BIGQUERY_SEGMENTS#SUCCESS',
  LIST_BIGQUERY_SEGMENTS_FAILURE = 'LIST_BIGQUERY_SEGMENTS#FAILURE',

  LIST_BIGQUERY_SEGMENTS_SEARCH_REQUEST = 'LIST_BIGQUERY_SEGMENTS_SEARCH#REQUEST',
  LIST_BIGQUERY_SEGMENTS_SEARCH_SUCCESS = 'LIST_BIGQUERY_SEGMENTS_SEARCH#SUCCESS',
  LIST_BIGQUERY_SEGMENTS_SEARCH_FAILURE = 'LIST_BIGQUERY_SEGMENTS_SEARCH#FAILURE',

  READ_BIGQUERY_SEGMENT_REQUEST = 'READ_BIGQUERY_SEGMENT#REQUEST',
  READ_BIGQUERY_SEGMENT_SUCCESS = 'READ_BIGQUERY_SEGMENT#SUCCESS',
  READ_BIGQUERY_SEGMENT_FAILURE = 'READ_BIGQUERY_SEGMENT#FAILURE',
}

export const initialState: BigquerySegmentState = {
  list: initialListState,
  searchList: initialListState,
  detail: undefined,
};

export interface BigquerySegmentDetail {
  segmentCode: string;
  declaration: SegmentDeclaration;
  schedule: SegmentSchedule;
}

export interface SegmentSchedule {
  schedule: string;
}

export interface BigquerySegment {
  segmentCode: string;
  reviewState: string;
  startDate?: string;
  endDate?: string;
}

export interface BigqueryNewUserSegment {
  segmentCode: string;
}

export interface BigquerySegmentState {
  list: ListState<BigquerySegment>;
  searchList: ListState<BigqueryNewUserSegment>;
  detail?: BigquerySegmentDetail;
}

export interface segmentSchedule {
  schedule: Schedule;
}

export enum Schedule {
  NONE = 'NONE',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY ',
}
