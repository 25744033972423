import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as teamMatchCampaignActions from './actions';
import { initialState, TeamMatchCampaignState } from './types';

const teamMatchCampaign = createReducer<TeamMatchCampaignState, RootAction>(initialState)
  .handleAction(teamMatchCampaignActions.listTeamMatchCampaign.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(teamMatchCampaignActions.listTeamMatchCampaign.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(({ teamMatchContents = [], ...campaignValues }) => ({
        ...campaignValues,
        teamMatchContents: teamMatchContents.map(({ teamProfile = {}, ...contentValues }) => ({
          ...contentValues,
          profileName: teamProfile.profileName,
        })),
      })),
    });

    return newState.toJS() as TeamMatchCampaignState;
  })
  .handleAction(teamMatchCampaignActions.readTeamMatchCampaign.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(teamMatchCampaignActions.createTeamMatchContent.success, (state, { payload }) => {
    const currentContents = state.detail?.teamMatchContents || [];

    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.setIn(['detail', 'teamMatchContents'], [...currentContents, payload]);

    return newState.toJS() as TeamMatchCampaignState;
  })
  .handleAction(teamMatchCampaignActions.updateTeamMatchContent.success, (state, { payload }) => {
    const currentContents = state.detail?.teamMatchContents || [];

    const index = currentContents.findIndex(({ id: currentContentId }) => currentContentId === payload.id);

    if (index > -1) {
      let newState: Map<keyof typeof state, any> = fromJS(state);
      newState = newState.setIn(['detail', 'teamMatchContents', index], payload);

      return newState.toJS() as TeamMatchCampaignState;
    }
    return state;
  })
  .handleAction(teamMatchCampaignActions.deleteTeamMatchContent.success, (state, { payload }) => {
    const currentContents = state.detail?.teamMatchContents || [];

    if (currentContents.length > 0) {
      let newState: Map<keyof typeof state, any> = fromJS(state);
      newState = newState.setIn(
        ['detail', 'teamMatchContents'],
        currentContents.filter(({ id: currentContentId }) => `${currentContentId}` !== `${payload}`)
      );

      return newState.toJS() as TeamMatchCampaignState;
    }
    return state;
  });

export default teamMatchCampaign;
