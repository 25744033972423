import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as teamMatchProfileActions from './actions';
import { initialState, TeamMatchProfileState } from './types';

const teamMatchProfile = createReducer<TeamMatchProfileState, RootAction>(initialState)
  .handleAction(teamMatchProfileActions.listTeamMatchProfile.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(teamMatchProfileActions.listTeamMatchProfile.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(teamMatchProfileActions.readTeamMatchProfile.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default teamMatchProfile;
