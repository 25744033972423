import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  ShopModulePageActions as Actions,
  ShopModulePageRequestData,
  ShopModulePageResponseData,
  ShopModulePageFormValues,
  ShopModuleListItem,
  ShopModuleScheduleCreateRequestData,
  ShopModuleScheduleCreateResponseData,
  ShopModuleScheduleFormValues,
  ShopModuleScheduleUpdateRequestData,
  ShopModuleScheduleUpdateResponseData,
} from './types';

export const createShopModulePage = createAsyncAction(
  Actions.CREATE_SHOP_MODULE_PAGE_REQUEST,
  Actions.CREATE_SHOP_MODULE_PAGE_SUCCESS,
  Actions.CREATE_SHOP_MODULE_PAGE_FAILURE
)<FormRequestPayload<ShopModulePageFormValues, ShopModulePageRequestData>, ShopModulePageResponseData, AxiosError>();

export const listShopModulePage = createAsyncAction(
  Actions.LIST_SHOP_MODULE_PAGE_REQUEST,
  Actions.LIST_SHOP_MODULE_PAGE_SUCCESS,
  Actions.LIST_SHOP_MODULE_PAGE_FAILURE
)<ListRequestPayload, ListState<ShopModuleListItem>, AxiosError>();

export const readShopModulePage = createAsyncAction(
  Actions.READ_SHOP_MODULE_PAGE_REQUEST,
  Actions.READ_SHOP_MODULE_PAGE_SUCCESS,
  Actions.READ_SHOP_MODULE_PAGE_FAILURE
)<ReadRequestPayload, any, AxiosError>();

export const updateShopModulePage = createAsyncAction(
  Actions.UPDATE_SHOP_MODULE_PAGE_REQUEST,
  Actions.UPDATE_SHOP_MODULE_PAGE_SUCCESS,
  Actions.UPDATE_SHOP_MODULE_PAGE_FAILURE
)<FormRequestPayload<ShopModulePageFormValues, ShopModulePageRequestData>, ShopModulePageResponseData, AxiosError>();

export const deleteShopModulePage = createAsyncAction(
  Actions.DELETE_SHOP_MODULE_PAGE_REQUEST,
  Actions.DELETE_SHOP_MODULE_PAGE_SUCCESS,
  Actions.DELETE_SHOP_MODULE_PAGE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

// - SCHEDULE -
export const createShopModuleSchedule = createAsyncAction(
  Actions.CREATE_SHOP_MODULE_PAGE_SCHEDULE_REQUEST,
  Actions.CREATE_SHOP_MODULE_PAGE_SCHEDULE_SUCCESS,
  Actions.CREATE_SHOP_MODULE_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopModuleScheduleFormValues, ShopModuleScheduleCreateRequestData>,
  ShopModuleScheduleCreateResponseData,
  AxiosError
>();

export const updateShopModuleSchedule = createAsyncAction(
  Actions.UPDATE_SHOP_MODULE_PAGE_SCHEDULE_REQUEST,
  Actions.UPDATE_SHOP_MODULE_PAGE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SHOP_MODULE_PAGE_SCHEDULE_FAILURE
)<
  FormRequestPayload<ShopModuleScheduleFormValues, ShopModuleScheduleUpdateRequestData>,
  ShopModuleScheduleUpdateResponseData,
  AxiosError
>();

export const deleteShopModuleSchedule = createAsyncAction(
  Actions.DELETE_SHOP_MODULE_PAGE_SCHEDULE_REQUEST,
  Actions.DELETE_SHOP_MODULE_PAGE_SCHEDULE_SUCCESS,
  Actions.DELETE_SHOP_MODULE_PAGE_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
