import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { updateGenderManage } from './actions';
import { updateGenderManageApi } from './api';

const updateGenderManageToastEpic = createToastEpic(updateGenderManage.success, 'Successfully updated');

const updateGenderManageEpic = createUpdateEpic(updateGenderManage, updateGenderManageApi);

export default combineEpics(updateGenderManageToastEpic, updateGenderManageEpic);
