export enum UploadFileListActions {
  UPLOAD_FILE_LIST = 'UPLOAD_FILE_LIST',
  RESET_FILE_LIST = 'RESET_FILE_LIST',
}

export type UploadFileListType = 'avatar';

export interface UploadFileListPayload {
  type: UploadFileListType;
  fileList: string[];
}
export interface UploadFileListState {
  avatar: {
    fileList: string[];
  };
}

export const initialState: UploadFileListState = { avatar: { fileList: [] } };
