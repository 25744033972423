import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from '../types';

import { ItemProvideActions as Actions, ItemProductRequestFormValues } from './types';

export const provideItemProduct = createAsyncAction(
  Actions.PROVIDE_ITEM_PRODUCT_REQUEST,
  Actions.PROVIDE_ITEM_PRODUCT_SUCCESS,
  Actions.PROVIDE_ITEM_PRODUCT_FAILURE
)<FormRequestPayload<ItemProductRequestFormValues>, undefined, AxiosError>();
