import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from '../types';

import {
  ConvertUserKeyActions as Actions,
  ConvertUserIdsToUsernamesRequestData,
  ConvertUserIdsToUsernamesRequestFormValues,
  ConvertUserIdsToUsernamesSuccessData,
  ConvertUsernamesToUserIdsRequestData,
  ConvertUsernamesToUserIdsRequestFormValues,
  ConvertUsernamesToUserIdsSuccessData,
} from './types';

export const convertUsernamesToUserIds = createAsyncAction(
  Actions.CONVERT_USERNAMES_TO_USER_IDS_REQUEST,
  Actions.CONVERT_USERNAMES_TO_USER_IDS_SUCCESS,
  Actions.CONVERT_USERNAMES_TO_USER_IDS_FAILURE
)<
  FormRequestPayload<ConvertUsernamesToUserIdsRequestFormValues, ConvertUsernamesToUserIdsRequestData>,
  ConvertUsernamesToUserIdsSuccessData,
  AxiosError
>();

export const convertUserIdsToUsernames = createAsyncAction(
  Actions.CONVERT_USER_IDS_TO_USERNAMES_REQUEST,
  Actions.CONVERT_USER_IDS_TO_USERNAMES_SUCCESS,
  Actions.CONVERT_USER_IDS_TO_USERNAMES_FAILURE
)<
  FormRequestPayload<ConvertUserIdsToUsernamesRequestFormValues, ConvertUserIdsToUsernamesRequestData>,
  ConvertUserIdsToUsernamesSuccessData,
  AxiosError
>();

export const resetConvertUserKeyResult = createAction(Actions.RESET_CONVERT_USER_KEY_RESULT)();
