import { createDeleteApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  GemSettingCreateRequestData,
  GemSettingCreateRequestFormValues,
  GemSettingCreateSuccessData,
  GemSettingDetail,
  GemSettingListItem,
  GemSettingUpdateRequestData,
  GemSettingUpdateRequestFormValues,
  GemSettingUpdateSuccessData,
} from './types';

export const listGemSettingApi = createListApi<GemSettingListItem>('/gem_shops/settings');
export const createGemSettingApi = createPostApi<
  GemSettingCreateRequestFormValues,
  GemSettingCreateRequestData,
  GemSettingCreateSuccessData
>('/gem_shops/settings');
export const readGemSettingApi = createReadApi<GemSettingDetail>('/gem_shops/settings/:id');
export const updateGemSettingApi = createUpdateApi<
  GemSettingUpdateRequestFormValues,
  GemSettingUpdateRequestData,
  GemSettingUpdateSuccessData
>('/gem_shops/settings/:id');
export const deleteGemSettingApi = createDeleteApi('/gem_shops/settings/:id');
export const refreshGemSettingApi = createPostApi('/gem_shops/refresh');
