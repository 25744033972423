import { ActionType, createReducer } from 'typesafe-actions';

import * as productActions from 'store/product/actions';
import { initialState, ProductState, ProductType } from 'store/product/types';

type Action = ActionType<typeof productActions>;

const product = createReducer<ProductState, Action>(initialState)
  .handleAction(productActions.listItemProduct.request, (state, { payload: { page, pageSize } }) => {
    const newState = { ...state };
    newState[ProductType.ITEM].list.page = page;
    newState[ProductType.ITEM].list.pageSize = pageSize;
    return newState;
  })
  .handleAction(productActions.listGeneralProduct.request, (state, { payload: { page, pageSize } }) => {
    const newState = { ...state };
    newState[ProductType.GENERAL].list.page = page;
    newState[ProductType.GENERAL].list.pageSize = pageSize;
    return newState;
  })
  .handleAction(productActions.listItemProduct.success, (state, { payload }) => {
    const newState = { ...state };
    newState[ProductType.ITEM].list = payload;
    return newState;
  })
  .handleAction(productActions.listGeneralProduct.success, (state, { payload }) => {
    const newState = { ...state };
    newState[ProductType.GENERAL].list = payload;
    return newState;
  });

export default product;
