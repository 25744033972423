import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { withdrawGem } from './actions';
import { gemWithdrawApi } from './api';

const provideGemWithdrawToastEpic = createToastEpic(withdrawGem.success, 'Successfully Withdrawn');

const provideGemWithdrawEpic = createUpdateEpic(withdrawGem, gemWithdrawApi);

export default combineEpics(provideGemWithdrawToastEpic, provideGemWithdrawEpic);
