import { combineEpics } from 'redux-observable';

import { createToastEpic, createUpdateEpic } from 'store/utils';

import { provideItemProduct } from './actions';
import { provideItemProductApi } from './api';

const provideItemProductToastEpic = createToastEpic(provideItemProduct.success, 'Successfully Provided');

const provideItemProductEpic = createUpdateEpic(provideItemProduct, provideItemProductApi);

export default combineEpics(provideItemProductToastEpic, provideItemProductEpic);
