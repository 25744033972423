import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as expressionSegmentActions from './actions';
import { ExpressionSegmentState, initialState } from './types';

// noinspection TypeScriptValidateJSTypes
const expressionSegment = createReducer<ExpressionSegmentState, RootAction>(initialState)
  .handleAction(expressionSegmentActions.listExpressionSegment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(expressionSegmentActions.listExpressionSegment.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(expressionSegmentActions.readExpressionSegment.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default expressionSegment;
