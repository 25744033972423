import React, { CSSProperties } from 'react';

import { CollapseProps } from '@material-ui/core/Collapse';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export enum MenuType {
  DEFAULT = 'defaultMenu',
  COLLAPSE = 'collapseMenu',
}

export interface BaseMenu {
  label: string;
  path: string;
  render?: React.ReactNode;
}

export interface MenuProps extends Omit<BaseMenu, 'path'> {
  type: MenuType;
  IconComponent: (props: SvgIconProps) => JSX.Element;
  path?: string;
  openCollapse?: boolean;
}

export type CollapseMenuItem = BaseMenu;

export interface CollapseMenuProps extends CollapseProps {
  collapseMenus: CollapseMenuItem[];
  style?: CSSProperties;
}

export interface DraggableCollapseMenuProps extends CollapseMenuProps {
  onDragEnd: (result: any) => void;
}
