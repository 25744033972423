import React, { useCallback, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DarkModeIcon from '@material-ui/icons/Brightness2Rounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import ViewCompactRoundedIcon from '@material-ui/icons/ViewCompactRounded';
import ViewStreamRoundedIcon from '@material-ui/icons/ViewStreamRounded';
import LightModeIcon from '@material-ui/icons/WbSunnyRounded';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { WEB_TITLE } from 'constants/routes';
import useIsDarkMode from 'hooks/useIsDarkMode';
import useSignedIn from 'hooks/useSignedIn';
import { layoutActions } from 'store/layout/slice';
import { RootState } from 'store/root/types';
import * as settingActions from 'store/setting/actions';
import { Palette } from 'store/setting/types';
import * as userActions from 'store/user/actions';

import SettingModal from './SettingModal';
import { ENVIRONMENT } from 'constants/env';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    margin: 0,
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  menuButton: { marginRight: theme.spacing(2) },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const DarkModeSwitch = withStyles({
  root: { width: 60 },
  switchBase: { padding: 8 },
})(Switch);

const selector = ({
  layout: {
    drawer: { isOpen },
  },
  setting: { presets, currentPresetIndex },
}: RootState) => ({
  isOpenDrawer: isOpen,
  presets,
  currentPresetIndex,
});

const Header: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { isOpenDrawer, presets, currentPresetIndex } = useSelector(selector);

  const isSignedIn = useSignedIn();
  const isDarkMode = useIsDarkMode();

  const [isOpenSettingModal, setIsOpenSettingModal] = useState(() => {
    const currentPreset = presets[currentPresetIndex];
    return ENVIRONMENT !== 'Production' && !(currentPreset.azarApiUrl && currentPreset.matchingApiUrl);
  });

  const handleClickSignOut = useCallback(() => {
    dispatch(
      layoutActions.makeConfirm({
        title: 'Are you sure you want to log out?',
        onAgree: () => {
          dispatch(userActions.signOut());
          history.push('/logout');
        },
      })
    );
  }, [dispatch, history]);

  const handleChangeDarkMode = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const { checked = false } = e.target;

      dispatch(settingActions.setPalette({ palette: checked ? Palette.DARK : Palette.LIGHT }));
    },
    [dispatch]
  );

  const handleClickOpen = useCallback(() => {
    dispatch(layoutActions.toggleDrawer(!isOpenDrawer));
  }, [dispatch, isOpenDrawer]);

  return (
    <>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleClickOpen}
            edge='start'
            className={classes.menuButton}
          >
            {isOpenDrawer ? <ViewCompactRoundedIcon /> : <ViewStreamRoundedIcon />}
          </IconButton>

          <Box flexGrow={1}>
            <Link to='/'>
              <Typography className={classes.title} variant='h2' display='inline' noWrap>
                {WEB_TITLE}
              </Typography>
            </Link>
          </Box>

          <Tooltip title={isDarkMode ? 'Dark Mode' : 'Light Mode'}>
            <DarkModeSwitch
              aria-label={isDarkMode ? 'Dark Mode' : 'Light Mode'}
              name='swithDarkMode'
              color='default'
              checked={isDarkMode}
              icon={<LightModeIcon />}
              checkedIcon={<DarkModeIcon />}
              onChange={handleChangeDarkMode}
            />
          </Tooltip>

          <Tooltip title='Settings'>
            <IconButton aria-label='Settings' color='inherit' onClick={() => setIsOpenSettingModal(true)}>
              <Badge color='secondary' variant='dot'>
                <SettingsRoundedIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <SettingModal isOpen={isOpenSettingModal} onClose={() => setIsOpenSettingModal(false)} />

          {isSignedIn && (
            <Tooltip title='Sign out'>
              <IconButton aria-label='Sign out' color='inherit' onClick={handleClickSignOut}>
                <ExitToAppRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>

      <Toolbar className={classes.toolbar} />
    </>
  );
};

export default Header;
