import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from '../utils';

import {
  createPushContent,
  createPushContentVariation,
  deletePushContentVariation,
  listPushContent,
  readPushContent,
  refreshPushContent,
  updatePushContent,
  updatePushContentVariation,
  readPushType,
} from './actions';
import {
  createPushContentApi,
  createPushContentVariationApi,
  deletePushContentVariationApi,
  listPushContentApi,
  readPushContentApi,
  refreshPushContentApi,
  updatePushContentApi,
  updatePushContentVariationApi,
  readPushTypeApi,
} from './api';

const createPushContentEpic = createPostEpic(createPushContent, createPushContentApi);
const readPushContentEpic = createReadEpic(readPushContent, readPushContentApi);
const updatePushContentEpic = createUpdateEpic(updatePushContent, updatePushContentApi);
const listPushContentEpic = createListEpic(listPushContent, listPushContentApi);
const refreshPushContentEpic = createPostEpic(refreshPushContent, refreshPushContentApi);

const updatePushContentToastEpic = createToastEpic(updatePushContent.success, 'Successfully saved');

const createPushContentToastEpic = createToastEpic(createPushContent.success, 'Successfully created');

const refreshPushContentToastEpic = createToastEpic(refreshPushContent.success, 'Successfully refreshed');

// - Varation -
const createPushContentVariationEpic = createAsyncEpic(createPushContentVariation, createPushContentVariationApi);
const editPushContentVariationEpic = createAsyncEpic(updatePushContentVariation, updatePushContentVariationApi);
const deletePushContentVariationEpic = createAsyncEpic(deletePushContentVariation, deletePushContentVariationApi);

const createPushContentVariationToastEpic = createToastEpic(createPushContentVariation.success, 'Successfully created');
const editPushContentVariationToastEpic = createToastEpic(updatePushContentVariation.success, 'Successfully saved');
const deletePushContentVariationToastEpic = createToastEpic(deletePushContentVariation.success, 'Successfully deleted');

// Push type
const readPushTypeEpic = createReadEpic(readPushType, readPushTypeApi);

export default combineEpics(
  updatePushContentToastEpic,
  createPushContentToastEpic,
  createPushContentEpic,
  readPushContentEpic,
  updatePushContentEpic,
  listPushContentEpic,
  refreshPushContentEpic,
  refreshPushContentToastEpic,
  // - Variation -
  createPushContentVariationEpic,
  editPushContentVariationEpic,
  deletePushContentVariationEpic,
  createPushContentVariationToastEpic,
  editPushContentVariationToastEpic,
  deletePushContentVariationToastEpic,
  // Push Type
  readPushTypeEpic
);
