import { GemSettingMapEntry } from 'store/gemSettingMapEntry/types';
import { GemSettingProduct } from 'store/gemSettingProduct/types';
import { initialListState, ListState } from 'store/types';

export enum GemSettingActions {
  LIST_GEM_SETTINGS_REQUEST = 'LIST_GEM_SETTINGS#REQUEST',
  LIST_GEM_SETTINGS_SUCCESS = 'LIST_GEM_SETTINGS#SUCCESS',
  LIST_GEM_SETTINGS_FAILURE = 'LIST_GEM_SETTINGS#FAILURE',

  CREATE_GEM_SETTING_REQUEST = 'CREATE_GEM_SETTING#REQUEST',
  CREATE_GEM_SETTING_SUCCESS = 'CREATE_GEM_SETTING#SUCCESS',
  CREATE_GEM_SETTING_FAILURE = 'CREATE_GEM_SETTING#FAILURE',

  READ_GEM_SETTING_REQUEST = 'READ_GEM_SETTING#REQUEST',
  READ_GEM_SETTING_SUCCESS = 'READ_GEM_SETTING#SUCCESS',
  READ_GEM_SETTING_FAILURE = 'READ_GEM_SETTING#FAILURE',

  UPDATE_GEM_SETTING_REQUEST = 'UPDATE_GEM_SETTING#REQUEST',
  UPDATE_GEM_SETTING_SUCCESS = 'UPDATE_GEM_SETTING#SUCCESS',
  UPDATE_GEM_SETTING_FAILURE = 'UPDATE_GEM_SETTING#FAILURE',

  DELETE_GEM_SETTING_REQUEST = 'DELETE_GEM_SETTING#REQUEST',
  DELETE_GEM_SETTING_SUCCESS = 'DELETE_GEM_SETTING#SUCCESS',
  DELETE_GEM_SETTING_FAILURE = 'DELETE_GEM_SETTING#FAILURE',

  SET_GEM_SETTING_PRODUCTS = 'SET_GEM_SETTING_PRODUCTS',

  REFRESH_GEM_SETTING_REQUEST = 'REFRESH_GEM_SETTING#REQUEST',
  REFRESH_GEM_SETTING_SUCCESS = 'REFRESH_GEM_SETTING#SUCCESS',
  REFRESH_GEM_SETTING_FAILURE = 'REFRESH_GEM_SETTING#FAILURE',
}

export const initialState: GemSettingState = {
  list: initialListState,
  detail: undefined,
};

export interface GemSettingListItem {
  id: number;
  name: string;
  gemPromotionTextKey: string;
  enterPopupUrl: string;
  enabled: boolean;
  productCount: number;
  gemShopSettingMapEntries: GemSettingMapEntry[];
  gemShopSettingProducts: GemSettingProduct[];
}

export type GemSettingDetail = GemSettingListItem;

export interface GemSettingState {
  list: ListState<GemSettingListItem>;
  detail?: GemSettingDetail;
}

export interface GemSettingCreateRequestFormValues {
  name: string;
  gemPromotionTextKeyAsString: boolean;
  gemPromotionTextKey: string;
  enterPopupUrl: string;
}

export interface GemSettingCreateRequestData {
  name: string;
  gemPromotionTextKey: string;
  enterPopupUrl: string;
}

export type GemSettingCreateSuccessData = GemSettingDetail;

export interface GemSettingUpdateRequestFormValues {
  name: string;
  gemPromotionTextKeyAsString: boolean;
  gemPromotionTextKey: string;
  enterPopupUrl: string;
}

export interface GemSettingUpdateRequestData {
  name: string;
  gemPromotionTextKey: string;
  enterPopupUrl: string;
}

export type GemSettingUpdateSuccessData = GemSettingDetail;
