import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FlatListRequestPayload, FlatListState, FormRequestPayload } from 'store/types';

import { MatchFilterActions as Actions, MatchFilter } from './types';

export const listMatchFilter = createAsyncAction(
  Actions.LIST_MATCH_FILTERS_REQUEST,
  Actions.LIST_MATCH_FILTERS_SUCCESS,
  Actions.LIST_MATCH_FILTERS_FAILURE
)<FlatListRequestPayload, FlatListState<MatchFilter>, AxiosError>();

export const refreshMatchFilter = createAsyncAction(
  Actions.REFRESH_MATCH_FILTER_REQUEST,
  Actions.REFRESH_MATCH_FILTER_SUCCESS,
  Actions.REFRESH_MATCH_FILTER_SUCCESS
)<FormRequestPayload<{}>, undefined, AxiosError>();
