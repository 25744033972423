export enum GemProductTierScheduleActions {
  CREATE_SCHEDULE_REQUEST = 'CREATE_GEM_PRODUCT_TIER_SCHEDULE#REQUEST',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_GEM_PRODUCT_TIER_SCHEDULE#SUCCESS',
  CREATE_SCHEDULE_FAILURE = 'CREATE_GEM_PRODUCT_TIER_SCHEDULE#FAILURE',

  UPDATE_SCHEDULE_REQUEST = 'UPDATE_GEM_PRODUCT_TIER_SCHEDULE#REQUEST',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_GEM_PRODUCT_TIER_SCHEDULE#SUCCESS',
  UPDATE_SCHEDULE_FAILURE = 'UPDATE_GEM_PRODUCT_TIER_SCHEDULE#FAILURE',

  DELETE_SCHEDULE_REQUEST = 'DELETE_GEM_PRODUCT_TIER_SCHEDULE#REQUEST',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_GEM_PRODUCT_TIER_SCHEDULE#SUCCESS',
  DELETE_SCHEDULE_FAILURE = 'DELETE_GEM_PRODUCT_TIER_SCHEDULE#FAILURE',
}

export enum Platform {
  ALL = 'ALL',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export interface GemProductTierSchedule {
  id: number;
  tierCode: string;
  segmentCode: string;
  startDate: string;
  endDate: string;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
  platform: Platform;
  activated: boolean;
}

export type GemProductTierScheduleCreateRequestData = Omit<GemProductTierSchedule, 'id' | 'activated'>;
export type GemProductTierScheduleCreateSuccessData = GemProductTierSchedule;
export type GemProductTierScheduleUpdateRequestData = Omit<GemProductTierSchedule, 'id' | 'activated'>;
export type GemProductTierScheduleUpdateSuccessData = GemProductTierSchedule;
