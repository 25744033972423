import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  DeleteSuccessPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  BannerSettingActions as Actions,
  BannerSettingCreateRequestData,
  BannerSettingCreateRequestFormValues,
  BannerSettingCreateSuccessData,
  BannerSettingDetail,
  BannerSettingListItem,
  BannerSettingUpdateRequestData,
  BannerSettingUpdateRequestFormValues,
  BannerSettingUpdateSuccessData,
} from './types';

export const listBannerSetting = createAsyncAction(
  Actions.LIST_BANNER_SETTINGS_REQUEST,
  Actions.LIST_BANNER_SETTINGS_SUCCESS,
  Actions.LIST_BANNER_SETTINGS_FAILURE
)<ListRequestPayload, ListState<BannerSettingListItem>, AxiosError>();

export const createBannerSetting = createAsyncAction(
  Actions.CREATE_BANNER_SETTING_REQUEST,
  Actions.CREATE_BANNER_SETTING_SUCCESS,
  Actions.CREATE_BANNER_SETTING_FAILURE
)<
  FormRequestPayload<BannerSettingCreateRequestFormValues, BannerSettingCreateRequestData>,
  BannerSettingCreateSuccessData,
  AxiosError
>();

export const readBannerSetting = createAsyncAction(
  Actions.READ_BANNER_SETTING_REQUEST,
  Actions.READ_BANNER_SETTING_SUCCESS,
  Actions.READ_BANNER_SETTING_FAILURE
)<ReadRequestPayload, BannerSettingDetail, AxiosError>();

export const updateBannerSetting = createAsyncAction(
  Actions.UPDATE_BANNER_SETTING_REQUEST,
  Actions.UPDATE_BANNER_SETTING_SUCCESS,
  Actions.UPDATE_BANNER_SETTING_FAILURE
)<
  FormRequestPayload<BannerSettingUpdateRequestFormValues, BannerSettingUpdateRequestData>,
  BannerSettingUpdateSuccessData,
  AxiosError
>();

export const deleteBannerSetting = createAsyncAction(
  Actions.DELETE_BANNER_SETTING_REQUEST,
  Actions.DELETE_BANNER_SETTING_SUCCESS,
  Actions.DELETE_BANNER_SETTING_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
