import { Grid, Box, Button, TextField } from '@material-ui/core';
import LoadingIndicator from 'components/LoadingIndicator';
import useForm from 'hooks/useForm';
import React, { useMemo } from 'react';
import { useAzarApiNamepsaceListQuery, useMatchingApiNamepsaceListQuery } from './useNamespaceListQuery';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteProps, Autocomplete } from '@material-ui/lab';
import { Control, Controller } from 'react-hook-form';

const useStyles = makeStyles({
  button: {},
  actionContainer: { display: 'flex', justifyContent: 'flex-end', columnGap: 10 },
});

export interface Form {
  azarApiUrl?: string;
  matchingApiUrl?: string;
  environment: string;
}

function ControlledAutocompleteTextField<T>({
  control,
  id,
  name,
  label,
  ...autocompleteProps
}: { control: Control; name: string; id: string; label: string } & Omit<
  AutocompleteProps<T, false, true, false>,
  'renderInput'
>) {
  return (
    <Controller
      control={control}
      name={name}
      render={(fieldProps) => (
        <Autocomplete
          {...fieldProps}
          {...autocompleteProps}
          id={id}
          autoHighlight
          onChange={(e, value) => fieldProps.onChange(value)}
          renderInput={(params) => <TextField {...params} label={label} variant='outlined' fullWidth />}
        />
      )}
    />
  );
}

export default function PresetForm({
  defaultValues,
  index,
  onCancel,
  onSetDefaultValue,
  onSave,
}: {
  defaultValues: Form;
  index: number;
  onSetDefaultValue: () => Form;
  onSave: (value: Form) => void;
  onCancel: () => void;
}) {
  const { control, handleSubmit, reset } = useForm<Form>({ defaultValues });
  const classes = useStyles();
  const azarApiNamespaceListQuery = useAzarApiNamepsaceListQuery();
  const matchingApiNamespaceListQuery = useMatchingApiNamepsaceListQuery();

  const azarApiUrlNamespaceMap = useMemo(
    () => new Map(azarApiNamespaceListQuery.data?.deployments.map(({ namespace, url }) => [url, namespace])),
    [azarApiNamespaceListQuery.data?.deployments]
  );
  const matchingApiUrlNamespaceMap = useMemo(
    () => new Map(matchingApiNamespaceListQuery.data?.deployments.map(({ namespace, url }) => [url, namespace])),
    [matchingApiNamespaceListQuery.data?.deployments]
  );

  if (!azarApiNamespaceListQuery.data || !matchingApiNamespaceListQuery.data) {
    return <LoadingIndicator />;
  }

  return (
    <form onSubmit={onSave && handleSubmit(onSave)}>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <ControlledAutocompleteTextField
            control={control}
            name='azarApiUrl'
            label='Azar API URL'
            options={azarApiNamespaceListQuery.data.deployments.map(({ url }) => url)}
            getOptionLabel={(url) => {
              const namespace = azarApiUrlNamespaceMap.get(url);
              if (!namespace) {
                return 'Invalid Url';
              }
              return namespace;
            }}
            id={`preset-${index}-azarApi-url`}
          />
        </Grid>
        <Grid item>
          <ControlledAutocompleteTextField
            control={control}
            name='matchingApiUrl'
            label='Matching API URL'
            options={matchingApiNamespaceListQuery.data.deployments.map(({ url }) => url)}
            getOptionLabel={(url) => {
              const namespace = matchingApiUrlNamespaceMap.get(url);
              if (!namespace) {
                return 'Invalid Url';
              }
              return namespace;
            }}
            id={`preset-${index}-matchingApi-url`}
          />
        </Grid>
        <Grid item>
          <ControlledAutocompleteTextField
            control={control}
            name='environment'
            label='Environment'
            options={['Local', 'Dev']}
            id={`preset-${index}-environment`}
          />
        </Grid>
        <Grid item>
          <Box className={classes.actionContainer}>
            <Button
              className={classes.button}
              variant='contained'
              color='default'
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              onClick={() => {
                reset(onSetDefaultValue());
              }}
            >
              Default Value
            </Button>
            <Button className={classes.button} variant='contained' color='primary' type='submit'>
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
