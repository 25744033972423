import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic } from 'store/utils';

import { createAbTest, listAbTest, readAbTest, updateAbTest } from './actions';
import { createAbTestApi, listAbTestApi, readAbTestApi, updateAbTestApi } from './api';

const updateAbTestToastEpic = createToastEpic(updateAbTest.success, 'AB Test saved successfully');

const createAbTestEpic = createPostEpic(createAbTest, createAbTestApi);

const updateAbTestEpic = createPostEpic(updateAbTest, updateAbTestApi);

const listAbTestEpic = createListEpic(listAbTest, listAbTestApi);

const readAbTestEpic = createReadEpic(readAbTest, readAbTestApi);

export default combineEpics(updateAbTestToastEpic, createAbTestEpic, updateAbTestEpic, listAbTestEpic, readAbTestEpic);
