import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from '../types';

import { GemProvideActions as Actions, GemPackageRequestFormValues, ProvideGemFormValues } from './types';

export const provideGem = createAsyncAction(
  Actions.PROVIDE_GEM_REQUEST,
  Actions.PROVIDE_GEM_SUCCESS,
  Actions.PROVIDE_GEM_FAILURE
)<FormRequestPayload<ProvideGemFormValues>, undefined, AxiosError>();

export const provideGemPackage = createAsyncAction(
  Actions.PROVIDE_GEM_PACKAGE_REQUEST,
  Actions.PROVIDE_GEM_PACKAGE_SUCCESS,
  Actions.PROVIDE_GEM_PACKAGE_FAILURE
)<FormRequestPayload<GemPackageRequestFormValues>, undefined, AxiosError>();
