import { Promotion } from '../promotionContent/types';

export enum PromotionPublishActions {
  CREATE_REUSABLE_COUPON_REQUEST = 'CREATE_REUSABLE_COUPON#REQUEST',
  CREATE_REUSABLE_COUPON_SUCCESS = 'CREATE_REUSABLE_COUPON#SUCCESS',
  CREATE_REUSABLE_COUPON_FAILURE = 'CREATE_REUSABLE_COUPON#FAILURE',

  CREATE_GROUP_COUPON_REQUEST = 'CREATE_GROUP_COUPON#REQUEST',
  CREATE_GROUP_COUPON_SUCCESS = 'CREATE_GROUP_COUPON#SUCCESS',
  CREATE_GROUP_COUPON_FAILURE = 'CREATE_GROUP_COUPON#FAILURE',

  CREATE_GROUP_SEGMENT_COUPON_REQUEST = 'CREATE_GROUP_SEGMENT_COUPON#REQUEST',
  CREATE_GROUP_SEGMENT_COUPON_SUCCESS = 'CREATE_GROUP_SEGMENT_COUPON#SUCCESS',
  CREATE_GROUP_SEGMENT_COUPON_FAILURE = 'CREATE_GROUP_SEGMENT_COUPON#FAILURE',

  CREATE_COUPON_REQUEST = 'CREATE_COUPON#REQUEST',
  CREATE_COUPON_SUCCESS = 'CREATE_COUPON#SUCCESS',
  CREATE_COUPON_FAILURE = 'CREATE_COUPON#FAILURE',
}

export interface PromotionPublishTypeFormValues {
  type: PromotionPublishType;
}

export enum PromotionPublishType {
  REUSABLE_COUPON = 'REUSABLE_COUPON',
  GROUP_COUPON = 'GROUP_COUPON',
  GROUP_SEGMENT_COUPON = 'GROUP_SEGMENT_COUPON',
  COUPON = 'COUPON',
}

export interface PromotionPublishReusableCouponFormValues {
  inputCode: string;
  promotionContentsId: string;
  segmentCode: string;
  startTime: string;
  expireTime: string;
  description: string;
  limitUseCount: Boolean;
  limitCount: number;
  publishPopup: Boolean;

  promotion?: Promotion[];
}
export type PromotionPublishReusableCouponRequestData = PromotionPublishReusableCouponFormValues;

export interface PromotionPublishGroupCouponFormValues {
  size: number;
  promotionContentsId: string;
  group: string;
  expireTime: string;
  description: string;
  limitUseCount: Boolean;
  limitCount: number;

  promotion?: Promotion[];
}
export type PromotionPublishGroupCouponRequestData = PromotionPublishGroupCouponFormValues;

export interface SegmentCodeToContentsIds {
  id?: number | null;
  segmentCode: string;
  promotionContentsId: string;
}

export interface PromotionPublishGroupSegmentCouponFormValues {
  size: number;
  group: string;
  expireTime: string;
  description: string;
  limitUseCount: Boolean;
  limitCount: number;
  segmentCodeToContentsIds: SegmentCodeToContentsIds[];
}
export type PromotionPublishGroupSegmentCouponRequestData = PromotionPublishGroupSegmentCouponFormValues;

export interface PromotionPublishCouponFormValues {
  size: number;
  promotionContentsId: string;
  segmentCode: string;
  expireTime: string;
  description: string;

  group: string;
  limitUseCount: Boolean;
  limitCount: number;
  promotion?: Promotion[];
}
export type PromotionPublishCouponRequestData = PromotionPublishCouponFormValues;
