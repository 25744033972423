import { VisitorAbTestCriteria } from 'store/visitorAbTestCriteria/types';
import { initialListState, ListState } from 'store/types';

export enum VisitorAbTestActions {
  CREATE_VISITOR_AB_TEST_REQUEST = 'CREATE_VISITOR_AB_TEST#REQUEST',
  CREATE_VISITOR_AB_TEST_SUCCESS = 'CREATE_VISITOR_AB_TEST#SUCCESS',
  CREATE_VISITOR_AB_TEST_FAILURE = 'CREATE_VISITOR_AB_TEST#FAILURE',

  UPDATE_VISITOR_AB_TEST_REQUEST = 'UPDATE_VISITOR_AB_TEST#REQUEST',
  UPDATE_VISITOR_AB_TEST_SUCCESS = 'UPDATE_VISITOR_AB_TEST#SUCCESS',
  UPDATE_VISITOR_AB_TEST_FAILURE = 'UPDATE_VISITOR_AB_TEST#FAILURE',

  LIST_VISITOR_AB_TESTS_REQUEST = 'LIST_VISITOR_AB_TESTS#REQUEST',
  LIST_VISITOR_AB_TESTS_SUCCESS = 'LIST_VISITOR_AB_TESTS#SUCCESS',
  LIST_VISITOR_AB_TESTS_FAILURE = 'LIST_VISITOR_AB_TESTS#FAILURE',

  READ_VISITOR_AB_TEST_REQUEST = 'READ_VISITOR_AB_TEST#REQUEST',
  READ_VISITOR_AB_TEST_SUCCESS = 'READ_VISITOR_AB_TEST#SUCCESS',
  READ_VISITOR_AB_TEST_FAILURE = 'READ_VISITOR_AB_TEST#FAILURE',
}

export const initialState: VisitorAbTestState = {
  list: initialListState,
  detail: undefined,
};

export interface VisitorAbTestState {
  list: ListState<VisitorAbTestListItem>;
  detail?: VisitorAbTestDetail;
}

export interface VisitorAbTest {
  testName: string;
  startDate: string;
  endDate: string;
  hashModularBasis: number;
  enabled: boolean;
  dateCreated: string;
  segmentCode?: string;
}

export interface VisitorAbTestListItem extends VisitorAbTest {}

export interface VisitorAbTestDetail extends VisitorAbTest {
  criteria: VisitorAbTestCriteria[];
}

export interface VisitorAbTestFormValues {
  testName: string;
  startDate: string;
  endDate: string;
  hashModularBasis: number;
  dateCreated: string;
  enabled: boolean;
  segmentCode?: string;
}

export type VisitorAbTestRequestData = VisitorAbTest;

export type VisitorAbTestResponseData = VisitorAbTest;
