import { createFlatListApi, createListApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  DecoCategory,
  ItemCategory,
  ItemCategoryCreateRequestData,
  ItemCategoryCreateResponseData,
  ItemCategoryUpdateRequestData,
  ItemCategoryUpdateResponseData,
} from './types';

const ITEM_CATEGORY_PATH = '/item_categories';

export const createItemCategoryApi = createPostApi<ItemCategoryCreateRequestData, ItemCategoryCreateResponseData>(
  ITEM_CATEGORY_PATH
);
export const updateItemCategoryApi = createUpdateApi<ItemCategoryUpdateRequestData, ItemCategoryUpdateResponseData>(
  ITEM_CATEGORY_PATH
);
export const getItemCategoryApi = createListApi<ItemCategory>(ITEM_CATEGORY_PATH);

export const getDecoCategoryApi = createFlatListApi<DecoCategory>('/deco_items/categories');
