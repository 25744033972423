export enum PunishActions {
  // 신고 API
  CREATE_PUNISH_USER_SUCCESS = 'CREATE_PUNISH_USER#SUCCESS',
  CREATE_PUNISH_USER_FAILURE = 'CREATE_PUNISH_USER#FAILURE',
  CREATE_PUNISH_USER_REQUEST = 'CREATE_PUNISH_USER#REQUEST',

  RESET_IDENTIFY_USER = 'RESET_IDENTIFY_USER',

  INDENTIFY_USER_BY_USERID_REQUEST = 'INDENTIFY_USER_BY_USERID#REQUEST',
  INDENTIFY_USER_BY_USERID_SUCCESS = 'INDENTIFY_USER_BY_USERID#SUCCESS',
  INDENTIFY_USER_BY_USERID_FAILURE = 'INDENTIFY_USER_BY_USERID#FAILURE',

  IDENTIFY_USER_BY_USERNAME_REQUEST = 'IDENTIFY_USER_BY_USERNAME#REQUEST',
  IDENTIFY_USER_BY_USERNAME_SUCCESS = 'IDENTIFY_USER_BY_USERNAME#SUCCESS',
  IDENTIFY_USER_BY_USERNAME_FAILURE = 'IDENTIFY_USER_BY_USERNAME#FAILURE',
}

export enum PUNISHType {
  USER_THREAT = 'USER_THREAT',
  SEXUAL_COERCION = 'SEXUAL_COERCION',
  ILLEGAL_USE = 'ILLEGAL_USE',
  IMPERSONATION = 'IMPERSONATION',
  UNDERAGE_USER = 'UNDERAGE_USER',
  ADVERTISING = 'ADVERTISING',
  SEX_BUYER_OR_SELLER = 'SEX_BUYER_OR_SELLER',
  FINANCIAL_CRIME_LOSS_REPORTED = 'FINANCIAL_CRIME_LOSS_REPORTED',
  BOTS_AND_SPAM = 'BOTS_AND_SPAM',
  PREVIOUSLY_BANNED = 'PREVIOUSLY_BANNED',
  BACKGROUND_CHECK_VIOLATION = 'BACKGROUND_CHECK_VIOLATION',
  DANGEROUS_PERSON_AND_ORG = 'DANGEROUS_PERSON_AND_ORG',
  INAPPROPRIATE_TO_MINOR = 'INAPPROPRIATE_TO_MINOR',
  SEXUAL_VIOLENCE = 'SEXUAL_VIOLENCE',
  PHYSICAL_HARM = 'PHYSICAL_HARM',
  SAFETY_RISK = 'SAFETY_RISK',
  CORPORATE_THREAT = 'CORPORATE_THREAT',
  HATEFUL_SPEECH = 'HATEFUL_SPEECH',
  HARASSMENT = 'HARASSMENT',
  FINANCIAL_CRIME_NO_LOSS_REPORTED = 'FINANCIAL_CRIME_NO_LOSS_REPORTED',
  ACCOUNT_TAKEOVER = 'ACCOUNT_TAKEOVER',
  FALSE_REPORTING = 'FALSE_REPORTING',
  VIOLENT_CONTENT = 'VIOLENT_CONTENT',
  SEXUAL_CONTENT = 'SEXUAL_CONTENT',
  NON_SAFETY_TOS_VIOLATION = 'NON_SAFETY_TOS_VIOLATION',
  TEST = 'TEST',
}
export const PUNISH_TYPE: PUNISHType[] = [
  PUNISHType.USER_THREAT,
  PUNISHType.SEXUAL_COERCION,
  PUNISHType.ILLEGAL_USE,
  PUNISHType.IMPERSONATION,
  PUNISHType.UNDERAGE_USER,
  PUNISHType.ADVERTISING,
  PUNISHType.SEX_BUYER_OR_SELLER,
  PUNISHType.FINANCIAL_CRIME_LOSS_REPORTED,
  PUNISHType.BOTS_AND_SPAM,
  PUNISHType.PREVIOUSLY_BANNED,
  PUNISHType.BACKGROUND_CHECK_VIOLATION,
  PUNISHType.DANGEROUS_PERSON_AND_ORG,
  PUNISHType.INAPPROPRIATE_TO_MINOR,
  PUNISHType.SEXUAL_VIOLENCE,
  PUNISHType.PHYSICAL_HARM,
  PUNISHType.SAFETY_RISK,
  PUNISHType.CORPORATE_THREAT,
  PUNISHType.HATEFUL_SPEECH,
  PUNISHType.HARASSMENT,
  PUNISHType.FINANCIAL_CRIME_NO_LOSS_REPORTED,
  PUNISHType.ACCOUNT_TAKEOVER,
  PUNISHType.FALSE_REPORTING,
  PUNISHType.VIOLENT_CONTENT,
  PUNISHType.SEXUAL_CONTENT,
  PUNISHType.NON_SAFETY_TOS_VIOLATION,
  PUNISHType.TEST,
];

export interface PunishRequest {
  userId: string;
  reason: PUNISHType;
}

export interface IdentifyUserByUserRequest {
  id: string;
}

export interface CreatePunishmentResponse {
  userId: number;
  punishmentIds: string;
}

export interface IdentifiedUserInfo {
  id: string;
  simpleName: string;
  username: string;
  userLevel: string;
  gender: string;
  loginCount: number;
  birthday: string;
  normalGemBalance: number;
  bonusGemBalance: number;
  dateCreated: string;
}

export interface PunishHistoryItem {
  id: number;
  datePunished: string;
  punishUser: string;
  punishedUserId: number;
  punishedUserName: string;
  reason: PUNISHType;
}

export interface PunishState {
  identifiedUserInfo?: IdentifiedUserInfo;
}

export const initialState: PunishState = {
  identifiedUserInfo: undefined,
};
