import {
  NoticePopupCarouselCreateRequestData,
  NoticePopupCarouselCreateSuccessData,
  NoticePopupCarouselUpdateRequestData,
  NoticePopupCarouselUpdateSuccessData,
} from 'store/noticePopupCarousel/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createNoticePopupCarouselApi = createPostApi<
  NoticePopupCarouselCreateRequestData,
  NoticePopupCarouselCreateSuccessData
>('/notice_popups/carousels');

export const updateNoticePopupCarouselApi = createUpdateApi<
  NoticePopupCarouselUpdateRequestData,
  NoticePopupCarouselUpdateSuccessData
>('/notice_popups/carousels/:id');

export const deleteNoticePopupCarouselApi = createDeleteApi('/notice_popups/carousels/:id');
