import { createListApi, createReadApi, createPostApi, createUpdateApi, createDeleteApi } from 'store/utils';

import {
  DownloadableContentData,
  DownloadableContentCreateRequestData,
  DownloadableContentCreateSuccessData,
} from './types';

export const listDownloadableContentApi = createListApi<DownloadableContentData>('/downloadable_contents');
export const readDownloadableContentApi = createReadApi<DownloadableContentData>('/downloadable_contents/:id');

export const createDownloadableContentApi = createPostApi<
  DownloadableContentCreateRequestData,
  DownloadableContentCreateSuccessData
>('/downloadable_contents');

export const updateDownloadableContentApi = createUpdateApi<
  DownloadableContentCreateRequestData,
  DownloadableContentCreateSuccessData
>('/downloadable_contents');

export const deleteDownloadableContentApi = createDeleteApi('/downloadable_contents/:id');
