import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as matataInvalidatedPurchaseByReceiverActions from './actions';
import { initialState, MatataInvalidatedPurchaseByReceiverState } from './types';

const matataInvalidatedPurchaseByReceiver = createReducer<MatataInvalidatedPurchaseByReceiverState, RootAction>(
  initialState
)
  .handleAction(
    matataInvalidatedPurchaseByReceiverActions.readMatataInvalidatedPurchaseByReceiver.success,
    (state, { payload }) => ({
      ...state,
      detail: payload,
    })
  )
  .handleAction(
    matataInvalidatedPurchaseByReceiverActions.listMatataInvalidatedPurchaseByReceiver.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(
    matataInvalidatedPurchaseByReceiverActions.listMatataInvalidatedPurchaseByReceiver.success,
    (state, { payload }) => ({
      ...state,
      list: payload,
    })
  );

export default matataInvalidatedPurchaseByReceiver;
