import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import {
  PromotionPublishHistoryActions as Actions,
  PromotionPublishHistoryDetail,
  PromotionPublishHistoryFormValues,
  PromotionPublishHistoryListItem,
  PromotionPublishHistoryUpdateRequestData,
  PromotionPublishHistoryUpdateResponseData,
} from './types';

export const listPromotionPublishHistory = createAsyncAction(
  Actions.LIST_PROMOTION_CODE_PUBLISHING_HISTORY_REQUEST,
  Actions.LIST_PROMOTION_CODE_PUBLISHING_HISTORY_SUCCESS,
  Actions.LIST_PROMOTION_CODE_PUBLISHING_HISTORY_FAILURE
)<ListRequestPayload, ListState<PromotionPublishHistoryListItem>, AxiosError>();

export const readPromotionPublishHistory = createAsyncAction(
  Actions.READ_PROMOTION_CODE_PUBLISHING_HISTORY_REQUEST,
  Actions.READ_PROMOTION_CODE_PUBLISHING_HISTORY_SUCCESS,
  Actions.READ_PROMOTION_CODE_PUBLISHING_HISTORY_FAILURE
)<ReadRequestPayload, PromotionPublishHistoryDetail, AxiosError>();

export const updatePromotionPublishHistory = createAsyncAction(
  Actions.UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY_REQUEST,
  Actions.UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY_SUCCESS,
  Actions.UPDATE_PROMOTION_CODE_PUBLISHING_HISTORY_FAILURE
)<
  FormRequestPayload<PromotionPublishHistoryFormValues, PromotionPublishHistoryUpdateRequestData>,
  PromotionPublishHistoryUpdateResponseData,
  AxiosError
>();
