import { MlConfigModelSegment } from 'store/mlConfigModelSegment/types';
import { initialListState, ListState } from 'store/types';

export enum MlConfigModelActions {
  LIST_ML_CONFIG_MODEL_REQUEST = 'LIST_ML_CONFIG_MODEL#REQUEST',
  LIST_ML_CONFIG_MODEL_SUCCESS = 'LIST_ML_CONFIG_MODEL#SUCCESS',
  LIST_ML_CONFIG_MODEL_FAILURE = 'LIST_ML_CONFIG_MODEL#FAILURE',

  CREATE_ML_CONFIG_MODEL_REQUEST = 'CREATE_ML_CONFIG_MODEL#REQUEST',
  CREATE_ML_CONFIG_MODEL_SUCCESS = 'CREATE_ML_CONFIG_MODEL#SUCCESS',
  CREATE_ML_CONFIG_MODEL_FAILURE = 'CREATE_ML_CONFIG_MODEL#FAILURE',

  READ_ML_CONFIG_MODEL_REQUEST = 'READ_ML_CONFIG_MODEL#REQUEST',
  READ_ML_CONFIG_MODEL_SUCCESS = 'READ_ML_CONFIG_MODEL#SUCCESS',
  READ_ML_CONFIG_MODEL_FAILURE = 'READ_ML_CONFIG_MODEL#FAILURE',

  UPDATE_ML_CONFIG_MODEL_REQUEST = 'UPDATE_ML_CONFIG_MODEL#REQUEST',
  UPDATE_ML_CONFIG_MODEL_SUCCESS = 'UPDATE_ML_CONFIG_MODEL#SUCCESS',
  UPDATE_ML_CONFIG_MODEL_FAILURE = 'UPDATE_ML_CONFIG_MODEL#FAILURE',
}

export const initialState: MlConfigModelState = {
  list: initialListState,
  detail: undefined,
};

export interface MlConfigModelDetail {
  id: number;
  modelId: string;
  modelVersion: string;
  type: string;
  intervalMs: number;
  maxLatencyLimit: number;
  maxRun?: number;
  segments: MlConfigModelSegment[];
}
export interface StringLocalizationFormValues {
  headerTitleAsString: boolean;
}

export type MlConfigModelListItem = Omit<MlConfigModelDetail, 'segments'>;

export interface MlConfigModelState {
  list: ListState<MlConfigModelListItem>;
  detail?: MlConfigModelDetail;
}

export type MlConfigModelCreateRequestFormValues = Omit<MlConfigModelListItem, 'id'>;
export type MlConfigModelCreateRequestData = Omit<MlConfigModelListItem, 'id'>;
export type MlConfigModelCreateSuccessData = MlConfigModelDetail;
export type MlConfigModelUpdateRequestFormValues = MlConfigModelDetail;
export type MlConfigModelUpdateRequestData = MlConfigModelDetail;
export type MlConfigModelUpdateSuccessData = MlConfigModelDetail;
