/**
 * https://www.notion.so/hpcnt/Emoji-API-a3c268784805400d905766eeac1afca8#f659307dce364db1872e26fe202f5ac8
 */

import { createPostApi, createReadApi } from 'store/utils';

import { IdentifiedUserInfo, PunishRequest } from './types';

export const identifyUserByUserIDApi = createReadApi<IdentifiedUserInfo>(
  '/monitoring-workloads/punish/identify/userId/:id'
);
export const identifyUserByUsernameApi = createReadApi<IdentifiedUserInfo>(
  '/monitoring-workloads/punish/identify/username/:id'
);

export const punishByUserIdApi = createPostApi<PunishRequest, undefined>('/monitoring-workloads/punish');
