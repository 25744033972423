import { initialListState, ListRequestPayload, ListState } from 'store/types';

export enum AgentHistoryActions {
  LIST_AGENT_HISTORY_REQUEST = 'LIST_AGENT_HISTORY#REQUEST',
  LIST_AGENT_HISTORY_SUCCESS = 'LIST_AGENT_HISTORY#SUCCESS',
  LIST_AGENT_HISTORY_FAILURE = 'LIST_AGENT_HISTORY#FAILURE',
}

export interface History {
  agentId: string;
  agentName: string;
  createdAt: string;
  id: number | string;
  requester: string;
  type: string;
}

export interface AgentHistoryState {
  list: ListState<AgentHistoryListItem>;
}

export type AgentHistoryListItem = History;
export type AgentHistoryRequestPayload = ListRequestPayload;
export type AgentHistoryResponseData = ListState<AgentHistoryListItem>;

export const initialAgentHistoryState: AgentHistoryState = { list: initialListState };
