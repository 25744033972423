import { WebDecoSettingEffect } from 'store/webDecoSettingEffect/types';
import { WebDecoSettingSchedule as BaseWebDecoSettingSchedule } from 'store/webDecoSettingSchedule/types';
import { initialListState, ListState } from 'store/types';

export const WEB_DECO_SETTING_API_PREFIX = '/v1/deco-settings';

export enum WebDecoSettingActions {
  LIST_WEB_DECO_SETTINGS_REQUEST = 'LIST_WEB_DECO_SETTINGS#REQUEST',
  LIST_WEB_DECO_SETTINGS_SUCCESS = 'LIST_WEB_DECO_SETTINGS#SUCCESS',
  LIST_WEB_DECO_SETTINGS_FAILURE = 'LIST_WEB_DECO_SETTINGS#FAILURE',

  READ_WEB_DECO_SETTING_REQUEST = 'READ_WEB_DECO_SETTING#REQUEST',
  READ_WEB_DECO_SETTING_FAILURE = 'READ_WEB_DECO_SETTING#FAILURE',
  READ_WEB_DECO_SETTING_SUCCESS = 'READ_WEB_DECO_SETTING#SUCCESS',

  CREATE_WEB_DECO_SETTING_REQUEST = 'CREATE_WEB_DECO_SETTING#REQUEST',
  CREATE_WEB_DECO_SETTING_SUCCESS = 'CREATE_WEB_DECO_SETTING#SUCCESS',
  CREATE_WEB_DECO_SETTING_FAILURE = 'CREATE_WEB_DECO_SETTING#FAILURE',

  UPDATE_WEB_DECO_SETTING_REQUEST = 'UPDATE_WEB_DECO_SETTING#REQUEST',
  UPDATE_WEB_DECO_SETTING_SUCCESS = 'UPDATE_WEB_DECO_SETTING#SUCCESS',
  UPDATE_WEB_DECO_SETTING_FAILURE = 'UPDATE_WEB_DECO_SETTING#FAILURE',

  DELETE_WEB_DECO_SETTING_REQUEST = 'DELETE_WEB_DECO_SETTING#REQUEST',
  DELETE_WEB_DECO_SETTING_SUCCESS = 'DELETE_WEB_DECO_SETTING#SUCCESS',
  DELETE_WEB_DECO_SETTING_FAILURE = 'DELETE_WEB_DECO_SETTING#FAILURE',

  REFRESH_WEB_DECO_SETTING_REQUEST = 'REFRESH_WEB_DECO_SETTING#REQUEST',
  REFRESH_WEB_DECO_SETTING_SUCCESS = 'REFRESH_WEB_DECO_SETTING#SUCCESS',
  REFRESH_WEB_DECO_SETTING_FAILURE = 'REFRESH_WEB_DECO_SETTING#FAILURE',
}

export enum WebDecoEffectGroupId {
  DECO_WEB_BEAUTY = 'DECO_WEB_BEAUTY',
  DECO_WEB_BACKGROUND = 'DECO_WEB_BACKGROUND',
  DECO_WEB_STYLE = 'DECO_WEB_STYLE',
  DECO_WEB_FILTER = 'DECO_WEB_FILTER',
}

export const initialState: WebDecoSettingState = {
  list: initialListState,
  detail: undefined,
};

export interface WebDecoSettingState {
  list: ListState<WebDecoSettingListItem>;
  detail?: WebDecoSettingDetail;
}

export type IndexableType<K extends string, V> = {
  [key in K]: V;
};

export type WebDecoSettingSchedule = BaseWebDecoSettingSchedule;

export type WebDecoSettingEffectCount = IndexableType<WebDecoEffectGroupId, number>;

export interface WebDecoSetting {
  id: number;
  name: string;
  effects: WebDecoSettingEffectMap;
  schedules: WebDecoSettingSchedule[];
  effectCount: WebDecoSettingEffectCount;
}

export interface WebDecoSettingListItem extends Omit<WebDecoSetting, 'effectCount'> {
  stickerCount: number;
  maskCount: number;
  backgroundCount: number;
  blurCount: number;
}

export type WebDecoSettingEffectMap = IndexableType<WebDecoEffectGroupId, WebDecoSettingEffect[]>;

export type WebDecoSettingDetail = WebDecoSetting;

export interface WebDecoSettingFormValues {
  name: string;
}

export type WebDecoSettingCreateRequestData = WebDecoSettingFormValues;

export type WebDecoSettingCreateResponseData = Pick<WebDecoSetting, 'id' | 'name'>;

export type WebDecoSettingUpdateRequestData = WebDecoSettingCreateRequestData;

export type WebDecoSettingUpdateResponseData = WebDecoSettingCreateResponseData;
