import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as queryString from 'querystring';
import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { ActionCreator, ActionType, isActionOf } from 'typesafe-actions';

import { layoutActions } from 'store/layout/slice';
import { ToastType } from 'store/layout/types';
import { RootAction, RootActionTypes, RootState } from 'store/root/types';
import { azarApiRequest } from 'utils/axios';

import {
  DeleteBodyRequestPayload,
  DeleteBodySuccessPayload,
  DeleteRequestPayload,
  DeleteParamRequestPayload,
  DeleteSuccessPayload,
  FlatListRequestPayload,
  FlatListState,
  FormRequestPayload,
  FormRequestParamPayload,
  ListRequestPayload,
  ListState,
  PayloadMeta,
  ReadRequestPayload,
} from './types';
import { refreshJWT } from './user/actions';
import { UserActions } from './user/types';

interface AsyncActionSet {
  request: any;
  success: any;
  failure: any;
}

export type RequestOption = Omit<AxiosRequestConfig, 'url'>;

const defaultRequestOption: RequestOption = {};

export interface ListApiResponseOption<ListItem, ResponseData = {}> {
  parsefn?: (data: ResponseData[]) => ListItem[];
}

interface ExtraOption {
  apiRequest?: AxiosInstance;
}

export type ListApi<Item> = (requestPayload: ListRequestPayload, meta?: any) => Promise<PayloadMeta<ListState<Item>>>;

export const createListApi =
  <ListItem, ResponseData = {}>(
    path: string,
    requestOption?: RequestOption,
    responseOption?: ListApiResponseOption<ListItem, ResponseData>,
    extraOption?: ExtraOption
  ): ListApi<ListItem> =>
  async ({ page, pageSize, ordering, search, extraQuery, pathGenerator }) => {
    let defaultQuery: queryString.ParsedUrlQueryInput = { page, pageSize };
    if (ordering) {
      defaultQuery.ordering = ordering;
    }
    if (search) {
      defaultQuery = { ...defaultQuery, ...search, ...extraQuery };
    }

    const qs = queryString.stringify(defaultQuery);

    const { data } = await (extraOption?.apiRequest || azarApiRequest)
      .get<{ totalCount: number; data: ListItem[] | ResponseData[] }>(
        `${pathGenerator ? pathGenerator(path) : path}?${qs}`,
        requestOption
      )
      .then((res) => ({
        ...res,
        data: {
          ...res.data,
          data: responseOption?.parsefn
            ? responseOption.parsefn(res.data.data as ResponseData[])
            : (res.data.data as ListItem[]),
        },
      }));

    return {
      payload: {
        ...data,
        page,
        pageSize,
      },
    };
  };

export type FlatListApi<Item> = (
  requestPayload: FlatListRequestPayload,
  meta?: any
) => Promise<PayloadMeta<FlatListState<Item>>>;

export const createFlatListApi =
  <ListItem, ResponseData = {}>(
    path: string,
    requestOption?: RequestOption,
    responseOption?: ListApiResponseOption<ListItem, ResponseData>,
    extraOption?: ExtraOption
  ): FlatListApi<ListItem> =>
  async ({ ordering, search, extraQuery, pathGenerator }) => {
    let defaultQuery: queryString.ParsedUrlQueryInput = {};
    if (ordering) {
      defaultQuery.ordering = ordering;
    }
    if (search) {
      defaultQuery = { ...defaultQuery, ...search, ...extraQuery };
    }

    const qs = queryString.stringify(defaultQuery);

    const { data } = await (extraOption?.apiRequest || azarApiRequest)
      .get<ListItem[] | ResponseData[]>(`${pathGenerator ? pathGenerator(path) : path}${qs && `?${qs}`}`, requestOption)
      .then((res) => ({
        ...res,
        data: responseOption?.parsefn ? responseOption.parsefn(res.data as ResponseData[]) : (res.data as ListItem[]),
      }));

    return { payload: data };
  };

export type FormApi<F, R, S> = (payload: FormRequestPayload<F, R>, meta?: any) => Promise<PayloadMeta<S>>;

export const createFormApi: {
  <Req, Res>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): FormApi<Req, Req, Res>;
  <Form, Req, Res>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): FormApi<Form, Req, Res>;
} =
  <Form, Req, Res>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): FormApi<Form, Req, Res> =>
  async ({ data, form, pathGenerator, onSuccess, onFailure }) => {
    try {
      const response = await (extraOption?.apiRequest || azarApiRequest)({
        url: pathGenerator ? pathGenerator(path, data) : path,
        data,
        ...(requestOption || defaultRequestOption),
      });
      if (onSuccess) {
        onSuccess(data, response.data, form);
      }
      return { payload: response.data };
    } catch (e) {
      if (onFailure) {
        onFailure(e as Error);
      }
      throw e;
    }
  };

export type PostApi<F, R, S = undefined> = (
  postPayload: FormRequestPayload<F, R>,
  meta?: any
) => Promise<PayloadMeta<S>>;

export const createPostApi: {
  <Req, Res = undefined>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): PostApi<
    Req,
    Req,
    Res
  >;
  <Form, Req, Res>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): PostApi<Form, Req, Res>;
} =
  <Form, Req, Res>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): PostApi<Form, Req, Res> =>
  async ({ data, form, pathGenerator, onSuccess, onFailure }) => {
    try {
      const response = await (extraOption?.apiRequest || azarApiRequest).post<Res>(
        pathGenerator ? pathGenerator(path, data) : path,
        data,
        requestOption
      );
      if (onSuccess) {
        onSuccess(data, response.data, form);
      }
      return { payload: response.data };
    } catch (e) {
      if (onFailure) {
        onFailure(e as Error);
      }
      throw e;
    }
  };

const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};
export const createBulkApi: {
  <Req, Res>(path: string, chunkSize: number, method?: 'post' | 'put', extraOption?: ExtraOption): PostApi<
    Req,
    Req[],
    Res[]
  >;
  <Form, Req, Res>(path: string, chunkSize: number, method?: 'post' | 'put', extraOption?: ExtraOption): PostApi<
    Form,
    Req[],
    Res[]
  >;
} =
  <Form, Req, Res>(
    path: string,
    chunkSize: number,
    method: 'post' | 'put' = 'post',
    extraOption?: ExtraOption
  ): PostApi<Form, Req[], Res[]> =>
  async ({ data, form, pathGenerator, onSuccess, onFailure }) => {
    try {
      const chunks = chunkArray(data, chunkSize);
      const responses: AxiosResponse<Res>[] = [];
      for (let i = 0; i < chunks.length; i += 1) {
        responses.push(
          await (extraOption?.apiRequest || azarApiRequest)({
            method,
            url: pathGenerator ? pathGenerator(path, data) : path,
            data: chunks[i],
          })
        );
      }
      const responsesData = responses.map((response) => response.data);
      if (onSuccess) {
        onSuccess(data, responsesData, form);
      }
      return { payload: responsesData };
    } catch (e) {
      if (onFailure) {
        onFailure(e as Error);
      }
      throw e;
    }
  };

export type ReadApi<Detail> = (readPayload: ReadRequestPayload, meta?: any) => Promise<PayloadMeta<Detail>>;
export const createReadApi =
  <Detail>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): ReadApi<Detail> =>
  async ({ id, pathGenerator } = { id: '', pathGenerator: undefined }) => {
    const response = await (extraOption?.apiRequest || azarApiRequest).get<Detail>(
      pathGenerator ? pathGenerator(path) : path.replace(':id', id),
      requestOption
    );

    return { payload: response.data };
  };

export type UpdateApi<F, R, S> = (updatePayload: FormRequestPayload<F, R>, meta?: any) => Promise<PayloadMeta<S>>;
export const createUpdateApi: {
  <R, S>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): UpdateApi<R, R, S>;
  <F, R, S>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): UpdateApi<F, R, S>;
} =
  <F, R, S>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): UpdateApi<F, R, S> =>
  async ({ id, data, form, pathGenerator, onSuccess, onFailure }) => {
    try {
      const response = await (extraOption?.apiRequest || azarApiRequest).put<S>(
        pathGenerator ? pathGenerator(path, data) : path.replace(':id', id || 'undefined'),
        data,
        requestOption
      );
      if (onSuccess) {
        onSuccess(data, response.data, form);
      }
      return { payload: response.data };
    } catch (e) {
      if (onFailure) {
        onFailure(e as Error);
      }
      throw e;
    }
  };

export type UpdateParamApi<F, R, S> = (
  updatePayload: FormRequestParamPayload<F, R>,
  meta?: any
) => Promise<PayloadMeta<S>>;
export const createUpdateParamApi: {
  <R, S>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): UpdateParamApi<R, R, S>;
  <F, R, S>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): UpdateParamApi<F, R, S>;
} =
  <F, R, S>(path: string, requestOption?: RequestOption, extraOption?: ExtraOption): UpdateParamApi<F, R, S> =>
  async ({ id, data, form, params, pathGenerator, onSuccess, onFailure }) => {
    try {
      const response = await (extraOption?.apiRequest || azarApiRequest).put<S>(
        pathGenerator ? pathGenerator(path, data) : path.replace(':id', id || 'undefined'),
        data,
        {
          ...requestOption,
          params: params,
        }
      );
      if (onSuccess) {
        onSuccess(data, response.data, form);
      }
      return { payload: response.data };
    } catch (e) {
      if (onFailure) {
        onFailure(e as Error);
      }
      throw e;
    }
  };

export type DeleteApi = (deletePayload: DeleteRequestPayload, meta?: any) => Promise<PayloadMeta<DeleteSuccessPayload>>;

export const createDeleteApi =
  (path: string, requestOption?: RequestOption, extraOption?: ExtraOption): DeleteApi =>
  async ({ id, pathGenerator, onSuccess }) => {
    await (extraOption?.apiRequest || azarApiRequest).delete(
      pathGenerator ? pathGenerator(path) : path.replace(':id', id),
      requestOption
    );
    if (onSuccess) {
      onSuccess(id);
    }
    return { payload: id };
  };

export type DeleteParamApi = (
  deletePayload: DeleteParamRequestPayload,
  meta?: any
) => Promise<PayloadMeta<DeleteSuccessPayload>>;

export const createDeleteParamApi =
  (path: string, requestOption?: RequestOption, extraOption?: ExtraOption): DeleteParamApi =>
  async ({ id, pathGenerator, params, onSuccess }) => {
    await (extraOption?.apiRequest || azarApiRequest).delete(
      pathGenerator ? pathGenerator(path) : path.replace(':id', id),
      {
        ...requestOption,
        params: params,
      }
    );
    if (onSuccess) {
      onSuccess(id);
    }
    return { payload: id };
  };

export type DeleteBodyApi = (
  deletePayload: DeleteBodyRequestPayload,
  meta?: any
) => Promise<PayloadMeta<DeleteBodySuccessPayload>>;

export const createDeleteBodyApi =
  (path: string): DeleteBodyApi =>
  async ({ data, pathGenerator, onSuccess }, extraOption?: ExtraOption) => {
    await (extraOption?.apiRequest || azarApiRequest).delete(pathGenerator ? pathGenerator(path) : path, { data });

    if (onSuccess) {
      onSuccess(data);
    }
    return { payload: data };
  };

export const createAsyncEpic = <ActionOutput extends RootAction = RootAction>(
  asyncAction: AsyncActionSet,
  asyncApi: (payload: any, meta?: any) => Promise<PayloadMeta<any, any>>
) => {
  const asyncEpic: Epic<RootAction, ActionOutput, RootState> = (action$) =>
    action$.pipe(
      filter(isActionOf(asyncAction.request)),
      exhaustMap((action) =>
        from(asyncApi(action.payload, action.meta)).pipe(
          map((response) => asyncAction.success(response.payload, action.meta)),
          catchError((e) => {
            if (e?.response?.status === 401 && action.type !== UserActions.REFRESH_JWT_REQUEST) {
              return [asyncAction.failure(e, action.meta), refreshJWT.request({ action })];
            }
            const message =
              e?.response?.data?.message || // dev 1
              JSON.stringify(e?.response?.data) || // dev 2
              e?.message || // dev 1 && dev 2
              'Server error';

            if (e?.response?.data?.code == 'argument.redirect') {
              window.location.href = e?.response?.data?.message;
              return [];
            }

            return [
              layoutActions.makeToast({
                type: ToastType.error,
                message,
              }),
              asyncAction.failure(e, action.meta),
            ];
          })
        )
      )
    );

  return asyncEpic;
};

export const createListEpic = <Item>(asyncAction: AsyncActionSet, listApi: ListApi<Item>) =>
  createAsyncEpic(asyncAction, listApi);

export const createPostEpic = <F, R, S>(asyncAction: AsyncActionSet, postApi: PostApi<F, R, S>) =>
  createAsyncEpic(asyncAction, postApi);

export const createReadEpic = <Detail>(asyncAction: AsyncActionSet, readApi: ReadApi<Detail>) =>
  createAsyncEpic(asyncAction, readApi);

export const createUpdateEpic = <F, R, S>(asyncAction: AsyncActionSet, updateApi: UpdateApi<F, R, S>) =>
  createAsyncEpic(asyncAction, updateApi);

export const createUpdateParamEpic = <F, R, S>(asyncAction: AsyncActionSet, updateParamApi: UpdateParamApi<F, R, S>) =>
  createAsyncEpic(asyncAction, updateParamApi);

export const createFormEpic = <F, R, S>(asyncAction: AsyncActionSet, formApi: FormApi<F, R, S>) =>
  createAsyncEpic(asyncAction, formApi);

export const createDeleteEpic = (asyncAction: AsyncActionSet, deleteApi: DeleteApi) =>
  createAsyncEpic(asyncAction, deleteApi);

export const createDeleteParamEpic = (asyncAction: AsyncActionSet, deleteParamApi: DeleteParamApi) =>
  createAsyncEpic(asyncAction, deleteParamApi);

export const createDeleteBodyEpic = (asyncAction: AsyncActionSet, deleteBodyApi: DeleteBodyApi) =>
  createAsyncEpic(asyncAction, deleteBodyApi);

export const createFlatListEpic = <Item>(asyncAction: AsyncActionSet, flatListApi: FlatListApi<Item>) =>
  createAsyncEpic(asyncAction, flatListApi);

export const createToastEpic = (action: ActionCreator<RootActionTypes>, message: string) => {
  const epic: Epic<RootAction, ActionType<typeof layoutActions.makeToast>, RootState> = (action$) =>
    action$.pipe(
      filter(isActionOf(action)),
      map(() =>
        layoutActions.makeToast({
          type: ToastType.info,
          message,
        })
      )
    );

  return epic;
};
