import { combineEpics, Epic } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState } from 'store/root/types';
import {
  createAsyncEpic,
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createStringLocalization,
  deleteStringLocalization,
  listStringLocalization,
  listStringLocalizationHistory,
  pasteStringLocalization,
  readStringLocalization,
  refreshStringLocalization,
  updateStringLocalization,
  upsertStringLocalizationBulk,
} from './actions';
import {
  createStringLocalizationApi,
  deleteStringLocalizationApi,
  listStringLocalizationApi,
  listStringLocalizationHistoryApi,
  readStringLocalizationApi,
  refreshStringLocalizationApi,
  updateStringLocalizationApi,
  upsertStringLocalizationBulkApi,
} from './api';

const listStringLocalizationEpic = createListEpic(listStringLocalization, listStringLocalizationApi);

const readStringLocalizationEpic = createReadEpic(readStringLocalization, readStringLocalizationApi);

const upsertStringLocalizationBulkEpic = createPostEpic(upsertStringLocalizationBulk, upsertStringLocalizationBulkApi);

const upsertStringLocalizationBulkToastEpic = createToastEpic(
  upsertStringLocalizationBulk.success,
  'Successfully imported'
);

const createStringLocalizationEpic = createPostEpic(createStringLocalization, createStringLocalizationApi);

const createStringLocalizationToastEpic = createToastEpic(createStringLocalization.success, 'Successfully created');

const updateStringLocalizationEpic = createUpdateEpic(updateStringLocalization, updateStringLocalizationApi);

const updateStringLocalizationToastEpic = createToastEpic(updateStringLocalization.success, 'Successfully saved');

const deleteStringLocalizationEpic = createDeleteEpic(deleteStringLocalization, deleteStringLocalizationApi);

const deleteStringLocalizationToastEpic = createToastEpic(deleteStringLocalization.success, 'Successfully deleted');

const listStringLocalizationHistoryEpic = createListEpic(
  listStringLocalizationHistory,
  listStringLocalizationHistoryApi
);

const refreshStringLocalizationEpic = createAsyncEpic(refreshStringLocalization, refreshStringLocalizationApi);

const refreshStringLocalizationToastEpic = createToastEpic(
  refreshStringLocalization.success,
  'Successfully refreshed server'
);

const pasteStringLocalizationEpic: Epic<RootAction, never, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(pasteStringLocalization)),
    mergeMap((action) => {
      const { data: req, onSuccess } = action.payload;
      if (onSuccess) {
        const res = req[0];
        onSuccess(req, res);
      }
      return EMPTY;
    })
  );

export default combineEpics(
  listStringLocalizationEpic,
  readStringLocalizationEpic,
  upsertStringLocalizationBulkEpic,
  upsertStringLocalizationBulkToastEpic,
  createStringLocalizationEpic,
  createStringLocalizationToastEpic,
  updateStringLocalizationEpic,
  updateStringLocalizationToastEpic,
  deleteStringLocalizationEpic,
  deleteStringLocalizationToastEpic,
  listStringLocalizationHistoryEpic,
  refreshStringLocalizationEpic,
  refreshStringLocalizationToastEpic,
  pasteStringLocalizationEpic
);
