import { TeamMatchProfile } from 'store/teamMatchProfile/types';
import { initialListState, ListState } from 'store/types';

export enum TeamMatchContentType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  ADS_NATIVE = 'ADS_NATIVE',
  ADS_INTERSTITIAL_PREFETCH = 'ADS_INTERSTITIAL_PREFETCH',
  ADS_INTERSTITIAL_SHOW = 'ADS_INTERSTITIAL_SHOW',
  ADS_CUSTOM_VIDEO = 'ADS_CUSTOM_VIDEO',
  ADS_CUSTOM_IMAGE = 'ADS_CUSTOM_IMAGE',
  SUPER_MATCH_NUDGE = 'SUPER_MATCH_NUDGE',
  PREFERRED_LANGUAGE_NUDGE = 'PREFERRED_LANGUAGE_NUDGE',
  ASYNC_CARD = 'ASYNC_CARD',
  PURPOSE_PROMPT = 'PURPOSE_PROMPT',
}

export enum TeamMatchCampaignActions {
  LIST_TEAM_MATCH_CAMPAIGNS_REQUEST = 'LIST_TEAM_MATCH_CAMPAIGNS#REQUEST',
  LIST_TEAM_MATCH_CAMPAIGNS_SUCCESS = 'LIST_TEAM_MATCH_CAMPAIGNS#SUCCESS',
  LIST_TEAM_MATCH_CAMPAIGNS_FAILURE = 'LIST_TEAM_MATCH_CAMPAIGNS#FAILURE',

  READ_TEAM_MATCH_CAMPAIGN_REQUEST = 'READ_TEAM_MATCH_CAMPAIGN#REQUEST',
  READ_TEAM_MATCH_CAMPAIGN_SUCCESS = 'READ_TEAM_MATCH_CAMPAIGN#SUCCESS',
  READ_TEAM_MATCH_CAMPAIGN_FAILURE = 'READ_TEAM_MATCH_CAMPAIGN#FAILURE',

  CREATE_TEAM_MATCH_CAMPAIGN_REQUEST = 'CREATE_TEAM_MATCH_CAMPAIGN#REQUEST',
  CREATE_TEAM_MATCH_CAMPAIGN_SUCCESS = 'CREATE_TEAM_MATCH_CAMPAIGN#SUCCESS',
  CREATE_TEAM_MATCH_CAMPAIGN_FAILURE = 'CREATE_TEAM_MATCH_CAMPAIGN#FAILURE',

  UPDATE_TEAM_MATCH_CAMPAIGN_REQUEST = 'UPDATE_TEAM_MATCH_CAMPAIGN#REQUEST',
  UPDATE_TEAM_MATCH_CAMPAIGN_SUCCESS = 'UPDATE_TEAM_MATCH_CAMPAIGN#SUCCESS',
  UPDATE_TEAM_MATCH_CAMPAIGN_FAILURE = 'UPDATE_TEAM_MATCH_CAMPAIGN#FAILURE',

  DELETE_TEAM_MATCH_CAMPAIGN_REQUEST = 'DELETE_TEAM_MATCH_CAMPAIGN#REQUEST',
  DELETE_TEAM_MATCH_CAMPAIGN_SUCCESS = 'DELETE_TEAM_MATCH_CAMPAIGN#SUCCESS',
  DELETE_TEAM_MATCH_CAMPAIGN_FAILURE = 'DELETE_TEAM_MATCH_CAMPAIGN#FAILURE',

  CREATE_TEAM_MATCH_CONTENT_REQUEST = 'CREATE_TEAM_MATCH_CONTENT#REQUEST',
  CREATE_TEAM_MATCH_CONTENT_SUCCESS = 'CREATE_TEAM_MATCH_CONTENT#SUCCESS',
  CREATE_TEAM_MATCH_CONTENT_FAILURE = 'CREATE_TEAM_MATCH_CONTENT#FAILURE',

  UPDATE_TEAM_MATCH_CONTENT_REQUEST = 'UPDATE_TEAM_MATCH_CONTENT#REQUEST',
  UPDATE_TEAM_MATCH_CONTENT_SUCCESS = 'UPDATE_TEAM_MATCH_CONTENT#SUCCESS',
  UPDATE_TEAM_MATCH_CONTENT_FAILURE = 'UPDATE_TEAM_MATCH_CONTENT#FAILURE',

  DELETE_TEAM_MATCH_CONTENT_REQUEST = 'DELETE_TEAM_MATCH_CONTENT#REQUEST',
  DELETE_TEAM_MATCH_CONTENT_SUCCESS = 'DELETE_TEAM_MATCH_CONTENT#SUCCESS',
  DELETE_TEAM_MATCH_CONTENT_FAILURE = 'DELETE_TEAM_MATCH_CONTENT#FAILURE',
}

export const initialState: TeamMatchCampaignState = {
  list: initialListState,
  detail: undefined,
};

export interface TeamMatchCampaignState {
  list: ListState<TeamMatchCampaignListItem>;
  detail?: TeamMatchCampaignDetail;
}

export interface LimitPercent {
  day: number;
  week: number;
  month: number;
  total: number;
}

export interface TeamMatchContent {
  id: number;
  teamMatchCampaignId: number;
  contentType: string;
  screenAreaButtonType?: string;
  screenAreaButtonInfo?: Array<{
    buttonColor: string;
    buttonText: string;
    buttonTextColor: string;
    buttonUrl: string;
  }>;
  button?: {
    buttonColor: string;
    buttonText: string;
    buttonTextColor: string;
    buttonUrl: string;
  };
  sources: Array<{
    language: string;
    sourceUrl: string;
  }>;
  limitPerContent: LimitPercent;
  limitPerUser: LimitPercent;
  winRatio: number;
  teamProfile: TeamMatchProfile;
  enabled: boolean;
  imageVisibleSeconds: number;
  repeat: boolean;
}

export interface TeamMatchCampaign {
  enableExit: boolean;
  enableFreeMatch: boolean;
  id: number;
  name: string;
  priority: number;
  segmentCode: string;
  skipPrevented: boolean;
  startDate: string;
  endDate: string;
  triggerType: string;
  triggerValue: number;
  limitPerUser: LimitPercent;
  teamMatchContents?: TeamMatchContent[];
  enablePremiumMatch: boolean;
  enabled: boolean;
  timeoutMs?: number;
}

export interface TeamMatchCampaignListItem extends Omit<TeamMatchCampaign, 'teamMatchContents'> {
  teamMatchContents: TeamMatchContentListItem[];
}

export type TeamMatchCampaignDetail = TeamMatchCampaign;

export type TeamMatchCampaignFormValues = TeamMatchCampaign;

export interface TeamMatchCampaignCreateRequestData extends Omit<TeamMatchCampaign, 'teamMatchContents'> {
  teamMatchContentIds: number[] | null;
}

export type TeamMatchCampaignCreateResponseData = TeamMatchCampaign;

export interface TeamMatchCampaignUpdateRequestData extends Omit<TeamMatchCampaign, 'teamMatchContents'> {
  teamMatchContentIds: number[] | null;
}

export type TeamMatchCampaignUpdateResponseData = TeamMatchCampaign;

export interface TeamMatchContentListItem extends TeamMatchContent {
  profileName: string;
}

export type TeamMatchContentFormValues = TeamMatchContent;

export interface TeamMatchContentCreateRequestData extends Omit<TeamMatchContent, 'teamProfile'> {
  teamProfileId: number;
  button?: {
    buttonColor: string;
    buttonText: string;
    buttonTextColor: string;
    buttonUrl: string;
    buttonTextKeyAsString: boolean; // not used
  };
}

export type TeamMatchContentCreateResponseData = TeamMatchContent;

export interface TeamMatchContentUpdateRequestData extends Omit<TeamMatchContent, 'teamProfile'> {
  teamProfileId: number;
}

export type TeamMatchContentUpdateResponseData = TeamMatchContent;

export type TeamMatchContentDeleteResponseData = TeamMatchContent;
