import { combineEpics } from 'redux-observable';

import {
  createNoticePopupCarousel,
  deleteNoticePopupCarousel,
  updateNoticePopupCarousel,
} from 'store/noticePopupCarousel/actions';
import {
  createNoticePopupCarouselApi,
  deleteNoticePopupCarouselApi,
  updateNoticePopupCarouselApi,
} from 'store/noticePopupCarousel/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createNoticePopupCarouselToastEpic = createToastEpic(
  createNoticePopupCarousel.success,
  'Successfully created Carousel Setting'
);

const updateNoticePopupCarouselToastEpic = createToastEpic(
  updateNoticePopupCarousel.success,
  'Successfully saved Carousel Setting'
);

const deleteNoticePopupCarouselToastEpic = createToastEpic(
  deleteNoticePopupCarousel.success,
  'Successfully deleted Carousel Setting'
);

const createNoticePopupCarouselEpic = createPostEpic(createNoticePopupCarousel, createNoticePopupCarouselApi);

const updateNoticePopupCarouselEpic = createUpdateEpic(updateNoticePopupCarousel, updateNoticePopupCarouselApi);

const deleteNoticePopupCarouselEpic = createDeleteEpic(deleteNoticePopupCarousel, deleteNoticePopupCarouselApi);

export default combineEpics(
  createNoticePopupCarouselToastEpic,
  updateNoticePopupCarouselToastEpic,
  deleteNoticePopupCarouselToastEpic,
  createNoticePopupCarouselEpic,
  updateNoticePopupCarouselEpic,
  deleteNoticePopupCarouselEpic
);
