import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  PunishActions as Actions,
  IdentifiedUserInfo,
  IdentifyUserByUserRequest,
  PunishRequest,
  CreatePunishmentResponse,
} from './types';

export const punishByUserId = createAsyncAction(
  Actions.CREATE_PUNISH_USER_REQUEST,
  Actions.CREATE_PUNISH_USER_FAILURE,
  Actions.CREATE_PUNISH_USER_SUCCESS
)<FormRequestPayload<PunishRequest>, CreatePunishmentResponse, AxiosError>();

export const resetIdentifyUser = createAction(Actions.RESET_IDENTIFY_USER)();

export const identifyUserByUserID = createAsyncAction(
  Actions.INDENTIFY_USER_BY_USERID_REQUEST,
  Actions.INDENTIFY_USER_BY_USERID_SUCCESS,
  Actions.INDENTIFY_USER_BY_USERID_FAILURE
)<IdentifyUserByUserRequest, IdentifiedUserInfo, AxiosError>();

export const identifyUserByUsername = createAsyncAction(
  Actions.IDENTIFY_USER_BY_USERNAME_REQUEST,
  Actions.IDENTIFY_USER_BY_USERNAME_SUCCESS,
  Actions.IDENTIFY_USER_BY_USERNAME_FAILURE
)<IdentifyUserByUserRequest, IdentifiedUserInfo, AxiosError>();
