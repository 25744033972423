import { FORMAT, formatIfValid } from 'utils/time';

import { RealtimeMonitoringListItem, RealtimeMonitoringListResponseData, Role } from './types';

const sumAll = (obj: Record<string, number>) => Object.values(obj).reduce((acc, cur) => acc + cur) as number;

export const sortWorkload = (data: RealtimeMonitoringListItem) => [
  data.date,
  data.agentName,
  data.hour,
  data.visualAbuse,
  data.topless,
  data.verbal,
  data.gender,
  data.child,
  data.minor,
  data.adult,
  data.total,
];

export const parsingListManagerResponse = (data: RealtimeMonitoringListResponseData<Role.manager>[]) =>
  data.map(({ workload, ...info }) => {
    const { visual_abuse: visualAbuse, ...rest } = workload;
    const total = sumAll(workload);
    const date = formatIfValid(info.date, FORMAT.DATE) || info.date;

    return {
      ...info,
      ...rest,
      visualAbuse,
      total,
      date,
    };
  });

export const parsingListAgentResponse = (data: RealtimeMonitoringListResponseData<Role>[]) =>
  data.map(({ workload, ...info }) => {
    const { visual_abuse: visualAbuse, ...rest } = workload;
    const total = sumAll(workload);
    const date = formatIfValid(info.hour || info.date, FORMAT.DATE) || 'total';
    const hour = formatIfValid(info.hour || info.date, FORMAT.HOUR) || '-';

    return {
      ...info,
      ...rest,
      date,
      hour,
      visualAbuse,
      total,
    };
  });
