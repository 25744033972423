import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createPurposeSchedule, deletePurposeSchedule, updatePurposeSchedule } from './actions';
import { createPurposeScheduleApi, deletePurposeScheduleApi, updatePurposeScheduleApi } from './api';

const createPurposeScheduleEpic = createPostEpic(createPurposeSchedule, createPurposeScheduleApi);

const createPurposeScheduleToastEpic = createToastEpic(createPurposeSchedule.success, 'Successfully created');

const updatePurposeScheduleEpic = createUpdateEpic(updatePurposeSchedule, updatePurposeScheduleApi);

const updatePurposeScheduleToastEpic = createToastEpic(updatePurposeSchedule.success, 'Successfully updated');

const deletePurposeScheduleEpic = createDeleteEpic(deletePurposeSchedule, deletePurposeScheduleApi);

const deletePurposeScheduleToastEpic = createToastEpic(deletePurposeSchedule.success, 'Successfully deleted');

export default combineEpics(
  createPurposeScheduleEpic,
  createPurposeScheduleToastEpic,
  updatePurposeScheduleEpic,
  updatePurposeScheduleToastEpic,
  deletePurposeScheduleEpic,
  deletePurposeScheduleToastEpic
);
