import { combineEpics } from 'redux-observable';

import {
  createBannerSettingMapEntry,
  deleteBannerSettingMapEntry,
  updateBannerSettingMapEntry,
} from 'store/bannerSettingMapEntry/actions';
import {
  createBannerSettingMapEntryApi,
  deleteBannerSettingMapEntryApi,
  updateBannerSettingMapEntryApi,
} from 'store/bannerSettingMapEntry/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const updateBannerSettingMapEntryToastEpic = createToastEpic(
  updateBannerSettingMapEntry.success,
  'Successfully saved Schedule Setting'
);

const createBannerSettingMapEntryToastEpic = createToastEpic(
  createBannerSettingMapEntry.success,
  'Successfully created Schedule Setting'
);

const createMapEntryEpic = createPostEpic(createBannerSettingMapEntry, createBannerSettingMapEntryApi);

const updateMapEntryEpic = createUpdateEpic(updateBannerSettingMapEntry, updateBannerSettingMapEntryApi);

const deleteMapEntryEpic = createDeleteEpic(deleteBannerSettingMapEntry, deleteBannerSettingMapEntryApi);

export default combineEpics(
  updateBannerSettingMapEntryToastEpic,
  createBannerSettingMapEntryToastEpic,
  createMapEntryEpic,
  updateMapEntryEpic,
  deleteMapEntryEpic
);
