import { combineEpics } from 'redux-observable';

import {
  listWebProduct,
  listAppStoreIabProduct,
  listAppStoreIabSubscriptionProduct,
  listGooglePlayIabProduct,
  listGooglePlayIabSubscriptionProduct,
} from 'store/iabProduct/actions';
import {
  listWebProductApi,
  listAppStoreIabProductApi,
  listAppStoreIabSubscriptionProductApi,
  listGooglePlayIabProductApi,
  listGooglePlayIabSubscriptionProductApi,
} from 'store/iabProduct/api';
import { createListEpic } from 'store/utils';

const listWebProductEpic = createListEpic(listWebProduct, listWebProductApi);
const listAppStoreIabProductEpic = createListEpic(listAppStoreIabProduct, listAppStoreIabProductApi);
const listAppStoreIabSubscriptionProductEpic = createListEpic(
  listAppStoreIabSubscriptionProduct,
  listAppStoreIabSubscriptionProductApi
);
const listGooglePlayIabProductEpic = createListEpic(listGooglePlayIabProduct, listGooglePlayIabProductApi);
const listGooglePlayIabSubscriptionProductEpic = createListEpic(
  listGooglePlayIabSubscriptionProduct,
  listGooglePlayIabSubscriptionProductApi
);
export default combineEpics(
  listWebProductEpic,
  listAppStoreIabProductEpic,
  listGooglePlayIabProductEpic,
  listAppStoreIabSubscriptionProductEpic,
  listGooglePlayIabSubscriptionProductEpic
);
