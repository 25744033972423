import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  ShopEntryBannerScheduleActions as Actions,
  ShopEntryBannerScheduleCreateRequestData,
  ShopEntryBannerScheduleCreateSuccessData,
  ShopEntryBannerScheduleUpdateRequestData,
  ShopEntryBannerScheduleUpdateSuccessData,
} from './types';

export const createShopEntryBannerSchedule = createAsyncAction(
  Actions.CREATE_SCHEDULE_REQUEST,
  Actions.CREATE_SCHEDULE_SUCCESS,
  Actions.CREATE_SCHEDULE_FAILURE
)<FormRequestPayload<ShopEntryBannerScheduleCreateRequestData>, ShopEntryBannerScheduleCreateSuccessData, AxiosError>();

export const updateShopEntryBannerSchedule = createAsyncAction(
  Actions.UPDATE_SCHEDULE_REQUEST,
  Actions.UPDATE_SCHEDULE_SUCCESS,
  Actions.UPDATE_SCHEDULE_FAILURE
)<FormRequestPayload<ShopEntryBannerScheduleUpdateRequestData>, ShopEntryBannerScheduleUpdateSuccessData, AxiosError>();

export const deleteShopEntryBannerSchedule = createAsyncAction(
  Actions.DELETE_SCHEDULE_REQUEST,
  Actions.DELETE_SCHEDULE_SUCCESS,
  Actions.DELETE_SCHEDULE_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
