import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  AgentManageActions as Actions,
  AgentManageCreateRequestPayload,
  AgentManageCreateResponseData,
  AgentManageDeleteRequestPayload,
  AgentManageListRequestPayload,
  AgentManageListResponseData,
  AgentManageReadRequestPayLoad,
  AgentManageReadResponseData,
  AgentManageUpdateRequestPayload,
  AgentManageUpdateResponseData,
} from './types';

export const listAgentManage = createAsyncAction(
  Actions.LIST_AGENT_MANAGE_REQUEST,
  Actions.LIST_AGENT_MANAGE_SUCCESS,
  Actions.LIST_AGENT_MANAGE_FAILURE
)<AgentManageListRequestPayload, AgentManageListResponseData, AxiosError>();

export const createAgentManage = createAsyncAction(
  Actions.CREATE_AGENT_MANAGE_REQUEST,
  Actions.CREATE_AGENT_MANAGE_SUCCESS,
  Actions.CREATE_AGENT_MANAGE_FAILURE
)<AgentManageCreateRequestPayload, AgentManageCreateResponseData, AxiosError>();

export const readAgentManage = createAsyncAction(
  Actions.READ_AGENT_MANAGE_REQUEST,
  Actions.READ_AGENT_MANAGE_SUCCESS,
  Actions.READ_AGENT_MANAGE_FAILURE
)<AgentManageReadRequestPayLoad, AgentManageReadResponseData, AxiosError>();

export const updateAgentManage = createAsyncAction(
  Actions.UPDATE_AGENT_MANAGE_REQUEST,
  Actions.UPDATE_AGENT_MANAGE_SUCCESS,
  Actions.UPDATE_AGENT_MANAGE_FAILURE
)<AgentManageUpdateRequestPayload, AgentManageUpdateResponseData, AxiosError>();

export const deleteAgentManage = createAsyncAction(
  Actions.DELETE_AGENT_MANAGE_REQUEST,
  Actions.DELETE_AGENT_MANAGE_SUCCESS,
  Actions.DELETE_AGENT_MANAGE_FAILURE
)<AgentManageDeleteRequestPayload, undefined, AxiosError>();
