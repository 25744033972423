import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as promotionContentGroupActions from './actions';
import { initialState, PromotionContentGroupState } from './types';

const promotionContentGroup = createReducer<PromotionContentGroupState, RootAction>(initialState)
  .handleAction(
    promotionContentGroupActions.listPromotionContentGroup.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(promotionContentGroupActions.listPromotionContentGroup.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(promotionContentGroupActions.readPromotionContentGroup.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(
    promotionContentGroupActions.listPromotionContentGroupSearch.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      searchList: {
        ...state.searchList,
        page,
        pageSize,
      },
    })
  )
  .handleAction(promotionContentGroupActions.listPromotionContentGroupSearch.success, (state, { payload }) => ({
    ...state,
    searchList: payload,
  }));

export default promotionContentGroup;
