import { combineEpics } from 'redux-observable';

import { createFlatListEpic, createListEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createItemCategory, getDecoCategory, getItemCategory, updateItemCategory } from './actions';
import { createItemCategoryApi, getDecoCategoryApi, getItemCategoryApi, updateItemCategoryApi } from './api';

const createItemCategoryEpic = createPostEpic(createItemCategory, createItemCategoryApi);

const getItemCategoryEpic = createListEpic(getItemCategory, getItemCategoryApi);

const createItemCategoryToastEpic = createToastEpic(createItemCategory.success, 'Successfully created');

const getDecoCategoryEpic = createFlatListEpic(getDecoCategory, getDecoCategoryApi);

const updateItemCategoryEpic = createUpdateEpic(updateItemCategory, updateItemCategoryApi);

export default combineEpics(
  createItemCategoryEpic,
  createItemCategoryToastEpic,
  getItemCategoryEpic,
  getDecoCategoryEpic,
  updateItemCategoryEpic
);
