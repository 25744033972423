import { combineEpics } from 'redux-observable';

import {
  createGemSettingMapEntry,
  deleteGemSettingMapEntry,
  updateGemSettingMapEntry,
} from 'store/gemSettingMapEntry/actions';
import {
  createGemSettingMapEntryApi,
  deleteGemSettingMapEntryApi,
  updateGemSettingMapEntryApi,
} from 'store/gemSettingMapEntry/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createGemSettingMapEntryToastEpic = createToastEpic(
  createGemSettingMapEntry.success,
  'Successfully created Schedule Setting'
);

const updateGemSettingMapEntryToastEpic = createToastEpic(
  updateGemSettingMapEntry.success,
  'Successfully saved Schedule Setting'
);

const createMapEntryEpic = createPostEpic(createGemSettingMapEntry, createGemSettingMapEntryApi);

const updateMapEntryEpic = createUpdateEpic(updateGemSettingMapEntry, updateGemSettingMapEntryApi);

const deleteMapEntryEpic = createDeleteEpic(deleteGemSettingMapEntry, deleteGemSettingMapEntryApi);

export default combineEpics(
  createGemSettingMapEntryToastEpic,
  updateGemSettingMapEntryToastEpic,
  createMapEntryEpic,
  updateMapEntryEpic,
  deleteMapEntryEpic
);
