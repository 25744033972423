import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import { SegmentDeclaration, SegmentDeclarationFormValues } from 'store/bigquerySegmentDeclaration/types';

import {
  BigquerySegmentDeclarationActions as Actions,
  SegmentDeclarationCreateRequestData,
  SegmentDeclarationCreateSuccessData,
  SegmentDeclarationUpdateRequestData,
} from './types';

export const createBigquerySegmentDeclaration = createAsyncAction(
  Actions.CREATE_BIGQUERY_SEGMENT_DECLARATION_REQUEST,
  Actions.CREATE_BIGQUERY_SEGMENT_DECLARATION_SUCCESS,
  Actions.CREATE_BIGQUERY_SEGMENT_DECLARATION_FAILURE
)<FormRequestPayload<SegmentDeclarationCreateRequestData>, SegmentDeclarationCreateSuccessData, AxiosError>();

export const updateBigquerySegmentDeclaration = createAsyncAction(
  Actions.UPDATE_BIGQUERY_SEGMENT_DECLARATION_REQUEST,
  Actions.UPDATE_BIGQUERY_SEGMENT_DECLARATION_SUCCESS,
  Actions.UPDATE_BIGQUERY_SEGMENT_DECLARATION_FAILURE
)<FormRequestPayload<SegmentDeclarationFormValues, SegmentDeclarationUpdateRequestData>, AxiosError>();

export const acceptBigquerySegmentDeclaration = createAsyncAction(
  Actions.ACCEPT_BIGQUERY_SEGMENT_DECLARATION_REQUEST,
  Actions.ACCEPT_BIGQUERY_SEGMENT_DECLARATION_SUCCESS,
  Actions.ACCEPT_BIGQUERY_SEGMENT_DECLARATION_FAILURE
)<FormRequestPayload<{}>, SegmentDeclaration, AxiosError>();

export const rejectBigquerySegmentDeclaration = createAsyncAction(
  Actions.REJECT_BIGQUERY_SEGMENT_DECLARATION_REQUEST,
  Actions.REJECT_BIGQUERY_SEGMENT_DECLARATION_SUCCESS,
  Actions.REJECT_BIGQUERY_SEGMENT_DECLARATION_FAILURE
)<FormRequestPayload<{}>, SegmentDeclaration, AxiosError>();

export const manualTriggerBigquerySegment = createAsyncAction(
  Actions.MANUAL_TRIGGER_BIGQUERY_SEGMENT_REQUEST,
  Actions.MANUAL_TRIGGER_BIGQUERY_SEGMENT_SUCCESS,
  Actions.MANUAL_TRIGGER_BIGQUERY_SEGMENT_FAILURE
)<FormRequestPayload<{}>, {}, AxiosError>();
