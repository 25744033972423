import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { AgentHistoryActions as Actions, AgentHistoryRequestPayload, AgentHistoryResponseData } from './types';

export const listAgentHistory = createAsyncAction(
  Actions.LIST_AGENT_HISTORY_REQUEST,
  Actions.LIST_AGENT_HISTORY_SUCCESS,
  Actions.LIST_AGENT_HISTORY_FAILURE
)<AgentHistoryRequestPayload, AgentHistoryResponseData, AxiosError>();
