import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  PromotionScheduleActions as Actions,
  PromotionScheduleListItem,
  PromotionScheduleCreateRequestData,
  PromotionScheduleCreateResponseData,
  PromotionScheduleDetail,
  PromotionScheduleFormValues,
  PromotionScheduleUpdateRequestData,
  PromotionScheduleUpdateResponseData,
} from './types';

export const listPromotionSchedule = createAsyncAction(
  Actions.LIST_PROMOTION_SCHEDULES_REQUEST,
  Actions.LIST_PROMOTION_SCHEDULES_SUCCESS,
  Actions.LIST_PROMOTION_SCHEDULES_FAILURE
)<ListRequestPayload, ListState<PromotionScheduleListItem>, AxiosError>();

export const readPromotionSchedule = createAsyncAction(
  Actions.READ_PROMOTION_SCHEDULE_REQUEST,
  Actions.READ_PROMOTION_SCHEDULE_SUCCESS,
  Actions.READ_PROMOTION_SCHEDULE_FAILURE
)<ReadRequestPayload, PromotionScheduleDetail, AxiosError>();

export const createPromotionSchedule = createAsyncAction(
  Actions.CREATE_PROMOTION_SCHEDULE_REQUEST,
  Actions.CREATE_PROMOTION_SCHEDULE_SUCCESS,
  Actions.CREATE_PROMOTION_SCHEDULE_FAILURE
)<
  FormRequestPayload<PromotionScheduleFormValues, PromotionScheduleCreateRequestData>,
  PromotionScheduleCreateResponseData,
  AxiosError
>();

export const updatePromotionSchedule = createAsyncAction(
  Actions.UPDATE_PROMOTION_SCHEDULE_REQUEST,
  Actions.UPDATE_PROMOTION_SCHEDULE_SUCCESS,
  Actions.UPDATE_PROMOTION_SCHEDULE_FAILURE
)<
  FormRequestPayload<PromotionScheduleFormValues, PromotionScheduleUpdateRequestData>,
  PromotionScheduleUpdateResponseData,
  AxiosError
>();

export const deletePromotionSchedule = createAsyncAction(
  Actions.DELETE_PROMOTION_SCHEDULE_REQUEST,
  Actions.DELETE_PROMOTION_SCHEDULE_SUCCESS,
  Actions.DELETE_PROMOTION_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const refreshPromotionSchedule = createAsyncAction(
  Actions.REFRESH_PROMOTION_SCHEDULE_REQUEST,
  Actions.REFRESH_PROMOTION_SCHEDULE_SUCCESS,
  Actions.REFRESH_PROMOTION_SCHEDULE_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
