import { combineEpics } from 'redux-observable';

import { createListEpic, createReadEpic } from 'store/utils';

import { listMatchFilterSetting, readMatchFilterSetting } from './actions';
import { listMatchFilterSettingApi, readMatchFilterSettingApi } from './api';

const listMatchFilterSettingEpic = createListEpic(listMatchFilterSetting, listMatchFilterSettingApi);

const readMatchFilterSettingEpic = createReadEpic(readMatchFilterSetting, readMatchFilterSettingApi);

export default combineEpics(listMatchFilterSettingEpic, readMatchFilterSettingEpic);
