import { combineEpics } from 'redux-observable';

import {
  createSpecialPromotionSettingProduct,
  deleteSpecialPromotionSettingProduct,
  updateSpecialPromotionSettingProduct,
} from 'store/specialPromotionSettingProduct/actions';
import {
  createSpecialPromotionSettingProductApi,
  deleteSpecialPromotionSettingProductApi,
  updateSpecialPromotionSettingProductApi,
} from 'store/specialPromotionSettingProduct/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const createSpecialPromotionSettingProductToastEpic = createToastEpic(
  createSpecialPromotionSettingProduct.success,
  'Successfully created'
);

const updateSpecialPromotionSettingProductToastEpic = createToastEpic(
  updateSpecialPromotionSettingProduct.success,
  'Successfully saved'
);

const createProductEpic = createPostEpic(createSpecialPromotionSettingProduct, createSpecialPromotionSettingProductApi);

const updateProductEpic = createUpdateEpic(
  updateSpecialPromotionSettingProduct,
  updateSpecialPromotionSettingProductApi
);

const deleteProductEpic = createDeleteEpic(
  deleteSpecialPromotionSettingProduct,
  deleteSpecialPromotionSettingProductApi
);

export default combineEpics(
  createSpecialPromotionSettingProductToastEpic,
  updateSpecialPromotionSettingProductToastEpic,
  createProductEpic,
  updateProductEpic,
  deleteProductEpic
);
