import {
  AgentManageCreateFormValues,
  AgentManageCreateRequestData,
  AgentManageCreateResponseData,
  AgentManageListItem,
  AgentManageReadResponseData,
  AgentManageUpdateFormValues,
  AgentManageUpdateRequestData,
} from 'store/agentManage/types';
import { createDeleteBodyApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';
import { switchPath } from 'utils/path';

const basePath = '/agents';

export const createAgentManageApi = createPostApi<
  AgentManageCreateFormValues,
  AgentManageCreateRequestData,
  AgentManageCreateResponseData
>(basePath);
export const deleteAgentManageApi = createDeleteBodyApi(basePath);

export const listAgentManageApi = createListApi<AgentManageListItem>(switchPath(basePath));
export const readAgentManageApi = createReadApi<AgentManageReadResponseData>(`${switchPath(basePath)}/:id`);
export const updateAgentManageApi = createUpdateApi<AgentManageUpdateFormValues, AgentManageUpdateRequestData>(
  `${switchPath(basePath)}/:id`
);
