export enum ImageSettingActions {
  RESET_IMAGE_SETTING = 'RESET_IMAGE_SETTING',

  CREATE_IMAGE_SETTING_REQUEST = 'CREATE_IMAGE_SETTING#REQUEST',
  CREATE_IMAGE_SETTING_SUCCESS = 'CREATE_IMAGE_SETTING#SUCCESS',
  CREATE_IMAGE_SETTING_FAILURE = 'CREATE_IMAGE_SETTING#FAILURE',
}

export const initialState: ImageSettingState = {};

export type ImageSettingState = ImageSettingList;

export interface ImageSettingList {
  [name: string]: string | undefined;
}

export type ImageSettingCreateRequestData = any;

export interface ImageSettingCreateSuccessData {
  imageUrl: string;
}

export interface ImageSettingCreateMeta {
  fetchKey: string;
}
