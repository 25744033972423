import { FlatListState } from '../types';

export enum SpecialPromotionSettingLimitKeyActions {
  LIST_LIMIT_KEY_REQUEST = 'LIST_SEPCIAL_PROMOTION_LIMIT_KEY#REQUEST',
  LIST_LIMIT_KEY_SUCCESS = 'LIST_SEPCIAL_PROMOTION_LIMIT_KEY#SUCCESS',
  LIST_LIMIT_KEY_FAILURE = 'LIST_SEPCIAL_PROMOTION_LIMIT_KEY#FAILURE',
}

export interface SpecialPromotionSettingLimitKeyState {
  list: FlatListState<SpecialPromotionSettingLimitKey>;
}

export const initialListState: FlatListState<SpecialPromotionSettingLimitKey> = [];

export const initialState: SpecialPromotionSettingLimitKeyState = { list: initialListState };

export interface SpecialPromotionSettingLimitKey {
  id: number;
  name: string;
  maxPurchaseCount: number;
}
