import { combineEpics } from 'redux-observable';

import { createListEpic, createPostEpic, createReadEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createMlConfigFeature, listMlConfigFeature, readMlConfigFeature, updateMlConfigFeature } from './actions';
import {
  createMlConfigFeatureApi,
  listMlConfigFeatureApi,
  readMlConfigFeatureApi,
  updateMlConfigFeatureApi,
} from './api';

const updateMlConfigFeatureToastEpic = createToastEpic(updateMlConfigFeature.success, 'Successfully saved');

const createMlConfigFeatureToastEpic = createToastEpic(createMlConfigFeature.success, 'Successfully created');

const listMlConfigFeatureEpic = createListEpic(listMlConfigFeature, listMlConfigFeatureApi);

const createMlConfigFeatureEpic = createPostEpic(createMlConfigFeature, createMlConfigFeatureApi);

const readMlConfigFeatureEpic = createReadEpic(readMlConfigFeature, readMlConfigFeatureApi);

const updateMlConfigFeatureEpic = createUpdateEpic(updateMlConfigFeature, updateMlConfigFeatureApi);

export default combineEpics(
  updateMlConfigFeatureToastEpic,
  createMlConfigFeatureToastEpic,
  listMlConfigFeatureEpic,
  createMlConfigFeatureEpic,
  readMlConfigFeatureEpic,
  updateMlConfigFeatureEpic
);
