import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as matataInvalidatedPurchaseBySenderActions from './actions';
import { initialState, MatataInvalidatedPurchaseBySenderState } from './types';

const matataInvalidatedPurchaseBySender = createReducer<MatataInvalidatedPurchaseBySenderState, RootAction>(
  initialState
)
  .handleAction(
    matataInvalidatedPurchaseBySenderActions.readMatataInvalidatedPurchaseBySender.success,
    (state, { payload }) => ({
      ...state,
      detail: payload,
    })
  )
  .handleAction(
    matataInvalidatedPurchaseBySenderActions.listMatataInvalidatedPurchaseBySender.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(
    matataInvalidatedPurchaseBySenderActions.listMatataInvalidatedPurchaseBySender.success,
    (state, { payload }) => ({
      ...state,
      list: payload,
    })
  );

export default matataInvalidatedPurchaseBySender;
