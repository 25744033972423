import { initialListState, ListState } from 'store/types';

export enum ItemProductActions {
  LIST_ITEM_PRODUCTS_REQUEST = 'LIST_ITEM_PRODUCTS#REQUEST',
  LIST_ITEM_PRODUCTS_SUCCESS = 'LIST_ITEM_PRODUCTS#SUCCESS',
  LIST_ITEM_PRODUCTS_FAILURE = 'LIST_ITEM_PRODUCTS#FAILURE',

  CREATE_ITEM_PRODUCT_REQUEST = 'CREATE_ITEM_PRODUCT#REQUEST',
  CREATE_ITEM_PRODUCT_SUCCESS = 'CREATE_ITEM_PRODUCT#SUCCESS',
  CREATE_ITEM_PRODUCT_FAILURE = 'CREATE_ITEM_PRODUCT#FAILURE',

  UPDATE_ITEM_PRODUCT_REQUEST = 'UPDATE_ITEM_PRODUCT#REQUEST',
  UPDATE_ITEM_PRODUCT_SUCCESS = 'UPDATE_ITEM_PRODUCT#SUCCESS',
  UPDATE_ITEM_PRODUCT_FAILURE = 'UPDATE_ITEM_PRODUCT#FAILURE',

  DELETE_ITEM_PRODUCT_REQUEST = 'DELETE_ITEM_PRODUCT#REQUEST',
  DELETE_ITEM_PRODUCT_SUCCESS = 'DELETE_ITEM_PRODUCT#SUCCESS',
  DELETE_ITEM_PRODUCT_FAILURE = 'DELETE_ITEM_PRODUCT#FAILURE',

  // ITEM_PRODUCT_PROVIDE

  CREATE_ITEM_PRODUCT_PROVIDE_REQUEST = 'CREATE_ITEM_PRODUCT_PROVIDE#REQUEST',
  CREATE_ITEM_PRODUCT_PROVIDE_SUCCESS = 'CREATE_ITEM_PRODUCT_PROVIDE#SUCCESS',
  CREATE_ITEM_PRODUCT_PROVIDE_FAILURE = 'CREATE_ITEM_PRODUCT_PROVIDE#FAILURE',

  UPDATE_ITEM_PRODUCT_PROVIDE_REQUEST = 'UPDATE_ITEM_PRODUCT_PROVIDE#REQUEST',
  UPDATE_ITEM_PRODUCT_PROVIDE_SUCCESS = 'UPDATE_ITEM_PRODUCT_PROVIDE#SUCCESS',
  UPDATE_ITEM_PRODUCT_PROVIDE_FAILURE = 'UPDATE_ITEM_PRODUCT_PROVIDE#FAILURE',

  DELETE_ITEM_PRODUCT_PROVIDE_REQUEST = 'DELETE_ITEM_PRODUCT_PROVIDE#REQUEST',
  DELETE_ITEM_PRODUCT_PROVIDE_SUCCESS = 'DELETE_ITEM_PRODUCT_PROVIDE#SUCCESS',
  DELETE_ITEM_PRODUCT_PROVIDE_FAILURE = 'DELETE_ITEM_PRODUCT_PROVIDE#FAILURE',

  // PERIODIC_ITEM_PRODUCT

  CREATE_PERIODIC_ITEM_PRODUCT_REQUEST = 'CREATE_PERIODIC_ITEM_PRODUCT#REQUEST',
  CREATE_PERIODIC_ITEM_PRODUCT_SUCCESS = 'CREATE_PERIODIC_ITEM_PRODUCT#SUCCESS',
  CREATE_PERIODIC_ITEM_PRODUCT_FAILURE = 'CREATE_PERIODIC_ITEM_PRODUCT#FAILURE',

  UPDATE_PERIODIC_ITEM_PRODUCT_REQUEST = 'UPDATE_PERIODIC_ITEM_PRODUCT#REQUEST',
  UPDATE_PERIODIC_ITEM_PRODUCT_SUCCESS = 'UPDATE_PERIODIC_ITEM_PRODUCT#SUCCESS',
  UPDATE_PERIODIC_ITEM_PRODUCT_FAILURE = 'UPDATE_PERIODIC_ITEM_PRODUCT#FAILURE',

  DELETE_PERIODIC_ITEM_PRODUCT_REQUEST = 'DELETE_PERIODIC_ITEM_PRODUCT#REQUEST',
  DELETE_PERIODIC_ITEM_PRODUCT_SUCCESS = 'DELETE_PERIODIC_ITEM_PRODUCT#SUCCESS',
  DELETE_PERIODIC_ITEM_PRODUCT_FAILURE = 'DELETE_PERIODIC_ITEM_PRODUCT#FAILURE',
}

export const initialState: ItemProductState = { list: initialListState };

export interface ItemProductState {
  list: ListState<ItemProductListItem>;
}

export interface ItemProduct {
  itemId: string;
  categoryId: string;
  useMins: number;
  gemPrice: number;
  shopThumbnailImageUrl: string;
  localizationKey?: string;
  multiPurchasable?: boolean;
  purchasableWithGem?: boolean;
  acceptsBonusGem?: boolean;
  displayItem?: boolean;
  triggeredDescriptionKey?: string;
}

export type ItemProductListItem = Omit<ItemProduct, 'multiPurchasable' | 'acceptsBonusGem' | 'displayItem'>;

export interface ItemProductFormValues extends ItemProduct {
  freeTrial: boolean;
  localizationKeyAsString: boolean;
}

export type ItemProductCreateRequestData = Omit<ItemProduct, 'id'>;

export type ItemProductCreateResponseData = ItemProduct;

export type ItemProductUpdateRequestData = Omit<ItemProduct, 'id'>;

export type ItemProductUpdateResponseData = ItemProduct;

// ItemProductProvide

export interface ItemProductProvide {
  id: number;
  itemId: string;
  providedItemId: string;
  useCount?: number;
  useMins?: number;
}

export type ItemProductProvideCreateRequestData = Omit<ItemProductProvide, 'id' | 'itemId'>;
export type ItemProductProvideCreateResponseData = ItemProductProvide;
export type ItemProductProvideUpdateRequestData = ItemProductProvideCreateRequestData;
export type ItemProductProvideUpdateResponseData = ItemProductProvide;
export type ItemProductProvideFormValues = ItemProductProvideCreateRequestData;

// PeriodicItemProduct
export interface PeriodicItemProduct {
  id: number;
  itemId: string;
  bundleItemId: string;
  useTimeSpan: number;
  useTimeSpanUnit: TimeSpanUnit;
  useTimeSpanTruncated: boolean;
  useCount: number;
  setDateExpires: boolean;
  offsetMins?: number;
  expiryTimeType?: ExpiryTimeType;
  includeCountryCodes?: string;
  excludeCountryCodes?: string;
}

export enum TimeSpanUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export enum ExpiryTimeType {
  NONE = 'NONE',
  DELIVERY_CYCLE = 'DELIVERY_CYCLE',
  BUNDLE_ITEM_SETTING = 'BUNDLE_ITEM_SETTING',
}

export type PeriodicItemProductCreateRequestData = Omit<PeriodicItemProduct, 'id' | 'itemId'>;
export type PeriodicItemProductCreateResponseData = PeriodicItemProduct;
export type PeriodicItemProductUpdateRequestData = PeriodicItemProductCreateRequestData;
export type PeriodicItemProductUpdateResponseData = PeriodicItemProduct;
export type PeriodicItemProductFormValues = PeriodicItemProductCreateRequestData;
