import { combineEpics } from 'redux-observable';

import {
  createGemSettingProduct,
  deleteGemSettingProduct,
  updateGemSettingProduct,
} from 'store/gemSettingProduct/actions';
import {
  createGemSettingProductApi,
  deleteGemSettingProductApi,
  updateGemSettingProductApi,
} from 'store/gemSettingProduct/api';
import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

const updateGemSettingProductToastEpic = createToastEpic(updateGemSettingProduct.success, 'Successfully saved');
const createGemSettingProductToastEpic = createToastEpic(createGemSettingProduct.success, 'Successfully created');

const createProductEpic = createPostEpic(createGemSettingProduct, createGemSettingProductApi);
const updateProductEpic = createUpdateEpic(updateGemSettingProduct, updateGemSettingProductApi);
const deleteProductEpic = createDeleteEpic(deleteGemSettingProduct, deleteGemSettingProductApi);

export default combineEpics(
  createGemSettingProductToastEpic,
  updateGemSettingProductToastEpic,
  createProductEpic,
  updateProductEpic,
  deleteProductEpic
);
