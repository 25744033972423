import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FlatListRequestPayload, FlatListState } from '../types';

import { SpecialPromotionSettingLimitKeyActions as Actions, SpecialPromotionSettingLimitKey } from './types';

export const listSpecialPromotionSettingLimitKey = createAsyncAction(
  Actions.LIST_LIMIT_KEY_REQUEST,
  Actions.LIST_LIMIT_KEY_SUCCESS,
  Actions.LIST_LIMIT_KEY_FAILURE
)<FlatListRequestPayload, FlatListState<SpecialPromotionSettingLimitKey>, AxiosError>();
