import { initialListState, ListState } from '../types';

export enum AppStoreReviewActions {
  LIST_APP_STORE_REVIEW_REQUEST = 'LIST_APP_STORE_REVIEW#REQUEST',
  LIST_APP_STORE_REVIEW_SUCCESS = 'LIST_APP_STORE_REVIEW#SUCCESS',
  LIST_APP_STORE_REVIEW_FAILURE = 'LIST_APP_STORE_REVIEW#FAILURE',
}

export interface AppStoreReview {
  id: string;
  type: string;
  rating?: number;
  title?: string;
  body?: string;
  reviewerNickname?: string;
  createdDate: number;
  territory?: string;
}

export const fileHeaders: string[] = [
  'id',
  'type',
  'rating',
  'title',
  'body',
  'reviewerNickname',
  'createdDate',
  'territory',
];

export const sortAppStoreReview = (data: AppStoreReview) => [
  data.id,
  data.type,
  data.rating,
  data.title,
  data.body,
  data.reviewerNickname,
  data.createdDate,
  data.territory,
];

export interface AppStoreReviewState {
  list: ListState<AppStoreReview>;
  detail?: AppStoreReview;
}

export const initialState: AppStoreReviewState = {
  list: initialListState,
  detail: undefined,
};
