import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as pushContentActions from './actions';
import { initialState, PushContentState } from './types';

const pushContent = createReducer<PushContentState, RootAction>(initialState)
  .handleAction(pushContentActions.readPushContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(pushContentActions.updatePushContent.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }))
  .handleAction(pushContentActions.listPushContent.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(pushContentActions.listPushContent.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(pushContentActions.readPushType.success, (state, { payload }) => ({
    ...state,
    pushTypes: payload,
  }));

export default pushContent;
