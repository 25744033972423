import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  PromotionContentCreateRequestData,
  PromotionContentCreateResponseData,
  PromotionContentDetail,
  PromotionContentFormValues,
  PromotionContentListItem,
  PromotionContentUpdateRequestData,
  PromotionContentUpdateResponseData,
} from './types';

export const listPromotionContentApi = createListApi<PromotionContentListItem>('/promotion/contents');

export const readPromotionContentApi = createReadApi<PromotionContentDetail>('/promotion/contents/:id');

export const createPromotionContentApi = createPostApi<
  PromotionContentFormValues,
  PromotionContentCreateRequestData,
  PromotionContentCreateResponseData
>('/promotion/contents');

export const updatePromotionContentApi = createUpdateApi<
  PromotionContentFormValues,
  PromotionContentUpdateRequestData,
  PromotionContentUpdateResponseData
>('/promotion/contents');
