import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as matchFilterOptionListingActions from './actions';
import { initialState, MatchFilterOptionListingState } from './types';

const matchFilterOptionListing = createReducer<MatchFilterOptionListingState, RootAction>(initialState)
  .handleAction(
    matchFilterOptionListingActions.listMatchFilterOptionListing.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      list: {
        ...state.list,
        page,
        pageSize,
      },
    })
  )
  .handleAction(matchFilterOptionListingActions.listMatchFilterOptionListing.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default matchFilterOptionListing;
