import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createDeleteEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createDecoSetting,
  deleteDecoSetting,
  listDecoSetting,
  readDecoSetting,
  refreshDecoSetting,
  updateDecoSetting,
} from './actions';
import {
  createDecoSettingApi,
  deleteDecoSettingApi,
  listDecoSettingApi,
  readDecoSettingApi,
  refreshDecoSettingApi,
  updateDecoSettingApi,
} from './api';

const listDecoSettingEpic = createAsyncEpic(listDecoSetting, listDecoSettingApi);

const readDecoSettingEpic = createReadEpic(readDecoSetting, readDecoSettingApi);

const createDecoSettingEpic = createPostEpic(createDecoSetting, createDecoSettingApi);

const createDecoSettingToastEpic = createToastEpic(createDecoSetting.success, 'Successfully created');

const updateDecoSettingEpic = createUpdateEpic(updateDecoSetting, updateDecoSettingApi);

const updateDecoSettingToastEpic = createToastEpic(updateDecoSetting.success, 'Successfully saved');

const deleteDecoSettingEpic = createDeleteEpic(deleteDecoSetting, deleteDecoSettingApi);

const deleteDecoSettingToastEpic = createToastEpic(deleteDecoSetting.success, 'Successfully deleted');

const refreshDecoSettingEpic = createAsyncEpic(refreshDecoSetting, refreshDecoSettingApi);

const refreshDecoSettingToastEpic = createToastEpic(refreshDecoSetting.success, 'Successfully refreshed server');

export default combineEpics(
  listDecoSettingEpic,
  readDecoSettingEpic,
  createDecoSettingEpic,
  createDecoSettingToastEpic,
  updateDecoSettingEpic,
  updateDecoSettingToastEpic,
  deleteDecoSettingEpic,
  deleteDecoSettingToastEpic,
  refreshDecoSettingEpic,
  refreshDecoSettingToastEpic
);
