import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { GemSettingProduct } from 'store/gemSettingProduct/types';
import {
  DeleteRequestPayload,
  DeleteSuccessPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  GemSettingActions as Actions,
  GemSettingCreateRequestData,
  GemSettingCreateRequestFormValues,
  GemSettingCreateSuccessData,
  GemSettingDetail,
  GemSettingListItem,
  GemSettingUpdateRequestData,
  GemSettingUpdateRequestFormValues,
  GemSettingUpdateSuccessData,
} from './types';

export const listGemSetting = createAsyncAction(
  Actions.LIST_GEM_SETTINGS_REQUEST,
  Actions.LIST_GEM_SETTINGS_SUCCESS,
  Actions.LIST_GEM_SETTINGS_FAILURE
)<ListRequestPayload, ListState<GemSettingListItem>, AxiosError>();

export const createGemSetting = createAsyncAction(
  Actions.CREATE_GEM_SETTING_REQUEST,
  Actions.CREATE_GEM_SETTING_SUCCESS,
  Actions.CREATE_GEM_SETTING_FAILURE
)<
  FormRequestPayload<GemSettingCreateRequestFormValues, GemSettingCreateRequestData>,
  GemSettingCreateSuccessData,
  AxiosError
>();

export const readGemSetting = createAsyncAction(
  Actions.READ_GEM_SETTING_REQUEST,
  Actions.READ_GEM_SETTING_SUCCESS,
  Actions.READ_GEM_SETTING_FAILURE
)<ReadRequestPayload, GemSettingDetail, AxiosError>();

export const updateGemSetting = createAsyncAction(
  Actions.UPDATE_GEM_SETTING_REQUEST,
  Actions.UPDATE_GEM_SETTING_SUCCESS,
  Actions.UPDATE_GEM_SETTING_FAILURE
)<
  FormRequestPayload<GemSettingUpdateRequestFormValues, GemSettingUpdateRequestData>,
  GemSettingUpdateSuccessData,
  AxiosError
>();

export const deleteGemSetting = createAsyncAction(
  Actions.DELETE_GEM_SETTING_REQUEST,
  Actions.DELETE_GEM_SETTING_SUCCESS,
  Actions.DELETE_GEM_SETTING_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();

export const setGemSettingProducts = createAction(Actions.SET_GEM_SETTING_PRODUCTS)<GemSettingProduct[]>();

export const refreshGemSetting = createAsyncAction(
  Actions.REFRESH_GEM_SETTING_REQUEST,
  Actions.REFRESH_GEM_SETTING_SUCCESS,
  Actions.REFRESH_GEM_SETTING_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
