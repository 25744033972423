import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import {
  TeamMessageContentSettingActions as Actions,
  TeamMessageContentSettingCreateRequestData,
  TeamMessageContentSettingCreateResponseData,
  TeamMessageContentSettingFormValues,
  TeamMessageContentSettingUpdateRequestData,
  TeamMessageContentSettingUpdateResponseData,
} from './types';

export const createTeamMessageContentSetting = createAsyncAction(
  Actions.CREATE_TEAM_MESSAGE_CONTENT_SETTING_REQUEST,
  Actions.CREATE_TEAM_MESSAGE_CONTENT_SETTING_SUCCESS,
  Actions.CREATE_TEAM_MESSAGE_CONTENT_SETTING_FAILURE
)<
  FormRequestPayload<TeamMessageContentSettingFormValues, TeamMessageContentSettingCreateRequestData>,
  TeamMessageContentSettingCreateResponseData,
  AxiosError
>();

export const updateTeamMessageContentSetting = createAsyncAction(
  Actions.UPDATE_TEAM_MESSAGE_CONTENT_SETTING_REQUEST,
  Actions.UPDATE_TEAM_MESSAGE_CONTENT_SETTING_SUCCESS,
  Actions.UPDATE_TEAM_MESSAGE_CONTENT_SETTING_FAILURE
)<
  FormRequestPayload<TeamMessageContentSettingFormValues, TeamMessageContentSettingUpdateRequestData>,
  TeamMessageContentSettingUpdateResponseData,
  AxiosError
>();
