import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  PromotionContentGroupCreateRequestData,
  PromotionContentGroupCreateResponseData,
  PromotionContentGroupSearchItem,
  PromotionContentGroupDetail,
  PromotionContentGroupFormValues,
  PromotionContentGroupListItem,
  PromotionContentGroupUpdateRequestData,
  PromotionContentGroupUpdateResponseData,
} from './types';

export const listPromotionContentGroupApi = createListApi<PromotionContentGroupListItem>('/promotion/contents/groups');

export const listPromotionContentGroupSearchApi =
  createListApi<PromotionContentGroupSearchItem>('/promotion/contents/groups');

export const readPromotionContentGroupApi = createReadApi<PromotionContentGroupDetail>(
  '/promotion/contents/groups/:id'
);

export const createPromotionContentGroupApi = createPostApi<
  PromotionContentGroupFormValues,
  PromotionContentGroupCreateRequestData,
  PromotionContentGroupCreateResponseData
>('/promotion/contents/groups');

export const updatePromotionContentGroupApi = createUpdateApi<
  PromotionContentGroupFormValues,
  PromotionContentGroupUpdateRequestData,
  PromotionContentGroupUpdateResponseData
>('/promotion/contents/groups');
