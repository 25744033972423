import { ReactNode } from 'react';

export interface LayoutState {
  drawer: DrawerOption;
  toast: ToastOption;
  confirm: ConfirmState;
  modal: ModalState;
  fullScreenModal: FullScreenModalState;
}

export enum LayoutActions {
  MAKE_TOAST = 'MAKE_TOAST',
  DISMISS_TOAST = 'DISMISS_TOAST',
  TOGGLE_DRAWER = 'TOGGLE_DRAWER',
  MAKE_CONFIRM = 'MAKE_CONFIRM',
  DISMISS_CONFIRM = 'DISMISS_CONFIRM',
  MAKE_MODAL = 'MAKE_MODAL',
  DISMISS_MODAL = 'DISMISS_MODAL',
  MAKE_FULL_SCREEN_MODAL = 'MAKE_FULL_SCREEN_MODAL',
  DISMISS_FULL_SCREEN_MODAL = 'DISMISS_FULL_SCREEN_MODAL',
}

export const initialState: LayoutState = {
  toast: {
    message: '',
    type: null,
  },
  drawer: { isOpen: true },
  confirm: {
    isOpen: false,
    title: '',
  },
  modal: {
    isOpen: false,
    title: '',
    children: null,
  },
  fullScreenModal: {
    isOpen: false,
    title: '',
    children: null,
  },
};

export interface DrawerOption {
  isOpen: boolean;
}

export interface ToastOption {
  type: ToastType | null;
  message: string;
}

export enum ToastType {
  info = 'info',
  error = 'error',
}

export interface ConfirmPayload {
  title: string;
  description?: string;
  onAgree?: () => void;
  onDisagree?: () => void;
}

export interface ConfirmState extends ConfirmPayload {
  isOpen: boolean;
}

export interface ModalPayload {
  title: string;
  children: ReactNode;
  isOutsideClose?: boolean;
}

export interface ModalState extends ModalPayload {
  isOpen: boolean;
}

export interface FullScreenModalPayload {
  title: string;
  children: ReactNode;
}

export interface FullScreenModalState extends FullScreenModalPayload {
  isOpen: boolean;
}
