import { fromJS, Map } from 'immutable';
import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as promotionScheduleActions from './actions';
import { PromotionScheduleState, initialState } from './types';

const promotionSchedule = createReducer<PromotionScheduleState, RootAction>(initialState)
  .handleAction(promotionScheduleActions.listPromotionSchedule.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(promotionScheduleActions.listPromotionSchedule.success, (state, { payload }) => {
    let newState: Map<keyof typeof state, any> = fromJS(state);
    newState = newState.set('list', {
      ...payload,
      data: payload.data.map(({ taskName, frequency, count, interval, createdAt }) => ({
        taskName,
        frequency,
        count,
        interval,
        createdAt,
      })),
    });

    return newState.toJS() as PromotionScheduleState;
  })
  .handleAction(promotionScheduleActions.readPromotionSchedule.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default promotionSchedule;
