import { combineEpics } from 'redux-observable';

import {
  createDeleteEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createExpressionSegment,
  deleteExpressionSegment,
  listExpressionSegment,
  readExpressionSegment,
  updateExpressionSegment,
} from './actions';
import {
  createExpressionSegmentApi,
  deleteExpressionSegmentApi,
  listExpressionSegmentApi,
  readExpressionSegmentApi,
  updateExpressionSegmentApi,
} from './api';

const updateExpressionSegmentToastEpic = createToastEpic(updateExpressionSegment.success, 'Successfully saved');

const createExpressionSegmentToastEpic = createToastEpic(createExpressionSegment.success, 'Successfully created');

const listExpressionSegmentEpic = createListEpic(listExpressionSegment, listExpressionSegmentApi);

const createExpressionSegmentEpic = createPostEpic(createExpressionSegment, createExpressionSegmentApi);

const readExpressionSegmentEpic = createReadEpic(readExpressionSegment, readExpressionSegmentApi);

const updateExpressionSegmentEpic = createUpdateEpic(updateExpressionSegment, updateExpressionSegmentApi);

const deleteExpressionSegmentEpic = createDeleteEpic(deleteExpressionSegment, deleteExpressionSegmentApi);

export default combineEpics(
  updateExpressionSegmentToastEpic,
  createExpressionSegmentToastEpic,
  listExpressionSegmentEpic,
  createExpressionSegmentEpic,
  readExpressionSegmentEpic,
  updateExpressionSegmentEpic,
  deleteExpressionSegmentEpic
);
