import { initialListState, ListState } from 'store/types';

export enum ShopTabPageActions {
  LIST_SHOP_TAB_PAGE_REQUEST = 'LIST_SHOP_TAB_PAGE#REQUEST',
  LIST_SHOP_TAB_PAGE_SUCCESS = 'LIST_SHOP_TAB_PAGE#SUCCESS',
  LIST_SHOP_TAB_PAGE_FAILURE = 'LIST_SHOP_TAB_PAGE#FAILURE',

  READ_SHOP_TAB_PAGE_REQUEST = 'READ_SHOP_TAB_PAGE#REQUEST',
  READ_SHOP_TAB_PAGE_FAILURE = 'READ_SHOP_TAB_PAGE#FAILURE',
  READ_SHOP_TAB_PAGE_SUCCESS = 'READ_SHOP_TAB_PAGE#SUCCESS',

  CREATE_SHOP_TAB_PAGE_REQUEST = 'CREATE_SHOP_TAB_PAGE#REQUEST',
  CREATE_SHOP_TAB_PAGE_SUCCESS = 'CREATE_SHOP_TAB_PAGE#SUCCESS',
  CREATE_SHOP_TAB_PAGE_FAILURE = 'CREATE_SHOP_TAB_PAGE#FAILURE',

  UPDATE_SHOP_TAB_PAGE_REQUEST = 'UPDATE_SHOP_TAB_PAGE#REQUEST',
  UPDATE_SHOP_TAB_PAGE_SUCCESS = 'UPDATE_SHOP_TAB_PAGE#SUCCESS',
  UPDATE_SHOP_TAB_PAGE_FAILURE = 'UPDATE_SHOP_TAB_PAGE#FAILURE',

  DELETE_SHOP_TAB_PAGE_REQUEST = 'DELETE_SHOP_TAB_PAGE#REQUEST',
  DELETE_SHOP_TAB_PAGE_SUCCESS = 'DELETE_SHOP_TAB_PAGE#SUCCESS',
  DELETE_SHOP_TAB_PAGE_FAILURE = 'DELETE_SHOP_TAB_PAGE#FAILURE',

  // Schedule
  CREATE_SHOP_TAB_PAGE_SCHEDULE_REQUEST = 'CREATE_SHOP_TAB_PAGE_SCHEDULE#REQUEST',
  CREATE_SHOP_TAB_PAGE_SCHEDULE_SUCCESS = 'CREATE_SHOP_TAB_PAGE_SCHEDULE#SUCCESS',
  CREATE_SHOP_TAB_PAGE_SCHEDULE_FAILURE = 'CREATE_SHOP_TAB_PAGE_SCHEDULE#FAILURE',

  UPDATE_SHOP_TAB_PAGE_SCHEDULE_REQUEST = 'UPDATE_SHOP_TAB_PAGE_SCHEDULE#REQUEST',
  UPDATE_SHOP_TAB_PAGE_SCHEDULE_SUCCESS = 'UPDATE_SHOP_TAB_PAGE_SCHEDULE#SUCCESS',
  UPDATE_SHOP_TAB_PAGE_SCHEDULE_FAILURE = 'UPDATE_SHOP_TAB_PAGE_SCHEDULE#FAILURE',

  DELETE_SHOP_TAB_PAGE_SCHEDULE_REQUEST = 'DELETE_SHOP_TAB_PAGE_SCHEDULE#REQUEST',
  DELETE_SHOP_TAB_PAGE_SCHEDULE_SUCCESS = 'DELETE_SHOP_TAB_PAGE_SCHEDULE#SUCCESS',
  DELETE_SHOP_TAB_PAGE_SCHEDULE_FAILURE = 'DELETE_SHOP_TAB_PAGE_SCHEDULE#FAILURE',
}

export interface ShopTab {
  id: number;
  pageId: string;
  name: string;
  activeName: string;
  anchor: string;
  schedules: ShopTabSchedule[];
}

export type ShopTabListItem = ShopTab;

export type ShopTabFormValue = Omit<ShopTab, 'id' | 'schedules'> &
  Partial<{
    activeNameAsString: boolean;
    nameAsString: boolean;
  }>;
export type ShopTabRequestData = ShopTabFormValue;
export type ShopTabResponseData = ShopTab;

// - SCHEDULE -
export type ShopTabSchedule = {
  id: number;
  segmentCode: string;
  startDate: string;
  endDate: string;
  includeCountryCodes: string;
  excludeCountryCodes: string;
  enabled: boolean;
};

export type ShopTabScheduleFormValues = Omit<ShopTabSchedule, 'id'>;
export type ShopTabScheduleCreateRequestData = ShopTabScheduleFormValues;
export type ShopTabScheduleCreateResponseData = ShopTabSchedule;
export type ShopTabScheduleUpdateRequestData = ShopTabScheduleFormValues;
export type ShopTabScheduleUpdateResponseData = ShopTabSchedule;

export interface ShopTabState {
  list: ListState<ShopTabListItem>;
  detail?: ShopTab;
}

export const initialState = {
  list: initialListState,
  detail: undefined,
};
