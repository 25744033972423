import { createContext } from 'react';

export interface SidebarCollapse {
  isOpenCollapse: boolean;
  setIsOpenCollapse: (isOpenCollapse: boolean) => void;
}

const MenuCollapseContext = createContext<SidebarCollapse>({
  isOpenCollapse: false,
  setIsOpenCollapse: () => {},
});

export default MenuCollapseContext;
