import { combineEpics } from 'redux-observable';

import { createListEpic } from 'store/utils';

import { listMatchFilterOption } from './actions';
import { listMatchFilterOptionApi } from './api';

const listMatchFilterOptionListingEpic = createListEpic(listMatchFilterOption, listMatchFilterOptionApi);

export default combineEpics(listMatchFilterOptionListingEpic);
