import { UseFormOptions, useForm as useHookForm } from 'react-hook-form';

const useForm = <T>(params?: UseFormOptions<T>) => {
  const formContext = useHookForm<T>({
    ...params,
    criteriaMode: 'all',
  });
  return formContext;
};
export default useForm;
