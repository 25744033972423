import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, FormRequestPayload } from 'store/types';

import {
  PurposeScheduleActions as Actions,
  PurposeScheduleCreateRequestData,
  PurposeScheduleCreateResponseData,
  PurposeScheduleFormValues,
  PurposeScheduleUpdateRequestData,
  PurposeScheduleUpdateResponseData,
} from './types';

export const createPurposeSchedule = createAsyncAction(
  Actions.CREATE_PURPOSE_SCHEDULE_REQUEST,
  Actions.CREATE_PURPOSE_SCHEDULE_SUCCESS,
  Actions.CREATE_PURPOSE_SCHEDULE_FAILURE
)<
  FormRequestPayload<PurposeScheduleFormValues, PurposeScheduleCreateRequestData>,
  PurposeScheduleCreateResponseData,
  AxiosError
>();

export const updatePurposeSchedule = createAsyncAction(
  Actions.UPDATE_PURPOSE_SCHEDULE_REQUEST,
  Actions.UPDATE_PURPOSE_SCHEDULE_SUCCESS,
  Actions.UPDATE_PURPOSE_SCHEDULE_FAILURE
)<
  FormRequestPayload<PurposeScheduleFormValues, PurposeScheduleUpdateRequestData>,
  PurposeScheduleUpdateResponseData,
  AxiosError
>();

export const deletePurposeSchedule = createAsyncAction(
  Actions.DELETE_PURPOSE_SCHEDULE_REQUEST,
  Actions.DELETE_PURPOSE_SCHEDULE_SUCCESS,
  Actions.DELETE_PURPOSE_SCHEDULE_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();
