import { combineEpics } from 'redux-observable';

import { createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createTeamMessageContentSetting, updateTeamMessageContentSetting } from './actions';
import { createTeamMessageContentSettingApi, updateTeamMessageContentSettingApi } from './api';

const createTeamMessageContentSettingEpic = createPostEpic(
  createTeamMessageContentSetting,
  createTeamMessageContentSettingApi
);

const createTeamMessageContentSettingToastEpic = createToastEpic(
  createTeamMessageContentSetting.success,
  'Successfully created'
);

const updateTeamMessageContentSettingEpic = createUpdateEpic(
  updateTeamMessageContentSetting,
  updateTeamMessageContentSettingApi
);

const updateTeamMessageContentSettingToastEpic = createToastEpic(
  updateTeamMessageContentSetting.success,
  'Successfully saved'
);

export default combineEpics(
  createTeamMessageContentSettingEpic,
  createTeamMessageContentSettingToastEpic,
  updateTeamMessageContentSettingEpic,
  updateTeamMessageContentSettingToastEpic
);
