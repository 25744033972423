import { AxiosError } from 'axios';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload } from 'store/types';

import { FileUploadActions as Actions, FileUploadCreateRequestData, FileUploadCreateSuccessData } from './types';

export const createFileUpload = createAsyncAction(
  Actions.CREATE_FILE_UPLOAD_REQUEST,
  Actions.CREATE_FILE_UPLOAD_SUCCESS,
  Actions.CREATE_FILE_UPLOAD_FAILURE
)<
  FormRequestPayload<FileUploadCreateRequestData, FileUploadCreateRequestData>,
  FileUploadCreateSuccessData,
  AxiosError
>();

export const resetFileUpload = createAction(Actions.RESET_FILE_UPLOAD)<string>();
