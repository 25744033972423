import { initialListState, ListState } from '../types';

export enum MatataInvalidatedPurchaseBySenderActions {
  LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER_REQUEST = 'LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER#REQUEST',
  LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER_SUCCESS = 'LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER#SUCCESS',
  LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER_FAILURE = 'LIST_MATATA_INVALIDATED_PURCHASE_BY_SENDER#FAILURE',

  READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER_REQUEST = 'READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER#REQUEST',
  READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER_SUCCESS = 'READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER#SUCCESS',
  READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER_FAILURE = 'READ_MATATA_INVALIDATED_PURCHASE_BY_SENDER#FAILURE',
}

export interface MatataInvalidatedPurchaseBySender {
  senderId: number;
  senderMatataId: string;
  status: string;
  senderCountryCode: string;
  senderPlatform: string;
  totalGemAmount: number;
  totalStarAmount: number;
  invalidatedAt: string;

  processedAt?: string;
  withdrawnAmount?: number;
}

export const fileHeadersBySender: string[] = [
  'senderId',
  'senderMatataId',
  'senderCountryCode',
  'senderPlatform',
  'totalGemAmount',
  'totalStarAmount',
  'invalidatedAt',
];

export const sortMatataInvalidatedPurchaseBySender = (data: MatataInvalidatedPurchaseBySender) => [
  data.senderId,
  data.senderMatataId,
  data.senderCountryCode,
  data.senderPlatform,
  data.totalGemAmount,
  data.totalStarAmount,
  data.invalidatedAt,
];

export interface MatataInvalidatedPurchaseBySenderState {
  list: ListState<MatataInvalidatedPurchaseBySender>;
  detail?: MatataInvalidatedPurchaseBySender;
}

export const initialState: MatataInvalidatedPurchaseBySenderState = {
  list: initialListState,
  detail: undefined,
};
