import { MlConfigFeatureSegment } from 'store/mlConfigFeatureSegment/types';
import { initialListState, ListState } from 'store/types';

export enum MlConfigFeatureActions {
  LIST_ML_CONFIG_FEATURE_REQUEST = 'LIST_ML_CONFIG_FEATURE#REQUEST',
  LIST_ML_CONFIG_FEATURE_SUCCESS = 'LIST_ML_CONFIG_FEATURE#SUCCESS',
  LIST_ML_CONFIG_FEATURE_FAILURE = 'LIST_ML_CONFIG_FEATURE#FAILURE',

  CREATE_ML_CONFIG_FEATURE_REQUEST = 'CREATE_ML_CONFIG_FEATURE#REQUEST',
  CREATE_ML_CONFIG_FEATURE_SUCCESS = 'CREATE_ML_CONFIG_FEATURE#SUCCESS',
  CREATE_ML_CONFIG_FEATURE_FAILURE = 'CREATE_ML_CONFIG_FEATURE#FAILURE',

  READ_ML_CONFIG_FEATURE_REQUEST = 'READ_ML_CONFIG_FEATURE#REQUEST',
  READ_ML_CONFIG_FEATURE_SUCCESS = 'READ_ML_CONFIG_FEATURE#SUCCESS',
  READ_ML_CONFIG_FEATURE_FAILURE = 'READ_ML_CONFIG_FEATURE#FAILURE',

  UPDATE_ML_CONFIG_FEATURE_REQUEST = 'UPDATE_ML_CONFIG_FEATURE#REQUEST',
  UPDATE_ML_CONFIG_FEATURE_SUCCESS = 'UPDATE_ML_CONFIG_FEATURE#SUCCESS',
  UPDATE_ML_CONFIG_FEATURE_FAILURE = 'UPDATE_ML_CONFIG_FEATURE#FAILURE',
}

export const initialState: MlConfigFeatureState = {
  list: initialListState,
  detail: undefined,
};

export interface MlConfigFeatureDetail {
  id: number;
  type: string;
  modelId: string;
  threshold: number;
  criteriaThreshold: number;
  criteriaWindow: number;
  index: number;
  maxInferencesLimit: number;
  evalOrder: number;
  segments: MlConfigFeatureSegment[];
}
export interface StringLocalizationFormValues {
  headerTitleAsString: boolean;
}

export type MlConfigFeatureListItem = Omit<MlConfigFeatureDetail, 'segments'>;

export interface MlConfigFeatureState {
  list: ListState<MlConfigFeatureListItem>;
  detail?: MlConfigFeatureDetail;
}

export type MlConfigFeatureCreateRequestFormValues = Omit<MlConfigFeatureListItem, 'id'>;
export type MlConfigFeatureCreateRequestData = Omit<MlConfigFeatureListItem, 'id'>;
export type MlConfigFeatureCreateSuccessData = MlConfigFeatureDetail;
export type MlConfigFeatureUpdateRequestFormValues = MlConfigFeatureDetail;
export type MlConfigFeatureUpdateRequestData = MlConfigFeatureDetail;
export type MlConfigFeatureUpdateSuccessData = MlConfigFeatureDetail;
