import {
  BannerSettingMapEntryCreateRequestData,
  BannerSettingMapEntryCreateSuccessData,
  BannerSettingMapEntryUpdateRequestData,
  BannerSettingMapEntryUpdateSuccessData,
} from 'store/bannerSettingMapEntry/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createBannerSettingMapEntryApi = createPostApi<
  BannerSettingMapEntryCreateRequestData,
  BannerSettingMapEntryCreateRequestData,
  BannerSettingMapEntryCreateSuccessData
>('/gem_shops/banner_map_entries');
export const updateBannerSettingMapEntryApi = createUpdateApi<
  BannerSettingMapEntryUpdateRequestData,
  BannerSettingMapEntryUpdateRequestData,
  BannerSettingMapEntryUpdateSuccessData
>('/gem_shops/banner_map_entries/:id');
export const deleteBannerSettingMapEntryApi = createDeleteApi('/gem_shops/banner_map_entries/:id');
