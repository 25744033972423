import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as userItemInventoryActions from './actions';
import { initialState, UserItemInventoryState } from './types';

const userItemInventory = createReducer<UserItemInventoryState, RootAction>(initialState)
  .handleAction(userItemInventoryActions.listUserItemInventory.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(userItemInventoryActions.listUserItemInventory.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }));

export default userItemInventory;
