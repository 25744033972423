export const LOG_ROCKET_APP = process.env.REACT_APP_LOG_ROCKET;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_TRACES_SAMPLE_RATE = process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '0.02';
export const RELEASE = process.env.REACT_APP_RELEASE || 'Unknown';
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'Local';
export type envTypes = 'Production' | 'QA' | 'Dev';
export const IS_PROD = process.env.REACT_APP_ENVIRONMENT === 'Production';
// TODO - 아래 NODE_ENV는 관리되고 있지 않은듯함. 확인 후 정리하기 (QA에서 IS_DEV false로 나옴)
export const IS_DEV = process.env.NODE_ENV !== 'production';
export const IS_TEST = process.env.NODE_ENV === 'test';
export const API_HOST = process.env.REACT_APP_API_HOST;
export const DEV3_API_HOST = process.env.REACT_APP_DEV3_API_HOST as string;
export const KEYCLOCK_CLIENT_ID = process.env.REACT_APP_KEYCLOCK_CLIENT_ID || '';
export const KEYCLOCK_SECRET = process.env.REACT_APP_KEYCLOCK_SECRET || '';
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER;
export const OKTA_CALLBACK = process.env.REACT_APP_OKTA_CALLBACK;
