import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as gemSettingLimitKeyActions from './actions';
import { GemSettingLimitKeyState, initialState } from './types';

const gemSettingLimitKey = createReducer<GemSettingLimitKeyState, RootAction>(initialState).handleAction(
  gemSettingLimitKeyActions.listGemSettingLimitKey.success,
  (state, { payload }) => ({
    ...state,
    list: payload,
  })
);

export default gemSettingLimitKey;
