import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import {
  DeleteRequestPayload,
  FormRequestPayload,
  ListRequestPayload,
  ListState,
  ReadRequestPayload,
} from 'store/types';

import {
  TeamMatchCampaignActions as Actions,
  TeamMatchCampaignCreateRequestData,
  TeamMatchCampaignCreateResponseData,
  TeamMatchCampaignDetail,
  TeamMatchCampaignFormValues,
  TeamMatchCampaignListItem,
  TeamMatchCampaignUpdateRequestData,
  TeamMatchCampaignUpdateResponseData,
  TeamMatchContentCreateRequestData,
  TeamMatchContentCreateResponseData,
  TeamMatchContentDeleteResponseData,
  TeamMatchContentFormValues,
  TeamMatchContentUpdateRequestData,
  TeamMatchContentUpdateResponseData,
} from './types';

export const listTeamMatchCampaign = createAsyncAction(
  Actions.LIST_TEAM_MATCH_CAMPAIGNS_REQUEST,
  Actions.LIST_TEAM_MATCH_CAMPAIGNS_SUCCESS,
  Actions.LIST_TEAM_MATCH_CAMPAIGNS_FAILURE
)<ListRequestPayload, ListState<TeamMatchCampaignListItem>, AxiosError>();

export const readTeamMatchCampaign = createAsyncAction(
  Actions.READ_TEAM_MATCH_CAMPAIGN_REQUEST,
  Actions.READ_TEAM_MATCH_CAMPAIGN_SUCCESS,
  Actions.READ_TEAM_MATCH_CAMPAIGN_FAILURE
)<ReadRequestPayload, TeamMatchCampaignDetail, AxiosError>();

export const createTeamMatchCampaign = createAsyncAction(
  Actions.CREATE_TEAM_MATCH_CAMPAIGN_REQUEST,
  Actions.CREATE_TEAM_MATCH_CAMPAIGN_SUCCESS,
  Actions.CREATE_TEAM_MATCH_CAMPAIGN_FAILURE
)<
  FormRequestPayload<TeamMatchCampaignFormValues, TeamMatchCampaignCreateRequestData>,
  TeamMatchCampaignCreateResponseData,
  AxiosError
>();

export const updateTeamMatchCampaign = createAsyncAction(
  Actions.UPDATE_TEAM_MATCH_CAMPAIGN_REQUEST,
  Actions.UPDATE_TEAM_MATCH_CAMPAIGN_SUCCESS,
  Actions.UPDATE_TEAM_MATCH_CAMPAIGN_FAILURE
)<
  FormRequestPayload<TeamMatchCampaignFormValues, TeamMatchCampaignUpdateRequestData>,
  TeamMatchCampaignUpdateResponseData,
  AxiosError
>();

export const deleteTeamMatchCampaign = createAsyncAction(
  Actions.DELETE_TEAM_MATCH_CAMPAIGN_REQUEST,
  Actions.DELETE_TEAM_MATCH_CAMPAIGN_SUCCESS,
  Actions.DELETE_TEAM_MATCH_CAMPAIGN_FAILURE
)<DeleteRequestPayload, undefined, AxiosError>();

export const createTeamMatchContent = createAsyncAction(
  Actions.CREATE_TEAM_MATCH_CONTENT_REQUEST,
  Actions.CREATE_TEAM_MATCH_CONTENT_SUCCESS,
  Actions.CREATE_TEAM_MATCH_CONTENT_FAILURE
)<
  FormRequestPayload<TeamMatchContentFormValues, TeamMatchContentCreateRequestData>,
  TeamMatchContentCreateResponseData,
  AxiosError
>();

export const updateTeamMatchContent = createAsyncAction(
  Actions.UPDATE_TEAM_MATCH_CONTENT_REQUEST,
  Actions.UPDATE_TEAM_MATCH_CONTENT_SUCCESS,
  Actions.UPDATE_TEAM_MATCH_CONTENT_FAILURE
)<
  FormRequestPayload<TeamMatchContentFormValues, TeamMatchContentUpdateRequestData>,
  TeamMatchContentUpdateResponseData,
  AxiosError
>();

export const deleteTeamMatchContent = createAsyncAction(
  Actions.DELETE_TEAM_MATCH_CONTENT_REQUEST,
  Actions.DELETE_TEAM_MATCH_CONTENT_SUCCESS,
  Actions.DELETE_TEAM_MATCH_CONTENT_FAILURE
)<DeleteRequestPayload, TeamMatchContentDeleteResponseData, AxiosError>();
