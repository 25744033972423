import { createDeleteBodyApi, createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  ManualSegment,
  ManualSegmentCreateRequestData,
  ManualSegmentUpdateRequestData,
  ManualSegmentUpdateSuccessData,
} from './types';

export const listManualSegmentApi = createListApi<ManualSegment>('/segment/manual');

export const readManualSegmentApi = createReadApi<ManualSegment>('/segment/manual/:id');

export const createManualSegmentApi = createPostApi<
  ManualSegmentCreateRequestData,
  ManualSegmentCreateRequestData,
  undefined
>('/segment/manual');

export const updateManualSegmentApi = createUpdateApi<
  ManualSegmentUpdateRequestData,
  ManualSegmentUpdateRequestData,
  ManualSegmentUpdateSuccessData
>('/segment/manual/:id');

export const deleteManualSegmentApi = createDeleteBodyApi('/segment/manual');
