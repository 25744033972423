import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { DeleteRequestPayload, DeleteSuccessPayload, FormRequestPayload } from 'store/types';

import {
  MlConfigFeatureSegmentActions as Actions,
  MlConfigFeatureSegmentCreateRequestData,
  MlConfigFeatureSegmentCreateSuccessData,
  MlConfigFeatureSegmentUpdateRequestData,
  MlConfigFeatureSegmentUpdateSuccessData,
} from './types';

export const createMlConfigFeatureSegment = createAsyncAction(
  Actions.CREATE_SEGMENT_REQUEST,
  Actions.CREATE_SEGMENT_SUCCESS,
  Actions.CREATE_SEGMENT_FAILURE
)<FormRequestPayload<MlConfigFeatureSegmentCreateRequestData>, MlConfigFeatureSegmentCreateSuccessData, AxiosError>();

export const updateMlConfigFeatureSegment = createAsyncAction(
  Actions.UPDATE_SEGMENT_REQUEST,
  Actions.UPDATE_SEGMENT_SUCCESS,
  Actions.UPDATE_SEGMENT_FAILURE
)<FormRequestPayload<MlConfigFeatureSegmentUpdateRequestData>, MlConfigFeatureSegmentUpdateSuccessData, AxiosError>();

export const deleteMlConfigFeatureSegment = createAsyncAction(
  Actions.DELETE_SEGMENT_REQUEST,
  Actions.DELETE_SEGMENT_SUCCESS,
  Actions.DELETE_SEGMENT_FAILURE
)<DeleteRequestPayload, DeleteSuccessPayload, AxiosError>();
