import { createReducer } from 'typesafe-actions';

import { RootAction } from 'store/root/types';

import * as matchFilterSegmentActions from './actions';
import { initialState, MatchFilterSegmentState } from './types';

const matchFilterSegment = createReducer<MatchFilterSegmentState, RootAction>(initialState)
  .handleAction(matchFilterSegmentActions.listMatchFilterSegment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(matchFilterSegmentActions.listMatchFilterSegment.success, (state, { payload }) => ({
    ...state,
    list: payload,
  }))
  .handleAction(matchFilterSegmentActions.readMatchFilterSegment.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));
export default matchFilterSegment;
