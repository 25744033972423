export enum SimulationUploadActions {
  RESET_SIMULATION_UPLOAD = 'RESET_SIMULATION_UPLOAD',
  CREATE_SIMULATION_UPLOAD_REQUEST = 'CREATE_SIMULATION_UPLOAD#REQUEST',
  CREATE_SIMULATION_UPLOAD_SUCCESS = 'CREATE_SIMULATION_UPLOAD#SUCCESS',
  CREATE_SIMULATION_UPLOAD_FAILURE = 'CREATE_SIMULATION_UPLOAD#FAILURE',
}

export const initialState: SimulationUploadState = { data: undefined };

export interface SimulationUploadState {
  data?: SimulationUpload;
}

export interface SimulationUpload {
  url: string;
  encryptionKey: string;
  md5Hash: string;
}

export type SimulationUploadCreateRequestData = any;

export type SimulationUploadCreateSuccessData = SimulationUpload;
