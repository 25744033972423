import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createDeleteBodyEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  bulkDeleteNoticeButtons,
  bulkDeleteNoticeCarousels,
  bulkInsertNoticeButtons,
  bulkInsertNoticeCarousels,
  createNotice,
  listNotice,
  readNotice,
  refreshNotice,
  updateNotice,
} from './actions';
import {
  bulkDeleteNoticeButtonsApi,
  bulkDeleteNoticeCarouselsApi,
  bulkInsertNoticeButtonsApi,
  bulkInsertNoticeCarouselsApi,
  createNoticeApi,
  listNoticeApi,
  readNoticeApi,
  refreshNoticeApi,
  updateNoticeApi,
} from './api';

const updateNoticeToastEpic = createToastEpic(updateNotice.success, 'Successfully saved');

const createNoticeToastEpic = createToastEpic(createNotice.success, 'Successfully created');

const refreshNoticeToastEpic = createToastEpic(refreshNotice.success, 'Successfully refreshed server');

const listNoticeEpic = createListEpic(listNotice, listNoticeApi);

const createNoticeEpic = createPostEpic(createNotice, createNoticeApi);

const readNoticeEpic = createReadEpic(readNotice, readNoticeApi);

const updateNoticeEpic = createUpdateEpic(updateNotice, updateNoticeApi);

const refreshNoticeEpic = createAsyncEpic(refreshNotice, refreshNoticeApi);

const bulkInsertNoticeButtonsEpic = createPostEpic(bulkInsertNoticeButtons, bulkInsertNoticeButtonsApi);

const bulkInsertNoticeCarouselsEpic = createPostEpic(bulkInsertNoticeCarousels, bulkInsertNoticeCarouselsApi);

const bulkDeleteNoticeButtonsEpic = createDeleteBodyEpic(bulkDeleteNoticeButtons, bulkDeleteNoticeButtonsApi);

const bulkDeleteNoticeCarouselsEpic = createDeleteBodyEpic(bulkDeleteNoticeCarousels, bulkDeleteNoticeCarouselsApi);

export default combineEpics(
  updateNoticeToastEpic,
  createNoticeToastEpic,
  refreshNoticeToastEpic,
  listNoticeEpic,
  createNoticeEpic,
  readNoticeEpic,
  updateNoticeEpic,
  refreshNoticeEpic,
  bulkInsertNoticeButtonsEpic,
  bulkInsertNoticeCarouselsEpic,
  bulkDeleteNoticeButtonsEpic,
  bulkDeleteNoticeCarouselsEpic
);
