import { combineEpics } from 'redux-observable';

import { createDeleteEpic, createPostEpic, createToastEpic, createUpdateEpic } from 'store/utils';

import { createPurposeOption, deletePurposeOption, updatePurposeOption } from './actions';
import { createPurposeOptionApi, deletePurposeOptionApi, updatePurposeOptionApi } from './api';

const createPurposeOptionEpic = createPostEpic(createPurposeOption, createPurposeOptionApi);

const createPurposeOptionToastEpic = createToastEpic(createPurposeOption.success, 'Successfully created');

const updatePurposeOptionEpic = createUpdateEpic(updatePurposeOption, updatePurposeOptionApi);

const updatePurposeOptionToastEpic = createToastEpic(updatePurposeOption.success, 'Successfully updated');

const deletePurposeOptionEpic = createDeleteEpic(deletePurposeOption, deletePurposeOptionApi);

const deletePurposeOptionToastEpic = createToastEpic(deletePurposeOption.success, 'Successfully deleted');

export default combineEpics(
  createPurposeOptionEpic,
  createPurposeOptionToastEpic,
  updatePurposeOptionEpic,
  updatePurposeOptionToastEpic,
  deletePurposeOptionEpic,
  deletePurposeOptionToastEpic
);
