import { combineEpics } from 'redux-observable';

import { listAgentHistory } from 'store/agentHistory/actions';

import { createListEpic } from '../utils';

import { listAgentHistoryApi } from './apis';

export const listAgentHistoryEpic = createListEpic(listAgentHistory, listAgentHistoryApi);

export default combineEpics(listAgentHistoryEpic);
