import { combineEpics } from 'redux-observable';

import { createAsyncEpic, createDeleteBodyEpic, createPostEpic, createToastEpic } from 'store/utils';

import {
  listShopSectionPage,
  readShopSectionPage,
  createShopSectionPage,
  deleteShopSectionPage,
  updateShopSectionPage,
  createShopSectionSchedule,
  deleteShopSectionSchedule,
  updateShopSectionSchedule,
  connectShopSectionModule,
  disconnectShopSectionModule,
  editShopSectionModule,
  bulkCreateShopSectionModules,
  bulkDeleteShopSectionModules,
} from './actions';
import {
  readShopSectionApi,
  listShopSectionApi,
  createShopSectionApi,
  deleteShopSectionApi,
  updateShopSectionApi,
  deleteShopSectionPageScheduleApi,
  createShopSectionPageScheduleApi,
  updateShopSectionPageScheduleApi,
  connectShopSectionModuleApi,
  disconnectShopSectionModuleApi,
  editShopSectionModuleApi,
  bulkCreateShopSectionModuleApi,
  bulkDeleteShopSectionModuleApi,
} from './api';

export const listShopSectionEpic = createAsyncEpic(listShopSectionPage, listShopSectionApi);
export const createShopSectionEpic = createAsyncEpic(createShopSectionPage, createShopSectionApi);
export const readShopSectionEpic = createAsyncEpic(readShopSectionPage, readShopSectionApi);
export const deleteShopSectionEpic = createAsyncEpic(deleteShopSectionPage, deleteShopSectionApi);
export const updateShopSectionEpic = createAsyncEpic(updateShopSectionPage, updateShopSectionApi);

// - Module -
const connectShopSectionModuleEpic = createAsyncEpic(connectShopSectionModule, connectShopSectionModuleApi);
const editShopSectionModuleEpic = createAsyncEpic(editShopSectionModule, editShopSectionModuleApi);
const disconnectShopSectionModuleEpic = createAsyncEpic(disconnectShopSectionModule, disconnectShopSectionModuleApi);

// - Section Bulk -
const bulkCreateShopSectionModuleEpic = createPostEpic(bulkCreateShopSectionModules, bulkCreateShopSectionModuleApi);

const bulkCreateShopSectionModuleToastEpic = createToastEpic(
  bulkCreateShopSectionModules.success,
  'Successfully copy from'
);
const bulkCreateFailureShopSectionModuleToastEpic = createToastEpic(
  bulkCreateShopSectionModules.failure,
  'Failed to copy sections'
);

const bulkDeleteShopSectionModuleEpic = createDeleteBodyEpic(
  bulkDeleteShopSectionModules,
  bulkDeleteShopSectionModuleApi
);

// - Schedule -
const createShopSectionScheduleEpic = createAsyncEpic(createShopSectionSchedule, createShopSectionPageScheduleApi);
const editShopSectionScheduleEpic = createAsyncEpic(updateShopSectionSchedule, updateShopSectionPageScheduleApi);
const deleteShopSectionScheduleEpic = createAsyncEpic(deleteShopSectionSchedule, deleteShopSectionPageScheduleApi);

export default combineEpics(
  listShopSectionEpic,
  createShopSectionEpic,
  readShopSectionEpic,
  deleteShopSectionEpic,
  updateShopSectionEpic,
  // module
  connectShopSectionModuleEpic,
  editShopSectionModuleEpic,
  disconnectShopSectionModuleEpic,
  //module bulk
  bulkCreateShopSectionModuleEpic,
  bulkCreateShopSectionModuleToastEpic,
  bulkDeleteShopSectionModuleEpic,
  bulkCreateFailureShopSectionModuleToastEpic,
  //schedule
  createShopSectionScheduleEpic,
  editShopSectionScheduleEpic,
  deleteShopSectionScheduleEpic
);
