import React, { FC, useCallback, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import clsx from 'clsx';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import CollapseMenu from 'components/CollapseMenu';
import DraggableCollapseMenu from 'components/DraggableCollapseMenu';
import EditableText from 'components/EditableText';
import Menu from 'components/Menu';
import { CollapseMenuItem, MenuType } from 'components/Menu/types';
import useSignedIn from 'hooks/useSignedIn';
import * as bookmarkActions from 'store/bookmark/actions';
import { layoutActions } from 'store/layout/slice';
import { RootState } from 'store/root/types';
import { REALM } from 'utils/okta';
import routes from 'utils/routes';
import useRole from 'hooks/useRole';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
}));

const selector = ({
  layout: {
    drawer: { isOpen },
  },
  bookmark: { list: bookmarks },
}: RootState) => ({
  isOpen,
  bookmarks,
});

const Sidebar: FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { isOpen, bookmarks } = useSelector(selector, shallowEqual);

  const isSignedIn = useSignedIn();

  const { roles } = useRole();

  const handleClickClose = () => {
    dispatch(layoutActions.toggleDrawer(false));
  };

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      dispatch(
        bookmarkActions.reorderBookmarks({
          sourceIndex: result.source.index,
          destinationIndex: result.destination.index,
        })
      );
    },
    [dispatch]
  );

  const handleSuccessEdit = useCallback(
    (path: string) => (newName: string) => {
      dispatch(bookmarkActions.renameBookmark({ path, newName }));
    },
    [dispatch]
  );

  const bookmarkCollapseMenus: CollapseMenuItem[] = useMemo(
    () =>
      bookmarks.map(({ name, path }) => ({
        label: name,
        path,
        render: <EditableText value={name} onEditEnd={handleSuccessEdit(path)} />,
      })),
    [bookmarks, handleSuccessEdit]
  );

  if (!isSignedIn) {
    return null;
  }

  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={handleClickClose}>
          {theme.direction === 'rtl' ? <ChevronRightRoundedIcon /> : <ChevronLeftRoundedIcon />}
        </IconButton>
      </Toolbar>

      <Divider />

      <Box display='flex' flexDirection='column' flexGrow={1}>
        <List>
          <ListSubheader>APPS</ListSubheader>

          <Menu
            type={MenuType.COLLAPSE}
            label='Bookmark'
            IconComponent={StarBorderRoundedIcon}
            openCollapse={bookmarks.length > 0}
          >
            <DraggableCollapseMenu
              collapseMenus={bookmarkCollapseMenus}
              onDragEnd={handleDragEnd}
              style={{ paddingLeft: 16 }}
            />
          </Menu>
        </List>

        <List>
          <ListSubheader>PAGES</ListSubheader>

          {routes.map((route) => {
            if (!route.realm.includes(REALM)) {
              return null;
            }

            const collapseMenus = route.subRoutes
              .filter((subRoute) => subRoute.roles === undefined || subRoute.roles.some((role) => roles.includes(role)))
              .map(({ label, path }) => ({ label, path }));
            const openCollapse = collapseMenus.some((collapseMenu) => pathname === collapseMenu.path);

            return (
              <Menu
                type={MenuType.COLLAPSE}
                key={route.key}
                label={route.label}
                IconComponent={route.IconComponent}
                openCollapse={openCollapse}
              >
                <CollapseMenu collapseMenus={collapseMenus} unmountOnExit />
              </Menu>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
