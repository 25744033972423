import { TypographyStyle } from '@material-ui/core/styles';

import { ENVIRONMENT, envTypes } from 'constants/env';

const defaultFontStyle = {
  fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", 
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  fontSize: 13,
};
const headingCommonStyle: TypographyStyle = {
  fontWeight: 600,
  lineHeight: '1.43',
  letterSpacing: 0,
};

export const overrides = {
  MuiCard: {
    root: {
      flex: 1,
    },
  },
  MuiCardHeader: {
    title: {
      margin: 0,
      fontSize: '1.15rem',
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 0,
      marginRight: 16,
    },
  },
  MuiListItemText: {
    primary: { fontSize: '0.9rem' },
    secondary: { fontSize: '0.85rem' },
  },
  MuiListSubheader: { root: { fontSize: '0.7rem' } },
  MuiDialogTitle: {
    root: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
    },
  },
  MuiPopover: {
    root: {
      '& .MuiPickersYear-yearSelected': {
        fontSize: '1.5rem',
        lineHeight: 1.334,
      },
      '& h4.MuiPickersToolbarText-toolbarTxt': {
        margin: 0,
        fontSize: '2.125rem',
        fontWeight: 400,
        lineHeight: 1.235,
      },
    },
  },
  MuiTooltip: { tooltip: { fontSize: '0.75rem' } },
};

type EnvAndColor = {
  [env in envTypes]: string;
};
const PrimaryColorMapWithEnv: EnvAndColor = {
  Production: '#ff9800',
  QA: '#00dd99',
  Dev: '#00dd99',
};

export const palettes = {
  dark: {
    primary: {
      light: '#ffac33',
      main: PrimaryColorMapWithEnv[ENVIRONMENT as envTypes] ?? PrimaryColorMapWithEnv['Dev'],
      dark: '#b26a00',
    },
    text: { disabled: '#878787' },
  },
  light: {
    primary: {
      light: '#00efcc',
      main: PrimaryColorMapWithEnv[ENVIRONMENT as envTypes] ?? PrimaryColorMapWithEnv['Dev'],
      dark: '#009f99',
    },
  },
};

export const typography = {
  ...defaultFontStyle,

  h1: {
    margin: '0.67em 0',
    fontSize: '1.7rem',
    ...headingCommonStyle,
  },
  h2: {
    margin: '0.83em 0',
    fontSize: '1.3rem',
    ...headingCommonStyle,
  },
  h3: {
    margin: '1em 0',
    fontSize: '1.15rem',
    ...headingCommonStyle,
  },
  h4: {
    margin: '1.33em 0',
    fontSize: '1rem',
    ...headingCommonStyle,
  },
  h5: {
    margin: '1.67em 0',
    fontSize: '0.85rem',
    ...headingCommonStyle,
  },
  h6: {
    margin: '2.33em 0',
    fontSize: '0.65rem',
    ...headingCommonStyle,
  },
};
