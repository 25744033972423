import { ActionType } from 'typesafe-actions';

import * as ageManageActions from 'store/ageManage/actions';
import { AgeManageActions } from 'store/ageManage/types';
import * as agentHistoryActions from 'store/agentHistory/actions';
import { AgentHistoryActions, AgentHistoryState } from 'store/agentHistory/types';
import * as agentManageActions from 'store/agentManage/actions';
import { AgentManageActions, AgentManageState } from 'store/agentManage/types';
import * as bannerSettingActions from 'store/bannerSetting/actions';
import { BannerSettingActions, BannerSettingState } from 'store/bannerSetting/types';
import * as bannerSettingMapEntryActions from 'store/bannerSettingMapEntry/actions';
import { BannerSettingMapEntryActions } from 'store/bannerSettingMapEntry/types';
import * as blockPhoneManageActions from 'store/blockPhoneManage/actions';
import { BlockPhoneManageActions } from 'store/blockPhoneManage/types';
import * as bookmarkActions from 'store/bookmark/actions';
import { BookmarkActions, BookmarkState } from 'store/bookmark/types';
import * as convertUserKeyActions from 'store/convertUserKey/actions';
import { ConvertUserKeyActions, ConvertUserKeyState } from 'store/convertUserKey/types';
import * as countryManageActions from 'store/countryManage/types';
import { CountryManageActions } from 'store/countryManage/types';
import * as decoColorPresetActions from 'store/decoColorPreset/actions';
import { DecoColorPresetActions, DecoColorPresetState } from 'store/decoColorPreset/types';
import * as decoItemActions from 'store/decoItem/actions';
import { DecoItemActions, DecoItemState } from 'store/decoItem/types';
import * as decoResourceActions from 'store/decoResource/actions';
import { DecoResourceActions } from 'store/decoResource/types';
import * as decoSettingActions from 'store/decoSetting/actions';
import { DecoSettingActions, DecoSettingState } from 'store/decoSetting/types';
import * as decoSettingProductActions from 'store/decoSettingProduct/actions';
import { DecoSettingProductActions } from 'store/decoSettingProduct/types';
import * as decoSettingScheduleActions from 'store/decoSettingSchedule/actions';
import { DecoSettingScheduleActions } from 'store/decoSettingSchedule/types';
import * as downloadableContentActions from 'store/downloadableContent/actions';
import { DownloadableContentActions, DownloadableContentState } from 'store/downloadableContent/types';
import * as expressionSegmentActions from 'store/expressionSegment/actions';
import { ExpressionSegmentActions, ExpressionSegmentState } from 'store/expressionSegment/types';
import * as fileS3UploadActions from 'store/fileS3Upload/actions';
import { FileS3UploadActions, FileS3UploadState } from 'store/fileS3Upload/types';
import * as fileSettingActions from 'store/fileSetting/actions';
import { FileSettingActions, FileSettingState } from 'store/fileSetting/types';
import * as fileUploadActions from 'store/fileUpload/actions';
import { FileUploadActions, FileUploadState } from 'store/fileUpload/types';
import * as simulationUploadActions from 'store/simulationUpload/actions';
import { SimulationUploadActions, SimulationUploadState } from 'store/simulationUpload/types';
import * as gemSettingActions from 'store/gemSetting/actions';
import { GemSettingActions, GemSettingState } from 'store/gemSetting/types';
import * as gemSettingLimitKeyActions from 'store/gemSettingLimitKey/actions';
import { GemSettingLimitKeyActions, GemSettingLimitKeyState } from 'store/gemSettingLimitKey/types';
import * as gemSettingMapEntryActions from 'store/gemSettingMapEntry/actions';
import { GemSettingMapEntryActions } from 'store/gemSettingMapEntry/types';
import * as gemSettingProductActions from 'store/gemSettingProduct/actions';
import { GemSettingProductActions } from 'store/gemSettingProduct/types';
import * as genderManageActions from 'store/genderManage/actions';
import { GenderManageActions } from 'store/genderManage/types';
import * as iabProductActions from 'store/iabProduct/actions';
import { IabProductActions, IabProductState } from 'store/iabProduct/types';
import * as imageSettingActions from 'store/imageSetting/actions';
import { ImageSettingActions, ImageSettingState } from 'store/imageSetting/types';
import { IsFetchingState } from 'store/isFetching/types';
import * as itemCategoryActions from 'store/itemCategory/actions';
import { ItemCategoryActions, ItemCategoryState } from 'store/itemCategory/types';
import * as itemProductActions from 'store/itemProduct/actions';
import { ItemProductActions, ItemProductState } from 'store/itemProduct/types';
import { layoutActions } from 'store/layout/slice';
import { LayoutActions, LayoutState } from 'store/layout/types';
import * as localizationKeyActions from 'store/localizationKey/actions';
import { LocalizationKeyActions, LocalizationKeyState } from 'store/localizationKey/types';
import * as manualSegmentActions from 'store/manualSegment/actions';
import { ManualSegmentActions, ManualSegmentState } from 'store/manualSegment/types';
import * as matchFilterActions from 'store/matchFilter/actions';
import { MatchFilterActions, MatchFilterState } from 'store/matchFilter/types';
import * as matchFilterConfigActions from 'store/matchFilterConfig/actions';
import { MatchFilterConfigActions } from 'store/matchFilterConfig/types';
import * as matchFilterOptionActions from 'store/matchFilterOption/actions';
import { MatchFilterOptionActions, MatchFilterOptionState } from 'store/matchFilterOption/types';
import * as matchFilterOptionListingActions from 'store/matchFilterOptionListing/actions';
import { MatchFilterOptionListingActions, MatchFilterOptionListingState } from 'store/matchFilterOptionListing/types';
import * as matchFilterSegmentActions from 'store/matchFilterSegment/actions';
import { MatchFilterSegmentActions, MatchFilterSegmentState } from 'store/matchFilterSegment/types';
import * as matchFilterSettingActions from 'store/matchFilterSetting/actions';
import { MatchFilterSettingActions, MatchFilterSettingState } from 'store/matchFilterSetting/types';
import * as matchTagManageActions from 'store/matchTagManage/actions';
import { MatchTagActions, MatchTagState } from 'store/matchTagManage/types';
import * as moniNoticeActions from 'store/moniNotice/actions';
import { MoniNoticeActions, MoniNoticeState } from 'store/moniNotice/types';
import * as noticeActions from 'store/notice/actions';
import { NoticeActions, NoticeState } from 'store/notice/types';
import * as noticePopupButtonActions from 'store/noticePopupButton/actions';
import { NoticePopupButtonActions } from 'store/noticePopupButton/types';
import * as noticePopupCarouselActions from 'store/noticePopupCarousel/actions';
import { NoticePopupCarouselActions } from 'store/noticePopupCarousel/types';
import * as noticePopupScheduleActions from 'store/noticePopupSchedule/actions';
import { NoticePopupScheduleActions } from 'store/noticePopupSchedule/types';
import * as productActions from 'store/product/actions';
import { ProductActions, ProductState } from 'store/product/types';
import * as productIconActions from 'store/productIcons/actions';
import { ProductIconActions, ProductIconState } from 'store/productIcons/types';
import * as profileManageActions from 'store/profileManage/actions';
import { ProfileManageActions, ProfileManageState } from 'store/profileManage/types';
import * as promotionContentActions from 'store/promotionContent/actions';
import { PromotionContentActions, PromotionContentState } from 'store/promotionContent/types';
import * as promotionSettingActions from 'store/promotionSetting/actions';
import { PromotionSettingActions, PromotionSettingState } from 'store/promotionSetting/types';
import * as punishAction from 'store/punish/actions';
import { PunishActions, PunishState } from 'store/punish/types';
import * as punishmentAction from 'store/punishment/actions';
import { PunishmentActions, PunishmentState } from 'store/punishment/types';
import * as matataSuspensionHistoryAction from 'store/matataSuspensionHistory/actions';
import { MatataSuspensionHistoryActions, MatataSuspensionHistoryState } from 'store/matataSuspensionHistory/types';
import * as matataDeviceUnbindingAction from 'store/matataDeviceUnbinding/actions';
import { MatataDeviceUnbindingActions, MatataDeviceUnbindingState } from 'store/matataDeviceUnbinding/types';
import * as realtimeMonitoringActions from 'store/realtimeMonitoring/actions';
import { RealtimeMonitoringActions, RealtimeMonitoringState } from 'store/realtimeMonitoring/types';
import * as settingActions from 'store/setting/actions';
import { SettingActions, SettingState } from 'store/setting/types';
import * as specialPromotionSettingActions from 'store/specialPromotionSetting/actions';
import { SpecialPromotionSettingActions, SpecialPromotionSettingState } from 'store/specialPromotionSetting/types';
import * as specialPromotionSettingLimitKeyActions from 'store/specialPromotionSettingLimitKey/actions';
import {
  SpecialPromotionSettingLimitKeyActions,
  SpecialPromotionSettingLimitKeyState,
} from 'store/specialPromotionSettingLimitKey/types';
import * as specialPromotionSettingMapEntryActions from 'store/specialPromotionSettingMapEntry/actions';
import { SpecialPromotionSettingMapEntryActions } from 'store/specialPromotionSettingMapEntry/types';
import * as specialPromotionSettingProductActions from 'store/specialPromotionSettingProduct/actions';
import { SpecialPromotionSettingProductActions } from 'store/specialPromotionSettingProduct/types';
import * as stringLocalizationActions from 'store/stringLocalization/actions';
import { StringLocalizationActions, StringLocalizationState } from 'store/stringLocalization/types';
import * as teamMatchCampaignActions from 'store/teamMatchCampaign/actions';
import { TeamMatchCampaignActions, TeamMatchCampaignState } from 'store/teamMatchCampaign/types';
import * as teamMatchProfileActions from 'store/teamMatchProfile/actions';
import { TeamMatchProfileActions, TeamMatchProfileState } from 'store/teamMatchProfile/types';
import * as teamMessageActions from 'store/teamMessage/actions';
import { TeamMessageActions, TeamMessageState } from 'store/teamMessage/types';
import * as teamMessageContentSettingActions from 'store/teamMessageContentSetting/actions';
import { TeamMessageContentSettingActions } from 'store/teamMessageContentSetting/types';
import * as teamMessageImageSettingActions from 'store/teamMessageImageSetting/actions';
import { TeamMessageImageSettingActions } from 'store/teamMessageImageSetting/types';
import * as teamMessageLinkSettingActions from 'store/teamMessageLinkSetting/actions';
import { TeamMessageLinkSettingActions } from 'store/teamMessageLinkSetting/types';
import * as uploadFileListActions from 'store/uploadFileList/actions';
import { UploadFileListActions, UploadFileListState } from 'store/uploadFileList/types';
import * as userActions from 'store/user/actions';
import { UserActions, UserState } from 'store/user/types';
import * as userItemInventoryActions from 'store/userItemInventory/actions';
import { UserItemInventoryActions, UserItemInventoryState } from 'store/userItemInventory/types';
import * as userSegmentActions from 'store/userSegment/actions';
import { UserSegmentActions, UserSegmentState } from 'store/userSegment/types';
import * as waterSlideBannerActions from 'store/waterSlideBanner/actions';
import { WaterSlideBannerActions, WaterSlideBannerState } from 'store/waterSlideBanner/types';
import * as shopMainPageActions from 'store/shopMainPage/actions';
import { ShopMainPageActions, ShopMainState } from 'store/shopMainPage/types';
import * as shopModulePageActions from 'store/shopModulePage/actions';
import { ShopModulePageActions, ShopModuleState } from 'store/shopModulePage/types';
import * as shopSectionPageActions from 'store/shopSectionPage/actions';
import { ShopSectionPageActions, ShopSectionState } from 'store/shopSectionPage/types';
import * as shopTabPageActions from 'store/shopTabPage/actions';
import { ShopTabPageActions, ShopTabState } from 'store/shopTabPage/types';
import * as shopDetailPageActions from 'store/shopDetailPage/actions';
import { ShopDetailPageActions, ShopDetailState } from 'store/shopDetailPage/types';
import * as limitedEditionActions from 'store/limitedEditionPage/actions';
import { LimitedEditionPageActions, LimitedEditionState } from 'store/limitedEditionPage/types';
import * as friendshipActions from '../friendship/actions';
import { FriendshipActions } from '../friendship/types';
import * as promotionPublishActions from 'store/promotionPublish/actions';
import * as shopEntryBannerActions from 'store/shopEntryBanner/actions';
import { ShopEntryBannerActions, ShopEntryBannerState } from 'store/shopEntryBanner/types';
import { PromotionPublishActions } from 'store/promotionPublish/types';
import * as shopEntryBannerScheduleActions from 'store/shopEntryBannerSchedule/actions';
import { ShopEntryBannerScheduleActions } from 'store/shopEntryBannerSchedule/types';
import * as promotionPublishHistoryActions from 'store/promotionPublishHistory/actions';
import { PromotionPublishHistoryActions, PromotionPublishHistoryState } from 'store/promotionPublishHistory/types';
import * as remoteConfigActions from 'store/remoteConfig/actions';
import { RemoteConfigActions, RemoteConfigState } from 'store/remoteConfig/types';
import * as gemProductTierActions from 'store/gemProductTier/actions';
import { GemProductTierActions, GemProductTierState } from 'store/gemProductTier/types';
import * as gemProductTierScheduleActions from 'store/gemProductTierSchedule/actions';
import { GemProductTierScheduleActions } from 'store/gemProductTierSchedule/types';
import * as messageBannerActions from 'store/messageBanner/actions';
import { MessageBannerActions } from 'store/messageBanner/types';
import * as abTestActions from 'store/abTest/actions';
import { AbTestActions, AbTestState } from 'store/abTest/types';
import * as abTestCriteriaActions from 'store/abTestCriteria/actions';
import { AbTestCriteriaActions } from 'store/abTestCriteria/types';
import * as criteriaSegmentActions from 'store/criteriaSegment/actions';
import { CriteriaSegmentActions, CriteriaSegmentState } from 'store/criteriaSegment/types';
import * as notificationCenterBannerActions from 'store/notificationCenterBanner/actions';
import { NotificationCenterBannerActions, NotificationCenterBannerState } from 'store/notificationCenterBanner/types';
import * as notificationCenterBannerScheduleActions from 'store/notificationCenterBannerSchedule/actions';
import { NotificationCenterBannerScheduleActions } from 'store/notificationCenterBannerSchedule/types';
import * as notificationContentActions from 'store/notificationContent/actions';
import { NotificationContentActions, NotificationContentState } from 'store/notificationContent/types';
import * as pushContentActions from 'store/pushContent/actions';
import { PushContentActions, PushContentState } from 'store/pushContent/types';
import * as gemProvideActions from 'store/gemProvide/actions';
import { GemProvideActions } from 'store/gemProvide/types';
import * as itemProvideActions from 'store/itemProductProvide/actions';
import { ItemProvideActions } from 'store/itemProductProvide/types';
import * as itemPackageProvideActions from 'store/itemPackageProvide/actions';
import { ItemPackageProvideActions } from 'store/itemPackageProvide/types';
import * as configStoreActions from 'store/configStore/actions';
import { ConfigStoreActions, ConfigStoreState } from 'store/configStore/types';
import * as gemWithdrawActions from 'store/gemWithdraw/actions';
import { GemWithdrawActions } from 'store/gemWithdraw/types';
import * as gemCancelPurchaseActions from 'store/gemCancelPurchase/actions';
import { CancelGemPurchaseActions } from 'store/gemCancelPurchase/types';
import * as invalidatedPurchaseActions from 'store/invalidatedPurchase/actions';
import { InvalidatedPurchaseActions, InvalidatedPurchaseState } from 'store/invalidatedPurchase/types';
import { ShopModuleGroupPageActions, ShopModuleGroupState } from 'store/shopModuleGroup/types';
import * as shopModuleGroupPageActions from 'store/shopModuleGroup/actions';
import * as visitorConfigActions from 'store/visitorConfig/actions';
import { VisitorConfigActions, VisitorConfigState } from 'store/visitorConfig/types';
import * as visitorAbTestActions from 'store/visitorAbTest/actions';
import { VisitorAbTestActions, VisitorAbTestState } from 'store/visitorAbTest/types';
import * as visitorAbTestCriteriaActions from 'store/visitorAbTestCriteria/actions';
import { VisitorAbTestCriteriaActions } from 'store/visitorAbTestCriteria/types';
import * as visitorSegmentActions from 'store/visitorSegment/actions';
import { VisitorSegmentActions, VisitorSegmentState } from 'store/visitorSegment/types';

import * as matataInvalidatedPurchaseActions from 'store/matataInvalidatedPurchase/actions';
import {
  MatataInvalidatedPurchaseActions,
  MatataInvalidatedPurchaseState,
} from 'store/matataInvalidatedPurchase/types';
import * as matataInvalidatedPurchaseBySenderActions from 'store/matataInvalidatedPurchaseBySender/actions';
import {
  MatataInvalidatedPurchaseBySenderActions,
  MatataInvalidatedPurchaseBySenderState,
} from 'store/matataInvalidatedPurchaseBySender/types';
import * as matataInvalidatedPurchaseByReceiverActions from 'store/matataInvalidatedPurchaseByReceiver/actions';
import {
  MatataInvalidatedPurchaseByReceiverActions,
  MatataInvalidatedPurchaseByReceiverState,
} from 'store/matataInvalidatedPurchaseByReceiver/types';
import * as promotionScheduleActions from 'store/promotionSchedule/actions';
import { PromotionScheduleActions, PromotionScheduleState } from 'store/promotionSchedule/types';
import * as promotionScheduleTargetActions from 'store/promotionScheduleTarget/actions';
import { PromotionScheduleTargetActions } from 'store/promotionScheduleTarget/types';
import * as promotionScheduleTestActions from 'store/promotionScheduleTest/actions';
import { PromotionScheduleTestActions } from 'store/promotionScheduleTest/types';

import * as bigquerySegmentActions from 'store/bigquerySegment/actions';
import { BigquerySegmentActions, BigquerySegmentState } from 'store/bigquerySegment/types';
import * as bigquerySegmentDeclarationActions from 'store/bigquerySegmentDeclaration/actions';
import { BigquerySegmentDeclarationActions } from 'store/bigquerySegmentDeclaration/types';
import * as webDecoSettingActions from 'store/webDecoSetting/actions';
import { WebDecoSettingActions, WebDecoSettingState } from 'store/webDecoSetting/types';
import * as webDecoSettingEffectActions from 'store/webDecoSettingEffect/actions';
import { WebDecoSettingEffectActions } from 'store/webDecoSettingEffect/types';
import * as webDecoSettingScheduleActions from 'store/webDecoSettingSchedule/actions';
import { WebDecoSettingScheduleActions } from 'store/webDecoSettingSchedule/types';

import * as webDecoEffectActions from 'store/webDecoEffect/actions';
import { WebDecoEffectActions, WebDecoEffectState } from 'store/webDecoEffect/types';

import * as purposeActions from 'store/purpose/actions';
import { PurposeActions, PurposeState } from 'store/purpose/types';
import * as purposeScheduleActions from 'store/purposeSchedule/types';
import { PurposeScheduleActions } from 'store/purposeSchedule/types';
import * as purposeOptionActions from 'store/purposeOption/types';
import { PurposeOptionActions } from 'store/purposeOption/types';

import { AppStoreReviewActions, AppStoreReviewState } from 'store/appStoreReview/types';
import * as appStoreReviewActions from 'store/appStoreReview/actions';

import * as mlConfigModelActions from 'store/mlConfigModel/actions';
import { MlConfigModelActions, MlConfigModelState } from 'store/mlConfigModel/types';
import * as mlConfigModelSegmentActions from 'store/mlConfigModelSegment/actions';
import { MlConfigModelSegmentActions } from 'store/mlConfigModelSegment/types';

import * as mlConfigFeatureActions from 'store/mlConfigFeature/actions';
import { MlConfigFeatureActions, MlConfigFeatureState } from 'store/mlConfigFeature/types';
import * as mlConfigFeatureSegmentActions from 'store/mlConfigFeatureSegment/actions';
import { MlConfigFeatureSegmentActions } from 'store/mlConfigFeatureSegment/types';

import * as promotionContentGroupActions from 'store/promotionContentGroup/actions';
import { PromotionContentGroupActions, PromotionContentGroupState } from 'store/promotionContentGroup/types';

export interface RootState {
  user: UserState;
  convertUserKey: ConvertUserKeyState;
  layout: LayoutState;
  isFetching: IsFetchingState;
  gemSetting: GemSettingState;
  gemSettingLimitKey: GemSettingLimitKeyState;
  specialPromotionSetting: SpecialPromotionSettingState;
  specialPromotionSettingLimitKey: SpecialPromotionSettingLimitKeyState;
  bannerSetting: BannerSettingState;
  expressionSegment: ExpressionSegmentState;
  productIcon: ProductIconState;
  iabProduct: IabProductState;
  product: ProductState;
  userSegment: UserSegmentState;
  imageSetting: ImageSettingState;
  fileSetting: FileSettingState;
  fileUpload: FileUploadState;
  simulationUpload: SimulationUploadState;
  localizationKey: LocalizationKeyState;
  stringLocalization: StringLocalizationState;
  setting: SettingState;
  bookmark: BookmarkState;
  notice: NoticeState;
  profileManage: ProfileManageState;
  realtimeMonitoring: RealtimeMonitoringState;
  manualSegment: ManualSegmentState;
  userItemInventory: UserItemInventoryState;
  moniNotice: MoniNoticeState;
  matchFilter: MatchFilterState;
  matchFilterOptionListing: MatchFilterOptionListingState;
  matchFilterOption: MatchFilterOptionState;
  matchFilterSegment: MatchFilterSegmentState;
  matchFilterSetting: MatchFilterSettingState;
  downloadableContent: DownloadableContentState;
  itemProduct: ItemProductState;
  itemCategory: ItemCategoryState;
  decoItem: DecoItemState;
  decoSetting: DecoSettingState;
  teamMatchCampaign: TeamMatchCampaignState;
  teamMatchProfile: TeamMatchProfileState;
  fileS3Upload: FileS3UploadState;
  promotionSetting: PromotionSettingState;
  promotionContent: PromotionContentState;
  teamMessage: TeamMessageState;
  agentManage: AgentManageState;
  agentHistory: AgentHistoryState;
  punish: PunishState;
  punishment: PunishmentState;
  matataSuspensionHistory: MatataSuspensionHistoryState;
  matataDeviceUnbinding: MatataDeviceUnbindingState;
  matchTag: MatchTagState;
  decoColorPreset: DecoColorPresetState;
  uploadFileList: UploadFileListState;
  waterSlideBanner: WaterSlideBannerState;
  shopMain: ShopMainState;
  shopDetail: ShopDetailState;
  shopSection: ShopSectionState;
  shopModule: ShopModuleState;
  shopModuleGroup: ShopModuleGroupState;
  shopTab: ShopTabState;
  limitedEdition: LimitedEditionState;
  shopEntryBanner: ShopEntryBannerState;
  promotionPublishHistory: PromotionPublishHistoryState;
  remoteConfig: RemoteConfigState;
  gemProductTier: GemProductTierState;
  abTest: AbTestState;
  criteriaSegment: CriteriaSegmentState;
  notificationCenterBanner: NotificationCenterBannerState;
  notificationContent: NotificationContentState;
  pushContent: PushContentState;
  configStore: ConfigStoreState;
  invalidatedPurchase: InvalidatedPurchaseState;
  visitorConfig: VisitorConfigState;
  visitorAbTest: VisitorAbTestState;
  visitorSegment: VisitorSegmentState;
  matataInvalidatedPurchase: MatataInvalidatedPurchaseState;
  matataInvalidatedPurchaseBySender: MatataInvalidatedPurchaseBySenderState;
  matataInvalidatedPurchaseByReceiver: MatataInvalidatedPurchaseByReceiverState;
  promotionSchedule: PromotionScheduleState;
  bigquerySegment: BigquerySegmentState;
  webDecoEffect: WebDecoEffectState;
  webDecoSetting: WebDecoSettingState;
  appStoreReview: AppStoreReviewState;
  purpose: PurposeState;
  mlConfigModel: MlConfigModelState;
  mlConfigFeature: MlConfigFeatureState;
  promotionContentGroup: PromotionContentGroupState;
}

export type RootAction =
  | ActionType<typeof userActions>
  | ActionType<typeof layoutActions>
  | ActionType<typeof bannerSettingActions>
  | ActionType<typeof expressionSegmentActions>
  | ActionType<typeof ageManageActions>
  | ActionType<typeof blockPhoneManageActions>
  | ActionType<typeof profileManageActions>
  | ActionType<typeof genderManageActions>
  | ActionType<typeof countryManageActions>
  | ActionType<typeof matchTagManageActions>
  | ActionType<typeof convertUserKeyActions>
  | ActionType<typeof productIconActions>
  | ActionType<typeof iabProductActions>
  | ActionType<typeof productActions>
  | ActionType<typeof userSegmentActions>
  | ActionType<typeof gemSettingActions>
  | ActionType<typeof gemSettingProductActions>
  | ActionType<typeof gemSettingMapEntryActions>
  | ActionType<typeof gemSettingLimitKeyActions>
  | ActionType<typeof specialPromotionSettingActions>
  | ActionType<typeof specialPromotionSettingProductActions>
  | ActionType<typeof specialPromotionSettingMapEntryActions>
  | ActionType<typeof specialPromotionSettingLimitKeyActions>
  | ActionType<typeof imageSettingActions>
  | ActionType<typeof fileSettingActions>
  | ActionType<typeof fileUploadActions>
  | ActionType<typeof simulationUploadActions>
  | ActionType<typeof bannerSettingMapEntryActions>
  | ActionType<typeof localizationKeyActions>
  | ActionType<typeof settingActions>
  | ActionType<typeof noticeActions>
  | ActionType<typeof noticePopupButtonActions>
  | ActionType<typeof noticePopupCarouselActions>
  | ActionType<typeof noticePopupScheduleActions>
  | ActionType<typeof stringLocalizationActions>
  | ActionType<typeof bookmarkActions>
  | ActionType<typeof settingActions>
  | ActionType<typeof realtimeMonitoringActions>
  | ActionType<typeof manualSegmentActions>
  | ActionType<typeof userItemInventoryActions>
  | ActionType<typeof moniNoticeActions>
  | ActionType<typeof matchFilterActions>
  | ActionType<typeof matchFilterConfigActions>
  | ActionType<typeof matchFilterOptionListingActions>
  | ActionType<typeof matchFilterOptionActions>
  | ActionType<typeof matchFilterSegmentActions>
  | ActionType<typeof matchFilterSettingActions>
  | ActionType<typeof downloadableContentActions>
  | ActionType<typeof itemCategoryActions>
  | ActionType<typeof itemProductActions>
  | ActionType<typeof decoItemActions>
  | ActionType<typeof decoResourceActions>
  | ActionType<typeof decoSettingActions>
  | ActionType<typeof decoSettingProductActions>
  | ActionType<typeof decoSettingScheduleActions>
  | ActionType<typeof teamMatchCampaignActions>
  | ActionType<typeof teamMatchProfileActions>
  | ActionType<typeof fileS3UploadActions>
  | ActionType<typeof promotionSettingActions>
  | ActionType<typeof promotionContentActions>
  | ActionType<typeof teamMessageActions>
  | ActionType<typeof teamMessageImageSettingActions>
  | ActionType<typeof teamMessageContentSettingActions>
  | ActionType<typeof teamMessageLinkSettingActions>
  | ActionType<typeof agentManageActions>
  | ActionType<typeof agentHistoryActions>
  | ActionType<typeof decoSettingScheduleActions>
  | ActionType<typeof punishAction>
  | ActionType<typeof punishmentAction>
  | ActionType<typeof matataSuspensionHistoryAction>
  | ActionType<typeof matataDeviceUnbindingAction>
  | ActionType<typeof decoColorPresetActions>
  | ActionType<typeof uploadFileListActions>
  | ActionType<typeof waterSlideBannerActions>
  | ActionType<typeof shopMainPageActions>
  | ActionType<typeof shopSectionPageActions>
  | ActionType<typeof shopModulePageActions>
  | ActionType<typeof shopModuleGroupPageActions>
  | ActionType<typeof shopTabPageActions>
  | ActionType<typeof shopDetailPageActions>
  | ActionType<typeof limitedEditionActions>
  | ActionType<typeof friendshipActions>
  | ActionType<typeof shopEntryBannerActions>
  | ActionType<typeof shopEntryBannerScheduleActions>
  | ActionType<typeof promotionPublishActions>
  | ActionType<typeof promotionPublishHistoryActions>
  | ActionType<typeof remoteConfigActions>
  | ActionType<typeof gemProductTierActions>
  | ActionType<typeof gemProductTierScheduleActions>
  | ActionType<typeof messageBannerActions>
  | ActionType<typeof abTestActions>
  | ActionType<typeof abTestCriteriaActions>
  | ActionType<typeof criteriaSegmentActions>
  | ActionType<typeof notificationCenterBannerActions>
  | ActionType<typeof notificationCenterBannerScheduleActions>
  | ActionType<typeof notificationContentActions>
  | ActionType<typeof pushContentActions>
  | ActionType<typeof gemProvideActions>
  | ActionType<typeof itemProvideActions>
  | ActionType<typeof itemPackageProvideActions>
  | ActionType<typeof configStoreActions>
  | ActionType<typeof gemWithdrawActions>
  | ActionType<typeof gemCancelPurchaseActions>
  | ActionType<typeof invalidatedPurchaseActions>
  | ActionType<typeof visitorConfigActions>
  | ActionType<typeof visitorAbTestActions>
  | ActionType<typeof visitorAbTestCriteriaActions>
  | ActionType<typeof visitorSegmentActions>
  | ActionType<typeof matataInvalidatedPurchaseActions>
  | ActionType<typeof matataInvalidatedPurchaseBySenderActions>
  | ActionType<typeof matataInvalidatedPurchaseByReceiverActions>
  | ActionType<typeof promotionScheduleActions>
  | ActionType<typeof promotionScheduleTargetActions>
  | ActionType<typeof promotionScheduleTestActions>
  | ActionType<typeof bigquerySegmentActions>
  | ActionType<typeof bigquerySegmentDeclarationActions>
  | ActionType<typeof webDecoEffectActions>
  | ActionType<typeof webDecoSettingActions>
  | ActionType<typeof webDecoSettingEffectActions>
  | ActionType<typeof webDecoSettingScheduleActions>
  | ActionType<typeof appStoreReviewActions>
  | ActionType<typeof purposeActions>
  | ActionType<typeof purposeScheduleActions>
  | ActionType<typeof purposeOptionActions>
  | ActionType<typeof mlConfigModelActions>
  | ActionType<typeof mlConfigModelSegmentActions>
  | ActionType<typeof mlConfigFeatureActions>
  | ActionType<typeof mlConfigFeatureSegmentActions>
  | ActionType<typeof promotionContentGroupActions>;

export type RootActionTypes =
  | UserActions
  | LayoutActions
  | BannerSettingActions
  | BannerSettingMapEntryActions
  | ExpressionSegmentActions
  | AgeManageActions
  | BlockPhoneManageActions
  | ProfileManageActions
  | GenderManageActions
  | CountryManageActions
  | MatchTagActions
  | ConvertUserKeyActions
  | ProductIconActions
  | IabProductActions
  | ProductActions
  | UserSegmentActions
  | GemSettingActions
  | GemSettingLimitKeyActions
  | GemSettingMapEntryActions
  | GemSettingProductActions
  | SpecialPromotionSettingActions
  | SpecialPromotionSettingLimitKeyActions
  | SpecialPromotionSettingMapEntryActions
  | SpecialPromotionSettingProductActions
  | ImageSettingActions
  | FileSettingActions
  | FileUploadActions
  | SimulationUploadActions
  | LocalizationKeyActions
  | SettingActions
  | NoticeActions
  | NoticePopupButtonActions
  | NoticePopupCarouselActions
  | NoticePopupScheduleActions
  | StringLocalizationActions
  | BookmarkActions
  | RealtimeMonitoringActions
  | ManualSegmentActions
  | MoniNoticeActions
  | UserItemInventoryActions
  | MatchFilterActions
  | MatchFilterConfigActions
  | MatchFilterOptionListingActions
  | MatchFilterOptionActions
  | MatchFilterSegmentActions
  | MatchFilterSettingActions
  | DownloadableContentActions
  | ItemCategoryActions
  | ItemProductActions
  | DecoItemActions
  | DecoResourceActions
  | DecoSettingActions
  | DecoSettingProductActions
  | DecoSettingScheduleActions
  | TeamMatchCampaignActions
  | TeamMatchProfileActions
  | FileS3UploadActions
  | PromotionSettingActions
  | PromotionContentActions
  | TeamMessageActions
  | TeamMessageImageSettingActions
  | TeamMessageContentSettingActions
  | TeamMessageLinkSettingActions
  | AgentManageActions
  | AgentHistoryActions
  | PunishActions
  | PunishmentActions
  | MatataSuspensionHistoryActions
  | MatataDeviceUnbindingActions
  | DecoColorPresetActions
  | UploadFileListActions
  | WaterSlideBannerActions
  | ShopMainPageActions
  | ShopDetailPageActions
  | ShopSectionPageActions
  | ShopModulePageActions
  | ShopModuleGroupPageActions
  | ShopTabPageActions
  | LimitedEditionPageActions
  | FriendshipActions
  | ShopEntryBannerActions
  | ShopEntryBannerScheduleActions
  | PromotionPublishActions
  | PromotionPublishHistoryActions
  | RemoteConfigActions
  | GemProductTierActions
  | GemProductTierScheduleActions
  | MessageBannerActions
  | AbTestActions
  | AbTestCriteriaActions
  | CriteriaSegmentActions
  | NotificationCenterBannerActions
  | NotificationCenterBannerScheduleActions
  | NotificationContentActions
  | PushContentActions
  | GemProvideActions
  | ItemProvideActions
  | ItemPackageProvideActions
  | ConfigStoreActions
  | GemWithdrawActions
  | CancelGemPurchaseActions
  | InvalidatedPurchaseActions
  | VisitorConfigActions
  | VisitorAbTestActions
  | VisitorAbTestCriteriaActions
  | VisitorSegmentActions
  | MatataInvalidatedPurchaseActions
  | MatataInvalidatedPurchaseBySenderActions
  | MatataInvalidatedPurchaseByReceiverActions
  | PromotionScheduleActions
  | PromotionScheduleTargetActions
  | PromotionScheduleTestActions
  | BigquerySegmentActions
  | BigquerySegmentDeclarationActions
  | WebDecoEffectActions
  | WebDecoSettingActions
  | WebDecoSettingEffectActions
  | WebDecoSettingScheduleActions
  | AppStoreReviewActions
  | PurposeActions
  | PurposeScheduleActions
  | PurposeOptionActions
  | MlConfigModelActions
  | MlConfigModelSegmentActions
  | MlConfigFeatureActions
  | MlConfigFeatureSegmentActions
  | PromotionContentGroupActions;

export const rootActionTypes = [
  MoniNoticeActions,
  UserActions,
  LayoutActions,
  GemSettingActions,
  GemSettingMapEntryActions,
  GemSettingLimitKeyActions,
  GemSettingProductActions,
  SpecialPromotionSettingActions,
  SpecialPromotionSettingMapEntryActions,
  SpecialPromotionSettingLimitKeyActions,
  SpecialPromotionSettingProductActions,
  BannerSettingActions,
  BannerSettingMapEntryActions,
  ExpressionSegmentActions,
  AgeManageActions,
  BlockPhoneManageActions,
  ProfileManageActions,
  GenderManageActions,
  CountryManageActions,
  MatchTagActions,
  ConvertUserKeyActions,
  ProductIconActions,
  IabProductActions,
  ProductActions,
  UserSegmentActions,
  ImageSettingActions,
  FileSettingActions,
  FileUploadActions,
  SimulationUploadActions,
  LocalizationKeyActions,
  StringLocalizationActions,
  BookmarkActions,
  SettingActions,
  NoticeActions,
  NoticePopupButtonActions,
  NoticePopupCarouselActions,
  NoticePopupScheduleActions,
  ManualSegmentActions,
  RealtimeMonitoringActions,
  UserSegmentActions,
  UserItemInventoryActions,
  MatchFilterActions,
  MatchFilterConfigActions,
  MatchFilterOptionListingActions,
  MatchFilterOptionActions,
  MatchFilterSegmentActions,
  MatchFilterSettingActions,
  DownloadableContentActions,
  ItemCategoryActions,
  ItemProductActions,
  DecoItemActions,
  DecoResourceActions,
  DecoSettingActions,
  DecoSettingProductActions,
  DecoSettingScheduleActions,
  TeamMatchCampaignActions,
  TeamMatchProfileActions,
  FileS3UploadActions,
  PromotionSettingActions,
  PromotionContentActions,
  TeamMessageActions,
  TeamMessageImageSettingActions,
  TeamMessageContentSettingActions,
  TeamMessageLinkSettingActions,
  AgentManageActions,
  AgentHistoryActions,
  PunishActions,
  PunishmentActions,
  MatataSuspensionHistoryActions,
  MatataDeviceUnbindingActions,
  DecoColorPresetActions,
  UploadFileListActions,
  WaterSlideBannerActions,
  ShopMainPageActions,
  ShopDetailPageActions,
  ShopSectionPageActions,
  ShopModulePageActions,
  ShopModuleGroupPageActions,
  ShopTabPageActions,
  LimitedEditionPageActions,
  FriendshipActions,
  ShopEntryBannerActions,
  ShopEntryBannerScheduleActions,
  PromotionPublishActions,
  PromotionPublishHistoryActions,
  RemoteConfigActions,
  GemProductTierActions,
  GemProductTierScheduleActions,
  MessageBannerActions,
  AbTestActions,
  AbTestCriteriaActions,
  CriteriaSegmentActions,
  NotificationCenterBannerActions,
  NotificationCenterBannerScheduleActions,
  NotificationContentActions,
  PushContentActions,
  GemProvideActions,
  ItemProvideActions,
  ItemPackageProvideActions,
  ConfigStoreActions,
  GemWithdrawActions,
  CancelGemPurchaseActions,
  InvalidatedPurchaseActions,
  VisitorConfigActions,
  VisitorAbTestActions,
  VisitorAbTestCriteriaActions,
  VisitorSegmentActions,
  MatataInvalidatedPurchaseActions,
  MatataInvalidatedPurchaseBySenderActions,
  MatataInvalidatedPurchaseByReceiverActions,
  PromotionScheduleActions,
  PromotionScheduleTargetActions,
  PromotionScheduleTestActions,
  BigquerySegmentActions,
  BigquerySegmentDeclarationActions,
  WebDecoEffectActions,
  WebDecoSettingActions,
  WebDecoSettingEffectActions,
  WebDecoSettingScheduleActions,
  AppStoreReviewActions,
  PurposeActions,
  PurposeScheduleActions,
  PurposeOptionActions,
  MlConfigModelActions,
  MlConfigModelSegmentActions,
  MlConfigFeatureActions,
  MlConfigFeatureSegmentActions,
  PromotionContentGroupActions,
]
  .map(Object.values)
  .reduce((a, b) => a.concat(b));
