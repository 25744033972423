import {
  createBulkApi,
  createDeleteApi,
  createListApi,
  createPostApi,
  createReadApi,
  createUpdateApi,
} from 'store/utils';

import {
  StringLocalizationCreateRequestData,
  StringLocalizationCreateSuccessData,
  StringLocalizationDetail,
  StringLocalizationHistoryListItem,
  StringLocalizationListItem,
  StringLocalizationRequestFormValues,
  StringLocalizationUpdateRequestData,
  StringLocalizationUpdateSuccessData,
  StringLocalizationUpsertRequestData,
  StringLocalizationUpsertSuccessData,
} from './types';

export const listStringLocalizationApi = createListApi<StringLocalizationListItem>('/localizations');

export const readStringLocalizationApi = createReadApi<StringLocalizationDetail>('/localizations/:id');

export const upsertStringLocalizationBulkApi = createBulkApi<
  StringLocalizationUpsertRequestData,
  StringLocalizationUpsertSuccessData
>('/localizations/bulk', 200);

export const createStringLocalizationApi = createPostApi<
  StringLocalizationRequestFormValues,
  StringLocalizationCreateRequestData,
  StringLocalizationCreateSuccessData
>('/localizations');

export const updateStringLocalizationApi = createUpdateApi<
  StringLocalizationRequestFormValues,
  StringLocalizationUpdateRequestData,
  StringLocalizationUpdateSuccessData
>('/localizations/:id');

export const deleteStringLocalizationApi = createDeleteApi('/localizations/:id');

export const listStringLocalizationHistoryApi =
  createListApi<StringLocalizationHistoryListItem>('/localization_histories');

export const refreshStringLocalizationApi = createPostApi('/localizations/refresh');
