import { createReducer } from 'typesafe-actions';

import { RootAction } from '../root/types';

import * as visitorSegmentActions from './actions';
import { initialState, VisitorSegmentState } from './types';

const visitorSegment = createReducer<VisitorSegmentState, RootAction>(initialState)
  .handleAction(visitorSegmentActions.listVisitorSegment.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    list: {
      ...state.list,
      page,
      pageSize,
    },
  }))
  .handleAction(visitorSegmentActions.listVisitorSegment.success, (state, { payload }) => ({
    ...state,
    list: payload,
    detail: undefined,
  }))
  .handleAction(visitorSegmentActions.listVisitorSegmentSearch.request, (state, { payload: { page, pageSize } }) => ({
    ...state,
    searchList: {
      ...state.searchList,
      page,
      pageSize,
    },
  }))
  .handleAction(visitorSegmentActions.listVisitorSegmentSearch.success, (state, { payload }) => ({
    ...state,
    searchList: payload,
  }))
  .handleAction(
    visitorSegmentActions.listAllVisitorSegmentSearch.request,
    (state, { payload: { page, pageSize } }) => ({
      ...state,
      searchList: {
        ...state.searchList,
        page,
        pageSize,
      },
    })
  )
  .handleAction(visitorSegmentActions.listAllVisitorSegmentSearch.success, (state, { payload }) => ({
    ...state,
    searchList: payload,
  }))
  .handleAction(visitorSegmentActions.readVisitorSegment.success, (state, { payload }) => ({
    ...state,
    detail: payload,
  }));

export default visitorSegment;
