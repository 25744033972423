import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { FormRequestPayload, ListRequestPayload, ListState, ReadRequestPayload } from 'store/types';

import { DecoItemActions as Actions, DecoItemDetail, DecoItemListItem } from './types';

export const listDecoItem = createAsyncAction(
  Actions.LIST_DECO_ITEMS_REQUEST,
  Actions.LIST_DECO_ITEMS_SUCCESS,
  Actions.LIST_DECO_ITEMS_FAILURE
)<ListRequestPayload, ListState<DecoItemListItem>, AxiosError>();

export const readDecoItem = createAsyncAction(
  Actions.READ_DECO_ITEM_REQUEST,
  Actions.READ_DECO_ITEM_SUCCESS,
  Actions.READ_DECO_ITEM_FAILURE
)<ReadRequestPayload, DecoItemDetail, AxiosError>();

export const refreshDecoItem = createAsyncAction(
  Actions.REFRESH_DECO_ITEM_REQUEST,
  Actions.REFRESH_DECO_ITEM_SUCCESS,
  Actions.REFRESH_DECO_ITEM_FAILURE
)<FormRequestPayload<{}>, undefined, AxiosError>();
