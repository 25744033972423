import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { LocalizationKeyActions as Actions, LocalizationKey } from 'store/localizationKey/types';
import { ListRequestPayload, ListState } from 'store/types';

export const listLocalizationKey = createAsyncAction(
  Actions.LIST_LOCALIZATION_KEY_REQUEST,
  Actions.LIST_LOCALIZATION_KEY_SUCCESS,
  Actions.LIST_LOCALIZATION_KEY_FAILURE
)<ListRequestPayload, ListState<LocalizationKey>, AxiosError>();
