import { combineEpics } from 'redux-observable';

import { createFlatListEpic } from '../utils';

import { listGemSettingLimitKey } from './actions';
import { listGemSettingLimitKeyApi } from './api';

const listGemSettingLimitKeyEpic = createFlatListEpic(listGemSettingLimitKey, listGemSettingLimitKeyApi);

export default combineEpics(listGemSettingLimitKeyEpic);
