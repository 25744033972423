import { createListApi, createPostApi, createReadApi, createUpdateApi } from 'store/utils';

import {
  PurposeCreateRequestData,
  PurposeCreateResponseData,
  PurposeItemDetail,
  PurposeFormValues,
  PurposeListItem,
  PurposeUpdateRequestData,
  PurposeUpdateResponseData,
} from './types';

const PURPOSE_API_PREFIX = '/purposes';
const PURPOSE_SET_API_PREFIX = '/purposes/sets';

export const listPurposeApi = createListApi<PurposeListItem>(PURPOSE_API_PREFIX);

export const createPurposeApi = createPostApi<PurposeFormValues, PurposeCreateRequestData, PurposeCreateResponseData>(
  PURPOSE_SET_API_PREFIX
);

export const updatePurposeApi = createUpdateApi<PurposeFormValues, PurposeUpdateRequestData, PurposeUpdateResponseData>(
  `${PURPOSE_SET_API_PREFIX}/:id`
);

export const readPurposeApi = createReadApi<PurposeItemDetail>(`${PURPOSE_API_PREFIX}/:id`);
