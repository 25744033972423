import {
  NoticePopupButtonCreateRequestData,
  NoticePopupButtonCreateSuccessData,
  NoticePopupButtonUpdateRequestData,
  NoticePopupButtonUpdateSuccessData,
} from 'store/noticePopupButton/types';
import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

export const createNoticePopupButtonApi = createPostApi<
  NoticePopupButtonCreateRequestData,
  NoticePopupButtonCreateSuccessData
>('/notice_popups/buttons');

export const updateNoticePopupButtonApi = createUpdateApi<
  NoticePopupButtonUpdateRequestData,
  NoticePopupButtonUpdateSuccessData
>('/notice_popups/buttons/:id');

export const deleteNoticePopupButtonApi = createDeleteApi('/notice_popups/buttons/:id');
