import { createDeleteApi, createPostApi, createUpdateApi } from 'store/utils';

import {
  DecoSettingScheduleCreateRequestData,
  DecoSettingScheduleCreateResponseData,
  DecoSettingScheduleFormValues,
  DecoSettingScheduleUpdateRequestData,
  DecoSettingScheduleUpdateResponseData,
} from './types';

export const createDecoSettingScheduleApi = createPostApi<
  DecoSettingScheduleFormValues,
  DecoSettingScheduleCreateRequestData,
  DecoSettingScheduleCreateResponseData
>('/deco_settings/schedules');

export const updateDecoSettingScheduleApi = createUpdateApi<
  DecoSettingScheduleFormValues,
  DecoSettingScheduleUpdateRequestData,
  DecoSettingScheduleUpdateResponseData
>('/deco_settings/schedules/:id');

export const deleteDecoSettingScheduleApi = createDeleteApi('/deco_settings/schedules/:id');
