import { combineEpics } from 'redux-observable';
import { createAsyncEpic } from 'store/utils';
import {
  createShopTab,
  createShopTabSchedule,
  deleteShopTab,
  deleteShopTabSchedule,
  listShopTab,
  readShopTab,
  updateShopTab,
  updateShopTabSchedule,
} from './actions';
import {
  createShopTabApi,
  createShopTabPageScheduleApi,
  deleteShopTabApi,
  deleteShopTabPageScheduleApi,
  listShopTabApi,
  readShopTabApi,
  updateShopTabApi,
  updateShopTabPageScheduleApi,
} from './api';

export const createShopTabEpic = createAsyncEpic(createShopTab, createShopTabApi);
export const listShopTabEpic = createAsyncEpic(listShopTab, listShopTabApi);
export const readShopTabEpic = createAsyncEpic(readShopTab, readShopTabApi);
export const deleteShopTabEpic = createAsyncEpic(deleteShopTab, deleteShopTabApi);
export const updateShopTabEpic = createAsyncEpic(updateShopTab, updateShopTabApi);

// - Schedule -
const createShopTabScheduleEpic = createAsyncEpic(createShopTabSchedule, createShopTabPageScheduleApi);
const editShopTabScheduleEpic = createAsyncEpic(updateShopTabSchedule, updateShopTabPageScheduleApi);
const deleteShopTabScheduleEpic = createAsyncEpic(deleteShopTabSchedule, deleteShopTabPageScheduleApi);

export default combineEpics(
  createShopTabEpic,
  listShopTabEpic,
  readShopTabEpic,
  deleteShopTabEpic,
  updateShopTabEpic,
  //schedule
  createShopTabScheduleEpic,
  editShopTabScheduleEpic,
  deleteShopTabScheduleEpic
);
