import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createListEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createShopEntryBanner,
  listShopEntryBanner,
  readShopEntryBanner,
  refreshShopEntryBanner,
  updateShopEntryBanner,
} from './actions';
import {
  createShopEntryBannerApi,
  listShopEntryBannerApi,
  readShopEntryBannerApi,
  refreshShopEntryBannerApi,
  updateShopEntryBannerApi,
} from './api';

const updateShopEntryBannerToastEpic = createToastEpic(updateShopEntryBanner.success, 'Successfully saved');

const createShopEntryBannerToastEpic = createToastEpic(createShopEntryBanner.success, 'Successfully created');

const refreshShopEntryBannerToastEpic = createToastEpic(
  refreshShopEntryBanner.success,
  'Successfully refreshed server'
);

const listShopEntryBannerEpic = createListEpic(listShopEntryBanner, listShopEntryBannerApi);

const createShopEntryBannerEpic = createPostEpic(createShopEntryBanner, createShopEntryBannerApi);

const readShopEntryBannerEpic = createReadEpic(readShopEntryBanner, readShopEntryBannerApi);

const updateShopEntryBannerEpic = createUpdateEpic(updateShopEntryBanner, updateShopEntryBannerApi);

const refreshShopEntryBannerEpic = createAsyncEpic(refreshShopEntryBanner, refreshShopEntryBannerApi);

export default combineEpics(
  updateShopEntryBannerToastEpic,
  createShopEntryBannerToastEpic,
  refreshShopEntryBannerToastEpic,
  listShopEntryBannerEpic,
  createShopEntryBannerEpic,
  readShopEntryBannerEpic,
  updateShopEntryBannerEpic,
  refreshShopEntryBannerEpic
);
