import { combineEpics } from 'redux-observable';

import {
  createAsyncEpic,
  createDeleteEpic,
  createPostEpic,
  createReadEpic,
  createToastEpic,
  createUpdateEpic,
} from 'store/utils';

import {
  createPromotionSchedule,
  deletePromotionSchedule,
  listPromotionSchedule,
  readPromotionSchedule,
  refreshPromotionSchedule,
  updatePromotionSchedule,
} from './actions';
import {
  createPromotionScheduleApi,
  deletePromotionScheduleApi,
  listPromotionScheduleApi,
  readPromotionScheduleApi,
  refreshPromotionScheduleApi,
  updatePromotionScheduleApi,
} from './api';

const listPromotionScheduleEpic = createAsyncEpic(listPromotionSchedule, listPromotionScheduleApi);

const readPromotionScheduleEpic = createReadEpic(readPromotionSchedule, readPromotionScheduleApi);

const createPromotionScheduleEpic = createPostEpic(createPromotionSchedule, createPromotionScheduleApi);

const createPromotionScheduleToastEpic = createToastEpic(createPromotionSchedule.success, 'Successfully created');

const updatePromotionScheduleEpic = createUpdateEpic(updatePromotionSchedule, updatePromotionScheduleApi);

const updatePromotionScheduleToastEpic = createToastEpic(updatePromotionSchedule.success, 'Successfully saved');

const deletePromotionScheduleEpic = createDeleteEpic(deletePromotionSchedule, deletePromotionScheduleApi);

const deletePromotionScheduleToastEpic = createToastEpic(deletePromotionSchedule.success, 'Successfully deleted');

const refreshPromotionScheduleEpic = createAsyncEpic(refreshPromotionSchedule, refreshPromotionScheduleApi);

const refreshPromotionScheduleToastEpic = createToastEpic(
  refreshPromotionSchedule.success,
  'Successfully refreshed server'
);

export default combineEpics(
  listPromotionScheduleEpic,
  readPromotionScheduleEpic,
  createPromotionScheduleEpic,
  createPromotionScheduleToastEpic,
  updatePromotionScheduleEpic,
  updatePromotionScheduleToastEpic,
  deletePromotionScheduleEpic,
  deletePromotionScheduleToastEpic,
  refreshPromotionScheduleEpic,
  refreshPromotionScheduleToastEpic
);
